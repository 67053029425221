import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class MobileStore {
    root: RootStore;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    * approveArticle(id) {
        const response = yield Api.put(`/admin/article/approve/${id}`, {});
        return response?.data
    }

    * rejectArticle(id) {
        const response = yield Api.put(`/admin/article/reject/${id}`, {});
        return response?.data
    }

    * approvePush(id) {
        const response = yield Api.put(`/admin/push/approve/${id}`, {});
        return response?.data
    }

    * rejectPush(id) {
        const response = yield Api.put(`/admin/push/reject/${id}`, {});
        return response?.data
    }

}
