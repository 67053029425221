import React, { useEffect, useState } from "react";
import { Props } from "./props";
import { Avatar, Badge, Button } from "@material-ui/core";
import NoMessages from "../NoMessages/NoMessages";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import Spinner from "../../../components/Spinner/Spinner";
import _ from "lodash";
import moment from "moment";
import { convertToDate } from "../../../shared/utils/sharedFunctions";
import { useHistory } from "react-router-dom";

const AllMessages = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { usersPageStore, languageStore, userStore } = useStore();
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [groupedList, setGroupedList] = useState({});
  const [today, setToday] = useState("");
  const [yesterday, setYesterday] = useState("");
  const [roleType, setRoleType] = useState("");

  const getMessages = async () => {
    setIsLoading(true);
    await flowResult(usersPageStore.getMessages()).then(async (response: any) => {
      if (!response?.success) {
        setIsLoading(false);
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
      const messages = response.messages;
      setNewMessagesCount(messages.filter(mes => !mes.isRead).length);
      mapMessages(messages);
      await readMessages();
      setIsLoading(false);
    });
  };

  const readMessages = async () => {
    await flowResult(usersPageStore.readMessages()).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
    });
  };

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const mapMessages = (messages) => {
    const date = (msg) => convertToDate(msg.createdAt, "preview");
    const groupedMsgs = _.groupBy(messages, date);
    setGroupedList(groupedMsgs);
    const today = convertToDate(moment(Date.now()), "preview");
    setToday(today);
    const yesterday = convertToDate(moment().add(-1, "days"), "preview");
    setYesterday(yesterday);
  };

  useEffect(() => {
    getMessages();
    setRoleType(userStore.getRoleType())
  }, [languageStore.switchLng]);

  const convertToMessage = (item) => {
    const name = `${item?.info?.user?.firstName ?? ''} ${item?.info?.user?.lastName ?? ''}`;
    const action = item?.info?.code ? t(`apiMessages.${item.info.code}`) : '';
    const headerName = item?.info?.header ?? '';
    return `${name} ${action} "${headerName}"`
  }

  const handleShow = (type) => {
    if (type === 'article') {
      history.push('/articles/list');
    } else if (type === 'push') {
      history.push('/push/list');
    }
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper messages-page">
      <h2 className="page-heading">
        {t("messages.allMessages")}
        {newMessagesCount !== 0 && (
          <Badge
            badgeContent={<span className="content">{newMessagesCount}</span>}
            color="primary"
            className="notifications-count"
          />
        )}
      </h2>
      {Object.keys(groupedList).length ? (
        <div>
          {Object.keys(groupedList).map((key, index) => {
            return (
              <div className="section" key={index}>
                <h3 className="section-heading">
                  {today === key ? t("messages.today") : ""}
                  {yesterday === key ? t("messages.yesterday") : ""}
                  {key}
                </h3>

                <div className="item-container">
                  {groupedList[key].map((item: any, index) => (
                    <div key={index} className="item-row">
                      <Avatar
                        alt={`${item?.info?.user?.firstName ?? ""} ${item?.info?.user?.lastName ?? ""
                          }`}
                        src={item?.info?.user?.image}
                        className="avatar"
                      />
                      <div className={`item-message-container d-flex flex-column ${!(item?.info?.isApproved === false && roleType === 'chiefEditor') ? 'justify-center' : ''}`}>
                        <p>{convertToMessage(item)}</p>
                        {
                          (item?.info?.isApproved === false && roleType === 'chiefEditor') ? (
                              <Button
                                className="view-button"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => handleShow(item.info.model)}
                              >{item?.info?.model === 'push' ? t('mobile.messages.showPush') : t('mobile.messages.showArticle')}</Button>
                          ) : null
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoMessages />
      )}
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        buttonText={t("common.buttons.confirm")}
        message={notificationMessage}
      />
    </div>
  );
};

export default AllMessages;
