export class CreateButton {
    static cases = {
        '/articles/list' : '/articles/create/article',
        '/articles/create/article' : '/articles/create/article',
        '/articles/rotating-banner' : '/articles/create/banner',
        '/articles/create/banner' : '/articles/create/banner',
        '/articles/ads' : '/articles/create/ad',
        '/articles/create/ad' : '/articles/create/ad',
        '/benefits/list' : '/benefits/create/sale',
        '/benefits/create/sale' : '/benefits/create/sale',
        '/community/list' : '/community/create',
        '/community/create' : '/community/create',
        '/users/list' : '/users/create',
        '/users/import' : '/users/create',
        '/users/invite' : '/users/create',
        '/users/approval' : '/users/create',
        '/users/create' : '/users/create',
        '/push/promotional' : '/push/create',
        '/push/create' : '/push/create',
    }


    public static getUrl(route: string): string {
        return this.cases[route];
    }
}
