import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { noContent } from "../../../db/db";
import CheckIcon from "@material-ui/icons/Check";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import SkyLight from "react-skylight";
import EditUser from "./EditUser";
import { useStore } from "../../../store/StoreContext";
import "./style.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import Spinner from "../../../components/Spinner/Spinner";
import { flowResult } from "mobx";
import UserTypeEnum from "../../../models/UserType";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import SortedAutocomplete from "../../../components/SortedAutocomplete/SortedAutocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "fit-content",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grid: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

const AllUsers = () => {
  const { i18n, t } = useTranslation();
  const {
    userStore,
    usersPageStore,
    languageStore,
    actionStore,
    settingsStore,
  } = useStore();
  const classes = useStyles();
  const [activeList, setActiveList] = useState<any>([]);
  const [notActiveList, setNotActiveList] = useState<any>([]);
  const [activeUsersFlag, setActiveUsersFlag] = useState("active");
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [selectCommunity, setSelectCommunity] = useState("all");
  const [itemType, setItemType] = useState("");
  const [toggleActiveUsers, setToggleActiveUsers] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loadOnce, setLoadOnce] = useState(true);
  const [roleType, setRoleType] = useState(null);
  const [selectAllActive, setSelectAllActive] = useState(false);
  const [selectAllNonActive, setSelectAllNonActive] = useState(false);
  const [lngDirection, setLngDirection] = useState("");

  const [downloadedCount, setDownloadedCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [notActivePage, setNotActivePage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalNotActiveUsers, setTotalNotActiveUsers] = useState(0);

  const handleFilterChange = (value) => {
    let filter = {};
    if (value !== "all") {
      filter["community"] = value;
    }
    setSelectCommunity(value);
    getUsersList(filter);
  };

  const [communities, setCommunities] = useState<any>([]);
  const [adminCommunities, setAdminCommunities] = useState<any>([]);

  let modalRef: any;
  function openModalHandler() {
    modalRef.show();
  }

  const overlayStyles = {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const getUsersList = async (params = {}) => {
    if (!loadOnce) {
      setIsLoading(true);
    }
    // Get Active Users
    await flowResult(
      usersPageStore.getUsers({ ...params, limit: 200, is_active: true })
    ).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
      const data = response.users;
      const pagination = response.pagination;
      setActiveList(data);
      setDownloadedCount(pagination.totalRegistered);
      setTotalActiveUsers(pagination.totalActive);
      setTotalNotActiveUsers(pagination.totalNotActive);
      setTotalUsers(pagination.totalActive + pagination.totalNonActive);
    });
    // Get Not Active Users
    await flowResult(
      usersPageStore.getUsers({ ...params, limit: 200, is_active: false })
    ).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
      const data = response.users;
      setNotActiveList(data);
    });
    if (!loadOnce) {
      setIsLoading(false);
    }
  };

  const getLoadMoreUsers = async (type, params = {}) => {
    setInnerLoading(true);
    let offset = type === "active" ? activeList.length : notActiveList;
    await flowResult(
      usersPageStore.getUsers({
        ...params,
        limit: 100,
        offset,
        is_active: type === "active",
      })
    ).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
      const data = response.users;
      if (type === "active") {
        setActiveList(activeList.concat(data));
        setActivePage(activePage + 1);
      } else {
        setNotActiveList(notActiveList.concat(data));
        setNotActivePage(notActivePage + 1);
      }
    });
    setInnerLoading(false);
  };

  const getData = async () => {
    setIsLoading(true);
    setCommunities(userStore.getCommunities());
    setAdminCommunities(userStore.getAdminCommunities());
    setRoleType(userStore.getRoleType());
    await getUsersList();
    setLoadOnce(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onDeleteUser = (user) => {
    setSelectedUser(user);
    openModalHandler();
  };

  const deleteUserFlow = async (userID) => {
    await flowResult(usersPageStore.deleteUser(userID)).then(
      (response: any) => {
        if (!response?.success) {
          return "error";
        }
      }
    );
    return "success";
  };

  const deleteUser = async (userID) => {
    setIsLoading(true);
    modalRef.hide();
    const deleteUser = await deleteUserFlow(userID);
    if (deleteUser === "success") {
      handleNotificationOpen(t("errorMessages.delUserSuccess"));
    } else {
      handleNotificationOpen(t("errorMessages.delUserFailed"));
    }
    getUsersList();
  };

  const onEditUser = (user) => (event) => {
    const data = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.image,
      email: user.email,
      phone: user.phone,
      departmentId: user?.connection?.[0]?.department?.id ?? "",
      secondaryDepartmentId:
        user?.connection?.[0]?.secondaryDepartment?.id ?? null,
      community: user?.connection?.[0]?.community ?? {},
      admin_communities: user?.connection?.[0]?.adminCommunities ?? [],
      type: user.connection[0].role,
      downloadApp: user.isRegistered,
      isActive: user?.connection?.[0]?.isActive ?? false,
    };
    usersPageStore.userEdit = data;
    const widthParentElement = event.currentTarget.closest(
      ".MuiDataGrid-viewport"
    );
    const rowAnchor = event.currentTarget.closest(".MuiDataGrid-row");
    usersPageStore.editRowAnchor = rowAnchor;
    usersPageStore.editRowWidth = widthParentElement.clientWidth;
    usersPageStore.editRowMaxWidth = widthParentElement.firstChild.clientWidth;
  };

  const handleSaveEdit = async (data, toggleActive = false) => {
    setIsLoading(true);
    const editedData = { ...data };
    delete editedData.id;
    await flowResult(usersPageStore.updateUser(editedData, data.id)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    if (toggleActive) {
      await flowResult(usersPageStore.toggleActiveUser(data.id)).then(
        (response: any) => {
          if (!response?.success) {
            handleNotificationOpen(
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            return;
          }
        }
      );
    }
    getUsersList();
  };

  const handleDeleteSelected = () => {
    let deleteList = [];
    if (toggleActiveUsers) {
      deleteList = activeList
        .filter((user: any) => user.isSelect)
        .map((user: any) => user.id);
    } else {
      deleteList = notActiveList
        .filter((user: any) => user.isSelect)
        .map((user: any) => user.id);
    }
    if (deleteList.length) {
      setSelectedUsers(deleteList);
      handleNotificationOpen(t("errorMessages.deleteUser"), "isDelete");
    } else {
      setNotificationMessage(t("errorMessages.needUser"));
      setButtonText(t("common.buttons.close"));
      setOpenNotificationModal(true);
    }
  };

  const deleteSelected = async () => {
    setIsLoading(true);
    if (
      (toggleActiveUsers && selectAllActive) ||
      (!toggleActiveUsers && selectAllNonActive)
    ) {
      await flowResult(usersPageStore.deleteAllUsers()).then(
        (response: any) => {
          if (!response?.success) {
            handleNotificationOpen(
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            setIsLoading(false);
            return;
          }
        }
      );
    } else {
      const promises: any = [];
      selectedUsers.forEach((userID: any) => {
        promises.push(deleteUserFlow(userID));
      });
      await Promise.all(promises).then((response: any) => {
        if (response.indexOf("error") !== -1) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.U202")
        );
      });
    }
    getUsersList();
  };

  const handleSelect = (id, list, setList) => {
    const newList = list.map((el: any) => {
      if (id === el.id) {
        return { ...el, isSelect: !el.isSelect };
      }
      return el;
    });
    setList(newList);
    if (toggleActiveUsers && selectAllActive) setSelectAllActive(false);
    if (!toggleActiveUsers && selectAllNonActive) setSelectAllNonActive(false);
  };

  const handleSelectAll = (list, setList) => {
    const newList = list.map((el: any) => {
      return {
        ...el,
        isSelect: toggleActiveUsers ? !selectAllActive : !selectAllNonActive,
      };
    });

    setList(newList);
    if (toggleActiveUsers) {
      setSelectAllActive(!selectAllActive);
    } else {
      setSelectAllNonActive(!selectAllNonActive);
    }
  };

  const handleNotificationOpen = (message = "", type = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    if (type) {
      if (type === "invite") {
        const list = toggleActiveUsers ? activeList : notActiveList;
        const selectedList = list
          .filter((user: any) => user.isSelect)
          .map((user: any) => user.email);
        if (!selectedList.length) {
          setNotificationMessage(t("errorMessages.needUser"));
          setOpenNotificationModal(true);
          return;
        }
      }
      setButtonText(t("common.buttons.confirm"));
      setItemType(type);
    }
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = (type, user?) => () => {
    if (type) {
      setItemType("");
      if (type === "isDelete") {
        deleteSelected();
      }
      if (type === "invite") {
        inviteSelectUsers();
      }
    }
    setOpenNotificationModal(false);
  };

  const inviteSelectUsers = async () => {
    setIsLoading(true);
    const list = toggleActiveUsers ? activeList : notActiveList;
    const selectedList = list
      .filter((user: any) => user.isSelect)
      .map((user: any) => user.email);
    const data = {
      emails: [...selectedList],
    };
    if (
      (toggleActiveUsers && selectAllActive) ||
      (!toggleActiveUsers && selectAllNonActive)
    ) {
      data["emails"] = ["all"];
    }
    await flowResult(usersPageStore.inviteThroughEmail(data)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  useEffect(() => {
    if (usersPageStore.triggerUpdateList) {
      getUsersList(usersPageStore.searchParams);
      usersPageStore.triggerUpdateList = false;
    }
  }, [usersPageStore.triggerUpdateList]);

  const handleErrorPopUp = (message) => {
    handleNotificationOpen(message);
  };

  const handleInviteOneUser = async (selectedUser) => {
    // TODO: add modal for confirmation, ask for messages content
    setIsLoading(true);
    const data = {
      // title: "Invite",
      // message: "Invite User",
      emails: [selectedUser.email],
    };
    await flowResult(usersPageStore.inviteThroughEmail(data)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll(".MuiDataGrid-window").forEach((el) => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0);
        });
      });
    }
  }, [languageStore.switchLng, isLoading === false]);

  const reverseDirection = (columns) => {
    const array = [];
    columns.forEach((el) => {
      array.unshift(el);
    });
    return array;
  };

  const getCommunityName = (id) => {
    const index = communities.findIndex((el) => el.id === id);
    if (index >= 0) {
      return communities[index].communityName;
    }
    return "";
  };

  const getRoleIndex = (role) => {
    return Object.keys(UserTypeEnum).indexOf(role);
  };

  const tableData: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      sortable: false,
      width: 90,
      renderHeader: () => {
        return (
          <IconButton
            aria-label="select all items"
            onClick={() =>
              handleSelectAll(
                toggleActiveUsers ? activeList : notActiveList,
                toggleActiveUsers ? setActiveList : setNotActiveList
              )
            }
          >
            <Checkbox
              checked={toggleActiveUsers ? selectAllActive : selectAllNonActive}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
      renderCell: (params: GridCellParams) => {
        let row = params.row;
        return (
          <IconButton
            aria-label="select item"
            onClick={() => {
              return handleSelect(
                row.id,
                toggleActiveUsers ? activeList : notActiveList,
                toggleActiveUsers ? setActiveList : setNotActiveList
              );
            }}
          >
            <Checkbox
              checked={row?.isSelect ?? false}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
    },
    {
      field: "avatar",
      headerName: "",
      sortable: false,
      width: 90,
      renderHeader: () => {
        return <div style={{ pointerEvents: "none" }} />;
      },
      renderCell: (params: GridCellParams) => {
        return (
          <Avatar
            className="table-row-image round"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={params.row.image}
          />
        );
      },
    },
    {
      field: "firstName",
      headerName: t("signUp.fields.firstName.label"),
      sortable: true,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.firstName}</span>;
      },
    },
    {
      field: "lastName",
      headerName: t("signUp.fields.lastName.label"),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.lastName}</span>;
      },
    },
    {
      field: "email",
      headerName: t("signUp.fields.email.label"),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title email">{params.row.email}</span>
        );
      },
    },
    {
      field: "phone",
      headerName: t("signUp.fields.phone.label"),
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const rawPhone = params.row?.phone;
        const phone = rawPhone
          ? rawPhone?.replace(" ", "").replace("-", "")
          : " ";
        return (
          <span className="table-row-title">
            {`${phone.slice(0, 3)} ${phone.slice(3, 10)}`}
          </span>
        );
      },
    },
    {
      field: "community",
      headerName: t("tabNavigation.community.mainTab"),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const connection = params.row?.connection?.[0] ?? {};
        const role = connection.role;
        let name;
        if (role === "ADMIN") {
          name = connection.adminCommunities.map((el) => getCommunityName(el));
        } else {
          name = connection.community?.communityName ?? "";
        }
        return (
          <div className={params.row.scheduled ? "table-row-double" : ""}>
            {typeof name !== "string" && name?.length > 1 ? (
              <Tooltip title={name.join(", ")} placement="bottom" arrow>
                <div className="table-row-title">{name[0]}, ...</div>
              </Tooltip>
            ) : (
              <div className="table-row-title">{name}</div>
            )}
          </div>
        );
      },
    },
    {
      field: "department",
      headerName: t("common.fieldHeaders.department"),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const name = params.row?.connection?.[0]?.department?.name ?? "";
        return (
          <div className={params.row.scheduled ? "table-row-double" : ""}>
            <div className="table-row-title">{name}</div>
          </div>
        );
      },
    },
    {
      field: "secondaryDepartment",
      headerName: t("common.fieldHeaders.secondaryDepartment"),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const name =
          params.row?.connection?.[0]?.secondaryDepartment?.name ?? "";
        return (
          <div className={params.row.scheduled ? "table-row-double" : ""}>
            <div className="table-row-title">{!!name ? name : "-"}</div>
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: t("common.fieldHeaders.type"),
      sortable: false,
      width: 120,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
          let role = params.row.connection[0].role;
          if (role && role.length > 1) {
            role = role
              .toLowerCase()
              .split("_")
              .map((el, index) => {
                if (index === 0) return el;
                return el?.charAt(0).toUpperCase() + el?.slice(1);
              })
              .join("");
          }
        return (
          <span className="table-row-title">{t(`users.create.${role}`)}</span>
        );
      },
    },
    {
      field: "downloadApp",
      headerName: t("common.fieldHeaders.invitation"),
      width: 150,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const user = params.row;
        return (
          <>
            {!user.isRegistered ? (
              <>
                {settingsStore.getUserPermissions().users && (
                  <Button
                    color="primary"
                    className="simple-action-button"
                    onClick={() => handleInviteOneUser(user)}
                  >
                    {t("common.buttons.invite")}
                  </Button>
                )}
              </>
            ) : (
              <span>{t("users.allUsers.appInstalled")}</span>
            )}
          </>
        );
      },
    },
    {
      field: "active",
      headerName: t("common.active"),
      width: 75,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        const headerName = params.colDef.headerName;
        return <span className={`table-column text-center`}>{headerName}</span>;
      },
      renderCell: (params: GridCellParams) => {
        let row = params.row;
        const isActive = row?.connection?.[0]?.isActive ?? false;
        return (
          <div className="table-row-actions">
            {isActive ? (
              <CheckIcon color="secondary" />
            ) : (
              <CheckIcon color="disabled" />
            )}
          </div>
        );
      },
    },
    {
      field: "actions",
      width: 120,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const currentRoleIndex: number = getRoleIndex(roleType);
        const role = params.row.connection?.[0]?.role?.toLowerCase() ?? "";
        const rowRoleIndex: number =
          role === "chief_editor"
            ? getRoleIndex("chiefEditor")
            : getRoleIndex(role);
        return (
          <div className="cell-actions">
            {currentRoleIndex < rowRoleIndex &&
            settingsStore.getUserPermissions().users ? (
              <>
                <div onClick={onEditUser(params.row)}>
                  <IconButton aria-label="Edit">
                    <IconComponent icon={EditIcon} />
                  </IconButton>
                </div>
                <div onClick={() => onDeleteUser(params.row)}>
                  <IconButton aria-label="Delete">
                    <IconComponent icon={DeleteIcon} />
                  </IconButton>
                </div>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const handleChange = (event, newAlignment) => {
    setToggleActiveUsers(newAlignment === "active");
    setActiveUsersFlag(newAlignment === "active" ? "active" : "inactive");
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper users-page">
      <div className="page-heading-wrap">
        <h2 className="page-heading">{t("users.allUsers.title")}</h2>
        <div className="total-box">
          <h3>
            {t("users.allUsers.totalUsers")} {totalUsers}
          </h3>
          <h3>
            {t("users.allUsers.downloadedApp")} {downloadedCount}
          </h3>
          {roleType === UserTypeEnum.owner && (
            <SortedAutocomplete 
            disableClearable
            options={communities}
            value={selectCommunity}
            label={t("tabNavigation.community.mainTab")}
            labelName={'communityName'}
            valueName={'id'}
            onChange={handleFilterChange}
          />
          )}
          <ToggleButtonGroup
            className="toggle-buttons-group"
            orientation="horizontal"
            value={activeUsersFlag}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="active" aria-label="active">
              {t("common.active")}
            </ToggleButton>
            <ToggleButton value="inactive" aria-label="inactive">
              {t("users.allUsers.nonActive")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="selected-items-actions">
        {settingsStore.getUserPermissions().users && (
          <>
            <Button
              className="link-button-container"
              onClick={() =>
                handleNotificationOpen(t("errorMessages.inviteUser"), "invite")
              }
            >
              {t("users.allUsers.invite")}
            </Button>
            <Button
              className="link-button-container"
              onClick={handleDeleteSelected}
            >
              {t("users.allUsers.delete")}
            </Button>
          </>
        )}
      </div>
      <div className="content-wrap">
        {activeList?.length && toggleActiveUsers ? (
          <>
            <div className="table-grid">
              <DataGrid
                className={classes.grid}
                autoHeight={true}
                rows={activeList}
                columns={
                  lngDirection === "ltr"
                    ? tableData
                    : reverseDirection(tableData)
                }
                rowHeight={90}
                disableColumnMenu={true}
                hideFooterSelectedRowCount={true}
                pagination={true}
                pageSize={100}
                rowsPerPageOptions={[]}
                onPageChange={(page) => {
                  if (
                    activePage < page &&
                    activeList.length < totalActiveUsers
                  ) {
                    getLoadMoreUsers("active");
                  }
                }}
                rowCount={totalActiveUsers}
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomPagination,
                }}
              />
            </div>
          </>
        ) : (
          <>
            {notActiveList?.length && !toggleActiveUsers ? (
              <>
                <div className="table-grid">
                  <DataGrid
                    className={classes.grid}
                    autoHeight={true}
                    rows={notActiveList}
                    columns={
                      lngDirection === "ltr"
                        ? tableData
                        : reverseDirection(tableData)
                    }
                    rowHeight={90}
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    pagination={true}
                    pageSize={100}
                    rowsPerPageOptions={[]}
                    onPageChange={(page) => {
                      if (
                        notActivePage < page &&
                        notActiveList.length < totalNotActiveUsers
                      ) {
                        getLoadMoreUsers("notActive");
                      }
                    }}
                    rowCount={totalNotActiveUsers}
                  />
                </div>
              </>
            ) : (
              <div className="no-content">
                <img src={noContent.users} alt="No messages" />
                <p>{t("users.allUsers.noContent.message1")}</p>
                <Link
                  to="/users/create"
                  onClick={() => actionStore.toggleSearch(false)}
                  className="note"
                >
                  {t("users.allUsers.noContent.message2")}
                </Link>
              </div>
            )}
          </>
        )}
        {activeList?.length || notActiveList.length ? (
          <EditUser
            handleSaveEdit={handleSaveEdit}
            handleErrorPopUp={handleErrorPopUp}
          />
        ) : null}
        {innerLoading && <Spinner />}
      </div>
      <SkyLight
        className="skylight-dialog"
        overlayStyles={overlayStyles}
        hideOnOverlayClicked
        ref={(ref: any) => (modalRef = ref)}
      >
        <div className="modal-wrapper delete-user">
          <Avatar
            alt={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            src={selectedUser?.avatar}
            style={{ width: "70px", height: "70px" }}
          />
          <h3>
            {t("errorMessages.users.deleteUser", {
              user: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
            })}
          </h3>
          <div className="modal-content">
            {t("users.allUsers.popUp.message")}
          </div>
          <Button
            variant="contained"
            color="primary"
            className="action-button"
            onClick={() => deleteUser(selectedUser.id)}
          >
            {t("users.allUsers.popUp.action")}
          </Button>
        </div>
      </SkyLight>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose(false)}
        handleButtonClick={handleNotificationClose(itemType)}
        buttonText={buttonText}
        message={notificationMessage}
      />
    </div>
  );
};

export default observer(AllUsers);
