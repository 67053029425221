import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class CommunityStore {
  root: RootStore;

  path = "/admin/user";
  // communities: any = [];
  searchParams = {};
  departments = [];
  secondaryDepartments = [];
  // linkID = localStorage.getItem('community_id') ?? '';
  userEdit: any = {};
  editRowAnchor = null;
  editRowWidth = 0;
  editRowMaxWidth = 0;
  triggerUpdateList = false;

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, {
      root: false,
    });
  }

  setSearchParams = (data) => {
    this.searchParams = data;
    this.triggerUpdateList = true;
  };

  getSearchParams = () => {
    return this.searchParams;
  };

  clearSearchParams = () => {
    this.searchParams = {};
  };

  setDepartments = (data) => {
    this.departments = data;
  };

  setSecondaryDepartments = (data) => {
    this.secondaryDepartments = data;
  };

  getDepartments = () => {
    return this.departments;
  };

  getSecondaryDepartments = () => {
    return this.secondaryDepartments;
  };

  *getUsers(hasParams: any = {}) {
    let extraRoleSearch = "";
    if (Object.keys(this.searchParams).length) {
      this.clearSearchParams();
    }
    if (hasParams?.role?.length) {
      const role = hasParams?.role;
      role.forEach((el, index) => {
        if (index === 0) {
          extraRoleSearch = `/?role=${el}`;
        } else {
          extraRoleSearch += `&role=${el}`;
        }
      });
      delete hasParams.role;
    }
    const response = yield Api.get(
      `${this.path}s${extraRoleSearch ? extraRoleSearch : ""}`,
      hasParams
    );
    return response?.data;
  }

  *exportUsers(hasParams: any = {}) {
    let extraRoleSearch = "";
    if (Object.keys(this.searchParams).length) {
      this.clearSearchParams();
    }
    if (hasParams?.role?.length) {
      const role = hasParams?.role;
      role.forEach((el, index) => {
        if (index === 0) {
          extraRoleSearch = `/?role=${el}`;
        } else {
          extraRoleSearch += `&role=${el}`;
        }
      });
      delete hasParams.role;
    }
    const response = yield Api.get(
      `${this.path}s/export${extraRoleSearch ? extraRoleSearch : ""}`,
      hasParams
    );
    return response?.data;
  }

  *getCommunityDepartments(communityID) {
    const response = yield Api.get(`${this.path}/departments/${communityID}`);
    return response?.data;
  }

  *createUser(payload: any) {
    const response = yield Api.post(this.path, payload);
    return response?.data;
  }

  *updateUser(payload: any, userID) {
    const response = yield Api.put(`${this.path}/${userID}`, payload);
    return response?.data;
  }

  *deleteUser(userID) {
    const response = yield Api.delete(`${this.path}/${userID}`);
    return response?.data;
  }

  *deleteAllUsers() {
    const response = yield Api.delete(`${this.path}/delete/all`);
    return response?.data;
  }

  *toggleActiveUser(userID) {
    const response = yield Api.put(`${this.path}/activate/${userID}`, {});
    return response?.data;
  }

  *importUsers(file, searchParams) {
    let urlParams = "";
    const searchKeys = Object.keys(searchParams);
    if (searchKeys.length) {
      searchKeys.forEach((key, index) => {
        urlParams += `${index === 0 ? "/?" : "&"}${key}=${searchParams[key]}`;
      });
    }
    const response = yield Api.post(
      `${this.path}/import/new${urlParams}`,
      file
    );
    return response?.data;
  }

  *inviteThroughEmail(payload) {
    const response = yield Api.post(`${this.path}/invite/email`, payload);
    return response?.data;
  }

  *inviteThroughSMS(payload) {
    const response = yield Api.post(`${this.path}/invite/sms`, payload);
    return response?.data;
  }

  *approveUser(userID, payload) {
    const response = yield Api.put(`${this.path}/approve/${userID}`, payload);
    return response?.data;
  }

  *approveAllUsers(payload) {
    const response = yield Api.put(`${this.path}/approve/all`, payload);
    return response?.data;
  }

  *deleteAllApprovals() {
    const response = yield Api.delete(`${this.path}/approve/delete/all`);
    return response?.data;
  }

  *getMessages() {
    const response = yield Api.get(`${this.path}/messages?limit=25`);
    return response?.data;
  }

  *readMessages() {
    const response = yield Api.put(`${this.path}/messages/read`, {});
    return response?.data;
  }
}
