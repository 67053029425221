import * as Yup from 'yup';

export const FIELDS = {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    PHONE: 'phone',
    EMAIL: 'email',
    IS_APPROVED: 'is_approved',
    ADMIN: 'admin',
    CHIEF_EDITOR: 'chief_editor',
    EDITOR: 'editor',
    USER: 'user',
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.FIRST_NAME]: Yup.string(),
    [FIELDS.LAST_NAME]: Yup.string(),
    [FIELDS.PHONE]: Yup.string(),
    [FIELDS.EMAIL]: Yup.string(),
    [FIELDS.IS_APPROVED]: Yup.boolean(),
    [FIELDS.ADMIN]: Yup.boolean(),
    [FIELDS.CHIEF_EDITOR]: Yup.boolean(),
    [FIELDS.EDITOR]: Yup.boolean(),
    [FIELDS.USER]: Yup.boolean(),
});
