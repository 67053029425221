import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DashDashIcon from "../../../assets/icons/dash-dash.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import contentPhone from "../../../assets/images/settings/contentPhone_empty.png";
import benefitPhone from "../../../assets/images/settings/benefitPhone_empty.png";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import Spinner from "../../../components/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import UploadFile from "../../../components/UploadFile/UploadFile";
import ProceedImage from "../../../shared/utils/proceedImage";
import ImageIcon from "../../../assets/icons/image.svg";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import UserTypeEnum from "../../../models/UserType";

const ManageCategories = () => {
  const { t } = useTranslation();
  const { contentStore, benefitStore, userStore } = useStore();

  const [isLoading, setIsLoading] = useState(true);
  const [activeContentInPreview, setActiveContentInPreview] = useState<any>({});
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [platformContentList, setPlatformContentList] = useState<any>([]);
  const [communityContentList, setCommunityContentList] = useState<any>([]);
  const [platformBenefitList, setPlatformBenefitList] = useState<any>([]);
  const [communityBenefitList, setCommunityBenefitList] = useState<any>([]);
  const [roleType, setRoleType] = useState(null);

  const handleOnDragEnd = (list, setList) => (result) => {
    if (!result.destination) return;
    let items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items = items.map((item: any, index) => {
      return { ...item, isEdited: item.order !== index, order: index }
    })
    setList(items);
  }

  const handleFileUpload = async (data, itemIndex) => {
    if (data) {
      const image: any = await ProceedImage.toBase64(data);
      if (roleType === UserTypeEnum.owner) {
        setPlatformBenefitList(
          platformBenefitList.map((el, index) => {
            if (index === itemIndex) {
              el["categoryImage"] = image;
              if (el.id) {
                el.isEdited = true;
              }
            }
            return el;
          })
        );
      } else {
        setCommunityBenefitList(
          communityBenefitList.map((el, index) => {
            if (index === itemIndex) {
              el["categoryImage"] = image;
              if (el.id) {
                el.isEdited = true;
              }
            }
            return el;
          })
        );
      }
    }
  };

  const handleFlow = async (flow, path) => {
    setIsBtnClicked(true);
    setIsLoading(true);
    await flowResult(flow).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        setIsLoading(false);
        setIsBtnClicked(false);
        return;
      }
      setButtonText(t("common.buttons.close"));
      setNotificationMessage(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0")
      );
      setOpenNotificationModal(true);
      setIsLoading(false);
      setIsBtnClicked(false);
    });
  };

  useEffect(() => {
    const platformContents = contentStore.getStoredPlatformContents() || [];
    const communityContents = contentStore.getStoredCommunityContents() || [];
    setPlatformContentList(platformContents);
    setCommunityContentList(communityContents);
    setActiveContentInPreview(platformContents?.[0] ?? communityContents?.[0] ?? {});
    setPlatformBenefitList(benefitStore.getStoredPlatformBenefits() || []);
    setCommunityBenefitList(benefitStore.getStoredCommunityBenefits() || []);
    setRoleType(userStore.getRoleType());
    setIsLoading(false);
  }, []);

  const handleAddContent = (list, setList) => {
    const newOrder = list.length ? list[list.length - 1].order + 1 : 0;
    const newContent = {
      id: `${newOrder}`,
      order: newOrder,
      contentName: "",
      isEdited: false,
      isNew: true,
    };
    setList([...list, newContent]);
    setActiveContentInPreview(newContent);
  };

  const handleAddBenefit = (list, setList) => {
    const newOrder = list.length ? list[list.length - 1].order + 1 : 0;
    const newContent = {
      id: `${newOrder}`,
      order: newOrder,
      categoryImage: "",
      categoryName: "",
      isEdited: false,
      isNew: true,
    };
    setList([...list, newContent]);
  };

  const handleChange = (itemIndex, list, setList, key) => (e) => {
    setList(
      list.map((el, index) => {
        if (index === itemIndex) {
          el[key] = e.target.value;
          if (el.id) {
            el.isEdited = true;
          }
        }
        return el;
      })
    );
  };

  const handleDeleteItem = async (itemIndex, list, path) => {
    setIsLoading(true);
    if (list[itemIndex].id && list[itemIndex].id !== list[itemIndex].order) {
      const item = list[itemIndex];
      if (path === "content") {
        await flowResult(contentStore.delete(item.id, roleType)).then((response: any) => {
          if (!response?.success) {
            handleNotificationOpen(
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            setIsLoading(false);
            return;
          }
          if (roleType === UserTypeEnum.owner) {
            setPlatformContentList(contentStore.getStoredPlatformContents());
          } else {
            setCommunityContentList(contentStore.getStoredCommunityContents());
          }
          setIsLoading(false);
        });
      }
      if (path === "benefit") {
        await flowResult(benefitStore.delete(item.id, roleType)).then((response: any) => {
          if (!response?.success) {
            handleNotificationOpen(
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            setIsLoading(false);
            return;
          }
          if (roleType === UserTypeEnum.owner) {
            setPlatformBenefitList(benefitStore.getStoredPlatformBenefits());
          } else {
            setCommunityBenefitList(benefitStore.getStoredCommunityBenefits());
          }
          setIsLoading(false);
        });
      }
    } else {
      if (path === "content") {
        if (roleType === UserTypeEnum.owner) {
          setPlatformContentList(
            platformContentList.filter((el, index) => {
              return index !== itemIndex;
            })
          );
        } else {
          setCommunityContentList(
            communityContentList.filter((el, index) => {
              return index !== itemIndex;
            })
          );
        }
      } else {
        if (roleType === UserTypeEnum.owner) {
          setPlatformBenefitList(
            platformBenefitList.filter((el, index) => {
              return index !== itemIndex;
            })
          );
        } else {
          setCommunityBenefitList(
            communityBenefitList.filter((el, index) => {
              return index !== itemIndex;
            })
          );
        }
      }
      setIsLoading(false);
    }
  };

  const updateLists = async () => {
    await flowResult(contentStore.getContents()).then(
      (response: any) => {
        if (!response?.success) {
          console.error(`Error while getting Content Categories information `);
          return;
        }
        if (roleType === UserTypeEnum.owner) {
          setPlatformContentList(contentStore.getStoredPlatformContents());
        } else {
          setCommunityContentList(contentStore.getStoredCommunityContents());
        }
      }
    )
    await flowResult(benefitStore.getBenefits()).then(
      (response: any) => {
        if (!response?.success) {
          console.error(`Error while getting Benefit Categories information `);
          return;
        }
        if (roleType === UserTypeEnum.owner) {
          setPlatformBenefitList(benefitStore.getStoredPlatformBenefits());
        } else {
          setCommunityBenefitList(benefitStore.getStoredCommunityBenefits());
        }
      }
    )
  }

  const handleSubmitCheck = async (e) => {
    e.preventDefault();
    const contentList = roleType === UserTypeEnum.owner ? platformContentList : communityContentList;
    const benefitList = roleType === UserTypeEnum.owner ? platformBenefitList : communityBenefitList;
    const awaitCategories = [];
    contentList.forEach((el) => {
      if (el.contentName) {
        const data = {
          order: el.order,
          content_name: el.contentName,
        };
        const path = "CONTENT";
        if (el.isNew) {
          awaitCategories.push(handleFlow(contentStore.create(data, roleType), path));
        } else if (el.isEdited) {
          awaitCategories.push(handleFlow(contentStore.update(data, el.id, roleType), path));
        }
      }
    });
    benefitList.forEach((el) => {
      if (el.categoryName) {
        const data: any = {
          order: el.order,
          category_image: el.categoryImage,
          category_name: el.categoryName,
        };
        if (!data.category_image) {
          delete data["category_image"];
        }
        const path = "BENEFIT";
        if (el.isNew) {
          awaitCategories.push(handleFlow(benefitStore.create(data, roleType), path));
        } else if (el.isEdited) {
          awaitCategories.push(handleFlow(benefitStore.update(data, el.id, roleType), path));
        }
      }
    });
    await Promise.all([...awaitCategories]);
    await updateLists();
    // if (roleType === UserTypeEnum.owner) {
    //   contentStore.updatePlatformContents(contentList);
    //   benefitStore.updatePlatformBenefits(benefitList);
    // } else {
    //   contentStore.updateCommunityContents(contentList);
    //   benefitStore.updateCommunityBenefits(benefitList);
    // }
  };

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [itemDeletion, setItemDeletion] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [itemPath, setItemPath] = useState("");

  const handleNotificationOpen = (message = "", id = 0, itemPath = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    if (!message) {
      setButtonText(t("common.buttons.yes"));
      setNotificationMessage(t("errorMessages.deleteQuestion"));
      setItemID(id);
      setItemPath(itemPath);
      setItemDeletion(true);
    }
    setOpenNotificationModal(true);
  };

  const handleNotificationClose =
    (isDelete = false) =>
      () => {
        if (isDelete) {
          if (itemPath === "content") {
            handleDeleteItem(itemID, roleType === UserTypeEnum.owner ? platformContentList : communityContentList, itemPath);
          }
          if (itemPath === "benefit") {
            handleDeleteItem(itemID, roleType === UserTypeEnum.owner ? platformBenefitList : communityBenefitList, itemPath);
          }
          setItemDeletion(false);
          setItemID(0);
          setItemPath("");
        }
        setOpenNotificationModal(false);
      };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper categories-page">
      <h2 className="page-heading">
        {t("settings.manageCategories.categories")}
      </h2>
      <form className="" autoComplete="off" onSubmit={handleSubmitCheck}>
        <div className="main-wrapper">
          <div className="content-header">
            <h3>{t("settings.manageCategories.content")}</h3>
            {/* <HelpIcon className="help-icon hover-item" /> */}
          </div>
          <Grid container className="content-grid" justifyContent="space-between">
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={11} className="column-headers">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={1} className="column-name text-center">
                      {/* <span>{t("settings.manageCategories.order")}</span> */}
                    </Grid>
                    <Grid item xs={4} className="column-name">
                      <span>{t("settings.manageCategories.categoryName")}</span>
                    </Grid>
                    <Grid item xs={3} lg={4} />
                    <Grid item xs={2} lg={1} />
                  </Grid>
                </Grid>
                {
                  platformContentList.length ?
                    (
                      <>
                        <Grid item xs={12}>
                          {
                            roleType === UserTypeEnum.owner ? (
                              <DragDropContext onDragEnd={handleOnDragEnd(platformContentList, setPlatformContentList)}>
                                <Droppable droppableId="contents">
                                  {(provided) => (
                                    <Grid container className="drag-drop-container" {...provided.droppableProps} ref={provided.innerRef}>
                                      {platformContentList.map((content, index) => {
                                        if (!content) return null;
                                        return (
                                          <Draggable key={content.id} draggableId={content.id} index={index}>
                                            {(provided) => (
                                              <Grid item xs={11} className="item-box" ref={provided.innerRef} {...provided.draggableProps}>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={1} className="text-center" {...provided.dragHandleProps}>
                                                    <div
                                                      aria-label="reorder item"
                                                      className="hover-item grab-icon"
                                                    >
                                                      <IconComponent icon={DashDashIcon} />
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                    <TextField
                                                      id="content-1"
                                                      label={t("community.list.name")}
                                                      placeholder={t(
                                                        "settings.manageCategories.categoryName"
                                                      )}
                                                      InputLabelProps={{ shrink: true }}
                                                      value={content["contentName"]}
                                                      onChange={handleChange(
                                                        index,
                                                        platformContentList,
                                                        setPlatformContentList,
                                                        "contentName"
                                                      )}
                                                      required
                                                    />
                                                    <p className="field-error">
                                                      {content["contentName"].length > 20
                                                        ? t("validationMessages.articles.addArticle.maxHeader", { max: 20 })
                                                        : ""}&nbsp;
                                                    </p>
                                                  </Grid>
                                                  <Grid item xs={3} lg={4} />
                                                  <Grid item xs={2} lg={1} className="text-center">
                                                    <IconButton
                                                      aria-label="delete current item"
                                                      className="hover-item"
                                                      onClick={() =>
                                                        handleNotificationOpen("", index, "content")
                                                      }
                                                    >
                                                      <IconComponent icon={DeleteIcon} />
                                                    </IconButton>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </Grid>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            ) : (
                              <Grid container className="drag-drop-container">
                                {platformContentList.map((content, index) => {
                                  if (!content) return null;
                                  return (
                                    <Grid key={index} item xs={11} className="item-box">
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={1} />
                                        <Grid item xs={4}>
                                          <p>{content["contentName"]}</p>
                                        </Grid>
                                        <Grid item xs={3} lg={4} />
                                        <Grid item xs={2} lg={1} />
                                      </Grid>
                                    </Grid>

                                  );
                                })}
                              </Grid>
                            )
                          }
                        </Grid>
                      </>
                    ) : null
                }
                {
                  communityContentList.length ?
                    (
                      <>
                        <Grid item xs={12}>
                          <h3>{t('tabNavigation.community.mainTab')}</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <DragDropContext onDragEnd={handleOnDragEnd(communityContentList, setCommunityContentList)}>
                            <Droppable droppableId="contents">
                              {(provided) => (
                                <Grid container className="drag-drop-container" {...provided.droppableProps} ref={provided.innerRef}>
                                  {communityContentList.map((content, index) => {
                                    if (!content) return null;
                                    return (
                                      <Draggable key={content.id} draggableId={content.id} index={index}>
                                        {(provided) => (
                                          <Grid item xs={11} className="item-box" ref={provided.innerRef} {...provided.draggableProps}>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <Grid item xs={1} className="text-center" {...provided.dragHandleProps}>
                                                <div
                                                  aria-label="reorder item"
                                                  className="hover-item grab-icon"
                                                >
                                                  <IconComponent icon={DashDashIcon} />
                                                </div>
                                              </Grid>
                                              <Grid item xs={4}>
                                                <TextField
                                                  id="content-1"
                                                  label={t("community.list.name")}
                                                  placeholder={t(
                                                    "settings.manageCategories.categoryName"
                                                  )}
                                                  InputLabelProps={{ shrink: true }}
                                                  value={content["contentName"]}
                                                  onChange={handleChange(
                                                    index,
                                                    communityContentList,
                                                    setCommunityContentList,
                                                    "contentName"
                                                  )}
                                                  required
                                                />
                                                <p className="field-error">
                                                  {content["contentName"].length > 20
                                                    ? t("validationMessages.articles.addArticle.maxHeader", { max: 20 })
                                                    : ""}&nbsp;
                                                </p>
                                              </Grid>
                                              <Grid item xs={3} lg={4} />
                                              <Grid item xs={2} lg={1} className="text-center">
                                                <IconButton
                                                  aria-label="delete current item"
                                                  className="hover-item"
                                                  onClick={() =>
                                                    handleNotificationOpen("", index, "content")
                                                  }
                                                >
                                                  <IconComponent icon={DeleteIcon} />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </Grid>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Grid>
                      </>
                    ) : null
                }
                <Grid
                  item
                  xs={7}
                  className="add-item-box hover-item"
                  onClick={() => handleAddContent(
                    roleType === UserTypeEnum.owner ? platformContentList : communityContentList,
                    roleType === UserTypeEnum.owner ? setPlatformContentList : setCommunityContentList
                  )}
                >
                  <AddIcon />
                  <span>{t("settings.myCommunity.addCategory")}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} lg={3} className="content-preview-container">
              <img
                src={contentPhone}
                alt="contentPhone"
                className="benefit-phone"
              />
              <ScrollContainer className="content-container">
                {platformContentList.concat(communityContentList).map((content, index) => {
                  const name = content.contentName;
                  const isActive =
                    activeContentInPreview.contentName === name &&
                    activeContentInPreview.order === content.order;
                  return (
                    <div
                      key={index}
                      className={`category ${isActive ? "active" : ""}`}
                      onClick={() => setActiveContentInPreview(content)}
                    >
                      {name}
                    </div>
                  );
                })}
              </ScrollContainer>
              <div className="displayed-content">
                {activeContentInPreview?.contentName?.length > 14
                  ? `${activeContentInPreview.contentName.substring(0, 14)}...`
                  : activeContentInPreview?.contentName ?? ""}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="main-wrapper">
          <div className="content-header">
            <h3>{t("settings.manageCategories.benefits")}</h3>
            {/* <HelpIcon className="help-icon hover-item" /> */}
          </div>
          <Grid container className="content-grid" justifyContent="space-between">
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={11} className="column-headers">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={1} className="column-name text-center">
                      {/* <span>{t("settings.manageCategories.order")}</span> */}
                    </Grid>
                    <Grid item xs={2} lg={1} className="column-name text-center" />
                    <Grid item xs={4} className="column-name">
                      <span>{t("settings.manageCategories.categoryName")}</span>
                    </Grid>
                    <Grid item xs={1} lg={4} className="column-name" />
                    <Grid item xs={2} lg={1} />
                  </Grid>
                </Grid>
                {
                  platformBenefitList.length ?
                    (
                      <>
                        <Grid item xs={12}>
                          {
                            roleType === UserTypeEnum.owner ? (
                              <DragDropContext onDragEnd={handleOnDragEnd(platformBenefitList, setPlatformBenefitList)}>
                                <Droppable droppableId="benefits">
                                  {(provided) => (
                                    <Grid container className="drag-drop-container" {...provided.droppableProps} ref={provided.innerRef}>
                                      {platformBenefitList.map((content, index) => {
                                        if (!content) return null;
                                        return (
                                          <Draggable key={content.id} draggableId={content.id} index={index}>
                                            {(provided) => (
                                              <Grid item xs={11} className="item-box" ref={provided.innerRef} {...provided.draggableProps}>
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={1} className="text-center" {...provided.dragHandleProps}>
                                                    <div
                                                      aria-label="reorder item"
                                                      className="hover-item grab-icon"
                                                    >
                                                      <IconComponent icon={DashDashIcon} />
                                                    </div>
                                                  </Grid>
                                                  <Tooltip title={t("informationMessages.imageOnly")} placement="bottom" arrow>
                                                    <Grid item xs={2} lg={1} className="image-container">
                                                      <UploadFile
                                                        text=""
                                                        id={`${index}`}
                                                        handleFileUpload={(data) =>
                                                          handleFileUpload(data, index)
                                                        }
                                                        showImage={!!content?.categoryImage}
                                                        image={content?.categoryImage ?? ""}
                                                        width={40}
                                                        height={40}
                                                        demoIcon={ImageIcon}
                                                        hoverOn={!!content?.categoryImage}
                                                      />
                                                    </Grid>
                                                  </Tooltip>
                                                  <Grid item xs={4}>
                                                    <TextField
                                                      id="content-1"
                                                      label={t("community.list.name")}
                                                      placeholder={t(
                                                        "settings.manageCategories.categoryName"
                                                      )}
                                                      InputLabelProps={{ shrink: true }}
                                                      value={content["categoryName"]}
                                                      onChange={handleChange(
                                                        index,
                                                        platformBenefitList,
                                                        setPlatformBenefitList,
                                                        "categoryName"
                                                      )}
                                                      required
                                                    />
                                                    <p className="field-error">
                                                      {content["categoryName"].length > 20
                                                        ? t("validationMessages.articles.addArticle.maxHeader", { max: 20 })
                                                        : ""}&nbsp;
                                                    </p>
                                                  </Grid>
                                                  <Grid item xs={1} lg={4} />
                                                  <Grid item xs={2} lg={1} className="text-center">
                                                    <IconButton
                                                      aria-label="delete current item"
                                                      className="hover-item"
                                                      onClick={() =>
                                                        handleNotificationOpen("", index, "benefit")
                                                      }
                                                    >
                                                      <IconComponent icon={DeleteIcon} />
                                                    </IconButton>
                                                  </Grid>

                                                </Grid>
                                              </Grid>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </Grid>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            ) : (
                              <Grid container className="drag-drop-container">
                                {platformBenefitList.map((content, index) => {
                                  if (!content) return null;
                                  return (
                                    <Grid key={index} item xs={11} className="item-box">
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item xs={1} />
                                        <Grid item xs={2} lg={1} className="image-container preview">
                                          {
                                            content.categoryImage ? (<img src={content.categoryImage} alt={content["categoryName"]} />) : null
                                          }
                                        </Grid>
                                        <Grid item xs={4}>
                                          <p>{content["categoryName"]}</p>

                                        </Grid>
                                        <Grid item xs={1} lg={4} />
                                        <Grid item xs={2} lg={1} className="text-center" />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )
                          }
                        </Grid>
                      </>
                    ) : null
                }
                {
                  communityBenefitList.length ?
                    (
                      <>
                        <Grid item xs={12}>
                          <h3>{t('tabNavigation.community.mainTab')}</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <DragDropContext onDragEnd={handleOnDragEnd(communityBenefitList, setCommunityBenefitList)}>
                            <Droppable droppableId="benefits">
                              {(provided) => (
                                <Grid container className="drag-drop-container" {...provided.droppableProps} ref={provided.innerRef}>
                                  {communityBenefitList.map((content, index) => {
                                    if (!content) return null;
                                    return (
                                      <Draggable key={content.id} draggableId={content.id} index={index}>
                                        {(provided) => (
                                          <Grid item xs={11} className="item-box" ref={provided.innerRef} {...provided.draggableProps}>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <Grid item xs={1} className="text-center" {...provided.dragHandleProps}>
                                                <div
                                                  aria-label="reorder item"
                                                  className="hover-item grab-icon"
                                                >
                                                  <IconComponent icon={DashDashIcon} />
                                                </div>
                                              </Grid>
                                              <Tooltip title={t("informationMessages.imageOnly")} placement="bottom" arrow>
                                                <Grid item xs={2} lg={1} className="image-container">
                                                  <UploadFile
                                                    text=""
                                                    id={`${index}`}
                                                    handleFileUpload={(data) =>
                                                      handleFileUpload(data, index)
                                                    }
                                                    showImage={!!content?.categoryImage}
                                                    image={content?.categoryImage ?? ""}
                                                    width={40}
                                                    height={40}
                                                    demoIcon={ImageIcon}
                                                    hoverOn={!!content?.categoryImage}
                                                  />
                                                </Grid>
                                              </Tooltip>
                                              <Grid item xs={4}>
                                                <TextField
                                                  id="content-1"
                                                  label={t("community.list.name")}
                                                  placeholder={t(
                                                    "settings.manageCategories.categoryName"
                                                  )}
                                                  InputLabelProps={{ shrink: true }}
                                                  value={content["categoryName"]}
                                                  onChange={handleChange(
                                                    index,
                                                    communityBenefitList,
                                                    setCommunityBenefitList,
                                                    "categoryName"
                                                  )}
                                                  required
                                                />
                                                <p className="field-error">
                                                  {content["categoryName"].length > 20
                                                    ? t("validationMessages.articles.addArticle.maxHeader", { max: 20 })
                                                    : ""}&nbsp;
                                                </p>
                                              </Grid>
                                              <Grid item xs={1} lg={4} />
                                              <Grid item xs={2} lg={1} className="text-center">
                                                <IconButton
                                                  aria-label="delete current item"
                                                  className="hover-item"
                                                  onClick={() =>
                                                    handleNotificationOpen("", index, "benefit")
                                                  }
                                                >
                                                  <IconComponent icon={DeleteIcon} />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </Grid>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Grid>
                      </>
                    ) : null
                }
                <Grid
                  item
                  xs={7}
                  className="add-item-box hover-item"
                  onClick={() => handleAddBenefit(
                    roleType === UserTypeEnum.owner ? platformBenefitList : communityBenefitList,
                    roleType === UserTypeEnum.owner ? setPlatformBenefitList : setCommunityBenefitList
                  )}
                >
                  <AddIcon />
                  <span>{t("settings.myCommunity.addCategory")}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} lg={3} className="benefits-preview-container">
              <img
                src={benefitPhone}
                className="benefit-phone"
                alt="benefitPhone"
              />
              <div className="benefits-preview">
                <div className="category-container">
                  {platformBenefitList.concat(communityBenefitList).map((cat: any, index) => {
                    return (
                      <div key={index} className="category">
                        {cat?.categoryImage ? (
                          <img src={cat.categoryImage} alt={cat?.categoryName ?? ""} />
                        ) : (
                          <div className="no-image" />
                        )}

                        <div className="flex-center">
                          <span className="category-text">{cat?.categoryName ?? ""}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="submit-buttons">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              (platformContentList.concat(communityContentList).length === 0 && platformBenefitList.concat(communityBenefitList).length === 0)
              || platformContentList.concat(communityContentList).filter(el => !el.contentName || el.contentName.length > 20).length > 0
              || platformBenefitList.concat(communityBenefitList).filter(el => !el.categoryName || el.categoryName.length > 20).length > 0
              || isBtnClicked
            }
          >
            {t("common.buttons.save")}
          </Button>
        </div>
      </form>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose(false)}
        handleButtonClick={handleNotificationClose(itemDeletion)}
        buttonText={buttonText}
        message={notificationMessage}
      />
    </div>
  );
};

export default observer(ManageCategories);
