import { TextField } from '@material-ui/core'
import React from 'react'
import "./style.scss";

export interface Props {
    onChange: any;
    handlePlusMinus: any;
    className?: string;
    defaultValue?: number;
    label?: string;
    value?: number;
}

const NumberField = ({onChange, handlePlusMinus, className, value = 0, label}: Props) => {

    return (
        <div className="number-field">
            <TextField
                className={`input ${className}`}
                type="number"
                InputLabelProps={{ shrink: true }}
                label={label}
                value={value}
                onChange={(e) => onChange(Number(e.target.value))}
            />
            <div className="change-value-icon increase" onClick={() => handlePlusMinus('increase')}>
                <div>+</div>
            </div>
            <div className="change-value-icon decrease" onClick={() => handlePlusMinus('decrease')}>
                <div>-</div>
            </div>
        </div>
    )
}

export default NumberField
