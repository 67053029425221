import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  COMMUNITY_NAME: "community_name",
  GROUP_ID: "group_id",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PHONE: "phone",
  EMAIL: "email",
  PASSWORD: "password",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.COMMUNITY_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.edit.minHeader"))
    .required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.GROUP_ID]: Yup.string().required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.FIRST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.edit.minHeader"))
    .max(15, () => i18n.t("validationMessages.community.edit.maxHeader"))
    .required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.LAST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.edit.minHeader"))
    .max(15, () => i18n.t("validationMessages.community.edit.maxHeader"))
    .required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.PHONE]: Yup.string()
    .min(11, () => i18n.t("validationMessages.community.edit.phone"))
    .max(11, () => i18n.t("validationMessages.community.edit.phone"))
    .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () => i18n.t("validationMessages.auth.signUp.phonePattern"))
    .required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.EMAIL]: Yup.string()
    .email(() => i18n.t("validationMessages.community.edit.email"))
    .required(() => i18n.t("validationMessages.community.edit.required")),
  [FIELDS.PASSWORD]: Yup.string().notRequired(),
});
