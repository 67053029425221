import React from "react"
import ContentLoader from "react-content-loader"

const ActionCardLoader = () => (
    <ContentLoader
        speed={2}
        width={377}
        height={298}
        viewBox="0 0 377 298"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title={''}
    >
        <rect x="76" y="30" rx="100" ry="100" width="125" height="125" />
        <rect x="90" y="200" rx="0" ry="0" width="92" height="8" />
    </ContentLoader>
)

export default ActionCardLoader

