import DateFnsUtils from '@date-io/date-fns';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, Input, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import SearchIcon from '../../LeftSideBar/Icons/SearchIcon/SearchIcon';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/StoreContext';
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import Spinner from '../../Spinner/Spinner';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIELDS, VALIDATIONS } from "./fields";
import UserTypeEnum from '../../../models/UserType';

const SearchPromo = () => {
    const { i18n, t } = useTranslation();
    const { languageStore, pushStore, groupStore, departmentStore, userStore } = useStore();
    const [isLoading, setIsLoading] = useState(false);

    const [groups, setGroups] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setGroups(groupStore.getStoredGroups())
        setDepartments(departmentStore.getStoredDepartments());
        setRoleType(userStore.getRoleType());
        setIsLoading(false);
    }, [])

    const handleSubmitCheck = (objectData) => {
        const data = {
            ...objectData,
            "sent_to": objectData["sent_to"] !== 'all' ? objectData["sent_to"] : '',
        }
        pushStore.setSearchParams(data);
    }

    const convertToDate = (date) => {
        if (!date) {
            return;
        }
        const locale = 'en-US'
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const {
        register,
        handleSubmit,
        control,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        defaultValues: {
            'search': '',
            'sent_to': '',
            'from_date': convertToDate(new Date()),
            'to_date': convertToDate(new Date(new Date().setDate(new Date().getDate() + 1))),
        }
    });

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng])

    return isLoading ? (<Spinner className="search-spinner" />) : (
        <div className="search-panel">
            <form className="" autoComplete="off" onSubmit={handleSubmit((d) => handleSubmitCheck(d))}>
                <div className="main-wrapper">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lngDirection === 'rtl' ? heLocale : enLocale}>
                        <Grid container className="view-grid" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <InputLabel htmlFor="search-field">{t('common.fieldHeaders.search')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name="search"
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    id="search-field"
                                                    value={value}
                                                    onChange={onChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="sent_to"
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <FormControl>
                                                        <InputLabel id="select-community">{t('push.promoNotification.sentTo')}</InputLabel>
                                                        <Select
                                                            labelId="select-community"
                                                            id="select-filter"
                                                            value={value}
                                                            onChange={onChange}
                                                        >
                                                            <MenuItem value="all">All</MenuItem>
                                                            {
                                                                roleType === UserTypeEnum.owner ?
                                                                    groups.map((el: any, index) => {
                                                                        return (<MenuItem key={index} value={el.id}>{el.name}</MenuItem>)
                                                                    }) :
                                                                    departments.map((el: any, index) => {
                                                                        return (<MenuItem key={index} value={el.id}>{el.name}</MenuItem>)
                                                                    })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row search-date-first">
                                    <p className="search-panel-subTitle">{t('common.fieldHeaders.publishedOn')}</p>
                                    <Controller
                                        control={control}
                                        name="from_date"
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="publishDate-picker-inline"
                                                className="date-picker-inline"
                                                label={t('common.fieldHeaders.fromDate')}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onChange={(value) => {
                                                    props.field.onChange(convertToDate(value));
                                                }}
                                                value={props.field.value}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item className="grid-row">
                                    <Controller
                                        control={control}
                                        name="to_date"
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                // minDate={getValues('from_date')}
                                                minDateMessage={t('errorMessages.minimumDate')}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="publishDate-picker-inline"
                                                className="date-picker-inline"
                                                label={t('common.fieldHeaders.toDate')}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onChange={(value) => {
                                                    props.field.onChange(convertToDate(value));
                                                }}
                                                value={props.field.value}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item className="grid-row search-button-container">
                                    <Button variant="contained" color="primary" type="submit">{t('common.fieldHeaders.search')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
            </form>
        </div>
    )
}

export default SearchPromo
