import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  EMAIL: "email",
  // PLATFORM_ID: "platform_id",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.EMAIL]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .email(() => i18n.t("validationMessages.auth.signUp.email")),
  // [FIELDS.PLATFORM_ID]: Yup.string().required(() =>
  //   i18n.t("validationMessages.articles.addArticle.required")
  // ),
});
