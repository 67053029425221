import React from 'react'
import ButtomWithIcon from '../../../../shared/components/ButtomWithIcon/ButtomWithIcon';
import "./DraftComponent.scss";
import rightArrow from "../../../../assets/icons/right-arrow.svg";
import RoundedImage from '../../../../shared/components/RoundedImage/RoundedImage';
import ListRowComponent from '../../../../shared/components/ListRow/ListRow';
import DraftsLoader from '../../../../shared/components/Loaders/DraftsLoader/DraftsLoader';
import { useHistory } from 'react-router';

export interface DraftComponentProps {
    imgSrc?: string;
    tag?: string;
    shortInfo?: string;
    isLoading?: boolean;
    openModal?: any;
    isBanner?: boolean;
    id?: string;
    template?: number;
}
const Draft = (props: DraftComponentProps) => {
    const { imgSrc, tag, shortInfo } = props;
    const history = useHistory();
    const isMobile = window.innerWidth < 736;

    const handleGoTo = () => {
        if (isMobile) return;
        if (props.isBanner) {
            history.push(`/articles/edit/banner/${props.id}`)
        } else {
            history.push(`/articles/edit/article/${props.id}`)
        }
    }
    return (
        <ListRowComponent>

            <div className="draft-item-container" >
                <div className={`draft-item-content-wrapper  ${!props.isLoading && 'show'}`} onClick={handleGoTo}>
                    {
                        props?.template !== 3 ? (<RoundedImage imgSrc={imgSrc} />) : (<div className={`image-container draft-image`} />)
                    }
                    
                    
                    <div className="draft-into-wrapper">
                        <div className="tag-text">{tag}</div>
                        <div className="short-info-text">{shortInfo}</div>

                    </div>
                    <div className="btn-wrapper">

                        <ButtomWithIcon icon={rightArrow} />
                    </div>
                </div>
                <div className={`draft-item-loader-wrapper ${!props.isLoading && 'hide'}`}>
                    <DraftsLoader />
                </div>

            </div>
        </ListRowComponent>
    )

}


export default Draft