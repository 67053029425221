import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class DepartmentStore {
  root: RootStore;

  path = "/admin/settings/department";
  departments: any = [];
  secondaryDepartments: any = [];

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, {
      root: false,
    });
  }

  getStoredDepartments() {
    return this.departments;
  }

  getStoredSecondaryDepartments() {
    return this.secondaryDepartments;
  }

  clearStore() {
    this.departments = [];
    this.secondaryDepartments = [];
  }

  *getDepartments() {
    const response = yield Api.get(this.path);
    if (
      response.ok &&
      response.data?.success &&
      response.data?.departments?.length
    ) {
      this.departments = response.data.departments || [];
      this.secondaryDepartments = response.data.secondaryDepartments || [];
    } else {
      this.departments = [];
      this.secondaryDepartments = [];
    }
    return response?.data;
  }

  *create(payload: any) {
    const response = yield Api.post(this.path, payload);
    if (response.ok && response.data?.success && response.data?.department) {
      if (response.data.department.isSecondary) {
        this.secondaryDepartments.push(response.data.department);
      } else {
        this.departments.push(response.data.department);
      }
    }
    return response?.data;
  }

  *update(payload: any, id) {
    const response = yield Api.put(`${this.path}/${id}`, payload);
    if (response.ok && response.data?.success) {
      if (response.data.department.isSecondary) {
        this.secondaryDepartments = this.secondaryDepartments.map((item) => {
          if (id === item.id) {
            return { ...item, ...payload };
          }
          return item;
        });
      } else {
        this.departments = this.departments.map((item) => {
          if (id === item.id) {
            return { ...item, ...payload };
          }
          return item;
        });
      }
    }
    return response?.data;
  }

  *delete(id, isSecondary: boolean = false) {
    const response = yield Api.delete(`${this.path}/${id}`);
    if (response.ok && response.data?.success) {
      if (isSecondary) {
        this.secondaryDepartments = this.secondaryDepartments.filter((item) => {
          return id !== item.id;
        });
      } else {
        this.departments = this.departments.filter((item) => {
          return id !== item.id;
        });
      }
    }
    return response?.data;
  }
}
