import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "./style.scss";
import { useTranslation } from "react-i18next";
import RangeDatePicker from "../../../components/RangeDatePicker/RangeDatePicker";
import { useStore } from "../../../store/StoreContext";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { observer } from "mobx-react-lite";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import { ClickAwayListener } from "@material-ui/core";

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const LineChart = () => {
  const { i18n, t } = useTranslation();
  const { languageStore } = useStore();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const { reportsStore } = useStore();

  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  useEffect(() => {
    getPushReports();
    // const onScroll = () => setOpenDatePicker(false);
    // document.body.querySelector('.App').addEventListener('scroll', onScroll)
    // return () => {
    //   document.body.querySelector('.App').removeEventListener('scroll', onScroll)
    // }
  }, []);

  const getPushReports = () => {
    setIsLoading(true);
    const date = mapDateForHttpReq(dateRange);

    flowResult(reportsStore.getPushRateForChart(date)).then((response: any) => {
      setShowChart(true);
      if (!response?.success) {
        setIsLoading(false);
        console.error(`Error while getting Purchases information`);
        return;
      }
      const reports = response.reports;
    
      if (reports.length === 0) {
        setShowChart(false);
      }

      const pushReportsHeaders = reports.map((report) => {
        return report?.articleHeader || report?.benefitHeader || "";
      });

      const pushReportsCount = reports.map((report) => {
        return report?.views;
      });

      setChartData(pushReportsCount);
      setChartLabels(pushReportsHeaders);
      setIsLoading(false);
    });
  };

  const convertToDate = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });

  const refreshChart = () => {
    getPushReports();
  };

  const data = (canvas) => {

    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 250);
    gradient.addColorStop(0, 'rgba(231,236,254,1)');   
    gradient.addColorStop(1, 'rgba(231,236,254,0)');

    return {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        fill: true,
        pointBackgroundColor: '#EFDA1F',
        tension: 0,
        backgroundColor: gradient || "#E7ECFF",
        borderColor: "#577BF9",
      },
    ],
  }
  };

  const handleDateChange = ({ startDate, endDate }, isClose = false) => {
    setDateRange({ startDate: startDate, endDate: endDate });
    mapDateForHttpReq({ startDate: startDate, endDate: endDate });
    if (isClose) {
      setOpenDatePicker(false);
      refreshChart();
    }
  };

  const mapDateForHttpReq = ({ startDate, endDate }) => {
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    const locale = "en-US";

    const sDateQuery = `${sDate.toLocaleDateString(locale, {
      year: "numeric",
    })}-${sDate.toLocaleDateString(locale, {
      month: "numeric",
    })}-${sDate.toLocaleDateString(locale, {
      day: "numeric",
    })}`;

    const eDateQuery = `${eDate.toLocaleDateString(locale, {
      year: "numeric",
    })}-${eDate.toLocaleDateString(locale, {
      month: "numeric",
    })}-${eDate.toLocaleDateString(locale, {
      day: "numeric",
    })}`;
    return { sDateQuery, eDateQuery };
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
        <div className="chart-header">
          <h2 className="title">{t("reports.openRatePush")}</h2>
          <div className="datepicker">
            <div className="dislayed-dates">
              <span onClick={() => toggleDatePicker()}>
                {t("reports.fromDate")}{" "}
                {dateRange?.startDate ? convertToDate(dateRange.startDate) : ""}
              </span>
              <span>-</span>
              <span onClick={() => toggleDatePicker()}>
                {t("reports.untilDate")}{" "}
                {dateRange?.endDate ? convertToDate(dateRange.endDate) : ""}
              </span>
            </div>
            <div className="range-datepicker">
              <RangeDatePicker
                value={dateRange}
                isOpened={openDatePicker}
                onChange={handleDateChange}
                locale={lngDirection === "rtl" ? heLocale : enLocale}
              />
            </div>
          </div>
        </div>
      </ClickAwayListener>
      {showChart ? (
        <Line height={258} width={1117} data={data} options={options} />
      ) : (
        <p>{t("reports.noOpenRatePush")}</p>
      )}
    </>
  );
};

export default observer(LineChart);
