import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import Spinner from "../../../components/Spinner/Spinner";
import { flowResult } from "mobx";
import { useStore } from "../../../store/StoreContext";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { useHistory, useLocation} from "react-router-dom";
import UserTypeEnum from "../../../models/UserType";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { formatToPhoneNumber } from "../../../shared/utils/sharedFunctions";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import i18n from "../../../i18n/i18n";

const CreateUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { usersPageStore, userStore } = useStore();
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [communities, setCommunities] = useState<any>([]);
  const [community, setCommunity] = useState<string>("");
  const [communityName, setCommunityName] = useState<string>(""); // to show community name in the form
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [role, setRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [firstSelectedCommunity, setFirstSelectedCommunity] = useState("");
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const { pathname } = useLocation(); // /communities/register?community_id=ckyn2jem01140893lnvpfri9eqlu&community_name=LS%20Techs
  const isRegisterPage = pathname.includes('/register'); // true
  const isCreatePage = pathname.includes('/create'); // true
  const [modalText, setModalText] = useState('common.buttons.close');
  const isMobile = window.innerWidth < 736;

  useEffect(() => {
    isCreatePage && setCommunities(userStore.getCommunities()); // []
  }, []);

  function extractParam(paramName) {
    return new URLSearchParams(window.location.search).get(paramName);
  } // extract community id and name from url 

  useEffect(() => {
    const role = userStore.getRoleType() ?? 'USER';
    const communityId = userStore.getCurrentCommunity().id ?? extractParam('community_id') ?? '';
    const communityName = userStore.getCurrentCommunity().communityName ?? extractParam('community_name') ?? '';
    setRole(role);
    if (role !== UserTypeEnum.owner) {
      setCommunity(communityId);
      setValue("community_id", communityId);
      setValue("admin_communities", [].concat(communityId));
      isCreatePage && getDepartmentsFlow(communityId);
      if(isRegisterPage) {
        setCommunityName(communityName);
        setValue("role", "USER");
        setValue("department_id", "ckyn2jem71140900lnvp31o8abff");
      }
    }
  }, []);

  const getDepartmentsFlow = async (comID) => {
    if (!comID) {
      setDepartments([]);
      setSecondaryDepartments([]);
      return;
    }
    setInnerLoading(true);
    setIsError(false);
    await flowResult(usersPageStore.getCommunityDepartments(comID)).then(
      (response: any) => {
        setInnerLoading(false);
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0"),
            true
          );
          setInnerLoading(false);
          return;
        }
        const dataMain = response.departments.map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        const dataSecondary = response.secondaryDepartments?.map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        setDepartments(dataMain);
        setSecondaryDepartments(dataSecondary || []);
      }
    );
    setInnerLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      community_id: "",
      admin_communities: [],
      department_id: "",
      secondary_department_id: "",
      role: "",
    },
  } as any);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const handleSubmitCheck = async (objectData) => {
        setIsLoading(true);
    setIsError(false);
    let data = {
      ...objectData,
      phone: objectData.phone.replace(" ", ""),
      admin_communities: objectData.admin_communities.filter(
        (el) => el !== "select-all"
      ),
    };
    if (community) {
      data = { ...data, community_id: community };
    }
    if (objectData.role === "ADMIN") {
      data = {
        ...data,
        community_id: data.admin_communities[0],
      };
    } else {
      delete data["admin_communities"];
    }
    if (isCreatePage) {
      await flowResult(usersPageStore.createUser(data)).then((response: any) => {
        if (!response?.success) {
          if (response?.errors) {
            handleNotificationOpen(
              response?.errors[0].code
                ? t(`apiMessages.${response?.errors[0].code}`)
                : t("apiMessages.0"),
              true
            );
            setIsLoading(false);
            return;
          }
          handleNotificationOpen(
            response.code ? t(`apiMessages.${response.code}`) : "",
            true
          );
          setIsLoading(false);
          return;
        }
        handleNotificationOpen(
          response.code ? t(`apiMessages.${response.code}`) : "",
          false
        );
        setIsLoading(false);
      });
    }
    
    if (isRegisterPage) {
    const platform = localStorage.getItem('platformSettings');
    data.platform_id = platform === 'communities' ? 'ckwz19it9158258j0vpwvgn4r46' : 'ckwtjppo3590838svpjfigsyfk';
    delete data.department_id;
    delete data.secondary_department_id;
    delete data.role;
    // delete data.community_id;
    await flowResult(userStore.registerApp(data)).then((response: any) => {
      if (!response?.success) { // false
        setModalText('common.buttons.close');
        if (response?.errors) { // true
          handleNotificationOpen(
            response?.errors[0].code // 0
              ? t(`apiMessages.${response?.errors[0].code}`)
              : t("apiMessages.0"),
            true
          );
          setIsLoading(false);
          return;
        }
        handleNotificationOpen(
          response.code ? t(`apiMessages.${response.code}`) : "",
          true
        );
        setIsLoading(false);
        return;
      }
      if (response?.code === 'AU201' && isMobile) { // true הרישום הצליח
        setModalText('users.create.openStore'); // עבור לאפליקצייה
      }
      handleNotificationOpen( response?.code ? t(`apiMessages.${response.code}`) : "", false);
      setIsLoading(false);
    });
    }
  };

  const handleNotificationOpen = (message = "", isError = false) => {
    setIsError(isError);
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
  };

  const handleButtonClick = () => {
    setOpenNotificationModal(false);
    if (isCreatePage && !isError) { // false
      history.push("/users/list");
    }

    if (isRegisterPage && !isError && isMobile) { // false
      if (localStorage.getItem('platformSettings') === 'communities') {
        window.location.href = 'https://communitygeneral.page.link/app'
      } else if (localStorage.getItem('platformSettings') === 'vaad') { 
        window.location.href = 'https://communityvaad.page.link/app'
      } else {
        history.push("/sign-in");
      }

    }
  };

  const onMultiSelectChange = (data, isSelectAll = false) => {
    setValue("admin_communities", data);
    setIsSelectAllSelected(isSelectAll);
    const firstCommunity =
      data.length > 0
        ? isSelectAll
          ? communities?.[0]?.id ?? ""
          : data?.[0] ?? ""
        : "";
    if (firstCommunity !== firstSelectedCommunity) {
      setFirstSelectedCommunity(firstCommunity);
      getDepartmentsFlow(firstCommunity);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
      <div style={{height: '100vh'}}>
        <div className={`main-wrapper create-user ${i18n.language==='he' ? 'community-rtl' : ''}`} style={isRegisterPage ? { padding: '30px' } : {}} dir={i18n.language==='he' ? 'rtl' : ''}>      <h2 className="page-heading">{t("users.create.title")}</h2>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
          >
            <Card className="card-wrapper">
              <CardContent>
                <Typography variant="h6" className="card-heading">
                  {t("users.create.subTitle")}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={4} lg={4}>
                    {isRegisterPage && <div className="form-field">
                      <TextField
                        label={'שם קהילה'}
                        value={communityName}
                        disabled={true}
                      />
                    </div>}
                    {isRegisterPage && <div className="form-field">
                      <TextField
                        label={'מזהה קהילה'}
                        value={community}
                        disabled={true}
                      />
                    </div>}
                    <div className="form-field">
                      <Controller
                        control={control}
                        name="first_name"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="firstName"
                              label={t("signUp.fields.firstName.label")}
                              value={value}
                              onChange={onChange}
                              autoComplete="off"
                            />
                            <p className="field-error">
                              {errors?.first_name?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-field">
                      <Controller
                        control={control}
                        name="last_name"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="lastName"
                              label={t("signUp.fields.lastName.label")}
                              value={value}
                              onChange={onChange}
                              autoComplete="off"
                            />
                            <p className="field-error">
                              {errors?.last_name?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-field">
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="phone"
                              label={t("signUp.fields.phone.label")}
                              value={value}
                              onChange={(e) =>
                                onChange(formatToPhoneNumber(e.target.value, value))
                              }
                              autoComplete="off"
                            />
                            <p className="field-error">
                              {errors?.phone?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-field">
                      <Controller
                        control={control}
                        name="email"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="email"
                              label={t("signUp.fields.email.label")}
                              value={value}
                              onChange={onChange}
                              autoComplete="off"
                            />
                            <p className="field-error">
                              {errors?.email?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-field password">
                      <FormControl className="password-field-container">
                        <InputLabel htmlFor="password">
                          {t("signUp.fields.password.label")}
                        </InputLabel>
                        <Controller
                          control={control}
                          name="password"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                value={value}
                                onChange={onChange}
                                autoComplete="off"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      className="visibility-icon"
                                      onClick={() => setShowPassword(!showPassword)}
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </>
                          )}
                        />
                      </FormControl>
                      <p className="field-error">
                        {errors?.password?.message ?? ""}&nbsp;
                      </p>
                    </div>
                    <div
                      className={`form-field ${role !== UserTypeEnum.owner ? "hide-element " : ""
                        }`}
                    >
                      {selectedRole !== "ADMIN" ? (
                        <Controller
                          control={control}
                          name="community_id"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <FormControl>
                                <InputLabel id="select-community">
                                  {t("tabNavigation.community.mainTab")}
                                </InputLabel>
                                <Select
                                  labelId="select-community"
                                  id="select-filter"
                                  value={value}
                                  onChange={(e: any) => {
                                    onChange(e);
                                    getDepartmentsFlow(e.target.value);
                                    setValue("department_id", "");
                                    setCommunity(e.target.value);
                                  }}
                                  disabled={!communities.length}
                                >
                                  {communities.map((el, index) => {
                                    return (
                                      <MenuItem key={index} value={el.id}>
                                        {el.communityName}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                              {communities.length ? (
                                <p className="field-error">
                                  {errors?.community_id?.message ?? ""}&nbsp;
                                </p>
                              ) : (
                                <p className="field-error">
                                  {t("errorMessages.articles.create.noCommunities")}
                                </p>
                              )}
                            </>
                          )}
                        />
                      ) : (
                        <FormControl>
                          <Controller
                            control={control}
                            name="admin_communities"
                            render={({ field: { onChange, value } }: any) => (
                              <>
                                <label className="field-label">
                                  {t("tabNavigation.community.mainTab")}
                                </label>
                                <MultiSelect
                                  showSelectAll
                                  value={value}
                                  hasError={!!errors?.admin_communities?.message}
                                  isSelectAllSelected={isSelectAllSelected}
                                  mapBy="id"
                                  renderedName="communityName"
                                  onChange={onMultiSelectChange}
                                  selectOptions={communities}
                                />
                                {communities.length ? (
                                  <p className="field-error">
                                    {errors?.admin_communities?.message ?? ""}&nbsp;
                                  </p>
                                ) : (
                                  <p className="field-error">
                                    {t(
                                      "errorMessages.articles.create.noCommunities"
                                    )}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      )}
                    </div>
                    {isCreatePage && <div className={`form-field department_id`}>
                      <Controller
                        control={control}
                        name="department_id"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <FormControl>
                              <InputLabel id="select-department">
                                {t("common.fieldHeaders.department")}
                              </InputLabel>
                              <Select
                                labelId="select-department"
                                id="select-filter"
                                value={value}
                                onChange={onChange}
                                disabled={
                                  (!communities.length &&
                                    !Object.keys(community).length) ||
                                  !departments.length
                                }
                              >
                                {departments.map((el: any, index) => {
                                  return (
                                    <MenuItem key={index} value={el.id}>
                                      {el.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            {communities.length || community.length ? (
                              role !== UserTypeEnum.owner ? (
                                <p className="field-error">
                                  {departments.length
                                    ? errors?.department_id?.message ?? ""
                                    : t(
                                      "errorMessages.articles.create.noDepartments"
                                    )}
                                  &nbsp;
                                </p>
                              ) : (
                                <p className="field-error">
                                  {departments.length
                                    ? errors?.department_id?.message ?? ""
                                    : t(
                                      "errorMessages.articles.create.noDepartmentsForOwner"
                                    )}
                                  &nbsp;
                                </p>
                              )
                            ) : (
                              <p className="field-error">
                                {t("errorMessages.articles.create.noCommunities")}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>}
                    {isCreatePage && <div className={`form-field secondary_department_id`}>
                      <Controller
                        control={control}
                        name="secondary_department_id"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <FormControl>
                              <InputLabel id="select-department">
                                {t("common.fieldHeaders.secondaryDepartment")}
                              </InputLabel>
                              <Select
                                labelId="select-department"
                                id="select-filter"
                                value={value}
                                onChange={onChange}
                                disabled={
                                  (!communities.length &&
                                    !Object.keys(community).length) ||
                                  !secondaryDepartments.length
                                }
                              >
                                {secondaryDepartments.map((el: any, index) => {
                                  return (
                                    <MenuItem key={index} value={el.id}>
                                      {el.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            {communities.length || community.length ? (
                              role !== UserTypeEnum.owner ? (
                                <p className="field-error">
                                  {secondaryDepartments.length
                                    ? errors?.department_id?.message ?? ""
                                    : t(
                                      "errorMessages.articles.create.noSecondaryDepartments"
                                    )}
                                  &nbsp;
                                </p>
                              ) : (
                                <p className="field-error">
                                  {secondaryDepartments.length
                                    ? errors?.department_id?.message ?? ""
                                    : t(
                                      "errorMessages.articles.create.noSecondaryDepartmentsForOwner"
                                    )}
                                  &nbsp;
                                </p>
                              )
                            ) : (
                              <p className="field-error">
                                {t("errorMessages.articles.create.noCommunities")}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>}
                    {isCreatePage && <div className="form-field type">
                      <Controller
                        control={control}
                        name="role"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <div>
                              <span className="search-panel-subTitle">
                                {t("common.fieldHeaders.type")}
                              </span>
                            </div>
                            {role === UserTypeEnum.owner ||
                              role === UserTypeEnum.associatedOwner ? (
                              <Grid item className="checkbox-item">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={value === "ADMIN"}
                                      onChange={() => {
                                        setSelectedRole("ADMIN");
                                        onChange("ADMIN");
                                      }}
                                    />
                                  }
                                  label={t("users.create.admin")}
                                />
                              </Grid>
                            ) : null}
                            {role === UserTypeEnum.owner ||
                              role === UserTypeEnum.associatedOwner ||
                              role === UserTypeEnum.admin ? (
                              <>
                                <Grid item className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={value === "CHIEF_EDITOR"}
                                        onChange={() => {
                                          setSelectedRole("CHIEF_EDITOR");
                                          onChange("CHIEF_EDITOR");
                                        }}
                                      />
                                    }
                                    label={t("users.create.chiefEditor")}
                                  />
                                </Grid>
                              </>
                            ) : null}
                            <Grid item className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={value === "EDITOR"}
                                    onChange={() => {
                                      setSelectedRole("EDITOR");
                                      onChange("EDITOR");
                                    }}
                                  />
                                }
                                label={t("users.create.editor")}
                              />
                            </Grid>
                            <Grid item className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={value === "USER"}
                                    onChange={() => {
                                      setSelectedRole("USER");
                                      onChange("USER");
                                    }}
                                  />
                                }
                                label={t("users.create.user")}
                              />
                            </Grid>
                          </>
                        )}
                      />
                      <p className="field-error">
                        {errors?.role?.message ?? ""}&nbsp;
                      </p>
                    </div>}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container direction="column">
              <Grid item xs={12} sm={3} lg={2}>
                <Button
                  style={{ width: '100%' }}
                  variant="contained"
                  color="primary"
                  className="uppercase"
                  type="submit"
                  disabled={!communities?.length && !community?.length}
                >
                  {t("users.create.addUser")}
                </Button>
                <Button
                  style={{ marginTop: '20px', width: '100%' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleButtonClick}
                >
                  {'פתיחת האפליקצייה'}
                </Button>
              </Grid>
            </Grid>
              
            
          </form>
          <NotificationModal
            openModal={openNotificationModal}
            buttonText={t(modalText)}
            handleClose={handleNotificationClose}
            handleButtonClick={handleButtonClick}
            message={notificationMessage}
          />
          {innerLoading ? <Spinner /> : null}
        </div>
      </div>
  );
};

export default CreateUser;
