import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class GroupStore {
    root: RootStore;

    path = "/admin/settings/group";
    groups: any = [];

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    getStoredGroups() {
        return this.groups;
    }

    clearStore() {
        this.groups = [];
    }

    * getGroups() {
        const response = yield Api.get(this.path);
        if (response.ok && response.data?.success && response.data?.groups?.length) {
            this.groups = response.data.groups
        } else {
            this.groups = []
        }
        return response?.data
    }

    * create(payload: any) {
        const response = yield Api.post(this.path, payload);
        if (response.ok && response.data?.success && response.data?.group) {
            this.groups.push(response.data.group)
        }
        return response?.data
    }

    * update(payload: any, id) {
        const response = yield Api.put(`${this.path}/${id}`, payload);
        if (response.ok && response.data?.success) {
            this.groups = this.groups.map((item) => {
                if (id === item.id) {
                    return {...item, ...payload }
                }
                return item
            })
        }
        return response?.data
    }

    * delete(id) {
        const response = yield Api.delete(`${this.path}/${id}`);
        if (response.ok && response.data?.success) {
            this.groups = this.groups.filter((item) => {
                return id !== item.id
            })
        }
        return response?.data
    }
}
