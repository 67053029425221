import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArchiveIcon from "../../assets/icons/archive.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import DuplicateIcon from "../../assets/icons/duplicate.svg";
import EditIcon from "../../assets/icons/edit.svg";
import UnarchiveIcon from "../../assets/icons/unarchive.svg";
import ViewIcon from "../../assets/icons/view.svg";
import IconComponent from "../../shared/components/IconComponent/IconComponent";
import { useStore } from "../../store/StoreContext";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import StorageIcon from "@material-ui/icons/Storage";
import { postDynamicShortLink } from "../../shared/utils/linking";

interface Props {
  toggleArchive?: any;
  handleDuplicate?: any;
  handleAction?: any;
  currentPage: string;
  handleGoToResults?: any;
}

const PopUpActionMenu = ({
  toggleArchive = null,
  handleDuplicate = null,
  handleAction,
  currentPage,
  handleGoToResults = null,
}: Props) => {
  const { t } = useTranslation();
  const { actionStore, userStore } = useStore();

  const [anchorEl, setAnchorEl] = useState(null);
  const [goToPath, setGoToPath] = useState("");
  const [isRowArchived, setIsRowArchived] = useState(false);
  const [options, setOptions] = useState([]);
  const [editRowData, setEditRowData] = useState({});

  useEffect(() => {
    setGoToPath(actionStore.goToPath);
    setIsRowArchived(actionStore.isRowArchived);
    setAnchorEl(actionStore.popUpActionMenuAnchor);
    setOptions(actionStore.popUpActionMenuOptions);
    setEditRowData(actionStore.editRowData);
  }, [
    actionStore.isRowArchived,
    actionStore.editRowID,
    actionStore.popUpActionMenuAnchor,
    actionStore.goToPath,
    actionStore.popUpActionMenuOptions,
    actionStore.editRowData,
  ]);

  const handleClose = () => {
    actionStore.popUpActionMenuAnchor = null;
  };

  const handleDuplicateClick = () => {
    handleDuplicate();
    handleClose();
  };

  const handleResultsClick = () => {
    handleGoToResults();
    handleClose();
  };  

  const handleCopyLink = () => {
    handleClose();
    const platform = userStore.getPlatformSettings();
    setTimeout(async () => {      
      const link = await postDynamicShortLink(
        actionStore.goToPath,
        `${currentPage}/view`,
        platform,
        editRowData
      );
      navigator.clipboard.writeText(link);
      handleAction(t("errorMessages.copiedLink"));
    });
  };

  const handleActionClick = (type = "") => {
    const message =
      type === "isDelete"
        ? t("errorMessages.deleteQuestion")
        : isRowArchived
        ? t("errorMessages.unarchiveQuestion")
        : t("errorMessages.archiveQuestion");
    handleAction(message, type);
    handleClose();
  };

  return (
    <Menu
      id="actions-menu"
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      className="action-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
    >
      {options.indexOf("edit") >= 0 && (
        <MenuItem onClick={() => handleClose()}>
          <div className="menu-item">
            <Link
              className="view-link flex-center"
              to={`/${currentPage}/edit/${goToPath}`}
            >
              <IconComponent icon={EditIcon} /> {t("popUpActionMenu.edit")}
            </Link>
          </div>
        </MenuItem>
      )}
      {options.indexOf("view") >= 0 && (
        <MenuItem onClick={() => handleClose()}>
          <div className="menu-item">
            <Link
              className="view-link flex-center"
              to={`/${currentPage}/view/${goToPath}`}
            >
              <IconComponent icon={ViewIcon} /> {t("popUpActionMenu.view")}
            </Link>
          </div>
        </MenuItem>
      )}
      {options.indexOf("duplicate") >= 0 && (
        <MenuItem onClick={handleDuplicateClick}>
          <div className="menu-item">
            <IconComponent icon={DuplicateIcon} />{" "}
            {t("popUpActionMenu.duplicate")}
          </div>
        </MenuItem>
      )}
      {options.indexOf("archive") >= 0 && (
        <MenuItem onClick={() => handleActionClick("isArchive")}>
          <div className="menu-item">
            <IconComponent icon={ArchiveIcon} /> {t("common.archive")}
          </div>
        </MenuItem>
      )}
      {options.indexOf("unarchive") >= 0 && (
        <MenuItem onClick={() => handleActionClick("isArchive")}>
          <div className="menu-item">
            <IconComponent icon={UnarchiveIcon} />{" "}
            {t("popUpActionMenu.unarchive")}
          </div>
        </MenuItem>
      )}
      {options.indexOf("delete") >= 0 && (
        <MenuItem onClick={() => handleActionClick("isDelete")}>
          <div className="menu-item">
            <IconComponent icon={DeleteIcon} /> {t("popUpActionMenu.delete")}
          </div>
        </MenuItem>
      )}
      {options.indexOf("copyLink") >= 0 && (
        <MenuItem onClick={handleCopyLink}>
          <div className="menu-item">
            <ShareOutlinedIcon className="icon" />{" "}
            {t("popUpActionMenu.copyLink")}
          </div>
        </MenuItem>
      )}
      {options.indexOf("results") >= 0 && handleGoToResults && (
        <MenuItem onClick={() => handleResultsClick()}>
          <div className="menu-item">
            <StorageIcon className="icon" /> {t("popUpActionMenu.goToResults")}
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};

export default observer(PopUpActionMenu);
