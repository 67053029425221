import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { noContent } from "../../../db/db";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../../components/Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import { getElementFromArrayObject, stringToCapital } from "../../../shared/utils/sharedFunctions";
import RangeDatePicker from "../../../components/RangeDatePicker/RangeDatePicker";
import { ClickAwayListener } from "@material-ui/core";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";

const Clicks = () => {
  const { reportsStore, actionStore, benefitStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  const { i18n, t } = useTranslation();
  const { languageStore } = useStore();
  const [clicksList, setClicksList] = useState<Array<Object>>([]);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => { });

  const [categories, setCategories] = useState<any>([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });

  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const convertToDate = (date, locale = lngDirection === "rtl" ? "he-IL" : "en-US") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };

    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  const convertToDateRangePicker = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  // Get all purchase and fill table
  const getAllClicks = async () => {
    setIsLoading(true);
    const searchParams = reportsStore.getSearchParamsClicks();
    if (searchParams?.from_date) {
      setDateRange(
        {
          startDate: searchParams.from_date,
          endDate: searchParams.to_date,
        }
      )
    }

    const benefitParams = {
      is_draft: false,
      has_clicks: true,
      limit: 200,
      from_date: convertToDate(new Date(new Date().setDate(new Date().getDate() - 7)), "en-US"),
      to_date: convertToDate(new Date(), "en-US"),
    }
    await flowResult(actionStore.getPathItems('benefit', { ...benefitParams, ...searchParams })).then(
      (response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
          return;
        }
        const data = response.benefits;
        setClicksList(data);
        setIsLoading(false);
      }
    )
  };

  useEffect(() => {
    reportsStore.triggerModalChange();
    setCategories(benefitStore.getStoredBenefits());
    getAllClicks();
    return () => {
      reportsStore.triggerModalChange();
      reportsStore.setClickInformation({});
    }
  }, []);

  // Error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0)
        })
      })
    }
  }, [languageStore.switchLng, isLoading === false])

  useEffect(() => {
    if (reportsStore.triggerUpdateList) {
      getAllClicks();
      reportsStore.triggerUpdateList = false;
    }
  }, [reportsStore.triggerUpdateList]);

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      sortable: false,
      width: 90,
      renderHeader: () => {
        return (
          <div style={{ pointerEvents: 'none' }} />
        )
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row?.image ?? ImagePlaceholder;
        return (
          <img className="table-row-image" src={row} alt={row || "Article image"} />
        );
      },
    },
    {
      field: 'id',
      headerName: t('common.fieldHeaders.id'),
      sortable: false,
      width: 50,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const id = params.api?.getRowIndex(params?.id) + 1;
        return (
          <span className="table-row-title">
            {id}
          </span>
        );
      },
    },
    {
      field: 'communityName',
      headerName: t('tabNavigation.community.mainTab'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{params.row?.appears?.[0]?.community?.communityName ?? t('users.create.owner')}</span>
        );
      },
    },
    {
      field: 'type',
      headerName: t('common.fieldHeaders.type'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const type = params.row.benefitType;
        return (<span>{stringToCapital(t(`common.selectFilters.${type.toLowerCase()}`))}</span>)
      },
    },
    {
      field: 'categoryId',
      headerName: t('common.fieldHeaders.category'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{getElementFromArrayObject('id', 'categoryName', params.row.categoryId, categories)}</span>
        );
      },
    },
    {
      field: 'header',
      headerName: t('common.fieldHeaders.header'),
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.header}
          </span>
        );
      },
    },
    {
      field: 'publishDate',
      headerName: t('common.fieldHeaders.live'),
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row.publishDate;
        return (
          <div>
            <div className="table-row-date">{convertToDate(row)}</div>
          </div>
        )
      },
    },
    {
      field: 'archiveDate',
      headerName: t('common.fieldHeaders.archived'),
      sortable: true,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            <div className="table-row-date">{convertToDate(params.row.archiveDate)}</div>
          </div>
        )
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );

  const handleRowClick = (row) => {
    reportsStore.setClickInformation(row)
  }

  const handleDateChange = ({ startDate, endDate }, isClose = false) => {
    setDateRange({ startDate: startDate, endDate: endDate });
    if (isClose) {
      setOpenDatePicker(false);
      const data = {
        from_date: convertToDate(startDate, "en-US"),
        to_date: convertToDate(endDate, "en-US"),
      }
      reportsStore.setSearchParamsClicks(data);
      reportsStore.setInnerSearchParams(data);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
      <div className="main-wrapper purchases-page">
        <div className="page-heading-wrap">
          <h2 className="page-heading">{t("tabNavigation.reports.mainTab")}</h2>
        </div>
        <div className="d-flex justify-end">
          {/* <span>
            {t("reports.purchases.totalPurchases")} ({clicksList.length})
          </span> */}
          <div className="range-date-picker datepicker">
            <div className="dislayed-dates" onClick={() => toggleDatePicker()}>
              <span>
                {t("reports.fromDate")}{" "}
                {dateRange?.startDate ? convertToDateRangePicker(dateRange.startDate) : ""}
              </span>
              <span>-</span>
              <span>
                {t("reports.untilDate")}{" "}
                {dateRange?.endDate ? convertToDateRangePicker(dateRange.endDate) : ""}
              </span>
            </div>
            <div className="range-datepicker">
              <RangeDatePicker
                value={dateRange}
                isOpened={openDatePicker}
                onChange={handleDateChange}
                locale={lngDirection === "rtl" ? heLocale : enLocale}
              />
            </div>
          </div>
        </div>
        <div className="clicks-content-wrap">
          {clicksList?.length ? (
            <>
              <div className="table-grid">
                <DataGrid
                  rows={clicksList}
                  autoHeight={true}
                  columns={lngDirection === "ltr" ? columns : columns.reverse()}
                  rowHeight={90}
                  headerHeight={56}
                  disableColumnMenu={true}
                  hideFooter={true}
                  onCellClick={(row: any) => {
                    handleRowClick(row)
                  }}
                />
              </div>
            </>
          ) : (
            <div className="no-content">
              <img src={noContent.users} alt="No messages" />
              <p>{t("reports.clicks.noContent.message1")}</p>
              <span className="note">
                {t("reports.purchases.noContent.message2")}
              </span>
            </div>
          )}
        </div>
        <NotificationModal
          openModal={openNotificationModal}
          handleClose={handleNotificationClose}
          handleButtonClick={buttonAction}
          buttonText={buttonText}
          message={notificationMessage}
        />
      </div>
    </ClickAwayListener>
  );
};

export default observer(Clicks);
