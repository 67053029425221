import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  TRANSPARENT_LOGO: "transparent_logo",
  BACKGROUND_IMAGE: "background_image",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.TRANSPARENT_LOGO]: Yup.string()
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.BACKGROUND_IMAGE]: Yup.string().notRequired(),
});
