import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from "@material-ui/data-grid";
import "./style.scss";
import { Button, Checkbox, FormControl, IconButton, MenuItem, Select } from "@material-ui/core";
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import { useStore } from '../../../store/StoreContext';
import { flowResult } from "mobx";
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import { observer } from 'mobx-react-lite';
import NoArticles from "../../../assets/images/articles/noArticles.svg";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import UserTypeEnum from '../../../models/UserType';
import { useTranslation } from 'react-i18next';
import PreviewModal from '../../../components/PreviewModal/PreviewModal';
import { convertToDate as improvedConvertToDate, convertZuluToLocalTime, getElementFromArrayObject } from '../../../shared/utils/sharedFunctions';
import { Link, useHistory } from 'react-router-dom';
import DragAndDropTable from '../../../components/DragAndDropTable/DragAndDropTable';
import { getPopUpActionOptions } from '../../../shared/utils/sharedOptions';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 'fit-content',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Sales = () => {
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const {
        benefitStore,
        userStore,
        actionStore,
        languageStore,
        saleStore,
        reportsStore,
        settingsStore
    } = useStore();
    const classes = useStyles();
    const [comFilter, setComFilter] = useState('all');
    const [typeFilter, setTypeFilter] = useState('all');

    const [categories, setCategories] = useState<any>([]);

    const [communities, setCommunities] = useState<any>([]);

    const [benefitsList, setBenefitsList] = useState<Array<Object>>([]);
    const [draftList, setDraftList] = useState<Array<Object>>([]);
    const [archiveList, setArchiveList] = useState<Array<Object>>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [actionType, setActionType] = useState('');
    const [roleType, setRoleType] = useState(null);

		const [isSelectAllBenefits, setIsSelectAllBenefits] = useState(false);
		const [isSelectAllArchivedBenefits, setIsSelectAllArchivedBenefits] = useState(false);

    // Pagination D&D Table
    const [totalActiveArticles, setTotalActiveArticles] = useState(0);
    const rowsPerPage = 50;

    const stringToCapital = (text) => {
        if (text && text.length > 1) {
            text = text.toLowerCase();
            return text?.charAt(0).toUpperCase() + text?.slice(1)
        }
        return text
    }

    const convertToDate = (date, type = '', locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const updateBenefits = async (params = {}) => {
        const benefitParams = {
            is_archive: false,
            is_locked: false,
            is_draft: false,
            limit: rowsPerPage * 2,
        }
        await flowResult(actionStore.getPathItems('benefit', { ...params, ...benefitParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.benefits;
                setBenefitsList(data);
                setTotalActiveArticles(response.pagination.total);
            }
        )
    }

    const updateArchivedArticles = async (params = {}) => {
        const archivedParams = {
            is_archive: true,
            is_locked: false,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('benefit', { ...params, ...archivedParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.benefits;
                setArchiveList(data)
            }
        )
    }

    const updateDraftArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_archive']
        delete incomingParams['is_locked']
        const draftParams = {
            is_draft: true,
        }
        await flowResult(actionStore.getPathItems('benefit', { ...incomingParams, ...draftParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.benefits;
                setDraftList(data)
            }
        )
    }

    const updateLists = async (params: any = {}) => {
        setIsLoading(true);
        setBenefitsList([]);
        setArchiveList([]);
        setDraftList([]);

        const searchParams = actionStore.getSearchParams();
        params = {
            ...params,
            is_archive: true,
            is_draft: true,
            ...searchParams,
            limit: 10
        };
        let promises: any = [];
        if (!params.is_archive || (params.is_archive && params.is_draft)) {
            promises.push(updateBenefits(params));
        }
        if (params.is_archive) {
            promises.push(updateArchivedArticles(params));
        }
        if (params.is_draft) {
            promises.push(updateDraftArticles(params));
        }
        await Promise.all(promises);
        setIsLoading(false);
    }

    useEffect(() => {
        let maxPrice = 0;
        benefitsList.forEach((benefit: any) => {
            let benefitPrice = benefit?.details?.price ?? 0;
            if (benefitPrice > maxPrice) {
                maxPrice = benefitPrice
            }
        });
        archiveList.forEach((benefit: any) => {
            let benefitPrice = benefit?.details?.price ?? 0;
            if (benefitPrice > maxPrice) {
                maxPrice = benefitPrice
            }
        });
        benefitStore.setMaxPrice(maxPrice);
    }, [benefitsList, archiveList])

    const handleFlow = (flow) => {
        setIsLoading(true);
        flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : (response?.message ?? t("apiMessages.0")));
                    setIsLoading(false);
                    return;
                }
                updateLists();
            }
        );
    }

    useEffect(() => {
        setCommunities(userStore.getCommunities());
        setCategories(benefitStore.getStoredBenefits());
        setRoleType(userStore.getRoleType());
        updateLists();
    }, []);

    useEffect(() => {
        if (actionStore.triggerUpdateList) {
            updateLists();
            actionStore.triggerUpdateList = false;
        }
    }, [actionStore.triggerUpdateList])

    /**
     * Filter selected to a list send to server some calls and rerender the list.
     * Uncheck the select all checkbox.
     */
    const handleAction = (action, listToFilter) => {
			const list = listToFilter.filter((el: any) => el.isSelect);
			const promises = [list.map((el: any) => action(el.id))]

			Promise.all(promises).then(() => setTimeout(() => updateLists(), 2000))

			setIsSelectAllBenefits(false)
			setIsSelectAllArchivedBenefits(false)
		};

    const handleNotificationOpen = (message = '', actionType = "") => {
        setButtonText(t('common.buttons.close'));

        setNotificationMessage(message);
        
				if (actionType) {
            setButtonText(t('common.buttons.confirm'));
            setActionType(actionType);
        }
        
				setOpenNotificationModal(true);
    }

    const handleNotificationClose = (actionType = "") => () => {
      if (actionType === "isDelete") {
          handleDelete();
          setActionType("");
      }
      
			if (actionType === "isArchive") {
          toggleArchive();
          setActionType("");
      }
			
			if (actionType === "archiveSelected") {
					handleAction(toggleArchive, benefitsList);
					setActionType("");
			}
			
			if (actionType === "deleteSelected") {
					handleAction(handleDelete, archiveList);
					setActionType("");
			}
      
			setOpenNotificationModal(false);
    }

    const handleFilterCommunity = (event) => {
        const value = event.target.value;
        setComFilter(value);
        let filter = {};
        if (value !== 'all') {
            filter['community'] = value
        }
        updateLists(filter)
    }

    const handleTypeCommunity = (event) => {
        const value = event.target.value;
        setTypeFilter(value);
        let filter = {};
        if (comFilter !== 'all') {
            filter['community'] = comFilter
        }
        if (value !== 'all') {
            filter['type'] = value
        }
        updateLists(filter)
    }

    const openActionMenu = (data) => (event) => {
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        let options = settingsStore.getUserPermissions().benefits ? getPopUpActionOptions(data, roleType, userStore.getIsPermitted()) : ['view'];
        if (data?.hasClicks > 0 || data?.hasPurchases > 0 || data?.hasSurveys > 0 || data?.hasLeads > 0) {
            options = [...options, 'results']
        }
        actionStore.handleOpenPopActionMenu(currentTarget.firstChild, `sale`, data, options);
    };

    const toggleArchive = (id = '') => {
        const path = 'benefit/archive';
        const idToUpdate = id ? id : actionStore.editRowData.id
        handleFlow(actionStore.update({}, path, idToUpdate));
    }

    const handleDuplicate = () => {
        const path = 'benefit/duplicate';
        handleFlow(actionStore.update({}, path, actionStore.editRowData.id));
    }

    const handleDelete = (id = '') => {
        const path = 'benefit';
        const idToUpdate = id ? id : actionStore.editRowData.id
        handleFlow(actionStore.delete(path, idToUpdate));
    }

    const handleGoToResults = () => {
        const benefitType = actionStore.editRowData?.benefitType ?? '';
        const data = actionStore.editRowData;
        if (benefitType === 'STORE') {
            reportsStore.setPurchaseInformation(data);
            history.push('/reports/purchases')
        } else if (benefitType === 'SURVEY') {
            reportsStore.setSurveyInformation(data);
            history.push('/reports/surveys')
        } else if (benefitType === 'LEADS') {
            reportsStore.setLeadInformation(data);
            history.push('/reports/leads')
        } else if (benefitType === 'LINK') {
            const dates = {
                from_date: convertToDate(data.publishDate, '', 'en-US'),
                to_date: convertToDate(data.archiveDate, '', 'en-US'),
            };
            reportsStore.setSearchParamsClicks(dates);
            reportsStore.setInnerSearchParams(dates);
            reportsStore.setClickInformation(data);
            history.push('/reports/clicks')
        }
    }

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    // Preview Modal
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => () => {
        setOpenModal(false);
    }

    const getCategoryName = (id) => {
        const index = categories.findIndex((cat: any) => cat.id === id);
        if (index >= 0) {
            return categories[index].categoryName
        }
    }

    const reverseDirection = (columns) => {
        const array = [];
        columns.forEach(el => {
            array.unshift(el)
        });
        return array
    }

    const handleReorder = async (objectData) => {
        const pageType = objectData.item_type;
        let data = {};
        data = {
            item_type: pageType,
            category_id: objectData.category_id,
            from_date: objectData.from_date,
            to_date: objectData.to_date,
            header: objectData.header,
            description: objectData.description,
            image: objectData.image,
            publish_date: objectData.publishDate,
            archive_date: objectData.archiveDate,
            is_draft: false,
        };
        if (pageType === "LEADS") {
            const emails = [objectData.supplier_email];
            if (objectData.supplier_second_email) {
                emails.push(objectData.supplier_second_email)
            }
            data = {
                ...data,
                details: {
                    supplier_email: [...emails],
                    date_from: objectData.date_from,
                    date_to: objectData.date_to,
                    external_link: objectData.external_link,
                    details_header: objectData.details_header,
                    details_text: objectData.details.details_text,
                    form_builder: {
                        fields: [...objectData.details.form_builder],
                    },
                },

            };
        }
        if (pageType === "LINK") {
            data = {
                ...data,
                details: {
                    button_text: objectData.button_text,
                    link: objectData.link,
                },
            };
        }
        if (pageType === "STORE") {
            data = {
                ...data,
                details: {
                    date: objectData.details.date,
                    hour: objectData.details.hour,
                    location: objectData.location,
                    number_of_tickets: objectData.number_of_tickets,
                    price: objectData.price,
                    details_header: objectData.details_header,
                    details_text: objectData.details.details_text,
                },
            };
        }
        if (pageType === "SURVEY") {
            const emails = [objectData.supplier_email];
            if (objectData.supplier_second_email) {
                emails.push(objectData.supplier_second_email)
            }
            data = {
                ...data,
                item_type: pageType,
                details: {
                    supplier_email: [...emails],
                    form_builder: {
                        fields: [...objectData.details.form_builder],
                    },
                },
            };
        }
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el] && el !== 'is_draft') delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        let error = false;
        await flowResult(saleStore.update(data, objectData.id)).then((response: any) => {
            if (!response?.success) {
                handleNotificationOpen(
                    response?.code
                        ? t(`apiMessages.${response.code}`)
                        : t("apiMessages.0")
                );
                error = true;
                return;
            }
        });
        return error;
    }

		const handleSelectAll = (list, setList, activity, setActivity) => {
      const newList = list.map((el) => {
        return { ...el, isSelect: !activity };
      });

      setList(newList);
      setActivity(!activity);
    };

    const handleSelect = (id, list, setList, activity, setActivity) => {
      const newList = list.map((el) =>
        id === el.id ? { ...el, isSelect: !el.isSelect } : el
      );
      setList(newList); //reset the list of article to show the selected
      if (activity) setActivity(false); //disable the select all checkbox
    };

		const handleArchiveSelected = () => {
			const selected = benefitsList.filter((el: any) => el?.isSelect);

			if (selected.length) {
					handleNotificationOpen(t("errorMessages.archiveItems"), 'archiveSelected');
			} else {
					handleNotificationOpen(t("errorMessages.needToSelect"));
			}
		}

		const handleDeleteSelected = () => {
			const selected = archiveList.filter((el: any) => el?.isSelect);

        if (selected.length) {
            handleNotificationOpen(t("errorMessages.deleteItems"), 'deleteSelected');
        } else {
            handleNotificationOpen(t("errorMessages.needToSelect"));
        }
		}

		const selectColumn : (list: any, setList: any, activity: boolean, setActivity: any) => GridColDef = (list, setList, activity, setActivity) => ({
			field: "select",
			headerName: "",
			sortable: false,
			width: 90,
			headerClassName: "d-flex justify-center align-center",
			renderHeader: () => {
					return (
							<IconButton
									aria-label="select all items"
									onClick={() => handleSelectAll(list, setList, activity, setActivity)}
							>
									<Checkbox
											checked={activity}
											color="primary"
											className="select-all-checkbox"
									/>
							</IconButton>
					);
			},
			renderCell: (params: GridCellParams) => {
					let row = params.row;
					return (
							<IconButton
									aria-label="select item"
									onClick={() => handleSelect(row.id, list, setList, activity, setActivity)}
							>
									<Checkbox
											checked={row?.isSelect ?? false}
											color="primary"
											className="select-all-checkbox"
									/>
							</IconButton>
					);
			},
	});
		
    const columns: GridColDef[] = [
        {
            field: 'image',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div style={{ pointerEvents: 'none' }} />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.image;
                return (
                    <img className="table-row-image" src={row || ImagePlaceholder} alt={row || "Article image"} />
                );
            },
        },
        {
            field: 'id',
            headerName: t('common.fieldHeaders.id'),
            sortable: false,
            width: 50,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>
                        {params.colDef.headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const id = params?.api
                    ? (params.api?.getRowIndex(params?.id) + 1)
                    : (params.row.index)
                return (
                    <span className="table-row-title">
                        {id}
                    </span>
                );
            },
        },
        {
            field: 'communityName',
            headerName: t('tabNavigation.community.mainTab'),
            sortable: false,
            width: 100,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>
                        {params.colDef.headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row?.appears?.[0]?.community?.communityName ?? t('users.create.owner')}</span>
                );
            },
        },
        {
            field: 'benefitType',
            headerName: t('common.fieldHeaders.type'),
            sortable: false,
            width: 100,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>
                        {params.colDef.headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const type = params.row.benefitType;
                return (<span>{stringToCapital(t(`common.selectFilters.${type.toLowerCase()}`))}</span>)
            },
        },
        {
            field: 'categoryId',
            headerName: t('common.fieldHeaders.category'),
            sortable: false,
            width: 100,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{getCategoryName(params.row.categoryId)}</span>
                );
            },
        },
        {
            field: 'header',
            headerName: t('common.fieldHeaders.header'),
            sortable: false,
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.header}
                    </span>
                );
            },
        },
        {
            field: 'price',
            headerName: t('common.fieldHeaders.price'),
            sortable: false,
            width: 100,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const price = params.row?.details?.price;
                const currentPrice = lngDirection === "rtl" ? price : price / 3.2;
                return (
                    <span className="table-row-title">{price ? `${t('common.currency')}${currentPrice}` : ''}</span>
                );
            },
        },
        {
            field: 'location',
            headerName: t('common.fieldHeaders.location'),
            sortable: false,
            width: 200,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row?.details?.location ?? ''}</span>
                );
            },
        },
        {
            field: 'publishDate',
            headerName: t('common.fieldHeaders.live'),
            width: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.publishDate;
                const todayDate = new Date();
                const liveDate = convertZuluToLocalTime(new Date(row));
                const isLiveDateFuture = Date.parse(todayDate.toString()) < Date.parse(liveDate.toString());
                return (
                    <div className={isLiveDateFuture ? "table-row-double" : ''}>
                        <div className="table-row-date">{convertToDate(row)}</div>
                        <div className="table-row-scheduled">{isLiveDateFuture ? t('common.fieldHeaders.scheduled') : ''}</div>
                    </div>
                )
            },
        },
        {
            field: 'archiveDate',
            headerName: t('common.fieldHeaders.archived'),
            sortable: true,
            width: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <div>
                        <div className="table-row-date">{convertToDate(params.row.archiveDate)}</div>
                    </div>
                )
            },
        },
        {
            field: 'quantity',
            headerName: t('common.fieldHeaders.quantity'),
            width: 100,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row?.details?.number_of_tickets ?? ''}</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            width: 100,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column text-center`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                return (
                    <div id={`grid-row-${data.id}`} className={`table-row-actions cell-actions`} onClick={openActionMenu(data)}>
                        <MoreHorizIcon
                            aria-controls={`actions-menu`}
                            aria-haspopup="true"
                            className="table-row-actionIcon"
                        />
                    </div>
                )
            },
        },
    ].map((column) => (
        {
            ...column,
            disableClickEventBubbling: true,
        }));

		const liveColumns = [ selectColumn(benefitsList, setBenefitsList, isSelectAllBenefits, setIsSelectAllBenefits), ...columns]
		const archivedColumns = [ selectColumn(archiveList, setArchiveList, isSelectAllArchivedBenefits, setIsSelectAllArchivedBenefits), ...columns]

    return isLoading ? (<Spinner />) : (
        <div className="main-wrapper benefit-page">
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('benefits.title')}
                </h2>
                <div className="flex-center">
                    {
                        (roleType === UserTypeEnum.owner) && (
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="select-filter"
                                    id="select-filter"
                                    className="select-preview-filter"
                                    value={comFilter}
                                    onChange={handleFilterCommunity}
                                >
                                    <MenuItem value='all'>{t('common.selectFilters.allCommunities')}</MenuItem>
                                    {
                                        communities.map((el, index) => {
                                            return (<MenuItem key={index} value={el.id}>{el.communityName}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="select-filter"
                            id="select-filter"
                            className="select-preview-filter"
                            value={typeFilter}
                            onChange={handleTypeCommunity}
                        >
                            <MenuItem value='all'>{t('common.selectFilters.allTypes')}</MenuItem>
                            <MenuItem value='LEADS'>{t('common.selectFilters.leads')}</MenuItem>
                            <MenuItem value='LINK'>{t('common.selectFilters.link')}</MenuItem>
                            <MenuItem value='STORE'>{t('common.selectFilters.store')}</MenuItem>
                            <MenuItem value='SURVEY'>{t('common.selectFilters.survey')}</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        benefitsList.length ? (
                            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                                {t('common.buttons.preview')}
                            </Button>
                        ) : null
                    }
                </div>
            </div>
            <div className="sales-page">
                {
                    (benefitsList.length || archiveList.length || draftList.length) ? (
                        <>
												
                            {
                                benefitsList.length > 0 && (
                                    <div>
																				<div className="d-flex justify-between">
																					<h3>{t('benefits.all')} ({totalActiveArticles})</h3>  
                                          <Button
                                              className="link-button-container"
                                              onClick={handleArchiveSelected}
                                              size="large"
                                              variant="contained"
                                              color="primary"
                                          >
                                              {t("common.fieldHeaders.moveToArchive")}
                                          </Button>
                                        </div>
                                        <div className={`table-grid drag-and-drop-table`}>
                                            <DragAndDropTable
                                                columns={liveColumns}
                                                rows={benefitsList}
                                                onReorder={handleReorder}
                                                pagination
                                                rowsPerPage={rowsPerPage}
                                                totalRows={totalActiveArticles}
                                                pageType='benefit'
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                draftList.length > 0 && (
                                    <div>
                                        <h3>{t('common.drafts')} ({draftList.length})</h3>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={draftList}
                                                columns={lngDirection === "ltr" ? columns : reverseDirection(columns)}
                                                rowHeight={90}
                                                headerHeight={0}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                archiveList.length > 0 && (
                                    <div>
																				<div className="d-flex justify-between">
																					<h3>{t('common.archive')}</h3>
																					<Button
                                            className="link-button-container"
                                            onClick={handleDeleteSelected}
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            >
                                            {t("users.allUsers.delete")}
                                          </Button>
																				</div>
                                        <div className={`table-grid element-faded`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={archiveList}
                                                columns={lngDirection === "ltr" ? archivedColumns : reverseDirection(archivedColumns)}
                                                rowHeight={90}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                <>
                                    <PopUpActionMenu toggleArchive={toggleArchive}
                                        handleDuplicate={handleDuplicate}
                                        handleAction={handleNotificationOpen}
                                        handleGoToResults={handleGoToResults}
                                        currentPage="benefits" />
                                    {
                                        benefitsList.length ? (
                                            <PreviewModal className="create-benefit list-preview" openModal={openModal} handleClose={handleCloseModal} hideNotYet content={
                                                <div className="scroll-container">{benefitsList.map((benefit: any, index) => {
                                                    const image = benefit?.thumbnail ?? benefit?.image ?? '';
                                                    const pageType = benefit?.benefitType ?? '';
                                                    const categoryId = benefit?.categoryId ?? '';
                                                    const publishDateAndTime = benefit.publishDate;
                                                    const archiveDateAndTime = benefit.archiveDate;
                                                    const storeDateAndTime = benefit?.details?.date ?? null;
                                                    const price = benefit?.details?.price ?? 0;
                                                    const location = benefit?.details?.location ?? '';
                                                    const buttonText = benefit?.details?.button_text ?? '';

                                                    return (
                                                        <div key={index} className="preview-image-container">
                                                            <div className={`preview-image`}>
                                                                <img
                                                                    src={image}
                                                                    alt={benefit?.header ?? ''}
                                                                />
                                                            </div>
                                                            <div className="preview-text-container">
                                                                <div className="preview-text">
                                                                    <div>
                                                                        {
                                                                            (pageType === "STORE" || pageType === "SURVEY") && (
                                                                                <div className="preview-category">
                                                                                    {categoryId ? getElementFromArrayObject('id', 'categoryName', categoryId, categories) : t('common.fieldHeaders.category')}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <div className="preview-text-header">
                                                                            <span>
                                                                                {benefit?.header || t("common.fieldHeaders.header")}
                                                                            </span>
                                                                        </div>
                                                                        {
                                                                            (pageType === "LEADS" || pageType === "LINK") && (
                                                                                <div>
                                                                                    <span className="preview-description">
                                                                                        {benefit?.description ||
                                                                                            t("common.fieldHeaders.description")}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            pageType === "STORE" && (
                                                                                <>
                                                                                    {
                                                                                        storeDateAndTime && (
                                                                                            <div className="d-flex align-center">
                                                                                                <AccessTimeIcon className="time-icon" />
                                                                                                <div className="d-flex align-center store-date-time">
                                                                                                    <span className="store-date">{improvedConvertToDate(storeDateAndTime, "preview", "DD/MM")}</span>
                                                                                                    <span className="separator">|</span>
                                                                                                    <span className="store-time">{improvedConvertToDate(storeDateAndTime, "preview", "HH:MM")}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div className="store-price">{t('common.currency') + price}</div>
                                                                                    {
                                                                                        location && (
                                                                                            <div className="d-flex align-center">
                                                                                                <span className="location-icon"><LocationOnOutlinedIcon /></span>
                                                                                                <span>{location}</span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        (publishDateAndTime && archiveDateAndTime && (pageType === "LINK")) ? (
                                                                            <div className="d-flex date-text dates">
                                                                                <span>
                                                                                    {improvedConvertToDate(
                                                                                        publishDateAndTime,
                                                                                        "preview"
                                                                                    ) || t("common.fieldHeaders.dateFrom")}
                                                                                </span>
                                                                                <div>&nbsp; - &nbsp;</div>
                                                                                <span>
                                                                                    {improvedConvertToDate(archiveDateAndTime, "preview") ||
                                                                                        t("common.fieldHeaders.dateTo")}
                                                                                </span>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        pageType === "LINK" && (
                                                                            <div className="w-100">
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    className="w-100"
                                                                                    size="small"
                                                                                >
                                                                                    {buttonText ? buttonText : t('common.buttons.buttonText')}
                                                                                </Button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}</div>
                                            } />
                                        ) : null
                                    }
                                </>
                            }
                        </>
                    ) : <></>
                }
                {
                    !(benefitsList.length || archiveList.length || draftList.length) ? (
                        <div className="no-benefits">
                            <img src={NoArticles} alt="" />
                            <h3>{t('benefits.noBenefits.message1')}</h3>
                            <Link to="/benefits/create/sale" onClick={() => actionStore.toggleSearch(false)}>{t('benefits.noBenefits.message2')}</Link>
                        </div>
                    ) : <></>
                }
            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose(actionType)}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default observer(Sales)
