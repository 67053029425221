import PromotionalNotifications from "./PromotionalNotifications/PromotionalNotifications";
import AutomatedNotifications from "./AutomatedNotifications/AutomatedNotifications";

export const routes = [
    {
        path: 'promotional',
        component: PromotionalNotifications
    },
    {
        path: 'automated',
        component: AutomatedNotifications
    },
]
