import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  EMAIL: "email",
  PASSWORD: "password",
  // PLATFORM_ID: "platform_id"
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.EMAIL]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signIn.required"))
    .email(() => i18n.t("validationMessages.auth.signIn.email")),
  [FIELDS.PASSWORD]: Yup.string().required(() =>
    i18n.t("validationMessages.auth.signIn.required")
  ),
  // [FIELDS.PLATFORM_ID]: Yup.string().required(() =>
  //   i18n.t("validationMessages.articles.addArticle.required")
  // ),
});
