import React from 'react'
import "./RoundedImage.scss";


export interface RoundedImageComponentProps {
    imgSrc?: string;
}
const RoundedImage = (props: RoundedImageComponentProps) => {
    const { imgSrc } = props;
    return (
            <div className="image-container">
                <img src={imgSrc ? imgSrc : ''} alt="" />
            </div>

    )

}


export default RoundedImage