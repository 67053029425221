import React from 'react'
import ListRowComponent from '../../../../shared/components/ListRow/ListRow';
import EventItemLoader from '../../../../shared/components/Loaders/ThisWeekEventsLoader/ThisWeekEventsLoader';
import ProgressComponent from '../../../../shared/components/ProgressComponent/ProgressComponent';
import RoundedImage from '../../../../shared/components/RoundedImage/RoundedImage';
import "./EventItem.scss";
export interface EventItemComponentProps {
    imgSrc?: string;
    title?: string;
    totalTickets: number;
    soldTickets: number;
    isLoading?: boolean;
    openModal?: any
}
const EventItem = (props: EventItemComponentProps) => {
    const { imgSrc, title, totalTickets, soldTickets } = props
    return (
        <ListRowComponent>

            <div className="event-item-container">

                <div className={`event-item-content-wrapper  ${!props.isLoading && 'show'}`}  onClick={props.openModal}>

                    <RoundedImage imgSrc={imgSrc} />
                    <div className="event-content-wrapper">
                        <div className="event-name">{title ? title : 'Imagine Dragons Live!'}</div>
                        <div className="propgress-wrapper">
                            <ProgressComponent total={totalTickets} currentAmount={soldTickets} />
                        </div>
                        <span className="event-tickets-status">
                            <span>{soldTickets}</span>
                            <span>/</span>
                            <span>{totalTickets}</span>
                        </span>
                    </div>
                </div>
                <div className={`event-item-loader-wrapper ${!props.isLoading && 'hide'}`}>
                    <EventItemLoader />
                </div>
            </div>
        </ListRowComponent>
    )

}

export default EventItem