import Archive from './Archive/Archive';
import Community from './Community/Community';
import General from './General/General';
import ManageDepartments from './ManageDepartments/ManageDepartments';
import ManageCategories from './ManageCategories/ManageCategories';
import ManageGroups from './ManageGroups/ManageGroups';
import Permissions from './Permissions/Permissions';
export const routes = [
    {
        path: 'general',
        component: General
    },
    {
        path: 'community',
        component: Community
    },
    {
        path: 'archive',
        component: Archive
    },
    {
        path: 'managedCategories',
        component: ManageCategories
    },
    {
        path: 'manageGroups',
        component: ManageGroups
    },
    {
        path: 'manageDepartments',
        component: ManageDepartments
    },
    {
        path: 'permissions',
        component: Permissions
    },
]
