import React, { useEffect, useState } from "react";
import "./style.scss";
import IconComponent from "../../shared/components/IconComponent/IconComponent";
import DashDashIcon from "../../assets/icons/dash-dash.svg";
import { convertZuluToLocalTime, convertLocalToZuluTime } from "../../shared/utils/sharedFunctions";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { TablePagination } from "@material-ui/core";
import { flowResult } from "mobx";
import { useStore } from "../../store/StoreContext";
import Spinner from '../../components/Spinner/Spinner';
import NotificationModal from '../../components/NotificationModal/NotificationModal';
import { useTranslation } from "react-i18next";

const DragAndDropTable = ({ columns, rows, onReorder, pagination = false, rowsPerPage = 5, totalRows = 0, pageType = '' }) => {

    const TableRow = ({ row, index, rowID }) => {
        return (
            <Draggable draggableId={row.id} index={index}>
                {(provided) => (
                    <tr className="tr-body-wrapper" ref={provided.innerRef} {...provided.draggableProps}>
                        <td className="handle-container">
                            <div className="firstElement">
                                <div className="handle hover-item grab-icon" {...provided.dragHandleProps}>
                                    <IconComponent icon={DashDashIcon} />
                                </div>
                            </div>
                        </td>
                        {
                            columns.map((col, colIndex) => {
                                return (
                                    <td
                                        className={`table-row-title-container ${col?.flex ? 'flex-item' : ''}`}
                                        key={`tbd-${colIndex}-${row.id}`}
                                        style={{ width: col?.width ?? 'unset' }}
                                    >
                                        {col.renderCell({ row: {...row, index: rowID}})}
                                    </td>
                                );
                            })
                        }

                    </tr>
                )}
            </Draggable>
        );
    };


    const futureDate = (date, minutes = 1) => {
        date = new Date(convertZuluToLocalTime(date));
        const futureDate = new Date(date.getTime() + (minutes * 1000));
        return convertLocalToZuluTime(futureDate);
    }

    const Pagination = ({ onPageChange, onFetchPage }) => {
        const [currentPage, setCurrentPage] = useState(0);
        const [furtherPage, setFurtherPage] = useState(0);

        return (
            <TablePagination
                component="div"
                count={totalRows}
                onPageChange={(event, page: number) => {
                    setCurrentPage(page);
                    onPageChange(page);
                    if (furtherPage < page && rows.length < totalRows) {
                        onFetchPage(page);
                        setFurtherPage(page);
                    }
                }}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                className="table-pagination"
            />
        )
    };

    const Table = ({ columns, rows }) => {
        const { t } = useTranslation();
        const { actionStore } = useStore();
        const [items, setItems] = useState(rows);
        const [activeSort, setActiveSort] = useState('');
        const [activeOrder, setActiveOrder] = useState('');
        const [innerLoading, setInnerLoading] = useState(false);
        const [activePage, setActivePage] = useState(0);
        const [openNotificationModal, setOpenNotificationModal] = useState(false);
        const [notificationMessage, setNotificationMessage] = useState('');

        const getCurrentPageItems = (list, page, rowsPerPage) => {
            return list.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
        };

        const getNextPageData = async (params = {}) => {
            setInnerLoading(true);
            const activeParams = {
                [`is_archive${pageType !== 'benefit' ? 'd' : ''}`]: false,
                is_locked: false,
                is_draft: false,
                is_approved: true,
            }
            let offset = items.length;
            await flowResult(actionStore.getPathItems(pageType, { ...params, ...activeParams, limit: rowsPerPage, offset })).then(
                (response: any) => {
                    if (!response?.success) {
                        handleNotificationOpen(
                            response?.code
                                ? t(`apiMessages.${response.code}`)
                                : t("apiMessages.0")
                        );
                        return;
                    }
                    const data = pageType === 'ads' ? response.ads : (pageType === 'benefit' ? response.benefits : response.articles);
                    setItems(items.concat(data));
                }
            );
            setInnerLoading(false);
        };

        useEffect(() => {
            let minWidth = 0;
            columns.forEach((col) => {
                minWidth += col?.width ?? col?.minWidth ?? 0
            });
            const allBodyTrs: any = document.querySelectorAll('.tr-body-wrapper');
            const headerDiv: any = document.querySelector('.div-head-wrapper');
            headerDiv.style.minWidth = `${minWidth}px`;
            for (let i = 0; i < allBodyTrs.length; i++) {
                allBodyTrs[i].style.minWidth = `${minWidth}px`;
            }

            // Scroll Table Head when Table body is scrolled
            const scrollBody = document.querySelector('.drag-and-drop-body');
            const scrollHead = document.querySelector('.div-head-scroll');
            if (scrollBody && scrollHead) {
                scrollBody.addEventListener('scroll', (e) => {
                    scrollHead.scrollLeft = scrollBody.scrollLeft
                })
                return () => {
                    scrollBody.removeEventListener('scroll', (e) => {
                        scrollHead.scrollLeft = scrollBody.scrollLeft
                    })
                }
            }
        })

        const handleOnDragEnd = (list, setList) => (result) => {
            if (!result.destination) return;
            let items = Array.from(list);
            const duplicatedList = Array.from(list);
            const sourceIndex = result.source.index + (activePage * rowsPerPage);
            const destinationIndex = result.destination.index + (activePage * rowsPerPage);
            const draggedInfo = {
                ...list[sourceIndex],
                publishDate: futureDate(list[destinationIndex].publishDate, sourceIndex < destinationIndex ? -1 : 1),
                archiveDate: futureDate(list[destinationIndex].archiveDate, sourceIndex < destinationIndex ? -1 : 1),
            }
            const droppedInfo = {
                ...list[destinationIndex],
                publishDate: futureDate(list[destinationIndex].publishDate, sourceIndex < destinationIndex ? 1 : -1),
                archiveDate: futureDate(list[destinationIndex].archiveDate, sourceIndex < destinationIndex ? 1 : -1),
            }
            items = items.map((item: any, index) => {
                if (index === sourceIndex) {
                    return draggedInfo
                } else if (index === destinationIndex) {
                    return droppedInfo
                }
                return item;
            })
            const [reorderedItem] = items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, reorderedItem);
            setList(items);
            onReorder(draggedInfo).then((draggedResponse) => {
                onReorder(droppedInfo).then((droppedResponse) => {
                    if (draggedResponse || droppedResponse) {
                        setList(duplicatedList);
                        return;
                    }
                })
            })

        }

        const handleSortBy = (field) => {
            let sortedArray = Array.from(items);
            if (activeOrder !== 'dec') {
                const order = (activeOrder !== 'asc' || activeSort !== field) ? 'asc' : 'dec';
                sortedArray.sort((a: any, b: any) => {
                    const itemA = field === 'writer' ? a?.details?.[field] : a[field];
                    const itemB = field === 'writer' ? b?.details?.[field] : b[field];
                    if (order === 'asc') {
                        if (itemA < itemB) {
                            return -1;
                        }
                        if (itemA > itemB) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (itemA > itemB) {
                            return -1;
                        }
                        if (itemA < itemB) {
                            return 1;
                        }
                        return 0;
                    }

                })
                setActiveSort(field);
                setActiveOrder(order);
                setItems(sortedArray);
            } else {
                setActiveSort('');
                setActiveOrder('');
                setItems(rows);
            }
        }

        const handleNotificationOpen = (message = '') => {
            setNotificationMessage(message);
            setOpenNotificationModal(true);
        };

        return (
            <div className="table-wrapper">
                {
                    pagination && (
                        <Pagination
                            onPageChange={setActivePage}
                            onFetchPage={getNextPageData}
                        />
                    )
                }
                <div className="div-head-scroll">
                    <div className="div-head-wrapper d-flex flex-row align-center">
                        {
                            columns.map((col, index) => {
                                const field = col.field;
                                const isCentered = field === 'push' || field === 'actions';
                                return col?.sortable ? (
                                    <div
                                        key={`thd-${items?.[index]?.id ?? index}`}
                                        className={`div-th sort ${activeSort === field ? `active ${activeOrder === 'asc' ? 'asc' : 'dec'}` : ''} ${isCentered ? 'text-center' : ''}`}
                                        style={{ flex: col?.flex ?? 'unset', flexBasis: col?.width ?? 'unset' }}
                                        onClick={() => handleSortBy(field)}
                                    >

                                        {col.renderHeader({ colDef: col })}
                                        {
                                            activeOrder === 'dec' ? (
                                                <ArrowDownwardIcon className="sort-icon" />
                                            ) : (
                                                <ArrowUpwardIcon className="sort-icon" />
                                            )
                                        }
                                    </div>

                                ) : (
                                    <div
                                        key={`thd-${items?.[index]?.id ?? index}`}
                                        className={`div-th ${isCentered ? 'text-center' : ''}`}
                                        style={{ flex: col?.flex ?? 'unset', flexBasis: col?.width ?? 'unset' }}
                                    >
                                        {col.renderHeader({ colDef: col })}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <table className="table table-dark fixed-header">
                    <DragDropContext onDragEnd={handleOnDragEnd(items, setItems)}>
                        <Droppable droppableId="contents">
                            {(provided) => (
                                <tbody className="drag-and-drop-body" {...provided.droppableProps} ref={provided.innerRef}>
                                    {getCurrentPageItems(items, activePage, rowsPerPage).map((row, index) => row?.id ? (
                                        <TableRow
                                            key={`item-${row.id}`}
                                            index={index}
                                            rowID={index + (activePage * rowsPerPage) + 1}
                                            row={row}
                                        />
                                    ) : null)}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
                {
                    pagination && (
                        <Pagination
                            onPageChange={setActivePage}
                            onFetchPage={getNextPageData}
                        />
                    )
                }
                <NotificationModal
                    openModal={openNotificationModal}
                    handleClose={() => setOpenNotificationModal(false)}
                    handleButtonClick={() => setOpenNotificationModal(false)}
                    buttonText={t('common.buttons.close')}
                    message={notificationMessage}
                />
                {innerLoading && (<Spinner />)}
            </div>
        );
    };

    return (
        <Table
            columns={columns}
            rows={rows}
        />
    )
}

export default DragAndDropTable;

