import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";

export default class ActionStore {
    root: RootStore;
    switchLng = false;
    currentLanguage = localStorage.getItem('language') || 'he';

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setLanguage = (value) => {
        this.currentLanguage = value;
        localStorage.setItem('language', value);
    }

    getLanguage = () => {
        return this.currentLanguage;
    }

    toggleLanguage = () => {
        this.switchLng = !this.switchLng
    }
}
