import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  NEW_PASSWORD: "new_password",
  REPEAT_PASSWORD: "repeat_password",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.NEW_PASSWORD]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .min(6, () => i18n.t("validationMessages.auth.signUp.minPass")),
  [FIELDS.REPEAT_PASSWORD]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .min(6, () => i18n.t("validationMessages.auth.signUp.minPass")),
});
