import React, { useEffect, useState } from "react";
import { noContent } from "../../../db/db";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from "../../../assets/icons/export.svg";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import { exportToExcel } from "../../../shared/utils/sharedFunctions";
import PieChart from "./PieChart/PieChart";
import { Modal } from "@material-ui/core";

const SurveyInformationModal = () => {
  const { reportsStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const colors = ['#577BF9', '#00208D', '#36E0F6', '#EFDA1F'];
  const [questionsList, setQuestionsList] = useState<any>([]);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => { });
  const [surveyInformation, setSurveyInformation] = useState<any>({});
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [questionIndex, setQuestionIndex] = useState<any>(0);
  const [openParticipantsModal, setOpenParticipantsModal] = useState(false);
  const [pieSize, setPieSize] = useState(0);


  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    if (randomColor.length === 6) return `#${randomColor}`;
    else return generateRandomColor();
  }

  // get all surveys and fill table
  const getAllPurchaseInformation = async (id = surveyInformation.id) => {
    setIsLoading(true);
    const searchParams = reportsStore.getSearchParamsSurveys();

    await flowResult(reportsStore.getStoreSurveys(id, { ...searchParams })).then(
      (response: any) => {
        if (!response?.success) {
          // on error
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        // Settings new color for each answer
        const results = response["results"].map((question) => {
          return {
            ...question,
            answers: question.answers.map((answer) => {
              return {
                ...answer,
                percentage: Math.round(answer.percentage),
                color: generateRandomColor(),
              }
            })
          }
        })
        setQuestionsList(results);
        setTotalParticipants(response["participants"])
        setIsLoading(false);
      }
    );
  };

  // error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  const handleExport = async () => {
    setIsLoading(true)
    await flowResult(reportsStore.exportStoreSurveys(surveyInformation.id)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        const header = surveyInformation?.row?.header ?? surveyInformation?.header ?? '';
        exportToExcel(response.file, `${t('tabNavigation.reports.surveys').toLowerCase()}_${header.replace(' ', '_')}`);
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    if (reportsStore.triggerUpdateList) {
      getAllPurchaseInformation();
      reportsStore.triggerUpdateList = false;
    }
  }, [reportsStore.triggerUpdateList]);

  useEffect(() => {
    const survey = reportsStore.getSurveyInformation();
    const surveyID = survey?.id ?? '';
    if (surveyID) {
      getAllPurchaseInformation(surveyID);
      setSurveyInformation(survey);
      setTimeout(() => {
        setOpenSideMenu(true);
      });
    } else {
      setOpenSideMenu(false);
      setTimeout(() => {
        setSurveyInformation({});
      }, 500);
    }
  }, [reportsStore.surveyInformation]);

  const handleParticipantsPopUpOpen = (question) => {
    setQuestionIndex(question);
    setOpenParticipantsModal(true);
  }

  const convertToDateRangePicker = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    return `${date.getDate()}/${date.getMonth() + 1}/${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  useEffect(() => {
    let size = Math.round(window.innerWidth * 0.097);
    setPieSize(size);
  }, [])

  return (
    <div className={`surveys-modal-page-container ${surveyInformation.id ? 'open' : ''}`}>
      <div className={`main-wrapper surveys-modal-page ${openSideMenu ? 'open' : ''}`}>
        {isLoading ? (
          <Spinner className="surveys-modal-spinner" />
        ) : (
          <>
            <div className="survey-modal-header d-flex justify-between align-center">
              <div>
                <h3>{t('reports.surveys.informationModal.header')}</h3>
              </div>
              <div className="d-flex justify-between align-center">
                <div className="export-button d-flex justify-between align-center" onClick={handleExport}>
                  <span className="export-button-text">{t('reports.purchases.informationModal.export')}</span>
                  <IconComponent icon={ExportIcon} />
                </div>
                <div className="close-container d-flex justify-between align-center">
                  <CloseIcon className="icon-close" onClick={() => reportsStore.setSurveyInformation({})} />
                </div>
              </div>
            </div>
            <div className="survey-modal-footer">
              <div className="page-heading-wrap">
                <h2 className="page-heading">{surveyInformation?.row?.header ?? surveyInformation?.header ?? ''}</h2>
              </div>
              <div>
                <span className="total-count">
                  {t("reports.surveys.questionsCount", { count: questionsList.filter((question) => question.hasOptions).length })}
                </span>
                <span className="total-count">  &#8226;  </span>
                <span className="total-count">
                  {t("reports.surveys.participantsCount", { count: totalParticipants })}
                </span>
              </div>
              <div className="content-wrap" style={{ maxHeight: window.innerHeight * 0.80 }}>
                {questionsList?.length ? (
                  questionsList.map((question, index) => {
                    if (!question.hasOptions) return null
                    return (
                      <div key={index} className="question-container">
                        <div className="question-header">
                          <span className="question-number">
                            {t('reports.surveys.informationModal.question', { num: index + 1 })}
                          </span>
                          {
                            (question?.answers?.filter((answer) => !answer.isAnonymous)?.length > 0) && (
                              <>
                                <span className="total-count">  &#8226;  </span>
                                <span className="cursor-pointer link" onClick={() => handleParticipantsPopUpOpen(index)}>
                                  {t('reports.surveys.informationModal.showParticipants')}
                                </span>
                              </>
                            )
                          }
                        </div>
                        <div className="question-label">
                          {question.label}
                        </div>
                        <div className="question-results d-flex justify-between">
                          <div className="question-answers-container">
                            {
                              question?.answers.length ? question.answers.map((answer, index) => {
                                return (
                                  <div key={index} className="answer-container d-flex">
                                    <div
                                      className="colored-bullet"
                                      style={{ backgroundColor: answer?.color ?? 'black' }}
                                    />
                                    <div className="answer d-flex flex-column">
                                      <span className="answer-label">{answer.value}</span>
                                      <span className="answer-percent">{`(${answer.percentage}/100) ${answer.percentage}%`}</span>
                                    </div>
                                  </div>
                                )
                              }) : null
                            }
                          </div>
                          <div className="pie-chart" style={{ width: pieSize, height: pieSize }}>
                            <PieChart data={question.answers} colors={colors} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="no-content">
                    <img src={noContent.users} alt="No messages" />
                    <p>{t("reports.purchases.noContent.message1")}</p>
                    <span className="note">
                      {t("reports.purchases.noContent.message2")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <NotificationModal
          openModal={openNotificationModal}
          handleClose={handleNotificationClose}
          handleButtonClick={buttonAction}
          buttonText={buttonText}
          message={notificationMessage}
        />
        <Modal
          open={openParticipantsModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={`modal-preview participants-modal`}
        >
          <div className="question-container">
            <div className="question-header-container d-flex justify-between">
              <div className="header">
                {t('reports.surveys.informationModal.popUp.header')}
              </div>
              <div className="close-container">
                <CloseIcon className="icon-close" onClick={() => setOpenParticipantsModal(false)} />
              </div>
            </div>
            <div className="question-label-container">
              <div className="question-number">
                {t('reports.surveys.informationModal.question', { num: questionIndex + 1 })}
              </div>
              <div className="question-label">
                {questionsList?.[questionIndex]?.label ?? ''}
              </div>
            </div>
            {
              questionsList?.[questionIndex]?.answers?.length ? questionsList[questionIndex].answers.map((answer, index) => {
                return (
                  <div key={index} className="answer-container">
                    <div className="answer d-flex align-end">
                      <span className="answer-label">{answer.value}</span>
                      <span className="answer-percent">{`(${answer.percentage}/100)`}</span>
                    </div>
                    {
                      answer?.users ? (
                        <>
                          {
                            answer.users.filter((userInfo) => !userInfo.isAnonymous).map((userInfo, index) => {
                              return (
                                <div key={index} className="user-container d-flex justify-between align-center">
                                  <div className="username">
                                    {userInfo.user.firstName} {userInfo.user.lastName}
                                  </div>
                                  <div className="date">
                                    {convertToDateRangePicker(userInfo.date)}
                                  </div>
                                </div>
                              )
                            })
                          }
                          {
                            answer.users.filter((userInfo) => userInfo.isAnonymous).length > 0 && (
                              <div key={index} className="user-container d-flex justify-between align-center">
                                <div className="username">
                                  {t("reports.surveys.informationModal.anonymous")}: {answer.users.filter((userInfo) => userInfo.isAnonymous).length}
                                </div>
                                <div className="date">
                               {answer.users.map(userInfo=>
                                convertToDateRangePicker(userInfo.date)
                                )}
                                  </div>
                              </div>
                            )
                          }
                        </>
                      ) : null
                    }
                  </div>
                )
              }) : null
            }
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default observer(SurveyInformationModal);
