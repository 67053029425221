import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, TextField, Avatar } from "@material-ui/core";
import iPhoneTemplateImage from "../../../assets/images/settings/iPhoneTemplateImage.png";
import iPhoneTemplateImageTopNav from "../../../assets/images/settings/iPhoneTemplateImage-TopNav.png";
import iPhoneTemplateImageBottomNav from "../../../assets/images/settings/iPhoneTemplateImage-BottomNav.png";
import UploadFile from "../../../components/UploadFile/UploadFile";
import CommunityLogo from "../../../assets/icons/logo.svg";
import { FilterCalculator } from "./FilterCalculator";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { useTranslation } from "react-i18next";
import ProceedImage from "../../../shared/utils/proceedImage";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import { SketchPicker } from "react-color";
import "./style.scss";
import UserTypeEnum from "../../../models/UserType";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../shared/utils/cropImage";

const General = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { settingsStore, userStore } = useStore();
  const path = "general";

  const [header, setHeader] = useState("");
  const [logo, setLogo] = useState(CommunityLogo);
  const [colorTopNav, setColorTopNav] = useState(
    "invert(14%) sepia(67%) saturate(3396%) hue-rotate(221deg) brightness(89%) contrast(118%)"
  );
  const [colorBottomNav, setColorBottomNav] = useState(
    "invert(77%) sepia(8%) saturate(2804%) hue-rotate(161deg) brightness(99%) contrast(100%)"
  );

  const [isLoading, setIsLoading] = useState(true);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isLogout, setIsLogout] = useState(false);

  const [topColorPicker, setTopColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  const [primaryColor, setPrimaryColor] = useState("");
  const [bottomColorPicker, setBottomColorPicker] = useState(false);
  const [textColorPicker, setTextColorPicker] = useState(false);
  const [roleType, setRoleType] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [textColor, setTextColor] = useState("");

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<any>("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    cropImage(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(logo, croppedAreaPixels);
    setCroppedImage(croppedImage);
  };


  // Ref
  const headerRef: any = useRef(null);
  const backgroundColorRef: any = useRef(null);
  // const communityNameRef: any = useRef(null);
  const primaryColorRef: any = useRef(null);
  const navbarLogoRef: any = useRef(null);
  const senderRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.header?.message) {
      headerRef.current.scrollIntoView();
    } else if (errors?.background_color?.message) {
      backgroundColorRef.current.scrollIntoView();
    } else if (errors?.primary_color?.message) {
      primaryColorRef.current.scrollIntoView();
    } else if (errors?.navbar_logo?.message) {
      navbarLogoRef.current.scrollIntoView();
    } else if (errors?.sender_name?.message || errors?.sender_email?.message || errors?.sender_phone?.message) {
      senderRef.current.scrollIntoView();
    }
    const element = document.querySelector('.main-content-container');
    if (element) {
        element.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    setRoleType(userStore.getRoleType());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      header: "",
      background_color: "",
      community_name: "",
      primary_color: "",
      navbar_logo: "",
      text_color: "",
      sender_name: "",
      sender_email: "",
      sender_phone: "",
    },
  });

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    if (userStore.getRoleType() !== UserTypeEnum.owner) {
      setIsLoading(true);
      flowResult(settingsStore.getPathItems(path)).then((response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          setIsError(true);
          if (
            response?.message ===
            "You do not have access for the attempted action."
          ) {
            handleNotificationOpen(
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            setIsLogout(true);
            return;
          }
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        const data = response.community;
        setValue("community_name", data?.communityName ?? "");
        if (!data?.header) {
          setIsFirstTime(true);
          setIsLoading(false);
          return;
        }
        setValue("header", data?.header ?? "");
        setHeader(data?.header ?? "");
        setValue("background_color", data?.backgroundColor ?? "");
        setBackgroundColor(data?.backgroundColor ?? "");
        handleColorChange(data?.backgroundColor ?? "", setColorTopNav);
        setValue("primary_color", data?.primaryColor ?? "");
        setPrimaryColor(data?.primaryColor ?? "");
        handleColorChange(data?.primaryColor ?? "", setColorBottomNav);
        setValue("navbar_logo", data?.imageNavigationBarLogo ?? "");
        setValue("text_color", data?.textColor ?? "");
        setTextColor(data?.textColor ?? "");
        setLogo(data?.imageNavigationBarLogo ?? "");
        setValue("sender_name", data?.senderName ?? "");
        setValue("sender_email", data?.senderEmail ?? "");
        setValue(
          "sender_phone",
          data?.senderPhone
            ? `${data.senderPhone.slice(0, 3)} ${data.senderPhone.slice(3, 10)}`
            : ""
        );
        setIsLoading(false);
      });
    }
  }, []);

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const handleColorChange = (hexColor, setColorNav) => {
    if (hexColor?.length === 7) {
      const calculate = FilterCalculator(hexColor);
      if (calculate === "Invalid format!") {
        setIsError(true);
        handleNotificationOpen(t("errorMessages.settings.general.hexFormatError"));
        return;
      }
      setColorNav(calculate.filter);
    }
  };

  const handleFileUpload = async (data) => {
    if (data) {
      const image: any = await ProceedImage.toBase64(data);
      setValue("navbar_logo", image);
      setLogo(image);
      setIsNewPictureUploaded(true);
    }
  };

  const handleSubmitCheck = (objectData) => {
    setIsLoading(true);
    const data = {
      ...objectData,
      sender_phone: objectData.sender_phone.replace(" ", ""),
    };
    if (objectData?.navbar_logo) {
      data["navbar_logo"] = croppedImage || objectData.navbar_logo;
    }
    if (!data.text_color) {
      delete data.text_color
    }
    flowResult(settingsStore.update(data, path)).then(async (response: any) => {
      if (!response?.success) {
        setIsError(true);
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        setIsLoading(false);
        return;
      }
      await flowResult(userStore.getMyAccountInfo()).then((response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        userStore.updateStoredAccountInfo(response, true);
        setIsLoading(false);
      });
      handleNotificationOpen(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0")
      );
      setIsLoading(false);
    });
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isLogout) {
      userStore.logout();
      history.push("/sign-in");
    }
    if (roleType === UserTypeEnum.associatedOwner && !isError) {
      if (!isFirstTime) return;
      history.push("/community/view");
    }
  };

  const handleClose = () => {
    setTopColorPicker(false);
  };

  const handleCloseBottom = () => {
    setBottomColorPicker(false);
  };

  const handleCloseTextColor = () => {
    setTextColorPicker(false);
  };

  const handleClickToColor = () => {
    setTopColorPicker(true);
  };

  const handleClickToColorBottom = () => {
    setBottomColorPicker(true);
  };

  const handleClickToTextColor = () => {
    setTextColorPicker(true);
  };


  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper general-settings">
      <h2 className="page-heading">{t("settings.general.title")}</h2>
      <form
        className=""
        autoComplete="off"
        onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
      >
        <div className="main-wrapper">
          <Grid container className="content-grid" justifyContent="space-between">
            <Grid item xs={5} lg={4}>
              <Grid container>
                <div className="content-header">
                  <h3>{t("settings.general.topBar")}</h3>
                </div>
                <Grid item xs={12} ref={headerRef}>
                  <Controller
                    control={control}
                    name="header"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          id="header"
                          label={t("common.fieldHeaders.header")}
                          value={value}
                          onChange={(e) => {
                            setHeader(e.target.value);
                            onChange(e);
                          }}
                        />
                        <p className="field-error">
                          {errors?.header?.message ?? ""}&nbsp;
                        </p>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="grid-row background-color">
                  <Grid container>
                    <Grid item xs={3} lg={2}>
                      <div
                        className="background-color-box"
                        style={{
                          backgroundColor:
                            backgroundColor?.length === 7
                              ? backgroundColor
                              : "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} lg={10} ref={backgroundColorRef}>
                      <Controller
                        control={control}
                        name="background_color"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="background_color"
                              label={t("settings.general.backgroundColor")}
                              value={value}
                              onClick={handleClickToColor}
                              onChange={(e) => {
                                setBackgroundColor(e.target.value);
                                handleColorChange(
                                  e.target.value,
                                  setColorTopNav
                                );
                                onChange(e);
                              }}
                            />
                            <p className="field-error">
                              {errors?.background_color?.message ?? ""}&nbsp;
                            </p>

                            {topColorPicker ? (
                              <div className="popover">
                                <div className="cover" onClick={handleClose} />
                                <SketchPicker
                                  id="background_color_picker"
                                  color={value}
                                  onChange={(color) => {
                                    setBackgroundColor(color?.hex);
                                    handleColorChange(
                                      color?.hex,
                                      setColorTopNav
                                    );
                                    onChange(color?.hex);
                                  }}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <div className="content-header">
                  <h3>{t("settings.general.bottomBar")}</h3>
                </div>
                <Grid item xs={12} className="grid-row background-color">
                  <Grid container>
                    <Grid item xs={3} lg={2}>
                      <div
                        className="background-color-box"
                        style={{
                          backgroundColor:
                            primaryColor.length === 7 ? primaryColor : "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} lg={10} ref={primaryColorRef}>
                      <Controller
                        control={control}
                        name="primary_color"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="primary_color"
                              label={t("settings.general.primaryColor")}
                              value={value}
                              onClick={handleClickToColorBottom}
                              onChange={(e) => {
                                setPrimaryColor(e.target.value);
                                handleColorChange(
                                  e.target.value,
                                  setColorBottomNav
                                );
                                onChange(e);
                              }}
                            />
                            <p className="field-error">
                              {errors?.primary_color?.message ?? ""}&nbsp;
                            </p>
                            {bottomColorPicker ? (
                              <div className="popover">
                                <div
                                  className="cover"
                                  onClick={handleCloseBottom}
                                />
                                <SketchPicker
                                  id="primary_color_picker"
                                  color={value}
                                  onChange={(color) => {
                                    setPrimaryColor(color?.hex);
                                    handleColorChange(
                                      color?.hex,
                                      setColorBottomNav
                                    );
                                    onChange(color?.hex);
                                  }}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <div className="content-header">
                  <h3>{t("settings.general.text")}</h3>
                </div>
                <Grid item xs={12} className="grid-row background-color">
                  <Grid container>
                    <Grid item xs={3} lg={2}>
                      <div
                        className="background-color-box"
                        style={{
                          backgroundColor:
                            textColor.length === 7 ? textColor : "black",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} lg={10} ref={primaryColorRef}>
                      <Controller
                        control={control}
                        name="text_color"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="text_color"
                              label={t("settings.general.textColor")}
                              value={value}
                              onClick={handleClickToTextColor}
                              onChange={(e) => {
                                setTextColor(e.target.value);
                                onChange(e);
                              }}
                            />
                            <p className="field-error">
                              {errors?.text_color?.message ?? ""}&nbsp;
                            </p>
                            {textColorPicker ? (
                              <div className="popover">
                                <div
                                  className="cover"
                                  onClick={handleCloseTextColor}
                                />
                                <SketchPicker
                                  id="text_color_picker"
                                  color={value}
                                  onChange={(color) => {
                                    setTextColor(color?.hex);
                                    onChange(color?.hex);
                                  }}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} ref={navbarLogoRef}>
                  <UploadFile
                    format={["image/png"]}
                    text={<>{t("settings.general.uploadLogo")}</>}
                    handleFileUpload={handleFileUpload}
                  />
                  <span className="information-message">{t("informationMessages.pngOnly")}</span>
                  <p className="field-error">
                    {errors?.navbar_logo?.message ?? ""}&nbsp;
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} lg={4}>
              <div className="phone-container">
                <img src={iPhoneTemplateImage} alt="iPhoneTemplateImage" className="phone-container-template" />
                <img
                  src={iPhoneTemplateImageTopNav}
                  style={{ filter: colorTopNav }}
                  className="phone-container-top-nav"
                  alt="iPhoneTemplateImageTopNav"
                />
                <div className="phone-container-header">
                  <span className="phone-container-header-text">
                    {header || t("common.fieldHeaders.header")}
                  </span>
                </div>
                <img
                  src={iPhoneTemplateImageBottomNav}
                  style={{ filter: colorBottomNav }}
                  className="phone-container-bottom-nav"
                  alt="iPhoneTemplateImageBottomNav"
                />
                <div className="phone-container-bottom">
                  <Avatar
                    src={isNewPictureUploaded ? croppedImage : logo}
                    className="phone-container-logo"
                    alt="logo"
                  />
                </div>
                <div className="proportion-container">
                    <span className="text">{t('common.proportions', { x: 1 , y: 1 })}</span>
                </div>
                {
                  isNewPictureUploaded ? (
                    <div className="image-upload d-flex justify-center align-center w-100">
                      <div className={"image-wrap"}>
                          <Cropper
                            image={logo}
                            crop={crop}
                            zoom={zoom}
                            zoomSpeed={0.1}
                            cropShape="round"
                            minZoom={0.05}
                            maxZoom={4}
                            restrictPosition={false}
                            objectFit="vertical-cover"
                            cropSize={{
                              width: 250,
                              height: 250,
                            }}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                          />
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="main-wrapper">
          <div className="content-header">
            <h3>{t("settings.general.emailSettings")}</h3>
          </div>
          <Grid
            container
            className="content-grid"
            justifyContent="space-between"
            direction="column"
          >
            <Grid item xs={5} lg={4} className="grid-row" ref={senderRef}>
              <Controller
                control={control}
                name="sender_name"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      id="sender_name"
                      label={t("settings.general.sendersName")}
                      value={value}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <p className="field-error">
                      {errors?.sender_name?.message ?? ""}&nbsp;
                    </p>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={5} lg={4} className="grid-row">
              <Controller
                control={control}
                name="sender_email"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      id="sender_email"
                      label={t("settings.general.sendersEmail")}
                      value={value}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <p className="field-error">
                      {errors?.sender_email?.message ?? ""}&nbsp;
                    </p>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={5} lg={4} className="grid-row">
              <Controller
                control={control}
                name="sender_phone"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      id="sender_phone"
                      label={t("settings.general.sendersPhone")}
                      value={value}
                      onChange={(e) => {
                        const value: string = e.target.value.trim().replace(' ', '');
                        if (isNaN(Number(value))) {
                          return;
                        }
                        onChange(value)
                      }}
                      autoComplete="off"
                    />
                    <p className="field-error">
                      {errors?.sender_phone?.message ?? ""}&nbsp;
                    </p>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className="submit-buttons">
          <Button variant="contained" color="primary" type="submit" onClick={() => setTimeout(() => scrollToError())}>
            {t("common.buttons.save")}
          </Button>
        </div>
      </form>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose}
        handleButtonClick={handleNotificationClose}
        buttonText={t("common.buttons.close")}
        message={notificationMessage}
      />
    </div>
  );
};

export default General;
