import React from 'react'
import { useTranslation } from 'react-i18next';
import ListRowComponent from '../../../../shared/components/ListRow/ListRow';
import LatestActivityLoader from '../../../../shared/components/Loaders/LatestActivityLoader/LatestActivityLoader';
import "./ActivityItemComponent.scss";

export interface ActivityItem {
    activityInfo: any;
    isLoading?: boolean;
    openModal?: any;
}

const getUserInitials = (fullName: string) => {
    const [firstName, lastName] = fullName.split(" ")
    return `${firstName?.[0]?.toUpperCase() ?? ""}${lastName?.[0]?.toUpperCase() ?? ""}`
}

const ActivityItemComponent = (props: ActivityItem) => {
    const { t } = useTranslation();
    const { activityInfo } = props;
    const fullName = `${activityInfo?.info?.user?.firstName ?? ''} ${activityInfo?.info?.user?.lastName ?? ''}`;
    const userImg = () => {
        const imgSrc = activityInfo?.info?.user?.image;
        if (imgSrc?.length)
            return <img src={imgSrc} alt="" />

        return <div className="user-without-img">{getUserInitials(fullName)}</div>
    }

    const convertToMessage = (item) => {
        const action = item?.info?.code ? t(`apiMessages.${item.info.code}`) : '';
        const headerName = item?.info?.header ?? '';
        return `${fullName} ${action} ${headerName}`
    }


    return (
        <ListRowComponent>
            <div className={`activity-item-container`} onClick={props.openModal}>

                <div className={`activity-item-content-wrapper  ${!props.isLoading && 'show'}`}>
                    <div className="profile-img-wrapper">

                        {
                            userImg()
                        }
                    </div>

                    <div className="activity-text-wrapper">
                        <div className="activity-text">{convertToMessage(activityInfo)}</div>
                    </div>
                </div>
                <div className={`latest-activity-loader-wrapper ${!props.isLoading && 'hide'}`}>
                    <LatestActivityLoader isLoading={props.isLoading} />
                </div>

            </div>






        </ListRowComponent>
    )

}


export default ActivityItemComponent