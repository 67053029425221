import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TabsComponent from "../../shared/components/Tabs/TabsComponent";
import Archive from "./Archive/Archive";
import Community from "./Community/Community";
import General from "./General/General";
import ManageDepartments from "./ManageDepartments/ManageDepartments";
import ManageCategories from "./ManageCategories/ManageCategories";
import ManageGroups from "./ManageGroups/ManageGroups";
import Permissions from "./Permissions/Permissions";
import { Props } from "./props";
import { routes } from "./routes";
import "./style.scss";
import { useStore } from "../../store/StoreContext";
import UserTypeEnum from "../../models/UserType";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

const Settings = (props: Props) => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const { path } = useRouteMatch();
  const [roleType, setRoleType] = useState(null);

  useEffect(() => {
    setRoleType(userStore.getRoleType());
  }, [userStore.roleType]);

  const tabs = [
    {
      title: t("tabNavigation.settings.general"),
      component: General,
      url: `${path}/general`,
      hide: roleType === UserTypeEnum.owner,
    },
    {
      title: t("tabNavigation.settings.myCommunity"),
      component: Community,
      url: `${path}/community`,
      hide: roleType === UserTypeEnum.owner,
    },
    {
      title: t("tabNavigation.settings.archive"),
      component: Archive,
      url: `${path}/archive`,
      hide: roleType === UserTypeEnum.owner,
    },
    {
      title: t("tabNavigation.settings.manageCategories"),
      component: ManageCategories,
      url: `${path}/manageCategories`,
      hide: false,
    },
    {
      title: t("tabNavigation.settings.manageGroups"),
      component: ManageGroups,
      url: `${path}/manageGroups`,
      hide:
        roleType === UserTypeEnum.chiefEditor ||
        roleType === UserTypeEnum.editor,
    },
    {
      title: t("tabNavigation.settings.manageDepartments"),
      component: ManageDepartments,
      url: `${path}/manageDepartments`,
      hide: roleType === UserTypeEnum.owner,
    },
    {
      title: t("tabNavigation.settings.permissions"),
      component: Permissions,
      url: `${path}/permissions`,
      hide:
        roleType === UserTypeEnum.chiefEditor ||
        roleType === UserTypeEnum.editor,
    },
    {
      title: t("tabNavigation.settings.communityPolicy"),
      component: PrivacyPolicy,
      url: `${path}/privacy-policy`,
      hide:
        roleType !== UserTypeEnum.owner &&
        roleType !== UserTypeEnum.associatedOwner,
    },
  ];
  return (
    <>
      <div className="main-content main-content-tabs settings-page">
        <Switch>
          <TabsComponent data={tabs} />
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={`${path}/${route.path}`}
                exact
                component={route.component}
              />
            );
          })}
        </Switch>
      </div>
    </>
  );
};

export default observer(Settings);
