import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { FIELDS, VALIDATIONS } from "./fields";
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../../store/StoreContext';
import Spinner from '../../../components/Spinner/Spinner';
import { flowResult } from 'mobx';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { formatToPhoneNumber } from '../../../shared/utils/sharedFunctions';

const CreateCommunity = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { groupStore, communityStore, userStore } = useStore();
    const [isLoading, setIsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isRedirect, setIsRedirect] = useState(true);

    const [groups, setGroups] = useState([]);

    // Notification Modal
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        const storedGroups = groupStore.getStoredGroups();
        setGroups(storedGroups);
        setIsLoading(false);
    }, [groupStore.groups])

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: 'onChange',
        defaultValues: {
            "community_name": "",
            "group_id": "",
            "first_name": "",
            "last_name": "",
            "phone": "",
            "email": "",
            "password": "",
        }
    });


    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    const handleSubmitCheck = (dataObject) => {
        setIsLoading(true);
        const data = {
            "community_name": dataObject.community_name,
            "group_id": dataObject.group_id,
            "chief_editor": {
                "first_name": dataObject.first_name,
                "last_name": dataObject.last_name,
                "phone": dataObject.phone.replace(' ', ''),
                "email": dataObject.email,
                "password": dataObject.password,
            }
        }
        flowResult(communityStore.create(data)).then(
            (response: any) => {
                setIsLoading(false);
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), false)
                    return;
                }
                handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), true)
            }
        );
    }

    const handleNotificationOpen = (message = '', isRedirect) => {
        setNotificationMessage(message);
        setIsRedirect(isRedirect);
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = async () => {
        setOpenNotificationModal(false);
        if (isRedirect) {
            setIsLoading(true);
            await flowResult(userStore.getMyAccountInfo()).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        return
                    }
                    userStore.updateStoredAccountInfo(response);
                    setIsLoading(false);
                }
            );
            userStore.triggerHomeNavigatorUpdate =
                !userStore.triggerHomeNavigatorUpdate;
            history.push('/settings/community')
        }
    }

    return isLoading ? (<Spinner />) : (
        <>
            <form className="create-community" autoComplete="off" onSubmit={handleSubmit((d) => handleSubmitCheck(d))}>
                <div className="page-heading-wrap">
                    <h2 className="page-heading">
                        {t('community.create.title')}
                    </h2>
                </div>
                <div className="main-wrapper">
                    <h3 className="page-heading">
                        {t('community.create.subTitle')}
                    </h3>
                    <Grid container className="content-grid" direction="column">
                        <Grid item xs={5} lg={4}>
                            <Controller
                                control={control}
                                name="community_name"
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <TextField id="community_name"
                                            label={t('community.list.name')}
                                            value={value}
                                            onChange={onChange}
                                            autoComplete="off"
                                        />
                                        <p className="field-error">{errors?.community_name?.message ?? ''}&nbsp;</p>
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <FormControl>
                                <InputLabel id="group-label">{t('community.list.group')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="group_id"
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Select
                                                labelId="group-label"
                                                id="group"
                                                displayEmpty
                                                defaultValue=""
                                                value={value}
                                                onChange={onChange}
                                                disabled={!groups.length}
                                            >
                                                {
                                                    groups.map((group: any, index) => (
                                                        <MenuItem key={index}
                                                            value={group.id}>{group.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>

                                            {
                                                groups.length ? (
                                                    <p className="field-error">{errors?.group_id?.message ?? ''}&nbsp;</p>
                                                ) : (
                                                    <p className="field-error">{t('errorMessages.articles.create.noGroups')}</p>
                                                )
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <p>{t('community.create.ce')}</p>
                        </Grid>
                        <Grid item xs={5} lg={4} className="grid-row item-box">
                            <Grid container className="content-grid" justifyContent="center">
                                <Grid item xs={11}>
                                    <Controller
                                        control={control}
                                        name="first_name"
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField id="first_name"
                                                    label={t('signUp.fields.firstName.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                                <p className="field-error">{errors?.first_name?.message ?? ''}&nbsp;</p>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <Controller
                                        control={control}
                                        name="last_name"
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField id="last_name"
                                                    label={t('signUp.fields.lastName.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                                <p className="field-error">{errors?.last_name?.message ?? ''}&nbsp;</p>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <Controller
                                        control={control}
                                        name="phone"
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField id="phone"
                                                    type="text"
                                                    value={value}
                                                    onChange={(е) => onChange(formatToPhoneNumber(е.target.value, value))}
                                                    label={t('signUp.fields.phone.label')}
                                                    autoComplete="off"
                                                />
                                                <p className="field-error">{errors?.phone?.message ?? ''}&nbsp;</p>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <TextField id="email"
                                                    label={t('signUp.fields.email.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                                <p className="field-error">{errors?.email?.message ?? ''}&nbsp;</p>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl className="password-field-container">
                                        <InputLabel
                                            htmlFor="password">{t('signUp.fields.password.label')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name="password"
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <Input
                                                        id="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="off"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    className="visibility-icon"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    onMouseDown={(e) => e.preventDefault()}
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </FormControl>
                                    <p className="field-error">{errors?.password?.message ?? ''}&nbsp;</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="submit-button">
                    <Button variant="contained" color="primary" type="submit" disabled={!groups.length}>{t('common.buttons.next')}</Button>
                </div>
            </form>
            <NotificationModal openModal={openNotificationModal} buttonText={t('common.buttons.close')}
                handleClose={handleNotificationClose} handleButtonClick={handleNotificationClose}
                message={notificationMessage} />
        </>
    )
}

export default CreateCommunity
