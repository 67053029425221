import React from 'react';
import './App.scss';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {themeSettings} from "./theme";
import {RootNavigator} from "./navigation";


const theme = createTheme(themeSettings, { index: 1 });

function App() {
    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <RootNavigator />
            </MuiThemeProvider>
        </div>
    );
}

export default App;
