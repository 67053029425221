import React from 'react';
import IconComponent from '../IconComponent/IconComponent';
import ActionCardLoader from '../Loaders/ActionCardLoader/ActionCardLoader';
import "./CardComponent.scss";
export interface CardComponentProps {
    icon?: string;
    content?: string;
    isLoading?: boolean;
    openModal?: any;
}
const CardComponent = (props: CardComponentProps) => {

    return (
        <div className="card-component-wrapper">
            <div className={`card-item-content-wrapper  ${!props.isLoading && 'show'}`} onClick={props.openModal}>
                <IconComponent icon={props.icon} />
                <div className="card-content">{props.content}</div>
            </div>
            <div className={`card-item-loader-wrapper ${!props.isLoading && 'hide'}`}>
                <ActionCardLoader />
            </div>
        </div>
    )

}


export default CardComponent