import React, { useEffect, useState } from 'react'
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './style.scss';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import TabsComponent from '../../../shared/components/Tabs/TabsComponent';
import MyArticles from '../Articles/MyArticles';
import { useTranslation } from 'react-i18next';
import PushNotifications from '../Notifications/PushNotifications';
import { routes } from './route';
import AllMessages from '../Messages/AllMessages';
import CreateArticle from '../Articles/Create/CreateArticle';
import ViewArticle from '../Articles/View/ViewArticle';
import CreateNotification from '../Notifications/Create/CreateNotification';
import ViewNotification from '../Notifications/View/ViewNotification';
import SuccessfulNotifications from '../Notifications/Create/SuccessfulNotifications/SuccessfulNotifications';
import SuccessfulArticle from '../Articles/Create/SuccessfulArticle/SuccessfulArticle';
import CreateUser from '../Users/Create/CreateUser';
import {lngChange} from "../../../i18n/i18n";

const HomePage = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const [showFooter, setShowFooter] = useState(true);

    
    const tabs = [
        {
            title: t('mobile.articles.title'),
            component: MyArticles,
            url: `${path}/articles/list`
        },
        {
            title: t('mobile.notifications.title'),
            component: PushNotifications,
            url: `${path}/notifications/list`
        },
    ];
    
    useEffect(() => {
        const pathName = history.location.pathname;
        switch (pathName) {
            case path:
                setShowFooter(true);
                break;
            case `${path}/articles/list`:
                setShowFooter(true);
                break;
            case `${path}/notifications/list`:
                setShowFooter(true);
                break;
            case `${path}/my-messages`:
                setShowFooter(true);
                break;
            case `${path}/articles/create/success`:
                setShowFooter(true);
                break;
            case `${path}/notifications/create/success`:
                setShowFooter(true);
                break;
            default:
                setShowFooter(false);
                break;
        }

    }, [window.location.pathname]);

    // useEffect(() => {
    //     //add flag initial
    //     // lngChange();
    // }, [])

    return (
        <div className="mobile-container">
            <Header />
            <div className={`mobile-content ${!showFooter ? 'no-footer' : ''}`}>
                <Switch>
                    <Route path={`${path}/my-messages`} exact component={AllMessages} />
                    <Route path={`${path}/articles/create`} exact component={CreateArticle} />
                    <Route path={`${path}/articles/create/success`} exact component={SuccessfulArticle} />
                    <Route path={`${path}/articles/:type/:id`} component={ViewArticle} />
                    <Route path={`${path}/notifications/create`} exact component={CreateNotification} />
                    <Route path={`${path}/notifications/create/success`} exact component={SuccessfulNotifications} />
                    <Route path={`${path}/notifications/:type/:id`} component={ViewNotification} />
                    <Route path={`${path}/users/create`} exact component={CreateUser} />
                    <TabsComponent className="mobile-tabs-container" data={tabs} />
                    {
                        routes.map((route, index) => {
                            return (
                                <Route key={index} path={`${path}/${route.path}`} exact component={route.component} />
                            )
                        })
                    }
                </Switch>
            </div>
            {
                showFooter ? (<Footer />) : null
            }
        </div>
    )
}

export default HomePage
