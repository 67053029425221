export default class ProceedImage {

    static async toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    static validBase64(image: string): boolean {
        return image?.length > 0 && image.startsWith("data");
    }
}
