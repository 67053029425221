
import React, {useEffect, useState} from "react";
import { Doughnut } from 'react-chartjs-2'
import PieLagents from "./PieLagents/PieLagents";
import "./PieChart.scss";
import PieLoader from "../Loaders/PieLoader/PieLoader";
import { useTranslation } from "react-i18next";

export interface LegentInfo {
    amountInPrecent: string;
    label: string;
    color?: string
}
export interface PieChartInfo {
    data: number[];
    legentInfo: LegentInfo[];
    isLoading?: boolean
}
const PieChart = (props: PieChartInfo) => {
    const { t } = useTranslation();
    const [extraLoading, setExtraLoading] = useState(true);
    const [pieSize, setPieSize] = useState(0);

    useEffect(() => {
      const timeout =  setTimeout(() => {
          setExtraLoading(false)
      }, 1500);
      return () => {
          clearTimeout(timeout);
      }
    })

    useEffect(() => {
        let size = Math.round(window.innerWidth * 0.1125);
        setPieSize(size);
    }, [])

    const labelList: string[] = props.legentInfo?.map(legentObj => legentObj.label) ?? [];

    const data = {
        datasets: [{
            data: props.data,
            borderWidth: 0,
            backgroundColor: [
                '#577BF9',
                '#00208D',
                '#36E0F6',
                '#EFDA1F',
            ],
            hoverBackgroundColor: [
                '#577BF9',
                '#00208D',
                '#36E0F6',
                '#EFDA1F',
            ]
        }],
        labels: labelList

    }
    return (
        <div className="pie-chart-container">


            <div className={`fade-effect-wrapper ${!props?.isLoading && 'visible'}`}>
                {
                    props?.legentInfo?.length ? (
                        <>
                        <div className={`chart-item ${!props?.isLoading && 'visible'}`}>
                            {!extraLoading &&
                                <React.Fragment>
                                    <Doughnut
                                        width={pieSize}
                                        height={pieSize}
                                        data={data}
                                        options={{
                                            borderWidth: 0,
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            cutoutPercentage: 88,
                                            legend: {
                                                display: false
                                            },
                                            tooltips: {
                                                callbacks: {
                                                    label: (tooltipItem: any, data: any) => {
                                                        var dataset = data['datasets'][0];
                                                        const shortTitle: string = data['labels'][tooltipItem['index']];
                                                        const percentage: number = dataset['data'][tooltipItem['index']];
                                                        return `${shortTitle.substr(0, 20)} - ${percentage}%`;
                                                    },
                                                },
                                                display: 'flex',
                                                displayColors: true
                                            },
                                            plugins: {
                                                datalabels: {
                                                    opacity: 0,
                                                }
                                            }

                                        }}
        
                                    />
                                </React.Fragment>
                            }
                        </div>
                        <PieLagents lagentInfo={[...props.legentInfo]} />
                        </>
                    ) : (<p>{t('dashboard.noPopularActivities')}</p>)
                }
            </div>
            <PieLoader isLoading={props.isLoading} />
        </div>
    )
}
export default PieChart;
