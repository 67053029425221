import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class SettingsStore {
    root: RootStore;

    userPermissions = {
        id: "",
        name: "",
        ads: false,
        banners: false,
        benefits: false,
        articles: false,
        users: false,
        push: false,
        reports: false
    };

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setUserPermissions(permissions) {
        this.userPermissions = permissions;
    }

    getUserPermissions() {
        return this.userPermissions;
    }

    * getPathItems(path) {
        const response = yield Api.get(`/admin/settings/${path}`);
        return response?.data
    }

    * create(payload: any, path) {
        const response = yield Api.post(`/admin/settings/${path}`, payload);
        return response?.data;
    }

    * update(payload: any, path, id = '') {
        const response = yield Api.put(`/admin/settings/${id ? `${path}/${id}` : path}`, payload);
        return response?.data;
    }

    * delete(path, id) {
        const response = yield Api.delete(`/admin/settings/${path}/${id}`);
        return response?.data;
    }
}
