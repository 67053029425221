import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link as MuiLink,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../store/StoreContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import { flowResult } from "mobx";
import TermsAndPrivacyPolicy from "../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy";
import LanguageSelection from "../../../components/LanguageSelection/LanguageSelection";
import { useTranslation } from "react-i18next";
import Spinner from '../../../components/Spinner/Spinner';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userStore } = useStore();

  const [values, setValues] = useState({
    showPassword: false,
    showRetypePassword: false,
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState("");

  const handleClickShowPassword = (prop) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      new_password: "",
      repeat_password: "",
    },
  });

  const [submitError, setSubmitError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const watchFields = watch();
  const validate = async (form: any) => {
    // @ts-ignore
    const resolver = yupResolver(VALIDATIONS)(form, null, {
      criteriaMode: "firstError",
    });
    setIsDisabled(Object.values((await resolver).errors).length > 0);
  };

  useEffect(() => {
    validate(watchFields);
  }, [watchFields]);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    const windowHeight = window.outerHeight;
    if (windowHeight < 768) {
      setWindowHeight(`${windowHeight}px`);
    } else {
      setWindowHeight("100%")
    }
    setIsLoading(false);
  }, [])

  const handleSubmitCheck = (payload) => {
    setSubmitError("");
    setPasswordError("");
    if (payload["new_password"] !== payload["repeat_password"]) {
      setPasswordError(t("errorMessages.signUpMissMatchPass"));
      return;
    } else {
      const search = history.location.search;
      const urlParams = new URLSearchParams(search);
      const token = urlParams.get('token');
      if (!token) {
        //@todo: show error message
        return;
      }
      flowResult(
        userStore.resetPassword({
          ...payload,
          token: token,
        })
      ).then((response: any) => {
        if (!response?.success) {
          setSubmitError(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
        } else {
          history.push("/sign-in");
        }
      });
    }
  };

  return isLoading ? (<Spinner />) : (
    <div className="scroll-container" style={{ height: windowHeight }}>
      <div className="sign-form-container">
        <div className="inner-full-container">
          <div className="center-container">
            <form
              onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
              className="sign-form"
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="sign-container"
              >
                <Grid item xs={10} sm={6} md={5} lg={4}>
                  <Grid container>
                    <Grid item xs={12} className="sign-header">
                      <div className="double-column">
                        <div>
                          <h2>{t("resetPassword.title")}</h2>
                        </div>
                        <LanguageSelection />
                      </div>
                    </Grid>
                    <Grid item xs={12} className="sign-body">
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid
                          item
                          xs={12}
                          className="sign-image-container flex-center"
                        >
                          <div className="sign-image-and-icon">
                            <img
                              src="https://d29fhpw069ctt2.cloudfront.net/icon/image/37722/preview.svg"
                              alt=""
                              className="sign-image"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} className="sign-field">
                          <FormHelperText error>
                            {submitError || passwordError}&nbsp;
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} className="sign-field">
                          <h3>{t("resetPassword.subTitle")}</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="password-field-container">
                            <InputLabel htmlFor="password">
                              {t("signUp.fields.password.label")}
                            </InputLabel>

                            <Controller
                              control={control}
                              name="new_password"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Input
                                    id="new_password"
                                    type={
                                      values.showPassword ? "text" : "password"
                                    }
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          className="visibility-icon"
                                          onClick={() =>
                                            handleClickShowPassword(
                                              "showPassword"
                                            )
                                          }
                                          onMouseDown={(e) => e.preventDefault()}
                                        >
                                          {values.showPassword ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                  <p className="field-error">
                                    {errors?.new_password?.message ?? ""}&nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="password-field-container">
                            <InputLabel htmlFor="retypePassword">
                              {t("signUp.fields.retypePassword.label")}
                            </InputLabel>

                            <Controller
                              control={control}
                              name="repeat_password"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Input
                                    id="repeat_password"
                                    type={
                                      values.showRetypePassword
                                        ? "text"
                                        : "password"
                                    }
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          className="visibility-icon"
                                          onClick={() =>
                                            handleClickShowPassword(
                                              "showRetypePassword"
                                            )
                                          }
                                          onMouseDown={(e) => e.preventDefault()}
                                        >
                                          {values.showRetypePassword ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                  <p className="field-error">
                                    {errors?.repeat_password?.message ?? ""}&nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className="sign-buttons">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isDisabled}
                          >
                            {t("common.buttons.send")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <div className="terms-and-policy cursor-pointer">
              <MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>
                {t("common.termsAndPrivacy")}
              </MuiLink>
            </div>
          </div>
          <TermsAndPrivacyPolicy
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
