import React from 'react';
import "./ButtomWithIcon.scss"
export interface ButtonProps {
    icon:string;
}
const ButtomWithIcon = (props: ButtonProps) => {
    return (
        <button className="btn-with-icon-wrapper">
            <img src={props.icon} alt=""/>
        </button>  
    )

}


export default ButtomWithIcon