import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { useStore } from "../store/StoreContext";
import { HomeNavigator } from "./index";
import "./style.scss";
import AuthenticationNavigator from './AuthenticationNavigator';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n, { lngChange, SUPPORTED_LANGUAGES } from '../i18n/i18n';

const RootNavigator = () => {
    const { userStore, languageStore } = useStore();
    const [platform, setPlatform] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const role = localStorage.getItem('role');
        if (token) {
            userStore.setToken(token);
            userStore.isAuthenticated = true;
            if (role) {
                userStore.setRole(role);
            }
        }
        const platform = window.location.pathname.replace('/', '').split('/')[0];
        const platformSetting = userStore.setPlatformSettings(platform);
        setPlatform(platformSetting);
        const search = window.location.href.split('?')[1];
        const searchParams = new URLSearchParams(search);
        const lang = searchParams.get('language');
        // TODO: change
        if(lang && SUPPORTED_LANGUAGES.includes(lang)) {
          localStorage.setItem('language', lang);
          languageStore.setLanguage(lang);
          languageStore.toggleLanguage();
          lngChange(lang);
        }
        console.log(search, lang, SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES.includes(lang));
    }, [])

    return platform && (
        <Router basename={`/${platform}`} getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
        }}>
            {userStore.isAuthenticated ? <HomeNavigator /> : <AuthenticationNavigator />}
        </Router>
    )
};

export default observer(RootNavigator)
