import { Button, Modal } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CloseIcon from '@material-ui/icons/Close';
import "./style.scss";
import { useTranslation } from "react-i18next";


const UnsavedChangesPrompt = (props) => {
  const { t } = useTranslation();
  const { when, onSave, onCancel } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, when]);

  const handleSave = useCallback(async () => {
    if (onSave) {
      const canRoute = await Promise.resolve(onSave());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onSave]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  const handleClose = () => {
    setShowPrompt(false);
  }

  return showPrompt ? (
    <Modal
      open={showPrompt}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`modal-preview unsaved-changes ${props.className}`}
    >
      <div className="notification-container">
        <div className="message-container">
          <span className="message">{t("unsavedChanges.areYouSure")}</span>
          <p className="sub-message">{t("unsavedChanges.permanent")}</p>
        </div>
        <div className="button-container">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            className="action-button"
          >
            {t("unsavedChanges.yesLeave")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            className="action-button"
          >
            {t("common.buttons.saveAsDraft")}
          </Button>
        </div>
        <div className="close-container">
          <CloseIcon className="icon-close" onClick={handleClose} />
        </div>
      </div>
    </Modal>
  ) : null;
}

export default UnsavedChangesPrompt