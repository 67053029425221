import React, { useEffect, useState } from 'react'
import { Avatar, Grid } from '@material-ui/core';
import { loggedUser } from "../../../../db/db";
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';
import { useStore } from '../../../../store/StoreContext';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface Props {
    open: boolean;
    handleClose: any;
}


const SideMenu = (props: Props) => {
    const { t } = useTranslation();
    const { userStore } = useStore();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [roleType, setRoleType] = useState('');

    useEffect(() => {
        setUsername(`${userStore.currentUser.firstName ?? 'Oral'} ${userStore.currentUser.lastName ?? 'Murrey'}`);
        setRoleType(userStore.getRoleType());
    }, [])

    const handleNewArticle = () => {
        history.push('/mobile/articles/create');
        props.handleClose();
    }

    const handleNewPush = () => {
        history.push('/mobile/notifications/create');
        props.handleClose();
    }

    const handleNewUser = () => {
        history.push('/mobile/users/create');
        props.handleClose();
    }

    return (
        <div className={`mobile-side-menu ${props.open ? 'opened' : ''}`}>
            <div className="content-container">
                <Grid container justifyContent="center" alignItems="center" direction="column" className="user-container">
                    <Grid item className="avatar-icon">
                        <Avatar src={userStore?.user?.image || loggedUser.avatar} />
                    </Grid>
                    <Grid item>
                        <span><strong>{username}</strong></span>
                    </Grid>
                    <Grid item>
                    <span className="role-title">{t('mobile.sideMenu.role')} {t(`users.create.${roleType}`)}</span>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignContent="space-between" className="action-container">
                    <Grid item xs={12} className="create-actions">
                        <Grid container>
                            <Grid item xs={12} className="action d-flex align-center" onClick={handleNewArticle}>
                                <TextFormatIcon className="action-icon" />
                                <span className="action-text">{t('mobile.sideMenu.addArticle')}</span>
                            </Grid>
                            <Grid item xs={12} className="action d-flex align-center" onClick={handleNewPush}>
                                <NotificationsNoneIcon className="action-icon" />
                                <span className="action-text">{t('mobile.sideMenu.addPush')}</span>
                            </Grid>
                            <Grid item xs={12} className="action d-flex align-center" onClick={handleNewUser}>
                                <AccountCircleOutlinedIcon className="action-icon" />
                                <span className="action-text">{t('mobile.sideMenu.addUser')}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid item xs={12} className="action d-flex align-center" onClick={handleBackToApp}>*/}
                    {/*            <div className="action-icon">*/}
                    {/*                <IconComponent icon={BackArrow} />*/}
                    {/*            </div>*/}
                    {/*            <span className="action-text">{t('mobile.sideMenu.backToApp')}</span>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={12} className="action no-border d-flex align-center" onClick={handleGoToSite}>*/}
                    {/*            <div className="action-icon">*/}
                    {/*                <IconComponent icon={ExitApp} />*/}
                    {/*            </div>*/}
                    {/*            <span className="action-text">{t('mobile.sideMenu.goToDesktopSite')}</span>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </Grid>
                <div className="close-container">
                    <CloseIcon className="icon-close" onClick={() => props.handleClose()} />
                </div>
            </div>
        </div>
    )
}

export default SideMenu
