import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Link as MuiLink,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AddIcon from "@material-ui/icons/Add";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "../../../store/StoreContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import { flowResult } from "mobx";
import "react-phone-number-input/style.css";
import TermsAndPrivacyPolicy from "../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy";
import LanguageSelection from "../../../components/LanguageSelection/LanguageSelection";
import { useTranslation } from "react-i18next";
import "../styles.scss";
import "./styles.scss";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import Spinner from '../../../components/Spinner/Spinner';
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../shared/utils/cropImage";
import ProceedImage from "../../../shared/utils/proceedImage";
import { formatToPhoneNumber } from '../../../shared/utils/sharedFunctions';


const SignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userStore } = useStore();

  const [values, setValues] = useState({
    showPassword: false,
    showRetypePassword: false,
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [windowHeight, setWindowHeight] = useState("");
  const [phoneExist, setPhoneExists] = useState(false);
  const [emailExist, setEmailExists] = useState(false);

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(1.4);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    cropImage(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(profilePicture, croppedAreaPixels);
    return croppedImage;
  };

  const handleClickShowPassword = (prop) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      repeat_password: "",
      image: "",
    },
  });

  const watchFields = watch();
  const validate = async (form: any) => {
    // @ts-ignore
    const resolver = yupResolver(VALIDATIONS)(form, null, {
      criteriaMode: "firstError",
    });
    setIsDisabled(Object.values((await resolver).errors).length > 0);
  };

  useEffect(() => {
    validate(watchFields);
  }, [watchFields]);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    const windowHeight = window.outerHeight;
    if (windowHeight < 768) {
      setWindowHeight(`${windowHeight}px`);
    } else {
      setWindowHeight("100%")
    }
    setIsLoading(false);
  }, []);

  const handleSubmitCheck = async (payload) => {
    setPasswordError("");
    if (isNewPictureUploaded) {
      payload.image = await cropImage(croppedAreaPixels);
    }
    payload = { ...payload, phone: payload.phone.replace(" ", "") };
    if (payload["password"] !== payload["repeat_password"]) {
      setPasswordError(t("errorMessages.signUpMissMatchPass"));
      return;
    } else {
      setIsLoading(true);
      delete payload["repeat_password"];
      await flowResult(userStore.register(payload)).then((response: any) => {
        if (!response?.success) {
          const code = response?.code ?? ''
          if (code === 'U214') {
            setPhoneExists(true);
            setIsLoading(false);
            return;
          }
          if (code === 'U215') {
            setEmailExists(true);
            setIsLoading(false);
            return;
          }
          setNotificationMessage(
            code
              ? t(`apiMessages.${code}`)
              : t("apiMessages.0")
          );
          setOpenNotificationModal(true);
          setIsLoading(false);
          return;
        } else {
          history.push("/sign-in");
        }
      });
    }
  };

  const handleUpload = async (event) => {
    const data = event.target.files[0];
    if (data) {
      setInnerLoading(true);
      if (data.size > 26214400) {
        setNotificationMessage(t("errorMessages.fileSize"));
        setOpenNotificationModal(true);
        setInnerLoading(false);
        return;
      }
      if (data.type.indexOf(`image/jpeg`) === -1) {
        setNotificationMessage(t("errorMessages.fileJpg"));
        setOpenNotificationModal(true);
        setInnerLoading(false);
        return;
      }
      setIsNewPictureUploaded(true);
      const image: any = await ProceedImage.toBase64(data);
      setProfilePicture(image);
      setValue("image", image);
      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          let zoom = 0;
          if (height > width) {
              zoom = (height / width)
          } else {
              zoom = (width / height)
          }
          zoom = zoom >= 1 ? zoom : zoom;
          setZoom(zoom);
          setMaxZoom(zoom * 4);
          setTimeout(() => {
              const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
              cropperImage.style.maxHeight = '98%';
          })
      };

      }
      reader.readAsDataURL(data);
      setInnerLoading(false);
    }
  };

  return isLoading ? (<Spinner />) : (
    <div className="scroll-container" style={{ height: windowHeight }}>
      <div className="sign-form-container sign-up">
        <div className="inner-full-container">
          <form
            onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
            className="sign-form"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className="sign-container"
            >
              <Grid item xs={10} sm={6} md={5} lg={4}>
                <Grid container>
                  <Grid item xs={12} className="sign-header">
                    <div className="double-column">
                      <div>
                        <h2>{t("common.signUpLabel")}</h2>
                        <h3>
                          {t("signUp.navTo")}{" "}
                          <Link
                            className="cursor-pointer link no-underline"
                            to="/sign-in"
                          >
                            {t("common.signInLabel")}
                          </Link>
                        </h3>
                      </div>
                      <LanguageSelection />
                    </div>
                  </Grid>
                  <Grid item xs={12} className="sign-body">
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        className="sign-image-container flex-center"
                      >
                        <div className="sign-image-and-icon">
                          {!isNewPictureUploaded ? (
                            <Avatar
                              alt="Avatar"
                              src="https://www.pinclipart.com/picdir/big/165-1653686_female-user-icon-png-download-user-colorful-icon.png"
                              className="sign-image"
                            />
                          ) : (
                            <div className="sign-image">
                              <Cropper
                                image={profilePicture}
                                crop={crop}
                                zoom={zoom}
                                minZoom={0.05}
                                maxZoom={maxZoom}
                                restrictPosition={false}
                                zoomSpeed={0.1}
                                cropShape="round"
                                objectFit="vertical-cover"
                                cropSize={{
                                  width: 125,
                                  height: 125,
                                }}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                              />
                            </div>
                          )}
                          <div className="upload-wrapper">
                            <label
                              htmlFor={`fileUpload`}
                              className="flex-center flex-column"
                            >
                              <div className="add-image-btn flex-center cursor-pointer">
                                <AddIcon className="plus-icon" />
                              </div>
                            </label>
                            <input
                              id={`fileUpload`}
                              type="file"
                              name="file"
                              hidden={true}
                              onChange={handleUpload}
                            />
                            <p className="field-error">
                              {errors?.image?.message ?? ""}&nbsp;
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} className="sign-field">
                        <h3>{t("myAccount.details")}</h3>
                      </Grid>
                      <Grid item xs={12} className="sign-field" />
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="first_name"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="firstName"
                                label={t("signUp.fields.firstName.label")}
                                value={value}
                                onChange={onChange}
                                autoComplete="off"
                              />
                              <p className="field-error">
                                {errors?.first_name?.message ?? ""}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="last_name"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="lastName"
                                label={t("signUp.fields.lastName.label")}
                                value={value}
                                onChange={onChange}
                                autoComplete="off"
                              />
                              <p className="field-error">
                                {errors?.last_name?.message ?? ""}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="phone"
                                type="text"
                                value={value}
                                onChange={(e) =>{
                                  if (phoneExist) setPhoneExists(false);
                                  onChange(
                                    formatToPhoneNumber(e.target.value, value)
                                  )
                                }}
                                label={t("signUp.fields.phone.label")}
                                autoComplete="off"
                              />
                              <p className="field-error">
                                {errors?.phone?.message ?? (phoneExist ? t(`apiMessages.U214`) : '')}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} className="sign-field">
                        <Controller
                          control={control}
                          name="email"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="email"
                                label={t("signUp.fields.email.label")}
                                value={value}
                                onChange={(e) => {
                                  if (emailExist) setEmailExists(false);
                                  onChange(e);
                                }}
                                autoComplete="off"
                              />
                              <p className="field-error">
                                {errors?.email?.message ?? (emailExist ? t(`apiMessages.U215`) : '')}&nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <div className="separator" />
                      <Grid item xs={12} className="sign-field">
                        <h3>{t("signUp.fields.password.label")}</h3>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className="password-field-container">
                          <InputLabel htmlFor="password">
                            {t("signUp.fields.password.label")}
                          </InputLabel>

                          <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Input
                                  id="password"
                                  type={values.showPassword ? "text" : "password"}
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="off"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        className="visibility-icon"
                                        onClick={() =>
                                          handleClickShowPassword("showPassword")
                                        }
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <p className="field-error">
                                  {errors?.password?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className="password-field-container">
                          <InputLabel htmlFor="retypePassword">
                            {t("signUp.fields.retypePassword.label")}
                          </InputLabel>

                          <Controller
                            control={control}
                            name="repeat_password"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Input
                                  id="repeat_password"
                                  type={
                                    values.showRetypePassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="off"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        className="visibility-icon"
                                        onClick={() =>
                                          handleClickShowPassword(
                                            "showRetypePassword"
                                          )
                                        }
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {values.showRetypePassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <p className="field-error">
                                  {errors?.repeat_password?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </FormControl>
                        <FormHelperText error>
                          {passwordError}&nbsp;
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="is-have-read"
                          control={
                            <Checkbox
                              color="primary"
                              checked={isRead}
                              onClick={() => {
                                setIsRead(!isRead);
                              }}
                            />
                          }
                          label={<MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>
                          {t("common.termsAndPrivacy")}
                        </MuiLink>}
                        />
                      </Grid>
                      <Grid item xs={12} className="sign-buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isDisabled || !isRead}
                          onClick={() => {
                            const firstName = getValues('first_name').trim();
                            const lastName = getValues('last_name').trim();
                            if (firstName.length < 0) setError('first_name', t("validationMessages.articles.addArticle.minHeader"))
                            if (lastName.length < 0) setError('last_name', t("validationMessages.articles.addArticle.minHeader"))
                            setValue('first_name', firstName);
                            setValue('last_name', lastName);
                          }}
                        >
                          {t("common.signUpLabel")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
        <TermsAndPrivacyPolicy
          openModal={openModal}
          onClose={() => setOpenModal(false)}
        />

        <NotificationModal
          openModal={openNotificationModal}
          message={notificationMessage}
          handleClose={() => setOpenNotificationModal(false)}
          handleButtonClick={() => setOpenNotificationModal(false)}
          buttonText={t("common.buttons.close")}
        />
        {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
      </div>
    </div>
  );
};

export default SignIn;
