import React, { useEffect, useState } from 'react'
import IconComponent from '../../shared/components/IconComponent/IconComponent';
import "./style.scss";
import Plus from "../../assets/icons/plus.svg"
import CommunityIcom from '../../shared/components/CommunityIcom/CommunityIcom';
import { Link, NavLink, useHistory } from "react-router-dom";
import { Props } from "./props";
import { CreateButton } from "../../shared/utils/createButton";
import SearchBarInfo from "../../components/SearchBarInfo/SearchBarInfo";
import { Avatar } from '@material-ui/core';
import CommunityLogo from "../../assets/icons/logo.svg"
import { useStore } from '../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import LanguageSelection from '../LanguageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';
import UserTypeEnum from '../../models/UserType';
import { stringToCapital } from '../../shared/utils/sharedFunctions';

const TopNavBar = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userStore, actionStore } = useStore();
    const isMobile = window.innerWidth < 736;

    const [createUrl, setCreateUrl] = useState('');
    const [navActive, setNavActive] = useState({
        articles: false,
        benefits: false,
        users: false,
        community: false,
        reports: false,
        push: false,
        settings: false,
    })
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setRoleType(userStore.getRoleType());
    }, [userStore.roleType]);

    useEffect(() => {
        const path = history.location.pathname;
        const url = CreateButton.getUrl(path);
        if (url) {
            setCreateUrl(url)
        } else {
            setCreateUrl('')
        }
        setNavActive({
            articles: path.indexOf('/articles/') !== -1,
            benefits: path.indexOf('/benefits/') !== -1,
            users: path.indexOf('/users/') !== -1,
            community: path.indexOf('/community/') !== -1,
            reports: path.indexOf('/reports/') !== -1,
            push: path.indexOf('/push/') !== -1,
            settings: path.indexOf('/settings/') !== -1,
        })
    }, [actionStore.checkCreate])

    const onCreateClicked = () => {
        actionStore.setTabIndex(null);
        actionStore.toggleSearch(false);
        history.push(createUrl);
    }

    const [currentCommunity, setCurrentCommunity] = useState<any>({});

    useEffect(() => {
        setCurrentCommunity(userStore.getCurrentCommunity())
    }, [userStore.currentCommunity])

    return (
        <div className="top-nav-bar-container">
            <div className="community-icon-wrapper" onClick={props.onRefresh}>
                <Link to="/" onClick={() => actionStore.toggleSearch(false)}>
                    <CommunityIcom url={''} />
                </Link>
            </div>

            <div className={`search-window-slide-popup-container ${actionStore.searchBarOpened ? "opened" : ""}`}>
                <div className="search-window-slide-popup">
                    <SearchBarInfo onClose={() => actionStore.toggleSearch(false)} />
                </div>
            </div>

            <div className={`top-nav-links-container`}>
                {
                    !isMobile ? (
                        <div className="top-nav-links-wrapper">
                            <NavLink className={`nav-bar-item ${navActive.articles ? 'active' : ''}`}
                                to="/articles/list" activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.articles.mainTab')}</NavLink>
                            <NavLink className={`nav-bar-item ${navActive.benefits ? 'active' : ''}`}
                                to="/benefits/list" activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.benefits.mainTab')}</NavLink>
                            <NavLink className={`nav-bar-item ${navActive.users ? 'active' : ''}`}
                                to="/users/list" activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.users.mainTab')}</NavLink>
                            <NavLink className={`nav-bar-item ${navActive.community ? 'active' : ''}`}
                                to={`/community/${roleType === UserTypeEnum.owner ? 'list' : 'view'}`} activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>
                                {roleType !== UserTypeEnum.owner ? t('tabNavigation.community.mainTab') : t('tabNavigation.community.mainTabForOwner')}
                            </NavLink>
                            <NavLink className={`nav-bar-item ${navActive.reports ? 'active' : ''}`}
                                to="/reports/leads" activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.reports.mainTab')}</NavLink>
                            <NavLink className={`nav-bar-item ${navActive.push ? 'active' : ''}`}
                                to="/push/promotional" activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.push.mainTab')}</NavLink>
                            <NavLink className={`nav-bar-item ${navActive.settings ? 'active' : ''}`}
                                to={`/settings/${roleType === UserTypeEnum.owner ? 'manageCategories' : 'general'}`} activeClassName="active"
                                onClick={() => actionStore.toggleSearch(false)}>{t('tabNavigation.settings.mainTab')}</NavLink>
                            {
                                createUrl && (
                                    <div className="plus-icon-wrapper" onClick={onCreateClicked}>
                                        <IconComponent icon={Plus} />
                                    </div>
                                )
                            }
                        </div>
                    ) : null
                }
                <div className="flex-center community-and-language">
                    <div className="flex-center current-community">
                        {
                            Object.keys(currentCommunity).length ? (
                                <>
                                    <Avatar className="community-logo" src={currentCommunity?.imageNavigationBarLogo ?? CommunityLogo} alt="logo" />
                                    <span>{stringToCapital(currentCommunity?.communityName)}</span>
                                </>) : (
                                <>
                                    <Avatar className="community-logo" src={CommunityLogo} alt="logo" />
                                    <span>{t('common.communityNotSelected')}</span>
                                </>)
                        }
                    </div>
                    <LanguageSelection />
                </div>
            </div>
        </div>
    )

}


export default observer(TopNavBar)
