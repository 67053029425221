import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core';
import Person from "../../../assets/icons/article-person.svg";
import Sale from "../../../assets/icons/sale-icon.svg";
import NumberField from '../../../components/NumberField/NumberField';
import { useStore } from '../../../store/StoreContext';
import {flowResult} from "mobx";
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import "./style.scss";
import { useTranslation } from 'react-i18next';

const Archive = () => {
    const { t } = useTranslation();
    const { archiveStore } = useStore();

    const [articleDays, setArticleDays] = useState(0);
    const [articleAmount, setArticleAmount] = useState(0);
    const [benefitDays, setBenefitDays] = useState(0);
    const [benefitAmount, setBenefitAmount] = useState(0);
    
    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        setIsLoading(true);
        flowResult(archiveStore.getArchive()).then(
            (response: any) => {
                setIsLoading(false);
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                    return;
                }
                setArticleDays(response?.['article_days'] ?? 0);
                setArticleAmount(response?.['article_amount'] ?? 0);
                setBenefitDays(response?.['benefit_days'] ?? 0);
                setBenefitAmount(response?.['benefit_amount'] ?? 0);
            }
        )
    }, [])

    const handlePlusMinus = (item, setItem) => (action) => {
        if (action === 'increase' && item < 999999) setItem(item + 1);
        if (action === 'decrease' && item > 0) setItem(item - 1);
    }

    const onValueChange = (setItem) => (value) => {
        setItem(value);
    }

    const handleNotificationOpen = (message = '') => {
        setNotificationMessage(message);
        setOpenNotificationModal(true);
    }

    const handleSubmitCheck = (e) => {
        e.preventDefault()
        setIsLoading(true);
        const data = {
            "article_days": articleDays,
            "article_amount": articleAmount,
            "benefit_days": benefitDays,
            "benefit_amount": benefitAmount,
        }
        flowResult(archiveStore.update(data)).then(
            (response: any) => {
                setIsLoading(false);
                if (response.code) {
                    handleNotificationOpen(t(`apiMessages.${response.code}`));
                }
            }
        )
    }

    return (
        <div className="main-wrapper settings-archive">
            <h2 className="page-heading">
                {t('settings.archive.title')}
            </h2>
            <form className="" autoComplete="off" onSubmit={handleSubmitCheck}>
                <Grid container className="content-grid" justifyContent="space-between">
                    <Grid item xs={6} md={5} className="main-wrapper-container">
                        <Grid container justifyContent="center" alignItems="center" className="main-wrapper" direction="column">
                            <Grid item xs={12} className="wrapper-image text-center">
                                <img src={Person} alt="Person" />
                            </Grid>
                            <Grid item xs={12} className="wrapper-header text-center align-with-img align-with-img-en">
                                {t('tabNavigation.articles.mainTab')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={12} lg={8} className="grid-row">
                                        {t('settings.archive.moveArticles')}
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={4} lg={2}>
                                        <NumberField
                                            value={articleDays}
                                            onChange={onValueChange(setArticleDays)}
                                            handlePlusMinus={handlePlusMinus(articleDays, setArticleDays)}
                                            label={t('settings.archive.days')}
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="text-center">
                                        {t('settings.archive.andOr')}
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <NumberField
                                            value={articleAmount}
                                            onChange={onValueChange(setArticleAmount)}
                                            handlePlusMinus={handlePlusMinus(articleAmount, setArticleAmount)}
                                            label={t('tabNavigation.articles.mainTab')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={5} className="main-wrapper-container">
                        <Grid container justifyContent="center" alignItems="center" className="main-wrapper" direction="column">
                            <Grid item xs={12} className="wrapper-image text-center">
                                <img src={Sale} alt="Sale" />
                            </Grid>
                            <Grid item xs={12} className="wrapper-header">
                                {t('settings.archive.saleItem')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={12} lg={8} className="grid-row">
                                        {t('settings.archive.moveSaleItems')}
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={4} lg={2}>
                                        <NumberField
                                            value={benefitDays}
                                            onChange={onValueChange(setBenefitDays)}
                                            handlePlusMinus={handlePlusMinus(benefitDays, setBenefitDays)}
                                            label={t('settings.archive.days')}
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="text-center">
                                        {t('settings.archive.andOr')}
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <NumberField
                                            value={benefitAmount}
                                            onChange={onValueChange(setBenefitAmount)}
                                            handlePlusMinus={handlePlusMinus(benefitAmount, setBenefitAmount)}
                                            label={t('settings.archive.items')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="submit-buttons">
                    <Button variant="contained" color="primary" type="submit" disabled={!(articleDays && articleAmount && benefitDays && benefitAmount)}>{t('common.buttons.save')}</Button>
                </div>
            </form>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={() => setOpenNotificationModal(false)}
                handleButtonClick={() => setOpenNotificationModal(false)}
                buttonText={t('common.buttons.close')}
                message={notificationMessage}
            />
            {isLoading && <Spinner />}
        </div>
    )
}

export default Archive
