import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { noContent } from "../../../db/db";
import { Button, Grid } from "@material-ui/core";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ExportIcon from "../../../assets/icons/export.svg";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import { exportToExcel, mapDateForHttpReq, handlePlusMinus, convertToDate1, getElementFromArrayObject } from "../../../shared/utils/sharedFunctions";
import NumberField from "../../NumberField/NumberField";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from "moment"
import { monthsArray, initDateRange } from "../../../shared/utils/constants"


const PurchaseInformationModal = () => {
  const { reportsStore, benefitStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const { languageStore, settingsStore } = useStore();
  const [purchasesList, setPurchasesList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => { });
  const [purchaseInformation, setPurchaseInformation] = useState<any>({});
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [limit, setLimit] = useState(100);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>(initDateRange);
  const [lngDirection, setLngDirection] = useState("");
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0)
        })
      })
    }
  }, [languageStore.switchLng, isLoading === false])

  useEffect(() => {
    if (reportsStore.triggerUpdateList) {
      getAllPurchaseInformation();
      reportsStore.triggerUpdateList = false;
    }
  }, [reportsStore.triggerUpdateList]);

  useEffect(() => {
    const purchase = reportsStore.getPurchaseInformation();
    const purchaseID = purchase?.id ?? '';
    if (purchaseID) {
      getAllPurchaseInformation(purchaseID);
      setPurchaseInformation(purchase);
      setTimeout(() => {
        setOpenSideMenu(true);
      });
    } else {
      setOpenSideMenu(false);
      setTimeout(() => {
        setPurchaseInformation({});
      }, 500);
    }
  }, [reportsStore.purchaseInformation]);

  useEffect(() => {
    setCategories(benefitStore.getStoredBenefits());
    const isModalOpen = reportsStore.getIsModalOpen();
    if (isModalOpen) {
      getAllPurchasesOrders()
      setPurchaseInformation({ id: 'all', header: 'רכישות' });
      setTimeout(() => {
        setOpenSideMenu(true);
      });
    } else {
      setOpenSideMenu(false);
      reportsStore.setIsModalOpen(false);
      setDateRange(initDateRange)
      setLimit(100)
      setTimeout(() => {
        setPurchaseInformation({});
      }, 500);
    }
  }, [reportsStore.isModalOpen]);

  // now on dateRange making the call dynamicly
  useEffect(() => {
    if(openSideMenu){
      if (purchaseInformation.id === 'all') getAllPurchasesOrders()
      else getAllPurchaseInformation()
    }
  }, [dateRange])

  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const handleDateChange = (range: DateRange) => {
    setDateRange(range)
    setOpen(false)
  };

  const calculateTotalPurchasesPrice = (response) => {
    let _totalPrice = 0;
    let _totalAmount = 0;
    response["purchases"].forEach((p) => {
      _totalPrice += p.totalPrice;
      _totalAmount += p.amount;
    });
    setTotalPrice(_totalPrice);
    setTotalAmount(_totalAmount);
  }

  const getAllPurchasesOrders = async () => {
    setIsLoading(true);
    const { from_date, to_date } = mapDateForHttpReq(dateRange)
    console.log("\x1b[33m  file: PurchaseInformationModal.tsx:136  getAllPurchasesOrders  to_date:", to_date)
    console.log("\x1b[33m  file: PurchaseInformationModal.tsx:136  getAllPurchasesOrders  from_date:", from_date)
    await flowResult(reportsStore.getAllPurchasesOrders({ limit, from_date, to_date })).then(
      (response: any) => {
        if (!response?.success) {
          // on error
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        calculateTotalPurchasesPrice(response)
        setPurchasesList(response["purchases"]);
        setIsLoading(false);
      }
    );
  };

  const search = () => {
    if (purchaseInformation.id === 'all') getAllPurchasesOrders()
    else getAllPurchaseInformation()
  }

  // get all purchase and fill table
  const getAllPurchaseInformation = async (id = purchaseInformation.id) => {
    setIsLoading(true);
    if (id === 'all') getAllPurchasesOrders()
    else getStorePurchases(id)
  };

  const getStorePurchases = async (id) => {
    console.log("\x1b[33m  file: PurchaseInformationModal.tsx:167  getStorePurchases  getStorePurchases:")
    const searchParams = reportsStore.getSearchParamsPurchases();
    const { from_date, to_date } = mapDateForHttpReq(dateRange)
    const hasParams = {
      ...searchParams,
      // limit, 
      from_date,
      to_date
    }
    await flowResult(reportsStore.getStorePurchases(id, hasParams)).then(
      (response: any) => {
        if (!response?.success) {
          // on error
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        // calculate total purchases price
        let _totalPrice = 0;
        let _totalAmount = 0;
        response["purchases"].forEach((p) => {
          _totalPrice += p.totalPrice;
          _totalAmount += p.amount;
        });
        setTotalPrice(_totalPrice);
        setTotalAmount(_totalAmount);

        setPurchasesList(response["purchases"]);
        setIsLoading(false);
      }
    );
  }
  // on click => open confirm modal
  const onCancelPurchase = (id) => {
    if (!id) {
      return;
    }
    reportsStore.setActiveTransactionId(id);
    openConfirmModal(id);
  };

  // error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  //confirm modal for cancel transaction
  const openConfirmModal = (selectedPurchase) => {
    if (!selectedPurchase) {
      return;
    }
    setButtonText(t("common.buttons.confirm"));
    setNotificationMessage(t("reports.purchases.confirmClose"));
    setButtonAction(() => cancelPurchase);
    setOpenNotificationModal(true);
  };

  const cancelPurchase = async () => {
    setOpenNotificationModal(false);
    const _id = reportsStore.activeTransactionId;
    setIsLoading(true);
    if (!_id) {
      setIsLoading(false);
      return;
    }
    await flowResult(reportsStore.deletePurchase(_id)).then((response: any) => {
      if (!response?.success) {
        setNotificationMessage(
          response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
        );
        setButtonAction(() => handleNotificationClose);
        setOpenNotificationModal(true);
        setIsLoading(false);
        return;
      }
      getAllPurchaseInformation(purchaseInformation.id);
      setIsLoading(false);
      setOpenNotificationModal(false);
      reportsStore.setActiveTransactionId(null);
    });
  };

  const handleExport = async () => {
    setIsLoading(true)
    if (purchaseInformation.id === 'all') exportAllPurchasesOrders()
    else exportStorePurchases()
  }

  const exportStorePurchases = async () => {
    const { from_date, to_date } = mapDateForHttpReq(dateRange)
    await flowResult(reportsStore.exportStorePurchases(purchaseInformation.id, {from_date, to_date })).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        const header = purchaseInformation?.row?.header ?? purchaseInformation?.header ?? '';
        exportToExcel(response.file, `${t('reports.purchases.title').toLowerCase()}_${header.replace(' ', '_')}`);
        setIsLoading(false);
      }
    );
  }

  const exportAllPurchasesOrders = async () => {
    const { from_date, to_date } = mapDateForHttpReq(dateRange)
    await flowResult(reportsStore.exportAllPurchasesOrders({ limit, from_date, to_date })).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        const header = purchaseInformation?.row?.header ?? purchaseInformation?.header ?? '';
        exportToExcel(response.file, `${t('reports.purchases.title').toLowerCase()}_${header.replace(' ', '_')}`);
        setIsLoading(false);
      }
    )
  }

  const tableData: GridColDef[] = [
    {
      field: 'isPaid',
      headerName: t('סטטוס חיוב'),
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{params?.row?.isPaid ? 'חויב' : '-'}</span>
        );
      },
    },
    {
      field: 'ccno',
      headerName: t('4 ספרות'),
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{params?.row?.ccno ?? ''}</span>
        );
      },
    },
    {
      field: 'price',
      headerName: t('מחיר לפריט'),
      valueGetter: (params) => params.row?.benefit?.details?.price,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const price = params.row?.benefit?.details?.price;
        const currentPrice = lngDirection === "rtl" ? price : price / 3.2;
        return (
          <span className="table-row-title">{price ? `${t('common.currency')+currentPrice}` : ''}</span>
        );
      },
    },
    {
      field: "benefitDetailsLocation",
      headerName: t("מיקום הטבה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row?.benefit?.details?.location,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row?.benefit?.details?.location ?? ''}
          </span>
        );
      },
    },
    {
      field: "benefitFromDate",
      headerName: t("מתאריך הטבה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.benefit.fromDate,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {convertToDate1(params.row.benefit.fromDate)}
          </span>
        );
      },
    },
    {
      field: "benefitToDate",
      headerName: t("עד תאריך הטבה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.benefit.toDate,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {convertToDate1(params.row.benefit.toDate)}
          </span>
        );
      },
    },
    {
      field: "benefitCategory",
      headerName: t("קטגוריה הטבה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => getElementFromArrayObject('id', 'categoryName', params.row.benefit.categoryId, categories),
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {getElementFromArrayObject('id', 'categoryName', params.row.benefit.categoryId, categories)}
          </span>

        );
      },
    },
    {
      field: "benefitHeader",
      headerName: t("שם הטבה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.benefit.header,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.benefit.header}
          </span>
        );
      },
    },
    {
      field: "role",
      headerName: t("סוג משתמש"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => t(`users.create.${params?.row?.user?.connection[0]?.role}`),
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const role = params?.row?.user?.connection[0]?.role;
        return (
          <span className="table-row-title">{t(`users.create.${role}`)}</span>
        );
      },
    },
    {
      field: "communityName",
      headerName: t("שם קהילה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.user.connection[0].community?.communityName ?? '',
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.connection[0].community?.communityName ?? ''}
          </span>
        );
      },
    },
    {
      field: "departmentName",
      headerName: t("שם מחלקה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.user.connection[0].department?.name ?? '',
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.connection[0].department?.name ?? ''}
          </span>
        );
      },
    },
    {
      field: "orderIndex",
      headerName: t("מספר עסקה"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.orderIndex}
          </span>
        );
      },
    },
    {
      field: "user",
      headerName: t("community.list.name"),
      sortable: true,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.user.firstName+" "+params.row.user.lastName,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.firstName + " " + params.row.user.lastName}
          </span>
        );
      },
    },
    {
      field: "phone",
      headerName: t("community.list.phone"),
      sortable: false,
      flex: 1,
      minWidth: 80,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.phone}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: t("community.list.email"),
      flex: 1,
      valueGetter: (params) => params.row.user.email,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.email}
          </span>
        );
      },
    },
    {
      field: "amount",
      headerName: t("common.fieldHeaders.amount"),
      sortable: true,
      flex: 1,
      width: 10,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column purchase-modal-amount`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.amount}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("common.fieldHeaders.date"),
      sortable: true,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className={`table-row-title`}>
            {convertToDate1(params?.row?.createdAt)}
          </span>
        );
      },
    },
    {
      field: "totalPrice",
      headerName: t("common.fieldHeaders.price"),
      sortable: true,
      flex: 1,
      width: 10,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column purchase-modal-price`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {t("common.currency")}
            {params.row.totalPrice}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: t("common.fieldHeaders.actions"),
      width: 150,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column text-center`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        let isCancelled = params.row.isCanceled;
        return (
          <div className="text-center">
            {
              (settingsStore.getUserPermissions().reports) && (
                <>
                  {
                    !isCancelled ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          onCancelPurchase(params.row.id);
                        }}
                      >
                        {t("reports.purchases.cancelPurchase")}
                      </Button>
                    ) :
                      (
                        <span>{t('reports.purchases.informationModal.cancelledPurchase')}</span>
                      )
                  }
                </>
              )
            }
          </div>
        );
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );

  return (
    <div className={`purchases-modal-page-container ${purchaseInformation.id || openSideMenu ? 'open' : ''}`}>
      <div className={`main-wrapper purchases-modal-page ${openSideMenu ? 'open' : ''}`}>
        {isLoading ? (
          <Spinner className="purchases-modal-spinner" />
        ) : (
          <>
            <div className="purchase-modal-header d-flex justify-between align-center">
              <div>
                <h3>{t('reports.purchases.informationModal.header')}</h3>
              </div>
              <div className="d-flex justify-between align-center">
                <div className="export-button d-flex justify-between align-center" onClick={handleExport}>
                  <span className="export-button-text">{t('reports.purchases.informationModal.export')}</span>
                  <IconComponent icon={ExportIcon} />
                </div>
                <div className="close-container d-flex justify-between align-center">
                  <CloseIcon className="icon-close" onClick={() => {
                    reportsStore.setPurchaseInformation({})
                    reportsStore.setIsModalOpen(false);
                  }} />
                </div>
              </div>
            </div>
            <div className="purchase-modal-footer">
              <div className="page-heading-wrap">
                <h2 className="page-heading">{purchaseInformation?.row?.header ?? purchaseInformation?.header ?? ''}</h2>
              </div>
              <span className="total-count">
                {t("reports.purchases.buyersCount", { count: purchasesList.length })}
              </span>
              <Grid container direction="column" justifyContent="flex-start" style={{ marginTop: 20 }} >
                  {purchaseInformation.id === 'all' &&
                    <Grid item xs={2} id="count">
                    <NumberField
                      value={limit}
                      onChange={setLimit}
                      handlePlusMinus={handlePlusMinus(limit, setLimit)}
                      label={t('common.fieldHeaders.quantity')}
                    />
                  </Grid>}

                <Grid item xs={12} id="dateRange">
                  <Button
                    style={{ width: '100%', marginTop: 10 }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpen(!open)}
                  >
                    <div className="dislayed-dates" style={{ direction: 'ltr' }}>
                      {moment(dateRange?.startDate).format('DD/MM/YYYY')}
                      <span> ➡ </span>
                      {moment(dateRange?.endDate).format('DD/MM/YYYY')}
                    </div>
                  </Button>
                  <div
                    style={{ direction: 'ltr' }}
                  >
                    <DateRangePicker
                      open={open}
                      toggle={() => setOpen(!open)}
                      onChange={handleDateChange}
                      initialDateRange={dateRange}
                      minDate={new Date("2020")}
                      maxDate={new Date()}
                      closeOnClickOutside
                      definedRanges={monthsArray}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} id="ok">
                  {!open && <Button
                    style={{ width: '100%', marginTop: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={search}
                  >
                    <SearchIcon className="icon-close" />
                    {t("reports.searchTitle")}
                  </Button>}
                </Grid>


              </Grid>
              <div className="sales-page content-wrap" style={{ maxHeight: window.innerHeight * 0.7 }}>
                {purchasesList?.length ? (
                  <>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: [
                          ".purchase-modal-price:after {",
                          `   content: '${t("common.currency")}${totalPrice}';`,
                          "}",
                          ".purchase-modal-amount:after {",
                          `  content: '${totalAmount}';`,
                          "}",
                        ].join("\n"),
                      }}
                    ></style>
                    <div className="table-grid">
                      <DataGrid
                        rows={purchasesList}
                        autoHeight={true}
                        // columns={lngDirection === "ltr" ? tableData : tableData.reverse()}
                        columns={lngDirection === "ltr" ? tableData.reverse() : tableData}
                        rowHeight={90}
                        headerHeight={80}
                        disableColumnMenu={true}
                        hideFooter={true}
                      />
                    </div>
                  </>
                ) : (
                  <div className="no-content">
                    <img src={noContent.users} alt="No messages" />
                    <p>{t("reports.purchases.noContent.message1")}</p>
                    <span className="note">
                      {t("reports.purchases.noContent.message2")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )
        }
        <NotificationModal
          openModal={openNotificationModal}
          handleClose={handleNotificationClose}
          handleButtonClick={buttonAction}
          buttonText={buttonText}
          message={notificationMessage}
        />
      </div >
    </div >
  );
};

export default observer(PurchaseInformationModal);
