import { useState } from "react"
import NotificationModal from "./NotificationModal"

export type UseNotificationModalArgs = {
	message : string,
	buttonText?: string,
	onClose?: Function,
	onOk?: Function
}

export const useNotificationModal = () : {
	element: React.FC
	open: (props : UseNotificationModalArgs) => void
} => {

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [message, setMessage] = useState("");
	const [buttonText, setButtonText] = useState("");
	
	const [onClose, setOnClose] = useState(undefined);
	const [onOk, setOnOk] = useState(undefined);

	const element = () => <NotificationModal 
		openModal={isOpen}
		handleClose={onClose}
		handleButtonClick={onOk}
		buttonText={buttonText}
		message={message}
	></NotificationModal>;

	const open = (props : UseNotificationModalArgs) => {
		console.log(props)
		setIsOpen(true);

		setMessage(props.message);

		setButtonText(props.buttonText ?? "");

		setOnClose(() => {
      return () => {
        setIsOpen(false);
        console.log("close");

        if (props.onClose) {
          props.onClose();
        }
      };
    });

    setOnOk(() => {
      return () => {
        setIsOpen(false);
        console.log("ok");

        if (props.onOk) {
          props.onOk();
        }
      };
    });
	}

	return {element, open};
}