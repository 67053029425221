import {
  Avatar,
  Button,
  FormControl,
  Popover,
  MenuItem,
  Select,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import CheckIcon from "@material-ui/icons/Check";
import { useStore } from "../../../store/StoreContext";
import { observer } from "mobx-react-lite";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { flowResult } from "mobx";
import ProceedImage from "../../../shared/utils/proceedImage";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import Spinner from "../../../components/Spinner/Spinner";
import UserTypeEnum from "../../../models/UserType";
import CloseIcon from "@material-ui/icons/Close";
import { formatToPhoneNumber } from "../../../shared/utils/sharedFunctions";

const EditUser = ({ handleSaveEdit, handleErrorPopUp }) => {
  const { i18n, t } = useTranslation();
  const { languageStore, userStore, usersPageStore } = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editRowWidth, setEditRowWidth] = useState(0);
  const [editRowMaxWidth, setEditRowMaxWidth] = useState(0);

  const [idEdited, setIdEdited] = useState(0);
  const [image, setImage] = useState("");
  const [isActiveEdited, setIsActiveEdited] = useState(false);
  const [communities, setCommunities] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleType, setRoleType] = useState(null);
  const [community, setCommunity] = useState<any>({});
  const [availableRoleTypes, setAvailableRoleTypes] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [firstSelectedCommunity, setFirstSelectedCommunity] = useState("");
  const [resetPassword, setResetPassword] = useState(false);

  const LEFT_TO_RIGHT_MARK = "‎\u200e";

  const notSecondDepartment = {
    id: "null",
    name: "-",
  }

  const getDepartmentsFlow = async (comID) => {
    setIsLoading(true);
    await flowResult(usersPageStore.getCommunityDepartments(comID)).then(
      (response: any) => {
        if (!response?.success) {
          return;
        }
        const dataMain = response.departments.map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        const dataSecondary = response.secondaryDepartments.map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        setDepartments(dataMain);
        setSecondaryDepartments([notSecondDepartment, ...dataSecondary]);
      }
    );
    setIsLoading(false);
  };

  const getEditedData = async () => {
    setIsLoading(true);
    const editedRow = usersPageStore.userEdit;
    const communityId = editedRow?.community?.id ?? "";
    setIdEdited(editedRow.id);
    setValue("image", editedRow.avatar || "");
    setImage(editedRow.avatar);
    setValue("first_name", editedRow.firstName);
    setValue("last_name", editedRow.lastName);
    setValue("email", editedRow.email);
    setValue(
      "phone",
      editedRow?.phone
        ? `${editedRow.phone.slice(0, 3)} ${editedRow.phone.slice(3, 10)}`
        : ""
    );
    setValue("department_id", editedRow?.departmentId ?? "");
    setValue("secondary_department_id", editedRow?.secondaryDepartmentId ?? "");
    setValue("community_id", communityId);
    setTimeout(() => {
      setValue(
        "admin_communities",
        editedRow.type === "ADMIN" ? editedRow.admin_communities : []
      );
    });
    setCommunity(editedRow?.community ?? {});
    setValue("role", editedRow.type);
    setSelectedRole(editedRow.type);
    setIsActiveEdited(editedRow.isActive);
    if (communityId) {
      await getDepartmentsFlow(communityId);
    }
    setCommunities(userStore.getCommunities());
    if (usersPageStore.editRowAnchor && usersPageStore.editRowWidth) {
      setEditRowWidth(usersPageStore.editRowWidth);
      setEditRowMaxWidth(usersPageStore.editRowMaxWidth);
      setAnchorEl(usersPageStore.editRowAnchor);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const roleType = userStore.getRoleType();
    setRoleType(roleType);
    let availableRoleTypes = [
      {
        value: "EDITOR",
        label: t("users.create.editor"),
      },
      {
        value: "USER",
        label: t("users.create.user"),
      },
    ];
    if (
      roleType === UserTypeEnum.owner ||
      roleType === UserTypeEnum.associatedOwner
    ) {
      availableRoleTypes = [
        {
          value: "ADMIN",
          label: t("users.create.admin"),
        },
        {
          value: "CHIEF_EDITOR",
          label: t("users.create.chiefEditor"),
        },
      ].concat(availableRoleTypes);
    } else if (roleType === UserTypeEnum.admin) {
      availableRoleTypes = [
        {
          value: "CHIEF_EDITOR",
          label: t("users.create.chiefEditor"),
        },
      ].concat(availableRoleTypes);
    }
    setAvailableRoleTypes(availableRoleTypes);
  }, []);

  useEffect(() => {
    getEditedData();
  }, [
    usersPageStore.editRowWidth,
    usersPageStore.editRowAnchor,
    usersPageStore.userEdit,
  ]);

  const handleCommunityChange = async (comID) => {
    await getDepartmentsFlow(comID);
  };

  const handleUpload = async (event) => {
    const data = event.target.files[0];
    if (data) {
      const image: any = await ProceedImage.toBase64(data);
      setValue("image", image);
      setImage(image);
    }
  };

  const handleSubmitCheck = (dataObject) => {
    const editedRow = usersPageStore.userEdit;
    let data = {
      ...dataObject,
      id: idEdited,
      image: dataObject.image || "",
      phone: dataObject.phone.replace(" ", ""),
    };
    const dataKeys = Object.keys(data);
    dataKeys.forEach((el) => {
      if (!data[el] && el !== 'secondary_department_id') delete data[el];
    });
    if(data.secondary_department_id === "null"){
      data.secondary_department_id = null;
    }
    if (dataObject.role === "ADMIN") {
      data = {
        ...data,
        community_id: data.admin_communities[0],
      };
      delete data["department_id"];
      delete data["secondary_department_id"]
    } else {
      delete data["admin_communities"];
    }
    handleSaveEdit(data, editedRow.isActive !== isActiveEdited);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    usersPageStore.editRowAnchor = null;
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      image: "",
      community_id: "",
      admin_communities: [],
      department_id: "",
      secondary_department_id: "",
      role: "",
      password: "",
    },
  } as any);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const handleValidationCheck = () => {
    const keys = Object.keys(errors);
    if (keys.length) {
      handleErrorPopUp(errors[keys[0]].message);
    }
  };

  const getCommunityName = (id) => {
    const index = communities.findIndex((el) => el.id === id);
    if (index >= 0) {
      return communities[index].communityName;
    }
    return "";
  };

  return (
    <Popover
      id="edit-user-container"
      className="user-edit"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
    >
      <div className="scroll-wrap" style={{ maxWidth: editRowWidth }}>
        <form
          className="edit-row-container"
          style={{ width: editRowMaxWidth }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
        >
          <div style={{ width: 85 }} />
          <div style={{ width: 90 }}>
            <label
              htmlFor="fileUpload"
              className="upload-image-container flex-center"
            >
              <Avatar
                className="table-row-image round"
                alt={`${getValues("first_name")} ${getValues("last_name")}`}
                src={image}
              />
              <PublishIcon className="upload-icon" />
            </label>
            <input
              id="fileUpload"
              type="file"
              name="file"
              onChange={handleUpload}
              hidden={true}
            />
          </div>
          <div
            style={{
              flex: 1,
              padding: lngDirection !== "rtl" ? "0 0 0 16px" : "0 16px 0 0",
            }}
          >
            <Controller
              control={control}
              name="first_name"
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    value={LEFT_TO_RIGHT_MARK + value}
                    onChange={(e) =>
                      onChange(e.target.value.replace(LEFT_TO_RIGHT_MARK, ""))
                    }
                    className={`${
                      errors?.first_name?.message ? "error-field" : ""
                    }`}
                    autoComplete="off"
                  />
                </>
              )}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="last_name"
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    value={LEFT_TO_RIGHT_MARK + value}
                    onChange={(e) =>
                      onChange(e.target.value.replace(LEFT_TO_RIGHT_MARK, ""))
                    }
                    className={`${
                      errors?.last_name?.message ? "error-field" : ""
                    }`}
                    autoComplete="off"
                  />
                </>
              )}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    value={LEFT_TO_RIGHT_MARK + value}
                    onChange={(e) =>
                      onChange(e.target.value.replace(LEFT_TO_RIGHT_MARK, ""))
                    }
                    className={`${errors?.email?.message ? "error-field" : ""}`}
                    autoComplete="off"
                  />
                </>
              )}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    value={value}
                    onChange={(e) =>
                      onChange(formatToPhoneNumber(e.target.value, value))
                    }
                    className={`${errors?.phone?.message ? "error-field" : ""}`}
                    autoComplete="off"
                  />
                </>
              )}
            />
          </div>
          <div style={{ maxWidth: 150, flex: 1 }}>
            {roleType !== UserTypeEnum.owner ? (
              <span>{community?.communityName ?? ""}</span>
            ) : (
              <>
                {selectedRole !== "ADMIN" ? (
                  <Controller
                    control={control}
                    name="community_id"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControl>
                          <Select
                            id="select-community"
                            className={`select-type ${
                              errors?.community_id?.message ? "error-field" : ""
                            }`}
                            value={value}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              onChange(e);
                              setValue("department_id", "");
                              setValue("secondary_department_id", "");
                              handleCommunityChange(value);
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {communities.map((el, index) => {
                              return (
                                <MenuItem key={index} value={el.id}>
                                  {el.communityName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="admin_communities"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FormControl>
                          <Select
                            id="select-community"
                            className={`select-type multi-select-type ${
                              errors?.admin_communities?.message
                                ? "error-field"
                                : ""
                            }`}
                            value={[].concat(value)}
                            onChange={(e: any) => {
                              const newArray = e.target.value.filter((el) => {
                                const index = communities.findIndex(
                                  (com) => com.id === el
                                );
                                return index >= 0;
                              });
                              const firstCommunity = newArray?.[0] ?? "";
                              if (firstCommunity !== firstSelectedCommunity) {
                                setFirstSelectedCommunity(firstCommunity);
                                handleCommunityChange(firstCommunity);
                              }
                              setValue("admin_communities", newArray);
                            }}
                            multiple
                            renderValue={(value: any) => {
                              return value
                                .map((el) => getCommunityName(el))
                                .join(", ");
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {communities.map((el, index) => {
                              return (
                                <MenuItem key={index} value={el.id}>
                                  <FormControlLabel
                                    style={{ pointerEvents: "none" }}
                                    control={
                                      <Checkbox
                                        style={{ pointerEvents: "auto" }}
                                        color="primary"
                                        checked={value.indexOf(el.id) !== -1}
                                      />
                                    }
                                    label={el.communityName}
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  />
                )}
              </>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="department_id"
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControl>
                    <Select
                      id="select-departmentEdited"
                      className={`select-type ${
                        errors?.department_id?.message ? "error-field" : ""
                      }`}
                      value={value}
                      onChange={onChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {departments.map((el, index) => {
                        return (
                          <MenuItem key={index} value={el.id}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              control={control}
              name="secondary_department_id"
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControl>
                    <Select
                      id="select-departmentEdited"
                      className={`select-type`} // change
                      value={value === null ? t("common.noSecondaryDepartments") : value}
                      onChange={onChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {secondaryDepartments.map((el, index) => {
                        return (
                          <MenuItem key={index} value={el.id}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            />
          </div>
          <div style={{ width: 150 }}>
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControl>
                    <Select
                      id="select-role"
                      className={`select-type ${
                        errors?.role?.message ? "error-field" : ""
                      }`}
                      value={value}
                      // @ts-ignore
                      onChange={(e: any) => {
                        setSelectedRole(e.target.value);
                        if (roleType === UserTypeEnum.owner) {
                          setValue("community_id", "");
                          setValue("admin_communities", []);
                        } else if (roleType === UserTypeEnum.associatedOwner) {
                          setValue(
                            "admin_communities",
                            [].concat(getValues("community_id"))
                          );
                        }
                        onChange(e);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {availableRoleTypes.map((role, index) => {
                        return (
                          <MenuItem key={index} value={role.value}>
                            {role.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            />
          </div>
          <div style={{ width: 135 }}>
            {!resetPassword ? (
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => setResetPassword(true)}
              >
                {t("resetPassword.title")}
              </Button>
            ) : (
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      value={value}
                      onChange={onChange}
                      className={`${
                        errors?.password?.message ? "error-field" : ""
                      }`}
                      autoComplete="off"
                    />
                  </>
                )}
              />
            )}
          </div>
          <div
            style={{ width: 70 }}
            className="table-row-checkIcon-container edit-user"
            onClick={() => setIsActiveEdited(!isActiveEdited)}
          >
            <IconButton aria-label="Edit" className="icon-container">
              {isActiveEdited ? (
                <CheckIcon color="secondary" />
              ) : (
                <CheckIcon color="disabled" />
              )}
            </IconButton>
          </div>
          <div style={{ width: 70 }} className="flex-center">
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleValidationCheck}
            >
              {t("common.buttons.save")}
            </Button>
          </div>
          <div style={{ width: 53 }} className="flex-center">
            <CloseIcon className="icon-close" onClick={() => handleClose()} />
          </div>
          {isLoading ? <Spinner className="edit-spinner" /> : null}
        </form>
      </div>
    </Popover>
  );
};

export default observer(EditUser);
