import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  // Primary
  CATEGORY_ID: "category_id",
  PAGE_TYPE: "page_type",
  HEADER: "header",
  DESCRIPTION: "description",
  IMAGE: "image",
  APPEARS: "appears",
  FROM_DATE: "from_date",
  TO_DATE: "to_date",
  IS_PUSH_NOTIFICATION: "is_push_notification",
  PUSH_NOTIFICATION_TEXT: "push_notification_text",
  PUBLISH_WHEN: "publishWhen",
  PUBLISH_DATE: "publish_date",
  ARCHIVE_WHEN: "archiveWhen",
  ARCHIVE_DATE: "archive_date",

  // Leads
  DETAILS_FROM_DATE: "date_from",
  DETAILS_TO_DATE: "date_to",
  DETAILS: "details",
  EXTERNAL_LINK: "external_link",
  SUPPLIER_EMAIL: "supplier_email",
  SUPPLIER_SECOND_EMAIL: "supplier_second_email",
  // Leads - form builder
  FULL_NAME: "full_name",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  FIELDS: "fields",

  // Link
  BUTTON_TEXT: "button_text",
  LINK: "link",

  // Store
  DATE: "date",
  HOUR: "hour",
  LOCATION: "location",
  NUMBER_OF_TICKETS: "number_of_tickets",
  MAX_PURCHASES: "max_purchases",
  PRICE: "price",

  // Used in Store & Leads
  DETAILS_HEADER: "details_header",
  DETAILS_TEXT: "details_text",
};

export const VALIDATIONS = Yup.object().shape({
  // Primary
  [FIELDS.CATEGORY_ID]: Yup.string().required(() =>
    i18n.t("validationMessages.benefits.create.required")
  ),
  [FIELDS.PAGE_TYPE]: Yup.string().required(() =>
    i18n.t("validationMessages.benefits.create.required")
  ),
  [FIELDS.HEADER]: Yup.string()
    .min(2, () => i18n.t("validationMessages.benefits.create.minHeader"))
    .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
    .required(() => i18n.t("validationMessages.benefits.create.required")),
  [FIELDS.DESCRIPTION]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) => element.when('page_type', {
      is: (value) => value !== "STORE",
      then: (element) =>
        element
          .required(() => i18n.t("validationMessages.benefits.create.required"))
          .min(2, () => i18n.t("validationMessages.benefits.create.minHeader"))
          .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100})),
      otherwise: (element) => element.notRequired(),
    })
  }),
  [FIELDS.IMAGE]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() => i18n.t("validationMessages.benefits.create.required"))
        .min(1, () => i18n.t("validationMessages.benefits.create.image")),
  }),
  [FIELDS.APPEARS]: Yup.array().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() =>
          i18n.t("validationMessages.articles.addArticle.required")
        )
        .min(1, () =>
          i18n.t("validationMessages.articles.addArticle.required")
        ),
  }),
  [FIELDS.FROM_DATE]: Yup.mixed(),
  [FIELDS.TO_DATE]: Yup.mixed(),
  [FIELDS.IS_PUSH_NOTIFICATION]: Yup.boolean(),
  [FIELDS.PUSH_NOTIFICATION_TEXT]: Yup.string().when("is_push_notification", {
    is: true,
    then: (text) =>
      text
        .required(() => i18n.t("validationMessages.benefits.create.required"))
        .min(1, () => i18n.t("validationMessages.benefits.create.pushNot"))
        .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
        .when("is_draft", {
          is: true,
          then: (text) => text.notRequired(),
        }),
  }),
  [FIELDS.PUBLISH_WHEN]: Yup.string(),
  [FIELDS.PUBLISH_DATE]: Yup.string(),
  [FIELDS.ARCHIVE_WHEN]: Yup.string(),
  [FIELDS.ARCHIVE_DATE]: Yup.string(),

  // Leads
  [FIELDS.DETAILS_FROM_DATE]: Yup.string(),
  [FIELDS.DETAILS_TO_DATE]: Yup.string(),

  [FIELDS.DETAILS]: Yup.string(),
  [FIELDS.SUPPLIER_EMAIL]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => (value === "LEADS" || value === "SURVEY"),
        then: (element) =>
          element
            .required(() => i18n.t("validationMessages.auth.signUp.required"))
            .email(() => i18n.t("validationMessages.auth.signUp.email")),
        otherwise: (element) => element.notRequired(),
      }),
  }),
  [FIELDS.SUPPLIER_SECOND_EMAIL]: Yup.string()
    .email(() => i18n.t("validationMessages.auth.signUp.email"))
    .notRequired(),
  [FIELDS.FIELDS]: Yup.array().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => (value === "LEADS" || value === "SURVEY"),
        then: (element) =>
          element
            .required(() =>
              i18n.t("validationMessages.benefits.create.required")
            )
            .min(1, () =>
              i18n.t("validationMessages.benefits.create.atLeastOne")
            ),
        otherwise: (element) => element.notRequired(),
      }),
  }),

  //Link
  [FIELDS.BUTTON_TEXT]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => value === "LINK",
        then: (element) =>
          element.required(() =>
            i18n.t("validationMessages.benefits.create.required")
          ),
        otherwise: (element) => element.notRequired(),
      }),
  }),
  [FIELDS.LINK]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => value === "LINK",
        then: (element) =>
          element
            .url(() => i18n.t("validationMessages.benefits.create.url"))
            .required(() =>
              i18n.t("validationMessages.benefits.create.required")
            ),
        otherwise: (element) => element.notRequired(),
      }),
  }),

  // Store
  [FIELDS.DATE]: Yup.mixed(),
  [FIELDS.HOUR]: Yup.mixed(),
  [FIELDS.LOCATION]: Yup.string().notRequired(),
  [FIELDS.NUMBER_OF_TICKETS]: Yup.number().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => value === "STORE",
        then: (element) =>
          element
            .required(() =>
              i18n.t("validationMessages.benefits.create.required")
            )
            .min(1, () => i18n.t("validationMessages.benefits.create.tickets")),
        otherwise: (element) => element.notRequired(),
      }),
  }),
  [FIELDS.MAX_PURCHASES]: Yup.number().notRequired()
    .max(Yup.ref('number_of_tickets'), () => i18n.t("validationMessages.benefits.create.maxPurchases")),
  [FIELDS.PRICE]: Yup.number().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => value === "STORE",
        then: (element) =>
          element
            .required(() =>
              i18n.t("validationMessages.benefits.create.required")
            )
            .min(1, () => i18n.t("validationMessages.benefits.create.price")),
        otherwise: (element) => element.notRequired(),
      }),
  }),

  // Used in Store & Leads
  [FIELDS.DETAILS_HEADER]: Yup.string().notRequired(),
  [FIELDS.DETAILS_TEXT]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("page_type", {
        is: (value) => value !== "LEADS",
        then: (element) => element.notRequired(),
        otherwise: (element) => element
          .required(() =>
            i18n.t("validationMessages.benefits.create.required")
          )
          .min(2, () =>
            i18n.t("validationMessages.benefits.create.minHeader")
          )
          .max(10000, () =>
            i18n.t("validationMessages.benefits.create.detailsText")
          ),
      }),
  }),
});
