import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormHelperText,
  Grid,
  Link as MuiLink,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../../store/StoreContext";
import { FIELDS, VALIDATIONS } from "./fields";
import { flowResult } from "mobx";
import TermsAndPrivacyPolicy from "../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy";
import LanguageSelection from "../../../components/LanguageSelection/LanguageSelection";
import { useTranslation } from "react-i18next";
import Spinner from '../../../components/Spinner/Spinner';
import { formatToPhoneNumber } from '../../../shared/utils/sharedFunctions';
import NotificationModal from "../../../components/NotificationModal/NotificationModal";

const SignInSMS = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userStore } = useStore();
  const [isDisabled, setIsDisabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState("");
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      phone: "",
    },
  });

  const watchFields = watch();
  const validate = async (form: any) => {
    // @ts-ignore
    const resolver = yupResolver(VALIDATIONS)(form, null, {
      criteriaMode: "firstError",
    });
    setIsDisabled(Object.values((await resolver).errors).length > 0);
  };

  useEffect(() => {
    validate(watchFields);
  }, [watchFields]);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    const windowHeight = window.outerHeight;
    if (windowHeight < 768) {
      setWindowHeight(`${windowHeight}px`);
    } else {
      setWindowHeight("100%")
    }
    setIsLoading(false);
  }, []);

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const handleSubmitCheck = (payload) => {
    setIsLoading(true);
    setSubmitError("");
    const _platformID = userStore.getPlatformID();
    if(!_platformID) {
      handleNotificationOpen(t('validationMessages.auth.signIn.noPlatform'));
      setIsLoading(false);
      return;
    }
    const _payload = { ...payload, phone: payload.phone.replace(" ", "",), platform_id: _platformID };

    flowResult(userStore.getOTP(_payload)).then((response: any) => {
      setIsLoading(false)
      if (!response?.success) {
        setSubmitError(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
      } else {
        history.push("/sms-code-number");
      }
    });
  };

  return isLoading ? (<Spinner />) : (
    <div className="scroll-container" style={{ height: windowHeight }}>
      <div className="sign-form-container">
        <div className="inner-full-container">
          <div>
            <form
              onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
              className="sign-form additional-box"
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="sign-container"
              >
                <Grid item xs={10} sm={6} md={5} lg={4}>
                  <Grid container>
                    <Grid item xs={12} className="sign-header">
                      <div className="double-column">
                        <div>
                          <h2>{t("common.signInLabel")}</h2>
                          <h3>
                            {t("common.doNotHaveAccount")}{" "}
                            <Link
                              className="cursor-pointer link no-underline"
                              to="/sign-up"
                            >
                              {t("common.signUpLabel")}
                            </Link>
                          </h3>
                        </div>
                        <LanguageSelection />
                      </div>
                    </Grid>
                    <Grid item xs={12} className="sign-body">
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid
                          item
                          xs={12}
                          className="sign-image-container flex-center"
                        >
                          <div className="sign-image-and-icon">
                            <img
                              src="https://www.pinclipart.com/picdir/big/165-1653686_female-user-icon-png-download-user-colorful-icon.png"
                              alt=""
                              className="sign-image"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} className="sign-field">
                          <FormHelperText error>
                            {submitError}&nbsp;
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} className="sign-field">
                          <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="phone"
                                  type="text"
                                  value={value}
                                  onChange={(e) =>
                                    onChange(
                                      formatToPhoneNumber(e.target.value, value)
                                    )
                                  }
                                  label={t("signIn.sms.fields.phone")}
                                  required
                                />
                                <p className="field-error">
                                  {errors?.phone?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*  <FormControl>*/}
                        {/*    <InputLabel id="platform-label" required>*/}
                        {/*      {t("settings.manageCategories.platform")}*/}
                        {/*    </InputLabel>*/}
                        {/*    <Controller*/}
                        {/*      control={control}*/}
                        {/*      name="platform_id"*/}
                        {/*      render={({ field: { onChange, value } }) => (*/}
                        {/*        <>*/}
                        {/*          <Select*/}
                        {/*            labelId="platform-label"*/}
                        {/*            id="platform"*/}
                        {/*            displayEmpty*/}
                        {/*            defaultValue=""*/}
                        {/*            onChange={onChange}*/}
                        {/*          >*/}
                        {/*            {platformList.map((cat, index) => (*/}
                        {/*              <MenuItem key={index} value={cat.id}>*/}
                        {/*                {cat.name}*/}
                        {/*              </MenuItem>*/}
                        {/*            ))}*/}
                        {/*          </Select>*/}
                        {/*          <p className="field-error">*/}
                        {/*            {errors?.platform_id?.message ?? ""}&nbsp;*/}
                        {/*          </p>*/}
                        {/*        </>*/}
                        {/*      )}*/}
                        {/*    />*/}
                        {/*  </FormControl>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} className="sign-buttons">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isDisabled}
                          >
                            {t("signIn.sms.submitButton")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={4} className="border-separator">
                  <div className="border-line" />
                  <div className="border-text">{t("signIn.sms.or")}</div>
                  <div className="border-line" />
                </Grid>
              </Grid>
              <Grid
                className="sign-container"
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item md={5} lg={4} className="second-container">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/sign-in")}
                  >
                    {t("signIn.sms.navToSignIn")}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div className="terms-and-policy cursor-pointer">
              <MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>
                {t("common.termsAndPrivacy")}
              </MuiLink>
            </div>
          </div>
          <TermsAndPrivacyPolicy
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          />
        </div>
      </div>
      <NotificationModal
        openModal={openNotificationModal}
        message={notificationMessage}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        buttonText={t("common.buttons.close")}
      />
    </div>
  );
};

export default SignInSMS;
