import React from 'react'
import {Props} from "./props";
import Sales from "./Sales/Sales";
import "./style.scss"
import {Route, useRouteMatch} from "react-router-dom";
import CreateSale from "./Create/CreateSale";

import { observer } from 'mobx-react-lite';

const Benefits = (props: Props) => {
    const { path } = useRouteMatch();

    return (
        <>
            <div className="main-content main-content-tabs">
                <Route path={`${path}/list`} exact component={Sales}/>
                <Route path={`${path}/create/sale`} exact component={CreateSale}/>
            </div>
        </>
    )
}

export default observer(Benefits)
