import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Checkbox,
  Input,
  InputAdornment,
} from "@material-ui/core";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import UploadFile from "../../../components/UploadFile/UploadFile";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import Person from "../../../assets/icons/article-person.svg";
import CommunityLogo from "../../../assets/icons/logo.svg";
import WebIcon from "@material-ui/icons/Web";
import ScrollContainer from "react-indiana-drag-scroll";
import SearchIcon from "../../../components/LeftSideBar/Icons/SearchIcon/SearchIcon";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import "./style.scss";
import ProceedImage from "../../../shared/utils/proceedImage";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UserTypeEnum from "../../../models/UserType";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../shared/utils/cropImage";
import { FIELDS, VALIDATIONS } from "./fields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const SOCIALS = ["web", "twitter", "facebook", "instagram", "tiktok"];

const Community = () => {
  const { t } = useTranslation();
  const { settingsStore, userStore } = useStore();
  const history = useHistory();
  const path = "community";
  const [roleType, setRoleType] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [isError, setIsError] = useState(false);

  const platform = userStore.platformSettings;
  
  useEffect(() => {
    setRoleType(userStore.getRoleType());
  }, []);

  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [linkList, setLinkList] = useState([]);

  const [userList, setUserList] = useState<any>([]);
  const [userSearchList, setUserSearchList] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [searchValue, setSearchValue] = useState("");

  const [isNewBackgroundUploaded, setIsNewBackgroundUploaded] = useState(false);
  const [isNewLogoUploaded, setIsNewLogoUploaded] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);

  // Ref
  const transparentLogoRef: any = useRef(null);
  const backgroundImageRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.transparent_logo?.message) {
      transparentLogoRef.current.scrollIntoView();
    } else if (errors?.background_image?.message) {
      backgroundImageRef.current.scrollIntoView();
    }
    const element = document.querySelector('.main-content-container');
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      transparent_logo: "",
      background_image: "",
    },
  });

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    setIsLoading(true);
    flowResult(settingsStore.getPathItems(path))
      .then((response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          setIsError(true);
          if (
            response?.message ===
            "You do not have access for the attempted action."
          ) {
            handleNotificationOpen(t("errorMessages.settings.noAccess"));
            setIsLogout(true);
            return;
          }
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsError(true);
          return;
        }
        setUserList(response?.["users"] ?? []);
        setUserSearchList(response?.["users"] ?? []);
        if (!response?.community["imageTransparentLogo"]) {
          setIsFirstTime(true);
          setIsLoading(false);
          return;
        }
        const logo = response?.community["imageTransparentLogo"] ?? "";
        const backgroundImage = response?.community["imageBackground"] ?? "";
        setLogo(logo);
        setValue('transparent_logo', logo)
        setBackground(backgroundImage);
        setValue('background_image', backgroundImage)
        setLinkList(response?.community["links"] ?? []);
        setIsLoading(false);
      })
  }, []);

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isError) {
      setIsError(false);
      return;
    }
    if (isLogout) {
      userStore.logout();
      history.push("/sign-in");
    }

    if (roleType === UserTypeEnum.associatedOwner && !isError) {
      if (!isFirstTime) return;
      history.push("/settings/general");
    }
  };

  const handleValueChange = (id, value) => (e) => {
    setLinkList(
      linkList.map((item, index) => {
        if (index === id) return { ...item, [value]: e.target.value };
        return item;
      })
    );
  };

  const handleUserSearch = (e) => {
    const value = e.target.value.toLocaleLowerCase().trim();
    setSearchValue(value);
    if (value) {
      setUserSearchList(
        userList.filter((el) => {
          const name = `${el.firstName} ${el.lastName}`.toLocaleLowerCase();
          return name.indexOf(value) !== -1;
        })
      );
    } else {
      setUserSearchList(userList);
    }
  };

  const handleAddItem = () => {
    setLinkList([...linkList, { link_name: "", icon: "", link: "" }]);
  };

  const handleDeleteItem = (id) => () => {
    setLinkList(linkList.filter((el, index) => index !== id));
  };

  const handleFileUpload = (setImage, imageType?) => async (data) => {
    const image: any = await ProceedImage.toBase64(data);
    setImage(image);
    if (imageType === "background") {
      setInnerLoading(true);
      setIsNewBackgroundUploaded(true);
      setValue('background_image', image)
      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          let zoom = 0;
          if (height > width) {
            zoom = (height / width * 0.936)
          } else {
            zoom = (width / height * 1.068)
          }
          zoom = zoom >= 1 ? zoom : zoom;
          setZoom(zoom);
          setMaxZoom(zoom * 4);
          setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            cropperImage.style.maxHeight = '100.1%';
            cropperImage.style.maxWidth = '100.1%';
          })
        };
        setInnerLoading(false)
      }
      reader.readAsDataURL(data);
    } else {
      setInnerLoading(true);
      setIsNewLogoUploaded(true);
      setValue('transparent_logo', image)
      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          let zoom = 0;
          if (height > width) {
            zoom = (height / width * 0.936)
          } else {
            zoom = (width / height * 1.068)
          }
          zoom = zoom >= 1 ? zoom : zoom;
          setZoomLogo(zoom);
          setMaxZoomLogo(zoom * 4);
          setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            cropperImage.style.maxHeight = '100.2%';
            cropperImage.style.maxWidth = '100.2%';
          })
        };
        setInnerLoading(false)
      }
      reader.readAsDataURL(data);
    }
  };

  const handleSubmitCheck = async () => {
    setIsLoading(true);
    let croppedBackgroundImage: any = "";
    let croppedLogoImage: any = "";
    if (isNewBackgroundUploaded) {
      croppedBackgroundImage = await cropImage(background, croppedAreaPixels);
      setBackground(croppedBackgroundImage);
    }
    if (isNewLogoUploaded) {
      croppedLogoImage = await cropImage(logo, croppedAreaPixelsLogo);
      setLogo(croppedLogoImage);
    }
    const data = {
      transparent_logo: croppedLogoImage,
      background_image: croppedBackgroundImage,
      links: [...linkList],
      community_leaders: [
        ...userList.map((user) => {
          return {
            id: user.id,
            is_community_leader: user.isCommunityLeader,
            role_description: user.roleDescription,
          };
        }),
      ],
    };
    flowResult(settingsStore.update(data, path))
      .then((response: any) => {
        setIsNewBackgroundUploaded(false);
        setIsNewLogoUploaded(false);
        setIsLoading(false);
        if (!response?.success) {
          setIsError(true);
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      })
  };

  const handleSelectLeader = (userID, isChecked) => {
    if (userID) {
      const newArray = userList.map((user: any) => {
        if (user.id === userID) {
          return { ...user, isCommunityLeader: !user.isCommunityLeader };
        }
        return user;
      });
      setUserList(newArray);
      setUserSearchList(
        newArray.filter((el) => {
          const name = `${el.firstName} ${el.lastName}`.toLocaleLowerCase();
          return name.indexOf(searchValue) !== -1;
        })
      );
    }
  };

  const stringToCapital = (text) => {
    if (text && text.length > 1) {
      text = text
        .toLowerCase()
        .split("_")
        .map((el) => {
          return el?.charAt(0).toUpperCase() + el?.slice(1);
        })
        .join(" ");
      return text;
    }
    return text;
  };

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(4);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  // Cropper
  const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 });
  const [zoomLogo, setZoomLogo] = useState(1);
  const [maxZoomLogo, setMaxZoomLogo] = useState(4);
  const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (setCroppedArea) => (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const cropImage = async (image, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    return croppedImage;
  };

  const validURL = (str) => {
    const pattern = new RegExp("((http|https)://)(www.)?"
      + "[a-zA-Z0-9@:%._\\+~#?&//=]"
      + "{2,256}\\.[a-z]"
      + "{2,6}\\b([-a-zA-Z0-9@:%"
      + "._\\+~#?&//=]*)"); // fragment locator
    return !!pattern.test(str);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper settings-community">
      <h2 className="page-heading">{t("settings.myCommunity.title")}</h2>
      <form className="" autoComplete="off" onSubmit={handleSubmit(() => handleSubmitCheck())}>
        <div className="main-wrapper">
          <Grid container className="content-grid" justifyContent="space-between">
            <Grid item xs={6} md={5} lg={4}>
              <Grid container>
                <Grid item xs={12} className="content-header">
                  <h3>{t("settings.myCommunity.subTitle")}</h3>
                </Grid>
                <Grid item xs={12} className="grid-row" ref={transparentLogoRef}>
                  <UploadFile
                    id="logo"
                    format={["image/png"]}
                    text={<>{t("settings.myCommunity.uploadLogo")}</>}
                    handleFileUpload={handleFileUpload(setLogo, "logo")}
                  />
                  <span className="information-message">{t("informationMessages.pngOnly")}</span>
                  <p className="field-error">
                    {errors?.transparent_logo?.message ?? ""}&nbsp;
                  </p>
                </Grid>
                <Grid item xs={12} className="grid-row" ref={backgroundImageRef}>
                  <UploadFile
                    id="background"
                    text={<>{t("settings.myCommunity.uploadBackground")}</>}
                    handleFileUpload={handleFileUpload(
                      setBackground,
                      "background"
                    )}
                  />
                  <span className="information-message">{t("informationMessages.imageOnly")}</span>
                  <p className="field-error">
                    {errors?.background_image?.message ?? ""}&nbsp;
                  </p>
                </Grid>
                <Grid item xs={12} className="content-header">
                  <h3>{t("settings.myCommunity.links")}</h3>
                </Grid>
                {linkList.map((link, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      className="grid-row item-box links"
                    >
                      <Grid container justifyContent="space-around">
                        <Grid item xs={6} md={7}>
                          <TextField
                            id="add-name"
                            type="text"
                            label={t("settings.myCommunity.addName")}
                            value={link.link_name}
                            onChange={handleValueChange(index, "link_name")}
                            required
                          />
                          <p className="field-error">
                            {link.link_name.length > 20 ? t('validationMessages.articles.addArticle.maxHeader', { max: 20 }) : ''}
                            &nbsp;
                          </p>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <FormControl>
                            <InputLabel id="icon-label">
                              {t("settings.myCommunity.icon")}
                            </InputLabel>
                            <Select
                              labelId="icon-label"
                              id="icon"
                              value={link.icon}
                              onChange={handleValueChange(index, "icon")}
                              required
                            >
                              {SOCIALS.map((v, index) => (
                                <MenuItem key={index} value={v}>{v}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={11} className="grid-row">
                          <TextField
                            id="link"
                            type="text"
                            label={t("benefits.create.form.link")}
                            value={link.link}
                            onChange={handleValueChange(index, "link")}
                            required
                          />
                          <p className="field-error">
                            {!validURL(link.link) ? t("validationMessages.articles.adFields.url") : ""}
                            &nbsp;
                          </p>
                        </Grid>
                        <Grid item xs={12} className="action-box">
                          <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid item xs={2}></Grid>
                            <Grid
                              item
                              xs={2}
                              className="action-box-item text-center"
                            >
                              <IconButton
                                aria-label="delete current item"
                                className="hover-item"
                                onClick={handleDeleteItem(index)}
                              >
                                <IconComponent icon={DeleteIcon} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid
                  item
                  xs={7}
                  className="add-item-box hover-item"
                  onClick={handleAddItem}
                >
                  <AddIcon />
                  <span>{t("settings.myCommunity.addLink")}</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5} md={6} lg={4} className="d-flex justify-center">
              <div className="preview-container">
                <div className="proportion-container">
                  <span className="text">{t('common.proportions', { x: 1, y: 1 })}</span>
                </div>
                <div className="background-container">
                  {isNewBackgroundUploaded ? (
                    <div className="crop-wrapper">
                      <Cropper
                        image={background ? background : ImagePlaceholder}
                        crop={crop}
                        zoom={zoom}
                        zoomSpeed={0.1}
                        minZoom={0.05}
                        maxZoom={maxZoom}
                        restrictPosition={false}
                        objectFit="contain"
                        cropSize={{
                          width: 280,
                          height: 280,
                        }}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete(setCroppedAreaPixels)}
                      />
                    </div>
                  ) : (background ? (
                    <img
                      className="background"
                      src={background}
                      alt="background"
                    />
                  ) : null
                  )}
                  <div className={`logo-wrapper ${!isNewLogoUploaded ? `d-flex ${logo ? 'justify-center align-center round' : ''}` : ''}`}>
                    {isNewLogoUploaded ? (
                      <div className="crop-wrapper">
                        <Cropper
                          image={logo ? logo : ImagePlaceholder}
                          crop={cropLogo}
                          zoom={zoomLogo}
                          zoomSpeed={0.1}
                          minZoom={0.05}
                          maxZoom={maxZoomLogo}
                          restrictPosition={false}
                          cropShape="round"
                          objectFit="contain"
                          cropSize={{
                            width: 140,
                            height: 140,
                          }}
                          onCropChange={setCropLogo}
                          onZoomChange={setZoomLogo}
                          onCropComplete={onCropComplete(setCroppedAreaPixelsLogo)}
                        />
                      </div>
                    ) : (
                      <img
                        className="logo"
                        src={logo ? logo : CommunityLogo}
                        alt="logo"
                      />
                    )}

                  </div>
                </div>

                <div className="content-separator" />

                <div className="content-container">
                  <ScrollContainer className="content-links">
                    {linkList.map((link, index) => {
                      return (
                        <div
                          key={index}
                          className="link flex-center flex-column"
                          onClick={() =>
                            link.link ? window.open(link.link, "_blank") : null
                          }
                        >
                          <WebIcon />
                          <span className="link-text">{link.link_name}</span>
                        </div>
                      );
                    })}
                  </ScrollContainer>
                </div>

                <div className="information-container">
                  <h3>{t(`settings.myCommunity.${platform}Leaders`)}</h3>
                  {userList.map((user: any, index) => {
                    if (user.isCommunityLeader) {
                      return (
                        <Grid key={index} container className="community-leader-container">
                          <Grid item xs={4} md={3}>
                            <img
                              className="user-photo"
                              src={Person}
                              alt="userPhoto"
                            />
                          </Grid>
                          <Grid item xs={8} md={9}>
                            <div className="user-name">{`${user.firstName} ${user.lastName}`}</div>
                            <div className="user-info">{user.email}</div>
                            <div className="user-info">
                              {user?.phone
                                ? `${user.phone.slice(0, 3)} ${user.phone.slice(
                                  3,
                                  10
                                )}`
                                : ""}
                            </div>
                            <div className="user-info">
                              {stringToCapital(user.roleDescription)}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="main-wrapper">
          <div className="content-header">
            <h3>{t(`settings.myCommunity.${platform}Leaders`)}</h3>
          </div>
          <Grid
            container
            className="content-grid"
            justifyContent="space-between"
            direction="column"
          >
            {/* <Grid item xs={5} lg={4} className="grid-row">
              <IconButton aria-label="select item">
                <Checkbox color="primary" className="select-all-checkbox" />
              </IconButton>
              <span>{t("settings.myCommunity.presentAllEditors")}</span>
            </Grid> */}
            <Grid item xs={5} lg={4}>
              <div className="user-list">
                <FormControl className="search-field-container">
                  <InputLabel htmlFor="search-field">
                    {t("common.fieldHeaders.search")}
                  </InputLabel>
                  <Input
                    id="search-field"
                    type="text"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={handleUserSearch}
                  />
                </FormControl>
                {userSearchList.map((user, index) => {
                  return (
                    <div key={index}>
                      <div>
                        <IconButton
                          aria-label="select item"
                          onClick={() =>
                            handleSelectLeader(user.id, user.isCommunityLeader)
                          }
                        >
                          <Checkbox
                            color="primary"
                            checked={user.isCommunityLeader}
                            className="select-all-checkbox"
                          />
                        </IconButton>
                        <span>
                          {user.firstName} {user.lastName}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="submit-buttons">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setTimeout(() => scrollToError())}
            disabled={linkList.filter(link => !validURL(link.link) || link.link_name.length > 20).length > 0}
          >
            {t("common.buttons.save")}
          </Button>
        </div>
      </form>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose}
        handleButtonClick={handleNotificationClose}
        buttonText={t("common.buttons.close")}
        message={notificationMessage}
      />
      {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
    </div>
  );
};

export default Community;
