import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PHONE: "phone",
  EMAIL: "email",
  PASSWORD: "password",
  REPEAT_PASSWORD: "repeat_password",
  IMAGE: "image",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.FIRST_NAME]: Yup.string().required(() =>
    i18n.t("validationMessages.auth.signUp.required")
  ).min(2, () => i18n.t("validationMessages.articles.addArticle.minHeader")),
  [FIELDS.LAST_NAME]: Yup.string().required(() =>
    i18n.t("validationMessages.auth.signUp.required")
  ).min(2, () => i18n.t("validationMessages.articles.addArticle.minHeader")),
  [FIELDS.PHONE]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () => i18n.t("validationMessages.auth.signUp.phonePattern"))
    .min(11, () => i18n.t("validationMessages.auth.signUp.phone"))
    .max(11, () => i18n.t("validationMessages.auth.signUp.phone")),
  [FIELDS.EMAIL]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .email(() => i18n.t("validationMessages.auth.signUp.email")),
  [FIELDS.PASSWORD]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .min(6, () => i18n.t("validationMessages.auth.signUp.minPass")),
  [FIELDS.REPEAT_PASSWORD]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .min(6, () => i18n.t("validationMessages.auth.signUp.minPass")),
  [FIELDS.IMAGE]: Yup.string(),
});
