import React from 'react'


const NotificationsIcon = () => {

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M6.89325 20L8.36825 18.5225C8.84075 18.05 9.10075 17.4225 9.10075 16.755V10.9088C9.10075 9.21254 9.83825 7.59129 11.1258 6.46379C12.4233 5.32629 14.0758 4.82629 15.797 5.05254C18.7058 5.43879 20.8995 8.06878 20.8995 11.1713V16.755C20.8995 17.4225 21.1595 18.05 21.6308 18.5213L23.107 20H6.89325ZM17.4995 22.9263C17.4995 24.05 16.3545 25 14.9995 25C13.6445 25 12.4995 24.05 12.4995 22.9263V22.5H17.4995V22.9263ZM25.6508 19.01L23.3995 16.755V11.1713C23.3995 6.82004 20.272 3.12379 16.1245 2.57504C13.722 2.25504 11.297 2.98879 9.47825 4.58379C7.64825 6.18629 6.60075 8.49129 6.60075 10.9088L6.5995 16.755L4.34825 19.01C3.762 19.5975 3.58825 20.4713 3.90575 21.2375C4.2245 22.005 4.96575 22.5 5.79575 22.5H9.9995V22.9263C9.9995 25.4488 12.242 27.5 14.9995 27.5C17.757 27.5 19.9995 25.4488 19.9995 22.9263V22.5H24.2033C25.0333 22.5 25.7733 22.005 26.0908 21.2388C26.4095 20.4713 26.237 19.5963 25.6508 19.01Z" fill="#577BF9" />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="24" height="26">
                <path clipRule="evenodd" d="M6.89325 20L8.36825 18.5225C8.84075 18.05 9.10075 17.4225 9.10075 16.755V10.9088C9.10075 9.21254 9.83825 7.59129 11.1258 6.46379C12.4233 5.32629 14.0758 4.82629 15.797 5.05254C18.7058 5.43879 20.8995 8.06878 20.8995 11.1713V16.755C20.8995 17.4225 21.1595 18.05 21.6308 18.5213L23.107 20H6.89325ZM17.4995 22.9263C17.4995 24.05 16.3545 25 14.9995 25C13.6445 25 12.4995 24.05 12.4995 22.9263V22.5H17.4995V22.9263ZM25.6508 19.01L23.3995 16.755V11.1713C23.3995 6.82004 20.272 3.12379 16.1245 2.57504C13.722 2.25504 11.297 2.98879 9.47825 4.58379C7.64825 6.18629 6.60075 8.49129 6.60075 10.9088L6.5995 16.755L4.34825 19.01C3.762 19.5975 3.58825 20.4713 3.90575 21.2375C4.2245 22.005 4.96575 22.5 5.79575 22.5H9.9995V22.9263C9.9995 25.4488 12.242 27.5 14.9995 27.5C17.757 27.5 19.9995 25.4488 19.9995 22.9263V22.5H24.2033C25.0333 22.5 25.7733 22.005 26.0908 21.2388C26.4095 20.4713 26.237 19.5963 25.6508 19.01Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
            </g>
        </svg>



    )

}


export default NotificationsIcon