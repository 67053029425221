import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import BackIcon from "../../../../../assets/icons/back-arrow-reverse.svg"
import IconComponent from "../../../../../shared/components/IconComponent/IconComponent";
import "./style.scss";
import NoBanners from "../../../../../assets/images/articles/noBanners.svg";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useStore } from '../../../../../store/StoreContext';

const SuccessfulNotifications = () => {
    const { t } = useTranslation();
    const { userStore } = useStore();
    const history = useHistory();
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setRoleType(userStore.getRoleType());
    }, [userStore.roleType]);

    return (
        <div className="successful-notification-container">
            <div className="back-icon-container d-flex justify-start">
                <div className="back-icon" onClick={() => history.push("/mobile/notifications/list")}>
                    <IconComponent icon={BackIcon} />
                </div>
            </div>
            <div className="image-holder">
                <img src={NoBanners} alt="" />
                <div>
                    <span className="top">{t('mobile.notifications.success.willBeHere')}</span>
                    <span className="bottom">{t('mobile.notifications.success.soon')}</span>
                </div>
            </div>
            <div className="great d-flex justify-center">
                <p>{t('mobile.notifications.success.great').toUpperCase()}</p>
            </div>
            <div className="message d-flex justify-center">
                {
                    roleType === 'editor' ? (
                        <p>{t('mobile.notifications.success.message')}</p>
                    ) : (
                        <p>{t('apiMessages.P200')}</p>
                    )
                }
            </div>
            <div className="d-flex justify-center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/mobile/notifications/list")}
                >
                    {t('mobile.notifications.success.allNotifications').toUpperCase()}</Button>
            </div>
        </div>
    )
}

export default SuccessfulNotifications
