import React, { useEffect, useState } from "react";
import "./style.scss";
import SearchIcon from "./Icons/SearchIcon/SearchIcon";
import AccountIcon from "./Icons/AccountIcon/AccountIcon";
// import HelpIcon from './Icons/HelpIcon/HelpIcon';
import NotificationsIcon from "./Icons/NotificationsIcon/NotificationsIcon";
import { NavLink, useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useStore } from "../../store/StoreContext";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import NotificationModal from "../NotificationModal/NotificationModal";
import { Badge, Tooltip } from "@material-ui/core";
import { flowResult } from "mobx";
import { APP_VERSION } from "../../config"

export interface LeftSideBarProps {
  openModal?: any;
  toggleSearch?: any;
  openSearch?: any;
}
const LeftSideBar = (props: LeftSideBarProps) => {
  const { t } = useTranslation();
  const { userStore, actionStore, usersPageStore } = useStore();
  const history = useHistory();
  const [letters, setLetters] = useState("");
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const isMobile = window.innerWidth < 736;

  const getMessages = async () => {
    await flowResult(usersPageStore.getMessages()).then((response: any) => {
      if (!response?.success) {
        return;
      }
      const messages = response.messages;
      setNewMessagesCount(messages.filter(mes => !mes.isRead).length);
    });
  }

  useEffect(() => {
    getMessages();
  }, [actionStore.checkCreate])

  useEffect(() => {
    setLetters(
      `${userStore.currentUser.firstName[0]}${userStore.currentUser.lastName[0]}`
    );
  }, [userStore.currentUser.firstName, userStore.currentUser.lastName]);

  const handleConfirm = () => {
    setOpenNotificationModal(false);
    userStore.logout();
    history.push("/sign-in");
  };

  const handleLogout = () => {
    setOpenNotificationModal(true);
    setNotificationMessage(t("leftSideBar.logOut"));
  };

  const handleSearchClick = () => {
    if (!actionStore.searchBarOpened) {
      const location = history.location.pathname;
      const searchList = [
        "/",
        "/dashboard/searchList",
        "/articles/list",
        "/articles/rotating-banner",
        "/articles/ads",
        "/benefits/list",
        "/community/list",
        "/reports/surveys",
        "/reports/purchases",
        "/reports/clicks",
        "/push/promotional",
        "/users/list",
      ];
      if (searchList.indexOf(location) === -1) {
        return;
      }
    }
    actionStore.toggleSearch(!actionStore.searchBarOpened);
  };
  return (
    <div className="left-side-bar-container">
      <div className="space"></div>
      <div className="icons-wrapper">
        {
          !isMobile ? (
            <div className="icon-wrapper" onClick={handleSearchClick}>
              <SearchIcon />
            </div>
          ) : null
        }
        <div className="icon-wrapper">
          <NavLink
            to="/account"
            activeClassName="active"
            onClick={() => actionStore.toggleCheckCreate()}
          >
            <AccountIcon />
          </NavLink>
        </div>
        <div className="icon-wrapper">
          <NavLink
            to={!isMobile ? "/messages/all-messages" : "/mobile/my-messages"}
            activeClassName="active"
            onClick={() => actionStore.toggleCheckCreate()}
          >
            <NotificationsIcon />
            {newMessagesCount !== 0 && (
              <Badge
                badgeContent={<span className="content">{newMessagesCount}</span>}
                color="primary"
                className="notifications-count"
              />
            )}
          </NavLink>
        </div>
        <div className="icon-wrapper" onClick={() => handleLogout()}>
          <ExitToAppIcon />
        </div>
      </div>
      <Tooltip title={APP_VERSION} placement="right" arrow>
        <div className="user-short-name" onClick={props.openModal}>
          {letters}
        </div>
      </Tooltip>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={handleConfirm}
        buttonText={t("common.buttons.yes")}
        cancelButtonText={t("common.buttons.no")}
        message={notificationMessage}
      />
    </div>
  );
};

export default observer(LeftSideBar);
