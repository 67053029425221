import React from 'react';
import iPhoneTemplateImage from '../../assets/images/iPhoneTemplateImage.png';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Link, Modal } from '@material-ui/core';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    openModal: boolean;
    handleClose: any;
    content?: any;
    className?: string;
    hideNotYet?: boolean;
}

const PreviewModal = ({openModal, handleClose, content, className, hideNotYet = false}: Props) => {
    const { t } = useTranslation();
    return (
        <div>
            <Modal
                open={openModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={`modal-preview ${className}`}
            >
                <div className="preview-container">
                    <div className="phone-container">
                        <img className="phone-image" src={iPhoneTemplateImage} alt="iPhoneX" />
                        <div className="content-container">
                            {content}
                        </div>
                    </div>
                    <div className="preview-buttons">
                        <Button variant="contained" color="primary" onClick={handleClose(true)}>{t('previewModal.looksGood')}</Button>
                        {
                            !hideNotYet && (<Link className="link-btn" onClick={handleClose()}>{t('previewModal.notYet')}</Link>)
                        }
                    </div>
                    <div className="preview-close">
                        <CloseIcon className="icon-close" onClick={handleClose()} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PreviewModal
