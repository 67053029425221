import React from 'react'
import "./ListRow.scss";

export interface ListRowComponentProps {
    children: any;
}
const ListRowComponent = (props: ListRowComponentProps) => {
    return (
        <div className="row-item-container">
            {props.children}
        </div>
    )

}


export default ListRowComponent