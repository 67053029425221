import React from 'react'
import {Props} from "./props";
import "./styles.scss"
import AllMessages from "./AllMessages/AllMessages";
import {Route, useRouteMatch} from "react-router-dom";

const Messages = (props: Props) => {
    const { path } = useRouteMatch();
    return (
        <>
            <div className="main-content main-content-tabs my-messages-page">
                <Route path={`${path}/all-messages`} component={AllMessages}/>
            </div>
        </>
    )
}

export default Messages
