
import React from "react";
import { LegentInfo } from "../PieChart";
import "./PieLagents.scss"
export interface PieLagentsProps {
    lagentInfo: LegentInfo[]
}
const PieLagents = (props: PieLagentsProps) => {

    return (
        <div className="lagent-list-wrapper">
            {
                props.lagentInfo.map((legent, idx) => {
                    return (
                        <div key={idx} className="legent-item">
                            <div className="precent-text" style={{color: legent.color}}>{legent.amountInPrecent}</div>
                            <div className="legent-label">{legent.label}</div>

                        </div>
                    )
                })
            }

        </div>
    )
}
export default PieLagents;