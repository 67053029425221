import { Button, Menu, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import '../style.scss';
import { useTranslation } from 'react-i18next';

const EditSales = ({ handleSaveEdit }) => {
    const { i18n, t } = useTranslation();
    const { actionStore, languageStore } = useStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editRowWidth, setEditRowWidth] = useState(0);

    const [idEdited, setIdEdited] = useState('');
    const [messageEdited, setMessageEdited] = useState('');

    const [sentToEdited, setSentToEdited] = useState('');
    const [openRateEdited, setOpenRateEdited] = useState(0);
 

    useEffect(() => {
        setEditRowWidth(actionStore.editRowWidth);
        setAnchorEl(actionStore.editRowAnchor);
    }, [actionStore.editRowData, actionStore.editRowWidth, actionStore.editRowAnchor])

    useEffect(() => {
        const editedRow = actionStore.editRowDataPromo;
        setIdEdited(editedRow.id);
        setMessageEdited(editedRow.message);
        setSentToEdited(editedRow.sentTo);
        setOpenRateEdited(editedRow.openRate);
    }, [actionStore.editRowDataPromo])

    const handleSave = () => {
        let data = {
            id: idEdited,
            message: messageEdited,
            sentTo: sentToEdited,
            openRate: openRateEdited,
            isSent: false,
        };
        handleSaveEdit(data);
        actionStore.editRowAnchor = null;
    }

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng])

    return (
        <Menu
            id="edit-row-container"
            getContentAnchorEl={null}

            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
        >
            <div className="edit-row-container push-page edit" style={{ width: editRowWidth }}>
                <div style={{ flex: 1, paddingLeft: 57, fontSize: '14px' }}>
                    <TextField value={messageEdited} onChange={(e) => setMessageEdited(e.target.value)} />
                </div>
                <div style={{ width: 200 }}>
                    <Select
                        id="sentTo"
                        defaultValue="en"
                        className="select-type"
                        value={sentToEdited}
                        onChange={(e: any) => setSentToEdited(e.target.value)}
                    >
                        <MenuItem value="All new users">All new users</MenuItem>
                        <MenuItem value="Jake">Jake</MenuItem>
                        <MenuItem value="Lana">Lana</MenuItem>
                        <MenuItem value="That Other Guy">That Other Guy</MenuItem>
                    </Select>
                </div>
                <div style={{ width: 125 }} className="flex-center">
                    <TextField
                        type="number"
                        value={openRateEdited}
                        onChange={(e) => {
                            const number = Number(e.target.value);
                            if (number > 100) {
                                setOpenRateEdited(100)
                            } else {
                                setOpenRateEdited(Number(e.target.value))
                            }
                        }}
                    />%  
                </div>
                <div style={{ width: 258 }}>
                    <div style={{ width: '87%', textAlign: lngDirection !== 'rtl' ? 'right' : 'left'}}>
                        <Button size="small" variant="contained" color="primary" onClick={handleSave}>{t('common.buttons.save')}</Button>
                    </div>
                </div>
            </div>
        </Menu>
    )
}

export default observer(EditSales)
