import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class CommunityStore {
    root: RootStore;

    path = "/admin/community";
    // communities: any = [];
    searchParams = {};
    userEdit: any = {};
    editRowAnchor = null;
    editRowWidth = 0;
    triggerUpdateList = false;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setSearchParams = (data) => {
        this.searchParams = data
        this.triggerUpdateList = true;
    }

    getSearchParams = () => {
        return this.searchParams;
    }

    clearSearchParams = () => {
        this.searchParams = {};
    }

    * getCommunities(hasParams = {}) {
        if (Object.keys(this.searchParams).length) {
            this.clearSearchParams()
        }
        const response = yield Api.get(this.path, hasParams);
        return response?.data
    }

    * getCommunityData(communityID) {
        const response = yield Api.get(`${this.path}/${communityID}`);
        return response?.data
    }

    * getAllUsers(params) {
        const response = yield Api.get(`${this.path}/users`, params);
        return response?.data
    }

    * getCommunityUsers(communityID) {
        const response = yield Api.get(`${this.path}/${communityID}/users`);
        return response?.data
    }

    * addUsers(payload, communityID) {
        const response = yield Api.put(`${this.path}/${communityID}/users`, payload);
        return response?.data
    }

    * addEditors(payload, communityID) {
        const response = yield Api.put(`${this.path}/${communityID}/editors`, payload);
        return response?.data
    }

    * updateEditor(payload, communityID, userID) {
        const response = yield Api.put(`${this.path}/${communityID}/editors/${userID}`, payload);
        return response?.data
    }

    * deleteUser(communityID, userID) {
        const response = yield Api.delete(`${this.path}/${communityID}/users/${userID}`);
        return response?.data
    }

    * create(payload: any) {
        const response = yield Api.post(this.path, payload);
        return response?.data
    }

    * update(payload: any, communityID) {
        const response = yield Api.put(`${this.path}/${communityID}`, payload);
        return response?.data
    }

    * updateCommunityGroup(communityID, groupID) {
        const response = yield Api.put(`${this.path}/${communityID}/group/${groupID}`, {});
        return response?.data
    }

    * delete(communityID) {
        const response = yield Api.delete(`${this.path}/${communityID}`);
        return response?.data
    }
}
