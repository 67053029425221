import React from 'react'
import PrimaryBoxComponent from '../../../shared/components/PrimaryBox/PrimaryBox';
import "./CurrentWeekEventList.scss";
import EventItem, { EventItemComponentProps } from './EventItem/EventItem';
import { useTranslation } from 'react-i18next';
export interface CurrentWeekEventListProps {
    events: EventItemComponentProps[];
    isLoading?: boolean;
    openModal?: any;
}

const CurrentWeekEventList = (props: CurrentWeekEventListProps) => {
    const { t } = useTranslation();
    return (
        <PrimaryBoxComponent>
            <div className="current-week-events-container">
                <div className="current-week-events-title">{t('dashboard.currentWeekEventList')}</div>
                <div className="scroll-container">
                    {
                        props.events.map((event, idx) => {
                            return (
                                <EventItem isLoading={props.isLoading} key={idx} {...event} openModal={props.openModal} />
                            )
                        })
                    }
                    {
                        !props.events?.length ? (<p>{t('dashboard.noThisWeek')}</p>) : (<></>)
                    }
                </div>
            </div>
        </PrimaryBoxComponent>
    )

}


export default CurrentWeekEventList