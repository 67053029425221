import { useEffect, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import "./style.scss";

interface Props {
  value?: any;
  onChange?: any;
  isOpened: boolean;
  locale?: any;
}

const RangeDatePicker = ({
  value,
  isOpened = false,
  onChange,
  locale,
}: Props) => {
  const convertToDate = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const locale = "en-US";
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )}.${date.toLocaleDateString(locale, option2)}.${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  useEffect(() => {
    if (isOpened) {
      document.body.className = document.body.className + " range-date-picker";
    } else {
      document.body.className = document.body.className.replace(
        " range-date-picker",
        ""
      );
    }
  }, [isOpened]);

  const [startDate, setStartDate] = useState(convertToDate(value.startDate));
  const [endDate, setEndDate] = useState(convertToDate(value.endDate));

  return (
    <div className="community-range-picker">
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={locale ? locale : enLocale}
      >
        <div className="from-date">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            // format="dd/MM/yyyy"
            margin="normal"
            id="publishDate-picker-from-date"
            // maxDate={new Date()}
            className="date-picker-from-date"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={(value) => {
              const startValue = convertToDate(value);
              setStartDate(startValue);
              onChange({ startDate: startValue, endDate: endDate }, true);
            }}
            value={startDate}
            open={isOpened}
          />
        </div>

        <div className="to-date">
          <KeyboardDatePicker
            // minDate={startDate}
            disableToolbar
            variant="inline"
            // format="dd/MM/yyyy"
            // maxDate={new Date()}
            margin="normal"
            id="publishDate-picker-to-date"
            className="date-picker-to-date"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={(value) => {
              const endValue = convertToDate(value);
              setEndDate(endValue);
              onChange({ startDate: startDate, endDate: endValue }, true);
            }}
            value={endDate}
            open={isOpened}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default RangeDatePicker;
