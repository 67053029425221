import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import NoArticles from "../../../assets/images/articles/noArticles.svg";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import EditIcon from "../../../assets/icons/edit.svg";
import ViewIcon from "../../../assets/icons/view.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserTypeEnum from "../../../models/UserType";
import { Button, Checkbox, IconButton } from "@material-ui/core";
import "./styles.scss"

const PromotionalNotifications = () => {
  const { i18n, t } = useTranslation();
  const {
    languageStore,
    pushStore,
    groupStore,
    departmentStore,
    userStore,
    actionStore,
    mobileStore,
    settingsStore,
  } = useStore();
  const [sentList, setSentList] = useState<any>([]);
  const [toBeSentList, setToBeSentList] = useState<any>([]);
  const [noNotifications, setNoNotifications] = useState(false);

  const [isLoading, setIsLoading] = useState(false); //true
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [itemDeletion, setItemDeletion] = useState(false);
  const [roleType, setRoleType] = useState(null);
  const resolutionAbove1024 = window.innerWidth > 1024;

  const [approvalList, setApprovalList] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
  const [isSelectAllSent, setIsSelectAllSent] = useState(false);
  const [isSelectAllToBeSent, setIsSelectAllToBeSent] = useState(false);
  const [failedDeletedPushes, setFailedDeletedPushes] = useState([]);

  const getRoleIndex = (role) => {
    return Object.keys(UserTypeEnum).indexOf(role);
  }

  const getNotifications = async (params = {}) => {
    setIsLoading(true);
    await flowResult(pushStore.getNotifications({ ...params, limit: 50 })).then(
      (response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
          );
          return;
        }
        const data = response.pushes;
        if (data.length) {
          setNoNotifications(false);
          const approval: any = [];
          const sent: any = [];
          const toBeSent: any = [];
          data.forEach((push: any) => {
            if (!push.isApproved) {
              approval.push(push);
            } else if (push.isPublished) {
              sent.push(push);
            } else {
              toBeSent.push(push);
            }
          });
          setApprovalList(approval);
          console.log("\x1b[33m  file: PromotionalNotifications.tsx:89  getNotifications  sent:", sent[0])
          setSentList(sent);
          setToBeSentList(toBeSent);
        } else {
          setNoNotifications(true);
        }
      }
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setGroups(groupStore.getStoredGroups());
    setDepartments(departmentStore.getStoredDepartments());
    setSecondaryDepartments(departmentStore.getStoredSecondaryDepartments());
    setRoleType(userStore.getRoleType());
    getNotifications();
  }, []);

  const convertToDate = (date, type = "") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const locale = lngDirection === "rtl" ? "he-IL" : "en-US";
    if (type === "header") {
      const option1 = { month: "long" };
      const option2 = { year: "numeric" };
      return `${date.toLocaleDateString(
        locale,
        option1
      )} ${date.toLocaleDateString(locale, option2)}`;
    }
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  useEffect(() => {
    if (pushStore.triggerUpdateList) {
      getNotifications(pushStore.searchParams);
      pushStore.triggerUpdateList = false;
    }
  }, [pushStore.triggerUpdateList]);

  const handleNotificationOpen = (message = "", isDelete = false, id = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    if (isDelete) {
      if (id) {
        setPotentialEditRowID(id);
      }
      setButtonText(t("common.buttons.confirm"));
      setItemDeletion(true);
    }
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = (isDelete = false) => () => {
        if (isDelete) {
          setIsLoading(true);
          if (potentialEditRowID) {
            handleDelete();
          } else {
            deleteSelected();
          }
          setItemDeletion(false);
        }
        if (failedDeletedPushes.length) {
          const pushNames = sentList.concat(toBeSentList).filter((el) => failedDeletedPushes.indexOf(el.id) !== -1).map((el) => el.pushName).join(', ');
          setNotificationMessage(t('validationMessages.push.unableToDelete', {pushes: pushNames}))
          setFailedDeletedPushes([]);
          return;
        }
        setOpenNotificationModal(false);
      };

  // Edit
  const [potentialEditRowID, setPotentialEditRowID] = useState("");

  const handleDelete = async (id = "") => {
    let isError = false;
    await flowResult(pushStore.delete(id ? id : potentialEditRowID)).then(
      (response: any) => {
        if (!response?.success) {
          isError = true
        }
        if (!id) {
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
          );
        }
        return;
      }
    );
    if (!id) {
      getNotifications();
    }
    if (isError) {
      return id
    }
  };

  const handleDeleteAll = async (type) => {
    const params = {
      is_sent: type === 'isSent',
    }
    await flowResult(pushStore.deleteAll(params)).then(
      (response: any) => {
        setIsLoading(false);
        handleNotificationOpen(
          response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
        );
        return;
      }
    );
    getNotifications();
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0)
        })
      })
    }
  }, [languageStore.switchLng, isLoading === false])

  const getSentTo = (appears) => {
    if (roleType === UserTypeEnum.owner) {
      appears = appears.filter((el, index) => appears.findIndex((item) => item.groupId === el.groupId) === index)
    }
    const sentToNames = [];
    appears.forEach((el) => {
        if (!el.groupId && !el.departmentId) {
            return;
        }
        if (roleType === UserTypeEnum.owner) {
            const id = el.groupId;
            const index = groups.findIndex((el: any) => el.id === id);
            if (index >= 0) {
                sentToNames.push(groups[index].name);
            }
        } else {
            if(el.departmentId) {
                const id = el.departmentId;
                const index = departments.findIndex((el: any) => el.id === id);
                if (index >= 0) {
                    sentToNames.push(departments[index].name);
                }
              }
            if(el.secondaryDepartmentId) {
                const id = el.secondaryDepartmentId;
                const index = secondaryDepartments.findIndex((el: any) => el.id === id);
                if (index >= 0) {
                    sentToNames.push(secondaryDepartments[index].name);
                }
              }
        }
    });

    const allNames =  Array.from(new Set(sentToNames.filter(el => el))).join(", ");
    return allNames.length > 150 ? allNames.substring(0, 150) + '...' : allNames;
  };

  const handleSelect = (id, list, setList, activity, setActivity) => {
    const newList = list.map((el: any) => {
      if (id === el.id) {
        return { ...el, isSelect: !el.isSelect };
      }
      return el;
    });
    setList(newList);
    if (activity) setActivity(false)
  };

  const handleSelectAll = (list, setList, activity, setActivity) => {
    const newList = list.map((el: any) => {
      return { ...el, isSelect: !activity };
    });
    setList(newList);
    setActivity(!activity);
  };

  const handleDeleteSelected = () => {
    const list = sentList.concat(toBeSentList);
    const selected = list.filter((el) => el?.isSelect);
    if (selected.length) {
      handleNotificationOpen(t("errorMessages.deleteItems"), true);
    } else {
      handleNotificationOpen(t("errorMessages.needToSelect"));
    }
  };

  const deleteSelected = async () => {
    setIsLoading(true);
    const sentToDelete = sentList.filter((el: any) => el.isSelect); //all the selected items[] from sentList
    const toBeSentToDelete = toBeSentList.filter((el: any) => el.isSelect); //all the selected items[] from toBeSentList
    const newList = [];

    if (sentList.length && sentToDelete.length === sentList.length) {
      await handleDeleteAll('isSent')
    } else {
      newList.push(...sentToDelete);
    }

    if (toBeSentList.length && toBeSentToDelete.length === toBeSentList.length) {
      await handleDeleteAll('isToBeSent')
    } else {
      newList.push(...toBeSentToDelete);
    }

    if (newList.length) {
      await Promise.all([
        ...newList.map(async (el: any) => {
          return await handleDelete(el.id);
        })
      ]).then((res) => {
        res = res.filter(e => e);
        if (res.length) {
          setFailedDeletedPushes(res);
        }
        handleNotificationOpen(t(`apiMessages.P202`));
      });
      // const errorIDs = [...response].filter(e => e);
    }
   setTimeout(async () => {
    await getNotifications();
    setIsLoading(false);
    })
  };

  const handleApprove = async (id) => {
    setIsLoading(true);
    await flowResult(mobileStore.approvePush(id)).then(
      (response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
          );
          return;
        }
        handleNotificationOpen(
          response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
        );
      }
    );
    getNotifications();
  }

  const handleReject = async (id) => {
    setIsLoading(true);
    await flowResult(mobileStore.rejectPush(id)).then(
      (response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
          );
          return;
        }
        handleNotificationOpen(
          response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
        );
      }
    );
    getNotifications();
  }



  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      sortable: false,
      width: 90,
      headerClassName: "d-flex justify-center align-center",
      renderHeader: () => {
        return (
          <IconButton
            aria-label="select all items"
            onClick={() => handleSelectAll(sentList, setSentList, isSelectAllSent, setIsSelectAllSent)}
          >
            <Checkbox
              checked={isSelectAllSent}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
      renderCell: (params: GridCellParams) => {
        let row = params.row;
        return (
          <IconButton
            aria-label="select item"
            onClick={() => handleSelect(row.id, sentList, setSentList, isSelectAllSent, setIsSelectAllSent)}
          >
            <Checkbox
              checked={row?.isSelect ?? false}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
    },
    {
      field: "image",
      headerName: "",
      sortable: false,
      width: 90,
      renderHeader: () => {
        return <div style={{ pointerEvents: "none" }} />;
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row?.info?.article?.thumbnail ?? params.row?.image;
        return (
          <div className="table-row-image-container">
            <img src={row || ImagePlaceholder} alt={row} />
          </div>
        );
      },
    },
    {
      field: "pushName",
      headerName: t("push.promoNotification.message"),
      flex: 1,
      minWidth: 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.pushName}</span>;
      },
    },
    {
      field: "sentTo",
      headerName: t("push.promoNotification.sentTo"),
      sortable: false,
      width: resolutionAbove1024 ? 200 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {getSentTo(params.row.appears)}
          </span>
        );
      },
    },
    {
      field: "date",
      headerName: t("common.fieldHeaders.date"),
      sortable: false,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row.publishDate;
        return (<div className="table-row-date">{convertToDate(row)}</div>);
      },
    },
    {
      field: "openRate",
      headerName: t("push.promoNotification.openRate"),
      sortable: false,
      width: resolutionAbove1024 ? 150 : 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.openRate}</span>;
      },
    },
    {
      field: "actions",
      headerName: t("common.fieldHeaders.actions"),
      sortable: false,
      width: resolutionAbove1024 ? 250 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column cell-actions-header single-action`}>
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const data = params.row;
        const id = data.id;
        let currentRoleIndex: number = getRoleIndex(roleType);
        const role = data.createdBy?.toLowerCase() ?? '';
        if (roleType === 'editor' && userStore.getIsPermitted()) {
          currentRoleIndex = getRoleIndex('chiefEditor');
        }
        const rowRoleIndex: number = role === "chief_editor" ? getRoleIndex("chiefEditor") : getRoleIndex(role);
        const canEditOrDelete = currentRoleIndex <= rowRoleIndex;
        const hasSettingsPermissions = settingsStore.getUserPermissions().push;
        return (
          <div
            id={`grid-row-${id}`}
            className={`table-row-actions cell-actions single-action`}
          >
            {
              (hasSettingsPermissions && canEditOrDelete) && (
                <div
                  onClick={() =>
                    handleNotificationOpen(
                      t("errorMessages.deleteOne"),
                      true,
                      id
                    )
                  }
                >
                  <IconComponent icon={DeleteIcon} />
                </div>
              )
            }
          </div>
        );
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    }));

  const columnsToBeSent: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      sortable: false,
      width: 90,
      headerClassName: "d-flex justify-center align-center",
      renderHeader: () => {
        return (
          <IconButton
            aria-label="select all items"
            onClick={() => handleSelectAll(toBeSentList, setToBeSentList, isSelectAllToBeSent, setIsSelectAllToBeSent)}
          >
            <Checkbox
              checked={isSelectAllToBeSent}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
      renderCell: (params: GridCellParams) => {
        let row = params.row;
        return (
          <IconButton
            aria-label="select item"
            onClick={() => handleSelect(row.id, toBeSentList, setToBeSentList, isSelectAllToBeSent, setIsSelectAllToBeSent)}
          >
            <Checkbox
              checked={row?.isSelect ?? false}
              color="primary"
              className="select-all-checkbox"
            />
          </IconButton>
        );
      },
    },
    {
      field: "image",
      headerName: "",
      sortable: false,
      width: 90,
      renderHeader: () => {
        return <div style={{ pointerEvents: "none" }} />;
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row?.info?.article?.thumbnail ?? params.row?.image;
        return (
          <div className="table-row-image-container">
            <img src={row || ImagePlaceholder} alt={row} />
          </div>
        );
      },
    },
    {
      field: "pushName",
      headerName: t("push.promoNotification.message"),
      flex: 1,
      minWidth: 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.pushName}</span>;
      },
    },
    {
      field: "sentTo",
      headerName: t("push.promoNotification.sentTo"),
      sortable: false,
      width: resolutionAbove1024 ? 200 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {getSentTo(params.row.appears)}
          </span>
        );
      },
    },
    {
      field: "date",
      headerName: t("common.fieldHeaders.date"),
      sortable: false,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row.publishDate;
        return (<div className="table-row-date">{convertToDate(row)}</div>);
      },
    },
    {
      field: "openRate",
      headerName: t("push.promoNotification.openRate"),
      sortable: false,
      width: resolutionAbove1024 ? 150 : 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.openRate}</span>;
      },
    },
    {
      field: "actions",
      headerName: t("common.fieldHeaders.actions"),
      sortable: false,
      width: resolutionAbove1024 ? 250 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column cell-actions-header`}>
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const data = params.row;
        const id = data.id;
        let currentRoleIndex: number = getRoleIndex(roleType);
        const role = data.createdBy?.toLowerCase() ?? '';
        if (roleType === 'editor' && userStore.getIsPermitted()) {
          currentRoleIndex = getRoleIndex('chiefEditor');
        }
        const rowRoleIndex: number = role === "chief_editor" ? getRoleIndex("chiefEditor") : getRoleIndex(role);
        const canEditOrDelete = currentRoleIndex <= rowRoleIndex;
        const hasSettingsPermissions = settingsStore.getUserPermissions().push;
        return (
          <div
            id={`grid-row-${id}`}
            className={`table-row-actions cell-actions`}
          >
            {
              hasSettingsPermissions ? (
                <>
                  <Link
                    className={`view-link flex-center ${!canEditOrDelete && 'element-invisible'}`}
                    to={`/push/edit/${id}`}
                  >
                    <IconComponent icon={EditIcon} />
                  </Link>
                  <Link
                    className="view-link flex-center"
                    to={`/push/view/${id}`}
                  >
                    <IconComponent icon={ViewIcon} />
                  </Link>
                  <div
                    onClick={() =>
                      handleNotificationOpen(
                        t("errorMessages.deleteOne"),
                        true,
                        id
                      )
                    }
                    className={`view-link ${!canEditOrDelete && 'element-invisible'}`}
                  >
                    <IconComponent icon={DeleteIcon} />
                  </div>
                </>
              ) : (
                <Link
                  className="view-link flex-center"
                  to={`/push/view/${id}`}
                >
                  <IconComponent icon={ViewIcon} />
                </Link>
              )
            }
          </div>
        );
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );

  const columnsApproval: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      sortable: false,
      width: 90,
      headerClassName: "d-flex justify-center align-center",
      renderHeader: () => {
        return <div style={{ pointerEvents: "none" }} />;
      },
      renderCell: (params: GridCellParams) => {
        return null
      },
    },
    {
      field: "image",
      headerName: "",
      sortable: false,
      width: 90,
      renderHeader: () => {
        return <div style={{ pointerEvents: "none" }} />;
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row?.info?.article?.thumbnail ?? params.row?.image;
        return (
          <div className="table-row-image-container">
            <img src={row || ImagePlaceholder} alt={row} />
          </div>
        );
      },
    },
    {
      field: "pushName",
      headerName: t("push.promoNotification.message"),
      flex: 1,
      minWidth: 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return <span className="table-row-title">{params.row.pushName}</span>;
      },
    },
    {
      field: "sentTo",
      headerName: t("push.promoNotification.sentTo"),
      sortable: false,
      width: resolutionAbove1024 ? 200 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {getSentTo(params.row.appears)}
          </span>
        );
      },
    },
    {
      field: "date",
      headerName: t("common.fieldHeaders.date"),
      sortable: false,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row.publishDate;
        return (<div className="table-row-date">{convertToDate(row)}</div>);
      },
    },
    {
      field: "openRate",
      headerName: t("push.promoNotification.openRate"),
      sortable: false,
      width: resolutionAbove1024 ? 150 : 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <div className="d-flex justify-center w-100">
            <span className="table-row-title">{params.row.openRate}</span>
          </div>
        );
      },
    },
    {
      field: "approval",
      headerName: '',
      sortable: false,
      width: lngDirection === 'ltr' ? 230 : 200,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column cell-actions-header`}>
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const id = params.row.id;
        return roleType === 'chiefEditor' ? (
          <div className='table-row-actions approve-reject'>
            <div className="article-item button">
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleApprove(id)}
              >{t('users.waitingForApproval.approve')}</Button>
            </div>
            <div className="article-item button">
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => handleReject(id)}
              >{t('users.waitingForApproval.reject')}</Button>
            </div>
          </div>
        ) : null
      },
    },
    {
      field: "actions",
      headerName: t("common.fieldHeaders.actions"),
      sortable: false,
      width: resolutionAbove1024 ? 250 : 125,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column cell-actions-header`}>
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const data = params.row;
        const id = data.id;
        const currentRoleIndex: number = getRoleIndex(roleType);
        const role = data.createdBy?.toLowerCase() ?? '';
        const rowRoleIndex: number = role === "chief_editor" ? getRoleIndex("chiefEditor") : getRoleIndex(role);
        const canEditOrDelete = currentRoleIndex <= rowRoleIndex;
        return (
          <div
            id={`grid-row-${id}`}
            className={`table-row-actions cell-actions`}
          >
            {(canEditOrDelete && <Link
              className="view-link flex-center"
              to={`/push/edit/${id}`}
            >
              <IconComponent icon={EditIcon} />
            </Link>)}
            <Link
              className="view-link flex-center"
              to={`/push/view/${id}`}
            >
              <IconComponent icon={ViewIcon} />
            </Link>
            {(canEditOrDelete && <div
              onClick={() =>
                handleNotificationOpen(
                  t("errorMessages.deleteOne"),
                  true,
                  id
                )
              }
              className="view-link"
            >
              <IconComponent icon={DeleteIcon} />
            </div>)}
          </div>
        );
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper notification-page">
      <div className="page-heading-wrap">
        <h2 className="page-heading">
          {t("tabNavigation.push.promoNotification")}
        </h2>
      </div>
      <div className="sales-page">
        {!noNotifications && (
          <>
            {approvalList.length > 0 && (
              <div>
                <h3 className={"sub-header"}><span>{t('common.fieldHeaders.waitingApproval')} ({approvalList.length})</span></h3>
                <div className={`table-grid`}>
                  <DataGrid
                    autoHeight={true}
                    rows={approvalList}
                    columns={lngDirection === "ltr" ? columnsApproval : columnsApproval.reverse()}
                    rowHeight={90}
                    headerHeight={0}
                    disableColumnMenu={true}
                    hideFooter={true}
                  />
                </div>
              </div>
            )}
            {sentList.length > 0 && (
              <div>
                <h3 className={"sub-header"}>{t("reports.sent")}</h3>
                <div className="delete-select-container">
                  <Button
                    className="link-button-container"
                    onClick={handleDeleteSelected}
                  >
                    {t("users.allUsers.delete")}
                  </Button>
                </div>
                <div className={`table-grid`}>
                  <DataGrid
                    autoHeight={true}
                    rows={sentList}
                    columns={lngDirection === "ltr" ? columns : columns.reverse()}
                    rowHeight={90}
                    headerHeight={56}
                    disableColumnMenu={true}
                    hideFooter={true}
                  />
                </div>
              </div>
            )}
            {toBeSentList.length > 0 && (
              <div>
                <h3>{t("push.promoNotification.aboutToBeSent")}</h3>
                {!sentList.length ? (
                  <div className="delete-select-container">
                    <Button
                      className="link-button-container"
                      onClick={handleDeleteSelected}
                    >
                      {t("users.allUsers.delete")}
                    </Button>
                  </div>
                ) : null}
                <div className={`table-grid`}>
                  <DataGrid
                    autoHeight={true}
                    rows={toBeSentList}
                    columns={lngDirection === "ltr" ? columnsToBeSent : columnsToBeSent.reverse()}
                    rowHeight={90}
                    headerHeight={56}
                    disableColumnMenu={true}
                    hideFooter={true}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {noNotifications && (
          <div className="no-notifications">
            <img src={NoArticles} alt="" />
            <h3>{t("push.noNotifications.message1")}</h3>
            <Link to="/push/create" onClick={() => actionStore.toggleSearch(false)}>{t("push.noNotifications.message2")}</Link>
          </div>
        )}
      </div>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose(false)}
        handleButtonClick={handleNotificationClose(itemDeletion)}
        buttonText={buttonText}
        message={notificationMessage}
      />
    </div>
  );
};

export default observer(PromotionalNotifications);