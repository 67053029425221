import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TabsComponent from '../../shared/components/Tabs/TabsComponent';
import PromotionalNotifications from './PromotionalNotifications/PromotionalNotifications';
import {Props} from "./props";
import AutomatedNotifications from './AutomatedNotifications/AutomatedNotifications';
import { routes } from './routes';
import CreateNotification from './Create/CreateNotification';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const Push = (props: Props) => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();

    const tabs = [
        {
            title: t('tabNavigation.push.promoNotification'),
            component: PromotionalNotifications,
            url: `${path}/promotional`
        },
        {
            title: t('tabNavigation.push.autoNotification'),
            component: AutomatedNotifications,
            url: `${path}/automated`,
            hide: true
        },
        {
            title: 'Create Notification',
            component: CreateNotification,
            url: `${path}/create`,
            hide: true
        },
    ];
    return (
        <>
            <div className="main-content main-content-tabs push-page">
                <Switch>
                    <TabsComponent data={tabs} />
                    {
                        routes.map((route, index) => {
                            return (
                                <Route key={index} path={`${path}/${route.path}`} exact component={route.component}/>
                            )
                        })
                    }
                </Switch>
            </div>
        </>
    )
}
export default observer(Push)
