import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  COMMUNITY_NAME: "community_name",
  GROUP_ID: "group_id",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PHONE: "phone",
  EMAIL: "email",
  PASSWORD: "password",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.COMMUNITY_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.create.minHeader"))
    .max(30, () => i18n.t("validationMessages.community.create.maxHeader"))
    .required(() => i18n.t("validationMessages.community.create.required")),
  [FIELDS.GROUP_ID]: Yup.string().required(() =>
    i18n.t("validationMessages.community.create.required")
  ),
  [FIELDS.FIRST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.create.minHeader"))
    .max(15, () => i18n.t("validationMessages.community.create.maxHeader"))
    .required(() => i18n.t("validationMessages.community.create.required")),
  [FIELDS.LAST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.community.create.minHeader"))
    .max(15, () => i18n.t("validationMessages.community.create.maxHeader"))
    .required(() => i18n.t("validationMessages.community.create.required")),
  [FIELDS.PHONE]: Yup.string()
    .required(() => i18n.t("validationMessages.community.create.required"))
    .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () =>
      i18n.t("validationMessages.auth.signUp.phonePattern")
    )
    .min(11, () => i18n.t("validationMessages.community.create.phone"))
    .max(11, () => i18n.t("validationMessages.community.create.phone")),
  [FIELDS.EMAIL]: Yup.string()
    .email(() => i18n.t("validationMessages.community.create.email"))
    .required(() => i18n.t("validationMessages.community.create.required")),
  [FIELDS.PASSWORD]: Yup.string()
    .min(6, () => i18n.t("validationMessages.community.create.pass"))
    .required(() => i18n.t("validationMessages.community.create.required")),
});
