import React, { useEffect, useState } from "react";
import { Button, FormControl, Select, MenuItem } from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { noContent } from "../../../db/db";
import { IconButton } from "@material-ui/core";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import { exportToExcel } from "../../../shared/utils/sharedFunctions";
import ExportIcon from "../../../assets/icons/export.svg";

const WaitingForApproval = () => {
  const { i18n, t } = useTranslation();
  const { languageStore, usersPageStore, userStore } = useStore();
  const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isInnerLoading, setIsInnerLoading] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [itemType, setItemType] = useState("");
  const [innerLoading, setInnerLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [communities, setCommunities] = useState<any>([]);
  const [approvalList, setApprovalList] = useState([]);

  const convertToDate = (date, type = "") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const locale = lngDirection === "rtl" ? "he-IL" : "en-US";
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };
  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll(".MuiDataGrid-window").forEach((el) => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0);
        });
      });
    }
  }, [languageStore.switchLng, isLoading === false]);

  const [potentialEditRowID, setPotentialEditRowID] = useState("");

  const handleNotificationOpen = (message = "", isType = "", id = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    if (isType) {
      if (id) setPotentialEditRowID(id);
      setButtonText(t("common.buttons.confirm"));
      setItemType(isType);
    }
    setOpenNotificationModal(true);
  };

  const handleNotificationClose =
    (isType = "") =>
    () => {
      setItemType("");
      if (isType === "delete") {
        setIsLoading(true);
        if (potentialEditRowID) {
          deleteUser();
        } else {
          deleteAllRequests();
        }
      }
      if (isType === "approve") {
        setIsLoading(true);
        if (potentialEditRowID) {
          approveUser(potentialEditRowID);
        } else {
          approveAll();
        }
      }
      setOpenNotificationModal(false);
    };

  const deleteUser = async () => {
    await flowResult(usersPageStore.deleteUser(potentialEditRowID)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  const deleteAllRequests = async () => {
    await flowResult(usersPageStore.deleteAllApprovals()).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  const convertUsers = (users: any[]) =>
    users.map((user) => ({
      id: user.id,
      communityID: user?.connection[0]?.communityId ?? "",
      departmentID: user?.connection[0]?.departmentId ?? "",
      departmentList: user?.connection[0]?.departmentList ?? [],
      secondaryDepartmentID: "",
      secondaryDepartmentList: [],
    }));

  const getUsersList = async (params = {}) => {
    setIsLoading(true);
    await flowResult(
      usersPageStore.getUsers({ ...params, is_approved: false, limit: 100 })
    ).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        setIsLoading(false);
        return;
      }
      const data = response.users;
      console.log('data:', data)
      setTotalUsers(response.pagination.totalActive);
      setList(data);
      setApprovalList(convertUsers(data));
    });
    setIsLoading(false);
  };

  const getLoadMoreUsers = async (params = {}) => {
    setInnerLoading(true);
    await flowResult(
      usersPageStore.getUsers({
        ...params,
        is_approved: false,
        limit: 100,
        offset: list.length,
      })
    ).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        return;
      }
      const data = response.users;
      // setList(prev => [...prev, data]);
      // setApprovalList(prev => [...prev, convertUsers(data)]);
      setList(list.concat(data));
      setApprovalList(approvalList.concat(convertUsers(data)));
      setActivePage(activePage + 1);
    });
    setInnerLoading(false);
  };

  const approveUser = async (userID) => {
    const user = approvalList.find((user) => user.id === userID);
    const data = {
      community_id: user.communityID,
      department_id: user.departmentID,
      secondary_department_id: user.secondaryDepartmentID,
    };
    await flowResult(usersPageStore.approveUser(userID, data)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  const approveAll = async () => {
    const data = {
      users: approvalList.map((user) => {
        return {
          id: user.id,
          community_id: user.communityID,
          department_id: user.departmentID,
          secondary_department_id: user.secondaryDepartmentID,
        };
      }),
    };
    await flowResult(usersPageStore.approveAllUsers(data)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
    );
    getUsersList();
  };

  const getDepartmentsFlow = async (userID, comID) => {
    setIsInnerLoading(true);
    await flowResult(usersPageStore.getCommunityDepartments(comID)).then(
      (response: any) => {
        if (!response?.success) {
          return;
        }
        const departmentList = response.departments.map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        const secondaryDepartmentList = (
          response.secondaryDepartments || []
        ).map((dep) => {
          return { id: dep.id, name: dep.name };
        });
        const newUserData = approvalList.map((user) => {
          if (user.id === userID) {
            return {
              ...user,
              communityID: comID,
              departmentID: "",
              secondaryDepartmentID: "",
              departmentList,
              secondaryDepartmentList,
            };
          }
          return user;
        });
        setApprovalList(newUserData);
      }
    );
    setIsInnerLoading(false);
  };


  const handleExport = async ({}) => {
    setIsLoading(true);

    await flowResult(
      usersPageStore.exportUsers({ is_approved: false, limit: 100 })
    ).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        exportToExcel(
          response.file,
          t("users.waitingForApproval.title")
        );
        setIsLoading(false);
      }
    );
  };

  const handleCommunityChange = async (userID, comID) => {
    await getDepartmentsFlow(userID, comID);
  };

  const handleDepartmentChange = async (userID, depID) => {
    const newUserData = approvalList.map((user) => {
      if (user.id === userID) {
        return { ...user, departmentID: depID };
      }
      return user;
    });
    setApprovalList(newUserData);
  };

  const handleSecondaryDepartmentChange = async (userID, depID) => {
    const newUserData = approvalList.map((user) => {
      if (user.id === userID) {
        return { ...user, secondaryDepartmentID: depID };
      }
      return user;
    });
    setApprovalList(newUserData);
  };

  useEffect(() => {
    setCommunities(userStore.getCommunities());
    getUsersList();
  }, []);

  const tableData: GridColDef[] = [
    {
      field: "name",
      headerName: t("signUp.fields.name.label"),
      sortable: false,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const name = `${params.row.firstName} ${params.row.lastName}`;
        return <span className="table-row-title">{name}</span>;
      },
    },
    {
      field: "email",
      headerName: t("signUp.fields.email.label"),
      sortable: false,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title email">{params.row.email}</span>
        );
      },
    },
    {
      field: "phone",
      headerName: t("signUp.fields.phone.label"),
      sortable: false,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const rawPhone = params.row?.phone;
        const phone = rawPhone
          ? rawPhone?.replace(" ", "").replace("-", "")
          : " ";
        return (
          <span className="table-row-title">
            {`${phone.slice(0, 3)} ${phone.slice(3, 10)}`}
          </span>
        );
      },
    },
    {
      field: "community",
      headerName: t("tabNavigation.community.mainTab"),
      flex: 1,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const currentID = params.row.id;
        const approvalUser = approvalList.find((user) => user.id === currentID);
        if (!approvalUser) return <></>;
        const value = approvalUser.communityID;
        return (
          <div className="select-dropdown">
            <FormControl>
              <Select
                id="select-community"
                className={`select-type ${false ? "error-field" : ""}`}
                value={value}
                onChange={(e: any) => {
                  const communityID = e.target.value;
                  handleCommunityChange(currentID, communityID);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {communities.map((el, index) => {
                  return (
                    <MenuItem key={index} value={el.id}>
                      {el.communityName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "department",
      headerName: t("common.fieldHeaders.department"),
      flex: 1,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const currentID = params.row.id;
        const approvalUser = approvalList.find((user) => user.id === currentID);
        if (!approvalUser) return <></>;
        const departmentValue = approvalUser.departmentID;
        const communityValue = approvalUser.communityID;
        const departmentList = approvalUser.departmentList;
        return (
          <div className="select-dropdown">
            <FormControl>
              <Select
                id="select-departmentEdited"
                className={`select-type ${false ? "error-field" : ""}`}
                value={departmentValue}
                onChange={(e: any) => {
                  const departmentID = e.target.value;
                  handleDepartmentChange(currentID, departmentID);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={!(departmentList?.length > 0 && communityValue)}
              >
                {departmentList?.map((el, index) => {
                  return (
                    <MenuItem key={index} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "secondaryDepartment",
      headerName: t("common.fieldHeaders.secondaryDepartment"),
      flex: 1,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const currentID = params.row.id;
        const approvalUser = approvalList.find((user) => user.id === currentID);
        if (!approvalUser) return <></>;
        const departmentValue = approvalUser.secondaryDepartmentID;
        const communityValue = approvalUser.communityID;
        const departmentList = approvalUser.secondaryDepartmentList;
        return (
          <div className="select-dropdown">
            <FormControl>
              <Select
                id="select-departmentEdited"
                className={`select-type ${false ? "error-field" : ""}`}
                value={departmentValue}
                onChange={(e: any) => {
                  const departmentID = e.target.value;
                  handleSecondaryDepartmentChange(currentID, departmentID);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={!(departmentList?.length > 0 && communityValue)}
              >
                {departmentList?.map((el, index) => {
                  return (
                    <MenuItem key={index} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: t("common.fieldHeaders.date"),
      flex: 1,
      sortable: true,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className={`table-row-title`}>
            {convertToDate(params.row.createdAt)}
          </span>
        );
      },
    },
    {
      field: "actions",
      width: 200,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const currentID = params.row.id;
        const approvalUser = approvalList.find((user) => user.id === currentID);
        const communityValue = approvalUser?.communityID;
        const departmentValue = approvalUser?.departmentID;
        return (
          <Button
            color="primary"
            className="simple-action-button approve-all"
            onClick={() =>
              handleNotificationOpen(
                t("errorMessages.approveUser"),
                "approve",
                params.row.id
              )
            }
            disabled={!communityValue || !departmentValue}
          >
            {t("users.waitingForApproval.approve")}
          </Button>
        );
      },
    },
    {
      field: "delete",
      width: 100,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => (
        <IconButton
          aria-label="Delete"
          onClick={() =>
            handleNotificationOpen(
              t("errorMessages.deleteOneUser"),
              "delete",
              params.row.id
            )
          }
        >
          <IconComponent icon={DeleteIcon} />
        </IconButton>
      ),
    },
  ].map((column) => ({
    ...column,
    disableClickEventBubbling: true,
  }));

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper waiting-for-approval">
      <div className="page-heading-wrap">
        <h2 className="page-heading">{t("users.waitingForApproval.title")}</h2>
        <div>
          <Button
            className="export-button d-flex justify-between align-center"
            disabled={list.length === 0}
            onClick={handleExport}
          >
            {t("reports.leads.informationModal.export")}
           <IconComponent icon={ExportIcon} />
          </Button>
          <Button
            className="link-button-container"
            disabled={list.length === 0}
            onClick={() =>
              handleNotificationOpen(t("errorMessages.deleteAll"), "delete")
            }
          >
            {t("users.allUsers.delete")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              list.length === 0 ||
              approvalList.filter(
                (user) => !user?.communityID || !user?.departmentID
              ).length > 0
            }
            className={"approve-all"}
            onClick={() =>
              handleNotificationOpen(t("errorMessages.approveAll"), "approve")
            }
          >
            {t("users.waitingForApproval.approveAll")}
          </Button>
        </div>
      </div>
      <div className="approval-page content-wrap">
        {list?.length ? (
          <>
            <div className="table-grid">
              <DataGrid
                autoHeight={true}
                rows={list}
                columns={
                  lngDirection === "ltr" ? tableData : tableData.reverse()
                }
                rowHeight={90}
                disableColumnMenu={true}
                hideFooterSelectedRowCount={true}
                pagination={true}
                pageSize={100}
                rowsPerPageOptions={[]}
                onPageChange={(page) => {
                  if (activePage < page && list.length < totalUsers) {
                    getLoadMoreUsers();
                  }
                }}
                rowCount={totalUsers}
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomPagination,
                }}
              />
              {isInnerLoading && <Spinner />}
            </div>
          </>
        ) : (
          <div className="no-content">
            <img src={noContent.noApproval} alt="No messages" />
            <p>{t("users.waitingForApproval.noContent")}</p>
          </div>
        )}
        {innerLoading && <Spinner />}
      </div>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose("")}
        handleButtonClick={handleNotificationClose(itemType)}
        buttonText={buttonText}
        message={notificationMessage}
      />
    </div>
  );
};

export default WaitingForApproval;
