import {
  Button,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import ImagePlaceholder from "../../../../assets/images/image-placeholder.png";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import "../../Create/Ad/style.scss";
import "../../Create/shared_styles.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import PreviewModal from "../../../../components/PreviewModal/PreviewModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./adFields";
import { flowResult } from "mobx";
import { useStore } from "../../../../store/StoreContext";
import { useHistory } from "react-router";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import UserTypeEnum from "../../../../models/UserType";
import ProceedImage from "../../../../shared/utils/proceedImage";
import Spinner from "../../../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../shared/utils/cropImage";
import { useParams } from "react-router-dom";
import { convertLocalToZuluTime, convertZuluToLocalTime, formatAppearsIds } from "../../../../shared/utils/sharedFunctions";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
import { compressAccurately } from "image-conversion";

const ViewAd = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const params: any = useParams();
  const id = params.id;
  const areFieldsDisabled = params.type === "view";
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {
    actionStore,
    groupStore,
    departmentStore,
    userStore,
    adStore,
    languageStore,
  } = useStore();
  const [fileUpload, setFileUpload] = useState({
    lastModified: 0,
    lastModifiedDate: new Date(),
    name: "",
    size: 0,
    type: "",
    webkitRelativePath: "",
    url: ImagePlaceholder,
  });
  const [imageOriginal, setImageOriginal] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [isRedirect, setIsRedirect] = useState(true);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

  const resolutionIsOrBelow1440 = window.innerWidth <= 1440;
  const resolutionAbove1024 = window.innerWidth > 1024;
  const getCropWidth = () => {
    if (!resolutionIsOrBelow1440) return 352
    else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 281.6
    return 220;
  }
  const getCropHeight = () => {
    if (!resolutionIsOrBelow1440) return 376;
    else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 300.8;
    return 235;
  }

  // GET DATA
  const [groups, setGroups] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);

  const [roleType, setRoleType] = useState(null);

  // Preview Modal
  const [openModal, setOpenModal] = useState(false);
  // Notification Modal
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showImage, setShowImage] = useState(false);

  const calculateCropperSettings = (height, width) => {
    if (height > width * 1.06) {
      let zoom = (height / width * 0.936);
      zoom = zoom >= 1 ? zoom : 1;
      setZoom(zoom);
      setMaxZoom(zoom * 4);
    } else {
      setZoom(1);
      setMaxZoom(4);
    }
    setTimeout(() => {
      const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
      if (cropperImage.className.indexOf('reactEasyCrop_Cover_Vertical') > -1) {
        cropperImage.style.height = '100.1%';
      } else if (cropperImage.className.indexOf('reactEasyCrop_Cover_Horizontal') > -1) {
        cropperImage.style.width = '100.1%';
      }
    })
  };

  const handleFileUpload = async (data) => {
    if (data) {
      setInnerLoading(true);
      setShowImage(true);
      const image: any = await ProceedImage.toBase64(data);
      setFileUpload({ ...data, url: image });
      setValue("image", image);

      // Save original image
      let compressed: Blob;
      if (data.type.indexOf("image") !== -1) {
        // Maximum for firbase is 300kb
        compressed = await compressAccurately(data, {size: 280, accuracy: 0.99});
      }
      const formData = new FormData();
      formData.append("file", compressed || data);
      const imageToURL = await flowResult(actionStore.uploadFile(formData)).then(
        (response: any) => {
          setIsLoading(false);
          return response;
        }
      );
      setImageOriginal(imageToURL);

      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          calculateCropperSettings(height, width);
        };
      }
      reader.readAsDataURL(data);
      setIsNewPictureUploaded(true);
      setInnerLoading(false);
    }
  };

  const convertToDate = (date) => {
    if (!date) {
      return;
    }
    const locale = "en-US";
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      header: "",
      sub_header: "",
      image: "",
      cta_text: "",
      appears: [],
      publish_date: convertToDate(new Date()),
      archive_date: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 7))
      ),
      archive_hour: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 7))
      ),
      link: "",
      is_draft: false,
    },
  } as any);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const saveAd = async (data) => {
    setIsLoading(true);
    if (isNewPictureUploaded) {
      const formData = new FormData();
      formData.append("file", data.image);
      const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
        (response: any) => {
          return response;
        }
      );
      data = { ...data, image: croppedImage }
    }
    await flowResult(adStore.update(data, id)).then(
      (response: any) => {
        setIsLoading(false);
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), false)
          return;
        }
        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), true)
      }
    )
  }

  const transformToData = async (objectData) => {
    let croppedImage = objectData.thumbnail || objectData.image;
    if (isNewPictureUploaded) {
      croppedImage = await cropImage(croppedAreaPixels);
    }
    return {
      template: 0,
      header: objectData.header,
      sub_header: objectData.sub_header,
      image: croppedImage || objectData.image,
      image_original: imageOriginal ?? "",
      cta_text: objectData.cta_text,
      appears: objectData.appears.filter((el) => el !== 'select-all'),
      publish_date: convertLocalToZuluTime(publishDateAndTime),
      archive_date: convertLocalToZuluTime(archiveDateAndTime),
      details: {
        link: objectData.link,
      },
      is_draft: false,
    };
  };

  const handleSubmitCheck = async (objectData) => {
    if (areFieldsDisabled) {
      setOpenModal(true);
      return;
    }
    if (publishInvalidDate || archiveInvalidDate) {
      return;
    }
    let data = await transformToData(objectData);
    const dataKeys = Object.keys(data);
    dataKeys.forEach((el) => {
      if (!data[el] && el !== 'is_draft') delete data[el];
      if (el === "details") {
        const detailsKeys = Object.keys(data[el]);
        detailsKeys.forEach((det) => {
          // @ts-ignore
          if (!data.details[det]) delete data.details[det];
        });
      }
    });
    saveAd(data);
  };

  const handleCloseModal = () => () => {
    setOpenModal(false);
  };

  const handleNotificationOpen = (message = '', isRedirect) => {
    setNotificationMessage(message);
    setIsRedirect(isRedirect);
    setOpenNotificationModal(true);
  }

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isRedirect) history.push('/articles/ads')
  }
  useEffect(() => {
    const groups = groupStore.getStoredGroups();
    const departments = departmentStore.getStoredDepartments();
    const storedSecondaryDepartments = departmentStore.getStoredSecondaryDepartments();
    setGroups(groups);
    setDepartments(departments);
    setSecondaryDepartments(storedSecondaryDepartments);
    const roleType = userStore.getRoleType();
    setRoleType(roleType);
    flowResult(actionStore.getPathItems(`ads/${id}`))
      .then((response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
            true
          );
          setIsLoading(false);
          return;
        }
        const article = response.ad;
        let appears = formatAppearsIds(article?.appears, roleType);
        appears = appears.filter((el, index) => appears.indexOf(el) === index);
        const groupOrDepartment = roleType === UserTypeEnum.owner ? groups : [...departments, ...secondaryDepartments];
        if(roleType !== UserTypeEnum.owner) {
          setIsSelectAllSelected(appears.filter(depID => departments.some(dep => dep.id === depID)).length === departments.length);
          setIsSelectAllSelectedSecondary(appears.filter(depID => secondaryDepartments.some(dep => dep.id === depID)).length === secondaryDepartments.length);
        } else {
          setIsSelectAllSelected(appears.length === groupOrDepartment.length);
        }
        setValue("appears", appears.length === groupOrDepartment.length ? [...appears, 'select-all'] : appears);

        setValue("header", article?.header ?? "");
        setHeader(article?.header ?? "");
        setValue("sub_header", article?.subHeader ?? "");
        setSubHeader(article?.subHeader ?? "");
        setValue("image", article?.image ?? "");
        setImageOriginal(article?.imageOriginal ?? "");
        setFileUpload({
          ...fileUpload,
          url: article?.image ?? ImagePlaceholder,
        });
        setShowImage(!!article?.image);
        setValue("cta_text", article?.ctaText ?? "");
        setCtaText(article?.ctaText ?? "");
        const publishDate = convertZuluToLocalTime(article.publishDate);
        const archiveDate = convertZuluToLocalTime(article.archiveDate);
        setValue("publish_date", convertToDate(new Date(publishDate)));
        setPublishDateAndTime(new Date(publishDate));
        setPublishHourAndMinutes({
          hour: new Date(publishDate).getHours(),
          minutes: new Date(publishDate).getMinutes(),
        });
        setValue("archive_date", convertToDate(new Date(archiveDate)));
        setArchiveDateAndTime(new Date(archiveDate));
        setArchiveHourAndMinutes({
          hour: new Date(archiveDate).getHours(),
          minutes: new Date(archiveDate).getMinutes(),
        });
        setValue("link", article.details?.link ?? "");
        setIsLoading(false);
      })
  }, []);

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  // Ref
  const headerRef: any = useRef(null);
  const subHeaderRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const ctaRef: any = useRef(null);
  const appearsRef: any = useRef(null);
  const linkRef: any = useRef(null);
  const publishDateRef: any = useRef(null);
  const archiveDateRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.header?.message) {
      headerRef.current.scrollIntoView();
    } else if (errors?.sub_header?.message) {
      subHeaderRef.current.scrollIntoView();
    } else if (errors?.image?.message && !isNewPictureUploaded && !fileUpload.url) {
      imageRef.current.scrollIntoView();
    } else if (errors?.cta_text?.message) {
      ctaRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.appears?.message) {
      appearsRef.current.scrollIntoView();
    } else if (errors?.link?.message) {
      linkRef.current.scrollIntoView();
    } else if (publishInvalidDate) {
      publishDateRef.current.scrollIntoView();
    } else if (archiveInvalidDate) {
      archiveDateRef.current.scrollIntoView();
    }
    const element = document.querySelector('.main-content-container');
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
  const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const [archiveDateAndTime, setArchiveDateAndTime] = useState(new Date());
  const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const changeDateOnly = (
    time,
    hourAndMinutes,
    setDateAndTime,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      const hours = hourAndMinutes.hour;
      const minutes = hourAndMinutes.minutes;
      if (!isNaN(hours) && !isNaN(minutes))
        setDateAndTime((date: any) => {
          if (date) {
            date = new Date(time);
            date.setHours(hours, minutes);
            const newDate = new Date(date);
            return newDate;
          }
          return new Date();
        });
    }
  };

  const changeTimeOnly = (
    time,
    setDateAndTime,
    setHourAndMinutes,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
        setDateAndTime((date: any) => {
          if (date) {
            date.setHours(time.getHours(), time.getMinutes());
            const newDate = new Date(date);
            setHourAndMinutes({
              hour: time.getHours(),
              minutes: time.getMinutes(),
            });
            return newDate;
          }
          return new Date();
        });
    }
  };

  const [publishInvalidDate, setPublishInvalidDate] = useState(false);
  const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.4);
  const [maxZoom, setMaxZoom] = useState(4);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels,
      true
    );
    return croppedImage;
  };

  const handleShowOriginal = () => {
    const img = new Image();
    img.onload = function (e: any) {
      const imgInformation = e?.path?.[0] ?? {};
      if (Object.keys(imgInformation)) {
        calculateCropperSettings(imgInformation.height, imgInformation.width);
      }
    };
    img.src = imageOriginal;
    setIsNewPictureUploaded(true);
    setFileUpload({ ...fileUpload, url: imageOriginal });
    setValue("image", imageOriginal);
  }

  const onDepartmentSelectChange = (data, isSelectAll = false) => {
    const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...secondaries]);
    setIsSelectAllSelected(isSelectAll)
  }
const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
    const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...deps]);
    setIsSelectAllSelectedSecondary(isSelectAll);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="create-article ad">
      <div className="main-wrapper">
        <h2 className="page-heading">
          {areFieldsDisabled
            ? t("articles.ads.view.title")
            : t("articles.ads.edit.title")}
        </h2>
        <div className="create-preview">
          <form
            className=""
            autoComplete="off"
            onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
          >
            <div className="main-wrapper">
              <h3>{t("articles.allArticles.create.form.title")}</h3>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={lngDirection === "rtl" ? heLocale : enLocale}
              >
                <Grid container className="create-grid" justifyContent="space-between">
                  <Grid item xs={6} lg={4}>
                    <Grid container direction="column">
                      <Grid item className="grid-row" xs={12} ref={headerRef}>
                        <Controller
                          control={control}
                          name="header"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="header"
                                label={t("common.fieldHeaders.header")}
                                value={value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onChange(e);
                                  setHeader(value);
                                  setValue('header', value);
                                }}
                                disabled={areFieldsDisabled}
                              />
                              {!areFieldsDisabled && (
                                <p className="field-error">
                                  {errors?.header?.message ?? ""}&nbsp;
                                </p>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item className="grid-row" xs={12} ref={subHeaderRef}>
                        <Controller
                          control={control}
                          name="sub_header"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="sub-header"
                                label={t("common.fieldHeaders.subHeader")}
                                value={value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onChange(e);
                                  setSubHeader(value);
                                  setValue('sub_header', value);
                                }}
                                disabled={areFieldsDisabled}
                              />
                              {!areFieldsDisabled && (
                                <p className="field-error">
                                  {errors?.sub_header?.message ?? ""}&nbsp;
                                </p>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                      {!areFieldsDisabled ? (
                        <Grid item className="grid-row" xs={12} ref={imageRef}>
                          <div className="show-original-image-button">
                            <Button
                              variant="contained"
                              name="ShowOriginal"
                              color="primary"
                              onClick={handleShowOriginal}
                              disabled={isNewPictureUploaded || !imageOriginal}
                            >
                              {t('common.buttons.showOriginal')}
                            </Button>
                          </div>
                          <UploadFile
                            text={t("common.fieldHeaders.uploadImage")}
                            handleFileUpload={handleFileUpload}
                            showImage={showImage}
                            image={fileUpload.url}
                            width={126}
                            height={138}
                          />
                          <span className="information-message">{t("informationMessages.imageOnly")}</span>
                          {!areFieldsDisabled && (
                            <p className="field-error">
                              {!showImage ? errors?.image?.message ?? "" : ""}
                              &nbsp;
                            </p>
                          )}
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <Grid item className="grid-row" xs={12} ref={ctaRef}>
                        <Controller
                          control={control}
                          name="cta_text"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="cta-text"
                                label={t("common.fieldHeaders.ctaText")}
                                value={value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onChange(e);
                                  setCtaText(value);
                                  setValue('cta_text', value);
                                }}
                                disabled={areFieldsDisabled}
                              />
                              {!areFieldsDisabled && (
                                <p className="field-error">
                                  {errors?.cta_text?.message ?? ""}&nbsp;
                                </p>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item className="grid-row" xs={12} ref={appearsRef}>
                        <FormControl>
                          <Controller
                            control={control}
                            name="appears"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <label
                                  className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                    }`}
                                >
                                  {t("common.fieldHeaders.whoWillSeeAd")}
                                </label>
                                {roleType !== UserTypeEnum.owner && <><br />
                                      <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                            }`}>{t('common.fieldHeaders.department')}</label></>}
                                <MultiSelect
                                  showSelectAll
                                  value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                  hasError={!!errors?.appears?.message}
                                  isSelectAllSelected={isSelectAllSelected}
                                  mapBy="id"
                                  renderedName="name"
                                  onChange={onDepartmentSelectChange}
                                  selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                  isDisabled={
                                    areFieldsDisabled ||
                                    (roleType === UserTypeEnum.owner &&
                                      !groups.length) ||
                                    (roleType !== UserTypeEnum.owner &&
                                      !departments.length)
                                  }
                                />
                                {!areFieldsDisabled && (
                                  <>
                                    {(roleType === UserTypeEnum.owner &&
                                      groups.length) ||
                                      (roleType !== UserTypeEnum.owner &&
                                        departments.length) ? (
                                      <>
                                        {/* @ts-ignore */}
                                        <p className="field-error">  {errors?.appears?.message ?? ""}&nbsp;  </p>
                                      </>
                                    ) : (
                                      <p className="field-error">
                                        {roleType === UserTypeEnum.owner
                                          ? t(
                                            "errorMessages.articles.create.noGroups"
                                          )
                                          : t(
                                            "errorMessages.articles.create.noDepartments"
                                          )}
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item className="grid-row" xs={12}>
                        <FormControl>
                          <Controller
                            control={control}
                            name="appears"
                            render={({ field: { onChange, value } }) => (
                              <>
                              <label className={`field-label ${areFieldsDisabled ? "text-disabled" : ""
                                            }`}>{t('common.fieldHeaders.department')}</label>
                                            <MultiSelect
                                                showSelectAll
                                                value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                                isSelectAllSelected={isSelectAllSelectedSecondary}
                                                mapBy="id"
                                                renderedName="name"
                                                onChange={onSecondaryDepartmentSelectChange}
                                                selectOptions={secondaryDepartments}
                                                isDisabled={
                                                  areFieldsDisabled
                                                  }
                                         />
                              </>
                                )}
                          />
                        </FormControl>
                      </Grid>}
                      <Grid item className="grid-row">
                        <p
                          className={`${areFieldsDisabled ? "text-disabled" : ""
                            }`}
                        >
                          {t("common.fieldHeaders.whenToPublishArticle")}
                        </p>
                        <Grid container justifyContent="space-between" ref={publishDateRef}>
                          <Grid item xs={7}>
                            <KeyboardDatePicker
                              // {... !areFieldsDisabled ? { disablePast: true } : {}}
                              minDateMessage={t('errorMessages.minimumDate')}
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="publishDate-picker-inline"
                              className="date-picker-inline"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              onChange={(value: any) => {
                                changeDateOnly(
                                  value,
                                  publishHourAndMinutes,
                                  setPublishDateAndTime,
                                  setPublishInvalidDate
                                );
                              }}
                              value={convertToDate(publishDateAndTime)}
                              autoOk={true}
                              disabled={areFieldsDisabled}
                            />
                          </Grid>
                          <Grid item xs={6} lg={4}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              okLabel={t("timePicker.confirm")}
                              cancelLabel={t("timePicker.cancel")}
                              value={publishDateAndTime}
                              onChange={(value: any) => {
                                changeTimeOnly(
                                  value,
                                  setPublishDateAndTime,
                                  setPublishHourAndMinutes,
                                  setPublishInvalidDate
                                );
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "Hour",
                              }}
                              disabled={areFieldsDisabled}
                            />
                          </Grid>
                          {!areFieldsDisabled ? (
                            <p className="field-error">
                              {publishInvalidDate ? t("push.errors.time") : ""}
                              &nbsp;
                            </p>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid item className="grid-row">
                        <p
                          className={`${areFieldsDisabled ? "text-disabled" : ""
                            }`}
                        >
                          {t("common.fieldHeaders.whenToRemoveAd")}
                        </p>
                        <Grid container justifyContent="space-between" ref={archiveDateRef}>
                          <Grid item xs={7}>
                            <KeyboardDatePicker
                              {... !areFieldsDisabled ? { minDate: publishDateAndTime } : {}}
                              minDateMessage={t('errorMessages.minimumDate')}
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="archiveDate-picker-inline"
                              className="date-picker-inline"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              onChange={(value: any) => {
                                changeDateOnly(
                                  value,
                                  archiveHourAndMinutes,
                                  setArchiveDateAndTime,
                                  setArchiveInvalidDate,
                                  "archive"
                                );
                              }}
                              value={convertToDate(archiveDateAndTime)}
                              autoOk={true}
                              disabled={areFieldsDisabled}
                            />
                          </Grid>
                          <Grid item xs={6} lg={4}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              okLabel={t("timePicker.confirm")}
                              cancelLabel={t("timePicker.cancel")}
                              value={archiveDateAndTime}
                              onChange={(value: any) => {
                                changeTimeOnly(
                                  value,
                                  setArchiveDateAndTime,
                                  setArchiveHourAndMinutes,
                                  setArchiveInvalidDate,
                                  "archive"
                                );
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "Hour",
                              }}
                              disabled={areFieldsDisabled}
                            />
                          </Grid>
                          {!areFieldsDisabled ? (
                            <p className="field-error">
                              {archiveInvalidDate ? t("push.errors.time") : ""}
                              &nbsp;
                            </p>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6} lg={4}
                    className="preview-container flex-center flex-column"
                  >
                    <div className="fixed">
                      <div className={`preview-image`}
                        style={{
                          backgroundImage:
                            fileUpload?.url && !isNewPictureUploaded
                              ? "url(" + fileUpload?.url + ")"
                              : " ",
                        }}
                      >
                        {!areFieldsDisabled && isNewPictureUploaded ?
                          <Cropper
                            image={fileUpload?.url ? fileUpload?.url : " "}
                            crop={crop}
                            zoom={zoom}
                            zoomSpeed={0.1}
                            minZoom={0.05}
                            maxZoom={maxZoom}
                            restrictPosition={false}
                            objectFit="vertical-cover"
                            cropSize={{
                              width: getCropWidth(),
                              height: getCropHeight(),
                            }}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                          />
                          : null
                        }
                      </div>
                      <div className="preview-text">
                        <p className="preview-header">
                          {header ||
                            t(
                              "articles.allArticles.create.form.preview.headerGoes"
                            )}
                        </p>
                        <p className="preview-header sub-header">
                          {subHeader ||
                            t("articles.ads.create.form.preview.subHeaderGoes")}
                        </p>
                        <div className="preview-category">
                          <span>
                            {ctaText || t("common.fieldHeaders.ctaText")}
                          </span>
                        </div>
                      </div>
                      <div className="proportion-container">
                        <span className="text">{t('common.proportions', { x: 1, y: 1.5 })}</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
            <div className="main-wrapper">
              <h3>{t("benefits.create.form.details")}</h3>
              <Grid
                container
                className="create-grid"
                justifyContent="space-between"
                direction="column"
              >
                <p className={`${areFieldsDisabled ? "text-disabled" : ""}`}>
                  {t("benefits.create.form.addButton")}
                </p>
                <Grid item xs={6} lg={4}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} ref={linkRef}>
                      <Controller
                        control={control}
                        name="link"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              id="link"
                              label={t("benefits.create.form.link")}
                              value={value}
                              onChange={onChange}
                              disabled={areFieldsDisabled}
                            />
                            {!areFieldsDisabled && (
                              <p className="field-error">
                                {errors?.link?.message ?? ""}&nbsp;
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="submit-buttons">
              <Button
                variant="contained"
                name="PreviewAndPublish"
                color="primary"
                type="submit"
                onClick={() => setTimeout(() => scrollToError())}
                disabled={
                  !areFieldsDisabled &&
                  !((groups.length || departments.length) && roleType)
                }
              >
                {areFieldsDisabled
                  ? t("common.buttons.preview")
                  : t("common.buttons.save")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <PreviewModal
        className="create-article ad"
        openModal={openModal}
        handleClose={handleCloseModal}
        hideNotYet={true}
        content={
          <>
            <div className="preview-image-container">
              <div className={`preview-image`}>
                <img
                  src={fileUpload.url}
                  alt={fileUpload?.name ?? ""}
                />
              </div>
              <div className="preview-text">
                <p className="preview-header">
                  {header ||
                    t("articles.allArticles.create.form.preview.headerGoes")}
                </p>
                <p className="preview-header sub-header">
                  {subHeader ||
                    t("articles.ads.create.form.preview.subHeaderGoes")}
                </p>
                <div className="preview-category">
                  <span>{ctaText || t("common.fieldHeaders.ctaText")}</span>
                </div>
              </div>
            </div>
          </>
        }
      ></PreviewModal>
      <NotificationModal
        openModal={openNotificationModal}
        buttonText={t("common.buttons.close")}
        handleClose={handleNotificationClose}
        handleButtonClick={handleNotificationClose}
        message={notificationMessage}
      />
      {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
    </div>
  );
};

export default ViewAd;
