import ReportDashboard from "./Dashboard/ReportDashboard";
import Purchases from "./Purchases/Purchases";

export const routes = [
    {
        path: 'dashboard',
        component: ReportDashboard
    },
    {
        path: 'purchases',
        component: Purchases
    },
]
