import React, { FC, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UserTypeEnum from "../../models/UserType";
import { useStore } from "../../store/StoreContext";
import { matchSorter } from "match-sorter";

interface ISoretedAutocomplete {
  options: any[];
  value: any;
  label: string;
  labelName: string;
  valueName: string;
  disableClearable?: boolean;
  onChange: (value: any) => void;
}

const SortedAutocomplete: FC<ISoretedAutocomplete> = ({
  options,
  value,
  label,
  labelName,
  valueName,
  disableClearable,
  onChange,
}) => {
  const { t } = useTranslation();
  const { userStore, languageStore } = useStore();
  const [sortedOptions, setSortedOptions] = useState<any[]>([]);

  const filterOptions = (options, { inputValue }) => {
    const match: any[] = matchSorter(options, inputValue, {
      keys: [
        "label",
        { maxRanking: matchSorter.rankings.WORD_STARTS_WITH, key: "label" },
      ],
    });
    const role = userStore.getRoleType();

    if (!inputValue && (role === UserTypeEnum.owner || role === UserTypeEnum.associatedOwner)) {
        const allCommunities = {
            firstLetter: "A",
            label: t("common.selectFilters.allCommunities"),
            value: "all"
        };
        match.sort(function(x,y){ return x.value == allCommunities.value ? -1 : y.value == allCommunities.value ? 1 : 0; });
    }
    return match;
  };

  useEffect(() => {
    const newOptions: any[] = options.map((element) => {
      const firstLetter = element["communityName"][0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        label: element[labelName],
        value: element[valueName],
      };
    });
    const role = userStore.getRoleType();
    if (role === UserTypeEnum.owner || role === UserTypeEnum.associatedOwner) {
      newOptions.unshift({
        value: "all",
        label: t("common.selectFilters.allCommunities"),
        firstLetter: "A",
      });
    }
    setSortedOptions(newOptions);
  }, [languageStore.switchLng]);

  return (
    sortedOptions.length > 0 && (
      <Autocomplete
        ListboxProps={{ style: { maxHeight: "14rem" } }}
        style={{minWidth: '150px'}}
        disableClearable={disableClearable}
        options={sortedOptions}
        id="select-filter"
        value={sortedOptions.find((opt) => opt.value === value)?.label}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(event, e: any) => onChange(e?.value)}
        noOptionsText={t("multiSelectLabels.noOptions")}
        filterOptions={filterOptions}
      />
    )
  );
};

export default SortedAutocomplete;
