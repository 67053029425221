import React from "react"
import ContentLoader from "react-content-loader"

const DraftsLoader = () => (
  <ContentLoader 
    speed={2}
    width={500}
    height={160}
    viewBox="0 0 500 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    title={''}
  >
    <rect x="78" y="42" rx="3" ry="3" width="172" height="6" /> 
    <rect x="74" y="22" rx="3" ry="3" width="270" height="6" /> 
    <rect x="0" y="0" rx="9" ry="9" width="60" height="60" /> 
    <rect x="455" y="17" rx="5" ry="5" width="37" height="31" />
  </ContentLoader>
)

export default DraftsLoader

