import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import fullImage from "../../../../assets/images/articles/full-image.png";
import imageShortDescription from "../../../../assets/images/articles/image-short-description.png";
import noImage from "../../../../assets/images/articles/no-image.png";
import onlyImageNoText from "../../../../assets/images/articles/only-image-no-text.png";
import ImagePlaceholder from "../../../../assets/images/image-placeholder.png";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import "../shared_styles.scss";
import "./style.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Editor } from "@tinymce/tinymce-react";
import PreviewModal from "../../../../components/PreviewModal/PreviewModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./articleFields";
import { flowResult, toJS } from "mobx";
import { useStore } from "../../../../store/StoreContext";
import { useHistory } from "react-router";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import UserTypeEnum from "../../../../models/UserType";
import Spinner from "../../../../components/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../shared/utils/cropImage";
import { convertLocalToZuluTime, convertToDate } from "../../../../shared/utils/sharedFunctions";
import { tinyEditorOptions } from "../../../../shared/utils/sharedOptions";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
import {compressAccurately} from 'image-conversion';

const NewArticle = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {
    createStore,
    contentStore,
    groupStore,
    departmentStore,
    userStore,
    languageStore,
    actionStore,
    archiveStore,
  } = useStore();
  const [fileUpload, setFileUpload] = useState({
    lastModified: 0,
    lastModifiedDate: new Date(),
    name: "",
    size: 0,
    type: "",
    webkitRelativePath: "",
    url: ImagePlaceholder,
  });
  const [imageOriginal, setImageOriginal] = useState<any>(null);
  const [showImage, setShowImage] = useState(false);
  const [template, setTemplate] = useState(1);
  const [publish, setPublish] = useState("now");
  const [archive, setArchive] = useState("globalDate");
  const [details, setDetails] = useState("write");
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [header, setHeader] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [textEditor, setTextEditor] = useState("");
  const [archiveDays, setArchiveDays] = useState(7);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(true);
  const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

  const resolutionIsOrBelow1440 = window.innerWidth <= 1440;
  const resolutionAbove1024 = window.innerWidth > 1024;
  const resolutionIs1024 = window.innerWidth === 1024;
  const getCropWidth = () => {
    if (!resolutionIsOrBelow1440) return 352
    else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 281.6
    else if (resolutionIs1024) return 220;
    return 176;
  }
  const getCropHeight = () => {
    if (template !== 2) {
      if (!resolutionIsOrBelow1440) return 526;
      else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 420.8;
      else if (resolutionIs1024) return 329;
      return 263;
    } else {
      if (!resolutionIsOrBelow1440) return 230;
      else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 184;
      else if (resolutionIs1024) return 144;
      return 115;
    }
  }

  // GET DATA
  const [categories, setCategories] = useState([
    {
      id: "",
      contentName: "",
    },
  ]);
  const [groups, setGroups] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
  const [communities,setCommunities] = useState<any>([]);

  const [roleType, setRoleType] = useState(null);

  useEffect(() => {
    const storedContent = contentStore.getStoredContents();
    const storedGroups = groupStore.getStoredGroups();
    const storedDepartments = departmentStore.getStoredDepartments();
    const storedSecondaryDepartments = departmentStore.getStoredSecondaryDepartments();
    const storedCommunities = userStore.getCommunities();
    const roleType = userStore.getRoleType();
    const archiveDays = archiveStore.getArticleArchiveDays();
    if (roleType === UserTypeEnum.owner) {
      setArchive("specificDate");
    } else {
      setArchiveDays(archiveDays);
      setArchiveDateAndTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
      setValue("archive_date", convertToDate(
        new Date(new Date().setDate(new Date().getDate() + archiveDays))
      ))
    }
    setCategories(storedContent);
    setGroups(storedGroups);
    setDepartments(storedDepartments);
    setCommunities(storedCommunities)
    setSecondaryDepartments(storedSecondaryDepartments);
    setRoleType(roleType);
    const appears = roleType === UserTypeEnum.owner ? storedGroups.map(group => group.id) : storedDepartments.map(department => department.id).concat(storedSecondaryDepartments.map(department => department.id))
    setValue("appears", [...appears, 'select-all']);
    setValue("writer", userStore.getUserFullName());
    setIsLoading(false);
  }, [
    contentStore.contents,
    groupStore.groups,
    departmentStore.departments,
    userStore.role,
  ]);

  const[ selectedCommunity, setSelectedCommunity]= useState<string>('')

  const handleChangeCommunity = (event) => {
    setSelectedCommunity(event.target.value);
  };

  // Preview Modal
  const [openModal, setOpenModal] = useState(false);
  // Notification Modal
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [isRedirect, setIsRedirect] = useState(true);
  const [videoData, setVideoData] = useState({});
  const [videoURL, setVideoURL] = useState("");

  const capture = (file) => {
    if (!videoData) {
      return;
    }
    let fileReader: any = new FileReader();
    fileReader.onload = () => {
      let blob = new Blob([fileReader.result], { type: file.type });
      let url = URL.createObjectURL(blob);
      let video = document.createElement("video");
      const timeupdate = () => {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
          video.pause();
        }
      };
      video.addEventListener("loadeddata", function () {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
        }
      });
      const snapImage = async () => {
        let canvas: any = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        let image = canvas.toDataURL();
        let imageBlob = await new Promise((resolve, reject) => {
          canvas.toBlob((file) => {
            resolve(file)
          }, 'image/jpeg', 0.8)
        });
        // let success = image.length > 100000;
        // if (success) {
        setShowImage(true);
        setFileUpload({ ...fileUpload, url: image });
        setValue("image", image);
        setImageOriginal(imageBlob);
        URL.revokeObjectURL(url);
        // }
        return true;
      };
      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
    };
    fileReader.readAsArrayBuffer(file);
  };

  const handleFileUpload = async (data: File) => {
    if (data) {
      setInnerLoading(true);
      let image: any = "", compressed: Blob;
      if (data.type.indexOf("image") !== -1) {
        // Maximum for firbase is 300kb
        compressed = await compressAccurately(data, {size: 280, accuracy: 0.99});
      }
      const formData = new FormData();
      formData.append("file", compressed || data);
      image = await flowResult(actionStore.uploadFile(formData)).then(
        (response: any) => {
          setIsLoading(false);
          return response;
        }
      );
      if (data.type.indexOf("image") !== -1) {
        // Save original image
        setImageOriginal(image);
        setVideoData({});
        setVideoURL("");
      }
      if (data.type.indexOf("video") !== -1) {
        if (image) {
          setVideoData(data);
          setVideoURL(image);
        }
      }
      setShowImage(true);
      setFileUpload({ ...data, url: image } as any);
      setCroppedImage(image);
      setValue("image", image);
      //Initiate the FileReader object.
      const reader = new FileReader();
      //Read the contents of Image File.
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        let image: any = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          const height: number = Number(this.height);
          const width: number = Number(this.width);
          if (template !== 2) {
            if (height > width * 1.49) {
              const zoom = (height / width * 0.67);
              setZoom(zoom);
              setMaxZoom(zoom * 4);
            } else {
              setZoom(1);
              setMaxZoom(4);
            }
          } else {
            if (width > height) {
              let zoom = (width / height * 0.654);
              zoom = zoom >= 1 ? zoom : 1;
              setZoom(zoom);
              setMaxZoom(zoom * 4);
            } else {
              setZoom(1);
              setMaxZoom(4);
            }
          }
          setTimeout(() => {
            const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
            if (cropperImage.className.indexOf('reactEasyCrop_Cover_Vertical') > -1) {
              cropperImage.style.height = '100.1%';
            } else if (cropperImage.className.indexOf('reactEasyCrop_Cover_Horizontal') > -1) {
              cropperImage.style.width = '100.1%';
            }
          })
        };
      };
      reader.readAsDataURL(data);
      setIsNewPictureUploaded(true);
      setInnerLoading(false);
    }
  };

  useEffect(() => {
    if (videoURL) capture(videoData);
  }, [videoURL]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      template: 1,
      header: "",
      category_id: "",
      image: "",
      appears: [],
      is_push_notification: false,
      push_notification_text: "",
      publish_date: convertToDate(new Date()),
      archive_date: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 7))
      ),
      details: "write",
      external_link: "",
      link_description: "",
      details_header: "",
      writer: "",
      text: "",
      is_draft: false,
    },
  } as any);

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const [potentialData, setPotentialData] = useState({});

  const createArticle = async (data) => {
    setIsLoading(true);
    setSubmitError("");
    if (isNewPictureUploaded) {
      if (data.template !== 3) {
        let image = data.image;
        if (videoURL) {
          image = data.thumbnail
        }
        const formData = new FormData();
        formData.append("file", image);
        const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
          (response: any) => {
            return response;
          }
        );
        if (videoURL) {
          const formDataImageOriginal = new FormData();
          formDataImageOriginal.append("file", imageOriginal);
          const imageOriginalURL = await flowResult(actionStore.uploadFile(formDataImageOriginal)).then(
            (response: any) => {
              return response;
            }
          );
          data = { ...data, thumbnail: croppedImage, image_original: imageOriginalURL }
        } else {
          data = { ...data, image: croppedImage }
        }
      }
    }
    await flowResult(createStore.createArticle(data)).then((response: any) => {
      setIsLoading(false);
      if (!response?.success) {
        handleNotificationOpen(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0"),
          false
        );
        return;
      }
      handleNotificationOpen(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"),
        true
      );
    });
    setOpenNotificationModal(true);
  };

  const transformToData = async (objectData, isDraft = false) => {
    let croppedImage;
    if (objectData.template !== 3 && isNewPictureUploaded) {
      croppedImage = await cropImage(croppedAreaPixels);
    } else {
      croppedImage = "";
    }
    const data = {
      template: objectData.template,
      header: objectData.header,
      category_id: objectData.category_id,
      image:
        objectData.template !== 3 ? (videoURL ? videoURL : croppedImage) : "",
      image_original: imageOriginal ?? "",
      appears: objectData.appears.filter((el) => el !== 'select-all'),
      is_push_notification: objectData.is_push_notification,
      push_notification_text: objectData.push_notification_text,
      publish_date: publish === "now" ? convertLocalToZuluTime(new Date()) : convertLocalToZuluTime(publishDateAndTime),
      archive_date:
        archive === "globalDate"
          ? convertLocalToZuluTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
          : convertLocalToZuluTime(archiveDateAndTime),
      details: {
        external_link: objectData.external_link,
        link_description: objectData.link_description,
        header: objectData.header,
        writer: objectData.writer,
        text: textEditor,
      },
      is_draft: isDraft,
    };
    if (videoURL && objectData.template !== 3) {
      data["thumbnail"] = croppedImage;
    }
    return data;
  };

  const handleSubmitDraft = async (objectData) => {
    setIsLoading(true);
    let data = await transformToData(objectData, true);
    const dataKeys = Object.keys(data);
    dataKeys.forEach((el) => {
      if (!data[el]) delete data[el];
    });
    // @ts-ignore
    if (
      !(
        data["details"]["header"] ||
        data["details"]["writer"] ||
        data["details"]["text"] ||
        data["details"]["external_link"] ||
        data["details"]["link_description"]
      )
    )
      // @ts-ignore
      delete data["details"];

    createArticle(data);
  };

  const handleCloseModal = (isSave) => () => {
    setOpenModal(false);
    if (isSave) {
      let data = potentialData;
      setSubmitError("");
      createArticle(data);
    }
  };

  const handleNotificationOpen = (message = "", isRedirect) => {
    setNotificationMessage(message);
    setIsRedirect(isRedirect);
    setOpenNotificationModal(true);
  };

  const handleNotificationClose = () => {
    setOpenNotificationModal(false);
    if (isRedirect) history.push("/articles/list");
  };

  const handleSubmitCheck = async (objectData) => {
    if (objectData.is_draft) {
      handleSubmitDraft(objectData);
      return;
    }
    if (publishInvalidDate || archiveInvalidDate) {
      return;
    }
    let data = await transformToData(objectData);
    if (!objectData.push_notification_text)
      delete data["push_notification_text"];
    if (details === "write") {
      delete data["external_link"];
      delete data["link_description"];
    } else {
      delete data["details_header"];
      delete data["writer"];
      delete data["text"];
    }

    setPotentialData(data);
    setOpenModal(true);
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  const textInputDirection = () => {
    if (lngDirection === "rtl") return "rtl";
    if (lngDirection === "ltr") return "ltr";
  };

  const transformEditorText = (text) => {
    if (text && text.length > 0) {
      return text
        .replace(/(<([^>]+)>)/gi, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/\&nbsp;/g, "")
        .trim();
    }
    return text;
  };

  // Ref
  const headerRef: any = useRef(null);
  const categoryRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const appearsRef: any = useRef(null);
  const pushTextRef: any = useRef(null);
  const externalRef: any = useRef(null);
  const detailsHeaderRef: any = useRef(null);
  const writerRef: any = useRef(null);
  const textRef: any = useRef(null);
  const publishDateRef: any = useRef(null);
  const archiveDateRef: any = useRef(null);

  const scrollToError = () => {
    if (errors?.header?.message) {
      headerRef.current.scrollIntoView();
    } else if (errors?.category_id?.message) {
      categoryRef.current.scrollIntoView();
    } else if (errors?.image?.message && !isNewPictureUploaded) {
      imageRef.current.scrollIntoView();
      // @ts-ignore
    } else if (errors?.appears?.message) {
      appearsRef.current.scrollIntoView();
    } else if (errors?.push_notification_text?.message) {
      pushTextRef.current.scrollIntoView();
    } else if (errors?.external_link?.message) {
      externalRef.current.scrollIntoView();
    } else if (errors?.link_description?.message) {
      externalRef.current.scrollIntoView();
    } else if (errors?.writer?.message) {
      writerRef.current.scrollIntoView();
    } else if (errors?.text?.message) {
      textRef.current.scrollIntoView();
    } else if (publishInvalidDate) {
      publishDateRef.current.scrollIntoView();
    } else if (archiveInvalidDate) {
      archiveDateRef.current.scrollIntoView();
    }
    const element = document.querySelector('.main-content-container');
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
  const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const [archiveDateAndTime, setArchiveDateAndTime] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
    hour: 0,
    minutes: 0,
  });

  const changeDateOnly = (
    time,
    hourAndMinutes,
    setDateAndTime,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      const hours = hourAndMinutes.hour;
      const minutes = hourAndMinutes.minutes;
      if (!isNaN(hours) && !isNaN(minutes))
        setDateAndTime((date: any) => {
          if (date) {
            date = new Date(time);
            date.setHours(hours, minutes);
            const newDate = new Date(date);
            return newDate;
          }
          return new Date();
        });
    }
  };

  const changeTimeOnly = (
    time,
    setDateAndTime,
    setHourAndMinutes,
    setInvalidDate,
    type = ""
  ) => {
    if (time instanceof Date) {
      if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
        setDateAndTime((date: any) => {
          if (date) {
            date.setHours(time.getHours(), time.getMinutes());
            const newDate = new Date(date);
            setHourAndMinutes({
              hour: time.getHours(),
              minutes: time.getMinutes(),
            });
            return newDate;
          }
          return new Date();
        });
    }
  };

  const [publishInvalidDate, setPublishInvalidDate] = useState(false);
  const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);

  const transformText = (children, len = 0, maxLen, maxRows) => {
    let charCount = len;
    children = [...children];
    children.forEach((child, index) => {
      if (charCount >= maxLen || index > maxRows) {
        child.remove();
        return;
      }
      if ((charCount + child.innerText.length) >= maxLen) {
        child.innerText = child.innerText.substring(0, maxLen - charCount) + "...";
      }
      charCount += child.innerText.length;
      child.style.fontSize = "1.1em";
      if (child.style.backgroundColor) {
        child.style.backgroundColor = "unset";
      }
      let innerChildren = child.children;
      if (innerChildren.length) {
        transformText(innerChildren, charCount, maxLen, maxRows);
      }
    });
  }

  const transformTextPhone = (children, len = 0, maxLen, maxRows) => {
    let charCount = len;
    children = [...children];
    children.forEach((child, index) => {
      if (charCount >= maxLen || index > maxRows) {
        child.remove();
        return;
      }
      if ((charCount + child.innerText.length) >= maxLen) {
        child.innerText = child.innerText.substring(0, maxLen - charCount) + "...";
      }
      charCount += child.innerText.length;
      child.style.fontSize = "1em";
      if (child.style.lineHeight) {
        const height = Number(child.style.lineHeight.replace("px", "")) / 2;
        child.style.lineHeight = height >= 10 ? height.toFixed(0) + "px" : "10px";
      }
      if (child.style.margin) {
        child.style.margin = "8px 0";
      }
      if (child.style.padding) {
        child.style.padding = child.style.padding
          .split(" ")
          .map((el) => Number(el.replace("px", "") / 2).toFixed(0) + "px")
          .join(" ");
      }
      if (child.style.paddingLeft) {
        child.style.paddingLeft =
          (Number(child.style.paddingLeft.replace("px", "")) / 2).toFixed(0) +
          "px";
      }
      if (child.style.paddingRight) {
        child.style.paddingRight =
          (Number(child.style.paddingRight.replace("px", "")) / 2).toFixed(0) +
          "px";
      }
      if (child.style.backgroundColor) {
        child.style.backgroundColor = "unset";
      }
      if (child?.firstChild?.nodeName === "IFRAME") {
        child.firstChild.style.maxWidth = "99%";
        child.firstChild.style.maxHeight = "110px";
      }
      if (child?.firstChild?.nodeName === "IMG") {
        child.firstChild.style.maxWidth = "99%";
        child.firstChild.style.height = "auto";
      }
      let innerChildren = child.children;
      if (innerChildren.length) {
        transformTextPhone(innerChildren, charCount, maxLen, maxRows);
      }
    });
  }

  const displayHTML = (value, maxChar = 160, maxRows = 3) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    let children: any = div.children;
    transformText(children, 0, maxChar, maxRows);
    const html = { __html: div.innerHTML };
    return <div className="dangerous-html" dangerouslySetInnerHTML={html} />;
  };

  const displayHTMLPhone = (value, maxChar = 160, maxRows = 3) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    let children: any = div.children;
    transformTextPhone(children, 0, maxChar, maxRows);
    const html = { __html: div.innerHTML };
    return <div className="dangerous-html" dangerouslySetInnerHTML={html} />;
  };

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(4);
  const [croppedImage, setCroppedImage] = useState<any>("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels
    );
    const croppedImageBlob = await getCroppedImg(
      fileUpload?.url,
      croppedAreaPixels,
      true
    );
    setCroppedImage(croppedImage);
    return croppedImageBlob;
  };

  const onDepartmentSelectChange = (data, isSelectAll = false) => {
    const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...secondaries]);
    setIsSelectAllSelected(isSelectAll)
  }
  const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
    const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
    setValue('appears', [...data, ...deps]);
    setIsSelectAllSelectedSecondary(isSelectAll);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="create-article article">
        <div className="main-wrapper">
          <div className="create-options">
            <h2 className="page-heading">
              {t("articles.allArticles.create.title")}
            </h2>
            <br />
            <h3>{t("articles.allArticles.create.template.title")}</h3>
            <br /> <br /> <br /> <br />
            <div className="new-article">
              <Controller
                control={control}
                name="template"
                render={({ field: { onChange } }) => (
                  <>
                    <div
                      className={`article-type ${template === 1 ? "active" : ""}`}
                      onClick={() => {
                        setTemplate(1);
                        onChange(1);
                      }}
                    >
                      <img src={fullImage} alt="fullImage" />
                      <p>{t("articles.allArticles.create.template.fullImage")}</p>
                    </div>
                    <div
                      className={`article-type ${template === 2 ? "active" : ""}`}
                      onClick={() => {
                        setTemplate(2);
                        onChange(2);
                      }}
                    >
                      <img
                        src={imageShortDescription}
                        alt="imageShortDescription"
                      />
                      <p>
                        {t(
                          "articles.allArticles.create.template.imageShortDescription"
                        )}
                      </p>
                    </div>
                    <div
                      className={`article-type ${template === 3 ? "active" : ""}`}
                      onClick={() => {
                        setTemplate(3);
                        onChange(3);
                      }}
                    >
                      <img src={noImage} alt="noImage" />
                      <p>{t("articles.allArticles.create.template.noImage")}</p>
                    </div>
                    <div
                      className={`article-type ${template === 4 ? "active" : ""}`}
                      onClick={() => {
                        setTemplate(4);
                        onChange(4);
                      }}
                    >
                      <img src={onlyImageNoText} alt="onlyImageNoText" />
                      <p>
                        {t(
                          "articles.allArticles.create.template.onlyImageNoText"
                        )}
                      </p>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="create-preview">
            <form
              className=""
              autoComplete="off"
              onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
            >
              <div className="main-wrapper">
                <h3>{t("articles.allArticles.create.form.title")}</h3>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={lngDirection === "rtl" ? heLocale : enLocale}
                >
                  <Grid container className="create-grid" justifyContent="space-between">
                    <Grid item xs={6} lg={4}>
                      <Grid container direction="column">
                        <Grid item xs={12} ref={headerRef}>
                          <Controller
                            control={control}
                            name="header"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="header"
                                  label={`${getValues("template") === 4
                                    ? t(
                                      "articles.allArticles.create.form.articleName"
                                    )
                                    : t("common.fieldHeaders.header")
                                    }*`}
                                  value={value}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    onChange(e);
                                    setHeader(value);
                                    setValue('header', value);
                                  }}
                                />
                                <p className="field-error">
                                  {errors?.header?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} ref={categoryRef}>
                          <FormControl>
                            <InputLabel id="category-label" required>
                              {t("common.fieldHeaders.category")}
                            </InputLabel>
                            <Controller
                              control={control}
                              name="category_id"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Select
                                    labelId="category-label"
                                    id="category"
                                    displayEmpty
                                    {...register("category_id")}
                                    defaultValue=""
                                    onChange={(e) => {
                                      const textValue =
                                        // @ts-ignore
                                        e.nativeEvent?.target?.innerText ?? "";
                                      setSelectedCategory(textValue);
                                      onChange(e);
                                    }}
                                    disabled={!categories.length}
                                  >
                                    {categories.map((cat, index) => (
                                      <MenuItem key={index} value={cat.id}>
                                        {cat.contentName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {categories.length ? (
                                    <p className="field-error">
                                      {errors?.category_id?.message ?? ""}&nbsp;
                                    </p>
                                  ) : (
                                    <p className="field-error">
                                      {t(
                                        "errorMessages.articles.create.noCategories"
                                      )}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        {template !== 3 && (
                          <Grid item xs={12} ref={imageRef}>
                            <UploadFile
                              text={t("common.fieldHeaders.uploadImageOrVideo")}
                              handleFileUpload={handleFileUpload}
                              showImage={showImage}
                              image={fileUpload.url}
                              height={template === 2 ? 115 : 139}
                              width={template === 2 ? 175 : 100}
                              enableVideo
                            />
                            <span className="information-message">{t("informationMessages.formatsAll")}</span>
                            <p className="field-error">
                              {!showImage ? errors?.image?.message ?? "" : ""}
                              &nbsp;
                            </p>
                          </Grid>
                        )}

                        {roleType === UserTypeEnum.admin && <FormControl style={{paddingBottom:50}}>
                        <InputLabel id="demo-simple-select-label">Select Community</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCommunity}
                        onChange={handleChangeCommunity}
                        >
                        {communities.map(community=>
                        <MenuItem key={community.id} value={community.id}>{community.communityName}</MenuItem>
                        )}
                        </Select>
                        </FormControl>}

                        <Grid item xs={12} ref={appearsRef}>
                          <FormControl>
                            <Controller
                              control={control}
                              name="appears"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <label className="field-label">
                                    {t("common.fieldHeaders.appears")}
                                  </label>
                                  {roleType !== UserTypeEnum.owner && <><br />
                                  <label className="field-label">{t('common.fieldHeaders.department')}</label></>}
                                  <MultiSelect
                                    showSelectAll
                                    value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                    hasError={!!errors?.appears?.message}
                                    isSelectAllSelected={isSelectAllSelected}
                                    mapBy="id"
                                    renderedName="name"
                                    onChange={onDepartmentSelectChange}
                                    isDisabled={roleType === UserTypeEnum.admin && selectedCommunity===''}
                                    selectOptions={
                                       roleType === UserTypeEnum.owner ?
                                       groups : roleType === UserTypeEnum.admin ? 
                                       departments.filter(department=>department.communityId===selectedCommunity):
                                       departments}
                                  />
                                  {(roleType === UserTypeEnum.owner &&
                                    groups.length) ||
                                    (roleType !== UserTypeEnum.owner &&
                                      departments.length) ? (
                                    <>
                                      <p className="field-error">
                                        {/* @ts-ignore */}
                                        {errors?.appears?.message ??
                                          ""}&nbsp;{" "}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="field-error">
                                      {roleType === UserTypeEnum.owner
                                        ? t(
                                          "errorMessages.articles.create.noGroups"
                                        )
                                        : t(
                                          "errorMessages.articles.create.noDepartments"
                                        )}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item xs={12}>
                          <FormControl>
                            <Controller
                              control={control}
                              name="appears"
                              render={({ field: { onChange, value } }) => (
                                <>
                                <label className="field-label">
                                    {t("common.fieldHeaders.secondaryDepartment")}
                                  </label>
                                  <MultiSelect
                                    showSelectAll
                                    value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                    isSelectAllSelected={isSelectAllSelectedSecondary}
                                    mapBy="id"
                                    renderedName="name"
                                    onChange={onSecondaryDepartmentSelectChange}
                                    isDisabled={roleType === UserTypeEnum.admin && selectedCommunity===''}
                                    selectOptions={
                                       roleType === UserTypeEnum.owner ?
                                       groups : roleType === UserTypeEnum.admin ?
                                       secondaryDepartments.filter(department=>department.communityId===selectedCommunity) : secondaryDepartments}
                                       />
                                </>
                                  )}
                                  />
                            </FormControl>
                        </Grid>}
                        <Grid item className="grid-row" ref={pushTextRef}>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                name="is_push_notification"
                                render={({ field: { onChange, value } }) => (
                                  <Checkbox
                                    color="primary"
                                    checked={value || false}
                                    onChange={onChange}
                                    onClick={() => {
                                      setIsPushNotification(!value);
                                      if (value) {
                                        setValue("push_notification_text", "");
                                      }
                                    }}
                                  />
                                )}
                              />
                            }
                            label={t("common.fieldHeaders.sendPushNotification")}
                          />
                          <Controller
                            control={control}
                            name="push_notification_text"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="pushNotificationText"
                                  type="text"
                                  label={t(
                                    "common.fieldHeaders.pushNotificationText"
                                  )}
                                  disabled={!isPushNotification}
                                  value={value}
                                  onChange={onChange}
                                />
                                <p className="field-error">
                                  {errors?.push_notification_text?.message ?? ""}
                                  &nbsp;
                                </p>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item className="grid-row">
                          <p>{t("common.fieldHeaders.whenToPublishArticle")}</p>
                          <RadioGroup name="publish" value={publish}>
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => {
                                    setPublish(e.target.value);
                                    setValue(
                                      "publish_date",
                                      convertToDate(new Date())
                                    );
                                  }}
                                />
                              }
                              value="now"
                              label={t("common.fieldHeaders.now")}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => setPublish(e.target.value)}
                                />
                              }
                              value="later"
                              label={t("common.fieldHeaders.later")}
                            />
                          </RadioGroup>
                          {publish === "later" && (
                            <Grid container justifyContent="space-between" ref={publishDateRef}>
                              <Grid item xs={7}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="publishDate-picker-inline"
                                  className="date-picker-inline"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  onChange={(value: any) => {
                                    changeDateOnly(
                                      value,
                                      publishHourAndMinutes,
                                      setPublishDateAndTime,
                                      setPublishInvalidDate
                                    );
                                  }}
                                  value={convertToDate(publishDateAndTime)}
                                  autoOk={true}
                                />
                              </Grid>
                              <Grid item xs={6} lg={4}>
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="time-picker"
                                  okLabel={t("timePicker.confirm")}
                                  cancelLabel={t("timePicker.cancel")}
                                  value={publishDateAndTime}
                                  onChange={(value: any) => {
                                    changeTimeOnly(
                                      value,
                                      setPublishDateAndTime,
                                      setPublishHourAndMinutes,
                                      setPublishInvalidDate
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "Hour",
                                  }}
                                />
                              </Grid>
                              <p className="field-error">
                                {publishInvalidDate ? t("push.errors.time") : ""}
                                &nbsp;
                              </p>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item className="grid-row">
                          <p>{t("common.fieldHeaders.whenToArchiveArticle")}</p>
                          <RadioGroup name="archive" value={archive}>
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => setArchive(e.target.value)}
                                />
                              }
                              value="globalDate"
                              label={t("common.fieldHeaders.globalDate")}
                              disabled={roleType === UserTypeEnum.owner}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  onChange={(e) => setArchive(e.target.value)}
                                />
                              }
                              value="specificDate"
                              label={t("common.fieldHeaders.specificDate")}
                            />
                          </RadioGroup>
                          {archive === "specificDate" && (
                            <Grid container justifyContent="space-between" ref={archiveDateRef}>
                              <Grid item xs={7}>
                                <KeyboardDatePicker
                                  minDateMessage={t('errorMessages.minimumDate')}
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="archiveDate-picker-inline"
                                  className="date-picker-inline"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  onChange={(value: any) => {
                                    changeDateOnly(
                                      value,
                                      archiveHourAndMinutes,
                                      setArchiveDateAndTime,
                                      setArchiveInvalidDate,
                                      "archive"
                                    );
                                  }}
                                  value={convertToDate(archiveDateAndTime)}
                                  autoOk={true}
                                />
                              </Grid>
                              <Grid item xs={6} lg={4}>
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="time-picker"
                                  okLabel={t("timePicker.confirm")}
                                  cancelLabel={t("timePicker.cancel")}
                                  value={archiveDateAndTime}
                                  onChange={(value: any) => {
                                    changeTimeOnly(
                                      value,
                                      setArchiveDateAndTime,
                                      setArchiveHourAndMinutes,
                                      setArchiveInvalidDate,
                                      "archive"
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "Hour",
                                  }}
                                />
                              </Grid>
                              <p className="field-error">
                                {archiveInvalidDate ? t("push.errors.time") : ""}
                                &nbsp;
                              </p>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6} lg={4}
                      className="preview-container flex-center flex-column"
                    >
                      <div className={"preview-image-wrap"}>
                        <div
                          className={`preview-image template-${template}`}
                          style={{
                            backgroundImage:
                              fileUpload?.url && template !== 3 && !isNewPictureUploaded
                                ? "url(" + fileUpload?.url + ")"
                                : " ",
                          }}
                        >
                          <div className="crop-container">
                            {template !== 3 && isNewPictureUploaded ? (
                              <Cropper
                                image={
                                  fileUpload?.url && template !== 3
                                    ? fileUpload?.url
                                    : " "
                                }
                                crop={crop}
                                zoom={zoom}
                                zoomSpeed={0.1}
                                minZoom={0.05}
                                maxZoom={maxZoom}
                                restrictPosition={false}
                                objectFit={
                                  template !== 2
                                    ? "vertical-cover"
                                    : "horizontal-cover"
                                }
                                cropSize={{
                                  width: getCropWidth(),
                                  height: getCropHeight(),
                                }}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                              />
                            ) : null}
                            {(template === 1) && (
                              <div className="template-1-container">
                                <div className="preview-category">
                                  <span>{selectedCategory || "Category"}</span>
                                </div>
                                <p className="preview-header">
                                  {header ||
                                    t(
                                      "articles.allArticles.create.form.preview.headerGoes"
                                    )}
                                </p>
                                {
                                  shortDescription && (
                                    <p>
                                      {shortDescription}
                                    </p>
                                  )
                                }
                                <p className="preview-date">
                                  {convertToDate(
                                    getValues("publish_date"),
                                    "preview"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {(template === 2) && (
                          <>
                            <div className="template-2-container">
                              <div className="preview-short-text">
                                <div className="break-text">
                                  <div className="preview-text-header">
                                    <span>
                                      {header ||
                                        t(
                                          "articles.allArticles.create.form.preview.detailsHeader"
                                        )}
                                    </span>
                                  </div>
                                  <div className="preview-html">
                                    {
                                      details === "externalLink" ? (
                                        <p>
                                          {shortDescription ? shortDescription : t("common.fieldHeaders.shortDescription")}
                                        </p>
                                      ) : (textEditor
                                        ? displayHTML(textEditor)
                                        : t(
                                          "articles.allArticles.create.form.preview.detailsText"
                                        ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`preview-dates`}>
                              <span>{convertToDate(getValues("publish_date"), "preview")}</span>
                            </div>
                          </>
                        )}
                        {
                          template === 3 && (
                            <>
                              <div className="template-3-container">
                                <div className="preview-category">
                                  <span>{selectedCategory || "Category"}</span>
                                </div>
                                <p className="preview-date">
                                  {convertToDate(
                                    getValues("publish_date"),
                                    "preview"
                                  )}
                                </p>
                                <div className="preview-short-text">
                                  <div className="break-text">
                                    <div className="preview-text-header">
                                      <span>
                                        {header ||
                                          t(
                                            "articles.allArticles.create.form.preview.detailsHeader"
                                          )}
                                      </span>
                                    </div>
                                    <div className="preview-html">
                                      {
                                        details === "externalLink" ? (
                                          <p>
                                            {shortDescription ? shortDescription : t("common.fieldHeaders.shortDescription")}
                                          </p>
                                        ) : (textEditor
                                          ? displayHTML(textEditor, 250, 8)
                                          : t(
                                            "articles.allArticles.create.form.preview.detailsText"
                                          ))
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`preview-author`}>
                                <span>{getValues("writer")}</span>
                              </div>
                            </>
                          )
                        }
                        <div className="proportion-container">
                          <span className="text">{t('common.proportions', { x: 1, y: 2 })}</span>
                        </div>
                        <div className={`preview-icons template-${template}`}>
                          <FavoriteIcon className="icon favorite" />
                          <MoreHorizIcon className="icon dots" />
                        </div>
                        {
                          template === 2 && (
                            <div className={`preview-dates template-${template}`}>
                              <span>{convertToDate(getValues("publish_date"), "preview")}</span>
                            </div>
                          )
                        }
                      </div>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className="main-wrapper">
                <h3>{t("articles.allArticles.create.form.subTitle")}</h3>
                <Grid container className="create-grid">
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item className="grid-row" xs={6} lg={4} ref={externalRef}>
                        <RadioGroup name="details" value={details}>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                onChange={(e) => {
                                  setDetails(e.target.value);
                                  setValue("details", "write");
                                  setValue("external_link", "");
                                  setValue("link_description", "");
                                  setShortDescription("");
                                }}
                              />
                            }
                            value="write"
                            label={t("common.fieldHeaders.iWillWrite")}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                onChange={(e) => {
                                  setDetails(e.target.value);
                                  setValue("details", "externalLink");
                                  setValue("details_header", "");
                                  setValue("writer", "");
                                  setValue("text", "");
                                  setTextEditor("");
                                }}
                              />
                            }
                            value="externalLink"
                            label={t("common.fieldHeaders.externalLink")}
                          />
                        </RadioGroup>
                        {details === "externalLink" && (
                          <>
                            <Controller
                              control={control}
                              name="external_link"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="external_link"
                                    type="text"
                                    label={t("common.fieldHeaders.url")}
                                    value={value}
                                    onChange={onChange}
                                  />
                                  <p className="field-error">
                                    {details === "externalLink"
                                      ? errors?.external_link?.message ?? ""
                                      : ""}
                                    &nbsp;
                                  </p>
                                </>
                              )}
                            />
                            <Controller
                              control={control}
                              name="link_description"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <TextField
                                    id="link_description"
                                    type="text"
                                    label={t("common.fieldHeaders.shortDescription")}
                                    value={value}
                                    onChange={(e: any) => {
                                      onChange(e);
                                      setShortDescription(e.target.value)
                                    }}
                                  />
                                  <p className="field-error">
                                    {details === "externalLink"
                                      ? errors?.link_description?.message ?? ""
                                      : ""}
                                    &nbsp;
                                  </p>
                                </>
                              )}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6} lg={4} ref={detailsHeaderRef}>
                        <Controller
                          control={control}
                          name="header"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="header"
                                type="text"
                                label={t("common.fieldHeaders.header")}
                                value={value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onChange(e);
                                  setHeader(value);
                                  setValue('header', value);
                                }}
                                disabled={details === "externalLink"}
                              />
                              <p className="field-error">
                                {details === "write"
                                  ? errors?.header?.message ?? ""
                                  : ""}
                                &nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} lg={4} ref={writerRef}>
                        <Controller
                          control={control}
                          name="writer"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                id="writer"
                                type="text"
                                label={t("common.fieldHeaders.writer")}
                                value={value}
                                onChange={onChange}
                                disabled={details === "externalLink"}
                              />
                              <p className="field-error">
                                {details === "write"
                                  ? errors?.writer?.message ?? ""
                                  : ""}
                                &nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item className="grid-row" xs={12} ref={textRef}>
                        <p
                          className={`${details === "externalLink" ? "text-disabled" : ""
                            }`}
                        >
                          {t("common.fieldHeaders.articleText")}
                        </p>
                        <Controller
                          control={control}
                          name="text"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Editor
                                value={textEditor}
                                init={{
                                  directionality: textInputDirection(),
                                  height: 500,
                                  width: "65%",
                                  ...tinyEditorOptions,
                                  toolbar_mode: 'sliding',
                                  content_style: `body { ${lngDirection === "rtl"
                                    ? "text-align: right;"
                                    : "text-align: left;"
                                    }
                                overflow-x: hidden; }`,
                                }}
                                onEditorChange={(value) => {
                                  setTextEditor(value);
                                  onChange(transformEditorText(value));
                                }}
                                disabled={details === "externalLink"}
                              />
                              <p className="field-error">
                                {details === "write"
                                  ? errors?.text?.message ?? ""
                                  : ""}
                                &nbsp;
                              </p>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText error className="error-text">
                      {submitError
                        ? submitError?.charAt(0).toUpperCase() +
                        submitError?.slice(1)
                        : ""}
                      &nbsp;
                    </FormHelperText>
                  </Grid>
                </Grid>
              </div>
              <div className="submit-buttons">
                <Button
                  variant="contained"
                  name="PreviewAndPublish"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setValue("is_draft", false);
                    setTimeout(() => {
                      scrollToError();
                    });
                  }}
                  disabled={
                    !(
                      categories.length &&
                      (groups.length || departments.length) &&
                      roleType
                    )
                  }
                >
                  {t("common.buttons.previewAndPublish")}
                </Button>
                <Button
                  className="link-button-container"
                  type="submit"
                  onClick={() => {
                    setValue("is_draft", true);
                    setTimeout(() => {
                      scrollToError();
                    });
                  }}
                >
                  <Link className="link-btn">
                    {t("common.buttons.saveAsDraft")}
                  </Link>
                </Button>
              </div>
            </form>
          </div >
        </div >
        <PreviewModal
          className="create-article article"
          openModal={openModal}
          handleClose={handleCloseModal}
          content={
            <>
              <div className={`preview-image-container template-${template}`}>
                <div className={`preview-image template-${template}`}>
                  <img
                    src={croppedImage && template !== 3 ? croppedImage : " "}
                    alt={fileUpload?.name ?? ""}
                  />
                </div>
                {(template === 1) && (
                  <div className={`template-1-container`}>
                    <div className="preview-category">
                      <span>
                        {selectedCategory || t("common.fieldHeaders.category")}
                      </span>
                    </div>
                    <p className="preview-header">
                      {header ||
                        t("articles.allArticles.create.form.preview.headerGoes")}
                    </p>
                    {
                      shortDescription && (
                        <p>
                          {shortDescription}
                        </p>
                      )
                    }
                    <p className="preview-date">
                      {convertToDate(getValues("publish_date"), "preview")}
                    </p>
                  </div>
                )}
                {template === 2 && (
                  <>
                    <div className={`template-2-container`}>
                      <div className="preview-short-text">
                        <div className="preview-text-header">
                          <span>
                            {header ||
                              t(
                                "articles.allArticles.create.form.preview.detailsHeader"
                              )}
                          </span>
                        </div>
                        <div className="preview-html">
                          {
                            details === "externalLink" ? (
                              <p>
                                {shortDescription}
                              </p>
                            ) : (textEditor
                              ? displayHTMLPhone(textEditor)
                              : t(
                                "articles.allArticles.create.form.preview.detailsText"
                              ))
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`preview-dates`}>
                      <span>{convertToDate(getValues("publish_date"), "preview")}</span>
                    </div>
                  </>
                )}
                {
                  template === 3 && (
                    <>
                      <div className="template-3-container">
                        <div className="preview-category">
                          <span>{selectedCategory || "Category"}</span>
                        </div>
                        <p className="preview-date">
                          {convertToDate(
                            getValues("publish_date"),
                            "preview"
                          )}
                        </p>
                        <div className="preview-short-text">
                          <div className="break-text">
                            <div className="preview-text-header">
                              <span>
                                {header ||
                                  t(
                                    "articles.allArticles.create.form.preview.detailsHeader"
                                  )}
                              </span>
                            </div>
                            <div className="preview-html">
                              {
                                details === "externalLink" ? (
                                  <p>
                                    {shortDescription}
                                  </p>
                                ) : (textEditor
                                  ? displayHTMLPhone(textEditor, 250, 8)
                                  : t(
                                    "articles.allArticles.create.form.preview.detailsText"
                                  ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`preview-author`}>
                        <span>{getValues("writer")}</span>
                      </div>
                    </>
                  )
                }
                <div className={`preview-icons template-${template}`}>
                  <FavoriteIcon className="icon favorite" />
                  <MoreHorizIcon className="icon dots" />
                </div>
              </div>
            </>
          }
        />
        <NotificationModal
          openModal={openNotificationModal}
          buttonText={t("common.buttons.close")}
          handleClose={handleNotificationClose}
          handleButtonClick={handleNotificationClose}
          message={notificationMessage}
        />
        {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
      </div >
    </>
  );
};

export default observer(NewArticle);
