import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { noContent } from "../../../db/db";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from "../../../assets/icons/export.svg";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import { exportToExcel } from "../../../shared/utils/sharedFunctions";
import RangeDatePicker from "../../RangeDatePicker/RangeDatePicker";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { ClickAwayListener } from "@material-ui/core";

const ClickInformationModal = () => {
  const { reportsStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const { i18n, t } = useTranslation();
  const { languageStore } = useStore();
  const [clicksList, setClicksList] = useState<Array<Object>>([]);
  const [totalClicks, setTotalClicks] = useState(0);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => { });
  const [clickInformation, setClickInformation] = useState<any>({});
  const [openSideMenu, setOpenSideMenu] = useState(false);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });

  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const convertToDate = (date, locale = lngDirection === "rtl" ? "he-IL" : "en-US") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };

    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  const convertToDateRangePicker = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  // Get all clicks and fill table
  const getAllClickInformation = async (id = clickInformation.id, params = {}) => {
    // return;
    setIsLoading(true);
    const searchParams = reportsStore.getInnerSearchParams();

    const benefitParams = {
      limit: 10,
      from_date: convertToDate(new Date(new Date().setDate(new Date().getDate() - 7)), "en-US"),
      to_date: convertToDate(new Date(), "en-US"),
    }

    await flowResult(reportsStore.getStoreClicks(id, { ...benefitParams, ...searchParams, ...params })).then(
      (response: any) => {
        if (!response?.success) {
          // on error
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        let totalClicks = 0;
        setClicksList(
          response["clicks"].map((data, index) => {
            totalClicks += data.clicks
            return { ...data, id: index }
          })
        );
        setTotalClicks(totalClicks);
        setIsLoading(false);
      }
    );
  };


  // error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  const handleExport = async () => {
    setIsLoading(true)
    await flowResult(reportsStore.exportStoreClicks(clickInformation.id)).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
          setIsLoading(false);
          return;
        }
        const header = clickInformation?.row?.header ?? clickInformation?.header ?? '';
        exportToExcel(response.file, `${t('tabNavigation.reports.clicks').toLowerCase()}_${header.replace(' ', '_')}`);
        setIsLoading(false);
      }
    );
  }

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0)
        })
      })
    }
  }, [languageStore.switchLng, isLoading === false])

  useEffect(() => {
    if (!clickInformation?.id) return
    if (reportsStore.triggerUpdateInnerList) {
      getAllClickInformation();
      reportsStore.triggerUpdateInnerList = false;
    }
  }, [reportsStore.triggerUpdateInnerList]);

  useEffect(() => {
    const click = reportsStore.getClickInformation();
    const clickID = click?.id ?? '';
    if (clickID) {
      getAllClickInformation(clickID);
      setClickInformation(click);
      if (reportsStore.triggerUpdateInnerList) {
        const searchParams = reportsStore.getSearchParamsClicks();
        setDateRange({ startDate: searchParams.from_date, endDate: searchParams.to_date });
      }
      setTimeout(() => {
        setOpenSideMenu(true);
      });
    } else {
      setOpenSideMenu(false);
      setTimeout(() => {
        setClickInformation({});
      }, 500);
    }
  }, [reportsStore.clickInformation]);

  const tableData: GridColDef[] = [
    {
      field: "date",
      headerName: t("common.fieldHeaders.date"),
      sortable: true,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className={`table-row-title`}>
            {convertToDate(params.row.date)}
          </span>
        );
      },
    },
    {
      field: "clicks",
      headerName: t("common.fieldHeaders.numberOfClicks"),
      sortable: false,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span
            className={`table-column click-modal-amount`}
          >
            {params.colDef.headerName}
          </span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.clicks}
          </span>
        );
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );

  const handleDateChange = ({ startDate, endDate }, isClose = false) => {
    setDateRange({ startDate: startDate, endDate: endDate });
    if (isClose) {
      setOpenDatePicker(false);
      const data = {
        from_date: convertToDate(startDate, "en-US"),
        to_date: convertToDate(endDate, "en-US"),
      }
      reportsStore.setInnerSearchParams(data);
    }
  };


  return (
    <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
      <div className={`clicks-modal-page-container ${clickInformation.id ? 'open' : ''}`}>
        <div className={`main-wrapper clicks-modal-page ${openSideMenu ? 'open' : ''}`}>
          {isLoading ? (
            <Spinner className="clicks-modal-spinner" />
          ) : (
            <>
              <div className="click-modal-header d-flex justify-between align-center">
                <div>
                  <h3>{t('reports.clicks.informationModal.header')}</h3>
                </div>
                <div className="d-flex justify-between align-center">
                  <div className="export-button d-flex justify-between align-center" onClick={handleExport}>
                    <span className="export-button-text">{t('reports.purchases.informationModal.export')}</span>
                    <IconComponent icon={ExportIcon} />
                  </div>
                  <div className="close-container d-flex justify-between align-center">
                    <CloseIcon className="icon-close" onClick={() => reportsStore.setClickInformation({})} />
                  </div>
                </div>
              </div>
              <div className="click-modal-footer">
                <div className="page-heading-wrap">
                  <h2 className="page-heading">{clickInformation?.row?.header ?? clickInformation?.header ?? ''}</h2>
                </div>
                <div className="d-flex justify-between">
                  <span>
                    {t("reports.clicks.clicksCount", { count: totalClicks })}
                  </span>
                  <div className="range-date-picker datepicker">
                    <div className="dislayed-dates" onClick={() => toggleDatePicker()}>
                      <span>
                        {t("reports.fromDate")}{" "}
                        {dateRange?.startDate ? convertToDateRangePicker(dateRange.startDate) : ""}
                      </span>
                      <span>-</span>
                      <span>
                        {t("reports.untilDate")}{" "}
                        {dateRange?.endDate ? convertToDateRangePicker(dateRange.endDate) : ""}
                      </span>
                    </div>
                    <div className="range-datepicker">
                      <RangeDatePicker
                        value={dateRange}
                        isOpened={openDatePicker}
                        onChange={handleDateChange}
                        locale={lngDirection === "rtl" ? heLocale : enLocale}
                      />
                    </div>
                  </div>
                </div>
                <div className="sales-page content-wrap" style={{ maxHeight: window.innerHeight * 0.7 }}>
                  {clicksList?.length ? (
                    <div className="table-grid">
                      <DataGrid
                        rows={clicksList}
                        autoHeight={true}
                        columns={lngDirection === "ltr" ? tableData.reverse() : tableData}
                        rowHeight={90}
                        headerHeight={80}
                        disableColumnMenu={true}
                        hideFooter={true}
                      />
                    </div>
                  ) : (
                    <div className="no-content">
                      <img src={noContent.users} alt="No messages" />
                      <p>{t("reports.purchases.noContent.message1")}</p>
                      <span className="note">
                        {t("reports.purchases.noContent.message2")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <NotificationModal
            openModal={openNotificationModal}
            handleClose={handleNotificationClose}
            handleButtonClick={buttonAction}
            buttonText={buttonText}
            message={notificationMessage}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default observer(ClickInformationModal);
