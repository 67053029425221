import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconComponent from '../../../shared/components/IconComponent/IconComponent';
import LockIcon from "../../../assets/icons/lock.svg";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from 'react-i18next';
import "./style.scss";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import { useStore } from '../../../store/StoreContext';
import UserTypeEnum from '../../../models/UserType';

const ArticleBox = ({
    article,
    toggleLock = null,
    openActionMenu = null,
    handleApprove = null,
    handleReject = null,
    className = '',
}) => {
    const { t } = useTranslation();
    const { userStore } = useStore();
    const [roleType, setRoleType] = useState(null);

    const dontShow = ['image', 'id', 'isLocked', 'isPublished', 'isApproved', 'isArchived', 'template'];

    useEffect(() => {
        setRoleType(userStore.getRoleType());
    }, [])

    return (
        <Grid container className={`article-box ${className}`} direction='row' justifyContent='space-between'>
            {
                Object.keys(article).map((key, index) => {
                    if (key === 'image') {
                        return (
                            <Grid item xs={12} key={index}>
                                <Grid container className="wrap-box">
                                    <Grid item xs={6} className="article-item image">
                                        {
                                            article.template !== 3 ? (
                                                <img className={`article-image`} src={article?.image || ImagePlaceholder} alt={article.header || "Article image"} />
                                            ) : (
                                                <div className={`article-image article-template-3`} />
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={6} className="article-item image">
                                        <div className="d-flex justify-end">
                                            {
                                                (toggleLock && (roleType === UserTypeEnum.owner || roleType === UserTypeEnum.associatedOwner)) ? (
                                                    <div className={`article-action lock`}
                                                        onClick={() => toggleLock(article.id, !article.isLocked)}>
                                                        {
                                                            !article.isLocked && (
                                                                <LockOpenIcon />
                                                            )
                                                        }
                                                        {
                                                            article.isLocked && (
                                                                <IconComponent icon={LockIcon} />
                                                            )
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                openActionMenu ? (
                                                    <div id={`article-action-${article.id}`} className="article-action"
                                                        onClick={openActionMenu}>
                                                        <MoreHorizIcon
                                                            aria-controls={`actions-menu`}
                                                            aria-haspopup="true"
                                                            className="article-action-icon"
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    } else if (dontShow.indexOf(key) === -1) {
                        return (
                            <Grid item xs={12} key={index}>
                                <Grid container>
                                    <Grid item xs={6} className="article-item">
                                        <span className="article-text header">{t(`common.fieldHeaders.${key}`)}</span>
                                    </Grid>
                                    <Grid item xs={6} className="article-item justify-content-end display-flex text-end">
                                        <span className="article-text">{article[key]}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return null
                    }
                })
            }
            {
                (!article.isApproved && handleApprove && handleReject && roleType === 'chiefEditor') ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} className="article-item button">
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleApprove(article.id)}
                                >{t('users.waitingForApproval.approve')}</Button>
                            </Grid>
                            <Grid item xs={6} className="article-item button text-right">
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleReject(article.id)}
                                >{t('users.waitingForApproval.reject')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null
            }
        </Grid>
    )
}

export default ArticleBox
