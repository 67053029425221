import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {en} from './en';
import {he} from './heb';

const defaultLanguage = 'he';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'he',
    lng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
            ...en
        }
      },
      he: {
        translation: {
            ...he
        }
      }
    }
  });

export const lngChange = (lng = 'he') => {
  if (lng) {
    i18n.changeLanguage(lng)
  }
  const dir = i18n.dir();
  const body = document.body;
  body.dir = dir;
  if (dir === "rtl") {
    if (body.className.indexOf("community-rtl") !== -1) {
      return;
    }
    body.className = body.className + " community-rtl";
  } else {
    body.className = body.className.replace(/community-rtl/g, '');
  }
}

export const SUPPORTED_LANGUAGES = ['he', 'en'];

export default i18n;
