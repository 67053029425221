import {create} from 'apisauce';
import {API_URL, API_VERSION} from "../config";
import {DEFAULT_API_CONFIG} from "./ApiConfing";


const ApiInstance = create({
    baseURL: `${API_URL}/${API_VERSION}`,
    timeout: DEFAULT_API_CONFIG.timeout,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
    }
});

ApiInstance.addAsyncRequestTransform(request => async () => {
    // eslint-disable-next-line no-lone-blocks
    {
        const token = localStorage.getItem('access_token');
        request.headers['Authorization'] = `Bearer ${token}`;
    }
});

export const Api = ApiInstance;

