import { Button } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { flowResult } from 'mobx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tinyEditorOptions } from '../../../shared/utils/sharedOptions';
import { useStore } from '../../../store/StoreContext';
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';

const PrivacyPolicy = () => {
    const { i18n, t } = useTranslation();
    const { languageStore, privacyStore, userStore } = useStore();
    const [platformId, setPlatformId] = useState('');
    const [privacyText, setPrivacyText] = useState('');
    const [lngDirection, setLngDirection] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        const id = userStore.platform.id;
        setPlatformId(id);
        getInformation(id);
    }, [])

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng]);

    const getInformation = async (id) => {
        await flowResult(privacyStore.getPrivacyInformation(id)).then(
            (response: any) => {
                setIsLoading(false);
                if (!response?.success) {
                    handleNotificationOpen(response?.message ? t(`apiMessages.${response.message}`) : t('apiMessages.0'))
                    return;
                }
                setPrivacyText(response.details?.["privacy-policy"] ?? '')
            }
        )
    }

    const textInputDirection = () => {
        if (lngDirection === "rtl") return "rtl";
        if (lngDirection === "ltr") return "ltr";
    };

    const handleSave = async () => {
        setIsLoading(true);
        const payload = {
            "about-us": "",
            "privacy-policy": privacyText,
            "terms-of-use": ""
        }
        await flowResult(privacyStore.update(payload, platformId)).then(
            (response: any) => {
                handleNotificationOpen(response?.message ? t(`apiMessages.${response.message}`) : t('apiMessages.0'))
            }
        )
        setIsLoading(false);
    }

    const handleNotificationOpen = (message = '') => {
        setNotificationMessage(message);
        setOpenNotificationModal(true);
    }

    return (
        <div className="main-wrapper">
            <h2 className="page-heading">
                {t('tabNavigation.settings.communityPolicy')}
            </h2>
            <div className="main-wrapper">
                <h3>{t('settings.communityPolicy.privacyPolicy')}</h3>
                <Editor
                    value={privacyText}
                    init={{
                        directionality: textInputDirection(),
                        height: 500,
                        width: "65%",
                        ...tinyEditorOptions,
                        toolbar_mode: 'sliding',
                        content_style: `body { ${lngDirection === "rtl"
                            ? "text-align: right;"
                            : "text-align: left;"
                            }
                    overflow-x: hidden; }`,
                    }}
                    onEditorChange={(value) => {
                        setPrivacyText(value);
                    }}
                />
            </div>
            <div className="submit-buttons">
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!privacyText.length}
                    onClick={() => handleSave()}
                >{t('common.buttons.save')}</Button>
            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={() => setOpenNotificationModal(false)}
                handleButtonClick={() => setOpenNotificationModal(false)}
                buttonText={t("common.buttons.close")}
                message={notificationMessage}
            />
            {isLoading ? <Spinner /> : null}
        </div>
    );
};

export default PrivacyPolicy;
