/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from "@material-ui/data-grid";
import "./style.scss";
import EditNotification from './EditNotification';
import { useStore } from '../../../store/StoreContext';
import { flowResult } from "mobx";
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import { observer } from 'mobx-react-lite';
import NoArticles from "../../../assets/images/articles/noArticles.svg";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import EditIcon from "../../../assets/icons/edit.svg";
import ViewIcon from "../../../assets/icons/view.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import DuplicateIcon from "../../../assets/icons/duplicate.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AutomatedNotifications = () => {
    const { t } = useTranslation();
    const { userStore, actionStore } = useStore();
    const [sortableColumn, setSortableColumn] = useState('');
    const [list, setList] = useState<Array<Object>>([
        {
            id: '1',
            image: '',
            message: 'Welcome! here is a quick guide to help you at your first time here',
            sentTo: 'All new users',
            openRate: 87,
        },
        {
            id: '2',
            image: '',
            message: 'Welcome! here is a quick guide to help you at your first time here',
            sentTo: 'All new users',
            openRate: 87,
        },
        {
            id: '3',
            image: '',
            message: 'Welcome! here is a quick guide to help you at your first time here',
            sentTo: 'All new users',
            openRate: 87,
        }
    ]);
    const [noNotifications, setNoNotifications] = useState(false);

    const [isLoading, setIsLoading] = useState(false); //true
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [itemDeletion, setItemDeletion] = useState(false);

    // const updateList = (hasParams = {}) => {
    //     setIsLoading(true);
    //     setNoBenefits(false);
    //     flowResult(actionStore.getPathItems('benefit', hasParams)).then(
    //         (response: any) => {
    //             console.log("updating list", response)
    //             if (!response.success) {
    //                 setIsLoading(false);
    //                 handleNotificationOpen(`Error while getting Notifications information`);
    //                 return;
    //             }
    //             const sent: Array<Object>[] = [];
    //             const toBeSent: Array<Object>[] = [];
    //             const data = response.notifications;
    //             for (const item of data) {
    //                 if (item?.toBeSent) {
    //                     toBeSent.push(item);
    //                 } else {
    //                     sent.push(item);
    //                 }
    //             }
    //             if (!(sent.length || toBeSent.length)) {
    //                 setNoNotifications(true);
    //                 setIsLoading(false);
    //                 return;
    //             }
    //             setSentList(data)
    //             setToBeSentList(notifications);
    //         }
    //     ).catch(error => {
    //         console.error('GET Notifications error: ', error);
    //     });
    // }

    // const handleFlow = (flow, message) => {
    //     setIsLoading(true);
    //     flowResult(flow).then(
    //         (response: any) => {
    //             console.log(response)
    //             if(!response.success) {
    //                 handleNotificationOpen(response?.error?.[0] ?? response?.message);
    //                 return;
    //             }
    //             updateList();
    //         }
    //     ).catch(error => {
    //         console.error(`${message} error: `, error);
    //     });
    // }

    useEffect(() => {
        // setCommunities([...userStore.communities])
        // setCategories(actionStore.getBenefitCategories());
        // updateList();
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, []);

    // useEffect(() => {
    //     if (actionStore.triggerUpdateList) {
    //         updateList();
    //         actionStore.triggerUpdateList = false;
    //     }
    // }, [actionStore.triggerUpdateList])

    const handleNotificationOpen = (message = '', isDelete = false, id = '') => () => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (isDelete) {
            setPotentialEditRowID(id);
            setButtonText(t('common.buttons.confirm'));
            setItemDeletion(true);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (isDelete) => () => {
        if (isDelete) {
            handleDelete();
            setItemDeletion(false);
        }
        setOpenNotificationModal(false);
    }

    // Edit 
    const [potentialEditRowID, setPotentialEditRowID] = useState('');

    const fakeNotification = (message) => {
        setTimeout(() => {
            if (!openNotificationModal) {
                setButtonText(t('common.buttons.close'));
                setNotificationMessage(message);
                setOpenNotificationModal(true);
            }
        })
    }

    const handleOpenEdit = (id) => (event) => {
        if (id) {
            // @ts-ignore
            const currentRowIndex = list.findIndex(el => el.id === id);
            const currentRow = list[currentRowIndex];
            actionStore.editRowDataPromo = currentRow;

            const rowElement = event.currentTarget.parentElement.parentElement.parentElement;
            actionStore.editRowAnchor = rowElement;
            actionStore.editRowWidth = rowElement.clientWidth;
            return;
        }
        fakeNotification('Unable to open edit. ID not found.');
    }

    const handleSaveEdit = (data) => {
        const newList = list.map((el: any) => {
            if (data.id === el.id) {
                return { ...data }
            }
            return el 
        })
        setList(newList)
        fakeNotification(`Fake message: - ID: ${data.id} successfully saved.`)
        // const path = 'benefit';
        // handleFlow(actionStore.update(data, path, data.id), `${path.toUpperCase()} UPDATE`);
    }

    const handleDuplicate = (data) => () => {
        const newList = [...list];
        newList.push({...data, id: `${data.id}${Math.floor(Math.random() * 1000)}`})
        setList([...newList])
        fakeNotification(`Fake message: - ID: ${data.id} duplicated.`);
        // const path = 'benefit/duplicate';
        // handleFlow(actionStore.update('', path, potentialEditRowID), `${path.toUpperCase()} UPDATE`);
    }

    const handleDelete = () => {
        const newList = list.filter((el: any) => {
            return potentialEditRowID !== el.id
        })
        setList(newList)
        fakeNotification(`Fake message: - ID: ${potentialEditRowID} deleted.`)
        setIsLoading(false);
        // const path = 'benefit';
        // handleFlow(actionStore.delete(path, potentialEditRowID), `${path.toUpperCase()} DELETE`);
    }

    const columns: GridColDef[] = [
        {
            field: 'message',
            headerName: t('push.promoNotification.message'),
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`large-spacing table-column ${sortableColumn === params.field ? ' sorted' : ''}`}>
                        {params.colDef.headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className=" large-spacing table-row-title">{params.row.message}</span>
                );
            },
        },
        {
            field: 'sentTo',
            headerName: t('push.promoNotification.sentTo'),
            sortable: false,
            width: 200,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column ${sortableColumn === params.field ? ' sorted' : ''}`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.sentTo}</span>
                );
            },
        },
        {
            field: 'openRate',
            headerName: t('push.promoNotification.openRate'),
            sortable: false,
            width: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column ${sortableColumn === params.field ? ' sorted' : ''}`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.openRate}%</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            width: 250,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column cell-actions-header`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                const id = data.id
                return (
                    <div id={`grid-row-${id}`} className={`table-row-actions cell-actions ${data.isSent ? 'single-action' : ''}`}>
                        <div onClick={handleOpenEdit(id)}>
                            <IconComponent icon={EditIcon} />
                        </div>
                        <div onClick={handleDuplicate(data)}>
                            <IconComponent icon={DuplicateIcon} />
                        </div>
                        <Link className="view-link flex-center" to={`view/${id}`}>
                            <IconComponent icon={ViewIcon} />
                        </Link>
                        <div onClick={handleNotificationOpen(t("errorMessages.deleteOne"), true, id)}>
                            <IconComponent icon={DeleteIcon} />
                        </div>
                    </div>)
            },
        },
    ]

    return isLoading ? (<Spinner />) : (
        <div className="main-wrapper automated-page">
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('tabNavigation.push.autoNotification')}
                </h2>
            </div>
            <div className="sales-page">
                {
                    !noNotifications && (
                        <>

                            {
                                list.length > 0 && (
                                    <div>
                                        {/* <h3></h3> */}
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={list}
                                                columns={columns.map((column) => ({
                                                    ...column,
                                                    disableClickEventBubbling: true,
                                                }))}
                                                rowHeight={90}
                                                headerHeight={56}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                                // onSortModelChange={(sort) => setSortableColumn(sort.sortModel[0]?.field ?? '')}
                                                />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                <>
                                    <EditNotification handleSaveEdit={handleSaveEdit} />
                                </>
                            }
                        </>
                    )
                }
                {
                    noNotifications && (
                        <div className="no-notifications">
                            <img src={NoArticles} alt="" />
                            <h3>{t('push.noNotifications.message1')}</h3>
                            <p>{t('push.noNotifications.message2')}</p>
                        </div>
                    )
                }
            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose(false)}
                handleButtonClick={handleNotificationClose(itemDeletion)}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default observer(AutomatedNotifications)
