import { flowResult } from 'mobx';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import Spinner from '../../../components/Spinner/Spinner';
import { useStore } from '../../../store/StoreContext';
import ArticleList from './ArticleList';
import noResults from '../../../assets/images/noResults.png';
import { observer } from 'mobx-react-lite';
import BenefitList from './BenefitList';

const SearchList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { actionStore, dashboardStore } = useStore();
    const [searchText, setSearchText] = useState('');

    const [articleList, setArticleList] = useState<any>([]);
    const [benefitList, setBenefitList] = useState<any>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const getArticles = async (params: any) => {
        delete params['category']
        if (params.is_archived) {
            let list = [];
            delete params['is_archived']
            await flowResult(actionStore.getPathItems('articles', {...params, limit: 50, is_archived: false})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                        return;
                    }
                    const data = response.articles;
                    list = list.concat(data)
                }
            )
            await flowResult(actionStore.getPathItems('articles', {...params, limit: 10, is_archived: true})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0') );
                        return;
                    }
                    const data = response.articles;
                    list = list.concat(data)
                }
            )
            setArticleList(list);
        } else {
            await flowResult(actionStore.getPathItems('articles', {...params, limit: 50})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0') );
                        return;
                    }
                    const data = response.articles;
                    setArticleList(data)
                }
            )
        }
    }

    const getBenefits = async (params: any) => {
        delete params['category']
        if (params.is_archived) {
            let list = [];
            delete params['is_archived']
            await flowResult(actionStore.getPathItems('benefit', {...params, limit: 50, is_archived: false})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0') );
                        return;
                    }
                    const data = response.benefits;
                    list = list.concat(data)
                }
            )
            await flowResult(actionStore.getPathItems('benefit', {...params, limit: 10, is_archived: true})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0') );
                        return;
                    }
                    const data = response.benefits;
                    list = list.concat(data)
                }
            )
            setBenefitList(list);
        } else {
            await flowResult(actionStore.getPathItems('benefit', {...params, limit: 50})).then(
                (response: any) => {
                    if (!response?.success) {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0') );
                        return;
                    }
                    const data = response.benefits;
                    setBenefitList(data)
                }
            )
        }
    }

    const updateLists = async (params: any) => {
        setIsLoading(true);
        const promises: any = [];
        const cats = params.category;
        if (cats.indexOf('articles') !== -1) {
            promises.push(getArticles(params))
        } else {
            setArticleList([]);
        }
        if (cats.indexOf('benefits') !== -1) {
            promises.push(getBenefits(params))
        }else {
            setBenefitList([]);
        }
        await Promise.all([...promises])
        setIsLoading(false);
    }

    const handleNotificationOpen = (message = '') => {
        setNotificationMessage(message);
        setOpenNotificationModal(true);
    }

    useEffect(() => {
        const searchParams: any = dashboardStore.getSearchParams();
        if (Object.keys(searchParams).length) {
            if (dashboardStore.triggerUpdateList) {
                setSearchText(searchParams.search)
                updateLists(searchParams);
                dashboardStore.triggerUpdateList = false;
            }
        } else {
            history.push("/");
        }

    }, [dashboardStore.triggerUpdateList]);

    const triggerIsLoading = () => {
        setIsLoading(true);
    }

    return isLoading ? (<Spinner />) : (
        <div className="main-content main-content-tabs my-messages-page">
            <div className="main-wrapper dashboard-search-page">
                {
                    (articleList.length || benefitList.length) ? (
                        <>
                            <div className="page-heading-wrap">
                                <h2 className="page-heading">
                                    {searchText ? `${t('dashboard.search.resultFor')} "${searchText}"` : t('dashboard.search.searchResults')}
                                </h2>
                            </div>
                            <div>
                                {
                                    articleList.length ? (
                                        <>
                                            <p>{t('tabNavigation.articles.mainTab')}</p>
                                            <ArticleList rows={articleList} triggerIsLoading={triggerIsLoading} />
                                        </>
                                    ) : <></>
                                }
                                {
                                    benefitList.length ? (
                                        <>
                                            <p>{t('tabNavigation.benefits.mainTab')}</p>
                                            <BenefitList rows={benefitList} triggerIsLoading={triggerIsLoading} />
                                        </>
                                    ) : <></>
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="page-heading-wrap">
                                <h2 className="page-heading">
                                    {`${t('dashboard.search.noResults.title')} "${searchText}"`}
                                </h2>
                            </div>
                            <div className="flex-center flex-column">
                                <img src={noResults} alt="" />
                                <h3>{t('dashboard.search.noResults.message1')}</h3>
                                <p>{t('dashboard.search.noResults.message2')}</p>
                            </div>
                        </>
                    )
                }

            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={() => setOpenNotificationModal(false)}
                handleButtonClick={() => setOpenNotificationModal(false)}
                buttonText={t('common.buttons.close')}
                message={notificationMessage}
            />
        </div>
    )
}

export default observer(SearchList)
