import React, { useEffect, useState } from 'react';
import SignIn from "../pages/Authentication/SignIn/SignIn";
import SignUp from "../pages/Authentication/SignUp/SignUp";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";
import EmailSent from "../pages/Authentication/EmailSent/EmailSent";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import LoginWithSMS from "../pages/Authentication/LoginWithSMS/LoginWithSMS";
import CodeNumber from "../pages/Authentication/ValidateOTP/ValidateOTP";
import { Switch, Route, Redirect } from 'react-router-dom';
import { useStore } from "../store/StoreContext";
import { observer } from "mobx-react-lite";
import { flowResult } from 'mobx';
import NotificationModal from '../components/NotificationModal/NotificationModal';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/Spinner/Spinner';
import CreateUser from "../pages/Users/Create/Create";

const AuthenticationNavigator = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const [isAuth, setIsAuth] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleNotificationOpen = (message = '') => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  }

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsAuth(!!token)
    if (!token) {
      setIsLoading(true);
      flowResult(userStore.getPlatforms()).then((response: any) => {
        if (!response?.success) {
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [userStore.isAuthenticated])

  return isLoading ? (<Spinner />) : (!isAuth ? (
    <>
      <Switch>
        <Route path={'/sign-in/:mobile_token?'} component={SignIn} />
        <Route path={'/sign-up'} component={SignUp} />
        <Route path={'/forgot-password'} component={ForgotPassword} />
        <Route path={'/email-sent'} component={EmailSent} />
        <Route path={'/reset-password'} component={ResetPassword} />
        <Route path={'/login-with-sms'} component={LoginWithSMS} />
        <Route path={'/sms-code-number'} component={CodeNumber} />
        <Route path={'/register/:community_id?/:community_name?'} component={CreateUser} />
        <Route path={'*'} component={() => <Redirect to={'/sign-in'} />} />
      </Switch>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        buttonText={t('common.buttons.confirm')}
        message={notificationMessage}
      />
    </>
  ) : null)
};

export default observer(AuthenticationNavigator)
