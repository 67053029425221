import DateFnsUtils from '@date-io/date-fns';
import { Grid, FormControl, FormControlLabel, Checkbox, Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIELDS, VALIDATIONS } from "./userFields";
import { useStore } from '../../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { formatToPhoneNumber } from '../../../shared/utils/sharedFunctions';

const SearchUsers = () => {
    const { t } = useTranslation();
    const { usersPageStore } = useStore();

    const {
        register,
        handleSubmit,
        control,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: 'onChange',
        defaultValues: {
            'first_name': '',
            'last_name': '',
            'phone': '',
            'email': '',
            'is_approved': false,
            'admin': false,
            'chief_editor': false,
            'editor': false,
            'user': false,
        }
    });

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    const handleSubmitCheck = (objectData) => {
        const roles: any = [];
        if (objectData.admin) roles.push("ADMIN")
        if (objectData.chief_editor) roles.push("CHIEF_EDITOR")
        if (objectData.editor) roles.push("EDITOR")
        if (objectData.user) roles.push("USER")
        const data = {
            first_name: objectData.first_name,
            last_name: objectData.last_name,
            phone: objectData.phone.replace(' ', ''),
            email: objectData.email,
            role: [...roles],
        }
        if(objectData.is_approved) {
            data['is_registered'] = true;
        }

            usersPageStore.setSearchParams(data);
    }
    return (
        <div className="search-panel">
            <form className="" autoComplete="off" onSubmit={handleSubmit((d) => handleSubmitCheck(d))}>
                <div className="main-wrapper">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container className="view-grid" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <Controller
                                            control={control}
                                            name="first_name"
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    label={t('signUp.fields.firstName.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <Controller
                                            control={control}
                                            name="last_name"
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    label={t('signUp.fields.lastName.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <Controller
                                            control={control}
                                            name="phone"
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    label={t('signUp.fields.phone.label')}
                                                    value={value}
                                                    onChange={(e) => onChange(formatToPhoneNumber(e.target.value, value))}
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <Controller
                                            control={control}
                                            name="email"
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    label={t('signUp.fields.email.label')}
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControlLabel
                                        control={

                                            <Controller
                                                control={control}
                                                name="is_approved"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value || false}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('users.search.downloadApp')}
                                    />
                                </Grid>
                                <Grid item className="checkbox-item">
                                    <div>
                                        <span className="search-panel-subTitle">{t('common.fieldHeaders.type')}</span>
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="admin"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('users.create.admin')}
                                    />
                                </Grid>
                                <Grid item className="checkbox-item">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="chief_editor"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('users.create.chiefEditor')}
                                    />
                                </Grid>
                                <Grid item className="checkbox-item">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="editor"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('users.create.editor')}
                                    />
                                </Grid>
                                <Grid item className="checkbox-item">
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="user"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('users.create.user')}
                                    />
                                </Grid>

                                <Grid item className="grid-row search-button-container">
                                    <Button variant="contained" color="primary" type="submit">{t('common.fieldHeaders.search')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
            </form>
        </div>
    )
}

export default observer(SearchUsers)
