import AllArticles from "./AllArticles/AllArticles";
import RotatingBanner from "./RotatingBanner/RotatingBanner";
import Ads from "./Ads/Ads";
import CreateArticle from "./Create/Article/CreateArticle";
import CreateBanner from "./Create/Banner/CreateBanner";
import CreateAd from "./Create/Ad/CreateAd";
// import ViewArticle from "./View/ViewArticle";
// import ViewBanner from "./View/ViewBanner";
// import ViewAd from "./View/ViewAd";

export const routes = [
    {
        path: 'list',
        component: AllArticles
    },
    {
        path: 'rotating-banner',
        component: RotatingBanner
    },
    {
        path: 'ads',
        component: Ads
    },
    {
        path: 'create/article',
        component: CreateArticle
    },
    {
        path: 'create/banner',
        component: CreateBanner
    },
    {
        path: 'create/ad',
        component: CreateAd
    },
    // {
    //     path: 'view/article/:id',
    //     component: ViewArticle
    // },
    // {
    //     path: 'view/banner/:id',
    //     component: ViewBanner
    // },
    // {
    //     path: 'view/ad/:id',
    //     component: ViewAd
    // }
]
