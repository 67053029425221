import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import Spinner from "../../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import "./style.scss";

const ManageDepartments = () => {
  const { t } = useTranslation();
  const { departmentStore } = useStore();

  const [isLoading, setIsLoading] = useState(true);
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [secondDepartmentList, setSecondDepartmentList] = useState<any[]>([]);

  const [isDeleteSecondary, setIsDeleteSecondary] = useState(false);

  useEffect(() => {
    setDepartmentList(departmentStore.getStoredDepartments());
    setSecondDepartmentList(departmentStore.getStoredSecondaryDepartments());
    setIsLoading(false);
  }, []);

  const handleAddItem = (isSecondary?: boolean) => {
    const newDepartment = {
      id: "",
      name: "",
      isEdited: false,
      isNew: true,
    };
    isSecondary
      ? setSecondDepartmentList([...secondDepartmentList, newDepartment])
      : setDepartmentList([...departmentList, newDepartment]);
  };

  const handleChange = (itemIndex, isSecondary?: boolean) => (e) => {
    const value = e.target.value;
    isSecondary
      ? setSecondDepartmentList(
          secondDepartmentList?.map((el, index) => {
            if (index === itemIndex) {
              el["name"] = value;
              if (el.id) {
                el.isEdited = true;
              }
            }
            return el;
          })
        )
      : setDepartmentList(
          departmentList?.map((el, index) => {
            if (index === itemIndex) {
              el["name"] = value;
              if (el.id) {
                el.isEdited = true;
              }
            }
            return el;
          })
        );
  };

  const handleDeleteItem = (itemIndex) => {
    if (isDeleteSecondary) {
      if (secondDepartmentList[itemIndex].id) {
        const item = secondDepartmentList[itemIndex];
        setIsLoading(true);
        flowResult(departmentStore.delete(item.id, true)).then(
          (response: any) => {
            setIsLoading(false);
            if (!response?.success) {
              handleNotificationOpen(
                0,
                response?.code
                  ? t(`apiMessages.${response.code}`)
                  : t("apiMessages.0")
              );
              return;
            }            
            setSecondDepartmentList(
              departmentStore.getStoredSecondaryDepartments()
            );
          }
        );
      } else {
        setSecondDepartmentList(
          secondDepartmentList.filter((el, index) => {
            return index !== itemIndex;
          })
        );
      }
      setIsDeleteSecondary(false);
    } else {
      if (departmentList[itemIndex].id) {
        const item = departmentList[itemIndex];
        setIsLoading(true);
        flowResult(departmentStore.delete(item.id)).then((response: any) => {
          setIsLoading(false);
          if (!response?.success) {
            handleNotificationOpen(
              0,
              response?.code
                ? t(`apiMessages.${response.code}`)
                : t("apiMessages.0")
            );
            return;
          }
          setDepartmentList(departmentStore.getStoredDepartments());
        });
      } else {
        setDepartmentList(
          departmentList.filter((el, index) => {
            return index !== itemIndex;
          })
        );
      }
    }
  };

  const handleSaveFlow = (flow) => {
    setIsBtnClicked(true);
    setIsLoading(true);
    flowResult(flow).then((response: any) => {
      if (!response?.success) {
        handleNotificationOpen(
          0,
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
      }
      setButtonText(t("common.buttons.close"));
      setNotificationMessage(
        response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0")
      );
      setDepartmentList(departmentStore.getStoredDepartments());
      setSecondDepartmentList(departmentStore.getStoredSecondaryDepartments());
      setOpenNotificationModal(true);
      setIsLoading(false);
      setIsBtnClicked(false);
    });
  };

  const handleSubmitCheck = (e) => {
    e.preventDefault();
    departmentList.forEach((el) => {
      if (el.name) {
        const data = {
          name: el.name,
        };
        if (el.isNew) {
          handleSaveFlow(departmentStore.create(data));
        }
        if (el.isEdited) {
          handleSaveFlow(departmentStore.update(data, el.id));
        }
      }
    });
    secondDepartmentList.forEach((el) => {
      if (el.name) {
        const data = {
          name: el.name,
          isSecondary: true,
        };
        if (el.isNew) {
          handleSaveFlow(departmentStore.create(data));
        }
        if (el.isEdited) {
          handleSaveFlow(departmentStore.update(data, el.id));
        }
      }
    });
  };

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [itemDeletion, setItemDeletion] = useState(false);
  const [itemID, setItemID] = useState(0);

  const handleNotificationOpen = (id = 0, message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    if (!message) {
      setButtonText(t("common.buttons.yes"));
      setNotificationMessage(t("errorMessages.deleteQuestion"));
      setItemID(id);
      setItemDeletion(true);
    }
    setOpenNotificationModal(true);
  };

  const handleNotificationClose =
    (isDelete = false) =>
    () => {
      if (isDelete) {
        setItemDeletion(false);
        handleDeleteItem(itemID);
        setItemID(-1);
      }
      setOpenNotificationModal(false);
    };

  return (
    <div className="main-wrapper departments-page">
      <h2 className="page-heading">{t("settings.manageDepartments.title")}</h2>
      <form className="" autoComplete="off" onSubmit={handleSubmitCheck}>
        <div className="row">
          <div className="main-wrapper main-wrapper-departments">
            <Grid
              container
              className="content-grid"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} className="column-headers">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={10}
                        md={3}
                        lg={2}
                        className="column-name item-margin"
                      >
                        <span className="segmentation-title">
                          {`${t("settings.manageDepartments.segmentation")} 1`}
                        </span>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  </Grid>
                  {departmentList?.map((department, index) => {
                    return (
                      <Grid key={index} item xs={12} className="item-box">
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {
                            <>
                              <Grid
                                item
                                xs={4}
                                md={3}
                                lg={2}
                                className="item-margin"
                              >
                                <TextField
                                  id="department-1"
                                  label={t("community.list.name")}
                                  placeholder={t(
                                    "settings.manageDepartments.departmentName"
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                  value={department.name}
                                  onChange={handleChange(index)}
                                />
                                <p className="field-error">
                                  {department.name.length > 20
                                    ? t(
                                        "validationMessages.articles.addArticle.maxHeader",
                                        { max: 20 }
                                      )
                                    : ""}
                                  &nbsp;
                                </p>
                              </Grid>
                              <Grid item xs={1} className="text-center">
                                <IconButton
                                  aria-label="delete current item"
                                  className="hover-item"
                                  onClick={() => handleNotificationOpen(index)}
                                >
                                  <IconComponent icon={DeleteIcon} />
                                </IconButton>
                              </Grid>
                            </>
                          }
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    xs={7}
                    className="add-item-box hover-item"
                    onClick={() => handleAddItem()}
                  >
                    <AddIcon />
                    <span>{t("settings.myCommunity.addDepartment")}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="main-wrapper main-wrapper-departments">
            <Grid
              container
              className="content-grid"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} className="column-headers">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        md={3}
                        lg={2}
                        className="column-name item-margin"
                      >
                        <span className="segmentation-title">
                          {`${t("settings.manageDepartments.segmentation")} 2`}
                        </span>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  </Grid>
                  {secondDepartmentList?.map((department, index) => {
                    return (
                      <Grid key={index} item xs={12} className="item-box">
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {
                            <>
                              <Grid
                                item
                                xs={4}
                                md={3}
                                lg={2}
                                className="item-margin"
                              >
                                <TextField
                                  id="department-2"
                                  label={t("community.list.name")}
                                  placeholder={t(
                                    "settings.manageDepartments.departmentName"
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                  value={department.name}
                                  onChange={handleChange(index, true)}
                                />
                                <p className="field-error">
                                  {department.name.length > 20
                                    ? t(
                                        "validationMessages.articles.addArticle.maxHeader",
                                        { max: 20 }
                                      )
                                    : ""}
                                  &nbsp;
                                </p>
                              </Grid>
                              <Grid item xs={1} className="text-center">
                                <IconButton
                                  aria-label="delete current item"
                                  className="hover-item"
                                  onClick={() => {
                                    setIsDeleteSecondary(true);
                                    handleNotificationOpen(index);
                                  }}
                                >
                                  <IconComponent icon={DeleteIcon} />
                                </IconButton>
                              </Grid>
                            </>
                          }
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    xs={7}
                    className="add-item-box hover-item"
                    onClick={() => handleAddItem(true)}
                  >
                    <AddIcon />
                    <span>{t("settings.myCommunity.addDepartment")}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="submit-buttons">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              isBtnClicked ||
              secondDepartmentList?.filter(
                (el) => !el.name || el.name.length > 20
              ).length > 0 ||
              departmentList?.length === 0 ||
              departmentList?.filter((el) => !el.name || el.name.length > 20)
                .length > 0
            }
          >
            {t("common.buttons.save")}
          </Button>
        </div>
      </form>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose(false)}
        handleButtonClick={handleNotificationClose(itemDeletion)}
        buttonText={buttonText}
        message={notificationMessage}
      />
      {isLoading && <Spinner />}
    </div>
  );
};

export default observer(ManageDepartments);
