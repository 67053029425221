import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { users } from "../../../db/db";
import "./style.scss";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import { observer } from "mobx-react-lite";
import Spinner from "../../../components/Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import PlatformSettings from "../../../models/PlatformSettings";

const InviteToApp = () => {
  const { i18n, t } = useTranslation();
  const { languageStore, usersPageStore, userStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  // Email
  const [emailTitle, setEmailTitle] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [emails, setEmails] = useState("");

  const [areEmailsInvalid, setAreEmailsInvalid] = useState(false);
  // Phone
  const [phoneMessage, setPhoneMessage] = useState("");
  const [phones, setPhones] = useState("");

  const [arePhonesInvalid, setArePhonesInvalid] = useState(false);

  const [copyLinkText, setCopyLinkText] = useState("");
  const displayLinkText = "Android/iOS";

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const platform = userStore.getPlatformSettings();
    let linkText = "Android: https://play.google.com/store/apps/details?id=com.ls.communities\r\niOS: https://apps.apple.com/us/app/הוועד-שלי/id1602810749";
    if (platform === PlatformSettings.communities) {
        linkText = "Android: https://play.google.com/store/apps/details?id=com.ls.communities.general\r\niOS: https://apps.apple.com/gw/app/%D7%A7%D7%94%D7%99%D7%9C%D7%95%D7%AA/id1580382858";
    }
    setCopyLinkText(linkText);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const dir = i18n.dir();
    setLngDirection(dir);
    setTimeout(() => {
      setIsLoading(false);
    })
  }, [languageStore.switchLng]);

  const textInputDirection = () => {
    if (lngDirection === "rtl") return "rtl";
    if (lngDirection === "ltr") return "ltr";
  };

  const handleEmailSend = async () => {
    setIsLoading(true);
    const data = {
      title: emailTitle,
      message: emailMessage,
      emails: emails.replace(/\s/g, "").split(",").filter((v) => v.length),
    };
    await flowResult(usersPageStore.inviteThroughEmail(data)).then(
      (response: any) => {
        handleNotificationOpen(
          response.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0")
        );
      }
    );
    setIsLoading(false);
  };

  const handleSmsSend = async () => {
    setIsLoading(true);
    const data = {
      message: phoneMessage,
      phones: phones.replace(/\s/g, "").split(","),
    };
    await flowResult(usersPageStore.inviteThroughSMS(data)).then(
        (response: any) => {
            handleNotificationOpen(
              response.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0")
            );
          }
        );
    setIsLoading(false);
  };

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(copyLinkText);
    handleNotificationOpen(t("errorMessages.copiedLink"));
  };
  
    const handleCopyLinkRegister = (isOpen = false) => {
        const community = JSON.parse(localStorage.getItem('community')) ?? ''
        const community_id = community?.id ?? '';
        const community_name = community?.communityName ?? '';

        if (!community_id || !community_name) { 
            handleNotificationOpen(`יש לבחור קהילה כדי לייצר קישור הרשמה לקהילה`);
            return;
        }
        
        const origin = window.location.origin;
        const communities = userStore.getPlatformSettings();
        const LinkRegister = `${origin}/${communities}/register?community_id=${community_id}&community_name=${community_name}`
        navigator.clipboard.writeText(LinkRegister);
        
        if (isOpen) {
            window.open(LinkRegister, '_blank');
            return;
        }

        handleNotificationOpen(`${t("errorMessages.copiedLink")} \n ${LinkRegister}`);
    };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^\+?(972\-?)?0?(([23489]\-?\d{7})|[5]\d\-?\d{7})$/; 
    return regex.test(String(phone).toLowerCase().trim());
  };

    return isLoading ? (<Spinner />) : (
        <div className="main-wrapper invite-to-app">
            <h2 className="page-heading">
                {t('users.inviteToApp.title')}
            </h2>
            <Grid
                container
                spacing={4}
                className="page-wrap"
                justifyContent="center"
            >
                <Grid item xs={4} lg={3}>
                    <Card className="card-wrapper">
                        <div className="title-image" style={{ backgroundImage: `url(${users.invite.email.image})` }} />
                        <CardContent className="card-content">
                            <h3 className="title">{t('users.inviteToApp.sendEmail')}</h3>
                            <form noValidate autoComplete="off">
                                <div className="form-item">
                                    <TextField
                                        label={t('users.inviteToApp.labelTitle')}
                                        className="email-title"
                                        value={emailTitle}
                                        onChange={(e) => setEmailTitle(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { color: "#577bf9", fontWeight: "bold", fontSize: 16, transform: "translate(0, 1.5px)" }
                                        }}
                                        id="title-name"
                                        placeholder={t('users.inviteToApp.titleName')}
                                    />
                                </div>
                                <div className="form-item">
                                    <Editor
                                        value={emailMessage}
                                        onEditorChange={(value) => setEmailMessage(value)}
                                        toolbar='styleselect align'
                                        init={{
                                            directionality: textInputDirection(),
                                            placeholder:t("users.inviteToApp.emailContent"),
                                            height: 200,
                                            width: '100%',
                                            menubar: false,
                                            toolbar: 'bold italic underline strikethrough | alignleft aligncenter alignright | fontselect fontsizeselect ',
                                            toolbar_mode: 'sliding',
                                            toolbar_location: "right",
                                            content_style: `body { ${
                                                lngDirection === "rtl"
                                                  ? "text-align: right;"
                                                  : "text-align: left;"
                                              }
                                              overflow-x: hidden; }`,
                                            }}
                                    />
                                </div>
                                <div className="form-item padding-top-20">
                                    <TextField
                                        variant="outlined"
                                        label={t('users.inviteToApp.emails')}
                                        value={emails}
                                        onChange={(e) => {
                                            setAreEmailsInvalid(false)
                                            const value = e.target.value;
                                            value.replace(/\s/g, '').split(',').forEach(email => {
                                                if (!validateEmail(email) && email.replace(/\s/g, '') !== '') {
                                                    setAreEmailsInvalid(true)
                                                }
                                            })
                                            setEmails(value)
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { color: "#577bf9", fontWeight: "bold", fontSize: 16 },
                                        }}
                                        id="emails"
                                        multiline
                                        placeholder={t('users.inviteToApp.emailsComma')}
                                        rows={8}
                                        className="multiline"
                                    />
                                    <p className="field-error">{areEmailsInvalid ? t("validationMessages.auth.signUp.email") : " "}&nbsp;</p>
                                </div>
                                <Button variant="contained" color="primary" className="action-button bolder-font"
                                    onClick={handleEmailSend}
                                    disabled={!emailTitle.length || !emailMessage.length || !(emails.length && !areEmailsInvalid)}
                                >
                                    {t('common.buttons.send')}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} lg={3}>
                    <Card className="card-wrapper">
                        <div className="title-image" style={{ backgroundImage: `url(${users.invite.whatsApp.image})` }} />
                        <CardContent className="card-content">
                            <h3 className="title">{t('users.inviteToApp.sendSMS')}</h3>
                            <form noValidate autoComplete="off">
                                <div className="form-item" style={{ height: 48 }}>

                                </div>
                                <div className="form-item">
                                    <Editor
                                        value={phoneMessage}
                                        onEditorChange={(value: string) => setPhoneMessage(value)}
                                        toolbar='styleselect align'
                                        outputFormat="text"
                                        init={{
                                            directionality: textInputDirection(),
                                            placeholder:t("users.inviteToApp.messageContent"),
                                            height: 200,
                                            width: '100%',
                                            menubar: false,
                                            toolbar_mode: 'sliding',
                                            content_style: `body { ${
                                                lngDirection === "rtl"
                                                  ? "text-align: right;"
                                                  : "text-align: left;"
                                              }
                                              overflow-x: hidden; }`,
                                            }}
                                    />
                                </div>
                                <div className="form-item padding-top-20">
                                    <TextField
                                        variant="outlined"
                                        label={t('signUp.fields.phone.label')}
                                        value={phones}
                                        onChange={(e) => {
                                            setArePhonesInvalid(false)
                                            const value = e.target.value;
                                            value.split(',').forEach(phone => {
                                                if (!validatePhone(phone) && phone.replace(/\s/g, '') !== '') {
                                                    setArePhonesInvalid(true)
                                                }
                                            })
                                            setPhones(value)
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { color: "#577bf9", fontWeight: "bold", fontSize: 16 }
                                        }}
                                        id="phones"
                                        multiline
                                        placeholder={t('users.inviteToApp.phoneComma')}
                                        rows={8}
                                        className="multiline"
                                    />
                                    <p className="field-error">{arePhonesInvalid ? t("validationMessages.users.inviteToApp.phonePattern") : ''}&nbsp;</p>
                                </div>
                                <Button variant="contained" color="primary" className="action-button bolder-font"
                                onClick={handleSmsSend}
                                disabled={!phoneMessage.length || !phones.length|| !(phones.length && !arePhonesInvalid)}
                                >
                                    {t('common.buttons.send')}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} lg={3} className="d-flex">
                    <Card className="card-wrapper">
                        <div className="title-image" style={{ backgroundImage: `url(${users.invite.link.image})` }} />
                        <CardContent className="card-content">
                            <h3 className="title">{t('users.inviteToApp.copyLink')}</h3>
                            <form noValidate autoComplete="off">
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#577bf9", fontWeight: "bold", fontSize: 16, transform: "translate(0, 1.5px)" }
                                    }}
                                    id="link"
                                    label={t('users.inviteToApp.link')}
                                    value={displayLinkText}
                                    className="singleline"
                                    disabled
                                    />
                                <Button variant="contained" color="primary" className="action-button bolder-font" onClick={handleCopyLink}>{t('users.inviteToApp.copy')}</Button>
                            </form>
                            
                            <form noValidate autoComplete="off" style={{marginTop: 30}}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#577bf9", fontWeight: "bold", fontSize: 16, transform: "translate(0, 1.5px)" }
                                    }}
                                    id="link"
                                    label={'קישור הרשמה לקהילה'}
                                    value={'קישור למילוי פרטים והרשמה לקהילה'}
                                    className="singleline"
                                    disabled
                                    />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button variant="contained" color="primary" className="action-button bolder-font" onClick={() => handleCopyLinkRegister()}>
                                        {t('users.inviteToApp.copy')}
                                    </Button>
                                    <Button variant="contained" color="primary" className="action-button bolder-font" onClick={() => handleCopyLinkRegister(true)}>
                                        {'פתח'}
                                    </Button>
                                </div>
                            </form>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
            <NotificationModal openModal={openNotificationModal} buttonText={t('common.buttons.close')}
                handleClose={() => setOpenNotificationModal(false)} handleButtonClick={() => setOpenNotificationModal(false)}
                message={notificationMessage} />
        </div>
    )
}

export default observer(InviteToApp);
