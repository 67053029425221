import React from 'react';
import { Button, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';

interface Props {
    openModal: boolean;
    handleButtonClick: any;
    handleClose: any;
    className?: string;
    message: string;
    buttonText?: string;
    hideCloseIcon?: boolean;
    cancelButtonText?: string;
}

const NotificationModal = (props: Props) => {
    return (
        <div>
            <Modal
                open={props.openModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={`modal-preview notification-modal ${props.className}`}
            >
                <div className="notification-container">
                    <div className="message-container">
                        {
                            props?.message?.indexOf('. ') === -1
                            ? (<p>{props.message}</p>) 
                            : (
                                props?.message.split('. ').map(el => {
                                    return (<p>{el}{el.indexOf('.') === -1 ? '.' : ''}</p>)
                                })
                            )
                        }
                    </div>
                    <div className="submit-button">
                        <Button variant="contained" color="primary" onClick={props.handleButtonClick}>{props.buttonText}</Button>
                        {
                            props?.cancelButtonText ? (<Button variant="contained" color="primary" onClick={props.handleClose}>{props.cancelButtonText}</Button>) : null
                        }
                    </div>
                    {
                        !props?.hideCloseIcon && 
                            (
                                <div className="close-container">
                                    <CloseIcon className="icon-close" onClick={props.handleClose} />
                                </div>
                            )
                    }
                </div>
            </Modal>
        </div>
    )
}

export default NotificationModal
