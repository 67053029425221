import {
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import React from 'react';
import { useTranslation } from "react-i18next";
import { getElementFromArrayObject } from "../../shared/utils/sharedFunctions";

interface MultiSelectInterface {
    value: string[];
    hasError?: boolean;
    showSelectAll?: boolean;
    isSelectAllSelected?: boolean;
    onChange?: any;
    mapBy?: string;
    renderedName?: string;
    selectOptions: Object[];
    isDisabled?: boolean;
}

const MultiSelect = (props: MultiSelectInterface) => {
    const { t } = useTranslation();
    return (
        <Select
            id="select-community"
            className={`select-type multi-select-type ${props.hasError ? 'error-field' : ''}`}
            value={[].concat(props.value)}
            onChange={(e: any) => {
                const eValue = e.target.value;
                if (eValue.findIndex(el => el === 'select-all') >= 0 && !props.isSelectAllSelected) {
                    props.onChange(props.selectOptions.map((el: any) => el[props.mapBy]), true)
                } else {
                    if (props.isSelectAllSelected
                        && eValue.filter(el => el !== 'select-all').length === props.selectOptions.length) {
                        props.onChange([], false)
                    } else {
                        const newArray = eValue.filter((el: any) => {
                            const index = props.selectOptions.findIndex(find => find[props.mapBy] === el);
                            return index >= 0;
                        })
                        props.onChange(newArray, newArray.length === props.selectOptions.length)
                    }
                }
            }}
            multiple
            renderValue={(value: any) => {
                const renderValue = value.filter(el => el !== 'select-all');
                return renderValue.map((el, index) => {
                    if (index < 4) {
                        const name = getElementFromArrayObject(props.mapBy, props.renderedName, el, props.selectOptions);
                        return name ? (name + (index !== renderValue.length - 1 ? (index < 3 ? ', ' : '...') : '')) : null
                    }
                    return ""
                })
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }}
            disabled={props.isDisabled}
        >
            {
                (props.selectOptions.length > 0 && props?.showSelectAll) && (
                    <MenuItem value='select-all'>
                        <FormControlLabel
                            style={{ pointerEvents: "none" }}
                            control={
                                <Checkbox
                                    style={{ pointerEvents: "auto" }}
                                    color="primary"
                                    checked={props.isSelectAllSelected}
                                />
                            }
                            label={t("multiSelectLabels.selectAll")}
                        />
                    </MenuItem>
                )
            }
            {
                props.selectOptions.map((element, index) => {
                        return (<MenuItem key={index} value={element[props.mapBy]}>
                            <FormControlLabel
                                style={{ pointerEvents: "none" }}
                                control={
                                    <Checkbox
                                        style={{ pointerEvents: "auto" }}
                                        color="primary"
                                        checked={props.value.indexOf(element[props.mapBy]) !== -1}
                                    />
                                }
                                label={element[props.renderedName]}
                            />
                        </MenuItem>)
                    })
            }
        </Select>
    )
}

export default MultiSelect