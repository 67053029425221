export const APP_VERSION = "1.0.4";
export const FIREBASE_API_KEY_VAAD =  "AIzaSyDFmfqt24WzuxeDVGwh_yrK1kDtvNdfkX4";
export const FIREBASE_API_KEY_GENERAL = "AIzaSyAGgCb57LbyIr8KWKZWE8zmKHhalbhmnAI";

export const ANDROID = {
  packageName: `com.ls.communities`,
};
export const IOS = {
  bundleId: `com.ls.communities`,
};
