import i18n from "../../i18n/i18n";
import UserTypeEnum from "../../models/UserType";
import { DateRange } from "materialui-daterange-picker";

// Local time to Zulu Time
export function convertLocalToZuluTime(date) {
  const dateAndTime = new Date(new Date(date).getTime() - (new Date(date).getTimezoneOffset() * 60000)).toISOString();
  return dateAndTime;
}

// Zulu time to Local Time
export function convertZuluToLocalTime(date) {
  const dateAndTime = new Date(new Date(date).getTime() + (new Date(date).getTimezoneOffset() * 60000)).toISOString();
  return dateAndTime;
}

// Community Date Format
export function convertToDate(date, type = "", format = "") {
  if (!date) {
    return date;
  }
  let locale = "en-US";
  if (type === "preview") {
    date = new Date(date);
    locale = localStorage.getItem("i18nextLng") === "he" ? "he-IL" : "en-US";
  }
  if (format === 'DD/MM') {
    const option1 = { day: "numeric" };
    const option2 = { month: "numeric" };
    return `${String(date.toLocaleDateString(locale, option1)).padStart(2, "0")}.${String(date.toLocaleDateString(locale, option2)).padStart(2, "0")}`;
  } else if (format === 'HH:MM') {
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  } else {
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  }
};

export function convertToDate1(date, type = ""){
  if (!date) {
    return;
  }
  date = new Date(date);
  const locale = i18n.dir() === "rtl" ? "he-IL" : "en-US";
  const option1 = { day: "numeric" };
  const option2 = { month: "long" };
  const option3 = { year: "numeric" };

  return `${date.toLocaleDateString(
    locale,
    option1
  )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
    locale,
    option3
  )}`;
};

// Capital Letters
export function stringToCapital(text) {
  if (text && text.length > 1) {
    text = text
      .toLowerCase()
      .split(" ")
      .map((el) => {
        return el?.charAt(0).toUpperCase() + el?.slice(1);
      })
      .join(" ");
    return text;
  }
  return text;
};

// Format phone number
export function formatToPhoneNumber(value, previousValue) {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 10)}`;
  }
  return value;
};

export function getElementFromArrayObject(findIndexBy, findKeyBy, value, array) {
  const index: number = array.findIndex((el: any) => el[findIndexBy] === value);
  if (index >= 0) {
    return array[index][findKeyBy]
  }
  return '';
};

export function exportToExcel(base64, fileName) {
  const link = document.createElement('a');
  link.download = `${fileName}.xlsx`;
  link.href = `data:application/vnd.openxmlformats;base64,${base64}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function validatePhone(phone) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\+?(972\-?)?0?(([23489]\-?\d{7})|[5]\d\-?\d{7})$/;
  return regex.test(String(phone).toLowerCase().trim());
};

export function formatAppearsIds(appears, roleType) {
  return Array.from(
    new Set(
      appears
        ?.map((el) =>
          roleType === UserTypeEnum.owner
            ? el.groupId
            : [el.departmentId, el.secondaryDepartmentId]
        )
        .flat()
        .filter(Boolean)
    )
  );
}

export function mapDateForHttpReq({ startDate, endDate }: DateRange){
  const sDate = new Date(startDate);
  const eDate = new Date(endDate);

  const locale = "en-US";

  const from_date = `${sDate.toLocaleDateString(locale, {
    year: "numeric",
  })}-${sDate.toLocaleDateString(locale, {
    month: "numeric",
  })}-${sDate.toLocaleDateString(locale, {
    day: "numeric",
  })}`;

  const to_date = `${eDate.toLocaleDateString(locale, {
    year: "numeric",
  })}-${eDate.toLocaleDateString(locale, {
    month: "numeric",
  })}-${eDate.toLocaleDateString(locale, {
    day: "numeric",
  })}`;
  return { from_date, to_date };
};

export const handlePlusMinus = (item, setItem) => (action) => {
  if (action === 'increase' && item < 999999) setItem(item + 10);
  if (action === 'decrease' && item > 0) setItem(item - 10);
};