import { DraftComponentProps } from "../pages/DashBoard/DraftsListComponent/DraftComponent/DraftComponent";

import FirstImg from "../assets/images/draft/fir-draft-img.png"
import SecImg from "../assets/images/draft/sec-draft-img.png"
import TherImg from "../assets/images/draft/ther-draft-img.png"
import FourImg from "../assets/images/draft/four-draft-img.png"

import FirstImgEvent from "../assets/images/events/event-first-pic.png";
import SecImgEvent from "../assets/images/events/event-sec-pic.png";
import TherImgEvent from "../assets/images/events/event-ther-pic.png";
import FourImgEvent from "../assets/images/events/event-four-pic.png";

import LoggedUserAvatar from "../assets/images/user.png";
import NoMessages from "../assets/images/noMessages.png";
import NoVacation from "../assets/images/vacation.png";
import NoApproval from "../assets/images/noApproval.png";
import NoInsurances from "../assets/images/insurance.png";
import NoUsers from "../assets/images/noUsers.png";

import { EventItemComponentProps } from "../pages/DashBoard/CurrentWeekEventList/EventItem/EventItem";
import { PieChartInfo } from "../shared/components/PieChart/PieChart";

import User_1 from "../assets/images/users/user_1.png";
import User_2 from "../assets/images/users/user_2.png";
import User_3 from "../assets/images/users/user_3.png";
import User_5 from "../assets/images/users/user_5.png";

import InviteWithEmail from "../assets/images/sendEmail.png";
import InviteWithLink from "../assets/images/copyLink.png";
import InviteWhatsApp from "../assets/images/sendWhatsApp.png";

import ImportFromFile from "../assets/images/importUsers.png"

import { ActivityItem } from "../pages/DashBoard/LatestActivitiesComponent/ActivityItemComponent/ActivityItemComponent";

export const loggedUser = {
    avatar: LoggedUserAvatar,
    communities: ["Yes. Union workers", "Dog lovers", "Ice-cream forever"]
}

export const users = {
    list: [
        {
            id: 1,
            avatar: User_1,
            firstName: 'Leopold',
            lastName: 'Marvin',
            email: 'test@gmail.com',
            phone: '052-368-785',
            community: 'Community',
            department: 'Department',
            type: 'Editor',
            downloadApp: true,
            active: true,
            isSelect: false,
        },
        {
            id: 2,
            avatar: User_2,
            firstName: 'John',
            lastName: 'Doe',
            email: 'john123@gmail.com',
            phone: '052-999-999',
            community: 'Community',
            department: 'Department',
            type: 'User',
            downloadApp: false,
            active: false,
            isSelect: false,
        },
        {
            id: 3,
            avatar: User_3,
            firstName: 'Pattie',
            lastName: 'Lemke',
            email: 'pattie99@yahho.com',
            phone: '052-666-666',
            community: 'Community',
            department: 'Department',
            type: 'Chief Editor',
            downloadApp: false,
            active: true,
            isSelect: false,
        },
        {
            id: 4,
            avatar: User_5,
            firstName: 'Jasmine',
            lastName: 'Eliver',
            email: 'eliver@gmail.com',
            phone: '052-444-444',
            community: 'Community',
            department: 'Department',
            type: 'User',
            downloadApp: false,
            active: true,
            isSelect: false,
        }, {
            id: 5,
            avatar: User_2,
            firstName: 'Tessie',
            lastName: 'Lastname',
            email: 'tessielastname@gmail.com',
            phone: '052-899-975',
            community: 'Community',
            department: 'Department',
            type: 'Editor',
            downloadApp: true,
            active: true,
            isSelect: false,
        },
        {
            id: 6,
            avatar: User_3,
            firstName: 'Tomar',
            lastName: 'Lastname',
            email: 'tomar@gmail.com',
            phone: '052-899-975',
            community: 'Community',
            department: 'Department',
            type: 'User',
            downloadApp: false,
            active: false,
            isSelect: false,
        },
        {
            id: 7,
            avatar: User_1,
            firstName: 'FirstName',
            lastName: 'Lastname',
            email: 'firstName@gmail.com',
            phone: '052-899-975',
            community: 'Community',
            department: 'Department',
            type: 'User',
            downloadApp: false,
            active: false,
            isSelect: false,
        }
    ],
    importFromFile: {
        image: ImportFromFile
    },
    approvalList: [
        {
            id: 1,
            email: 'testEmail@gmail.com',
            by: 'Link',
            date: new Date(),
            community: 'Community',
        },
        {
            id: 2,
            email: 'johnDoe@yahoo.com',
            by: 'Email',
            date: new Date(),
            community: 'Community',
        },
        {
            id: 3,
            email: 'johnDoeOtherEmail@gmail.com',
            by: 'Link',
            date: new Date(),
            community: 'Community',
        },
        {
            id: 4,
            email: 'somethingNew@gmail.com',
            by: 'Link',
            date: new Date(),
            community: 'Community',
        }
    ],
    invite: {
        email: {
            image: InviteWithEmail
        },
        link: {
            image: InviteWithLink
        },
        whatsApp: {
            image: InviteWhatsApp
        }
    }
}

export const communityUsers = [
    {
        id: 1,
        firstName: 'Ilene',
        lastName: 'Wisoky',
        phone: '423-0084-1432',
        email: 'ilene.wisoky@website.com',
        canRemove: true,
    },
    {
        id: 2,
        firstName: 'Murphy',
        lastName: 'Morissete',
        phone: '423-1234-1432',
        email: 'murphy.morissete@website.com',
        canRemove: false,
    },
    {
        id: 3,
        firstName: 'Nicolette',
        lastName: 'Hane',
        phone: '321-0084-1432',
        email: 'nic.hane@website.com',
        canRemove: true,
    },
    {
        id: 4,
        firstName: 'Florencio',
        lastName: 'Wat',
        phone: '333-1284-1432',
        email: 'flor.wat@website.com',
        canRemove: true,
    },
]

export const noContent = {
    messages: NoMessages,
    vacation: NoVacation,
    insurance: NoInsurances,
    users: NoUsers,
    noApproval: NoApproval
}

export const draftsList: DraftComponentProps[] = [
    { imgSrc: FirstImg, tag: 'News', shortInfo: 'Assumenda tempore facere consequatur quia laudantium aut inventore aspernatur.' },
    { imgSrc: SecImg, tag: 'News', shortInfo: 'Architecto nisi deserunt atque aut quod dolorem ad asperiores corporis.' },
    { imgSrc: TherImg, tag: 'News', shortInfo: 'Culpa ad temporibus omnis laboriosam aut voluptatem dolorem est molestias.' },
    { imgSrc: FourImg, tag: 'News', shortInfo: 'Eveniet qui voluptates officia.' }
]
export const eventsList: EventItemComponentProps[] = [
    { imgSrc: FirstImgEvent, title: 'Imagine Dragons Live!', totalTickets: 35, soldTickets: 23 },
    { imgSrc: SecImgEvent, title: 'Imagine Dragons Live!', totalTickets: 35, soldTickets: 15 },
    { imgSrc: TherImgEvent, title: 'Imagine Dragons Live!', totalTickets: 35, soldTickets: 32 },
    { imgSrc: FourImgEvent, title: 'Imagine Dragons Live!', totalTickets: 35, soldTickets: 4 }
]
export const chartData: PieChartInfo = {
    legentInfo: [
        { amountInPrecent: '10%', label: 'Trump pardons Roger Stone, Paul Manafort, Charles Kushner and others', color: '#577BF9' },
        { amountInPrecent: '30%', label: 'Accusations of racism at Google grow louder after second top Black woman pushed out', color: '#00208D' },
        { amountInPrecent: '50%', label: "How Washington's new Covid bill risks the futures of millions of American children", color: '#36E0F6' },
        { amountInPrecent: '10%', label: 'See the most powerful and inspiring images of 2020', color: '#EFDA1F' }
    ],
    data: [10, 30, 50, 10]
}

export const latestActivityList: ActivityItem[] = [
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_1, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_2, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_3, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: 'Revivo Sharon', imgSrc: '', activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' },
    // { fullName: '', imgSrc: User_5, activityInfo: 'Leo added a comment to “Assumenda tempore facere consequatur”' }
]

export const messagesList = {
    myMessages: [],
    allMessages: [
        {
            date: "Today, Sep 20, 2020",
            messages: [
                {
                    new: true,
                    user: {
                        avatar: User_1,
                        name: 'Leo'
                    },
                    content: "Leo added a comment to 'Assumenda tempore facere consequatur'"
                },
                {
                    new: true,
                    user: {
                        avatar: User_2,
                        name: 'Leo'
                    },
                    content: "Happy New Year! Choose your gift here"
                },
                {
                    new: false,
                    user: {
                        avatar: User_3,
                        name: 'Leo'
                    },
                    content: "Imagine Dragons live today!"
                }
            ]
        },
        {
            date: "Sep 19, 2020",
            messages: [
                {
                    new: false,
                    user: {
                        avatar: User_5,
                        name: 'Kailiee Jenkins'
                    },
                    content: "Kailiee Jenkins likes an article you published"
                }
            ]
        }
    ]
}


