import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  PHONE: "phone",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.PHONE]: Yup.string()
    .required(() => i18n.t("validationMessages.auth.signUp.required"))
    .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () =>
      i18n.t("validationMessages.auth.signUp.phonePattern")
    )
    .min(11, () => i18n.t("validationMessages.auth.signUp.phone"))
    .max(11, () => i18n.t("validationMessages.auth.signUp.phone")),
});
