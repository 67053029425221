import React from "react";
import "./IconComponent.scss";
export interface ButtonProps {
  icon: any;
}
const IconComponent = (props: ButtonProps) => {
  return (
    <div className="icon">
      <img src={props.icon} alt="" />
    </div>
  );
};

export default IconComponent;
