import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class CreateStore {
    root: RootStore;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    * createArticle(payload: any) {
        const response = yield Api.post(`/admin/article`, payload);
        return response?.data;
    }
}
