import React, { useState, useEffect } from 'react'
import {
    Grid,
    Link as MuiLink,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TermsAndPrivacyPolicy from '../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy';
import LanguageSelection from '../../../components/LanguageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/Spinner/Spinner';

const SignIn = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [windowHeight, setWindowHeight] = useState("");

    useEffect(() => {
        const windowHeight = window.outerHeight;
        if (windowHeight < 768) {
            setWindowHeight(`${windowHeight}px`);
        } else {
            setWindowHeight("100%")
        }
        setIsLoading(false);
    }, []);

    return isLoading ? (<Spinner />) : (
        <div className="scroll-container" style={{ height: windowHeight }}>
            <div className="sign-form-container">
                <div className="inner-full-container">
                    <div className="center-container">
                        <form className="sign-form">
                            <Grid container justifyContent="center" alignItems="center" direction="column" className="sign-container">
                                <Grid item xs={10} sm={6} md={5} lg={4}>
                                    <Grid container>
                                        <Grid item xs={12} className="sign-header">
                                            <div className="double-column">
                                                <div>
                                                    <h2>{t('common.forgotPassword')}</h2>
                                                    <h3>{t('common.remember')} <Link className="cursor-pointer link no-underline" to='/sign-in'>{t('common.signInLabel')}</Link></h3>
                                                </div>
                                                <LanguageSelection />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="sign-body">
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item xs={12} className="sign-image-container flex-center">
                                                    <div className="sign-image-and-icon">
                                                        <img src="https://d29fhpw069ctt2.cloudfront.net/icon/image/37722/preview.svg" alt="" className="sign-image" />
                                                    </div>

                                                </Grid>
                                                <Grid item xs={12} className="sign-field sent-message">
                                                    <h3>{t('emailSent.message1')}</h3>
                                                    <h3>{t('emailSent.message2')}</h3>
                                                    <h4>{t('emailSent.message3')}</h4>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <div className="terms-and-policy cursor-pointer">
                            <MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>{t('common.termsAndPrivacy')}</MuiLink>
                        </div>
                    </div>
                    <TermsAndPrivacyPolicy openModal={openModal} onClose={() => setOpenModal(false)} />
                </div>
            </div>
        </div>
    )
}

export default SignIn
