import React from 'react'
import "./CommunityIcom.scss"
import CmmunityUrl from "../../../assets/icons/logo.svg"
export interface CommunityIcomProps {
    url:string;
    className?: string;
}
const CommunityIcom = (props: CommunityIcomProps) => {
    return (
        <div className={`community-icom ${props?.className ?? ''}`}>
            <img src={CmmunityUrl} alt=""/>
        </div>
    )

}


export default CommunityIcom