import React from "react";
import { useGridSlotComponentProps } from "@material-ui/data-grid";
import { makeStyles, TablePagination } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    display: "flex",
    justifyContent: "flex-end",
    borderBottom: "unset",
  },
}));

const CustomPagination = () => {
  const { state, apiRef } = useGridSlotComponentProps();
  const classes = useStyles();
  return (
    <TablePagination
      component="div"
      className={classes.paginationRoot}
      count={state.pagination.rowCount}
      page={state.pagination.page}
      onPageChange={(event, value) => apiRef.current.setPage(value)}
      rowsPerPage={state.pagination.pageSize}
      rowsPerPageOptions={[]}
    />
  );
};

export default CustomPagination;
