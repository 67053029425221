import { Avatar, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import './style.scss';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import SideMenu from '../SideMenu/SideMenu';
import { useStore } from '../../../../store/StoreContext';
import { useTranslation } from 'react-i18next';
import CommunityLogo from "../../../../assets/icons/logo.svg"
import { stringToCapital } from '../../../../shared/utils/sharedFunctions';


const Header = () => {
    const { t } = useTranslation();
    const { userStore } = useStore();
    const history = useHistory();
    const [openSideMenu, setOpenSideMenu] = useState(false);

    const toggleSideMenu = () => setOpenSideMenu(!openSideMenu);

    const [currentCommunity, setCurrentCommunity] = useState<any>({});

    useEffect(() => {
        setCurrentCommunity(userStore.getCurrentCommunity())
    }, [userStore.currentCommunity])

    return (
        <>
            <div className="mobile-header">
                <Grid container justifyContent="space-evenly" alignItems="center" className="grid-container">
                    <Grid item xs={2} onClick={() => history.push('/mobile')} className="d-flex justify-center align-center">
                        {/* <CommunityIcom url={''} className="d-flex justify-center align-center logo-icon" /> */}
                        <Avatar
                            className={`d-flex justify-center align-center logo-icon ${!currentCommunity?.imageNavigationBarLogo ? 'filter' : ''}`}
                            src={currentCommunity?.imageNavigationBarLogo ?? CommunityLogo} alt="logo"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <div className="d-flex justify-center align-center">
                            <span className="community-name">{stringToCapital(currentCommunity?.communityName) ?? t('settings.general.companyName')}</span>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="d-flex justify-center align-center" onClick={toggleSideMenu}>
                            <MenuIcon className="action-icon" />
                        </div>
                    </Grid>
                </Grid>
                <SideMenu open={openSideMenu} handleClose={toggleSideMenu} />
            </div>
        </>
    )
}

export default Header
