import React from "react"
import ContentLoader from "react-content-loader";
import "./PieLoader.scss";

const PieLoader = (props: any) => {
  return (
          <div className={`loader-wrapper fade-in ${!props.isLoading && 'visible'}`}>
      <ContentLoader
        speed={2}
        width={500}
        height={450}
        viewBox="0 0 500 450"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title={''}
      >
        <rect x="307" y="34" rx="3" ry="3" width="146" height="6" />
        <rect x="0" y="2" rx="100" ry="100" width="212" height="212" />
        <rect x="307" y="54" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="95" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="116" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="172" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="154" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="214" rx="3" ry="3" width="146" height="6" />
        <rect x="307" y="235" rx="3" ry="3" width="146" height="6" />
      </ContentLoader>
      </div>
  )
}


export default PieLoader

