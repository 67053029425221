import React from 'react'


const AccountIcon = () => {

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.47446 23.331C11.0585 24.3852 12.9589 25 15 25C17.0411 25 18.9415 24.3852 20.5255 23.331C19.4755 21.3514 17.3926 20 15 20C12.6074 20 10.5244 21.3514 9.47446 23.331ZM7.55059 21.6643C5.96526 19.8941 5 17.5576 5 15C5 9.48625 9.48625 5 15 5C20.5138 5 25 9.48625 25 15C25 17.5576 24.0347 19.8941 22.4494 21.6643C20.9065 19.1673 18.1439 17.5 15 17.5C17.7575 17.5 20 15.2575 20 12.5C20 9.7425 17.7575 7.5 15 7.5C12.2425 7.5 10 9.7425 10 12.5C10 15.2575 12.2425 17.5 15 17.5C11.8561 17.5 9.09348 19.1673 7.55059 21.6643ZM2.5 15C2.5 8.1075 8.1075 2.5 15 2.5C21.8925 2.5 27.5 8.1075 27.5 15C27.5 21.8925 21.8925 27.5 15 27.5C8.1075 27.5 2.5 21.8925 2.5 15ZM17.5 12.5C17.5 11.1213 16.3787 10 15 10C13.6213 10 12.5 11.1213 12.5 12.5C12.5 13.8787 13.6213 15 15 15C16.3787 15 17.5 13.8787 17.5 12.5Z" fill="#577BF9" />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="26" height="26">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.47446 23.331C11.0585 24.3852 12.9589 25 15 25C17.0411 25 18.9415 24.3852 20.5255 23.331C19.4755 21.3514 17.3926 20 15 20C12.6074 20 10.5244 21.3514 9.47446 23.331ZM7.55059 21.6643C5.96526 19.8941 5 17.5576 5 15C5 9.48625 9.48625 5 15 5C20.5138 5 25 9.48625 25 15C25 17.5576 24.0347 19.8941 22.4494 21.6643C20.9065 19.1673 18.1439 17.5 15 17.5C17.7575 17.5 20 15.2575 20 12.5C20 9.7425 17.7575 7.5 15 7.5C12.2425 7.5 10 9.7425 10 12.5C10 15.2575 12.2425 17.5 15 17.5C11.8561 17.5 9.09348 19.1673 7.55059 21.6643ZM2.5 15C2.5 8.1075 8.1075 2.5 15 2.5C21.8925 2.5 27.5 8.1075 27.5 15C27.5 21.8925 21.8925 27.5 15 27.5C8.1075 27.5 2.5 21.8925 2.5 15ZM17.5 12.5C17.5 11.1213 16.3787 10 15 10C13.6213 10 12.5 11.1213 12.5 12.5C12.5 13.8787 13.6213 15 15 15C16.3787 15 17.5 13.8787 17.5 12.5Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
            </g>
        </svg>

    )

}


export default AccountIcon