import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/StoreContext';
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import ArticleBox from '../../../components/Mobile/ArticleBox/ArticleBox';
import { flowResult } from 'mobx';
import Spinner from '../../../components/Spinner/Spinner';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import UserTypeEnum from '../../../models/UserType';
import 'moment/locale/he';

const PushNotifications = () => {
    const { i18n, t } = useTranslation();
    const {
        groupStore,
        departmentStore,
        userStore,
        languageStore,
        pushStore,
        actionStore,
        mobileStore,
        settingsStore
    } = useStore();
    const [lngDirection, setLngDirection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [actionType, setActionType] = useState('');
    const [potentialEditRowID, setPotentialEditRowID] = useState('');
    const [filter, setFilter] = useState('all');
    const [groups, setGroups] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
    const [roleType, setRoleType] = useState(null);

    const [approvalList, setApprovalList] = useState<any>([]);
    const [sentList, setSentList] = useState<any>([]);
    const [toBeSentList, setToBeSentList] = useState<any>([]);

    useEffect(() => {
        setGroups(groupStore.getStoredGroups());
        setDepartments(departmentStore.getStoredDepartments());
        setSecondaryDepartments(departmentStore.getStoredSecondaryDepartments());
        setRoleType(userStore.getRoleType());
        getNotifications();
    }, []);

    // Detecting Language Switch
    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    const getNotifications = async () => {
        setIsLoading(true);
        await flowResult(pushStore.getNotifications({ limit: 10 })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(
                        response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                    );
                    return;
                }
                const data = response.pushes;
                if (data.length) {
                    const approval: any = [];
                    const sent: any = [];
                    const toBeSent: any = [];
                    data.forEach((push: any) => {
                        if (!push.isApproved) {
                            approval.push(push);
                        } else if (push.isPublished) {
                            sent.push(push);
                        } else {
                            toBeSent.push(push);
                        }
                    });
                    setApprovalList(approval);
                    setSentList(sent);
                    setToBeSentList(toBeSent);
                }
            }
        );
        setIsLoading(false);
    };

    const getRoleIndex = (role) => {
        return Object.keys(UserTypeEnum).indexOf(role);
    }

    const getPermission = (createdBy, roleType) => {
        if (createdBy) {
            let currentRoleIndex: number = getRoleIndex(roleType);
            const role = createdBy?.toLowerCase() ?? '';
            if (roleType === 'editor' && roleType === UserTypeEnum.editor) {
                currentRoleIndex = getRoleIndex('chiefEditor');
            }
            const rowRoleIndex: number = role === "chief_editor" ? getRoleIndex("chiefEditor") : getRoleIndex(role);
            return currentRoleIndex <= rowRoleIndex;
        }
        return true;
    };

    const openActionMenu = (data) => (event) => {
        const id = data.id;
        setPotentialEditRowID(id);
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        let options = ['view'];
        if (settingsStore.getUserPermissions().push) {
            const isPermitted = getPermission(data.createdBy, roleType);
            if (data?.isPublished) {
                options = isPermitted ? ['delete'] : ['view'];
            } else {
                options = isPermitted ? ['edit', 'view', 'delete'] : ['view'];
            }
        } else {
            if (data?.isPublished) {
                options = ['view'];
            }
        }
        actionStore.handleOpenPopActionMenu(currentTarget.firstChild, '', data, options);
    };

    const handleDelete = async (id = "") => {
        await flowResult(pushStore.delete(id ? id : potentialEditRowID)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        getNotifications();
    };

    const handleNotificationOpen = (message = '', actionType = "") => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (actionType) {
            setButtonText(t('common.buttons.confirm'));
            setActionType(actionType);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (actionType = "") => () => {
        if (actionType === "isDelete") {
            setIsLoading(true);
            handleDelete(potentialEditRowID);
            setActionType("");
        }
        setOpenNotificationModal(false);
    }

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }

        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const getSentTo = (appears) => {
        if (roleType === UserTypeEnum.owner) {
            appears = appears.filter((el, index) => appears.findIndex((item) => item.groupId === el.groupId) === index)
        }
        const sentToNames = [];
        appears.forEach((el) => {
            if (!el.groupId && !el.departmentId) {
                return;
            }
            if (roleType === UserTypeEnum.owner) {
                const id = el.groupId;
                const index = groups.findIndex((el: any) => el.id === id);
                if (index >= 0) {
                    sentToNames.push(groups[index].name);
                }
            } else {
                if(el.departmentId) {
                    const id = el.departmentId;
                    const index = departments.findIndex((el: any) => el.id === id);
                    if (index >= 0) {
                        sentToNames.push(departments[index].name);
                    }
                  }
                if(el.secondaryDepartmentId) {
                    const id = el.secondaryDepartmentId;
                    const index = secondaryDepartments.findIndex((el: any) => el.id === id);
                    if (index >= 0) {
                        sentToNames.push(secondaryDepartments[index].name);
                    }
                  }
            }
        });

        const allNames =  Array.from(new Set(sentToNames.filter(el => el))).join(", ");
        return allNames.length > 70 ? allNames.substring(0, 70) + '...' : allNames;
    };

    const handleApprove = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.approvePush(id)).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(
                        response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                    );
                    return;
                }
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        getNotifications();
    }

    const handleReject = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.rejectPush(id)).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(
                        response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                    );
                    return;
                }
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        getNotifications();
    }

    return isLoading ? <Spinner /> : (
        <div className={`article-list ${!(approvalList.length || toBeSentList.length || sentList.length) ? 'full-height' : ''}`}>

            {
                (approvalList.length || toBeSentList.length || sentList.length) ? (
                    <>
                        <div className="select-filter-container">
                            <FormControl>
                                <InputLabel id="select-filter">{t('common.fieldHeaders.status')}</InputLabel>
                                <Select
                                    labelId="select-filter"
                                    id="select-filter"
                                    className="select-preview-filter"
                                    value={filter}
                                    onChange={(e: any) => setFilter(e.target.value)}
                                >
                                    <MenuItem value='all'>{t('common.selectFilters.allMessages')}</MenuItem>
                                    <MenuItem value='waitingApproval'>{t('common.fieldHeaders.waitingApproval')}</MenuItem>
                                    <MenuItem value='sent'>{t('reports.sent')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {
                            (approvalList.length && (filter === 'all' || filter === 'waitingApproval')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('common.fieldHeaders.waitingApproval')} ({approvalList.length})</span>
                                    </div>
                                    {
                                        approvalList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            message: article.pushName,
                                                            sendTo: getSentTo(article.appears),
                                                            date: convertToDate(article.publishDate),
                                                            openRate: article.openRate,
                                                            isPublished: article.isPublished,
                                                            status: t('mobile.statuses.pending'),
                                                            isApproved: article.isApproved,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                    handleApprove={handleApprove}
                                                    handleReject={handleReject}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (toBeSentList.length && (filter === 'all')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('push.promoNotification.aboutToBeSent')} ({toBeSentList.length})</span>
                                    </div>
                                    {
                                        toBeSentList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            message: article.pushName,
                                                            sendTo: getSentTo(article.appears),
                                                            date: convertToDate(article.publishDate),
                                                            openRate: article.openRate,
                                                            isPublished: article.isPublished,
                                                            status: t('mobile.statuses.awaitingToBeSent'),
                                                            isApproved: article.isApproved,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                    handleApprove={handleApprove}
                                                    handleReject={handleReject}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (sentList.length && (filter === 'all' || filter === 'sent')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('reports.sent')} ({sentList.length})</span>
                                    </div>
                                    {
                                        sentList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            message: article.pushName,
                                                            sendTo: getSentTo(article.appears),
                                                            date: convertToDate(article.publishDate),
                                                            openRate: article.openRate,
                                                            isPublished: article.isPublished,
                                                            status: t('reports.sent'),
                                                            isApproved: article.isApproved,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                    handleApprove={handleApprove}
                                                    handleReject={handleReject}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            ((filter === 'waitingApproval' && !toBeSentList.length)
                                || (filter === 'sent' && !sentList.length)) ? (
                                <div className="no-items-container">
                                    <p className="no-items">{t('mobile.notifications.noContent')}</p>
                                </div>
                            ) : null
                        }
                    </>
                ) : (
                    <div className="no-items-container">
                        <p className="no-items">{t('mobile.notifications.noContent')}</p>
                    </div>
                )
            }
            <PopUpActionMenu
                handleAction={handleNotificationOpen}
                currentPage="mobile/notifications"
            />
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose(actionType)}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default PushNotifications
