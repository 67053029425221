import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  PUSH_NAME: "push_name",
  IMAGE: "image",
  APPEARS: "appears",
  PUBLISH_DATE: "publish_date",
  LINK: "link",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.PUSH_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.push.create.minHeader"))
    .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
    .required(() => i18n.t("validationMessages.push.create.required")),
  [FIELDS.LINK]: Yup.string()
    .url(() => i18n.t("validationMessages.push.create.url"))
    .notRequired(),
  [FIELDS.IMAGE]: Yup.string().notRequired(),
  [FIELDS.APPEARS]: Yup.array()
    .required(() => i18n.t("validationMessages.push.create.required"))
    .min(1,() => i18n.t("validationMessages.push.create.required")),
  [FIELDS.PUBLISH_DATE]: Yup.string(),
});
