import UserTypeEnum from '../../models/UserType';

interface tinyEditorOptionsInterface {
    menubar: string;
    plugins: string;
    toolbar: string;
}

export const tinyEditorOptions: tinyEditorOptionsInterface = {
    menubar: 'file edit view insert format tools table help',
    plugins: `print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons`,
    toolbar: `undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl`,
}

export const getPopUpActionOptions = (data, roleType, isPermitted = false) => {
    const getRoleIndex = (role) => {
        return Object.keys(UserTypeEnum).indexOf(role);
    }

    const getPermission = (createdBy, roleType) => {
        if (createdBy) {
            let currentRoleIndex: number = getRoleIndex(roleType);
            const role = createdBy?.toLowerCase() ?? '';
            if (roleType === 'editor' && isPermitted) {
                currentRoleIndex = getRoleIndex('chiefEditor');
            }
            const rowRoleIndex: number = role === "chief_editor" ? getRoleIndex("chiefEditor") : getRoleIndex(role);
            return currentRoleIndex <= rowRoleIndex;
        }
        return true;
    };
    
    let options: string[] = ['view'];
    const hasPermissions = getPermission(data.createdBy, roleType);
    const isPublished = !data.isArchived && !data.isDraft && data.isApproved;

    if (hasPermissions) {
        if (isPublished) return ['edit', 'view', 'duplicate', 'archive', 'copyLink'];
        else if (data.isDraft) return ['edit', 'delete'];
        else if (!data.isApproved) return ['edit', 'view', 'delete'];
        else if (data.isArchived) return ['unarchive', 'delete'];
    }
    return options;
}