import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Grid, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from '@material-ui/icons/Add';
import { useStore } from '../../../store/StoreContext';
import { flowResult } from 'mobx';
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import { useTranslation } from 'react-i18next';
import UserTypeEnum from '../../../models/UserType';
import "./style.scss";

const Permissions = () => {
    const { t } = useTranslation();
    const { userStore, settingsStore } = useStore();
    const path = 'permissions';

    const [communities, setCommunities] = useState<any>([]);
    const [currentCommunity, setCurrentCommunity] = useState<any>({})
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setCommunities(userStore.getCommunities());
        setCurrentCommunity(userStore.getCurrentCommunity());
        setRoleType(userStore.getRoleType());
    }, [])

    const [selectAll, setSelectAll] = useState(false);
    const [permissionsList, setPermissionsList] = useState<any>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const updateLists = () => {
        flowResult(settingsStore.getPathItems(path)).then(
            (response: any) => {
                setIsLoading(false);
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                    return;
                }
                let data;
                if (response['permissions']) {
                    data = [...response['permissions'].map(el => {
                        return {
                            id: el.id,
                            articles: el.articles,
                            ads: el.ads,
                            banners: el.banners,
                            benefits: el.benefits,
                            push: el.push,
                            reports: el.reports,
                            users: el.users,
                            checked: false,
                            isSelected: true,
                        }
                    })]
                }
                else {
                    data = []
                }
                setPermissionsList(data);
            }
        )
    }

    useEffect(() => {
        updateLists();
    }, [])

    const handleSelectChange = (itemIndex, value) => {
        const newObject = permissionsList.map((item, index) => {
            if (index === itemIndex) {
                return { ...item, id: value, isSelected: true }
            }
            return item
        });
        setPermissionsList(newObject)
    }

    const toggleTypeCheck = (itemIndex, type) => () => {
        const newObject = permissionsList.map((item, index) => {
            if (index === itemIndex) {
                return { ...item, [type]: !item[type] }
            }
            return item
        });
        setPermissionsList(newObject)
    }

    const handleCheckItem = (itemIndex) => () => {
        const newObject = permissionsList.map((item, index) => {
            if (index === itemIndex) return { ...item, checked: !item.checked }
            return item
        })
        setPermissionsList(newObject)
    }

    const handleAddItem = () => {
        let newData = {
            id: '',
            isNew: true,
            articles: false,
            ads: false,
            banners: false,
            benefits: false,
            push: false,
            reports: false,
            users: false,
            checked: false,
            isSelected: false,
        }
        if (roleType !== UserTypeEnum.owner) {
            newData = { ...newData, id: currentCommunity.id }
        }
        setPermissionsList([...permissionsList, newData])
    }

    const [buttonText, setButtonText] = useState('');
    const [itemDeletion, setItemDeletion] = useState('');
    const [itemIndex, setItemIndex] = useState(0);

    const handleNotificationOpen = (message = '', isDelete = '', index = -1) => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (isDelete) {
            setButtonText(t('common.buttons.yes'));
            setItemIndex(index)
            setItemDeletion(isDelete);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (isDelete = '') => () => {
        if (isDelete === 'DeleteOne') {
            handleDeleteItem(itemIndex);
            setItemDeletion('');
        }
        if (isDelete === 'DeleteSelected') {
            handleDeleteSelectItems();
        }
        setOpenNotificationModal(false);
    }

    const handleDeleteItem = async (itemIndex) => {
        const item = permissionsList[itemIndex];
        if (item.id && item.id !== 'placeholder' && !item?.isNew) {
            setIsLoading(true);
            await flowResult(settingsStore.delete(`permissions`, item.id)).then(
                (response: any) => {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                }
            )
        }
        setPermissionsList(permissionsList.filter((el, index) => index !== itemIndex));
    }

    const handleSelectAllItem = () => {
        setPermissionsList(permissionsList.map((item) => {
            return { ...item, checked: !selectAll }
        }))
        setSelectAll(!selectAll);
    }

    const handleDeleteSelectItems = async () => {
        permissionsList.forEach(async (el) => {
            if (el.checked && el.id) {
                setIsLoading(true);
                await flowResult(settingsStore.delete(`permissions`, el.id)).then(
                    (response: any) => {
                        setIsLoading(false);
                        handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                    }
                )
            }
        })
        setPermissionsList(permissionsList.filter((el => !el.checked)))
        setSelectAll(false);
    }

    const handleSubmitCheck = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        const perms = permissionsList.filter(el => el.id);
        let data = { permissions: [] };
        if (perms.length > 0) {
            data = {
                // @ts-ignore
                permissions: [...perms.map(el => {
                    return {
                        community_id: el.id,
                        is_articles: el.articles,
                        is_ads: el.ads,
                        is_banners: el.banners,
                        is_benefits: el.benefits,
                        is_push: el.push,
                        is_reports: el.reports,
                        is_users: el.users,
                    }
                })]
            }
        }
        await flowResult(settingsStore.update(data, path)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                if (!response?.success) {
                    return;
                }
                setPermissionsList(perms.map(el => {
                    return { ...el, isNew: false }
                }));
            }
        )
    }

    const getCommunityName = (id) => {
        if(id) {
            let name = '';
            const index = communities.findIndex(el => el.id === id);
            if (index >= 0) {
                name = communities[index].communityName;
            }
            return name;
        }
        return '';
    }

    return (
        <div className="main-wrapper permissions-page">
            <h2 className="page-heading">
                {t('tabNavigation.settings.permissions')}
            </h2>
            <form className="" autoComplete="off" onSubmit={handleSubmitCheck}>
                <div className="main-wrapper">
                    <Grid container className="content-grid" justifyContent="space-between">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} className="select-all">
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item xs={2} className="column-name">
                                            <IconButton aria-label="select all items" onClick={handleSelectAllItem}>
                                                <Checkbox checked={selectAll} color="primary" className="select-all-checkbox" />
                                            </IconButton>
                                            <span>{t('settings.permissions.selectAll')}</span>
                                        </Grid>
                                        <Grid item xs={1} className="double-icon">
                                            <Grid container>
                                                <Grid item xs={6} lg={5}>
                                                    <IconButton
                                                        aria-label="add item"
                                                        className="hover-item"
                                                        onClick={handleAddItem}
                                                        disabled={
                                                            roleType === UserTypeEnum.owner && permissionsList.length === communities.length
                                                            ||
                                                            (roleType === UserTypeEnum.associatedOwner 
                                                                ||
                                                                roleType === UserTypeEnum.admin) && permissionsList.length > 0
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={6} lg={5}>
                                                    <IconButton
                                                        aria-label="delete selected items"
                                                        className="hover-item"
                                                        onClick={() => handleNotificationOpen(t("errorMessages.deleteItems"), 'DeleteSelected')}
                                                        disabled={!(permissionsList.length > 0)}
                                                    >
                                                        <IconComponent icon={DeleteIcon} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    permissionsList.map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={12} className="item-box">
                                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={2} className="d-flex item-margin">
                                                        <IconButton aria-label="select item" onClick={handleCheckItem(index)} className="select-permission-checkbox">
                                                            <Checkbox checked={item.checked} color="primary" />
                                                        </IconButton>
                                                        {
                                                            roleType === UserTypeEnum.owner ? (
                                                                <>
                                                                {
                                                                    item?.isSelected ? (
                                                                        <div className="associated-community">
                                                                            <div className="label">{t('tabNavigation.community.mainTab')}</div>
                                                                            <div className="title">{getCommunityName(item.id)}</div>
                                                                        </div>
                                                                    ) : (
                                                                        <FormControl className="select-box">
                                                                            <InputLabel id="category-label" className="select-label">{t('tabNavigation.community.mainTab')}</InputLabel>
                                                                            <Select
                                                                                labelId="category-label"
                                                                                id={`category-${item.id}`}
                                                                                value={item.id}
                                                                                onChange={(e) => handleSelectChange(index, e.target.value)}
                                                                            >
                                                                                {
                                                                                    communities.map((com, comIndex) => {
                                                                                        if (com.id === item.id) {
                                                                                            return (<MenuItem key={comIndex} value={com.id}>{com.communityName}</MenuItem>)
                                                                                        }
                                                                                        for (let i = 0; i < permissionsList.length; i++) {
                                                                                            if (permissionsList[i].id === com.id) {
                                                                                                return null
                                                                                            }
                                                                                        }
                                                                                        return (<MenuItem key={comIndex} value={com.id}>{com.communityName}</MenuItem>)
                                                                                    })
                                                                                }
                                                                                {
                                                                                    !communities.filter(com => {
                                                                                        if (com.id === item.id) {
                                                                                            return true
                                                                                        }
                                                                                        for (let i = 0; i < permissionsList.length; i++) {
                                                                                            if (permissionsList[i].id === com.id) {
                                                                                                return false
                                                                                            }
                                                                                        }
                                                                                        return true
                                                                                    }).length ? (<p>{t('community.list.noCommunity')}</p>) : null
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    )
                                                                }
                                                                </>
                                                            ) : (
                                                                <div className="associated-community">
                                                                    <div className="label">{t('tabNavigation.community.mainTab')}</div>
                                                                    <div className="title">{currentCommunity?.communityName ?? ''}</div>
                                                                </div>
                                                            )
                                                        }

                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'ads')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.ads} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.articles.ads')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'banners')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.banners} color="primary" />
                                                        </IconButton>
                                                        <span>{t('settings.permissions.banners')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'benefits')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.benefits} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.benefits.mainTab')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'articles')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.articles} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.articles.mainTab')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'push')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.push} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.push.mainTab')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'reports')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.reports} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.reports.mainTab')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="flex-center">
                                                        <IconButton aria-label="select item" onClick={toggleTypeCheck(index, 'users')} className="select-permission-checkbox">
                                                            <Checkbox checked={item.users} color="primary" />
                                                        </IconButton>
                                                        <span>{t('tabNavigation.users.mainTab')}</span>
                                                    </Grid>
                                                    <Grid item xs={1} className="text-center">
                                                        <IconButton aria-label="delete current item" className="hover-item" onClick={() => handleNotificationOpen(t("errorMessages.deleteOne"), 'DeleteOne', index)}>
                                                            <IconComponent icon={DeleteIcon} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="submit-buttons">
                    <Button variant="contained" color="primary" type="submit" disabled={permissionsList.length === 0}>{t('common.buttons.save')}</Button>
                </div>
            </form>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose(itemDeletion)}
                buttonText={buttonText}
                message={notificationMessage}
            />
            {isLoading && <Spinner />}
        </div>
    )
}

export default Permissions
