import React from "react"
import ContentLoader from "react-content-loader"

const EventItemLoader = () => (
    <ContentLoader 
    speed={2}
    width={500}
    height={160}
    viewBox="0 0 500 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    title={''}
  >
    <rect x="77" y="5" rx="3" ry="3" width="170" height="6" /> 
    <rect x="77" y="27" rx="3" ry="3" width="415" height="6" /> 
    <rect x="0" y="0" rx="5" ry="5" width="60" height="60" /> 
  </ContentLoader>
)

export default EventItemLoader

