import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TabsComponent from '../../shared/components/Tabs/TabsComponent';
import {Props} from "./props";
import Surveys from './Surveys/Surveys';
import Purchases from './Purchases/Purchases';
import Clicks from './Clicks/Clicks';
import { routes } from './routes';
import { useTranslation } from 'react-i18next';
import Leads from './Leads/Leads';

const Reports = (props: Props) => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();

    const tabs = [
        {
            title: t('tabNavigation.reports.leads'),
            component: Leads,
            url: `${path}/leads`
        },
        {
            title: t('tabNavigation.reports.surveys'),
            component: Surveys,
            url: `${path}/surveys`
        },
        {
            title: t('tabNavigation.reports.purchases'),
            component: Purchases,
            url: `${path}/purchases`
        },
        {
            title: t('tabNavigation.reports.clicks'),
            component: Clicks,
            url: `${path}/clicks`
        },
    ];
    return (
        <>
            <div className="main-content main-content-tabs reports-page">
                <Switch>
                    <TabsComponent data={tabs} />
                    {
                        routes.map((route, index) => {
                            return (
                                <Route key={index} path={`${path}/${route.path}`} exact component={route.component}/>
                            )
                        })
                    }
                </Switch>
            </div>
        </>
    )
}

export default Reports
