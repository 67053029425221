import * as Yup from 'yup';

export const FIELDS = {
    SEARCH: "search",
    CATEGORY: "category",
    TYPE: "type",
    FROM_DATE: "from_date",
    TO_DATE: "to_date",
    PRICE: "price",
    IS_ARCHIVE: "is_archive",
    LOW_INVENTORY: "low_inventory",
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.SEARCH]: Yup.string(),
    [FIELDS.CATEGORY]: Yup.string(),
    [FIELDS.TYPE]: Yup.string(),
    [FIELDS.FROM_DATE]: Yup.string(),
    [FIELDS.TO_DATE]: Yup.string(),
    [FIELDS.PRICE]: Yup.number(),
    [FIELDS.LOW_INVENTORY]: Yup.boolean(),
    [FIELDS.IS_ARCHIVE]: Yup.boolean(),
});
