import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class GetStore {
    root: RootStore;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    * getCategories() {
        let response = yield Api.get(`/admin/article/categories`);
        return response?.data
    }

    * getGroups() {
        let response = yield Api.get(`/admin/article/group`);
        return response?.data
    }
}
