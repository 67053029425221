import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";
import UserTypeEnum from "../models/UserType";
import PlatformSettings from "../models/PlatformSettings";

export default class UserStore {
  root: RootStore;
  token = "";
  isAuthenticated = false;
  user = JSON.parse(localStorage.getItem("user") ?? "{}");
  platform = JSON.parse(localStorage.getItem("platform") ?? "{}");
  connection = JSON.parse(localStorage.getItem("connection") ?? "{}");
  adminCommunities = this.connection?.adminCommunities ?? [];
  communities = this.platform?.communities ?? [];
  currentUser = {
    id: this.user?.id ?? 1,
    firstName: this.user?.firstName ?? "",
    lastName: this.user?.lastName ?? "",
    email: this.user?.email ?? "",
    phone: this.user?.phone ?? "",
  };
  role = "";
  isAssociatedWithCommunity = this.connection?.communityId ? true : false;
  roleType;
  currentCommunity = JSON.parse(localStorage.getItem("community") ?? "{}");
  phoneNumber = "";
  codeNumber = "";
  triggerUpdateAccount = false;
  triggerHomeNavigatorUpdate = false;
  allPlatforms = [];
  platformSettings: any =
    localStorage.getItem("platformSettings") ?? PlatformSettings.vaad;
  isPermitted = false;

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, {
      root: false,
    });
  }

  getCommunities = () => {
    return this.communities;
  };

  getAdminCommunities = () => {
    return this.adminCommunities;
  }

  *login(payload: any) {
    const response = yield Api.post(`/admin/auth/login`, payload);
    if (response.data?.success && response.data?.access_token) {
      this.proceedLoggedUser(response.data);
    }
    return response?.data;
  }

  *loginOTP(otpCode?: string) {
    let response = yield Api.get(`/admin/auth/login/phone/${otpCode}`);
    if (response.data?.success && response.data?.access_token) {
      this.proceedLoggedUser(response.data);
    }
    return response?.data;
  }

  *register(payload: any) {
    const response = yield Api.post("/admin/auth/register", payload);
    return response?.data;
  }

  *registerApp(payload: any) {
    const response = yield Api.post("/app/auth/register", payload);
    return response?.data;
  }

  *forgotPassword(payload: any) {
    const response = yield Api.post("/auth/forgot-password", payload);
    return response?.data;
  }

  *resetPassword(payload: any) {
    const response = yield Api.post("/auth/reset-password", payload);
    return response?.data;
  }

  *getOTP(payload: any) {
    const response = yield Api.post("/admin/auth/login/phone", payload);
    if (response.data?.success) {
      this.phoneNumber = payload.phone;
      this.codeNumber = response.data.code;
    }
    return response.data;
  }

  proceedLoggedUser(data: any, isLogin = true): void {
    localStorage.setItem("user", JSON.stringify(data?.user));
    localStorage.setItem("platform", JSON.stringify(data?.platform || {}));
    localStorage.setItem("connection", JSON.stringify(data?.connection || {}));
    this.setRole(data?.connection?.role);
    this.isAssociatedWithCommunity = data?.connection?.communityId ?? false;
    this.user = data?.user;
    this.currentUser.firstName = data?.user?.firstName;
    this.currentUser.lastName = data?.user?.lastName;
    this.platform = data?.platform;
    this.adminCommunities = data?.connection?.adminCommunities ?? [];
    this.communities = 
      data?.platform?.communities ?? data?.connection?.adminCommunities ?? [];
    this.setCommunity(data);
    this.isPermitted = data?.connection?.isPermitted ?? false;
    if (isLogin) {
      localStorage.setItem("access_token", data?.access_token);
      this.setToken(data?.access_token);
      this.isAuthenticated = true;
    }
  }

  getIsPermitted() {
    return this.isPermitted;
  }

  setCommunity(data) {
    localStorage.setItem("community", JSON.stringify(data?.community || {}));
    this.currentCommunity = data?.community ?? {};
  }

  getToken = () => {
    return this.token;
  };

  setRoleType = () => {
    // 1 - Owner who is associated with a community
    if (this.role === "OWNER" && this.isAssociatedWithCommunity) {
      this.roleType = UserTypeEnum.associatedOwner;
      // 2 - Owner who is NOT associated with a community
    } else if (this.role === "OWNER" && !this.isAssociatedWithCommunity) {
      this.roleType = UserTypeEnum.owner;
      // 3 - CHIEF EDITOR
    } else if (this.role === "CHIEF_EDITOR") {
      this.roleType = UserTypeEnum.chiefEditor;
      // 4 - ADMIN
    } else if (this.role === "ADMIN") {
      this.roleType = UserTypeEnum.admin;
      // 5 - EDITOR
    } else {
      this.roleType = UserTypeEnum.editor;
    }
  };

  getRoleType = () => {
    return this.roleType;
  };

  setPlatformSettings = (platform) => {
    let selectedPlatform = PlatformSettings.vaad;
    if (platform === PlatformSettings.communities) {
      selectedPlatform = PlatformSettings.communities;
    }
    if (this.platformSettings !== selectedPlatform && this.isAuthenticated) {
      this.logout();
    }
    this.platformSettings = selectedPlatform;
    localStorage.setItem("platformSettings", selectedPlatform);
    return selectedPlatform;
  };

  getPlatformSettings = () => {
    return this.platformSettings;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  getRole = () => {
    return this.role;
  };

  setRole = (role: string) => {
    localStorage.setItem("role", role);
    this.role = role;
  };

  getCurrentCommunity = () => {
    return this.currentCommunity;
  };

  *getMyAccountInfo() {
    const response = yield Api.get(`/user/my-account`);
    return response?.data;
  }

  *setMyAccountInfo(payload) {
    const response = yield Api.put(`/admin/user/my-account`, payload);
    return response?.data;
  }

  updateStoredAccountInfo = (data, communityOnly = false) => {
    if (communityOnly) {
      this.setCommunity(data);
      return;
    }
    localStorage.setItem("community_id", "");
    this.proceedLoggedUser(data, false);
    this.setRoleType();
    this.triggerUpdateAccount = !this.triggerUpdateAccount;
  };

  getUserFullName = () => {
    const user = this.currentUser;
    return `${user.firstName} ${user.lastName}`;
  };

  logout = () => {
    this.setToken("");
    this.setRole("");
    localStorage.clear();
    localStorage.setItem("platformSettings", this.platformSettings);
    this.user = {};
    this.platform = {};
    this.isAuthenticated = false;
  };

  *testPUT() {
    const response = yield Api.put(`/admin/test/123`);
    return response?.data;
  }

  getStoredPlatforms() {
    return this.allPlatforms;
  }

  *getPlatforms() {
    const response = yield Api.get(`/admin/platform`);
    if (
      response.ok &&
      response.data?.success &&
      response.data?.platforms?.length
    ) {
      this.allPlatforms = response.data.platforms;
    } else {
      this.allPlatforms = [];
    }
    return response?.data;
  }

  //Return selected platform ID
  getPlatformID() {
    const selectedPlatformKey = localStorage.getItem("platformSettings");
    const index = this.allPlatforms.findIndex((platform) => {
      return platform.name.toLowerCase().indexOf(selectedPlatformKey) > -1;
    });
    return this.allPlatforms[index]?.id;
  }
}
