export const themeSettings:any = {
    typography: {
        fontFamily: "Arial",
        h2: {
            fontSize: "1.89rem",
        },
        h3: {
            fontSize: "1.15rem"
        },
        h4: {
            fontSize: "1rem"
        },
        h5: {
            fontSize: "0.85rem"
        },
        button: {
            fontSize: 18,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    palette: {
        primary: {
            main: "#577bf9"
        },
        secondary: {
            main: "#000"
        }
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: "100%"
            }
        },
        MuiInputLabel: {
            root: {
                color: "#000"
            }
        },
        MuiInputBase: {
          root: {
              width: "100%",
              marginTop: "16px"
          }
        },
        MuiFormLabel: {
          root: {
              color: "#000",
          }
        },
        MuiIconButton: {
            root: {
                padding: "12px 0px"
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: "none",
                border: "none",
                padding: "0 75px",
                zIndex: 0,
                '&::after': {
                    height: 1,
                    content: "''",
                    background: "#d3d3d3",
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    right: 0,
                    bottom: 0,
                }
            }
        },
        MuiTabs: {
            scroller: {
                flex: "0 0 auto",
                zIndex: 2
            },
            indicator: {
                height: 1
            }
        },
        MuiButton: {
            root: {
                maxHeight: 45,
                textTransform: 'capitalize',
                "&.MuiButton-contained.Mui-disabled": {
                    backgroundColor: "#abbdfc",
                    color: "#fff"
                }
            },
        },
        MuiTab: {
            root: {
                padding: "16px 0",
                minWidth: 0,
                marginRight: 40,
                fontSize: 16,
                '@media (min-width: 0px)': {
                    minWidth: "auto",
                    padding: "16px 0"
                },
                "&$selected": {
                    "color": "#000"
                }
            },
            wrapper: {
                textTransform: "capitalize"
            },
        }
    }
}
