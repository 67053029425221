import * as Yup from 'yup';

export const FIELDS = {
    SEARCH: "search",
    SENT_TO: "sent_to",
    FROM_DATE: "from_date",
    TO_DATE: "to_date",
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.SEARCH]: Yup.string(),
    [FIELDS.SENT_TO]: Yup.string(),
    [FIELDS.FROM_DATE]: Yup.string(),
    [FIELDS.TO_DATE]: Yup.string(),
});
