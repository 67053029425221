import React from 'react'
import {noContent} from '../../../db/db';
import "./style.scss";
import { useTranslation } from 'react-i18next';

const NoMessages = () => {
    const { t } = useTranslation();
    return (
        <div className="no-content">
            <img src={noContent.messages} alt="No messages" />
            <p>{t('messages.noMessages')}</p>
        </div>
    )
}

export default NoMessages
