import moment from "moment"
const today = moment(); // Get the current date

export const monthsArray = [
    { label: 'This month', startDate: moment(today).startOf('month').toDate(), endDate: moment(today).endOf('month').toDate() },
    { label: 'Last month', startDate: moment(today).subtract(1, 'months').startOf('month').toDate(), endDate: moment(today).subtract(1, 'months').endOf('month').toDate() },
    { label: '2 months ago', startDate: moment(today).subtract(2, 'months').startOf('month').toDate(), endDate: moment(today).subtract(2, 'months').endOf('month').toDate() },
    { label: '3 months ago', startDate: moment(today).subtract(3, 'months').startOf('month').toDate(), endDate: moment(today).subtract(3, 'months').endOf('month').toDate() },
    { label: '4 months ago', startDate: moment(today).subtract(4, 'months').startOf('month').toDate(), endDate: moment(today).subtract(4, 'months').endOf('month').toDate() },
    { label: '5 months ago', startDate: moment(today).subtract(5, 'months').startOf('month').toDate(), endDate: moment(today).subtract(5, 'months').endOf('month').toDate() },
    { label: '6 months ago', startDate: moment(today).subtract(6, 'months').startOf('month').toDate(), endDate: moment(today).subtract(6, 'months').endOf('month').toDate() },
    { label: '7 months ago', startDate: moment(today).subtract(7, 'months').startOf('month').toDate(), endDate: moment(today).subtract(7, 'months').endOf('month').toDate() },
    { label: '8 months ago', startDate: moment(today).subtract(8, 'months').startOf('month').toDate(), endDate: moment(today).subtract(8, 'months').endOf('month').toDate() },
    { label: '9 months ago', startDate: moment(today).subtract(9, 'months').startOf('month').toDate(), endDate: moment(today).subtract(9, 'months').endOf('month').toDate() },
    { label: '10 months ago', startDate: moment(today).subtract(10, 'months').startOf('month').toDate(), endDate: moment(today).subtract(10, 'months').endOf('month').toDate() },
    { label: '11 months ago', startDate: moment(today).subtract(11, 'months').startOf('month').toDate(), endDate: moment(today).subtract(11, 'months').endOf('month').toDate() },
    { label: '12 months ago', startDate: moment(today).subtract(12, 'months').startOf('month').toDate(), endDate: moment(today).subtract(12, 'months').endOf('month').toDate() }
];

export const initDateRange = {
    startDate: moment("2022-01-01").toDate(),
    endDate: moment(today).toDate()
}