import MyArticles from "../Articles/MyArticles";
import PushNotifications from "../Notifications/PushNotifications";

export const routes = [
    {
        path: 'articles/list',
        component: MyArticles
    },
    {
        path: 'notifications/list',
        component: PushNotifications
    },
]