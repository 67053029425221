import * as Yup from 'yup';

export const FIELDS = {
    SEARCH: "search",
    FROM_DATE: "from_date",
    TO_DATE: "to_date",
    IS_ARCHIVE: "is_archive",
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.SEARCH]: Yup.string(),
    [FIELDS.FROM_DATE]: Yup.string(),
    [FIELDS.TO_DATE]: Yup.string(),
    [FIELDS.IS_ARCHIVE]: Yup.boolean(),
});
