import React from 'react'
import './style.scss';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface Props {
    open: boolean;
    handleClose: any;
}

const CreateMenu = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleNewUser = () => {
        history.push('/mobile/users/create');
        props.handleClose();
    }
    const handleNewArticle = () => {
        history.push('/mobile/articles/create');
        props.handleClose();
    }

    const handleNewPush = () => {
        history.push('/mobile/notifications/create');
        props.handleClose();
    }

    return (
        <div className={`mobile-create-menu ${props.open ? 'opened' : ''}`}>
            <div className="content-container d-flex flex-column justify-center align-center">
            <div className="action d-flex align-center" onClick={handleNewUser}>
                    <div className="action-icon-container d-flex justify-center align-center">
                        <AccountCircleOutlinedIcon className="action-icon" />
                    </div>
                    <span className="action-text">{t('users.create.user')}</span>
                </div>
                <div className="action d-flex align-center" onClick={handleNewArticle}>
                    <div className="action-icon-container d-flex justify-center align-center">
                        <TextFormatIcon className="action-icon" />
                    </div>
                    <span className="action-text">{t('mobile.createMenu.article')}</span>
                </div>
                <div className="action d-flex align-center" onClick={handleNewPush}>
                    <div className="action-icon-container d-flex justify-center align-center">
                        <NotificationsNoneIcon className="action-icon" />
                    </div>
                    <span className="action-text">{t('common.fieldHeaders.push')}</span>
                </div>
            </div>
        </div>
    )
}

export default CreateMenu
