import * as Yup from 'yup';

export const FIELDS = {
    SEARCH: "search",
    CATEGORY: "category",
    FROM_DATE: "from_date",
    TO_DATE: "to_date",
    PRICE: "price",
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.SEARCH]: Yup.string(),
    [FIELDS.CATEGORY]: Yup.string(),
    [FIELDS.FROM_DATE]: Yup.string(),
    [FIELDS.TO_DATE]: Yup.string(),
    [FIELDS.PRICE]: Yup.number(),
});
