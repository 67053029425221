export const he = {
  common: {
    termsAndPrivacy: "תנאי השימוש ומדיניות הפרטיות",
    readTermsAndConditions: "קראתי את תנאי השימוש ומדיניות הפרטיות",
    comingSoon: "בקרוב!",
    underConstruction: "עמוד זה בבנייה, המשך לעקוב :)",
    communityNotSelected: "לא נבחר",
    currency: "₪",
    israeliCurrency: "₪",
    management: "לא משוייך",
    noSecondaryDepartments: "לא משוייך",
    deleteItems: "האם אתה בטוח שברצונך למחוק פריטים אלה?",
		archiveItems: "האם אתה בטוח שברצונך לשלוח לארכיון פריטים אלה?",
    buttons: {
      save: "שמור",
      send: "שלח",
      agree: "מסכים",
      close: "סגור", //
      preview: "תצוגה מקדימה",
      previewAndPublish: "תצוגה מקדימה ופרסום",
      saveAsDraft: "שמור כטיוטה",
      invite: "הזמן",
      update: "עדכן",
      add: "לְהוֹסִיף", //
      next: "הבא",
      yes: "כן", //
      no: "לא", //
      confirm: "אשר",
      showOriginal: "הצגת המקור",
      buttonText: "טקסט לחצן",
      delete: "מחק",
    },
    forgotPassword: "שכחת סיסמה?",
    signInLabel: "התחברות",
    signInOTP: "שכחתי סיסמה",
    signUpLabel: "הירשם",
    remember: "האם נזכרת?",
    doNotHaveAccount: "אין לך פרופיל?", // NB
    fieldHeaders: {
      search: "חיפוש",
      category: "קטגוריה",
      categories: "קטגוריות",
      header: "כותרת",
      managedBy: "מנוהל על ידי",
      writer: "נכתב על ידי",
      live: "פורסם בתאריך",
      archived: "ארכיון",
      moveToArchive: "העבר לארכיון",
      push: "הודעה",
      actions: "פעולות",
      date: "תאריך",
      from: "תאריך",
      to: "ארכיון",
      uploadImage: "העלאת תמונה", //
      uploadImageOrVideo: "העלאת תמונה או סרטון וידאו",
      appears: "המאמר יופיע",
      whoWillSeeAd: "מי יראה את המודעה?",
      sendPushNotification: "שלח הודעת התראה?",
      pushNotificationText: "טקסט הודעת התראה",
      whenToPublishArticle: "מתי לפרסם את המאמר?",
      whenToPublishBenefit: "מתי לפרסם את הישום?",
      now: "עכשיו",
      later: "מאוחר יותר",
      whenToArchiveArticle: "מתי לאחסן את המאמר בארכיון?",
      whenToArchiveBenefit: "מתי לאחסן את הישום בארכיון?",
      globalDate: "תאריך כללי כפי שהוגדר בהגדרות",
      specificDate: "בתאריך מסוים",
      iWillWrite: "אני אכתוב את המאמר",
      externalLink: "קישור חיצוני",
      url: "כתובת URL", //
      articleText: "טקסט מאמר",
      whenToRemoveAd: "מתי להסיר את המודעה",
      subHeader: "כותרת משנה",
      ctaText: "טקסט קריאה לפעולה",
      publishedOn: "פורסם בתאריך",
      searchArchive: "חיפוש בארכיון",
      type: "סוג",
      price: "מחיר",
      id: "מס׳",
      location: "מיקום",
      quantity: "כמות",
      scheduled: "מתוזמן",
      pageType: "סוג עמוד",
      description: "תיאור",
      dateFrom: "מתאריך",
      dateTo: "עד תאריך",
      fullName: "שם מלא",
      emailAddress: "אימייל",
      phoneNumber: "טלפון",
      numberOfTickets: "מלאי יחידות לישום",
      maxPurchases: "מקסימום רכישות למשתמש",
      department: "סגמנטציה 1",
      secondaryDepartment: "סגמנטציה 2",
      invitation: "הזמן",
      fromDate: "מתאריך",
      toDate: "עד תאריך",
      lowInventory: "מלאי נמוך",
      status: "סטטוס",
      waitingApproval: "ממתין לאישור", //
      draft: "טיוטה", //
      message: "הודעה",
      sendTo: "נשלח",
      openRate: "דירוג",
      amount: "כמות",
      numberOfClicks: "כמות קליקים",
      shortDescription: "תיאור קצר",
    },
    selectFilters: {
      allCommunities: "כל הקהילות",
      allCategories: "כל הקטגוריות",
      allGroups: "כל הקבוצות", //
      allWriters: "כל הכותבים",
      allTypes: "כל הסוגים", //
      allArticles: "כל המאמרים",
      allMessages: "כל ההודעות",
      all: "את כל",
      leads: "ליד",
      link: "לינק",
      store: "חנות",
      survey: "סקר",
    },
    drafts: "טיוטות",
    archive: "ארכיון",
    active: "פעיל",
    uploading: "מעלה ...",
    updating: "עִדכּוּן...",
    proportions: "הפרופורציות המומלצות של התמונה הן: {{ x }}:{{ y }}",
    errors: "שגיאות:", //
  },
  signIn: {
    fields: {
      email: {
        label: "אימייל",
      },
      password: {
        label: "סיסמה",
      },
    },
    sms: {
      or: "או",
      navTo: "התחברות באמצעות קוד SMS",
      fields: {
        phone: "מספר הטלפון שלך",
      },
      submitButton: "שלח לי קוד",
      navToSignIn: "היכנס באמצעות סיסמת משתמש",
    },
  },
  signUp: {
    navTo: "יש לך חשבון?",
    fields: {
      name: {
        label: "שם משתמש",
      },
      firstName: {
        label: "שם פרטי",
      },
      lastName: {
        label: "שם משפחה",
      },
      phone: {
        label: "טלפון",
      },
      email: {
        label: "אימייל",
      },
      password: {
        label: "סיסמה",
      },
      retypePassword: {
        label: "אמת סיסמה",
      },
    },
  },
  forgotPassword: {
    message: "הזנת פרטים עבור קישור לאיפוס סיסמה",
    fields: {
      email: {
        label: "אימייל",
      },
    },
  },
  emailSent: {
    message1: "שלחנו לך קישור זמני לאיפוס סיסמה.",
    message2: "אנא בדוק את תיבת הדואר הנכנס שלך",
    message3: "תוקפו של הקישור יפוג בעוד 10 דקות",
  },
  resetPassword: {
    title: "אפס סיסמה",
    subTitle: "צור סיסמה חדשה",
    fields: {
      password: {
        label: "סיסמה חדשה",
      },
      retypePassword: {
        label: "אמת סיסמה חדשה",
      },
    },
  },
  otpCode: {
    tempCode: "זמני - מספר קוד:",
    fields: {
      code: "קוד האימות שקיבלת",
    },
    message: "לא קיבלת קוד?",
    sendMeAgain: "שלח לי שוב",
    login: "התחבר",
  },
  tabNavigation: {
    articles: {
      mainTab: "מאמרים",
      allArticles: "כל המאמרים",
      rotatingBanner: "כרזה מסתובבת",
      ads: "מודעות",
    },
    benefits: {
      mainTab: "יישומים",
    },
    users: {
      mainTab: "משתמשים",
      allUsers: "כל המשתמשים",
      importFile: "ייבוא מקובץ",
      inviteApp: "הזמן לאפליקציה",
      waitingApproval: "ממתין לאישור",
    },
    community: {
      mainTab: "קהילה",
      mainTabForOwner: "קהילות",
    },
    reports: {
      mainTab: "דוחות",
      surveys: "סקרים",
      leads: "לידים",
      purchases: "רכישות",
      clicks: "קליקים",
    },
    push: {
      mainTab: "הודעות",
      promoNotification: "הודעות שיווקיות",
      autoNotification: "הודעות אוטומטיות",
    },
    settings: {
      mainTab: "הגדרות",
      general: "כללי",
      myCommunity: "הגדרות קהילה",
      archive: "ארכיון",
      manageCategories: "נהל קטגוריות",
      manageGroups: "נהל קבוצות",
      manageDepartments: "נהל סגמנטציות",
      permissions: "הרשאות",
      communityPolicy: "תנאי פרטיות",
    },
    temporaryDisabled: "אינו זמין כעת",
  },
  dashboard: {
    actions: {
      writeArticle: "כתיבת מאמר",
      addSale: "הוספת פריט מכירה",
      createAd: "יצירת מודעה",
      addUsers: "הוספת משתמשים",
    },
    articlesStatistics: "המאמרים הפופולריים ביותר",
    currentWeekEventList: "מתרחש השבוע",
    draftsListComponent: "הטיוטות שלי",
    latestActivitiesComponent: "פעילות אחרונה",
    welcomeSection: {
      message: "ברוך שובך,",
      question: "מה ברצונך לעשות היום?",
    },
    search: {
      resultFor: "תוצאות ל",
      searchResults: "תוצאות חיפוש",
      noResults: {
        title: "אין תוצאות עבור",
        message1: "זה לא הזמן לנוח.",
        message2: "מקד את קריטריון החיפוש שלך",
      },
    },
    noDrafts: "לא נמצאו טיוטות.", //
    noThisWeek: "כלום השבוע.", //
    noPopularActivities: "לא נמצאו מאמרים פופולריים.", //
    noActivity: "לא נמצאו פעילויות.", //
  },
  articles: {
    allArticles: {
      title: "כל המאמרים",
      noArticles: {
        message1: "לא נמצאו מאמרים",
        message2: "כתוב את המאמר הראשון שלך עכשיו",
      },
      create: {
        saveDraft: "המאמר נשמר בהצלחה כטיוטה.",
        title: "מאמר חדש",
        template: {
          title: "בחר את התבנית שלך",
          fullImage: "תמונה מלאה",
          imageShortDescription: "תמונה + תיאור קצר",
          noImage: "ללא תמונה",
          onlyImageNoText: "רק תמונה, ללא טקסט",
        },
        form: {
          title: "תצוגה מקדימה של מאמר",
          subTitle: "פרטי מאמר",
          articleName: "שם המאמר", //
          preview: {
            headerGoes: "כותרת עליונה נכנסת כאן",
            detailsHeader: "פרטים כותרת", //
            detailsText: "פרטים טקסט",
            showMore: "להראות יותר", //
          },
        },
      },
      view: {
        title: "צפה במאמר", //
      },
      edit: {
        title: "ערוך מאמר", //
      },
      search: {
        title: "חיפוש מאמרים",
        articlesManagedBy: "מאמרים מנוהלים על ידי",
      },
    },
    rotatingBanner: {
      title: "כרזה מסתובבת",
      noArticles: {
        message1: "לא.",
        message2: "אין פה כלום.",
      },
      create: {
        title: "כרזה חדשה",
      },
      view: {
        title: "הצג באנר", //
      },
      edit: {
        title: "ערוך באנר", //
      },
      search: {
        title: "חיפוש כרזה",
      },
    },
    ads: {
      title: "מודעות",
      noArticles: {
        message1: "לא נמצאו מודעות",
        message2: "הוסף מודעה",
      },
      create: {
        title: "פרסומת חדשה",
        form: {
          preview: {
            subHeaderGoes: "כותרת משנה",
          },
        },
      },
      view: {
        title: "צפה במודעה", //
      },
      edit: {
        title: "ערוך מודעה", //
      },
      search: {
        title: "חיפוש מודעות ",
      },
    },
  },
  benefits: {
    draft: "הישום נשמרה בהצלחה כטיוטה.",
    validationMessages: {
      fillLabel: "אנא מלא את התווית.",
      fillKey: "אנא מלא מפתח.",
      fillValue: "אנא מלא ערך.",
      labelCantHaveNumberAsFirstChar: "תווית לא יכולה להתחיל עם מספר.",
    },
    title: "כל היישומים",
    all: "הכל",
    noBenefits: {
      message1: "לא נמצאו ישומים", //
      message2: "כתוב את הישום הראשונה שלך עכשיו", //
    },
    create: {
      title: "סוג הישום",
      enterLabel: "הזן תווית", //
      form: {
        title: "תצוגה מקדימה",
        details: "פרטים",
        detailsText: "פרטים טקסט",
        detailsEmailTitle: "הכנס את כתובת המייל אליה יישלחו הלידים",
        detailsEmail: "כתובת מייל",
        formBuilder: {
          title: "יוצר טפסים",
          selectFieldType: "בחר סוג שדה",
          textfield: "שדה מילוי",
          dropdown: "תפריט נשלף",
          checkbox: "סימון",
          radio: "כפתור בחירה",
          key: "מַפְתֵחַ", //
          enterKey: "מקש להיכנס", //
          value: "ערך", //
          enterValue: "הזן ערך", //
          addOption: "הוסף אפשרות",
          isRequired: "שאלת חובה",
        },
        addButton: "הוסף לחצן",
        buttonText: "טקסט לחצן",
        link: "לינק",
      },
    },
    view: {
      title: "צפה בישום", //
    },
    edit: {
      title: "ערוך ישום", //
    },
    search: {
      title: "חיפוש",
    },
  },
  users: {
    allUsers: {
      title: "כל המשתמשים",
      totalUsers: "סה״כ משתמשים",
      downloadedApp: "כמות הורדות",
      nonActive: "לא פעיל",
      invite: "הזמן",
      delete: "מחק",
      noContent: {
        message1: "לא נמצאו משתמשים",
        message2: "התחל להוסיף משתמשים לקהילה שלך",
      },
      popUp: {
        message: "פעולה זו היא קבועה",
        action: "כן, הסר",
      },
      appInstalled: "אפליקציה הותקנה",
      export: "יצא לאקסל"
    },
    create: {
      title: "משתמש חדש",
      subTitle: "פרטי משתמש",
      addUser: "הוסף משתמש",
      owner: "בעלים",
      associatedOwner: "בעלים קשורים",
      chiefEditor: "עורך ראשי",
      admin: "אדמין", //
      editor: "עורך",
      user: "משתמש",
      draftStatus: "בעריכה",
      archivedStatus: "בארכיון",
      USER: "משתמש",
      EDITOR: "עורך",
      CHIEF_EDITOR: "עורך ראשי",
      ADMIN: "אדמין",
      OWNER: "בעלים",
      openStore: "עבור לאפליקצייה"
    },
    search: {
      title: "חיפוש משתמשים ",
      downloadApp: "הוריד את האפליקציה",
    },
    importFromFile: {
      importUserList: "ייבוא רשימת משתמשים מקובץ",
      uploadExcel: "העלה קובץ אקסל עם עמודות מוגדרות. מידע נוסף על הקובץ",
      here: "כאן",
      pleaseNote: "שים לב - משתמשים קיימים לא ישוכפלו",
      allUsersList: "כל רשימת המשתמשים",
      newUsers: "משתמשים חדשים",
      updatedUsers: "פרטי המשתמשים עודכנו",
      turnNonActive: "משתמשים יהפכו ללא פעילים",
      clickHere: "אנא לחץ כאן להעלאת הקובץ או גרור ושחרר ישירות.",
      importOptionMessage:
        "אתה עומד לטעון משתמשים חדשים או לעדכן משתמשים ברשימה קיימת. אנא בחר את האופציה הנכונה:",
      create: "הוספת משתמשים חדשים",
      update: "עדכון משתמשים קיימים",
      deactivateUsers: "הפוך משתמשים חסרים ללא פעילים",
    },
    inviteToApp: {
      title: "הזמן אנשים לאפליקציה",
      labelTitle: "כותרת",
      sendEmail: "שלח אימייל",
      titleName: "אנא הזן כותרת למייל",
      messageContent: "אנא הכנס את תוכן ההודעה כאן",
      emailContent: "אנא הכנס את תוכן ההדעה כאן",
      emails: "אימייל",
      emailsComma: "באפשרותך להוסיף רשימה של כתובות מייל המופרדות באמצעות פסיק",
      sendSMS: "שלח SMS",
      phoneComma: "באפשרותך להוסיף רשימה של טלפונים המופרדים באמצעות פסיק",
      copyLink: "העתק קישור",
      link: "לינק",
      copy: "העתק",
    },
    waitingForApproval: {
      title: "ממתין לאישור",
      inviteBy: "הזמנה על ידי",
      approve: "אשר",
      approveAll: "אשר הכל",
      reject: "דחה",
      noContent: "אף אחד לא מחכה לאישור",
    },
  },
  community: {
    list: {
      title: "כל הארגונים",
      name: "שם",
      phone: "טלפון",
      email: "אימייל",
      group: "קבוצות",
      editors: "עורכים",
      users: "משתמשים",
      actions: "פעולות",
      noCommunity: "לא נמצאו קהילות.",
    },
    view: {
      canRemoveArticles: "יכול להסיר מאמרי ניהול?",
      addEditor: "הוסף עורך",
      chooseFromUserList: "בחר מרשימת המשתמשים:",
      addSelected: "הוסף נבחר",
      free: "עורך חופשי",
    },
    create: {
      title: "צור קהילה חדשה",
      subTitle: "פרטי הקהילה",
      ce: "עורך ראשי",
      created: "הקהילה נוצרה בהצלחה.",
    },
    edit: {
      title: "ערוך את הקהילה",
    },
    search: {
      title: "חיפוש",
    },
  },
  reports: {
    fromDate: "מתאריך:",
    untilDate: "עד תאריך:",
    dashboard: "לוח בקרה",
    searchTitle: "חפש רכישות",
    title: "דוחות",
    sent: "נשלח",
    opened: "נפתח",
    purchased: "נרכש",
    openRatePush: "דירוג פתיחת הודעות",
    noOpenRatePush: "אין הודעות התראה לתקופה שנבחרה.",
    noPurchases: "אין רכישות לתקופה שנבחרה.",
    purchases: {
      title: "רכישות",
      cancelPurchase: "בטל רכישה",
      totalPurchases: 'סה"כ רכישות',
      total: "סה״כ:",
      confirmClose: "האם את/ה בטוח/ה שברצונך לבטל את הרכישה?",
      buyersCount: "{{ count }} רוכשים",
      noContent: {
        message1: "לא נמצאו רכישות",
        message2: "צמצם את קריטריוני החיפוש שלך",
      },
      informationModal: {
        header: "פירוט רוכשים",
        export: "יצא לאקסל",
        cancelledPurchase: "הרכישה בוטלה ",
      },
    },
    clicks: {
      clicksCount: "{{ count }} קליקים",
      informationModal: {
        header: "פירוט קליקים",
      },
      noContent: {
        message1: "לא נמצאו קליקים",
      },
    },
    surveys: {
      questionsCount: "{{ count }} שאלות בסקר",
      participantsCount: "{{ count }} משתתפים",
      informationModal: {
        header: "תוצאות הסקר",
        question: "שאלה {{ num }}",
        showParticipants: "הצג משיבים",
        popUp: {
          header: "רשימת משיבים",
        },
        anonymous: "אנונימי",
      },
      noContent: {
        message1: "לא נמצאו סקרים",
      },
    },
    leads: {
      title: "לידים",
      leadsCount: "{{ count }} לידים",
      noContent: {
        message1: "לא נמצאו לידים",
        message2: "צמצם את קריטריוני החיפוש שלך",
      },
      informationModal: {
        header: "פירוט לידים",
        export: "יצא לאקסל",
      },
    },
    search: {
      title: "חיפוש מכירות ",
    },
  },
  push: {
    errors: {
      time: "אנא ודא שבחרת תאריך עתידי.",
    },
    promoNotification: {
      message: "הודעה",
      sentTo: "נשלח",
      openRate: "דירוג",
      aboutToBeSent: "עומד להישלח",
    },
    autoNotification: {
      title: "הודעות מערכת אוטומטיות",
    },
    noNotifications: {
      message1: "לא נמצאו הודעות",
      message2: "אתה יכול לשלוח אחד עכשיו",
    },
    search: {
      title: "חיפוש הודעות",
    },
    create: {
      title: "התראה חדשה",
      whenToSendNotification: "מתי לשלוח את ההודעות?",
      messages: "הודעות",
      yourMessage: "ההודעה שלך",
    },
    edit: "ערוך הודעת התראה", //
    view: "צפה בהודעות התראה", //
    myCommunity: "הוועד שלי",
  },
  settings: {
    general: {
      title: "הגדרות כלליות",
      topBar: "סרגל עליון",
      backgroundColor: "צבע רקע",
      bottomBar: "סרגל תחתון",
      text: "טקסט", //
      textColor: "צבע טקסט", //
      companyName: "שם קהילה",
      primaryColor: "צבע יסוד",
      uploadLogo: "העלאת לוגו עבור סרגל הניווט",
      emailSettings: "הגדרות יצירת קשר",
      sendersName: "שם ליצירת קשר",
      sendersEmail: 'דוא"ל ליצירת קשר',
      sendersPhone: "טלפון ליצירת קשר",
      saved: "הקהילה ניצלה בהצלחה.",
    },
    myCommunity: {
      title: "הגדרות קהילה",
      subTitle: "הקהילה שלי",
      uploadLogo: "העלאת לוגו שקוף",
      uploadBackground: "העלאת תמונת רקע",
      links: "לינקים",
      addLink: "הוסף לינק",
      addName: "הוסף שם",
      icon: "איקון",
      addCategory: "הוסף קטגוריה",
      addDepartment: "הוסף סגמנטציה",
      addGroup: "הוסף קבוצה",
      vaadLeaders: "חברי הוועד",
      communitiesLeaders: "חברי הקהילה",
      presentAllEditors: "הצג את כל העורכים כאנשים ליצירת קשר כאן",
    },
    archive: {
      title: "הגדרות ארכיון",
      moveArticles: "העברת מאמרים לארכיון לאחר:",
      days: "ימים",
      andOr: "ו/או",
      saleItem: "יישומים",
      moveSaleItems: "העבר פריטי מכירה לארכיון לאחר:",
      items: "פריטים",
    },
    manageCategories: {
      categories: "נהל קטגוריות",
      content: "קטגוריות ראשיות",
      order: "הזמנה",
      categoryName: "שם קטגוריה",
      typeCategory: "סוג דף",
      benefits: "קטגוריות ישומים",
      leads: "תפקיד",
      link: "לינק",
      store: "חנות",
      platform: "פלטפורמה",
    },
    manageDepartments: {
      title: "נהל סגמנטציות",
      departmentName: "שם סגמנטציה",
      segmentation: "סגמנטציה",
    },
    manageGroups: {
      title: "נהל קבוצות",
      groupName: "שם קבוצה",
    },
    permissions: {
      selectAll: "בחר הכל", //
      banners: "באנרים",
    },
    communityPolicy: {
      privacyPolicy: "מדיניות פרטיות", //
    },
  },
  myAccount: {
    title: "המשתמש שלי",
    details: "פרטים אישיים",
    myCommunities: "הקהילות שלי",
    uploadPicture: "העלאת תמונת פרופיל",
  },
  leftSideBar: {
    as: "אש",
    logOut: "האם אתה בטוח שברצונך להתנתק ממערכת ?",
  },
  popUpActionMenu: {
    edit: "ערוך",
    view: "צפה",
    duplicate: "שכפל",
    unarchive: "לא ארכיוני", //
    delete: "מחק", //
    copyLink: "העתק קישור",
    goToResults: "מעבר לתוצאות",
  },
  previewModal: {
    looksGood: "נראה טוב!",
    notYet: "עוד לא",
  },
  unsavedChanges: {
    areYouSure: "האם אתה בטוח שברצונך לעזוב מבלי לשמור שינויים?",
    permanent: "פעולה זו היא קבועה",
    yesLeave: "כן, עזוב",
  },
  messages: {
    allMessages: "ההודעות שלי",
    meMessages: "ההודעות שלי", //
    noMessages: "לא נמצאו הודעות",
    today: "היום, ",
    yesterday: "אתמול, ",
  },
  termsAndPrivacy: {
    title: "תנאי השימוש ומדיניות הפרטיות",
    paragraph1:
      "קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק לורםקליר, בנפת נפקט למסון בלרק לורם איפסום דולור סיט אמט, קונסקטורר",
    paragraph2:
      "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרקלורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. ",
    paragraph3:
      "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק לורם איפסום דולור סיט אמט, קונסקטורר",
    iHaveRead: "קראתי את תנאי השימוש ומדיניות הפרטיות",
  },
  errorMessages: {
    signUpMissMatchPass: "הסיסמה לא תואמת",
    duplicateAdds: "לא תוכל להכיל יותר משתי מודעות נעולות!",
    lockedArticles: "לא תוכל להכיל יותר משני מאמרים נעולים!",
    lockedBanners: "לא תוכל להחזיק יותר משני באנרים נעולים!",
    approveUser: "האם אתה בטוח לאשר משתמש זה?",
    deleteOneUser: "האם אתה בטוח שברצונך למחוק משתמש?",
    deleteAll: "האם אתה בטוח שברצונך למחוק הכל?",
    approveAll: "האם אתה בטוח שאתה רוצה התחל הכל?",
    deleteUser: "האם אתה בטוח שברצונך למחוק את ההודעות שנבחרו?",
    needUser: "צריך לבחור משתמש אחד או יותר.",
    needToSelect: "צריך לבחור פריט אחד או יותר.",
    delUserSuccess: "המשתמש נמחק בהצלחה.",
    delUserFailed: "שגיאה במהלך מחיקת המשתמש.",
    inviteUser: "האם אתה בטוח שברצונך להזמין משתמשים נבחרים?",
    deleteItems: "האם אתה בטוח שברצונך למחוק פריטים אלה?",
    archiveItems: "האם אתה בטוח שברצונך לשלוח לארכיון פריטים אלה?",
    deleteOne: "האם אתה בטוח שברצונך למחוק פריט זה?",
    deleteCommunity: "האם אתה בטוח שברצונך למחוק את הקהילה הזו?",
    deleteQuestion: "האם אתה בטוח שברצונך למחוק פריט זה?",
    archiveQuestion: "האם אתה בטוח שברצונך לשמור פריט זה בארכיון?",
    unarchiveQuestion: "האם אתה בטוח שברצונך לבטל פריט זה מהארכיון?",
    fileSize: "גודל הקובץ אינו יכול לעלות על 25MB.",
    fileJpg: "הקובץ חייב להיות תמונת JPG/JPEG.",
    fileXls: "הקובץ חייב להיות בפורמט XLSX.",
    copiedLink: "הקישור הועתק ללוח.",
    minimumDate: "תאריך לא יכול להיות לפני התאריך הנוכחי",
    login: {
      noGroupAndCommunity:
        "ברוכים הבאים לקהילה. על מנת להמשיך להשתמש בפלטפורמה שלנו עליך ליצור קבוצה וקהילה.", //
      noCommunity:
        "ברוכים הבאים לקהילה. כדי להמשיך להשתמש בפלטפורמה שלנו עליך ליצור קהילה.", //
      threeTimesError: "אנא סגור את המערכת ופתח אותה מחדש",
    },
    articles: {
      create: {
        noCategories: "לא נמצאו קטגוריות. צור חדש בכרטיסייה הגדרות.", //
        noGroups: "לא נמצאו קבוצות. צור חדש בכרטיסייה הגדרות.", //
        noDepartments: "לא נמצאו סגמנטציות. צור חדש בכרטיסייה הגדרות.", //
        noDepartmentsForOwner: "לא נמצאו סגמנטציות. אנא שנה קהילה.", //
        noSecondaryDepartments:
          "לא נמצאו סגמנטציות משנה. צור חדש בכרטיסייה הגדרות.", //
        noSecondaryDepartmentsForOwner:
          "לא נמצאו סגמנטציות משנה. אנא שנה קהילה.", //
        noCommunities: "לא נמצאו קהילות. צור חדש בכרטיסייה קהילה.", //
      },
    },
    settings: {
      noAccess: "אין לך גישה לביצוע הפעולה. אנא התחבר שוב.",
      general: {
        hexFormatError: "פורמט לא חוקי. אנא השתמש בתבנית הצבעים",
      },
      community: {
        maxLeaders: "לא תוכל להכיל יותר מ -{{maxLeaders}} מנהיגי קהילה",
      },
      groups: {
        cannotDelete: "לא ניתן למחוק את הקבוצה.",
        inUse: "הקבוצה משמשת במאמר.",
        saved: "הקבוצה נוצרה בהצלחה.",
      },
      departments: {
        cannotDelete: "לא ניתן למחוק את הסגמנטציה.",
        inUse: "הסגמנטציה משמשת במאמר.",
        saved: "הסגמנטציה נוצרה בהצלחה.",
      },
    },
    users: {
      deleteUser: "האם אתה בטוח שברצונך להסיר את {{user}}?",
    },
    uploadFile: {
      maxSizeImage: "גודל קובץ התמונה אינו יכול לעלות על 25MB.",
      formatsImage: "הקובץ חייב להיות תמונת {{formats}}.",
      maxSizeVideo: "גודל קובץ הווידאו אינו יכול לעלות על 50MB.",
      formatsAll: "הקובץ חייב להיות תמונת {{formats}} או MP4.",
      informationMessage:
        "*כאן תוכל להוסיף תמונה מסוג JPG/PNG עד משקל 25 מגה בייט או מסוג MP4 עד משקל 50 מגה בייט",
    },
  },
  informationMessages: {
    formatsAll:
      "*כאן תוכל להוסיף תמונה מסוג JPG/PNG עד משקל 25 מגה בייט או מסוג MP4 עד משקל 50 מגה בייט",
    imageOnly: "*כאן תוכל להוסיף תמונה מסוג JPG/PNG עד משקל 25 מגה בייט.",
    pngOnly: "*כאן תוכל להוסיף תמונה מסוג PNG עד משקל 25 מגה בייט.",
  },
  staticMessages: {
    noDepartments: "אין סגמנטציה לקהילה זו, אנא שנה את הקהילה.",
  },
  validationMessages: {
    account: {
      required: "שדה חובה.",
      email: 'אנא הזן דוא"ל חוקי.',
      phone: "הטלפון חייב להיות בן 10 ספרות.",
      minName: "שדה זה צריך להכיל 2 תווים לפחות.",
      maxName: "שדה זה אינו יכול להכיל יותר מ -15 תווים.",
    },
    articles: {
      adFields: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader50: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        maxHeader65: "שדה זה אינו יכול להכיל יותר מ -65 תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        image: "יש צורך בתמונה.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
      },
      addArticle: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -{{ max }} תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
        longText: "טקסט צריך לכלול 10000 תווים לכל היותר.",
      },
      banner: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
        longText: "טקסט צריך לכלול 1500 תווים לכל היותר.",
        image: "יש צורך בתמונה.",
      },
    },
    view: {
      adFields: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader50: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        maxHeader65: "שדה זה אינו יכול להכיל יותר מ -65 תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        image: "יש צורך בתמונה.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
      },
      article: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
        longText: "טקסט צריך לכלול 1500 תווים לכל היותר.",
      },
      banner: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        oneLetter: "שדה זה צריך להכיל תווים אחד לפחות.",
        longText: "טקסט צריך לכלול 1500 תווים לכל היותר.",
        image: "יש צורך בתמונה.",
      },
    },
    community: {
      create: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -20 תווים.",
        email: 'אנא הזן דוא"ל חוקי.',
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        pass: "סיסמא חייבת להיות לפחות בת 6 תווים.",
      },
      edit: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -15 תווים.",
        email: 'אנא הזן דוא"ל חוקי.',
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        pass: "סיסמא חייבת להיות לפחות בת 6 תווים.",
      },
    },
    push: {
      create: {
        url: "הקישור חייב להיות כתובת URL חוקית.",
        image: "יש צורך בתמונה.",
        oneSelected: "מופיע פריט אחד לפחות שנראה.",
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
      },
      view: {
        url: "הקישור חייב להיות כתובת URL חוקית.",
        image: "יש צורך בתמונה.",
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
      },
      unableToDelete: "מחיקת הדחיפות הללו נכשלה: {{ pushes }}",
    },
    users: {
      allUsers: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -15 תווים.",
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        email: 'אנא הזן דוא"ל חוקי.',
      },
      create: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -15 תווים.",
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        email: 'אנא הזן דוא"ל חוקי.',
      },
      inviteToApp: {
        phonePattern: "מספר הטלפון חייב להיות בפורמט ישראלי.",
      },
    },
    settings: {
      general: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -{{max}} תווים.",
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        email: 'אנא הזן דוא"ל חוקי.',
        bgColor: "צבע הרקע חייב להיות בפורמט Hex.",
        prmColor: "הצבע העיקרי חייב להיות בפורמט Hex.",
      },
    },
    benefits: {
      create: {
        url: "הקישור חייב להיות כתובת URL חוקית.",
        image: "יש צורך בתמונה.",
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -1000 תווים.",
        maxHeader20: "שדה זה אינו יכול להכיל יותר מ -20 תווים.",
        atLeastOne: "נדרש לפחות שדה אחד.",
        tickets: "הכרטיסים צריכים להיות יותר מ -0.",
        maxPurchases:
          "מקסימום רכישות למשתמש לא יכול לחרוג ממספר מלאי יחידות לישום",
        price: "המחיר צריך להיות יותר מ 0.",
        detailsText: "טקסט צריך לכלול 10000 תווים לכל היותר.",
        pushNot: "טקסט ההתראה צריך לכלול תווים אחד לפחות.",
        numbers: "שדה זה צריך להיות מספר חוקי",
      },
      view: {
        required: "שדה חובה.",
        minHeader: "שדה זה צריך להכיל 2 תווים לפחות.",
        maxHeader: "שדה זה אינו יכול להכיל יותר מ -50 תווים.",
        detailsText: "טקסט צריך לכלול 1500 תווים לכל היותר.",
        price: "המחיר צריך להיות יותר מ 0.",
        tickets: "הכרטיסים צריכים להיות יותר מ -0.",
        url: "הקישור חייב להיות כתובת URL חוקית.",
        atLeastOne: "נדרש לפחות שדה אחד.",
        email: 'אנא הזן דוא"ל חוקי.',
        fullName: "שם מלא לא יעלה על 10 תווים.",
        pushNot: "טקסט ההתראה צריך לכלול תווים אחד לפחות.",
        image: "יש צורך בתמונה.",
      },
    },
    auth: {
      signUp: {
        required: "שדה חובה.",
        email: 'אנא הזן דוא"ל חוקי.',
        phone: "הטלפון חייב להיות בן 10 ספרות.",
        minPass: "שדה זה צריך להכיל לפחות 6 תווים.",
        phonePattern: "מספר הטלפון חייב להיות בפורמט ישראלי. דוגמה: ********05",
      },
      signIn: {
        required: "שדה חובה.",
        email: 'אנא הזן דוא"ל חוקי.',
        noPlatform:
          "לא זוהתה פלטפורמה. אנא ודא שהזנת את הקישור הנכון של כתובת האתר.", //
      },
    },
  },
  multiSelectLabels: {
    allItemsAreSelected: "כל הפריטים נבחרים.",
    allGroupsAreSelected: "כל הקבוצות נבחרו.",
    allDepartmentsAreSelected: "כל סגמנטציות נבחרות.",
    allCategoriesAreSelected: "כל הקטגוריות נבחרות.",
    allCommunitiesAreSelected: "כל הקהילות נבחרות.",
    clearSearch: "חיפוש נקי",
    noOptions: "אין אופציות",
    search: "לחפש",
    selectAll: "בחר הכל",
    selectSomeItems: "בחר...",
  },
  timePicker: {
    confirm: "אשר",
    cancel: "סגור",
  },
  mobile: {
    sideMenu: {
      role: "תפקיד:", //
      addArticle: "הוסף מאמר חדש", //
      addPush: "הוסף הודעה חדשה",
      addUser: "הוסף משתמש חדש", //
      backToApp: "חזרה לאפליקציה", //
      goToDesktopSite: "עבור לאתר שולחן העבודה", //
    },
    createMenu: {
      article: "מאמר", //
    },
    footer: {
      messages: "הודעות", //
      profile: "פרופיל", //
    },
    articles: {
      title: "מאמרים", //
      noContent: "עדיין לא יצרת מאמרים", //
      success: {
        message: "המאמר שלך הוגש לעורך הראשי", //
      },
    },
    notifications: {
      title: "הודעות", //
      noContent: "עדיין לא יצרת הודעות", //
      success: {
        willBeHere: "יהיה כאן", //
        soon: "בקרוב!", //
        great: "גדול!", //
        message: "הודעת ההתראה שלך נשלחה לעורך הראשי", //
        allNotifications: "כל ההתראות", //
      },
    },
    statuses: {
      published: "פורסם", //
      pending: "ממתין לאישור", //
      awaitingToBeSent: "ממתין לשליחה", //
    },
    messages: {
      showArticle: "הצג מאמר", //
      showPush: "הצג התראה", //
    },
  },
  apiMessages: {
    0: "אירעה שגיאה",
    A200: "המאמר נוצר בהצלחה",
    A201: "המאמר עודכן בהצלחה",
    A202: "המאמר נמחק בהצלחה",
    A203: "המאמר שוכפל בהצלחה",
    A204: "המאמר מאוחסן בארכיון",
    A205: "המאמר אינו מאוחסן בארכיון",
    A206: "המאמר נעול",
    A207: "המאמר אינו נעול",
    A208: "המאמר אהוב",
    A209: "המאמר אינו אהוב",
    A212: "המאמר נשמר כטיוטה",
    AD200: "המודעה נוצרה בהצלחה",
    AD201: "המודעה עודכנה בהצלחה",
    AD202: "המודעה נמחקה בהצלחה",
    AD203: "המודעה שוכפלה בהצלחה",
    AD204: "המודעה מאוחסנת בארכיון",
    AD205: "המודעה אינה מאוחסנת בארכיון",
    AD206: "המודעה נעולה",
    AD207: "המודעה אינה נעולה",
    AD208: "המודעה נשמרה כטיוטה",
    // B200: "נוצר בהצלחהה ישום", original from Alon
    B200: "הישום נשמרה בהצלחה", // required new by QA team<Miryam>
    B201: "הישום עודכנה בהצלחה",
    B202: "הישום נמחקה בהצלחה",
    B203: "ישום הוכפלה בהצלחה",
    B204: "הישום מאוחסנת בארכיון",
    B205: "הישום אינה מאוחסנת בארכיון",
    B206: "הישום נעולה",
    B207: "הישום אינה נעולה",
    B208: "הישום אהובה",
    B209: "הישום אינה אהובה",
    B210: "ההפניה נשלחה בהצלחה",
    B211: "הישום נשמרה כטיוטה",
    AC200: "קטגוריית מאמר נוצרה בהצלחה",
    AC201: "קטגוריית המאמר עודכנה בהצלחה",
    AC202: "קטגוריית המאמר נמחקה בהצלחה",
    BC200: "קטגוריית הישום נוצרה בהצלחה",
    BC201: "קטגוריית הישום עודכנה בהצלחה",
    BC202: "קטגוריית הישום נמחקה בהצלחה",
    O200: "ביטול תשלום ההזמנה הצליח",
    O201: "ההזמנה נמחקה בהצלחה",
    G200: "הקבוצה נוצרה בהצלחה",
    G201: "הקבוצה עודכנה בהצלחה",
    G202: "הקבוצה נמחקה בהצלחה",
    C200: "הקהילה נוצרה בהצלחה",
    C201: "הקהילה עודכנה בהצלחה",
    C202: "הקהילה נמחקה בהצלחה",
    C203: "קבוצת הקהילה עודכנה בהצלחה",
    C204: "משתמשי הקהילה עודכנו בהצלחה",
    C205: "עורכי הקהילה הוסיפו בהצלחה",
    C206: "עורך הקהילה עודכן בהצלחה",
    C207: "משתמש הקהילה נמחק בהצלחה",
    D200: "הסגמנטציה נוצרה בהצלחה",
    D201: "הסגמנטציה עודכנה בהצלחה",
    D202: "הסגמנטציה נמחקה בהצלחה",
    U200: "המשתמש נוצר בהצלחה",
    U201: "המשתמש עודכן בהצלחה",
    U202: "המשתמש נמחק בהצלחה",
    U203: "כל המשתמשים נמחקו בהצלחה",
    U204: "ההזמנות נשלחו בהצלחה",
    U205: "משתמשים חדשים הועלו בהצלחה",
    U206: "המשתמש מופעל",
    U207: "הפעלת המשתמש בוטלה",
    U208: "המשתמש אושר בהצלחה",
    U209: "כל הבקשות נמחקו בהצלחה",
    U210: "המשתמש מאושר",
    U211: "הסיסמה שונתה בהצלחה",
    U212: "המשתמש נשמר בהצלחה",
    U213: "הודעת משתמש נשמרה בהצלחה",
    M200: "ההודעה נקראה בהצלחה",
    M201: "ההודעה נמחקה בהצלחה",
    AU200: "קוד נשלח לטלפון שלך",
    AU201: "הרישום הצליח",
    AU202: 'דוא"ל נשלח לדוא"ל',
    AU204: "ההרשמה נכשלה, משתמש קיים",
    PR000: "הרשאות לא נמצאו",
    PR200: "הרשאות עודכנו בהצלחה",
    PR201: "הרשאות נמחקו בהצלחה",
    A000: "המאמר לא נמצא",
    A001: "לא תוכל להכיל יותר משני מאמרים נעולים!",
    A002: "פעולה בוצעה בהצלחה",
    A100: "המאמר לא נמצא",
    A101: "אי אפשר ליצור יותר מ-20 כרזות מסתובבות",
    AD000: "המודעה לא נמצאה",
    AD001: "לא תוכל להכיל יותר משתי מודעות נעולות!",
    B000: "הישום לא נמצאה",
    B001: "הסוג אינו תואם לסוג הקטגוריה",
    B002: "אתה לא יכול להחזיק יותר משתי ישומים נעולים!",
    B100: "הישום לא נמצאה",
    B101: "המשתמש אינו בקהילה",
    B102: "דואר אלקטרוני של השולחים אינו מוגדר בקהילה",
    B103: "הישום אינה הפניה",
    AC000: "קטגוריית מאמר לא נמצאה",
    BC000: "קטגוריית הישום לא נמצאה",
    BC001: "סוג קטגוריה לא חוקי",
    P000: "ההתראה לא נמצאה",
    P001: "אין אפשרות ליצור התראה לפני השעה הנוכחית",
    P002: "אין אפשרות לעדכן התראה לאחר שחלף הזמן",
    P200: "התראה נוצרה בהצלחה",
    P201: "ההתראה עודכנה בהצלחה",
    P202: "התראה נמחקה בהצלחה",
    O000: "ההזמנה לא נמצאה",
    O001: "אין תמיכה במטבע",
    O002: "לחיצת היד של טרנזילה נכשלה",
    O003: "לתשומת ליבך! מועד ביטול התשלום חלף בהתאם למדיניות ביטול רכישה באשראי. לפרטים נוספים פנה למנהל הקהילה שלך.",
    O004: "התשלום לא הצליח",
    O005: "ספירת פריטים לא חוקית",
    O006: "ההזמנה כבר שולם",
    O007: "ישום היא לא חנות",
    O008: "אין מספיק כרטיסים",
    G000: "הקבוצה לא נמצאה",
    G003: "הקבוצה משוייכת",
    C000: "הקהילה לא נמצאה",
    C001: "המשתמש לא נמצא בקהילה",
    C002: "עורך Cheif לא נמצא",
    D000: "הסגמנטציה לא נמצאה",
    D003: "הסגמנטציה משויכת",
    D004: "לא ניתן לשנות סגמנטציה זו",
    U000: "המשתמש לא נמצא",
    U001: "אין אפשרות לשייך בעלים",
    U002: "אין אפשרות לעורך הראשי",
    U100: "הסיסמה הישנה שהזנת שגויה",
    AU000: "כתובת הדואר האלקטרוני או הסיסמה שהזנת שגויות",
    AU001: "הטלפון שהזנת שגוי",
    AU002: "הקוד שהזנת שגוי",
    AU003: "כתובת הדואר האלקטרוני שהזנת אינה קיימת",
    AU004: "תוקף האסימון פג",
    AU005: "אסימון לא חוקי",
    AU006: "אינך רשום לקהילה",
    AU007: "החשבון שלך אינו פעיל",
    AU008: "החשבון שלך ממתין לאישור",
    AU009: "אין לך הרשאות לפעולה זו",
    AU010: "אימות האסימון נכשל",
    AU011: "לא סופק אסימון",
    AU013: "אין לך גישה לערוך תוכן זה",
    R000: "לא הועברו תאריכים",
    M100: "לא נמצאה הודעה",
    AW000: "העלאת קובץ נכשלה",
    AW001: "תבנית קובץ לא חוקית",
    EM000: 'ניסיון לשלוח דוא"ל ללא משגר',
    F000: "שדה נדרש",
    F001: "צריך להיות טקסט",
    F002: "לא יכול להיות שדה ריק",
    F003: "אורך מינימלי של 6",
    F004: "אורך מרבי של 15",
    F005: "אורך מינימלי של 2",
    F006: "אורך מרבי של 12",
    F007: "צריך להיות בוליאני",
    F008: "צריך להיות מספר",
    F009: "מספר המינום הוא 1",
    F010: "צריך להיות צבע כיוה",
    F011: "צריך להיות מספר טלפון חוקי",
    F012: 'צריך להיות דוא"ל חוקי',
    F013: "צריך להתאים לסיסמה",
    //missing
    P2002: "טלפון או מייל קיימים. אנא הכנס אחר.",
    P2003:
      "על מנת למחוק את הקטגוריה , יש למחוק תחילה את המאמרים המשויכים לקטגוריה זו",
    U214: "הטלפון כבר קיים. אנא הכנס אחר.",
    U215: "האימייל כבר קיים. אנא הכנס אחר.",
    AC001: "קטגוריית המאמרים כבר קיימת",
    AC002: "לא ניתן למחוק קטגוריה המכילה מאמרים",
    BC002: "קטגוריית הישומים כבר קיימת",
    BC003: "לא ניתן למחוק קטגוריה המכילה ישומים",
    G002: "הקבוצה כבר קיימת",
    D002: "הסגמנטציה כבר קיימת",

    // Privacy Settings
    PF100: "הפרטים עודכנו בהצלחה",

    // Messages
    "S-AD000": "יצר מודעה",
    "S-AD001": "עידכן מודעה",
    "S-AD002": "מחק מודעה",
    "S-AD003": "שיכפל מודעה",
    "S-AD004": "הכניס מודעה לארכיון",
    "S-AD005": "הוציא מודעה מהארכיון",
    "S-AD006": "נעל מודעה",
    "S-AD007": "מודעה לא נעולה",
    "S-AD008": "אישר מודעה",
    "S-AD009": "דחה מודעה",
    "S-AD010": "מודעה ממתינה לאישור",
    "S-A000": "יצר מאמר",
    "S-A001": "עידכן מאמר",
    "S-A002": "מחק מאמר",
    "S-A003": "שיכפל מאמר",
    "S-A004": "הכניס מאמר לארכיון",
    "S-A005": "הוציא מאמר מהארכיון",
    "S-A006": "נעל מאמר",
    "S-A007": "מאמר לא נעול",
    "S-A008": "אהב מאמר",
    "S-A009": "אישר מאמר",
    "S-A010": "דחה מאמר",
    "S-A011": "יצר מאמר שממתין לאישור",
    "S-B000": "יצר ישום",
    "S-B001": "עדכן ישום",
    "S-B002": "מחק ישום",
    "S-B003": "שיכפל ישום",
    "S-B004": "הכניס ישום לארכיון",
    "S-B005": "הוציא ישום מהארכיון",
    "S-B006": "נעל ישום",
    "S-B007": "ישום לא נעולה",
    "S-B008": "אהב ישום",
    "S-B009": "אישר ישום",
    "S-B010": "דחה ישום",
    "S-B011": "יצר ישום שממתינה לאישור",
    "S-C000": "יצר קהילה",
    "S-C001": "עידכן קהילה",
    "S-C002": "מחק קהילה",
    "S-P000": "יצר הודעה",
    "S-P001": "עידכן הודעה",
    "S-P002": "מחק הודעה",
    "S-P003": "אישר הודעה",
    "S-P004": "דחה הודעה",
    "S-P005": "יצר הודעה שממתינה לאישור",
    "S-U000": "יצר משתמש",
    "S-U001": "עדכן את המשתמש",
    "S-U002": "מחק משתמש",
    "S-U003": "מחק את כל המשתמשים",
    "S-U004": "הביא משתמשים חדשים",
    "S-U005": "הפעיל משתמש",
    "S-U006": "אישר את כל המשתמשים",
    "S-U007": "אישר משתמש",
    "S-U008": "מחק את כל הבקשות",
  },
};

export type TranslateSchema = typeof he;
