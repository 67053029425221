import React, { useEffect, useState } from 'react'
import "./style.scss"
import WellcomSection from './WellcomeSection/WellcomSection';
import CardComponent from '../../shared/components/CardComponent/CardComponent'
import DraftsListComponent from './DraftsListComponent/DraftsListComponent'
import CurrentWeekEventList from './CurrentWeekEventList/CurrentWeekEventList'
import ArticleStatistics from './ArticleStatistics/ArticleStatistics'
import LatestActivitiesComponent from './LatestActivitiesComponent/LatestActivitiesComponent';
import { Props } from "./props";
import articlePerson from "../../assets/icons/article-person.svg";
import saleIcon from "../../assets/icons/sale-icon.svg";
import CreateAdIcon from "../../assets/icons/create-ad-icon.svg";
import addUserIcon from "../../assets/icons/add-user-icon.svg";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store/StoreContext';
import { flowResult } from 'mobx';
import ImagePlaceholder from "../../assets/images/image-placeholder.png";
import Spinner from '../../components/Spinner/Spinner';
import { Grid } from '@material-ui/core';
export interface Card {
    icon: string;
    content: string;
    path: string;
    mobilePath: string;
}
const DashBoard = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dashboardStore, userStore } = useStore();
    const [isLoading, setIsLoading] = useState(true);
    const dashBoardActionSection: Card[] = [
        { icon: articlePerson, content: t('dashboard.actions.writeArticle'), path: '/articles/create/article', mobilePath: '/mobile/articles/create' },
        { icon: saleIcon, content: t('dashboard.actions.addSale'), path: '/benefits/create/sale', mobilePath: '' },
        { icon: CreateAdIcon, content: t('dashboard.actions.createAd'), path: '/articles/create/ad', mobilePath: '' },
        { icon: addUserIcon, content: t('dashboard.actions.addUsers'), path: '/users/create', mobilePath: '/mobile/users/create' },
    ];
    const [draftsList, setDraftsList] = useState<any>([]);
    const [thisWeekList, setThisWeekList] = useState<any>([]);
    const [popularArticlesData, setPopularArticlesData] = useState<any>({});
    const [latestActivityList, setLatestActivityList] = useState<any>([]);
    const isMobile = window.innerWidth < 736;

    const getDraftList = async () => {
        await flowResult(dashboardStore.getDrafts()).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false)
                    return;
                }
                setDraftsList(response.drafts.map(draft => {
                    return {
                        imgSrc: draft?.image ?? ImagePlaceholder,
                        tag: draft.isBannerRotating ? t('tabNavigation.articles.rotatingBanner') : t('tabNavigation.articles.mainTab'),
                        shortInfo: draft.header,
                        isBanner: draft.isBannerRotating,
                        id: draft.id,
                        template: draft.template,
                    }
                }));
            }
        )
    }

    const getThisWeekList = async () => {
        await flowResult(dashboardStore.getThisWeek()).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false)
                    return;
                }
                setThisWeekList(response.benefits.filter(event => event?.details?.number_of_tickets).map(event => {
                    return {
                        imgSrc: event?.image ?? ImagePlaceholder,
                        title: event.header,
                        totalTickets: event?.details?.number_of_tickets ?? 0,
                        soldTickets: 0, // TO DO - BE
                    }
                }));
            }
        )
    }

    const getPopularArticlesList = async () => {
        await flowResult(dashboardStore.getPopularArticles()).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false)
                    return;
                }
                let totalViews = 0;
                let articles = response.articles;
                articles.forEach((el) => {
                    totalViews += el.views
                });
                let percents: any = [];
                const color = ['#577BF9', '#00208D', '#36E0F6', '#EFDA1F'];
                articles = response.articles.map((article, index) => {
                    let articlePercent = '';
                    if (totalViews > 0) {
                        articlePercent = ((article.views / totalViews) * 100).toFixed(1);
                    } else {
                        articlePercent = (100 / articles.length).toFixed(1);
                    }
                    percents.push(Number(articlePercent));
                    return {
                        amountInPrecent: `${articlePercent}%`, //article.views
                        label: article.header,
                        color: color[index],
                    }
                });
                setPopularArticlesData({
                    legentInfo: articles,
                    data: percents
                });
            }
        )
    }

    const getLatestActivityList = async () => {
        await flowResult(dashboardStore.getLatestActivities()).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false)
                    return;
                }
                setLatestActivityList(response.activities);
            }
        )
    }

    const updateLists = async () => {
        setIsLoading(true);
        await Promise.all([getDraftList(), getThisWeekList(), getPopularArticlesList(), getLatestActivityList()]);
        setIsLoading(false);
    }

    useEffect(() => {
        const communities = userStore.getCommunities();
        const currentCommunity = userStore.getCurrentCommunity();
        if (!communities.length && !Object.keys(currentCommunity).length) {
            setIsLoading(false)
            return;
        }
        updateLists();
    }, [])

    return isLoading ? (<Spinner />) : (
        <Grid container className="dashboard-page">
            <Grid item xs={12}>
            </Grid>
            <Grid container className="main-content dashboard-container" justifyContent="space-between">
                <Grid item className="dashboard-welcome-wrapper" sm={12}>
                    <WellcomSection />
                </Grid>
                <Grid item className="dashboard-actions-wrapper" xs={12}>
                    <Grid container justifyContent="space-between" alignItems='center'>
                        {
                            dashBoardActionSection.map((card, idx) => {
                                if (isMobile && !card.mobilePath) return null
                                return (
                                    <Grid item xs={12} sm={3} key={idx} className="card-container" onClick={() => history.push(!isMobile ? card.path : card.mobilePath)}>
                                        <CardComponent {...card} isLoading={isLoading} openModal={props.openModal} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item className="dashboard-box-row-wrapper after" xs={12} sm={6}>
                    <DraftsListComponent isLoading={isLoading} drafts={[...draftsList]} openModal={props.openModal} />
                </Grid>
                <Grid item className="dashboard-box-row-wrapper before" xs={12} sm={6}>
                    <CurrentWeekEventList isLoading={isLoading} events={[...thisWeekList]} openModal={props.openModal} />
                </Grid>
                <Grid item className="dashboard-box-row-wrapper after" xs={12} sm={6}>
                    <ArticleStatistics isLoading={isLoading} articlesStatistics={popularArticlesData} />
                </Grid>
                <Grid item className="dashboard-box-row-wrapper before" xs={12} sm={6}>
                    <LatestActivitiesComponent isLoading={isLoading} latestActivity={latestActivityList} openModal={props.openModal} />
                </Grid>
            </Grid>
        </Grid>
    )

}


export default DashBoard
