import React from 'react'
import "./ProgressComponent.scss";
import LinearProgress from '@material-ui/core/LinearProgress';
import {  withStyles } from '@material-ui/core/styles';


export interface ProgressComponentProps {
  total: number;
  currentAmount: number;
}
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#577BF9',
  },
}))(LinearProgress);


const ProgressComponent = (props: ProgressComponentProps) => {
  const { total, currentAmount } = props
  const [progress, setProgress] = React.useState(0);
  const maxValue: number = (currentAmount / total) * 100

  React.useEffect(() => {
    let timer: any;
    let interval: any;
    timer = setTimeout(() => {
      interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= maxValue ? maxValue : prevProgress + 10));
      }, 200);
    }, 2600);
    return () => {
      clearTimeout(timer)
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="progress-container">
      <BorderLinearProgress variant="determinate" value={progress} />
    </div>
  )

}


export default ProgressComponent
