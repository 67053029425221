import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class BenefitStore {
    root: RootStore;

    path = "/admin/settings/categories/benefit";
    benefits: any = [];
    platformBenefits: any = [];
    communityBenefits: any = [];
    // Store Benefits - Max price
    maxPrice: number = 100;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setMaxPrice(price) {
        this.maxPrice = price;
    }

    getMaxPrice() {
        return this.maxPrice;
    }

    getStoredBenefits() {
        return this.benefits;
    }

    getStoredPlatformBenefits() {
        return this.platformBenefits;
    }

    getStoredCommunityBenefits() {
        return this.communityBenefits;
    }

    updateContents() {
        this.benefits = this.platformBenefits.concat(this.communityBenefits);
    }

    updatePlatformBenefits(newBenefits) {
        this.platformBenefits = newBenefits;
        this.updateContents();
    }

    updateCommunityBenefits(newBenefits) {
        this.communityBenefits = newBenefits;
        this.updateContents();
    }

    * getBenefits() {
        const response = yield Api.get(this.path);
        if (response.ok && response.data?.success && (response.data?.benefitCategories?.platform?.length || response.data?.benefitCategories?.community?.length)) {
            this.benefits = response.data.benefitCategories.platform.concat(response.data.benefitCategories.community);
            this.platformBenefits = response.data.benefitCategories.platform;
            this.communityBenefits = response.data.benefitCategories.community;
        } else {
            this.benefits = []
            this.platformBenefits = [];
            this.communityBenefits = [];
        }
        return response?.data
    }

    * create(payload: any, role) {
        const response = yield Api.post(this.path, payload);
        if (response.ok && response.data?.success && response.data?.benefitCategory) {
            if (role === 'owner') {
                this.platformBenefits.push(response.data.contentCategory)
            } else {
                this.communityBenefits.push(response.data.contentCategory)
            }
            this.updateContents();
        }
        return response?.data
    }

    * update(payload: any, id, role) {
        const response = yield Api.put(`${this.path}/${id}`, payload);
        if (response.ok && response.data?.success) {
            if (role === 'owner') {
                this.platformBenefits = this.platformBenefits.map((item) => {
                    if (id === item?.id) {
                        return { ...item, ...payload }
                    }
                    return item
                })
            } else {
                this.communityBenefits = this.communityBenefits.map((item) => {
                    if (id === item?.id) {
                        return { ...item, ...payload }
                    }
                    return item
                })
            }
            this.updateContents();
        }
        return response?.data
    }

    * delete(id, role) {
        const response = yield Api.delete(`${this.path}/${id}`);
        if (response.ok && response.data?.success) {
            if (role === 'owner') {
                this.platformBenefits = this.platformBenefits.filter((item) => {
                    return id !== item?.id
                })
            } else {
                this.communityBenefits = this.communityBenefits.filter((item) => {
                    return id !== item?.id
                })
            }
            this.updateContents();
        }
        return response?.data
    }
}
