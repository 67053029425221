import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridCellParams, GridColumnHeaderParams, GridOverlay } from '@material-ui/data-grid';
import Person from "../../../assets/icons/article-person.svg";
import CheckIcon from '@material-ui/icons/Check';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import CommunityLogo from '../../../assets/icons/logo.svg';
import penBox from '../../../assets/images/penBox.png';
import { Button, Checkbox, FormControl, IconButton, Input, InputAdornment, InputLabel, Modal, Grid, MenuItem, Select, Avatar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '../../../components/LeftSideBar/Icons/SearchIcon/SearchIcon';
import "./style.scss";
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/StoreContext';
import { flowResult } from 'mobx';
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import EditUser from './EditUser';
import UserTypeEnum from '../../../models/UserType';
import { useHistory, useParams } from 'react-router-dom';

const ViewCommunity = () => {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const params: any = useParams();
    const id = params.id;
    const { i18n, t } = useTranslation();
    const { userStore, communityStore, groupStore, languageStore } = useStore();
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openAddEditorModal, setOpenAddEditorModal] = useState(false);
    const [community, setCommunity] = useState<any>({ editors: [] });
    const [loadOnce, setLoadOnce] = useState(true);

    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [allUserList, setAllUserList] = useState([]);
    const [allUserSearchList, setAllUserSearchList] = useState([]);
    const [communityUsers, setCommunityUsers] = useState([]);
    const [communityUsersSearchList, setCommunityUsersSearchList] = useState([]);
    const [toAddList, setToAddList] = useState<any>([]);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [itemDeletion, setItemDeletion] = useState(false);
    const [potentialId, setPotentialId] = useState('');
    const [roleType, setRoleType] = useState(null);
    const [permissions, setPermissions] = useState<any>({});
    const [communityGroup, setCommunityGroup] = useState<any>({});
    const [lngDirection, setLngDirection] = useState('');

    const getCommunityData = async (id) => {
        await flowResult(communityStore.getCommunityData(id)).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'))
                    return;
                }
                const community = response.community;
                setCommunity(community);
                setCommunityGroup(community.group);
                setSelectedGroup(community.group.id);
                setPermissions(community.permissions?.[0] ?? {})
            }
        );
    }

    const getCommunityUsers = async (id) => {
        await flowResult(communityStore.getCommunityUsers(id)).then(
            async (response: any) => {
                if (!response?.success) {
                    return;
                }
                setCommunityUsers(response.users)
                setCommunityUsersSearchList(response.users)
                getAllUserList(response.users);
            }
        );
    }

    const getAllUserList = async (comUsers) => {
        const params = { limit: 50, role: 'USER' }
        await flowResult(communityStore.getAllUsers(params)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
                comUsers = comUsers.map((user: any) => user.id);
                const nonComUsers = response.users.filter((user: any) => comUsers.indexOf(user.id) === -1)
                setAllUserList(nonComUsers)
                setAllUserSearchList(nonComUsers)
            }
        );
    }

    const getData = async () => {
        let currentCommunityID = id;
        if (!currentCommunityID) {
            const currentCommunity = userStore.getCurrentCommunity();
            currentCommunityID = currentCommunity.id
        }
        await Promise.all([getCommunityData(currentCommunityID), getCommunityUsers(currentCommunityID)]);
        if (loadOnce) {
            setLoadOnce(false)
            const storedGroups = groupStore.getStoredGroups();
            setGroups(storedGroups);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setRoleType(userStore.getRoleType());
        getData();
    }, [])

    const handleUserSearch = (setSearch, list) => (e) => {
        const value = e.target.value.toLocaleLowerCase();
        if (value) {
            setSearch(list.filter((user: any) => {
                const name = `${user.firstName} ${user?.lastName}`.toLocaleLowerCase();
                return name.indexOf(value) !== -1
            }));
        } else {
            setSearch(list)
        }
    }

    const handleSelect = (id) => {
        if (toAddList.indexOf(id) === -1) {
            setToAddList([...toAddList, id]);
        } else {
            setToAddList(toAddList.filter(el => el !== id));
        }
    }

    const handleAddUser = async () => {
        setIsLoading(true);
        setOpenAddUserModal(false);
        let data = {
            users: [...toAddList.map(el => {
                return { id: el }
            })]
        }
        await flowResult(communityStore.addUsers(data, community.id)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
            }
        );
        getData();
    }

    const handleAddEditor = async () => {
        setIsLoading(true);
        setOpenAddUserModal(false);
        let data = {
            users: [...toAddList.map(el => {
                return { id: el }
            })]
        }
        await flowResult(communityStore.addEditors(data, community.id)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
            }
        );
        getData();
    }

    const handleUpdateGroup = async () => {
        setIsLoading(true);
        await flowResult(communityStore.updateCommunityGroup(community.id, selectedGroup)).then(
            (response: any) => {
                handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'))
            }
        );
        setIsLoading(false);
    }

    const handleNotificationOpen = (message = '', isDelete = false, id = '') => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (isDelete) {
            setPotentialId(id)
            setButtonText(t('common.buttons.confirm'));
            setItemDeletion(true);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (isDelete = false) => () => {
        if (isDelete) {
            setItemDeletion(false);
            handleDeleteUser(potentialId)
        }
        setOpenNotificationModal(false);
    }

    const handleDeleteUser = async (userId) => {
        setIsLoading(true);
        await flowResult(communityStore.deleteUser(community.id, userId)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
            }
        );
        getData();
    }

    const openEditUser = (user) => (event) => {
        communityStore.userEdit = user;
        const rowElement = event.currentTarget.parentElement.parentElement.parentElement;
        communityStore.editRowAnchor = rowElement;
        communityStore.editRowWidth = rowElement.clientWidth - 30;
    }

    const handleSaveEdit = async (data, userID) => {
        setIsLoading(true);
        await flowResult(communityStore.updateEditor(data, community.id, userID)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
            }
        );
        getData();
    }

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: t('signUp.fields.firstName.label'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row?.firstName
                return (
                    <span className="table-row-title">{name}</span>
                );
            },
        },
        {
            field: 'lastName',
            headerName: t('signUp.fields.lastName.label'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row?.lastName
                return (
                    <span className="table-row-title">{name}</span>
                );
            },
        },
        {
            field: 'phone',
            headerName: t('signUp.fields.phone.label'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const phone = params.row?.phone
                return (
                    <span className="table-row-title">{phone ? `${phone.slice(0, 3)} ${phone.slice(3, 10)}` : ''}</span>
                );
            },
        },
        {
            field: 'email',
            headerName: t('signUp.fields.email.label'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const email = params.row?.email;
                return (
                    <span className="table-row-title email">{email}</span>
                );
            },
        },
        {
            field: 'canRemove',
            headerName: t('community.view.canRemoveArticles'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column can-remove-th`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const canRemove = permissions?.articles || permissions?.banners || permissions?.ads;
                return (
                    <div>
                        {
                            canRemove ? (
                                <CheckIcon color="secondary" />
                            ) : null
                        }
                    </div>
                )
            },
        },
        {
            field: 'isPermitted',
            headerName: t('community.view.free'),
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isPermitted = params.row?.isPermitted ?? false;
                return (
                    <div>
                        {
                            isPermitted ? (
                                <CheckIcon color="secondary" />
                            ) : null
                        }
                    </div>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('community.list.actions'),
            sortable: false,
            width: 180,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column text-center`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const id = params.row.id;
                return (
                    <div id={`grid-row-${id}`} className={`table-actions cell-actions`}>
                        <IconButton aria-label="edit selected item" className="hover-item"
                            onClick={openEditUser(params.row)}
                        >
                            <IconComponent icon={EditIcon} />
                        </IconButton>
                        <IconButton aria-label="delete selected item" className="hover-item"
                            onClick={() => handleNotificationOpen(t("errorMessages.deleteOneUser"), true, id)}
                        >
                            <IconComponent icon={DeleteIcon} />
                        </IconButton>
                    </div>
                );
            },
        },
    ].map((column) => (
        {
        ...column,
        disableClickEventBubbling: true,
    }));

    const CustomGridMessage = () => {
        return (
            <GridOverlay>
                <p>{t('users.allUsers.noContent.message1')}</p>
            </GridOverlay>
        )
    }

    return (
        <div className="main-content view-community">
            {
                isLoading ? (<Spinner />) : (
                    <>
                        <div className="page-heading-wrap">
                            <h2 className="page-heading">
                                {community.communityName}
                            </h2>
                        </div>
                        <div className="community-page">
                            <Grid container className="content-grid" justifyContent="space-between">
                                <Grid item xs={4} md={3} className="box-wrapper-container">
                                    <Grid container justifyContent="center" className="box-wrapper" alignItems="center" direction="column">
                                        <Grid item xs={12} className="wrapper-image">
                                            <Avatar className="circle-container logo" src={community?.imageTransparentLogo ?? CommunityLogo} alt="logo" />
                                        </Grid>
                                        <Grid item xs={12} className="wrapper-header text-center">
                                            {t('community.list.group')}
                                        </Grid>
                                        <Grid item xs={10} lg={9} className="grid-row">
                                            {
                                                (roleType === UserTypeEnum.owner || roleType === UserTypeEnum.associatedOwner || roleType === UserTypeEnum.admin) ? (
                                                <FormControl className="select-form-control">
                                                    <Select
                                                        className="select-group"
                                                        labelId="group-label"
                                                        id="group"
                                                        displayEmpty
                                                        defaultValue=""
                                                        value={selectedGroup}
                                                        onChange={(e: any) => setSelectedGroup(e.target.value)}
                                                    >
                                                        {
                                                            groups.map((group: any, index) => (
                                                                <MenuItem key={index}
                                                                    value={group.id}>{group.name}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                ) : (
                                                    <h2>{communityGroup?.name ?? ''}</h2>
                                                )
                                            }
                                        </Grid>
                                        <Grid item xs={12} className={
                                            `grid-row
                                            ${
                                                roleType !== UserTypeEnum.owner &&
                                                roleType !== UserTypeEnum.associatedOwner &&
                                                roleType !== UserTypeEnum.admin ? 'element-invisible' : ''
                                            }`
                                            }>
                                            <Button variant="contained" color="primary" onClick={handleUpdateGroup}>{t('common.buttons.update')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} className="box-wrapper-container">
                                    <Grid container justifyContent="center" className="box-wrapper" alignItems="center" direction="column">
                                        <Grid item xs={12} className="wrapper-image">
                                            <div className="circle-container">
                                                <img src={Person} alt="Person" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="wrapper-header text-center">
                                            {t('community.list.users')}
                                        </Grid>
                                        <Grid item xs={9} className="grid-row">
                                            <h2>{communityUsers?.length ? communityUsers.length : 0}</h2>
                                        </Grid>
                                        <Grid item xs={12} className="grid-row">
                                            <Button variant="contained" color="primary" onClick={() => history.push('/users/create')}>{t('users.create.addUser')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} className="box-wrapper-container">
                                    <Grid container justifyContent="center" className="box-wrapper" alignItems="center" direction="column">
                                        <Grid item xs={12} className="wrapper-image">
                                            <div className="circle-container flex-center">
                                                <img src={penBox} alt="penBox" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="wrapper-header text-center">
                                            {t('users.create.chiefEditor')}
                                        </Grid>
                                        <Grid item xs={9} className="grid-row">
                                            <h2>{`${community?.chiefEditors?.[0]?.firstName ?? ''} ${community?.chiefEditors?.[0]?.lastName ?? ''}`}&nbsp;</h2>
                                        </Grid>
                                        <Grid item xs={12} className="grid-row">
                                            <div className="email">
                                                {community?.chiefEditors?.[0]?.email ?? ''}&nbsp;
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div>
                                <div className="table-grid-header">{t('community.list.editors')}</div>
                                <div className={`table-grid`}>
                                    <DataGrid
                                        autoHeight={true}
                                        rows={community.editors}
                                        columns={lngDirection === "ltr" ? columns : columns.reverse()}
                                        rowHeight={90}
                                        headerHeight={56}
                                        disableColumnMenu={true}
                                        hideFooter={true}
                                        components={{
                                            NoRowsOverlay: CustomGridMessage,
                                        }}
                                    />
                                    <EditUser handleSaveEdit={handleSaveEdit} />
                                </div>
                            </div>
                        </div>
                        <div className="submit-button">
                            <Button variant="contained" color="primary" onClick={() => setOpenAddEditorModal(true)}>{t('community.view.addEditor')}</Button>
                        </div>
                        <Modal
                            open={openAddUserModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            className="modal-editors"
                        >
                            <div className="editors-container">
                                <div className="editors-close">
                                    <div className="editors-header">{t('community.view.chooseFromUserList')}</div>
                                    <CloseIcon className="icon-close"
                                        onClick={() => {
                                            setToAddList([]);
                                            setOpenAddUserModal(false);
                                        }}
                                    />
                                </div>
                                <div className="user-container">
                                    <div className="user-search">
                                        <FormControl className="search-field-container">
                                            <InputLabel htmlFor="search-field">{t('common.fieldHeaders.search')}</InputLabel>
                                            <Input
                                                id="search-field"
                                                type="text"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                }
                                                onChange={handleUserSearch(setAllUserSearchList, allUserList)}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="user-list">
                                        {
                                            allUserSearchList.map((user: any, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>
                                                            <IconButton aria-label="select item" className="select-checkbox" onClick={() => handleSelect(user.id)}>
                                                                <Checkbox color="primary" />
                                                            </IconButton>
                                                            <span>{`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="editors-buttons">
                                    <Button variant="contained" color="primary" onClick={() => handleAddUser()} disabled={!toAddList?.length}>{t('community.view.addSelected')}</Button>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            open={openAddEditorModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            className="modal-editors"
                        >
                            <div className="editors-container">
                                <div className="editors-close">
                                    <div className="editors-header">{t('community.view.chooseFromUserList')}</div>
                                    <CloseIcon className="icon-close"
                                        onClick={() => {
                                            setToAddList([]);
                                            setOpenAddEditorModal(false);
                                        }}
                                    />
                                </div>
                                <div className="user-container">
                                    <div className="user-search">
                                        <FormControl className="search-field-container">
                                            <InputLabel htmlFor="search-field">{t('common.fieldHeaders.search')}</InputLabel>
                                            <Input
                                                id="search-field"
                                                type="text"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                }
                                                onChange={handleUserSearch(setCommunityUsersSearchList, communityUsers)}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="user-list">
                                        {
                                            communityUsersSearchList.map((user: any, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>
                                                            <IconButton aria-label="select item" className="select-checkbox" onClick={() => handleSelect(user.id)}>
                                                                <Checkbox color="primary" />
                                                            </IconButton>
                                                            <span>{`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="editors-buttons">
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            handleAddEditor()
                                            setOpenAddEditorModal(false)
                                        }}
                                        disabled={!toAddList?.length}
                                    >{t('community.view.addSelected')}</Button>
                                </div>
                            </div>
                        </Modal>
                        <NotificationModal openModal={openNotificationModal} buttonText={buttonText}
                            handleClose={handleNotificationClose(false)} handleButtonClick={handleNotificationClose(itemDeletion)}
                            message={notificationMessage} />
                    </>
                )}
        </div>
    )
}

export default ViewCommunity
