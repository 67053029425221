import React, { useEffect, useState } from "react";
import { useStore } from "../../../store/StoreContext";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link as MuiLink,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import { flowResult } from "mobx";
import { observer } from "mobx-react-lite";
import TermsAndPrivacyPolicy from "../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy";
import LanguageSelection from "../../../components/LanguageSelection/LanguageSelection";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import Spinner from '../../../components/Spinner/Spinner';
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import i18n, { lngChange } from "../../../i18n/i18n";

const SignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { userStore, languageStore } = useStore();
  const params: any = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      // platform_id: "",
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const watchFields = watch();

  const validate = async (form: any) => {
    // @ts-ignore
    const resolver = yupResolver(VALIDATIONS)(form, null, {
      criteriaMode: "firstError",
    });
    setIsDisabled(Object.values((await resolver).errors).length > 0);
  };

  useEffect(() => {
    validate(watchFields);
  }, [watchFields]);

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  useEffect(() => {
    const token = params?.mobile_token ?? '';
    if (token) {
      localStorage.setItem('access_token', token);
      localStorage.setItem('mobile_token', token);
      userStore.setToken(token);

      userStore.isAuthenticated = true;
      history.push('/mobile');
      return;
    }
    const windowHeight = window.outerHeight;
    if (windowHeight < 768) {
      setWindowHeight(`${windowHeight}px`);
    } else {
      setWindowHeight("100%")
    }
    setIsLoading(false);
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const [submitError, setSubmitError] = useState("");

  const handleSubmitCheck = (payload: any) => {
    if (errorCount >= 3) {
      setSubmitError(t('errorMessages.login.threeTimesError'));
      return;
    }
    setIsLoading(true);
    setSubmitError("");
    const _platformID = userStore.getPlatformID();
    if(!_platformID) {
      handleNotificationOpen(t('validationMessages.auth.signIn.noPlatform'));
      setIsLoading(false);
      return;
    }
    const data = {
      ...payload,
      email: payload.email.trim().toLowerCase(),
      platform_id: _platformID,
    };
    flowResult(userStore.login(data)).then((response: any) => {
      if (!response || !response?.success) {
        setIsLoading(false);
        if (response?.code === "AU000") {
          setErrorCount(errorCount + 1)
        }
        setSubmitError(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
      }
    });
  };

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  return isLoading ? (<Spinner />) : (
    <div className="scroll-container" style={{ height: windowHeight }}>
      <div className="sign-form-container">
        <div className="inner-full-container">
          <form
            onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
            className="sign-form additional-box"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className="sign-container"
            >
              <Grid item xs={10} sm={6} md={5} lg={4}>
                <Grid container>
                  <Grid item xs={12} className="sign-header">
                    <div className="double-column">
                      <div>
                        <h2>{t("common.signInLabel")}</h2>
                        <h3>
                          {t("common.doNotHaveAccount")}{" "}
                          <Link
                            className="cursor-pointer link no-underline"
                            to="/sign-up"
                          >
                            {t("common.signUpLabel")}
                          </Link>
                        </h3>
                      </div>
                      <LanguageSelection />
                    </div>
                  </Grid>
                  <Grid item xs={12} className="sign-body">
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        className="sign-image-container flex-center"
                      >
                        <div className="sign-image-and-icon">
                          <img
                            src="https://www.pinclipart.com/picdir/big/165-1653686_female-user-icon-png-download-user-colorful-icon.png"
                            alt=""
                            className="sign-image"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} className="sign-field">
                        <FormHelperText error>{submitError}&nbsp;</FormHelperText>
                      </Grid>
                      <Grid item xs={12} className="sign-field">
                        <FormControl className="email-field-container">
                          <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <TextField
                                  id="email"
                                  label={t("signUp.fields.email.label")}
                                  value={value}
                                  onChange={onChange}
                                />
                                <p className="field-error">
                                  {errors?.email?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="sign-field">
                        <FormControl className="password-field-container">
                          <InputLabel htmlFor="password" required>
                            {t("signIn.fields.password.label")}
                          </InputLabel>
                          <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Input
                                  id="password"
                                  type={showPassword ? "text" : "password"}
                                  onChange={onChange}
                                  value={value}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    >
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        className="visibility-icon"
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <p className="field-error">
                                  {errors?.password?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl>
                          <InputLabel id="platform-label" required>
                            {t("settings.manageCategories.platform")}
                          </InputLabel>
                          <Controller
                            control={control}
                            name="platform_id"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Select
                                  labelId="platform-label"
                                  id="platform"
                                  displayEmpty
                                  defaultValue=""
                                  onChange={onChange}
                                >
                                  {platformList.map((cat, index) => (
                                    <MenuItem key={index} value={cat.id}>
                                      {cat.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <p className="field-error">
                                  {errors?.platform_id?.message ?? ""}&nbsp;
                                </p>
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12} className="sign-buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isDisabled}
                        >
                          {t("common.signInLabel")}
                        </Button>
                        <Link
                          className="cursor-pointer link"
                          to="/forgot-password"
                        >
                          {t("common.forgotPassword")}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10} sm={6} md={5} lg={4} className="border-separator">
                <div className="border-line" />
                <div className="border-text">{t("signIn.sms.or")}</div>
                <div className="border-line" />
              </Grid>
            </Grid>
            <Grid
              className="sign-container"
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10} sm={6} md={5} lg={4} className="second-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/login-with-sms")}
                >
                  {t("signIn.sms.navTo")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="terms-and-policy cursor-pointer">
            <MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>
              {t("common.termsAndPrivacy")}
            </MuiLink>
          </div>
        </div>
        <TermsAndPrivacyPolicy
          openModal={openModal}
          onClose={() => setOpenModal(false)}
        />
      </div>
      <NotificationModal
        openModal={openNotificationModal}
        message={notificationMessage}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        buttonText={t("common.buttons.close")}
      />
    </div>
  );
};

export default observer(SignIn);
