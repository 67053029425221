import React, {useEffect, useState} from 'react'
import {Props} from "./props";
import AllUsers from "./AllUsers/AllUsers";
import ImportFromFile from "./ImportFromFile/ImportFromFile";
import InviteToApp from "./InviteToApp/InviteToApp";
import WaitingForApproval from "./WaitingForApproval/WaitingForApproval";
import TabsComponent from "../../shared/components/Tabs/TabsComponent";
import {routes} from "./routes";
import "./style.scss";
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/StoreContext';
import UserTypeEnum from '../../models/UserType';

import {Route, Switch, useRouteMatch} from "react-router-dom";
import CreateUser from "./Create/Create";

const Users = (props: Props) => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const [roleType, setRoleType] = useState(null);
    const { userStore } = useStore();

    useEffect(() => {
        setRoleType(userStore.getRoleType());
    }, [userStore.roleType]);

    const tabs = [
        {
            title: t('tabNavigation.users.allUsers'),
            component: AllUsers,
            url: `${path}/list`
        },
        {
            title: t('tabNavigation.users.importFile'),
            component: ImportFromFile,
            url: `${path}/import`,
        },
        {
            title: t('tabNavigation.users.inviteApp'),
            component: InviteToApp,
            url: `${path}/invite`
        },
        {
            title: t('tabNavigation.users.waitingApproval'),
            component: WaitingForApproval,
            url: `${path}/approval`
            // hide: roleType !== UserTypeEnum.owner,
        },
        {
            title: "Create user",
            component: CreateUser,
            url: `${path}/create`,
            hide: true
        }
    ]
    return (
        <>
            <div className="main-content main-content-tabs">
                <Switch>
                    <TabsComponent data={tabs} />
                    {
                        routes.map((route, index) => (
                            <Route key={index} path={`${path}/${route.path}`} component={route.component}/>
                        ))
                    }
                </Switch>
            </div>
        </>
    )
}

export default observer(Users)
