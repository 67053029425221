import React from 'react'
import "./style.scss";

interface Props {
    className?: string;
    text?: string;
}

const Spinner = ({ className, text = '' }: Props) => {
    return (
        <div id="spinner-loader">
            <div className={`lds-ring ${className}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {
                text ? (
                    <div className="spinner-text">
                        {text}
                    </div>
                ) : null
            }
        </div>
    );
};

export default Spinner;