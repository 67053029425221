import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { lngChange, SUPPORTED_LANGUAGES } from "../../i18n/i18n";
import "./styles.scss";
import { useStore } from "../../store/StoreContext";
import { observer } from "mobx-react-lite";

const LanguageSelection = () => {
  const { languageStore } = useStore();
  const [selectedLang, setSelectedLang] = useState("");

  useEffect(() => {
    const language = languageStore.getLanguage();
    setSelectedLang(language);
    lngChange(language);
    languageStore.toggleLanguage();
  }, []);

  const handleLanguageChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value === selectedLang) {
      return;
    }
    languageStore.setLanguage(value);
    setSelectedLang(value);
    languageStore.toggleLanguage();
    lngChange(value);
  };

  return (
    <div className="select-language-container flex-center">
      <Select
        id="language"
        value={selectedLang}
        className="select-language"
        title={"Not implemented yet"}
        onChange={handleLanguageChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {SUPPORTED_LANGUAGES.map((lang, idx) => (
          <MenuItem key={idx} value={lang}>{lang.charAt(0).toUpperCase() + lang.slice(1)}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default observer(LanguageSelection);
