import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class PrivacyStore {
    root: RootStore;

    path = "/admin/platform";
    privacy: any = {};

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    getPrivacyPolicy() {
        return this.privacy;
    }

    * getPrivacyInformation(id) {
        const response = yield Api.get(`${this.path}/${id}/details`);
        if (response.ok && response.data?.success) {
            this.privacy = response.data.details
        }
        return response?.data
    }

    * update(payload: any, id) {
        const response = yield Api.put(`${this.path}/${id}/details`, payload);
        if (response.ok && response.data?.success) {
            this.privacy = payload
        }
        return response?.data
    }
}
