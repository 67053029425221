import React from 'react'
import PrimaryBoxComponent from '../../../shared/components/PrimaryBox/PrimaryBox';
import Draft, { DraftComponentProps } from './DraftComponent/DraftComponent';
import "./DraftsListComponent.scss";
import { useTranslation } from 'react-i18next';

export interface DraftsListProps {
    drafts: DraftComponentProps[];
    isLoading: boolean;
    openModal?: Function
}



const DraftsListComponent = (props: DraftsListProps) => {
    const { t } = useTranslation();
    return (
        <PrimaryBoxComponent>

            <div className="draft-list-container">
                <div className="drafts-title">{t('dashboard.draftsListComponent')}</div>
                <div className="scroll-container">
                    {
                        props.drafts.map((draft, idx) => {
                            return (
                                <Draft openModal={props.openModal} isLoading={props.isLoading} {...draft} key={idx} />
                            )
                        })
                    }
                    {
                        !props.drafts?.length ? (<p>{t('dashboard.noDrafts')}</p>) : (<></>)
                    }
                </div>
            </div>
        </PrimaryBoxComponent>
    )

}


export default DraftsListComponent