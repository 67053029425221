import React, { useEffect, useState } from 'react'
import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AddIcon from '@material-ui/icons/Add';
import { useStore } from '../../../store/StoreContext';
import { flowResult } from "mobx";
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import Spinner from '../../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const ManageGroups = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { groupStore, userStore } = useStore();

    const [isLoading, setIsLoading] = useState(true);
    const [isCreated, setIsCreated] = useState(false);
    const [isBtnClicked, setIsBtnClicked] = useState(false);
    const [groupList, setGroupList] = useState(
        [
            {
                'id': '',
                'name': '',
                'isEdited': false,
                'isNew': false,
            }
        ]
    );


    useEffect(() => {
        setGroupList(groupStore.getStoredGroups());
        setIsLoading(false)
    }, [])

    const handleAddItem = () => {
        const newGroup = {
            'id': '',
            'name': '',
            'isEdited': false,
            'isNew': true,
        }
        setGroupList([...groupList, newGroup])
    }

    const handleChange = (itemIndex) => (e) => {
        setGroupList(groupList.map((el, index) => {
            if (index === itemIndex) {
                el['name'] = e.target.value
                if (el.id) {
                    el.isEdited = true
                }
            }
            return el
        }))
    }

    const handleDeleteItem = (itemIndex) => {
        if (groupList[itemIndex].id) {
            const item = groupList[itemIndex];
            setIsLoading(true);
            flowResult(groupStore.delete(item.id)).then(
                (response: any) => {
                    setIsLoading(false);
                    if (!response?.success) {
                        handleNotificationOpen(0, response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                        return;
                    }
                    setGroupList(groupStore.getStoredGroups());
                }
            )
        } else {
            setGroupList(groupList.filter((el, index) => {
                return index !== itemIndex
            }))
        }
    }

    const handleSaveFlow = (flow, message) => {
        setIsBtnClicked(true);
        setIsLoading(true);
        flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(0, response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'))
                }
                setIsCreated(true);
                setButtonText(t('common.buttons.close'));
                setNotificationMessage(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'));
                setGroupList(groupStore.getStoredGroups());
                setOpenNotificationModal(true);
                setIsLoading(false);
                setIsBtnClicked(false);
            }
        )
    }

    const handleSubmitCheck = (e) => {
        e.preventDefault();
        groupList.forEach((el) => {
            if (el.name) {
                const data = {
                    'name': el.name,
                }
                const path = 'GROUP';
                if (el.isNew) {
                    handleSaveFlow(groupStore.create(data), `${path} POST`);
                }
                if (el.isEdited) {
                    handleSaveFlow(groupStore.update(data, el.id), `${path} UPDATE`);
                }
            }
        })
    }

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [itemDeletion, setItemDeletion] = useState(false);
    const [itemID, setItemID] = useState(0);

    const handleNotificationOpen = (id = 0, message = '') => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (!message) {
            setButtonText(t('common.buttons.yes'));
            setNotificationMessage(t('errorMessages.deleteQuestion'));
            setItemID(id);
            setItemDeletion(true);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (isDelete = false) => () => {
        if (isDelete) {
            setItemDeletion(false);
            handleDeleteItem(itemID)
            setItemID(-1);
        }
        if (isCreated) {
            const communities = userStore.getCommunities();
            if (!communities.length) {
                history.push("/community/create")
            }
        }
        setOpenNotificationModal(false);
    }

    return isLoading ? <Spinner /> : (
        <div className="main-wrapper">
            <h2 className="page-heading">
                {t('settings.manageGroups.title')}
            </h2>
            <form className="" autoComplete="off" onSubmit={handleSubmitCheck}>
                <div className="main-wrapper">
                    <Grid container className="content-grid" justifyContent="space-between">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} className="column-headers">
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item xs={4} md={3} lg={2} className="column-name item-margin">
                                            <span>{t('settings.manageGroups.groupName')}</span>
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    groupList.map((group, index) => {
                                        return (
                                            <Grid key={index} item xs={12} className="item-box">
                                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={4} md={3} lg={2} className="item-margin">
                                                        <TextField
                                                            id="group-1"
                                                            label={t('community.list.name')}
                                                            placeholder={t('settings.manageGroups.groupName')}
                                                            InputLabelProps={{ shrink: true }}
                                                            value={group.name}
                                                            onChange={handleChange(index)}
                                                        />
                                                        <p className="field-error">
                                                            {group.name.length > 20
                                                                ? t("validationMessages.articles.addArticle.maxHeader", { max: 20 })
                                                                : ""}&nbsp;
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={1} className="text-center">
                                                        <IconButton aria-label="delete current item" className="hover-item" onClick={() => handleNotificationOpen(index)}>
                                                            <IconComponent icon={DeleteIcon} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                <Grid item xs={7} className="add-item-box hover-item" onClick={handleAddItem}>
                                    <AddIcon />
                                    <span>{t('settings.myCommunity.addGroup')}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="submit-buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={groupList.length === 0
                            || isBtnClicked
                            || groupList.filter(el => !el.name || el.name.length > 20).length > 0
                        }
                    >{t('common.buttons.save')}</Button>
                </div>
            </form>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose(false)}
                handleButtonClick={handleNotificationClose(itemDeletion)}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default observer(ManageGroups);
