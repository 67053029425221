import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridCellParams, GridColumnHeaderParams, GridOverlay } from '@material-ui/data-grid';
import IconComponent from "../../shared/components/IconComponent/IconComponent";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import "./style.scss";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/StoreContext';
import { flowResult } from 'mobx';
import Spinner from '../../components/Spinner/Spinner';
import ImagePlaceholder from "../../assets/images/image-placeholder.png";
import { IconButton } from '@material-ui/core';
import NotificationModal from '../../components/NotificationModal/NotificationModal';
import { observer } from 'mobx-react-lite';

const CommunityList = () => {
    const { i18n, t } = useTranslation();
    const { communityStore, languageStore } = useStore();
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [itemDeletion, setItemDeletion] = useState(false);
    const [potentialId, setPotentialId] = useState('');
    const resolutionAbove1024 = window.innerWidth > 1024;
    const resolutionIs1024 = window.innerWidth === 1024;
    const [lngDirection, setLngDirection] = useState('');

    const updateLists = async (params: any = {}) => {
        setIsLoading(true);
        setList([]);

        const searchParams = communityStore.getSearchParams();
        params = {
            ...params,
            ...searchParams,
        }
        await flowResult(communityStore.getCommunities(params)).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.communities;
                setList(data)
            }
        )
        setIsLoading(false)
    }

    const handleFlow = (flow) => {
        setIsLoading(true);
        flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : (response?.message ?? t("apiMessages.0")));
                    setIsLoading(false);
                    return;
                }
                updateLists();
            }
        );
    }

    useEffect(() => {
        updateLists();
    }, [])

    const handleNotificationOpen = (message = '', isDelete = false, id = '') => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (isDelete) {
            setPotentialId(id)
            setButtonText(t('common.buttons.confirm'));
            setItemDeletion(true);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (isDelete = false) => () => {
        if (isDelete) {
            handleDelete(potentialId)
        }
        setOpenNotificationModal(false);
    }

    const handleDelete = (id) => {
        handleFlow(communityStore.delete(id));
    }

    useEffect(() => {
        if (communityStore.triggerUpdateList) {
            updateLists();
            communityStore.triggerUpdateList = false;
        }
    }, [communityStore.triggerUpdateList]);

    // Detecting Language Switch
    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    const columns: GridColDef[] = [
        {
            field: 'image',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.imageTransparentLogo;
                return (
                    <div className="table-row-image-container" >
                        <img src={row || ImagePlaceholder} alt={row || "Article image"}/>
                    </div>
                )
            },
        },
        {
            field: 'name',
            headerName: t('community.list.name'),
            flex: 1,
            minWidth: 100,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row.communityName;
                const id = params.row.id;
                return (
                    <Link to={`/community/view/${id}`} className="go-to-view table-row-title">{name}</Link>
                );
            },
        },
        {
            field: 'group',
            headerName: t('community.list.group'),
            width: resolutionAbove1024 ? 300 : 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.group.name}</span>
                );
            },
        },
        {
            field: 'editors',
            headerName: t('community.list.editors'),
            width: resolutionAbove1024 ? 200 : (resolutionIs1024 ? 100 : 75),
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.editors}</span>
                );
            }
        },
        {
            field: 'users',
            headerName: t('community.list.users'),
            width: resolutionAbove1024 ? 200 : (resolutionIs1024 ? 100 : 75),
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.users}</span>
                );
            }
        },
        {
            field: 'actions',
            headerName: t('community.list.actions'),
            sortable: false,
            width: resolutionAbove1024 ? 200 : (resolutionIs1024 ? 100 : 75),
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column text-center`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                const id = data.id;
                return (
                    <div id={`grid-row-${id}`} className={`table-row-actions cell-actions`}>
                        <Link className="view-link flex-center" to={`/community/edit/${id}`}>
                            <IconButton aria-label="edit selected item" className="hover-item">
                                <IconComponent icon={EditIcon} />
                            </IconButton>
                        </Link>

                        <IconButton aria-label="delete selected item" className="hover-item"
                                    onClick={() => handleNotificationOpen(t("errorMessages.deleteCommunity"), true, id)}
                        >
                            <IconComponent icon={DeleteIcon} />
                        </IconButton>
                    </div>
                );
            },
        },
    ].map((column) => (
        {
        ...column,
        disableClickEventBubbling: true,
    }));

    const CustomGridMessage = () => {
        return (
            <GridOverlay>
                <p>{t('community.list.noCommunity')}</p>
            </GridOverlay>
        )
    }
    return isLoading ? (<Spinner/>) : (
        <>
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('common.selectFilters.allCommunities')}
                </h2>
            </div>
            <div className="community-page">
                <div>
                    <div className={`table-grid`}>
                        <DataGrid
                            autoHeight={true}
                            rows={list}
                            columns={lngDirection === "ltr" ? columns : columns.reverse()}
                            rowHeight={90}
                            headerHeight={56}
                            disableColumnMenu={true}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: CustomGridMessage,
                              }}
                            />
                    </div>
                </div>
            </div>
            <NotificationModal openModal={openNotificationModal} buttonText={buttonText}
                handleClose={handleNotificationClose(false)} handleButtonClick={handleNotificationClose(itemDeletion)}
                message={notificationMessage} />
        </>
    )
}

export default observer(CommunityList)
