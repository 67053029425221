
import "./WellcomSection.scss"
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStore } from '../../../store/StoreContext';
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
export interface WellcomSectionProps {
    userName?: string;
}
const WellcomSection = (props: WellcomSectionProps) => {
    const { userStore } = useStore();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');

    useEffect(() => {
        setUsername(userStore.currentUser?.firstName);
    }, [userStore.triggerUpdateAccount])
    
    return (
        <div className="welcome-container">
            <Typography variant="h2">
                {t('dashboard.welcomeSection.message')} {username ?? 'Alexander'}
            </Typography>
            <div className="welcome-question">{t('dashboard.welcomeSection.question')}</div>
        </div>
    )

}


export default observer(WellcomSection)
