import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class ReportsStore {
  root: RootStore;
  activeTransactionId = null;

  reports: any = [];
  searchParams: any = {};
  searchParamsSurveys: any = {};
  searchParamsPurchases: any = {};
  searchParamsClicks: any = {};
  innerSearchParams: any = {};
  triggerUpdateList: boolean = false;
  triggerUpdateInnerList: boolean = false;
  purchaseInformation: any = {};
  clickInformation: any = {};
  surveyInformation: any = {};
  leadInformation: any = {};
  modalChange: boolean = false;
  isModalOpen: boolean = false;
  // Store Benefits - Max price
  maxPrice: number = 100;

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, {
      root: false,
    });
  }

  setMaxPrice(price) {
    this.maxPrice = price;
  }

  getMaxPrice() {
    return this.maxPrice;
  }

  triggerModalChange() {
    this.modalChange = !this.modalChange;
  }
  
  setIsModalOpen(objectData) {
    this.isModalOpen = objectData;
  }
  
  getIsModalOpen() {
    return this.isModalOpen;
  }

  setSurveyInformation(objectData) {
    this.surveyInformation = objectData;
  }

  getSurveyInformation() {
    return this.surveyInformation;
  }

  setPurchaseInformation(objectData) {
    this.purchaseInformation = objectData;
  }

  getPurchaseInformation() {
    return this.purchaseInformation;
  }

  setClickInformation(objectData) {
    this.clickInformation = objectData;
  }

  getClickInformation() {
    return this.clickInformation;
  }

  setLeadInformation(objectData) {
    this.leadInformation = objectData;
  }

  getLeadInformation() {
    return this.leadInformation;
  }

  getStoredReports() {
    return this.reports;
  }

  setTriggerUpdateList = (data) => {
    console.log("\x1b[33m  file: ReportsStore.ts:91  data:", data)
    this.triggerUpdateList = data
  }

  setSearchParams = (data) => {
    this.searchParams = data;
    this.triggerUpdateList = true;
  };

  setSearchParamsSurveys = (data) => {
    this.searchParamsSurveys = data;
    this.triggerUpdateList = true;
  };

  setSearchParamsPurchases = (data) => {
    this.searchParamsPurchases = data;
    this.triggerUpdateList = true;
  };

  setSearchParamsClicks = (data) => {
    this.searchParamsClicks = data;
    this.triggerUpdateList = true;
  };

  setInnerSearchParams = (data) => {
    this.innerSearchParams = data;
    this.triggerUpdateInnerList = true;
  };

  getSearchParams = () => {
    return this.searchParams;
  };

  getSearchParamsSurveys = () => {
    return this.searchParamsSurveys;
  };

  getSearchParamsPurchases = () => {
    return this.searchParamsPurchases;
  };

  getSearchParamsClicks = () => {
    return this.searchParamsClicks;
  };

  getInnerSearchParams = () => {
    return this.innerSearchParams;
  };

  * deletePurchase(id) {
    const response = yield Api.put(`admin/reports/purchases/${id}`, {});
    if (response.ok && response.data?.success) {
      this.reports = this.reports.filter((item) => {
        return id !== item.id;
      });
    }
    return response?.data;
  }

  // Dashboard requests

  * getPurchasesForChart(date) {
    const response = yield Api.get(
      `admin/reports/dashboard/purchases?from_date=${date.sDateQuery}&to_date=${date.eDateQuery}`
    );
    return response?.data;
  }

  * getPushRateForChart(date) {
    const response = yield Api.get(
      `admin/reports/dashboard/push?from_date=${date.sDateQuery}&to_date=${date.eDateQuery}`
    );
    return response?.data;
  }

  setActiveTransactionId(transactionId) {
    this.activeTransactionId = transactionId;
  }

  // Benefit Store's Surveys
  * getStoreSurveys(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/surveys`, hasParams);
    return response?.data;
  }

  * exportStoreSurveys(id) {
    const response = yield Api.get(`admin/benefit/${id}/surveys/export`);
    return response?.data;
  }

  // Benefit Store's Purchases Orders
  * getAllPurchasesOrders(hasParams = {}) {
    const response = yield Api.get(`admin/benefit/purchases/orders`, hasParams);
    return response?.data;
  }

  /**
   * Export all orders by range, offset, limit
   * {{BASE_URL}}/v1/admin/benefit/purchases/orders/export?from_date=2022-03-01&to_date=2023-05-01&limit=1&offset=1
   */
  * exportAllPurchasesOrders(hasParams = {}) {
    const response = yield Api.get(`admin/benefit/purchases/orders/export`, hasParams);
    return response?.data;
  }

  // Benefit Store's Purchases
  * getStorePurchases(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/purchases`, hasParams);
    return response?.data;
  }

  * exportStorePurchases(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/purchases/export`, hasParams);
    return response?.data;
  }

  // Benefit Store's Clicks
  * getStoreClicks(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/clicks`, hasParams);
    return response?.data;
  }

  * exportStoreClicks(id) {
    const response = yield Api.get(`admin/benefit/${id}/clicks/export`);
    return response?.data;
  }

  // Benefit Store's Leads
  * getStoreLeads(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/leads`, hasParams);
    return response?.data;
  }

  * exportStoreLeads(id, hasParams = {}) {
    const response = yield Api.get(`admin/benefit/${id}/leads/export`);
    return response?.data;
  }
}