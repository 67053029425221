import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ImagePlaceholder from "../../../../assets/images/image-placeholder-banner.png";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import "../shared_styles.scss";
import "./style.scss";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Editor } from "@tinymce/tinymce-react";
import PreviewModal from "../../../../components/PreviewModal/PreviewModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./bannerFields";
import { flowResult } from "mobx";
import { useStore } from "../../../../store/StoreContext";
import { useHistory } from "react-router";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import UserTypeEnum from "../../../../models/UserType";
import ProceedImage from "../../../../shared/utils/proceedImage";
import Spinner from "../../../../components/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Navigation } from "swiper";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../shared/utils/cropImage";
import { convertLocalToZuluTime, convertToDate } from "../../../../shared/utils/sharedFunctions";
import { tinyEditorOptions } from "../../../../shared/utils/sharedOptions";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
import { compressAccurately } from "image-conversion";

SwiperCore.use([Pagination, Navigation]);

const NewBanner = () => {
    const { i18n, t } = useTranslation();

    const history = useHistory();
    const {
        contentStore,
        actionStore,
        groupStore,
        departmentStore,
        userStore,
        languageStore,
        archiveStore
    } = useStore();
    const [fileUpload, setFileUpload] = useState({
        lastModified: 0,
        lastModifiedDate: new Date(),
        name: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
        url: ImagePlaceholder,
    });
    const [imageOriginal, setImageOriginal] = useState("");
    const [showImage, setShowImage] = useState(false);
    const [publish, setPublish] = useState("now");
    const [details, setDetails] = useState("write");
    const [archive, setArchive] = useState("globalDate");
    const [isPushNotification, setIsPushNotification] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [innerLoading, setInnerLoading] = useState(false);
    const [header, setHeader] = useState("");
    const [textEditor, setTextEditor] = useState("");
    const carouselSlides = [1, 2, 3, 4, 5, 6];
    const [isRedirect, setIsRedirect] = useState(true);
    const [archiveDays, setArchiveDays] = useState(7);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(true);
    const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(true);

    const resolutionIsOrBelow1440 = window.innerWidth <= 1440;
    const resolutionAbove1024 = window.innerWidth > 1024;
    const getCropWidth = () => {
        if (!resolutionIsOrBelow1440) return 352
        else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 281.6
        return 220;
    }
    const getCropHeight = () => {
        if (!resolutionIsOrBelow1440) return 376;
        else if (resolutionIsOrBelow1440 && resolutionAbove1024) return 300.8;
        return 235;
    }

    // GET DATA
    const [categories, setCategories] = useState([
        {
            id: "",
            contentName: "",
        },
    ]);
    const [groups, setGroups] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);

    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        const storedContent = contentStore.getStoredContents();
        const storedGroups = groupStore.getStoredGroups();
        const storedDepartments = departmentStore.getStoredDepartments();
        const storedSecondaryDepartments = departmentStore.getStoredSecondaryDepartments();
        const roleType = userStore.getRoleType();
        const archiveDays = archiveStore.getArticleArchiveDays();
        if (roleType === UserTypeEnum.owner) {
            setArchive("specificDate");
        } else {
            setArchiveDays(archiveDays);
            setArchiveDateAndTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
            setValue("archive_date", convertToDate(
                new Date(new Date().setDate(new Date().getDate() + archiveDays))
            ))
        }
        setCategories(storedContent);
        setGroups(storedGroups);
        setDepartments(storedDepartments);
        setSecondaryDepartments(storedSecondaryDepartments);
        setRoleType(roleType);
        const appears = roleType === UserTypeEnum.owner ? storedGroups.map(group => group.id) : storedDepartments.map(department => department.id).concat(storedSecondaryDepartments.map(department => department.id))
        setValue("appears", [...appears, 'select-all']);
        setValue("writer", userStore.getUserFullName());
        setIsLoading(false);
    }, [
        contentStore.contents,
        groupStore.groups,
        departmentStore.departments,
        userStore.role,
    ]);

    // Preview Modal
    const [openModal, setOpenModal] = useState(false);
    // Notification Modal
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const handleFileUpload = async (data) => {
        if (data) {
            setInnerLoading(true);
            setShowImage(true);
            const image: any = await ProceedImage.toBase64(data);
            setFileUpload({ ...data, url: image });
            setValue("image", image);

            // Save original image
            let compressed: Blob;
            if (data.type.indexOf("image") !== -1) {
              // Maximum for firbase is 300kb
              compressed = await compressAccurately(data, {size: 280, accuracy: 0.99});
            }
            const formData = new FormData();
            formData.append("file", compressed || data);
            const imageToURL = await flowResult(actionStore.uploadFile(formData)).then(
              (response: any) => {
                setIsLoading(false);
                return response;
              }
            );
            setImageOriginal(imageToURL);

            //Initiate the FileReader object.
            const reader = new FileReader();
            //Read the contents of Image File.
            reader.onload = function (e) {
                //Initiate the JavaScript Image object.
                let image: any = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;

                //Validate the File Height and Width.
                image.onload = function () {
                    const height: number = Number(this.height);
                    const width: number = Number(this.width);
                    let zoom = 0;
                    if (height > width) {
                        zoom = (height / width * 0.936)
                    } else {
                        zoom = (width / height * 1.068)
                    }
                    zoom = zoom >= 1 ? zoom : zoom;
                    setZoom(zoom);
                    setMaxZoom(zoom * 4);
                    setTimeout(() => {
                        const cropperImage: any = document.querySelector('.reactEasyCrop_Image');
                        cropperImage.style.maxHeight = '100.1%';
                        cropperImage.style.maxWidth = '100.1%';
                    })
                };

            }
            reader.readAsDataURL(data);
            setIsNewPictureUploaded(true);
            setInnerLoading(false);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: "onChange",
        defaultValues: {
            header: "",
            category_id: "",
            image: "",
            appears: [],
            is_push_notification: false,
            push_notification_text: "",
            publish_date: convertToDate(new Date()),
            archive_date: convertToDate(
                new Date(new Date().setDate(new Date().getDate() + 7))
            ),
            details: "write",
            external_link: "",
            details_header: "",
            writer: "",
            text: "",
            is_draft: false,
        },
    } as any);

    const [submitError, setSubmitError] = useState("");

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    const [potentialData, setPotentialData] = useState({});

    const createBanner = async (data) => {
        setIsLoading(true);
        setSubmitError("");
        if (isNewPictureUploaded) {
            const formData = new FormData();
            formData.append("file", data.image);
            const croppedImage = await flowResult(actionStore.uploadFile(formData)).then(
                (response: any) => {
                    return response;
                }
            );
            data = { ...data, image: croppedImage }
        }
        await flowResult(actionStore.create(data, "rotating-banners")).then(
            (response: any) => {
                setIsLoading(false);
                if (!response?.success) {
                    handleNotificationOpen(
                        response?.code
                            ? t(`apiMessages.${response.code}`)
                            : t("apiMessages.0"),
                        false
                    );
                    return;
                }
                handleNotificationOpen(
                    response?.code
                        ? t(`apiMessages.${response.code}`)
                        : t("apiMessages.0"),
                    true
                );
            }
        );
    };

    const transformToData = async (objectData, isDraft = false) => {
        let croppedImage: any = "";
        if (isNewPictureUploaded) {
            croppedImage = await cropImage(croppedAreaPixels);
        }
        return {
            header: objectData.header,
            category_id: objectData.category_id,
            image: croppedImage || objectData.image,
            image_original: imageOriginal ?? "",
            appears: objectData.appears.filter((el) => el !== 'select-all'),
            is_push_notification: objectData.is_push_notification,
            push_notification_text: objectData.push_notification_text,
            publish_date: publish === "now" ? convertLocalToZuluTime(new Date()) : convertLocalToZuluTime(publishDateAndTime),
            archive_date:
                archive === "globalDate"
                    ? convertLocalToZuluTime(new Date(new Date().setDate(new Date().getDate() + archiveDays)))
                    : convertLocalToZuluTime(archiveDateAndTime),
            details: {
                external_link: objectData.external_link,
                header: objectData.header,
                writer: objectData.writer,
                text: textEditor,
            },
            is_draft: isDraft,
        };
    };

    const handleSubmitDraft = async (objectData) => {
        let data = await transformToData(objectData, true);
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el]) delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        createBanner(data);
    };

    const handleSubmitCheck = async (objectData) => {
        if (objectData.is_draft) {
            handleSubmitDraft(objectData);
            return;
        }
        if (publishInvalidDate || archiveInvalidDate) {
            return;
        }
        let data = await transformToData(objectData);
        setPotentialData(data);
        setOpenModal(true);
    };

    const handleCloseModal = (isSave) => () => {
        setOpenModal(false);
        if (isSave) {
            let data = potentialData;
            const dataKeys = Object.keys(data);
            dataKeys.forEach((el) => {
                if (!data[el]) delete data[el];
                if (el === "details") {
                    const detailsKeys = Object.keys(data[el]);
                    detailsKeys.forEach((det) => {
                        // @ts-ignore
                        if (!data.details[det]) delete data.details[det];
                    });
                }
            });
            setSubmitError("");
            createBanner(data);
        }
    };

    const handleNotificationOpen = (message = "", isRedirect) => {
        setNotificationMessage(message);
        setIsRedirect(isRedirect);
        setOpenNotificationModal(true);
    };

    const handleNotificationClose = () => {
        setOpenNotificationModal(false);
        if (isRedirect) history.push("/articles/rotating-banner");
    };

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState("");

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng]);

    const textInputDirection = () => {
        if (lngDirection === "rtl") return "rtl";
        if (lngDirection === "ltr") return "ltr";
    };

    const transformEditorText = (text) => {
        if (text && text.length > 0) {
            return text
                .replace(/(<([^>]+)>)/gi, "")
                // eslint-disable-next-line no-useless-escape
                .replace(/\&nbsp;/g, "")
                .trim();
        }
        return text;
    };

    // Ref
    const headerRef: any = useRef(null);
    const categoryRef: any = useRef(null);
    const imageRef: any = useRef(null);
    const appearsRef: any = useRef(null);
    const pushTextRef: any = useRef(null);
    const externalRef: any = useRef(null);
    const detailsHeaderRef: any = useRef(null);
    const writerRef: any = useRef(null);
    const textRef: any = useRef(null);
    const publishDateRef: any = useRef(null);
    const archiveDateRef: any = useRef(null);

    const scrollToError = () => {
        if (errors?.header?.message) {
            headerRef.current.scrollIntoView();
        } else if (errors?.category_id?.message) {
            categoryRef.current.scrollIntoView();
        } else if (errors?.image?.message && !isNewPictureUploaded) {
            imageRef.current.scrollIntoView();
            // @ts-ignore
        } else if (errors?.appears?.message) {
            appearsRef.current.scrollIntoView();
        } else if (errors?.push_notification_text?.message) {
            pushTextRef.current.scrollIntoView();
        } else if (errors?.external_link?.message) {
            externalRef.current.scrollIntoView();
        } else if (errors?.writer?.message) {
            writerRef.current.scrollIntoView();
        } else if (errors?.text?.message) {
            textRef.current.scrollIntoView();
        } else if (publishInvalidDate) {
            publishDateRef.current.scrollIntoView();
        } else if (archiveInvalidDate) {
            archiveDateRef.current.scrollIntoView();
        }
        const element = document.querySelector('.main-content-container');
        if (element) {
            element.scrollTo(0, 0);
        }
    };

    const [publishDateAndTime, setPublishDateAndTime] = useState(new Date());
    const [publishHourAndMinutes, setPublishHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const [archiveDateAndTime, setArchiveDateAndTime] = useState(
        new Date(new Date().setDate(new Date().getDate() + 7))
    );
    const [archiveHourAndMinutes, setArchiveHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const changeDateOnly = (
        time,
        hourAndMinutes,
        setDateAndTime,
        setInvalidDate,
        type = ""
    ) => {
        if (time instanceof Date) {
            const hours = hourAndMinutes.hour;
            const minutes = hourAndMinutes.minutes;
            if (!isNaN(hours) && !isNaN(minutes))
                setDateAndTime((date: any) => {
                    if (date) {
                        date = new Date(time);
                        date.setHours(hours, minutes);
                        const newDate = new Date(date);
                        return newDate;
                    }
                    return new Date();
                });
        }
    };

    const changeTimeOnly = (
        time,
        setDateAndTime,
        setHourAndMinutes,
        setInvalidDate,
        type = ""
    ) => {
        if (time instanceof Date) {
            if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
                setDateAndTime((date: any) => {
                    if (date) {
                        date.setHours(time.getHours(), time.getMinutes());
                        const newDate = new Date(date);
                        setHourAndMinutes({
                            hour: time.getHours(),
                            minutes: time.getMinutes(),
                        });
                        return newDate;
                    }
                    return new Date();
                });
        }
    };

    const [publishInvalidDate, setPublishInvalidDate] = useState(false);
    const [archiveInvalidDate, setArchiveInvalidDate] = useState(false);

    // Cropper
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.4);
    const [maxZoom, setMaxZoom] = useState(1.4);
    const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
    const [croppedImage, setCroppedImage] = useState<any>("");
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({
        height: 0,
        width: 0,
        x: 0,
        y: 0,
    });

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const cropImage = async (croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            fileUpload?.url,
            croppedAreaPixels
        );
        const croppedImageBlob = await getCroppedImg(
            fileUpload?.url,
            croppedAreaPixels,
            true
        );
        setCroppedImage(croppedImage);
        return croppedImageBlob
    };

    const onDepartmentSelectChange = (data, isSelectAll = false) => {
        const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...secondaries]);
        setIsSelectAllSelected(isSelectAll)
      }
    const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
        const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...deps]);
        setIsSelectAllSelectedSecondary(isSelectAll);
      }

    return isLoading ? (
        <Spinner />
    ) : (
        <div className="create-article banner">
            <div className="main-wrapper">
                <div className="create-options">
                    <h2 className="page-heading">
                        {t("articles.rotatingBanner.create.title")}
                    </h2>
                </div>
                <div className="create-preview">
                    <form
                        className=""
                        autoComplete="off"
                        onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
                    >
                        <div className="main-wrapper">
                            <h3>{t("articles.allArticles.create.form.title")}</h3>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={lngDirection === "rtl" ? heLocale : enLocale}
                            >
                                <Grid container className="create-grid" justifyContent="space-between">
                                    <Grid item xs={6} lg={4}>
                                        <Grid container direction="column">
                                            <Grid item xs={12} ref={headerRef}>
                                                <Controller
                                                    control={control}
                                                    name="header"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="header"
                                                                label={`${t("common.fieldHeaders.header")}*`}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    onChange(e);
                                                                    setHeader(value);
                                                                    setValue('header', value);
                                                                }}
                                                            />
                                                            <p className="field-error">
                                                                {errors?.header?.message ?? ""}&nbsp;
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} ref={categoryRef}>
                                                <FormControl>
                                                    <InputLabel id="category-label" required>
                                                        {t("common.fieldHeaders.category")}
                                                    </InputLabel>
                                                    <Controller
                                                        control={control}
                                                        name="category_id"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <Select
                                                                    labelId="category-label"
                                                                    id="category"
                                                                    displayEmpty
                                                                    {...register("category_id")}
                                                                    defaultValue=""
                                                                    onChange={(e) => {
                                                                        const textValue =
                                                                            // @ts-ignore
                                                                            e.nativeEvent?.target?.innerText ?? "";
                                                                        setSelectedCategory(textValue);
                                                                        onChange(e);
                                                                    }}
                                                                    disabled={!categories.length}
                                                                >
                                                                    {categories.map((cat, index) => (
                                                                        <MenuItem key={`${index}-${cat.id}`} value={cat.id}>
                                                                            {cat.contentName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {categories.length ? (
                                                                    <p className="field-error">
                                                                        {errors?.category_id?.message ?? ""}&nbsp;
                                                                    </p>
                                                                ) : (
                                                                    <p className="field-error">
                                                                        {t(
                                                                            "errorMessages.articles.create.noCategories"
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} ref={imageRef}>
                                                <UploadFile
                                                    text={t("common.fieldHeaders.uploadImage")}
                                                    handleFileUpload={handleFileUpload}
                                                    showImage={showImage}
                                                    image={fileUpload.url}
                                                    width={126}
                                                    height={138}
                                                />
                                                <span className="information-message">{t("informationMessages.imageOnly")}</span>
                                                <p className="field-error">
                                                    {!showImage ? errors?.image?.message ?? "" : ""}&nbsp;
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} ref={appearsRef}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <label className="field-label">
                                                                    {t("common.fieldHeaders.appears")}
                                                                </label>
                                                                {roleType !== UserTypeEnum.owner && <><br />
                                                                <label className="field-label">{t('common.fieldHeaders.department')}</label></>}
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                                                    hasError={!!errors?.appears?.message}
                                                                    isSelectAllSelected={isSelectAllSelected}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onDepartmentSelectChange}
                                                                    selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                                                />
                                                                {(roleType === UserTypeEnum.owner &&
                                                                    groups.length) ||
                                                                    (roleType !== UserTypeEnum.owner &&
                                                                        departments.length) ? (
                                                                    <>
                                                                        <p className="field-error">
                                                                            {/* @ts-ignore */}
                                                                            {errors?.appears?.message ?? ""}&nbsp;
                                                                        </p>
                                                                    </>
                                                                ) : (
                                                                    <p className="field-error">
                                                                        {roleType === UserTypeEnum.owner
                                                                            ? t(
                                                                                "errorMessages.articles.create.noGroups"
                                                                            )
                                                                            : t(
                                                                                "errorMessages.articles.create.noDepartments"
                                                                            )}
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item xs={12}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                            <label className="field-label">
                                                                    {t("common.fieldHeaders.secondaryDepartment")}
                                                                </label>
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                                                    isSelectAllSelected={isSelectAllSelectedSecondary}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onSecondaryDepartmentSelectChange}
                                                                    selectOptions={secondaryDepartments}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>}
                                            <Grid item xs={12} ref={pushTextRef}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            control={control}
                                                            name="is_push_notification"
                                                            render={({ field: { onChange, value } }) => (
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={value || false}
                                                                    onChange={onChange}
                                                                    onClick={() => {
                                                                        setIsPushNotification(!value);
                                                                        if (value) {
                                                                            setValue("push_notification_text", "");
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={t("common.fieldHeaders.sendPushNotification")}
                                                    onClick={() =>
                                                        getValues("push_notification_text")
                                                            ? setValue("push_notification_text", "")
                                                            : null
                                                    }
                                                />
                                                <Controller
                                                    control={control}
                                                    name="push_notification_text"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField
                                                                id="pushNotificationText"
                                                                label={t(
                                                                    "common.fieldHeaders.pushNotificationText"
                                                                )}
                                                                value={value}
                                                                onChange={onChange}
                                                                disabled={!isPushNotification}
                                                            />
                                                            <p className="field-error">
                                                                {errors?.push_notification_text?.message ?? ""}
                                                                &nbsp;
                                                            </p>
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item className="grid-row">
                                                <p>{t("common.fieldHeaders.whenToPublishArticle")}</p>
                                                <RadioGroup name="publish" value={publish}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onChange={(e) => {
                                                                    setPublish(e.target.value);
                                                                    setValue(
                                                                        "publish_date",
                                                                        convertToDate(new Date())
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        value="now"
                                                        label={t("common.fieldHeaders.now")}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onChange={(e) => setPublish(e.target.value)}
                                                            />
                                                        }
                                                        value="later"
                                                        label={t("common.fieldHeaders.later")}
                                                    />
                                                </RadioGroup>
                                                {publish === "later" && (
                                                    <Grid container justifyContent="space-between" ref={publishDateRef}>
                                                        <Grid item xs={7}>
                                                            <KeyboardDatePicker
                                                                // disablePast
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id="publishDate-picker-inline"
                                                                className="date-picker-inline"
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onChange={(value: any) => {
                                                                    changeDateOnly(
                                                                        value,
                                                                        publishHourAndMinutes,
                                                                        setPublishDateAndTime,
                                                                        setPublishInvalidDate
                                                                    );
                                                                }}
                                                                value={convertToDate(publishDateAndTime)}
                                                                autoOk={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} lg={4}>
                                                            <KeyboardTimePicker
                                                                margin="normal"
                                                                id="time-picker"
                                                                okLabel={t("timePicker.confirm")}
                                                                cancelLabel={t("timePicker.cancel")}
                                                                value={publishDateAndTime}
                                                                onChange={(value: any) => {
                                                                    changeTimeOnly(
                                                                        value,
                                                                        setPublishDateAndTime,
                                                                        setPublishHourAndMinutes,
                                                                        setPublishInvalidDate
                                                                    );
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "Hour",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <p className="field-error">
                                                            {publishInvalidDate ? t("push.errors.time") : ""}
                                                            &nbsp;
                                                        </p>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item className="grid-row">
                                                <p>{t("common.fieldHeaders.whenToArchiveArticle")}</p>
                                                <RadioGroup name="archive" value={archive}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onChange={(e) => setArchive(e.target.value)}
                                                            />
                                                        }
                                                        value="globalDate"
                                                        label={t("common.fieldHeaders.globalDate")}
                                                        disabled={roleType === UserTypeEnum.owner}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                onChange={(e) => setArchive(e.target.value)}
                                                            />
                                                        }
                                                        value="specificDate"
                                                        label={t("common.fieldHeaders.specificDate")}
                                                    />
                                                </RadioGroup>
                                                {archive === "specificDate" && (
                                                    <Grid container justifyContent="space-between" ref={archiveDateRef}>
                                                        <Grid item xs={7}>
                                                            <KeyboardDatePicker
                                                                // minDate={publishDateAndTime}
                                                                minDateMessage={t('errorMessages.minimumDate')}
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id="archiveDate-picker-inline"
                                                                className="date-picker-inline"
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onChange={(value: any) => {
                                                                    changeDateOnly(
                                                                        value,
                                                                        archiveHourAndMinutes,
                                                                        setArchiveDateAndTime,
                                                                        setArchiveInvalidDate,
                                                                        "archive"
                                                                    );
                                                                }}
                                                                value={convertToDate(archiveDateAndTime)}
                                                                autoOk={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} lg={4}>
                                                            <KeyboardTimePicker
                                                                margin="normal"
                                                                id="time-picker"
                                                                okLabel={t("timePicker.confirm")}
                                                                cancelLabel={t("timePicker.cancel")}
                                                                value={archiveDateAndTime}
                                                                onChange={(value: any) => {
                                                                    changeTimeOnly(
                                                                        value,
                                                                        setArchiveDateAndTime,
                                                                        setArchiveHourAndMinutes,
                                                                        setArchiveInvalidDate,
                                                                        "archive"
                                                                    );
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "Hour",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <p className="field-error">
                                                            {archiveInvalidDate ? t("push.errors.time") : ""}
                                                            &nbsp;
                                                        </p>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4} lg={4}
                                        className="preview-container flex-center flex-column"
                                    >
                                        <div className="fixed">
                                            <div className={`preview-image`}
                                                style={{
                                                    backgroundImage:
                                                        fileUpload?.url && !isNewPictureUploaded
                                                            ? "url(" + fileUpload?.url + ")"
                                                            : " ",
                                                }}
                                            >
                                                {isNewPictureUploaded ?
                                                    <Cropper
                                                        image={
                                                            fileUpload?.url
                                                                ? fileUpload?.url
                                                                : " "
                                                        }
                                                        crop={crop}
                                                        zoom={zoom}
                                                        zoomSpeed={0.1}
                                                        minZoom={0.05}
                                                        maxZoom={maxZoom}
                                                        restrictPosition={false}
                                                        objectFit="contain"
                                                        cropSize={{
                                                            width: getCropWidth(),
                                                            height: getCropHeight(),
                                                        }}
                                                        onCropChange={setCrop}
                                                        onZoomChange={setZoom}
                                                        onCropComplete={onCropComplete}
                                                    />
                                                    : null
                                                }
                                            </div>
                                            <div className="proportion-container">
                                                <span className="text">{t('common.proportions', { x: 1, y: 1 })}</span>
                                            </div>
                                            <Swiper
                                                slidesPerView={1}
                                                slidesPerGroup={1}
                                                loop={true}
                                                navigation={false}
                                                className="rotating-banner"
                                            >
                                                {carouselSlides.map((index: number) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="trans-border">
                                                            <div className="banner">
                                                                <div className="preview-text">
                                                                    <div className="preview-category">
                                                                        <span>
                                                                            {selectedCategory || "Category"}
                                                                        </span>
                                                                    </div>
                                                                    <div className="preview-header-date">
                                                                        <p className="preview-header">
                                                                            {(header ? (header.length > 40 ? header.substring(0, 40) + '...' : header) : '') ||
                                                                                t(
                                                                                    "articles.allArticles.create.form.preview.headerGoes"
                                                                                )}
                                                                        </p>
                                                                        <p className="preview-date">
                                                                            {convertToDate(
                                                                                getValues("publish_date"),
                                                                                "preview"
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="main-wrapper">
                            <h3>{t("articles.allArticles.create.form.subTitle")}</h3>
                            <Grid container className="create-grid">
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Grid item xs={6} lg={4} ref={externalRef}>
                                            <Controller
                                                control={control}
                                                name="details"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <RadioGroup name="details" value={value}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio color="primary" onChange={onChange} />
                                                                }
                                                                value="write"
                                                                label={t("common.fieldHeaders.iWillWrite")}
                                                                onClick={() => {
                                                                    setDetails("write");
                                                                    setValue("external_link", "");
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio color="primary" onChange={onChange} />
                                                                }
                                                                value="externalLink"
                                                                label={t("common.fieldHeaders.externalLink")}
                                                                onClick={() => {
                                                                    setDetails("externalLink");
                                                                    setValue("details_header", "");
                                                                    setValue("writer", "");
                                                                    setValue("text", "");
                                                                    setTextEditor("");
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                        {value === "externalLink" && (
                                                            <Controller
                                                                control={control}
                                                                name="external_link"
                                                                render={({ field: { onChange, value } }) => (
                                                                    <>
                                                                        <TextField
                                                                            id="external_link"
                                                                            type="text"
                                                                            label={t("common.fieldHeaders.url")}
                                                                            value={value}
                                                                            onChange={onChange}
                                                                        />
                                                                        <p className="field-error">
                                                                            {details === "externalLink"
                                                                                ? errors?.external_link?.message ?? ""
                                                                                : ""}
                                                                            &nbsp;
                                                                        </p>
                                                                    </>
                                                                )}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={4} ref={detailsHeaderRef}>
                                            <Controller
                                                control={control}
                                                name="header"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <TextField
                                                            id="header"
                                                            type="text"
                                                            label={t("common.fieldHeaders.header")}
                                                            value={value}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                onChange(e);
                                                                setHeader(value);
                                                                setValue('header', value);
                                                            }}
                                                            disabled={details === "externalLink"}
                                                        />
                                                        <p className="field-error">
                                                            {details === "write"
                                                                ? errors?.header?.message ?? ""
                                                                : ""}
                                                            &nbsp;
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={4} ref={writerRef}>
                                            <Controller
                                                control={control}
                                                name="writer"
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <TextField
                                                            id="writer"
                                                            type="text"
                                                            label={t("common.fieldHeaders.writer")}
                                                            value={value}
                                                            onChange={onChange}
                                                            disabled={details === "externalLink"}
                                                        />
                                                        <p className="field-error">
                                                            {details === "write"
                                                                ? errors?.writer?.message ?? ""
                                                                : ""}
                                                            &nbsp;
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} ref={textRef}>
                                            <p
                                                className={`${details === "externalLink" ? "text-disabled" : ""
                                                    }`}
                                            >
                                                {t("common.fieldHeaders.articleText")}
                                            </p>
                                            <Controller
                                                control={control}
                                                name="text"
                                                render={({ field: { onChange } }) => (
                                                    <>
                                                        <Editor
                                                            value={textEditor}
                                                            init={{
                                                                directionality: textInputDirection(),
                                                                height: 500,
                                                                width: "65%",
                                                                ...tinyEditorOptions,
                                                                toolbar_mode: 'sliding',
                                                                content_style: `body { ${lngDirection === "rtl"
                                                                    ? "text-align: right;"
                                                                    : "text-align: left;"
                                                                    }
                                                                    overflow-x: hidden; }`,
                                                            }}
                                                            onEditorChange={(value) => {
                                                                setTextEditor(value);
                                                                onChange(transformEditorText(value));
                                                            }}
                                                            disabled={details === "externalLink"}
                                                        />
                                                        <p className="field-error">
                                                            {details === "write"
                                                                ? errors?.text?.message ?? ""
                                                                : ""}
                                                            &nbsp;
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormHelperText error className="error-text">
                                        {submitError
                                            ? submitError?.charAt(0).toUpperCase() +
                                            submitError?.slice(1)
                                            : ""}
                                        &nbsp;
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="submit-buttons">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    setValue("is_draft", false);
                                    setTimeout(() => {
                                        scrollToError();
                                    });
                                }}
                                disabled={
                                    !(
                                        categories.length &&
                                        (groups.length || departments.length) &&
                                        roleType
                                    )
                                }
                            >
                                {t("common.buttons.previewAndPublish")}
                            </Button>
                            <Button
                                className="link-button-container"
                                type="submit"
                                onClick={() => {
                                    setValue("is_draft", true);
                                    setTimeout(() => {
                                        scrollToError();
                                    });
                                }}
                            >
                                <Link className="link-btn">
                                    {t("common.buttons.saveAsDraft")}
                                </Link>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <PreviewModal
                className="create-article banner"
                openModal={openModal}
                handleClose={handleCloseModal}
                content={
                    <>
                        <div className="preview-image-container">
                            <div className={`preview-image`}>
                                <img src={croppedImage ?? ""} alt={fileUpload?.name ?? ""} />
                            </div>
                            <Swiper
                                slidesPerView={1}
                                slidesPerGroup={1}
                                loop={true}
                                navigation={false}
                                className="rotating-banner"
                            >
                                {carouselSlides.map((index: number) => (
                                    <SwiperSlide key={`carousel-item-${index}`}>
                                        <div className="trans-border">
                                            <div className="banner">
                                                <div className="preview-text">
                                                    <div className="preview-category">
                                                        <span>{selectedCategory || "Category"}</span>
                                                    </div>
                                                    <div className="preview-header-date">
                                                        <p className="preview-header">
                                                            {(header ? (header.length > 40 ? header.substring(0, 40) + '...' : header) : '') ||
                                                                t(
                                                                    "articles.allArticles.create.form.preview.headerGoes"
                                                                )}
                                                        </p>
                                                        <p className="preview-date">
                                                            {convertToDate(
                                                                getValues("publish_date"),
                                                                "preview"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                }
            />
            <NotificationModal
                openModal={openNotificationModal}
                buttonText={t("common.buttons.close")}
                handleClose={handleNotificationClose}
                handleButtonClick={handleNotificationClose}
                message={notificationMessage}
            />
            {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
        </div>
    );
};

export default observer(NewBanner);
