import React from "react";
import GroupBarChart from "../../../shared/components/GroupBarChart/GroupBarChart";
import LineChart from "../../../shared/components/LineChart/LineChart";
import "./style.scss";
import { useTranslation } from "react-i18next";

const ReportDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="main-wrapper">
      <div className="page-heading-wrap">
        <h2 className="page-heading">{t("reports.title")}</h2>
      </div>
      <div className="main-wrapper purchase-chart">
        <GroupBarChart />
      </div>
      <div className="chart-legend flex-center">
        <div className="legend flex-center">
          <div className="dot blue" />
          <div className="name">{t("reports.sent")}</div>
        </div>
        <div className="legend flex-center">
          <div className="dot yellow" />
          <div className="name">{t("reports.opened")}</div>
        </div>
        <div className="legend flex-center">
          <div className="dot cyan" />
          <div className="name">{t("reports.purchased")}</div>
        </div>
      </div>
      <div className="main-wrapper">
        <LineChart  />
      </div>
    </div>
  );
};

export default ReportDashboard;
