import React from 'react'
import "../SearchIcon/SearchIcon.scss";


const SearchIcon = () => {

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  d="M11.4226 18.1152C7.7331 18.1152 4.73065 15.1138 4.73065 11.423C4.73065 7.73222 7.7331 4.73075 11.4226 4.73075C15.1132 4.73075 18.1145 7.73222 18.1145 11.423C18.1145 15.1138 15.1132 18.1152 11.4226 18.1152ZM24.3447 23.6921L23.6912 24.3457L19.6927 20.346L20.3452 19.6924L24.3447 23.6921ZM20.3452 11.423C20.3452 6.50308 16.3434 2.5 11.4226 2.5C6.5029 2.5 2.5 6.50308 2.5 11.423C2.5 16.3429 6.5029 20.346 11.4226 20.346C13.4815 20.346 15.3742 19.6388 16.8855 18.4632L17.6528 19.2306L16.5375 20.346L23.6912 27.5L27.5 23.6921L20.3452 16.5381L19.2299 17.6535L18.4625 16.885C19.6381 15.3737 20.3452 13.482 20.3452 11.423Z" fill="#577BF9" />
        </svg>

    )

}


export default SearchIcon