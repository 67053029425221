import React from 'react'
import {Props} from "./props";
import AllArticles from "./AllArticles/AllArticles";
import RotatingBanner from "./RotatingBanner/RotatingBanner";
import Ads from "./Ads/Ads";
import TabsComponent from "../../shared/components/Tabs/TabsComponent";
import {routes} from "./routes";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import CreateArticle from "./Create/Article/CreateArticle";
import CreateBanner from "./Create/Banner/CreateBanner";
import CreateAd from "./Create/Ad/CreateAd";
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Articles = (props: Props) => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();

    const tabs = [
        {
            title: t('tabNavigation.articles.allArticles'),
            component: AllArticles,
            url: `${path}/list`
        },
        {
            title: t('tabNavigation.articles.rotatingBanner'),
            component: RotatingBanner,
            url: `${path}/rotating-banner`
        },
        {
            title: t('tabNavigation.articles.ads'),
            component: Ads,
            url: `${path}/ads`
        },
        {
            title: 'Create article',
            component: CreateArticle,
            url: `${path}/create/article`,
            hide: true
        },
        {
            title: 'Create banner',
            component: CreateBanner,
            url: `${path}/create/banner`,
            hide: true
        },
        {
            title: 'Create ad',
            component: CreateAd,
            url: `${path}/create/ad`,
            hide: true
        },
    ]

    return (
        <>
            <div className="main-content main-content-tabs">
                <Switch>
                    <TabsComponent data={tabs} />
                    {
                        routes.map((route, index) => {
                            return (
                                <Route key={index} path={`${path}/${route.path}`} component={route.component}/>
                            )
                        })
                    }
                </Switch>
            </div>
        </>
    )
}

export default observer(Articles)
