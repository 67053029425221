import { IOS, ANDROID, FIREBASE_API_KEY_VAAD, FIREBASE_API_KEY_GENERAL, IS_RELEASE } from "../../config";

export const postDynamicShortLink = async (
  path: string,
  webPath: string,
  platform: string,
  rowData: any,
) => {
  try {
    // You need to change the API Key by the one provided in the FIrebase Console
    let url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${platform === "vaad" ?  FIREBASE_API_KEY_VAAD : FIREBASE_API_KEY_GENERAL}`;

    const params = new URLSearchParams();
    if(rowData.benefitType)
      params.append("benefitType", rowData.benefitType);

    // Long dynamic link is composed by
    // 1. Short URL (by default, Firebase allows you to create https://[YOUR_PROJECT].page.link type of links)
    // 2. Long URL encoded (important to encode the URL, otherwise, Firebase will return a 400 error)

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: `https://community${platform === "vaad" ? 'vaad' : 'general'}.page.link`,
          link: `https://client${IS_RELEASE ? '' : '-dev'}.communities-app.com/${platform}/${webPath}/${path}${params.toString() ? "?" + params.toString() : ""}`,
          socialMetaTagInfo :{
            socialImageLink: rowData.imageOriginal || rowData.image,
            socialTitle: rowData.header,
          },
          androidInfo: {
            androidPackageName:
            `${ANDROID.packageName}${platform === "vaad" ? '' : '.general'}`,
          },
          iosInfo: {
            iosBundleId:
            `${IOS.bundleId}${platform === "vaad" ? '' : '.general'}`,

          },
        },
      }),
    });

    let data = await response.json();

    // Firebase returns a 'shortLink' field with the short URL
    return data.shortLink;
  } catch (e) {
    return null;
  }
};
