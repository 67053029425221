import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Button, Input, InputAdornment, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '../../LeftSideBar/Icons/SearchIcon/SearchIcon';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/StoreContext';
import Spinner from '../../../components/Spinner/Spinner';

const SearchCommunity = () => {
    const { t } = useTranslation();
    const {groupStore, communityStore} = useStore();
    const [search, setSearch] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');

    const [groups, setGroups] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const storedGroups = groupStore.getStoredGroups();
        setGroups(storedGroups);
        setIsLoading(false);
    }, [groupStore.groups])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            "group_id": selectedGroup !== 'all' ? selectedGroup : '',
            "search": search,
        }
        communityStore.setSearchParams(data);
    }
    return isLoading ? (<Spinner className="search-spinner" />) : (
        <div className="search-panel">
            <form className="" autoComplete="off" onSubmit={handleSubmit}>
                <div className="main-wrapper">
                    <Grid container className="view-grid" justifyContent="center">
                        <Grid item xs={12}>
                            <Grid item className="grid-row">
                                <FormControl className="search-field-container">
                                    <InputLabel htmlFor="search-field">{t('common.fieldHeaders.search')}</InputLabel>
                                    <Input
                                        id="search-field"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                        <InputLabel id="category-label">{t('community.list.group')}</InputLabel>
                                            <Select
                                                labelId="category-label"
                                                id="category"
                                                displayEmpty
                                                value={selectedGroup}
                                                onChange={(e: any) => setSelectedGroup(e.target.value)}
                                            >
                                                <MenuItem value='all'>{t('common.selectFilters.allGroups')}</MenuItem>
                                                {
                                                    groups.map((cat, index) => (
                                                        //@ts-ignore
                                                        <MenuItem key={index} value={cat.id}>{cat.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                    </FormControl>
                            </Grid>
                            <Grid item className="grid-row search-button-container">
                                <Button variant="contained" color="primary" type="submit">{t('common.fieldHeaders.search')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    )
}

export default SearchCommunity
