
export type Mode = "dev" | "prod" | "local"

export type ModeList = {
	dev : string,
	prod : string,
	local : string,
}

export const urls : ModeList= {
	prod: "https://api.communities-app.com",
	dev: "https://api-dev.communities-app.com",
	local: "http://localhost:5001"
}

export const MODE : Mode = "prod";

export const API_VERSION = 'v1';

export const API_URL = urls[MODE];

//@ts-ignore
export const IS_RELEASE = MODE === "prod" ? 1 : 0;