import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class ArticleStore {
    root: RootStore;

    writers: any = [];
    managers: any = [];

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    getStoredWriters() {
        return this.writers;
    }

    getStoredManagers() {
        return this.managers;
    }

    * getWriters() {
        const response = yield Api.get("/admin/article/writters");
        if (response.ok && response.data?.success) {
            this.writers = response.data.writters.filter(el => el);
        }
        return response?.data
    }

    * getManagers() {
        const response = yield Api.get("/admin/article/managedBy");
        if (response.ok && response.data?.success) {
            this.managers = response.data.managers;
        }
        return response?.data
    }

    * update(payload: any, id = '') {
        const response = yield Api.put(`admin/article/${id}`, payload);
        return response?.data;
    }

}
