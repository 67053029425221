import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class ActionStore {
    root: RootStore;

    // Pop Up Action Menu
    popUpActionMenuAnchor = null;
    goToPath = "";
    editRowID = -1;
    isRowArchived = false;
    popUpActionMenuOptions = [];

    categories = [];
    benefitCategories = [];
    groups = [];
    departments = [];

    // Edit row
    editRowAnchor = null;
    editRowWidth = 0;
    editRowData: any = {};
    editRowDataPromo: any = {};
    editRowDataUser: any = {
        id: -1,
        avatar: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        department: "",
        type: "",
        downloadApp: false,
        active: false
    };

    searchBarOpened = false;
    checkCreate = false;

    // onSearch
    searchParams = {};
    triggerUpdateList = false;

    // Update categories, groups, etc.
    triggerUpdateCats = false;

    // Tab navigation
    tabIndex = null;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setTabIndex = (status) => {
        this.tabIndex = status
    }

    getTabIndex = () => {
        return this.tabIndex;
    }

    setCategories = (data) => {
        this.categories = data
    }

    setBenefitCategories = (data) => {
        this.benefitCategories = data
    }

    setGroups = (data) => {
        this.groups = data
    }

    clearSearchParams = () => {
        this.searchParams = {};
    }

    setSearchParams = (data) => {
        this.searchParams = data
        this.triggerUpdateList = true;
    }

    getSearchParams = () => {
        return this.searchParams;
    }

    getCategories = () => {
        return this.categories;
    }

    getBenefitCategories = () => {
        return this.benefitCategories;
    }

    getGroups = () => {
        return this.groups;
    }

    toggleSearch = (status) => {
        this.searchBarOpened = status;
        if (!status) {
            this.toggleCheckCreate();
        }
    }

    toggleCheckCreate = () => {
        this.checkCreate = !this.checkCreate;
    }

    handleOpenPopActionMenu = (popUpActionMenuAnchor, goToPath, data, options = []) => {
        this.goToPath = goToPath ? (goToPath + '/' + data.id) : data.id;
        this.editRowID = data.id;
        this.isRowArchived = data.isArchived;
        this.popUpActionMenuOptions = options;
        this.editRowData = data;
        this.popUpActionMenuAnchor = popUpActionMenuAnchor;
    }

    * getPathItems(path, hasParams = {}) {
        if (Object.keys(this.searchParams).length) {
            this.clearSearchParams()
        }
        const response = yield Api.get(`/admin/${path}`, hasParams);
        return response?.data
    }

    * create(payload: any, path) {
        const response = yield Api.post(`/admin/${path}`, payload);
        return response?.data;
    }

    * update(payload: any, path, id = '') {
        const response = yield Api.put(`/admin/${id ? `${path}/${id}` : path}`, payload);
        return response?.data;
    }

    * delete(path, id) {
        const response = yield Api.delete(`/admin/${path}/${id}`);
        return response?.data;
    }

    * uploadFile(file) {
        const response = yield Api.post(`/admin/upload`, file);
        return response?.data
    }
}
