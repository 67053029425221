import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class CommunityStore {
    root: RootStore;

    path = "/admin/dashboard";
    searchParams = {};
    triggerUpdateList = false;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    setSearchParams = (data) => {
        this.searchParams = data
        this.triggerUpdateList = true;
    }

    getSearchParams = () => {
        return this.searchParams;
    }

    * getDrafts() {
        const response = yield Api.get(`${this.path}/drafts`);
        return response?.data
    }

    * getThisWeek() {
        const response = yield Api.get(`${this.path}/this-week`);
        return response?.data
    }

    * getPopularArticles() {
        const response = yield Api.get(`${this.path}/popular-articles`);
        return response?.data
    }

    * getLatestActivities() {
        const response = yield Api.get(`${this.path}/latest-activities`);
        return response?.data
    }

}
