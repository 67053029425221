import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  HEADER: "header",
  CATEGORY_ID: "category_id",
  IMAGE: "image",
  APPEARS: "appears",
  IS_PUSH_NOTIFICATION: "is_push_notification",
  PUSH_NOTIFICATION_TEXT: "push_notification_text",
  PUBLISH_DATE: "publish_date",
  ARCHIVE_DATE: "archive_date",
  DETAILS: "details",
  EXTERNAL_LINK: "external_link",
  DETAILS_HEADER: "details_header",
  DETAILS_WRITER: "writer",
  DETAILS_TEXT: "text",
  IS_DRAFT: "is_draft",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.HEADER]: Yup.string()
    .min(2, () => i18n.t("validationMessages.articles.banner.minHeader"))
    .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
    .required(() => i18n.t("validationMessages.articles.banner.required")),
  [FIELDS.CATEGORY_ID]: Yup.string().required(() =>
    i18n.t("validationMessages.articles.banner.required")
  ),
  [FIELDS.IMAGE]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() => i18n.t("validationMessages.articles.banner.required"))
        .min(1, () => i18n.t("validationMessages.articles.banner.image")),
  }),
  [FIELDS.APPEARS]: Yup.array().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() => i18n.t("validationMessages.articles.banner.required"))
        .min(1, () => i18n.t("validationMessages.articles.banner.required")),
  }),
  [FIELDS.IS_PUSH_NOTIFICATION]: Yup.boolean(),
  [FIELDS.PUSH_NOTIFICATION_TEXT]: Yup.string().when("is_push_notification", {
    is: true,
    then: (text) =>
      text
        .required(() => i18n.t("validationMessages.articles.banner.required"))
        .min(1, () => i18n.t("validationMessages.articles.banner.oneLetter"))
        .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
        .when("is_draft", {
          is: true,
          then: (text) => text.notRequired(),
        }),
  }),
  [FIELDS.PUBLISH_DATE]: Yup.string(),
  [FIELDS.ARCHIVE_DATE]: Yup.string(),
  [FIELDS.DETAILS]: Yup.string(),
  [FIELDS.EXTERNAL_LINK]: Yup.string()
    .url( () => i18n.t("validationMessages.articles.banner.url"))
    .when("details", {
      is: (value) => value === "externalLink",
      then: (text) =>
        text.required(() =>
          i18n.t("validationMessages.articles.banner.required")
        ),
      otherwise: (text) => text.notRequired(),
    }),
  [FIELDS.DETAILS_HEADER]: Yup.string().notRequired(),
  [FIELDS.DETAILS_WRITER]: Yup.string().notRequired(),
  [FIELDS.DETAILS_TEXT]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element.when("details", {
        is: (value) => value === "write",
        then: (text) =>
          text
            .required(() =>
              i18n.t("validationMessages.articles.banner.required")
            )
            .min(2, () =>
              i18n.t("validationMessages.articles.banner.minHeader")
            )
            .max(1500, () =>
              i18n.t("validationMessages.articles.banner.longText")
            ),
        otherwise: (text) => text.notRequired(),
      }),
  }),
  [FIELDS.IS_DRAFT]: Yup.boolean(),
});
