import {API_URL} from "../config";

export interface ApiConfig {
  url: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 180000
}