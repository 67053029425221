import React from 'react'
import PieChart, { PieChartInfo } from '../../../shared/components/PieChart/PieChart';
import PrimaryBoxComponent from '../../../shared/components/PrimaryBox/PrimaryBox';
import "./ArticleStatistics.scss";
import { useTranslation } from 'react-i18next';

export interface ArticleStatisticsProps {
    articlesStatistics: PieChartInfo;
    isLoading?:boolean
}
const ArticleStatistics = (props: ArticleStatisticsProps) => {
    const { t } = useTranslation();
    return (
        <PrimaryBoxComponent>
            <div className="articles-statistics-container">
                <div className="articles-statistics-title">{t('dashboard.articlesStatistics')}</div>
                <PieChart isLoading={props.isLoading} {...props.articlesStatistics} />

            </div>
        </PrimaryBoxComponent>
    )

}


export default ArticleStatistics