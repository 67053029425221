import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import "./style.scss";
import SurveyInformationModal from './SurveyInformationModal/SurveyInformationModal';
import PurchaseInformationModal from './PurchaseInformationModal/PurchaseInformationModal';
import ClickInformationModal from './ClickInformationModal/ClickInformationModal';
import LeadInformationModal from './LeadInformationModal/LeadInformationModal';
import { useStore } from '../../store/StoreContext';
import { observer } from 'mobx-react-lite';

const ExportModals = () => {
    const { reportsStore } = useStore();
    const history = useHistory();
    const location = history.location.pathname;
    const [component, setComponent] = useState(<></>);

    useEffect(() => {
    switch (location) {
        case '/reports/surveys':
                setComponent(<SurveyInformationModal/>);
                break;
            case '/reports/purchases':
                setComponent(<PurchaseInformationModal/>);
                break;
            case '/reports/clicks':
                setComponent(<ClickInformationModal />);
                break;
            case '/reports/leads':
                setComponent(<LeadInformationModal />);
                break;
            default:
                setComponent(<></>);
                break;
        }
    }, [reportsStore.purchaseInformation, reportsStore.clickInformation, reportsStore.surveyInformation, reportsStore.leadInformation])

    return component
}

export default observer(ExportModals)
