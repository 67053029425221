import React, { useState, useEffect } from 'react'
import { useStore } from "../../../store/StoreContext";
import {
    Button,
    FormHelperText,
    Grid,
    TextField,
    Link as MuiLink,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIELDS, VALIDATIONS } from "./fields";
import { observer } from "mobx-react-lite";
import { flowResult } from "mobx";
import TermsAndPrivacyPolicy from '../../../components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy';
import LanguageSelection from '../../../components/LanguageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/Spinner/Spinner';

const ValidateOTP = ({ handleSignSwitch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userStore } = useStore();

    const [isDisabled, setIsDisabled] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [windowHeight, setWindowHeight] = useState("");

    const {
        register,
        handleSubmit,
        watch,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: "onChange",
        defaultValues: {
            code: "",
        },
    });

    const watchFields = watch();
    const validate = async (form: any) => {
        // @ts-ignore
        const resolver = yupResolver(VALIDATIONS)(form, null, { criteriaMode: 'firstError' });
        setIsDisabled(Object.values((await resolver).errors).length > 0)
    }

    useEffect(() => {
        validate(watchFields)
    }, [watchFields]);

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    useEffect(() => {
        const windowHeight = window.outerHeight;
        if (windowHeight < 768) {
            setWindowHeight(`${windowHeight}px`);
        } else {
            setWindowHeight("100%")
        }
        setIsLoading(false);
    }, []);

    const [submitError, setSubmitError] = useState('');

    const handleNewCode = async () => {
        setIsLoading(true);
        setSubmitError('');
        const _platformID = userStore.getPlatformID();
        if(!_platformID) {
            return;
        }
        const _payload = {
            phone: userStore.phoneNumber,
            platform_id: _platformID
        }
        flowResult(userStore.getOTP(_payload)).then(
            (response: any) => {
                if (!response?.success) {
                    setSubmitError(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'))
                }
                setIsLoading(false);
            }
        )
    }

    const handleSubmitCheck = (payload: any) => {
        setIsLoading(true);
        setSubmitError('');

        flowResult(userStore.loginOTP(payload.code)).then(
            (response: any) => {
                if (!response?.success) {
                    setSubmitError(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'))
                }
                setIsLoading(false);
            }
        );
    }
    return isLoading ? (<Spinner />) : (
        <div className="scroll-container" style={{ height: windowHeight }}>
            <div className="sign-form-container">
                <div className="inner-full-container">
                    <div className="center-container">
                        <form onSubmit={handleSubmit((d) => handleSubmitCheck(d))} className="sign-form additional-box">
                            <Grid container justifyContent="center" alignItems="center" direction="column"
                                className="sign-container">
                                <Grid item xs={10} sm={6} md={5} lg={4}>
                                    <Grid container>
                                        <Grid item xs={12} className="sign-header">
                                            <div className="double-column">
                                                <div>
                                                    <h2>{t('common.signInLabel')}</h2>
                                                    <h3>{t('common.doNotHaveAccount')} <Link className="cursor-pointer link"
                                                        to='/sign-up'>{t('common.signUpLabel')}</Link></h3>
                                                </div>
                                                <LanguageSelection />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="sign-body">
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item xs={12} className="sign-image-container flex-center">
                                                    <div className="sign-image-and-icon">
                                                        <img
                                                            src="https://www.pinclipart.com/picdir/big/165-1653686_female-user-icon-png-download-user-colorful-icon.png"
                                                            alt="" className="sign-image" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="sign-field">
                                                    <FormHelperText error>{submitError}&nbsp;</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} className="sign-field">
                                                    <TextField id="code"
                                                        type="number"
                                                        {...register('code')}
                                                        label={t('otpCode.fields.code')}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="sign-buttons">
                                                    <Button variant="contained" color="primary" type="submit"
                                                        disabled={isDisabled}>{t('otpCode.login')}</Button>
                                                    <div className="link">{t('otpCode.message')} <Link
                                                        className="cursor-pointer link" to='/sms-code-number'
                                                        onClick={() => handleNewCode()}>{t('otpCode.sendMeAgain')}</Link></div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={10} sm={6} md={5} lg={4} className="border-separator">
                                    <div className="border-line" />
                                    <div className="border-text">{t('signIn.sms.or')}</div>
                                    <div className="border-line" />
                                </Grid>
                            </Grid>
                            <Grid className="sign-container" container justifyContent="center" alignItems="center">
                                <Grid item xs={10} sm={6} md={5} lg={4} className="second-container">
                                    <Button variant="contained" color="primary" onClick={() => history.push("/sign-in")}>{t('signIn.sms.navToSignIn')}</Button>
                                </Grid>
                            </Grid>
                        </form>
                        <div className="terms-and-policy cursor-pointer">
                            <MuiLink className="terms-btn" onClick={() => setOpenModal(true)}>{t('common.termsAndPrivacy')}</MuiLink>
                        </div>
                    </div>
                    <TermsAndPrivacyPolicy openModal={openModal} onClose={() => setOpenModal(false)} />
                </div>
            </div>
        </div>
    )
}

export default observer(ValidateOTP)
