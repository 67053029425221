import React, { useEffect, useState } from "react";
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';

import { noContent } from "../../../db/db";
import { Button } from "@material-ui/core";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import ExportIcon from "../../../assets/icons/export.svg";
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import { exportToExcel } from "../../../shared/utils/sharedFunctions";
import RangeDatePicker from "../../RangeDatePicker/RangeDatePicker";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import {convertToDate as convertToDateTime} from "../../../shared/utils/sharedFunctions";

const initialDate = new Date();
initialDate.setFullYear(new Date().getFullYear() - 1);

const LeadInformationModal = () => {
  const { reportsStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const { i18n, t } = useTranslation();
  const { languageStore, settingsStore } = useStore();

  const [leadsList, setLeadsList] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => {});
  const [leadInformation, setLeadInformation] = useState<any>({});
  const [openSideMenu, setOpenSideMenu] = useState(false);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(initialDate),
    endDate: new Date(),
  });

  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const convertToDate = (date, locale = lngDirection === "rtl" ? "he-IL" : "en-US") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };

    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  const convertToDateRangePicker = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  // get all lead and fill table
  const getAllLeadInformation = async (id = leadInformation.id) => {
    setIsLoading(true);
    const searchParams = reportsStore.getInnerSearchParams();

    const benefitParams = {
      from_date: convertToDate(new Date(initialDate), "en-US"),
      to_date: convertToDate(new Date(), "en-US"),
    }

    console.log(benefitParams);
    

    await flowResult(reportsStore.getStoreLeads(id, { ...benefitParams, ...searchParams })).then(
      (response: any) => {
        if (!response?.success) {
          // on error
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        setLeadsList(response.results);
        setIsLoading(false);
        const leadTable = document.querySelectorAll(".MuiDataGrid-virtualScroller")[0] as any;
        if (leadTable) {
          leadTable.onscroll = (ev) => {
          const leadHeader = document.querySelectorAll(".MuiDataGrid-columnHeadersInner--scrollable")[0] as any;
          if (leadHeader) 
            leadHeader.style.transform = `translate3d(${Math.abs(ev.target.scrollLeft)}px, 0px, 0px)`
          };
        }
      }
    );
  };

  // error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  const handleExport = async () => {
    setIsLoading(true);

    const searchParams = reportsStore.getInnerSearchParams();

    const benefitParams = {
      from_date: convertToDate(new Date(initialDate), "en-US"),
      to_date: convertToDate(new Date(), "en-US"),
    }
    await flowResult(reportsStore.exportStoreLeads(leadInformation.id, { ...benefitParams, ...searchParams })).then(
      (response: any) => {
        if (!response?.success) {
          handleNotificationOpen(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setIsLoading(false);
          return;
        }
        const header = leadInformation?.row?.header ?? leadInformation?.header ?? "";
        exportToExcel(
          response.file,
          `${t("reports.leads.title").toLowerCase()}_${header.replace(
            " ",
            "_"
          )}`
        );
        setIsLoading(false);
      }
    );
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);

    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll(".MuiDataGrid-window").forEach((el) => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0);
        });
      });
    }
  }, [languageStore.switchLng, isLoading === false]);

  useEffect(() => {
    if (reportsStore.triggerUpdateInnerList) {
      getAllLeadInformation();
      reportsStore.triggerUpdateInnerList = false;
    }
  }, [reportsStore.triggerUpdateInnerList]);

  useEffect(() => {
    const lead = reportsStore.getLeadInformation();
    const leadID = lead?.id ?? "";
    if (leadID) {
      getAllLeadInformation(leadID);
      setLeadInformation(lead);
      setTimeout(() => {
        setOpenSideMenu(true);
      });
    } else {
      setOpenSideMenu(false);
      setTimeout(() => {
        setLeadInformation({});
      }, 500);
    }
  }, [reportsStore.leadInformation]);

  const tableData: GridColDef[] = [
    {
      field: "user",
      headerName: t("community.list.name"),
      sortable: false,
      minWidth: 80,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">
            {params.row.user.firstName} {params.row.user.lastName}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: t("common.fieldHeaders.emailAddress"),
      sortable: false,
      minWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title email">{params.row.user.email}</span>
        );
      },
    },
    {
      field: "phone",
      headerName: t("common.fieldHeaders.phoneNumber"),
      sortable: false,
      minWidth: 110,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        const rawPhone = params.row?.user.phone;
        const phone = rawPhone
          ? rawPhone?.replace(" ", "").replace("-", "")
          : " ";
        return (
          <span className="table-row-title">
            {`${phone.slice(0, 3)} ${phone.slice(3, 10)}`}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("common.fieldHeaders.date"),
      sortable: true,
      minWidth: 110,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className={`table-row-title`}>
            {`${convertToDate(params.row.createdAt)} ${convertToDateTime(new Date(params.row.createdAt), undefined, 'HH:MM')}`}
          </span>
        );
      },
    },
    ...(leadsList.length ? leadsList[0]?.answers?.map((answer: any) => ({
      field: answer.label,
      headerName: answer.label,
      sortable: false,
      minWidth: answer.label.length * 5,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className={`table-row-title`}>
            {answer.value}
          </span>
        );
      },
    })) : []),
  ].map((column) => ({
    ...column,
    disableClickEventBubbling: true,
  }));

  const handleDateChange = ({ startDate, endDate }, isClose = false) => {
    setDateRange({ startDate: startDate, endDate: endDate });
    if (isClose) {
      setOpenDatePicker(false);
      const data = {
        from_date: convertToDate(startDate, "en-US"),
        to_date: convertToDate(endDate, "en-US"),
      }
      reportsStore.setInnerSearchParams(data);
    }
  };

  console.log(tableData);
  


  return (
    <div
      className={`leads-modal-page-container ${
        leadInformation.id ? "open" : ""
      }`}
    >
      <div
        className={`main-wrapper leads-modal-page ${
          openSideMenu ? "open" : ""
        }`}
      >
        {isLoading ? (
          <Spinner className="leads-modal-spinner" />
        ) : (
          <>
            <div className="lead-modal-header d-flex justify-between align-center">
              <div>
                <h3>{t("reports.leads.informationModal.header")}</h3>
              </div>
              <div className="d-flex justify-between align-center">
                <div
                  className="export-button d-flex justify-between align-center"
                  onClick={handleExport}
                >
                  <span className="export-button-text">
                    {t("reports.leads.informationModal.export")}
                  </span>
                  <IconComponent icon={ExportIcon} />
                </div>
                <div className="close-container d-flex justify-between align-center">
                  <CloseIcon
                    className="icon-close"
                    onClick={() => reportsStore.setLeadInformation({})}
                  />
                </div>
              </div>
            </div>
            <div className="lead-modal-footer">
              <div className="page-heading-wrap">
                <h2 className="page-heading">
                  {leadInformation?.row?.header ?? leadInformation?.header ?? ""}
                </h2>
              </div>
              <div className="d-flex justify-between">
                <span className="total-count">
                  {t("reports.leads.leadsCount", { count: leadsList.length })}
                </span>
                <div className="range-date-picker datepicker">
                  <div
                    className="dislayed-dates"
                    onClick={() => toggleDatePicker()}
                  >
                    <span>
                      {t("reports.fromDate")}{" "}
                      {dateRange?.startDate
                        ? convertToDateRangePicker(dateRange.startDate)
                        : ""}
                    </span>
                    <span>-</span>
                    <span>
                      {t("reports.untilDate")}{" "}
                      {dateRange?.endDate
                        ? convertToDateRangePicker(dateRange.endDate)
                        : ""}
                    </span>
                  </div>
                  <div className="range-datepicker">
                    <RangeDatePicker
                      value={dateRange}
                      isOpened={openDatePicker}
                      onChange={handleDateChange}
                      locale={lngDirection === "rtl" ? heLocale : enLocale}
                    />
                  </div>
                </div>
              </div>
              <div
                className="sales-page content-wrap"
                style={{ maxHeight: window.innerHeight * 0.7 }}
              >
                {leadsList?.length ? (
                  <div className="table-grid">
                    <DataGrid
                      rows={leadsList}
                      autoHeight={true}
                      columns={
                        lngDirection === "ltr" ? tableData.reverse() : tableData
                      }
                      disableVirtualization
                      rowHeight={90}
                      headerHeight={80}
                      disableColumnMenu={true}
                      hideFooter={true}
                      className="table-grid-container"

                    />
                  </div>
                ) : (
                  <div className="no-content">
                    <img src={noContent.users} alt="No messages" />
                    <p>{t("reports.leads.noContent.message1")}</p>
                    <span className="note">
                      {t("reports.leads.noContent.message2")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <NotificationModal
          openModal={openNotificationModal}
          handleClose={handleNotificationClose}
          handleButtonClick={buttonAction}
          buttonText={buttonText}
          message={notificationMessage}
        />
      </div>
    </div>
  );
};

export default observer(LeadInformationModal);
