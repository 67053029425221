import React from 'react'
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);

interface Props {
    data: any;
    colors: string[];
}

const PieChart = (props: Props) => {
    const data = {
        labels: props.data.map(el => el.value),
        datasets: [
            {
                label: '# of Votes',
                data: props.data.map(el => el.percentage),
                backgroundColor: props.data.map(el => el.color),
                borderColor: props.data.map(el => el.color),
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: any, data: any) => {
                    var dataset = data['datasets'][0];
                    const shortTitle: string = data['labels'][tooltipItem['index']];
                    const percentage: number = dataset['data'][tooltipItem['index']];
                    return `${shortTitle} - ${percentage}%`;
                },
            },
            display: 'flex',
            displayColors: true
        },
        plugins: {
            datalabels: {
                formatter: (value) => {
                    if (!value) return ''
                    return `${value}%`;
                },
                anchor: 'center',
                clip: true,
                clamp: true,
                font: {
                    size: "14",
                    weight: 'bold',
                },
                textAlign: 'center',
                color: 'white',
                textShadowBlur: 5,
                textShadowColor: '#7f7f7f',
            }
        }
    }

    return (
        <Pie
            data={data}
            options={options}
        />
    );
}

export default PieChart