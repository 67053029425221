import UserStore from "./UserStore";
import ActionStore from "./ActionStore";
import ArticleStore from "./ArticleStore";
import BannerStore from "./BannerStore";
import AdStore from "./AdStore";
import SaleStore from "./SaleStore";
import SettingsStore from "./SettingsStore";
import ContentStore from "./ContentStore";
import BenefitStore from "./BenefitStore";
import GroupStore from "./GroupStore";
import DepartmentStore from "./DepartmentStore";
import CreateStore from "./CreateStore";
import GetStore from "./GetStore";
import LanguageStore from "./LanguageStore";
import CommunityStore from "./CommunityStore";
import DashboardStore from "./DashboardStore";
import PushStore from "./PushStore";
import UsersPageStore from "./UsersPageStore";
import ReportsStore from "./ReportsStore";
import ArchiveStore from "./ArchiveStore";
import MobileStore from "./MobileStore";
import PrivacyStore from "./PrivacyStore";

export default class RootStore {
  userStore: UserStore;
  actionStore: ActionStore;
  articleStore: ArticleStore;
  bannerStore: BannerStore;
  adStore: AdStore;
  saleStore: SaleStore;
  settingsStore: SettingsStore;
  contentStore: ContentStore;
  benefitStore: BenefitStore;
  groupStore: GroupStore;
  departmentStore: DepartmentStore;
  createStore: CreateStore;
  getStore: GetStore;
  languageStore: LanguageStore;
  communityStore: CommunityStore;
  dashboardStore: DashboardStore;
  pushStore: PushStore;
  usersPageStore: UsersPageStore;
  reportsStore: ReportsStore;
  archiveStore: ArchiveStore;
  mobileStore: MobileStore;
  privacyStore: PrivacyStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.actionStore = new ActionStore(this);
    this.articleStore = new ArticleStore(this);
    this.bannerStore = new BannerStore(this);
    this.adStore = new AdStore(this);
    this.saleStore = new SaleStore(this);
    this.settingsStore = new SettingsStore(this);
    this.contentStore = new ContentStore(this);
    this.benefitStore = new BenefitStore(this);
    this.groupStore = new GroupStore(this);
    this.departmentStore = new DepartmentStore(this);
    this.createStore = new CreateStore(this);
    this.getStore = new GetStore(this);
    this.languageStore = new LanguageStore(this);
    this.communityStore = new CommunityStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.pushStore = new PushStore(this);
    this.usersPageStore = new UsersPageStore(this);
    this.reportsStore = new ReportsStore(this);
    this.archiveStore = new ArchiveStore(this);
    this.mobileStore = new MobileStore(this);
    this.privacyStore = new PrivacyStore(this);
  }
}
