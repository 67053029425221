import { Button, Checkbox, IconButton } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import CheckIcon from '@material-ui/icons/Check';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { flowResult } from "mobx";
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LockIcon from "../../../assets/icons/lock.svg";
import NoArticles from "../../../assets/images/articles/noArticles.svg";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import DragAndDropTable from '../../../components/DragAndDropTable/DragAndDropTable';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import PreviewModal from '../../../components/PreviewModal/PreviewModal';
import Spinner from '../../../components/Spinner/Spinner';
import UserTypeEnum from '../../../models/UserType';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import { MANAGEMENT } from '../../../shared/constants';
import { convertZuluToLocalTime, formatAppearsIds } from '../../../shared/utils/sharedFunctions';
import { getPopUpActionOptions } from '../../../shared/utils/sharedOptions';
import { useStore } from '../../../store/StoreContext';
import "./style.scss";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 'fit-content',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AllArticles = () => {
    const { i18n, t } = useTranslation();
    const {
        contentStore,
        userStore,
        actionStore,
        articleStore,
        languageStore,
        mobileStore,
        settingsStore,
    } = useStore();
    const classes = useStyles();
    const [comFilter, setComFilter] = useState('all');
    const [catsFilter, setCatsFilter] = useState('all');

    const [categories, setCategories] = useState([{
        id: '',
        contentName: '',
    }]);

    const [communities, setCommunities] = useState<any>([]);

    const [lockedList, setLockedList] = useState<Array<Object>>([]);
    const [articleList, setArticleList] = useState<Array<Object>>([]);
    const [approvalList, setApprovalList] = useState<Array<Object>>([]);
    const [draftList, setDraftList] = useState<Array<Object>>([]);
    const [archiveList, setArchiveList] = useState<Array<Object>>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [actionType, setActionType] = useState('');
    const [roleType, setRoleType] = useState(null);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isSelectAllArticle, setIsSelectAllArticle] = useState(false);
    const [isSelectAllArchive, setIsSelectAllArchive] = useState(false);
    const [totalActiveArticles, setTotalActiveArticles] = useState(0);
    const [totalArchiveArticles, setTotalArchiveArticles] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [lngDirection, setLngDirection] = useState('');
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setCommunities(userStore.getCommunities());
        setCategories(contentStore.getStoredContents());
        articleStore.getWriters();
        articleStore.getManagers();
        setRoleType(userStore.getRoleType());
        updateLists();
    }, []);

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    useEffect(() => {
        if (actionStore.triggerUpdateList) {
            updateLists();
            actionStore.triggerUpdateList = false;
        }
    }, [actionStore.triggerUpdateList]);

    // #region ---------------------- FUNC ----------------------

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const handleFilterCommunity = (event) => {
        const value = event.target.value;
        setComFilter(value);
        let filter = {};
        if (value !== 'all') {
            filter['community'] = value
        }
        if (catsFilter !== 'all') {
            filter['category'] = catsFilter
        }
        updateLists(filter)
    }

    const handleFilterCategory = (event) => {
        const value = event.target.value;
        setCatsFilter(value);
        let filter = {};
        if (comFilter !== 'all') {
            filter['community'] = comFilter
        }
        if (value !== 'all') {
            filter['category'] = value
        }
        updateLists(filter)
    }

    const openActionMenu = (data) => (event) => {
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        console.log('settingsStore.getUserPermissions()', settingsStore.getUserPermissions().articles)
        const options = settingsStore.getUserPermissions().articles ? getPopUpActionOptions(data, roleType, userStore.getIsPermitted()) : ['view'];
        actionStore.handleOpenPopActionMenu(currentTarget.parentElement, `article`, data, options);
    };

    const handleDuplicate = () => {
        const path = 'article/duplicate';
        const id = actionStore.editRowData.id
        handleFlow(actionStore.update({}, path, id));
    }

    const toggleLock = (id, toLock) => {
        const path = 'article/lock';
        handleFlow(actionStore.update({}, path, id));
    }

    const handleReorder = async (objectData) => {
        const data = {
            template: objectData.template,
            header: objectData.header,
            category_id: objectData.category_id,
            image:
                objectData.image,
            appears: formatAppearsIds(objectData.appears, roleType) ?? [],
            publish_date: objectData.publishDate,
            archive_date: objectData.archiveDate,
            details: {
                external_link: objectData.details.external_link,
                header: objectData.details.header,
                writer: objectData.details.writer,
                text: objectData.details.text,
            },
            is_draft: false,
        };
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el] && el !== 'is_draft') delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        let error = false;
        await flowResult(articleStore.update(data, objectData.id)).then((response: any) => {
            if (!response?.success) {
                handleNotificationOpen(
                    response?.code
                        ? t(`apiMessages.${response.code}`)
                        : t("apiMessages.0")
                );
                error = true;
                return;
            }
        });
        return error;
    };

    const transformTextPhone = (children, len = 0, maxLen, maxRows) => {
        let charCount = len;
        children = [...children];
        children.forEach((child, index) => {
            if (charCount >= maxLen || index > maxRows) {
                child.remove();
                return;
            }
            if ((charCount + child.innerText.length) >= maxLen) {
                child.innerText = child.innerText.substring(0, maxLen - charCount) + "...";
            }
            charCount += child.innerText.length;
            child.style.fontSize = "1em";
            if (child.style.lineHeight) {
                const height = Number(child.style.lineHeight.replace("px", "")) / 2;
                child.style.lineHeight = height >= 10 ? height.toFixed(0) + "px" : "10px";
            }
            if (child.style.margin) {
                child.style.margin = "8px 0";
            }
            if (child.style.padding) {
                child.style.padding = child.style.padding
                    .split(" ")
                    .map((el) => Number(el.replace("px", "") / 2).toFixed(0) + "px")
                    .join(" ");
            }
            if (child.style.paddingLeft) {
                child.style.paddingLeft =
                    (Number(child.style.paddingLeft.replace("px", "")) / 2).toFixed(0) +
                    "px";
            }
            if (child.style.paddingRight) {
                child.style.paddingRight =
                    (Number(child.style.paddingRight.replace("px", "")) / 2).toFixed(0) +
                    "px";
            }
            if (child.style.backgroundColor) {
                child.style.backgroundColor = "unset";
            }
            if (child?.firstChild?.nodeName === "IFRAME") {
                child.firstChild.style.maxWidth = "99%";
                child.firstChild.style.maxHeight = "110px";
            }
            if (child?.firstChild?.nodeName === "IMG") {
                child.firstChild.style.maxWidth = "99%";
                child.firstChild.style.height = "auto";
            }
            let innerChildren = child.children;
            if (innerChildren.length) {
                transformTextPhone(innerChildren, charCount, maxLen, maxRows);
            }
        });
    };

    const displayHTMLPhone = (value, maxChar = 160, maxRows = 3) => {
        const div = document.createElement("div");
        div.innerHTML = value;
        let children: any = div.children;
        transformTextPhone(children, 0, maxChar, maxRows);
        const html = { __html: div.innerHTML };
        return <div className="dangerous-html" dangerouslySetInnerHTML={html} />;
    };

    const reverseDirection = (columns) => {
        const array = [];
        columns.forEach(el => {
            array.unshift(el)
        });
        return array
    };

    const handleApprove = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.approveArticle(id)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        updateLists();
    };

    const handleReject = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.rejectArticle(id)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        updateLists();
    };

    // #endregion ------------------- FUNC ----------------------

    // #region ------------------- UPDATE ----------------------

    const updateArticles = async (params = {}) => {
        const articleParams = {
            is_archived: false,
            is_locked: false,
            is_draft: false,
            is_approved: true,
            limit: pageSize * 2,
        }
        console.log('articleParams:', articleParams)
        await flowResult(actionStore.getPathItems('articles', { ...params, ...articleParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArticleList(data);
                setTotalActiveArticles(response.pagination.total);
            }
        )
    }

    const updateApprovalArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_locked']
        const approvalParams = {
            is_approved: false,
            is_archived: false,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('articles', { ...incomingParams, ...approvalParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setApprovalList(data)
            }
        )
    }

    const updateArchivedArticles = async (params = {}) => {
        const incomingParams = { ...params };
        const archivedParams = {
            is_archived: true,
            is_locked: false,
            is_draft: false,
            is_approved: true,
        }

        await flowResult(actionStore.getPathItems('articles', { ...incomingParams, ...archivedParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArchiveList(data)
                setTotalArchiveArticles(response.pagination.total);
            }
        )
    }

    const updateLockedArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_approved']
        const lockedParams = {
            is_archived: false,
            is_locked: true,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('articles', { ...incomingParams, ...lockedParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setLockedList(data)
            }
        )
    }

    const updateDraftArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_archived']
        delete incomingParams['is_locked']
        delete incomingParams['is_approved']
        const draftParams = {
            is_draft: true,
        }
        await flowResult(actionStore.getPathItems('articles', { ...incomingParams, ...draftParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setDraftList(data)
            }
        )
    }

    // #endregion ---------------- UPDATE ----------------------

    // #region ---------------------- MODAL ----------------------

    const handleCloseModal = () => () => {
        setOpenModal(false)
    };

    const handleNotificationOpen = (message = "", actionType = "") => {
        setButtonText(t("common.buttons.close"));
        setNotificationMessage(message);
        if (actionType) {
            setButtonText(t("common.buttons.confirm"));
            setActionType(actionType);

        }
        setOpenNotificationModal(true);
    };

    const handleNotificationClose = () => () => {
        if (actionType === "isDelete") {
            handleDelete();
            setActionType("");
        }
        if (actionType === "isArchive") {
            toggleArchive();
            setActionType("");
        }
        if (actionType === "archiveSelected") {
            handleAction(toggleArchive, articleList);
            setActionType("");
        }
        if (actionType === "deleteSelected") {
            handleAction(handleDelete, archiveList);
            setActionType("");
        }
        setOpenNotificationModal(false);
    };

    // #endregion ------------------- MODAL ----------------------

    // #region ---------------------- ARCHIVE / DELETE ----------------------

    const updateLists = async (params: any = {}) => {
        setIsLoading(true);
        setArticleList([]);
        setApprovalList([]);
        setArchiveList([]);
        setLockedList([]);
        setDraftList([]);

        const searchParams = actionStore.getSearchParams();
        params = {
            ...params,
            is_archived: true,
            is_approved: true,
            is_locked: true,
            is_draft: true,
            ...searchParams,
            limit: 30
        };
        let promises: any = [];
        if (!params.is_archived || (params.is_archived && params.is_locked && params.is_draft)) {
            promises.push(updateArticles(params));
        }
        if (params.is_approved) {
            promises.push(updateApprovalArticles(params));
        }
        if (params.is_archived) {
            promises.push(updateArchivedArticles(params));
        }
        if (params.is_locked) {
            promises.push(updateLockedArticles(params));
        }
        if (params.is_draft) {
            promises.push(updateDraftArticles(params));
        }
        await Promise.all(promises);
        setIsLoading(false)
    }

    const handleFlow = async (flow) => {
        setIsLoading(true);
        await flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code
                        ? t(`apiMessages.${response.code}`)
                        : (response?.message ?? t("apiMessages.0")));
                    setIsLoading(false);
                    return;
                }
                if (actionType === 'isDelete') updateLists();
                if (actionType === 'isArchive') updateLists();
            }
        );
    }

    const handleDelete = (id = '') => {
        const path = 'article';
        const idToUpdate = id ? id : actionStore.editRowData.id
        handleFlow(actionStore.delete(path, idToUpdate));
    }

    const toggleArchive = (id = '') => {
        const path = 'article/archive';
        const idToUpdate = id ? id : actionStore.editRowData.id
        handleFlow(actionStore.update({}, path, idToUpdate));
    }

    /**
     * Filter selected to a list send to server some calls and rerender the list.
     * Uncheck the select all checkbox.
     */
    const handleAction = (action, listToFilter) => {
        const list = listToFilter.filter((el: any) => el.isSelect);
        const promises = [list.map((el: any) => action(el.id))]

        Promise.all(promises).then(() => setTimeout(() => updateLists(), 2000))

        setIsSelectAllArchive(false)
        setIsSelectAllArticle(false)
    };

    // #endregion ------------------- ARCHIVE / DELETE ----------------------

    // #region ---------------------- SELECT ----------------------

    const handleSelect = (id, list, setList, activity, setActivity) => {
        const newList = list.map((el) => (id === el.id) ? { ...el, isSelect: !el.isSelect } : el)
        setList(newList) //reset the list of article to show the selected
        if (activity) setActivity(false) //disable the select all checkbox
    };

    const handleSelectAll = (list, setList, activity, setActivity) => {
        const newList = list.map((el) => { return { ...el, isSelect: !activity } })
        // const newList = [
        //     ...list.slice(0, 5).map((el) => ({ ...el, isSelect: !activity })),
        //     ...list.slice(5),
        // ];
        setList(newList);
        setActivity(!activity);
    };

    const handleDeleteSelected = () => {
        const selected = archiveList.filter((el: any) => el?.isSelect);

        if (selected.length) {
            handleNotificationOpen(t("errorMessages.deleteItems"), 'deleteSelected');
        } else {
            handleNotificationOpen(t("errorMessages.needToSelect"));
        }
    };

    const handleArchiveSelected = () => {
        const selected = articleList.filter((el: any) => el?.isSelect);

        if (selected.length) {
            handleNotificationOpen(t("errorMessages.archiveItems"), 'archiveSelected');
        } else {
            handleNotificationOpen(t("errorMessages.needToSelect"));
        }
    };

    // #endregion ---------------------- SELECT ----------------------

    // #region ---------------------- COLUMNS ----------------------

    const columns: GridColDef[] = [
        {
            field: 'bannerAction',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div className='empty-header-cell' />
                )
            },
            renderCell: (params: GridCellParams) => {
                const id = params.row.id;
                const isLocked = params.row.isLocked;
                const showLock = !(params.row.isArchived || params.row.isDraft || !params.row.isApproved)
                    && (roleType === UserTypeEnum.owner || roleType === UserTypeEnum.associatedOwner);
                return showLock ? (
                    <div className={`banner-action ${!isLocked ? 'margin' : ''} text-center`}
                        onClick={() => toggleLock(id, !isLocked)}>
                        {
                            !isLocked && (
                                <LockOpenIcon />
                            )
                        }
                        {
                            isLocked && (
                                <IconComponent icon={LockIcon} />
                            )
                        }
                    </div>
                ) : <></>
            },
        },
        {
            field: 'image',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div className='empty-header-cell' />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row;
                const image = params.row?.thumbnail ?? params.row?.image ?? '';
                return row.template !== 3 ? (
                    <img className={`table-row-image`} src={image || ImagePlaceholder} alt={image || "Article image"} />
                ) : (
                    <div className={`table-row-image article-template-3`} />
                )
            },
        },
        {
            field: 'category',
            sortable: false,
            headerName: t('common.fieldHeaders.category'),
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row.category.contentName;
                return (<span className="table-row-title">{name}</span>)
            },
            width: 120,
        },
        {
            field: 'header',
            headerName: t('common.fieldHeaders.header'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.header}
                    </span>
                );
            },
        },
        {
            field: 'managedBy',
            headerName: t('common.fieldHeaders.managedBy'),
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.managedBy === MANAGEMENT ? t(`common.management`) : params.row.managedBy}</span>
                );
            },
            width: 200
        },
        {
            field: 'writer',
            headerName: t('common.fieldHeaders.writer'),
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row?.details?.writer ?? '';
                return (<span>{row}</span>)
            },
            width: 200
        },
        {
            field: 'publishDate',
            headerName: t('common.fieldHeaders.live'),
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.publishDate;
                const todayDate = new Date();
                const liveDate = convertZuluToLocalTime(new Date(row));
                const isLiveDateFuture = Date.parse(todayDate.toString()) < Date.parse(liveDate.toString());
                return (
                    <div className={isLiveDateFuture ? "table-row-double" : ''}>
                        <div className="table-row-date">{convertToDate(row)}</div>
                        <div className="table-row-scheduled">{isLiveDateFuture ? t('common.fieldHeaders.scheduled') : ''}</div>
                    </div>
                )
            },
            width: 155
        },
        {
            field: 'archiveDate',
            headerName: t('common.fieldHeaders.archived'),
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <div>
                        <div className="table-row-date">{convertToDate(params.row.archiveDate)}</div>
                    </div>
                )
            },
            width: 155
        },
        {
            field: 'push',
            headerName: t('common.fieldHeaders.push'),
            width: 70,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column text-center`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isPushNotification = params.row.isPushNotification
                return (
                    <div className={`table-row-actions ${isPushNotification ? 'checked' : ''}`} style={{ cursor: "auto" }}>
                        {isPushNotification ? <CheckIcon /> : null}
                    </div>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            sortable: false,
            width: 120,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column text-center`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                return (
                    <div id={`grid-row-${data.id}`} className={`table-row-actions cell-actions`}
                        onClick={openActionMenu(data)}>
                        <MoreHorizIcon
                            aria-controls={`actions-menu`}
                            aria-haspopup="true"
                            className="table-row-actionIcon"
                        />
                    </div>
                );
            },
        },
    ].map((column) => (
        {
            ...column,
            disableClickEventBubbling: true,
        })
    );

    const approvalColumns = columns.map((col) => {
        if (col.field === 'push') {
            return {
                ...col,
                renderCell: (params: GridCellParams) => {
                    const id = params.row.id;
                    return roleType === 'chiefEditor' ? (
                        <div className='table-row-actions approve-reject'>
                            <div className="article-item button">
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleApprove(id)}
                                >{t('users.waitingForApproval.approve')}</Button>
                            </div>
                            <div className="article-item button">
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleReject(id)}
                                >{t('users.waitingForApproval.reject')}</Button>
                            </div>
                        </div>
                    ) : null
                },
                width: lngDirection === 'ltr' ? 230 : 200,
            }
        }
        return col
    })
    const selectColumn: (list: any, setList: any, activity: boolean, setActivity: any) => GridColDef[] = (list, setList, activity, setActivity) => [{
        field: "select",
        headerName: "",
        sortable: false,
        width: 90,
        headerClassName: "d-flex justify-center align-center",
        renderHeader: () => {
            return (
                <IconButton
                    aria-label="select all items"
                    onClick={() => handleSelectAll(list, setList, activity, setActivity)}
                >
                    <Checkbox
                        checked={activity}
                        color="primary"
                        className="select-all-checkbox"
                    />
                </IconButton>
            );
        },
        renderCell: (params: GridCellParams) => {
            let row = params.row;
            return (
                <IconButton
                    aria-label="select item"
                    onClick={() => handleSelect(row.id, list, setList, activity, setActivity)}
                >
                    <Checkbox
                        checked={row?.isSelect ?? false}
                        color="primary"
                        className="select-all-checkbox"
                    />
                </IconButton>
            );
        },
    }]
    const articleColumns = [...selectColumn(articleList, setArticleList, isSelectAllArticle, setIsSelectAllArticle), ...columns]
    const archiveColumns = [...selectColumn(archiveList, setArchiveList, isSelectAllArchive, setIsSelectAllArchive), ...columns]

    // #endregion ---------------------- COLUMNS ----------------------


    return isLoading ? <Spinner /> : (
        <div className="main-wrapper">
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('articles.allArticles.title')}
                </h2>
                <div className="flex-center">
                    {
                        (roleType === UserTypeEnum.owner) && (
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="select-filter"
                                    id="select-filter"
                                    className="select-preview-filter"
                                    value={comFilter}
                                    onChange={handleFilterCommunity}
                                >
                                    <MenuItem value='all'>{t('common.selectFilters.allCommunities')}</MenuItem>
                                    {
                                        communities.map((el, index) => {
                                            return (<MenuItem key={index} value={el.id}>{el.communityName}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="select-filter"
                            id="select-filter"
                            className="select-preview-filter"
                            value={catsFilter}
                            onChange={handleFilterCategory}
                        >
                            <MenuItem value='all'>{t('common.selectFilters.allCategories')}</MenuItem>
                            {
                                categories?.map((el, index) => {
                                    return (<MenuItem key={index} value={el.id}>{el.contentName}</MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        lockedList.concat(articleList).length ? (
                            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                                {t('common.buttons.preview')}
                            </Button>
                        ) : null
                    }
                </div>
            </div>
            <div className="articles-page">
                {
                    (
                        articleList.length
                        || archiveList.length
                        || lockedList.length
                        || draftList.length
                        || approvalList.length
                    ) ? (
                        <>
                            {
                                lockedList.length > 0 && (
                                    <div>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                pagination
                                                autoHeight={true}
                                                rows={lockedList}
                                                columns={lngDirection === 'ltr' ? columns : reverseDirection(columns)}
                                                rowHeight={90}
                                                headerHeight={56}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                articleList.length > 0 && (
                                    <div>
                                        <div className="d-flex justify-between">
                                            <h3>
                                                {
                                                    // @ts-ignore
                                                    convertToDate(articleList[0].publishDate, 'header')
                                                } ({totalActiveArticles})
                                            </h3>
                                            <div className="delete-select-container">
                                                <Button
                                                    className="link-button-container"
                                                    onClick={handleArchiveSelected}
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t("common.fieldHeaders.moveToArchive")}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={`table-grid drag-and-drop-table`}>
                                            <DragAndDropTable
                                                columns={articleColumns}
                                                rows={articleList}
                                                onReorder={handleReorder}
                                                pagination
                                                rowsPerPage={pageSize}
                                                totalRows={totalActiveArticles}
                                                pageType='articles'
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                approvalList.length > 0 && (
                                    <div>
                                        <h3>{t('common.fieldHeaders.waitingApproval')} ({approvalList.length})</h3>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={approvalList}
                                                columns={lngDirection === 'ltr'
                                                    ? (roleType === 'chiefEditor' ? approvalColumns : columns)
                                                    : reverseDirection((roleType === 'chiefEditor' ? approvalColumns : columns))}
                                                rowHeight={90}
                                                headerHeight={0}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                draftList.length > 0 && (
                                    <div>
                                        <h3>{t('common.drafts')} ({draftList.length})</h3>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={draftList}
                                                columns={lngDirection === 'ltr' ? columns : reverseDirection(columns)}
                                                rowHeight={90}
                                                headerHeight={0}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                archiveList.length > 0 && (
                                        <div>
                                            <div className="d-flex justify-between">
                                                <h3>{`${t('common.archive')} (${totalArchiveArticles})`}</h3>
                                                <div className="delete-select-container">
                                                    <Button
                                                        className="link-button-container"
                                                        onClick={handleDeleteSelected}
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t("users.allUsers.delete")}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className={`table-grid element-faded`}>
                                                <DataGrid
                                                    autoHeight={true}
                                                    rows={archiveList}
                                                    columns={lngDirection === 'ltr' ? archiveColumns : reverseDirection(archiveColumns)}
                                                    rowHeight={90}
                                                    disableColumnMenu={true}
                                                    hideFooter={true}
                                                />
                                            </div>
                                        </div>
                                )
                            }
                            {
                                <>
                                    <PopUpActionMenu toggleArchive={toggleArchive}
                                        handleDuplicate={handleDuplicate}
                                        handleAction={handleNotificationOpen}
                                        currentPage="articles" />
                                    {
                                        lockedList.concat(articleList).length ? (
                                            <PreviewModal className="create-article article list-preview" openModal={openModal} handleClose={handleCloseModal} hideNotYet content={
                                                <div className="scroll-container">{lockedList.concat(articleList).map((article: any, index) => {
                                                    const category = categories.filter((cat: any) => cat.id === article.categoryId)?.[0]?.contentName ?? '';
                                                    const image = article?.thumbnail ?? article?.image ?? '';
                                                    const textEditor = article?.details?.text ?? '';
                                                    const template = article.template;
                                                    const writer = article.details?.writer ?? '';
                                                    const shortDescription = article.details?.link_description ?? '';
                                                    return (
                                                        <div key={index} className={`preview-image-container all-articles-preview template-${template}`}>
                                                            <div className={`preview-image template-${template}`}>
                                                                {
                                                                    template !== 3 ?
                                                                        (
                                                                            <img
                                                                                src={image}
                                                                                alt={article.header}
                                                                            />
                                                                        ) : null
                                                                }
                                                            </div>
                                                            {
                                                                (template === 1) && (
                                                                    <div className={`template-1-container`}>
                                                                        <div className="preview-category">
                                                                            <span>{category || t('common.fieldHeaders.category')}</span>
                                                                        </div>
                                                                        <p className="preview-header articles">{article.header || t('articles.allArticles.create.form.preview.headerGoes')}</p>
                                                                        {
                                                                            shortDescription && (
                                                                                <p className="preview-date">
                                                                                    {shortDescription}
                                                                                </p>
                                                                            )
                                                                        }
                                                                        <p className="preview-date">{convertToDate(article.publishDate)}</p>
                                                                    </div>
                                                                )
                                                            }
                                                            {template === 2 && (
                                                                <>
                                                                    <div className={`template-2-container`}>
                                                                        <div className="preview-short-text">
                                                                            <div className="preview-text-header">
                                                                                <span>
                                                                                    {article.header ||
                                                                                        t(
                                                                                            "articles.allArticles.create.form.preview.detailsHeader"
                                                                                        )}
                                                                                </span>
                                                                            </div>
                                                                            <div className="preview-html">
                                                                                {
                                                                                    shortDescription ? (
                                                                                        <p className="preview-date">
                                                                                            {shortDescription}
                                                                                        </p>
                                                                                    ) : (textEditor
                                                                                        ? displayHTMLPhone(textEditor)
                                                                                        : t(
                                                                                            "articles.allArticles.create.form.preview.detailsText"
                                                                                        ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`preview-dates`}>
                                                                        <span>{convertToDate(article.publishDate)}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {
                                                                template === 3 && (
                                                                    <>
                                                                        <div className="template-3-container">
                                                                            <div className="preview-category">
                                                                                <span>{category || "Category"}</span>
                                                                            </div>
                                                                            <p className="preview-date">
                                                                                {convertToDate(article.publishDate)}
                                                                            </p>
                                                                            <div className="preview-short-text">
                                                                                <div className="break-text">
                                                                                    <div className="preview-text-header">
                                                                                        <span>
                                                                                            {article.header ||
                                                                                                t(
                                                                                                    "articles.allArticles.create.form.preview.detailsHeader"
                                                                                                )}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="preview-html">
                                                                                        {
                                                                                            shortDescription ? (
                                                                                                <p className="preview-date">
                                                                                                    {shortDescription}
                                                                                                </p>
                                                                                            ) : (textEditor
                                                                                                ? displayHTMLPhone(textEditor, 250, 8)
                                                                                                : t(
                                                                                                    "articles.allArticles.create.form.preview.detailsText"
                                                                                                ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`preview-author`}>
                                                                            <span>{writer}</span>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            <div className={`preview-icons template-${template}`}>
                                                                <FavoriteIcon className="icon favorite" />
                                                                <MoreHorizIcon className="icon dots" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}</div>
                                            } />
                                        ) : null
                                    }
                                </>
                            }
                        </>
                    ) : <></>
                }
                {
                    !(articleList.length || archiveList.length || lockedList.length || draftList.length) ? (
                        <div className="no-articles">
                            <img src={NoArticles} alt="" />
                            <h3>{t('articles.allArticles.noArticles.message1')}</h3>
                            <Link to="/articles/create/article" onClick={() => actionStore.toggleSearch(false)}>{t('articles.allArticles.noArticles.message2')}</Link>
                        </div>
                    ) : <></>
                }
            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose()}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default observer(AllArticles)
