export const en = {
  common: {
    termsAndPrivacy: "Our Terms of Use and Privacy Policy",
    readTermsAndConditions: "I've read the terms and conditions",
    comingSoon: "Coming Soon!",
    underConstruction: "This page is under construction, stay tuned :)",
    communityNotSelected: "Not selected",
    currency: "$",
    israeliCurrency: "₪",
    noSecondaryDepartments: "Not associated",
    management: "Management",
    buttons: {
      save: "Save",
      send: "Send",
      agree: "Agree",
      close: "Close",
      preview: "Preview",
      previewAndPublish: "Preview and Publish",
      saveAsDraft: "Save as draft",
      invite: "Invite",
      update: "Update",
      add: "Add",
      next: "Next",
      yes: "Yes",
      no: "No",
      confirm: "Confirm",
      showOriginal: "Show Original",
      buttonText: "Button text",
    },
    forgotPassword: "Forgot password?",
    signInLabel: "Sign In",
    signUpLabel: "Sign Up",
    remember: "Do you remember?",
    doNotHaveAccount: "Don't have an account?",
    fieldHeaders: {
      search: "Search",
      category: "Category",
      categories: "Categories",
      header: "Header",
      managedBy: "Managed By",
      writer: "Writer",
      live: "Live",
      archived: "Archived",
      moveToArchive: "Archive",
      push: "Push",
      actions: "Actions",
      date: "Date",
      from: "From",
      to: "To",
      uploadImage: "Upload image",
      uploadImageOrVideo: "Upload image or video",
      appears: "Article should appear in",
      whoWillSeeAd: "Who will see this ad?",
      sendPushNotification: "Send push notification?",
      pushNotificationText: "Push Notification text",
      whenToPublishArticle: "When to publish article?",
      whenToPublishBenefit: "When to publish benefit?",
      now: "Now",
      later: "Later",
      whenToArchiveArticle: "When to archive the article?",
      whenToArchiveBenefit: "When to archive the benefit?",
      globalDate: "Global date as set in Settings",
      specificDate: "On a specific date",
      iWillWrite: "I will write the article",
      externalLink: "External link",
      url: "URL",
      articleText: "Article text",
      whenToRemoveAd: "When to remove the ad?",
      subHeader: "Sub-Header",
      ctaText: "CTA text",
      publishedOn: "Published on",
      searchArchive: "Search Archive",
      type: "Type",
      price: "Price",
      id: "ID",
      location: "Location",
      quantity: "Quantity",
      scheduled: "Scheduled",
      pageType: "Page Type",
      description: "Description",
      dateFrom: "Date from",
      dateTo: "Date to",
      fullName: "Full Name",
      emailAddress: "E-mail address",
      phoneNumber: "Phone Number",
      numberOfTickets: "Number of tickets",
      maxPurchases: "Max Purchases",
      department: "Segmentation 1",
      secondaryDepartment: "Segmentation 2",
      invitation: "Invitation",
      fromDate: "From date",
      toDate: "To date",
      lowInventory: "Low Inventory",
      status: "Status",
      waitingApproval: "Waiting Approval",
      draft: "Draft",
      message: "Message",
      sendTo: "Send to",
      openRate: "Open Rate",
      amount: "Amount",
      numberOfClicks: "Number of Clicks",
      shortDescription: "Short Description",
    },
    selectFilters: {
      allCommunities: "All Communities",
      allCategories: "All Categories",
      allGroups: "All Groups",
      allWriters: "All Writers",
      allTypes: "All Types",
      allArticles: "All Articles",
      allMessages: "All Messages",
      all: "All",
      leads: "LEADS",
      link: "LINK",
      store: "STORE",
      survey: "SURVEY",
    },
    drafts: "Drafts",
    archive: "Archive",
    active: "Active",
    uploading: "Uploading...",
    updating: "Updating...",
    proportions:
      "The recommended proportions of the image are: {{ x }}:{{ y }}",
    errors: "Errors:",
  },
  signIn: {
    fields: {
      email: {
        label: "Email",
      },
      password: {
        label: "Password",
      },
    },
    sms: {
      or: "OR",
      navTo: "Login using sms code",
      fields: {
        phone: "Your phone number",
      },
      submitButton: "Send me Code",
      navToSignIn: "Log in with User & Password",
    },
  },
  signUp: {
    navTo: "Have an account?",
    fields: {
      name: {
        label: "User Name",
      },
      firstName: {
        label: "First Name",
      },
      lastName: {
        label: "Last Name",
      },
      phone: {
        label: "Phone",
      },
      email: {
        label: "Email",
      },
      password: {
        label: "Password",
      },
      retypePassword: {
        label: "Retype Password",
      },
    },
  },
  forgotPassword: {
    message: "Enter details for a password reset link",
    fields: {
      email: {
        label: "Email",
      },
    },
  },
  emailSent: {
    message1: "We sent you a temporary password reset link",
    message2: "Please check your Inbox",
    message3: "The link expires in 10 minutes",
  },
  resetPassword: {
    title: "Reset password",
    subTitle: "Create new password",
    fields: {
      password: {
        label: "New Password",
      },
      retypePassword: {
        label: "Retype New Password",
      },
    },
  },
  otpCode: {
    tempCode: "Temporary - Code Number:",
    fields: {
      code: "Type the code number",
    },
    message: "Did not receive a code?",
    sendMeAgain: "Send me again",
    login: "Login",
  },
  tabNavigation: {
    articles: {
      mainTab: "Articles",
      allArticles: "All Articles",
      rotatingBanner: "Rotating Banner",
      ads: "Ads",
    },
    benefits: {
      mainTab: "Applications",
    },
    users: {
      mainTab: "Users",
      allUsers: "All users",
      importFile: "Import from file",
      inviteApp: "Invite to App",
      waitingApproval: "Waiting for approval",
      createUser: "Create user",
    },
    community: {
      mainTab: "Community",
      mainTabForOwner: "Communities",
    },
    reports: {
      mainTab: "Reports",
      surveys: "Surveys",
      leads: "Leads",
      purchases: "Purchases",
      clicks: "Clicks",
    },
    push: {
      mainTab: "Push",
      promoNotification: "Promotional Notifications",
      autoNotification: "Automated Notifications",
    },
    settings: {
      mainTab: "Settings",
      general: "General",
      myCommunity: "My Community",
      archive: "Archive",
      manageCategories: "Manage Categories",
      manageGroups: "Manage Groups",
      manageDepartments: "Manage Segmentations",
      permissions: "Permissions",
      communityPolicy: "Community Policy",
    },
    temporaryDisabled: "Temporary Disabled",
  },
  dashboard: {
    actions: {
      writeArticle: "Write an article",
      addSale: "Add a sale Item",
      createAd: "Create an ad",
      addUsers: "Add users",
    },
    articlesStatistics: "Most popular articles",
    currentWeekEventList: "Happening this week",
    draftsListComponent: "My drafts",
    latestActivitiesComponent: "Latest activity",
    welcomeSection: {
      message: "Welcome Back,",
      question: "What would you like to do today?",
    },
    search: {
      resultFor: "Results for",
      searchResults: "Search Results",
      noResults: {
        title: "No results for",
        message1: "This is not a time to rest.",
        message2: "Refine your search criteria",
      },
    },
    noDrafts: "No Drafts found.",
    noThisWeek: "Nothing this week.",
    noPopularActivities: "No Popular Articles found.",
    noActivity: "No Activities found.",
  },
  articles: {
    allArticles: {
      title: "All Articles",
      noArticles: {
        message1: "No articles found",
        message2: "Write your first article now",
      },
      create: {
        saveDraft: "The article has been successfully saved as draft.",
        title: "New Article",
        template: {
          title: "Select your template",
          fullImage: "Full image",
          imageShortDescription: "Image + short description",
          noImage: "No image",
          onlyImageNoText: "Only image, no text",
        },
        form: {
          title: "Article Preview",
          subTitle: "Article Details",
          articleName: "Article Name",
          preview: {
            headerGoes: "Header goes here",
            detailsHeader: "Details Header",
            detailsText: "Details Text",
            showMore: "Show more",
          },
        },
      },
      view: {
        title: "View Article",
      },
      edit: {
        title: "Edit Article",
      },
      search: {
        title: "Search Articles",
        articlesManagedBy: "Articles Managed by",
      },
    },
    rotatingBanner: {
      title: "Rotating Banner",
      noArticles: {
        message1: "Nope.",
        message2: "Nothing here",
      },
      create: {
        title: "New Banner",
        preview: {
          headerGoes: "Header goes here",
          showMore: "Show more",
        },
      },
      view: {
        title: "View Banner",
      },
      edit: {
        title: "Edit Banner",
      },
      search: {
        title: "Banner Search",
      },
    },
    ads: {
      title: "Ads",
      noArticles: {
        message1: "No ads found.",
        message2: "Add an ad",
      },
      create: {
        title: "New Ad",
        form: {
          preview: {
            subHeaderGoes: "Sub-Header",
          },
        },
      },
      view: {
        title: "View Ad",
      },
      edit: {
        title: "Edit Ad",
      },
      search: {
        title: "Ads Search",
      },
    },
  },
  benefits: {
    draft: "The benefit has been successfully saved as draft.",
    validationMessages: {
      fillLabel: "Please fill label.",
      fillKey: "Please fill key.",
      fillValue: "Please fill value.",
      labelCantHaveNumberAsFirstChar: "Label cant start with a number.",
    },
    title: "Sales",
    all: "All",
    noBenefits: {
      message1: "No benefits found",
      message2: "Write your first benefit now",
    },
    create: {
      title: "New Benefit",
      enterLabel: "Enter label",
      form: {
        title: "General",
        preview: {
          subHeaderGoes: "Sub-Header goes here",
        },
        details: "Details",
        detailsText: "Details text",
        detailsEmailTitle:
          "Set the email address to which the leads will be sent",
        detailsEmail: "E-mail address",
        formBuilder: {
          title: "Form Builder",
          selectFieldType: "Select field type",
          textfield: "Text field",
          dropdown: "Dropdown",
          checkbox: "Checkbox",
          radio: "Radio Button",
          key: "Key",
          enterKey: "Enter key",
          value: "Value",
          enterValue: "Enter value",
          addOption: "Add Option",
          isRequired: "A mandatory question",
        },
        addButton: "Add Button",
        buttonText: "Button Text",
        link: "Link",
      },
    },
    view: {
      title: "View Benefit",
    },
    edit: {
      title: "Edit Benefit",
    },
    search: {
      title: "Search Sales",
    },
  },
  users: {
    allUsers: {
      title: "All Users",
      totalUsers: "Total users:",
      downloadedApp: "Number of downloads:",
      nonActive: "Non-active",
      invite: "Invite",
      delete: "Delete",
      noContent: {
        message1: "No users found",
        message2: "Start adding users to your community",
      },
      popUp: {
        message: "This action is permanent",
        action: "Yes, remove",
      },
      appInstalled: "App installed",
      export: "EXPORT ALL"
    },
    create: {
      title: "New User",
      subTitle: "User Details",
      addUser: "Add user",
      owner: "Owner",
      associatedOwner: "Associated Owner",
      chiefEditor: "Chief Editor",
      admin: "Admin",
      editor: "Editor",
      user: "User",
    },
    search: {
      title: "Search Users",
      downloadApp: "Downloaded App",
    },
    importFromFile: {
      importUserList: "Import User List from file",
      uploadExcel:
        "Upload excel file with set columns. More info about the file",
      here: "here",
      pleaseNote: "Please note - existing users will not be duplicated.",
      allUsersList: "All Users List",
      newUsers: "New Users",
      updatedUsers: "Users details were updated",
      turnNonActive: "Users will turn non-active",
      clickHere: "Please click here to upload file or drag & drop directly.",
      importOptionMessage:
        "You are about to load new users or update existing list users. Please select the correct option:",
      create: "Add new users",
      update: "Update existing users",
      deactivateUsers: "Make missing users inactive",
    },
    inviteToApp: {
      title: "Invite People to App",
      labelTitle: "Title",
      sendEmail: "Send an Email",
      titleName: "Please enter a title for the email",
      messageContent: "Please enter the content of the message here",
      emailContent: "Please enter the content of the email here",
      emails: "Emails",
      emailsComma: "You can add a list of emails separated by comma",
      sendSMS: "Send a SMS",
      phoneComma: "You can add a list of phone numbers separated by comma",
      copyLink: "Copy Link",
      link: "Link",
      copy: "Copy",
    },
    waitingForApproval: {
      title: "Waiting for Approval",
      inviteBy: "Invitation by",
      approve: "Approve",
      approveAll: "Approve all",
      reject: "Reject",
      noContent: "No one is waiting for approval",
    },
  },
  community: {
    list: {
      title: "All Organizations",
      name: "Name",
      phone: "Phone",
      email: "Email",
      group: "Group",
      editors: "Editors",
      users: "Users",
      actions: "Actions",
      noCommunity: "No communities found.",
    },
    view: {
      canRemoveArticles: "Can remove Management articles?",
      addEditor: "Add Editor",
      chooseFromUserList: "Choose from user list:",
      addSelected: "Add Selected",
      free: "Free",
    },
    create: {
      title: "New Community",
      subTitle: "Community Details",
      ce: "Chief Editor",
      created: "The community has been successfully created.",
    },
    edit: {
      title: "Edit Community",
    },
    search: {
      title: "Search Organizations",
    },
  },
  reports: {
    fromDate: "From date:",
    untilDate: "Until date:",
    dashboard: "Dashboard",
    searchTitle: "Search Purchases",
    title: "Reports",
    sent: "Sent",
    opened: "Opened",
    purchased: "Purchased",
    openRatePush: "Open Rate Push Notifications",
    noOpenRatePush: "There are no push notifications for the selected period.",
    noPurchases: "There are no purchases for the selected period.",
    purchases: {
      title: "Purchases",
      cancelPurchase: "Cancel purchase",
      totalPurchases: "Total Purchases",
      total: "Total:",
      confirmClose: "Аre you sure you want to close the purchase ?",
      buyersCount: "Buyers {{ count }}",
      noContent: {
        message1: "No purchases found",
        message2: "Refine your search criteria",
      },
      informationModal: {
        header: "Purchasing details",
        export: "Export Excel",
        cancelledPurchase: "Purchase is cancelled",
      },
    },
    clicks: {
      clicksCount: "Clicks {{ count }}",
      informationModal: {
        header: "Click Details",
      },
      noContent: {
        message1: "No clicks found",
      },
    },
    surveys: {
      questionsCount: "Questions in survey {{ count }}",
      participantsCount: "Participants {{ count }}",
      informationModal: {
        header: "Survey results",
        question: "Question {{ num }}",
        showParticipants: "Show Participants",
        popUp: {
          header: "List of respondents",
        },
        anonymous: "Anonymous",
      },
      noContent: {
        message1: "No surveys found",
      },
    },
    leads: {
      title: "Leads",
      noContent: {
        message1: "No leads found",
      },
      leadsCount: "Leads {{ count }}",
      informationModal: {
        header: "Lead Details",
        export: "Export Excel",
      },
    },
  },
  push: {
    errors: {
      time: "Please make sure that you've selected a future date.",
    },
    promoNotification: {
      message: "Message",
      sentTo: "Sent To",
      openRate: "Open Rate",
      aboutToBeSent: "About to be sent",
    },
    autoNotification: {
      title: "Automated System Notifications",
    },
    noNotifications: {
      message1: "No notifications found",
      message2: "You can send one now",
    },
    search: {
      title: "Search Push",
    },
    create: {
      title: "New Push Notification",
      whenToSendNotification: "When to send the notification?",
      messages: "MESSAGES",
      yourMessage: "Your message",
    },
    edit: "Edit Push Notification",
    view: "View Push Notification",
    myCommunity: "My Community",
  },
  settings: {
    general: {
      title: "General Settings",
      topBar: "Top Bar",
      backgroundColor: "Background Color",
      bottomBar: "Bottom Bar",
      text: "Text",
      textColor: "Text Color",
      companyName: "Community Name", //
      primaryColor: "Primary Color",
      uploadLogo: "Upload logo for Navigation Bar",
      emailSettings: "Contact Us Settings",
      sendersName: "Name for Contact",
      sendersEmail: "Email for Contact",
      sendersPhone: "Phone for Contact",
      saved: "The community has been successfully saved.",
    },
    myCommunity: {
      title: "Community Settings",
      subTitle: "My Community",
      uploadLogo: "Upload transparent logo",
      uploadBackground: "Upload Background image",
      links: "Links",
      addLink: "Add Link",
      addName: "Add Name",
      icon: "Icon",
      addCategory: "ADD CATEGORY",
      addDepartment: "ADD SEGMENTATION",
      addGroup: "ADD GROUP",
      vaadLeaders: "Community Leaders",
      communitiesLeaders: "Community Leaders",
      presentAllEditors: "Present all Editors as contact persons here",
    },
    archive: {
      title: "Archive Settings",
      moveArticles: "Move Articles to archive after:",
      days: "Days",
      andOr: "And/or",
      saleItem: "Sale Items",
      moveSaleItems: "Move Sale items to archive after:",
      items: "Items",
    },
    manageCategories: {
      categories: "Manage Categories",
      content: "Content",
      order: "Order",
      categoryName: "Category Name",
      typeCategory: "Type Category Name",
      benefits: "Benefits Categories",
      leads: "Leads",
      link: "Link",
      store: "Store",
      platform: "Platform",
    },
    manageDepartments: {
      title: "Manage Segmentations",
      departmentName: "Segmentation Name",
      segmentation: "Segmentation",
    },
    manageGroups: {
      title: "Manage Group",
      groupName: "Group Name",
    },
    permissions: {
      selectAll: "Select all",
      banners: "Banners",
    },
    communityPolicy: {
      privacyPolicy: "Privacy Policy",
    },
  },
  myAccount: {
    title: "My Account",
    details: "Personal Details",
    myCommunities: "My Communities",
    uploadPicture: "Upload profile picture",
  },
  leftSideBar: {
    as: "AS",
    logOut: "Are you sure you want to log out?",
  },
  popUpActionMenu: {
    edit: "Edit",
    view: "View",
    duplicate: "Duplicate",
    unarchive: "Unarchive",
    delete: "Delete",
    copyLink: "Copy Link",
    goToResults: "Go To Results",
  },
  previewModal: {
    looksGood: "LOOKS GOOD!",
    notYet: "Not yet",
  },
  unsavedChanges: {
    areYouSure: "Are you sure you want to leave without saving changes?",
    permanent: "This action is permanent",
    yesLeave: "Yes, leave",
  },
  messages: {
    allMessages: "All Messages",
    meMessages: "My Messages",
    noMessages: "No messages found",
    today: "Today, ",
    yesterday: "Yesterday, ",
  },
  termsAndPrivacy: {
    title: "Terms and conditions",
    paragraph1:
      "Est perferendis consequatur sed neque nam quibusdam quia quia consequatur. Eius animi delectus qui alias. Voluptas ea aut  quia ut rerum occaecati quia quia  error.",
    paragraph2:
      "Numquam et cumque quia quia perferendis. In et aut voluptate laborum animi maiores iure. Sed quiaDoloremque nulla rerum eaque fugiat optio. Molestias quam ut quo tempore aut et ut.",
    paragraph3:
      "ipsam modi temporibus quis exercitationem qui quia quia  similique. Aut a quas magnam. Est perferendis consequatur neque nam quibusdam",
    iHaveRead: "I've read the terms and conditions",
  },
  errorMessages: {
    signUpMissMatchPass: "Password doesn't match",
    duplicateAdds: "You cannot have more than 2 locked ads!",
    lockedArticles: "You cannot have more than 2 locked articles!",
    lockedBanners: "You cannot have more than 2 locked banners!",
    approveUser: "Are you sure you want to approve user?",
    deleteOneUser: "Are you sure you want to delete user?",
    deleteAll: "Are you sure you want to delete all?",
    approveAll: "Are you sure you want to approve all?",
    deleteUser: "Are you sure you want to delete the selected users?",
    needUser: "Need to select one or more users.",
    needToSelect: "Need to select one or more items.",
    delUserSuccess: "User successfully deleted.",
    delUserFailed: "Error during deleting user.",
    inviteUser: "Are you sure you want to invite selected users?",
    deleteItems: "Are you sure you want to delete these items?",
    archiveItems: "Are you sure you want to archive these items?",
    deleteOne: "Are you sure you want to delete this item?",
    deleteCommunity: "Are you sure you want to delete this community?",
    deleteQuestion: "Are you sure you want to delete this item?",
    archiveQuestion: "Are you sure you want to archive this item?",
    unarchiveQuestion: "Are you sure you want to unarchive this item?",
    fileSize: "File size cannot exceed 25MB.",
    fileJpg: "File must be a JPG/JPEG image.",
    fileXls: "File must be a XLSX format.",
    copiedLink: "Link copied to clipboard.",
    minimumDate: "Date should not be before minimal date",
    login: {
      noGroupAndCommunity:
        "Welcome to Community. In order to continue on using our platform you must create a group and a community.",
      noCommunity:
        "Welcome to Community. In order to continue on using our platform you must create a community.",
      threeTimesError:
        "You have to close the web and try over again / reset password.",
    },
    articles: {
      create: {
        noCategories:
          "No categories found. Please create new in the Settings tab.",
        noGroups: "No groups found. Please create new in the Settings tab.",
        noDepartments:
          "No segmentations found. Please create new in the Settings tab.",
        noDepartmentsForOwner:
          "No segmentations found. Please change community.",
        noSecondaryDepartments:
          "No secondary segmentations found. Please create new in the Settings tab.",
        noSecondaryDepartmentsForOwner:
          "No secondary segmentations found. Please change community.",
        noCommunities:
          "No communities found. Please create new in the Community tab.",
      },
    },
    settings: {
      noAccess:
        "You do not have access for the attempted action. Please login again.",
      general: {
        hexFormatError: "Invalid format. Please use the color picker.",
      },
      community: {
        maxLeaders:
          "You cannot have more than {{maxLeaders}} community leaders",
      },
      groups: {
        cannotDelete: "Group cannot be deleted.",
        inUse: "Group is used in an Article.",
        saved: "Group successfully created.",
      },
      departments: {
        cannotDelete: "Segmentation cannot be deleted.",
        inUse: "Segmentation is used in an Article.",
        saved: "Segmentation successfully created.",
      },
    },
    users: {
      deleteUser: "Are you sure you want to remove {{user}}?",
    },
    uploadFile: {
      maxSizeImage: "Image file size cannot exceed 25MB.",
      formatsImage: "File must be a {{formats}} image.",
      maxSizeVideo: "Video file size cannot exceed 50MB.",
      formatsAll: "File must be a {{formats}} or MP4 image.",
      informationMessage:
        "*Here you can add JPG/PNG file up to 25MB or a MP4 file up to 50MB",
    },
  },
  informationMessages: {
    formatsAll:
      "*Here you can add JPG/PNG file up to 25MB or a MP4 file up to 50MB",
    imageOnly: "*Here you can add JPG/PNG file up to 25MB",
    pngOnly: "*Here you can add PNG file up to 25MB",
  },
  staticMessages: {
    noDepartments:
      "There is no segmentation for this community please change Community.",
  },
  validationMessages: {
    account: {
      required: "This field is required.",
      email: "Please enter a valid email.",
      phone: "Phone must be 10 digits.",
      minName: "This field should be at least 2 characters.",
      maxName: "This field should not be more than 15 characters.",
    },
    articles: {
      adFields: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader50: "This field should not be more than 50 characters.",
        maxHeader65: "This field should not be more than 65 characters.",
        url: "Link must be a valid URL.",
        image: "Image is required.",
        oneSelected: "Appears must have at least 1 item selected.",
        oneLetter: "This field should be at least 1 characters.",
      },
      addArticle: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than {{ max }} characters.",
        oneLetter: "This field should be at least 1 characters.",
        oneSelected: "Appears must have at least 1 item selected.",
        url: "Link must be a valid URL.",
        longText: "Text should be at most 10000 characters.",
      },
      banner: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
        oneSelected: "Appears must have at least 1 item selected.",
        image: "Image is required.",
        longText: "Text should be at most 1500 characters.",
        url: "Link must be a valid URL.",
        oneLetter: "This field should be at least 1 characters.",
      },
    },
    view: {
      adFields: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader50: "This field should not be more than 50 characters.",
        maxHeader65: "This field should not be more than 65 characters.",
        url: "Link must be a valid URL.",
        image: "Image is required.",
        oneSelected: "Appears must have at least 1 item selected.",
        oneLetter: "This field should be at least 1 characters.",
      },
      article: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
        oneLetter: "This field should be at least 1 characters.",
        oneSelected: "Appears must have at least 1 item selected.",
        url: "Link must be a valid URL.",
        longText: "Text should be at most 1500 characters.",
      },
      banner: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
        oneSelected: "Appears must have at least 1 item selected.",
        image: "Image is required.",
        longText: "Text should be at most 1500 characters.",
        url: "Link must be a valid URL.",
        oneLetter: "This field should be at least 1 characters.",
      },
    },
    community: {
      create: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 30 characters.",
        email: "Please enter a valid email.",
        phone: "Phone must be 10 digits.",
        pass: "Password must be at least 6 characters.",
      },
      edit: {
        required: "This field is required.",
        phone: "Phone must be 10 digits.",
        email: "Please enter a valid email.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 15 characters.",
        pass: "Password must be at least 6 characters.",
      },
    },
    push: {
      create: {
        required: "This field is required.",
        url: "Link must be a valid URL.",
        image: "Image is required.",
        oneSelected: "Appears must have at least 1 item selected.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
      },
      view: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
        url: "Link must be a valid URL.",
        image: "Image is required.",
      },
      unableToDelete: "Failed to delete these pushes: {{ pushes }}",
    },
    users: {
      allUsers: {
        required: "This field is required.",
        phone: "Phone must be 10 digits.",
        email: "Please enter a valid email.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 15 characters.",
      },
      create: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 15 characters.",
        email: "Please enter a valid email.",
        phone: "Phone must be 10 digits.",
      },
      inviteToApp: {
        phonePattern: "Phone number must be Israeli format.",
      },
    },
    settings: {
      general: {
        required: "This field is required.",
        email: "Please enter a valid email.",
        phone: "Phone must be 10 digits.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than {{max}} characters.",
        bgColor: "Background Color must be a Hex Format.",
        prmColor: "Primary Color must be a Hex Format.",
      },
    },
    benefits: {
      create: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 1000 characters.",
        maxHeader20: "This field should not be more than 20 characters.",
        url: "Link must be a valid URL.",
        image: "Image is required.",
        atLeastOne: "At least one field is required",
        tickets: "Tickets need to be more than 0.",
        maxPurchases: "Maximum Purchases cannot exceed Number of Tickets.",
        price: "Price needs to be more than 0.",
        detailsText: "Details Text should be at most 10000 characters.",
        pushNot: "Notification text be at least 1 characters.",
        numbers: "This field should be valid number",
      },
      view: {
        required: "This field is required.",
        minHeader: "This field should be at least 2 characters.",
        maxHeader: "This field should not be more than 50 characters.",
        detailsText: "Details Text should be at most 1500 characters.",
        price: "Price needs to be more than 0.",
        tickets: "Tickets need to be more than 0.",
        url: "Link must be a valid URL.",
        atLeastOne: "At least one field is required",
        email: "Please enter a valid email.",
        fullName: "Full Name should not exceed 10 characters.",
        pushNot: "Notification text be at least 1 characters.",
        image: "Image is required.",
      },
    },
    auth: {
      signUp: {
        required: "This field is required.",
        phone: "Phone must be 10 digits.",
        email: "Please enter a valid email.",
        minPass: "This field should be at least 6 characters.",
        phonePattern: "Phone number must be Israeli format.\nEx: 05* *******",
      },
      signIn: {
        required: "This field is required.",
        email: "Please enter a valid email.",
        noPlatform:
          "No platform detected. Please make sure you've entered the correct URL link.",
      },
    },
  },
  multiSelectLabels: {
    allItemsAreSelected: "All items are selected.",
    allGroupsAreSelected: "All groups are selected.",
    allDepartmentsAreSelected: "All segmentations are selected.",
    allCategoriesAreSelected: "All categories are selected.",
    allCommunitiesAreSelected: "All communities are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select...",
  },
  timePicker: {
    confirm: "Confirm",
    cancel: "Cancel",
  },
  mobile: {
    sideMenu: {
      role: "Role:",
      addArticle: "Add new article",
      addPush: "Add new push",
      addUser: "Add new user",
      backToApp: "Back to App",
      goToDesktopSite: "Go to desktop site",
    },
    createMenu: {
      article: "Article",
    },
    footer: {
      messages: "Messages",
      profile: "Profile",
    },
    articles: {
      title: "My Article",
      noContent: "You have not created any articles yet",
      success: {
        message: "Your article has been submitted for chief editor",
      },
    },
    notifications: {
      title: "Push Notifications",
      noContent: "You have not created any notifications yet",
      success: {
        willBeHere: "Will be here",
        soon: "SOON!",
        great: "Great!",
        message: "Your push notification has been submitted for chief editor",
        allNotifications: "All Notifications",
      },
    },
    statuses: {
      published: "Published",
      pending: "Pending",
      awaitingToBeSent: "Awaiting to be sent",
    },
    messages: {
      showArticle: "SHOW ARTICLE",
      showPush: "SHOW PUSH",
    },
  },
  apiMessages: {
    0: "An error occurred",

    // Article
    /* Fail */
    A000: "Article not found",
    A001: "You cannot have more than 2 locked articles!",
    A002: "Action completed succesfully",
    A100: "Article not found",
    A101: "Cannot create more than 20 rotating banners",

    /* Success */
    A200: "Article successfully created",
    A201: "Article successfully updated",
    A202: "Article successfully deleted",
    A203: "Article successfully duplicated",
    A204: "Article is archived",
    A205: "Article is un-archived",
    A206: "Article is locked",
    A207: "Article is un-locked",
    A208: "Article is liked",
    A209: "Article is un-liked",
    A212: "Article saved as draft",

    // Ads
    /* Fail */
    AD000: "Ad not found",
    AD001: "You cannot have more than 2 locked ads!",

    /* Success */
    AD200: "Ad successfully created",
    AD201: "Ad successfully updated",
    AD202: "Ad successfully deleted",
    AD203: "Ad successfully duplicated",
    AD204: "Ad is archived",
    AD205: "Ad is un-archived",
    AD206: "Ad is locked",
    AD207: "Ad is un-locked",
    AD208: "Ad saved as draft",

    // Benefits
    /* Fail */
    B000: "Benefit not found",
    B001: "Type doesn't match the category type",
    B002: "You cannot have more than 2 locked benefits!",
    B100: "Benefit not found",
    B101: "User not in community",
    B102: "Senders email is not set in community",
    B103: "Benefit is not a LEAD",

    /* Success */
    B200: "Benefit successfully created",
    B201: "Benefit successfully updated",
    B202: "Benefit successfully deleted",
    B203: "Benefit successfully duplicated",
    B204: "Benefit is archived",
    B205: "Benefit is un-archived",
    B206: "Benefit is locked",
    B207: "Benefit is un-locked",
    B208: "Benefit is liked",
    B209: "Benefit is un-liked",
    B210: "Lead successfully submitted",
    B211: "Benefit saved as draft",

    // Article Category
    /* Fail */
    AC000: "Article category not found",

    /* Success */
    AC200: "Article category successfully created",
    AC201: "Article category successfully updated",
    AC202: "Article category successfully deleted",

    // Benefit Category
    /* Fail */
    BC000: "Benefit category not found",
    BC001: "Invalid category type",

    /* Success */
    BC200: "Benefit category successfully created",
    BC201: "Benefit category successfully updated",
    BC202: "Benefit category successfully deleted",

    // Push
    /* Fail */
    P000: "Push not found",
    P001: "Unable to create a push before current time",
    P002: "Unable to update a push after it's time passed",

    /* Success */
    P200: "Push successfully created",
    P201: "Push successfully updated",
    P202: "Push successfully deleted",

    // Order
    /* Fail */
    O000: "Order not found",
    O001: "Currency not supported",
    O002: "Tranzila handshake failed",
    O003: "Please note! The date of cancellation of payment has passed in accordance with the policy of cancellation of purchase on credit. Contact your community manager for more details.",
    O004: "Payment not successful",
    O005: "Invalid item count",
    O006: "Order is already paid",
    O007: "Benefit is not a store",
    O008: "Not enough tickets",

    /* Success */
    O200: "Order payment cancellation successful",
    O201: "Order successfully deleted",

    // Groups
    /* Fail */
    G000: "Group not found",
    G003: "Group is related",

    /* Success */
    G200: "Group successfully created",
    G201: "Group successfully updated",
    G202: "Group successfully deleted",

    // Communities
    /* Fail */
    C000: "Community not found",
    C001: "User not found in community",
    C002: "Chief editor not found",

    /* Success */
    C200: "Community successfully created",
    C201: "Community successfully updated",
    C202: "Community successfully deleted",
    C203: "Community group successfully updated",
    C204: "Community users successfully updated",
    C205: "Community editors successfully added",
    C206: "Community editor successfully updated",
    C207: "Community user successfully deleted",

    // Departments
    /* Fail */
    D000: "Segmentation not found",
    D003: "Segmentation is related",
    D004: "Segmentation cannot be changed",

    /* Success */
    D200: "Segmentation successfully created",
    D201: "Segmentation successfully updated",
    D202: "Segmentation successfully deleted",

    // Users
    /* Fail */
    U000: "User not found",
    U001: "Unable to associate owner",
    U002: "Unable to associate chief editor",
    U100: "The old password you entered is incorrect",

    /* Success */
    U200: "User successfully created",
    U201: "User successfully updated",
    U202: "User successfully deleted",
    U203: "All users were successfully deleted",
    U204: "The invites were sent successfully",
    U205: "New users were successfully uploaded",
    U206: "User is activated",
    U207: "User is deactivated",
    U208: "User were successfully approved",
    U209: "All requests were successfully deleted",
    U210: "User is approved",
    // My User
    U211: "The password was successfully changed",
    U212: "User successfully saved",
    U213: "User notification successfully saved",

    // Message
    /* Fail */
    M100: "No message was found",

    /* Success */
    M200: "Message read successfully",
    M201: "Message deleted successfully",

    // Auth
    /* Fail */
    AU000: "The email address or password you entered is incorrect",
    AU001: "The phone you entered is incorrect",
    AU002: "The code you entered is incorrect",
    AU003: "The email address you entered is not exist",
    AU004: "The token has expired",
    AU005: "Invalid token",
    AU006: "You are not registered to the community",
    AU007: "Your account is inactive",
    AU008: "Your account is awaiting to approval",
    AU009: "You do not have access for the attempted action",
    AU010: "Failed to authenticate token",
    AU011: "No token provided",
    AU013: "You dont have access to edit this content",

    /* Success */
    AU200: "A code has been sent to your phone",
    AU201: "Registration successful",
    AU202: "An email was sent to the email",

    // Permissions
    /* Success */
    PR200: "Permissions successfully updated",
    PR201: "Permissions successfully deleted",

    /* Fail */
    PR000: "Permissions not found",

    // Reports
    /* Fail */
    R000: "No dates were passed",

    // AWS
    /* Fail */
    AW000: "Failed to uploading file",
    AW001: "Invalid file format",

    // Email
    /* Fail */
    EM000: "Trying to send an email without a transporter",

    // Field Validations
    /* Fail */
    // Any
    F000: "Field is required",

    // String
    F001: "Should be a text",
    F002: "Cannot be an empty field",

    // Min/Max Length
    F003: "Minimum length of 6",
    F004: "Maximum length of 15",
    F005: "Minimum length of 2",
    F006: "Maximum length of 12",

    // Boolean
    F007: "Should be a boolean",

    // Number
    F008: "Should be a number",
    F009: "Minimum number is 1",

    // Hex
    F010: "Should be a hex color",

    // Phone
    F011: "Invalid phone number",

    // Email
    F012: "Invalid email",

    // Password
    F013: "Should match password",

    // missing // need different codes for email and phone
    P2002: "Phone or email exist. Please insert a different.",
    P2003:
      "In order to delete this category, the article associated with that category must first be deleted.",
    U214: "Phone already exists. Please insert a different.",
    U215: "Email already exists. Please insert a different.",
    AC001: "Article category already exists",
    BC002: "Benefit category already exists",
    AC002: "Cannot delete category that contains articles",
    BC003: "Cannot delete category that contains benefits",
    G002: "Group already exists",
    D002: "Segmentation already exists",

    // Privacy Settings
    PF100: "Details updated successfully",

    // Messages
    "S-AD000": "created an ad",
    "S-AD001": "updated an ad",
    "S-AD002": "deleted ad",
    "S-AD003": "duplicated ad",
    "S-AD004": "archived ad",
    "S-AD005": "unarchived ad",
    "S-AD006": "locked ad",
    "S-AD007": "unlocked ad",
    "S-AD008": "approved ad",
    "S-AD009": "rejected ad",
    "S-AD010": "pending ad",

    "S-A000": "created an article",
    "S-A001": "updated an article",
    "S-A002": "deleted article",
    "S-A003": "duplicated article",
    "S-A004": "archived article",
    "S-A005": "unarchived article",
    "S-A006": "locked article",
    "S-A007": "unlocked article",
    "S-A008": "likes an article",
    "S-A009": "approved article",
    "S-A010": "rejected article",
    "S-A011": "pending article",

    "S-B000": "created a benefit",
    "S-B001": "updated a benefit",
    "S-B002": "deleted benefit",
    "S-B003": "duplicated benefit",
    "S-B004": "archived benefit",
    "S-B005": "unarchived benefit",
    "S-B006": "locked benefit",
    "S-B007": "unlocked benefit",
    "S-B008": "likes benefit",
    "S-B009": "approved benefit",
    "S-B010": "rejected benefit",
    "S-B011": "pending benefit",

    "S-C000": "created a community",
    "S-C001": "updated a community",
    "S-C002": "deleted a community",

    "S-P000": "created push",
    "S-P001": "updated push",
    "S-P002": "deleted push",
    "S-P003": "approved push",
    "S-P004": "rejected push",
    "S-P005": "pending push",

    "S-U000": "created user",
    "S-U001": "updated user",
    "S-U002": "deleted user",
    "S-U003": "deleted all users",
    "S-U004": "import a new users",
    "S-U005": "activated an user",
    "S-U006": "approved all users",
    "S-U007": "approved an user",
    "S-U008": "deleted all the requests",
  },
};

export type TranslateSchema = typeof en;
