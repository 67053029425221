import { Grid } from '@material-ui/core';
import React, { useState } from 'react'
import './style.scss';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconComponent from '../../../../shared/components/IconComponent/IconComponent';
import Plus from "../../../../assets/icons/plus.svg"
import CreateMenu from './CreateMenu/CreateMenu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [openCreateMenu, setOpenCreateMenu] = useState(false);

    const toggleCreateMenu = () => setOpenCreateMenu(!openCreateMenu);

    return (
        <div className="mobile-footer">
            <Grid container justifyContent="space-evenly" alignItems="center" className="grid-container">
                <Grid item xs={2}>
                    <div className="d-flex justify-center align-center flex-column" onClick={() => history.push('/mobile/my-messages')}>
                        <NotificationsNoneIcon className="action-icon" />
                        <span className="profile-text">{t('mobile.footer.messages')}</span>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="d-flex justify-center align-center">
                        <div className={`plus-icon-wrapper ${openCreateMenu ? 'x-state' : ''}`} onClick={toggleCreateMenu}>
                            <IconComponent icon={Plus} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="d-flex justify-center align-center flex-column">
                    </div>
                </Grid>
            </Grid>
            <CreateMenu open={openCreateMenu} handleClose={toggleCreateMenu} />
        </div>
    )
}

export default Footer
