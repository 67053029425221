import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "./style.scss";
import { useTranslation } from "react-i18next";
import RangeDatePicker from "../../../components/RangeDatePicker/RangeDatePicker";
import { useStore } from "../../../store/StoreContext";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { observer } from "mobx-react-lite";
import { flowResult } from "mobx";
import Spinner from "../../../components/Spinner/Spinner";
import { ClickAwayListener } from "@material-ui/core";

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  maintainAspectRatio: false,
};

const GroupBarChart = () => {
  const { i18n, t } = useTranslation();
  const { languageStore } = useStore();
  const { reportsStore } = useStore();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const toggleDatePicker = () => setOpenDatePicker(!openDatePicker);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  });
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState({
    sent: [],
    opened: [],
    purchased: [],
  });
  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");
  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  useEffect(() => {
    getAllPurchases();
    // const onScroll = () => setOpenDatePicker(false);
    // document.body.querySelector('.App').addEventListener('scroll', onScroll)
    // return () => {
    //   document.body.querySelector('.App').removeEventListener('scroll', onScroll)
    // }
  }, []);

  const getAllPurchases = () => {
    setIsLoading(true);
    const date = mapDateForHttpReq(dateRange);
    flowResult(reportsStore.getPurchasesForChart(date)).then(
      (response: any) => {
        setShowChart(true);
        if (!response?.success) {
          setIsLoading(false);
          console.error(`Error while getting Purchases information`);
          // handleNotificationOpen(`Error while getting Purchases information`);
          return;
        }
        const reports = response.reports;
        if (reports.length === 0) {
          setShowChart(false);
        }

        const sent = reports.map((r) => r.sent);
        const opened = reports.map((r) => r.opened);
        const purchased = reports.map((r) => r.purchased);
        const labels = reports.map((r) => r.categoryName);

        const mappedData = {
          sent: sent,
          opened: opened,
          purchased: purchased,
        };
        setChartLabels(labels);
        setChartData(mappedData);
        setIsLoading(false);
      }
    );
  };

  const convertToDate = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date);

    return `${date.getDate()}.${date.getMonth() + 1}.${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  };

  const refreshChart = () => {
    getAllPurchases();
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: t("reports.sent"),
        data: chartData.sent,
        backgroundColor: "#577BF9",
        barThickness: 10,
        maxBarThickness: 10,
      },
      {
        label: t("reports.opened"),
        data: chartData.opened,
        backgroundColor: "#EFDA1F",
        barThickness: 10,
        maxBarThickness: 10,
      },
      {
        label: t("reports.purchased"),
        data: chartData.purchased,
        backgroundColor: "#36E0F6",
        barThickness: 10,
        maxBarThickness: 10,
      },
    ],
  };

  const handleDateChange = ({ startDate, endDate }, isClose = false) => {
    setDateRange({ startDate: startDate, endDate: endDate });
    if (isClose) {
      setOpenDatePicker(false);
      refreshChart();
    }
  };

  const mapDateForHttpReq = ({ startDate, endDate }) => {
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    const locale = "en-US";

    const sDateQuery = `${sDate.toLocaleDateString(locale, {
      year: "numeric",
    })}-${sDate.toLocaleDateString(locale, {
      month: "numeric",
    })}-${sDate.toLocaleDateString(locale, {
      day: "numeric",
    })}`;

    const eDateQuery = `${eDate.toLocaleDateString(locale, {
      year: "numeric",
    })}-${eDate.toLocaleDateString(locale, {
      month: "numeric",
    })}-${eDate.toLocaleDateString(locale, {
      day: "numeric",
    })}`;
    return { sDateQuery, eDateQuery };
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
        <div className="chart-header">
          <h2 className="title">{t("reports.purchases.title")}</h2>
          <div className="datepicker">
            <div className="dislayed-dates" onClick={() => toggleDatePicker()}>
              <span>
                {t("reports.fromDate")}{" "}
                {dateRange?.startDate ? convertToDate(dateRange.startDate) : ""}
              </span>
              <span>-</span>
              <span>
                {t("reports.untilDate")}{" "}
                {dateRange?.endDate ? convertToDate(dateRange.endDate) : ""}
              </span>
            </div>
            <div className="range-datepicker">
              <RangeDatePicker
                value={dateRange}
                isOpened={openDatePicker}
                onChange={handleDateChange}
                locale={lngDirection === "rtl" ? heLocale : enLocale}
              />
            </div>
          </div>
        </div>
      </ClickAwayListener>

      { showChart ? (
        <Bar height={100} width={1117} data={data} options={options} />
      ) : (
        <p>{t("reports.noPurchases")}</p>
      )}
    </>
  );
};

export default observer(GroupBarChart);
