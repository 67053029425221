import RootStore from "./RootStore";
import { makeAutoObservable } from "mobx";
import { Api } from "../services/Api";

export default class BannerStore {
    root: RootStore;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    * update(payload: any, id = '') {
        const response = yield Api.put(`admin/benefit/${id}`, payload);
        return response?.data;
    }

}
