import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@material-ui/data-grid";
import { noContent } from "../../../db/db";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import Spinner from "../../../components/Spinner/Spinner";
import { flowResult } from "mobx";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import { getElementFromArrayObject, stringToCapital } from "../../../shared/utils/sharedFunctions";
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";

const Surveys = () => {
  const { reportsStore, actionStore, benefitStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  const { i18n, t } = useTranslation();
  const { languageStore } = useStore();
  const [surveysList, setSurveysList] = useState<Array<Object>>([]);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonAction, setButtonAction] = useState(() => { });

  const [categories, setCategories] = useState<any>([]);

  const handleNotificationClose = () => {
    return setOpenNotificationModal(false);
  };

  const convertToDate = (date, type = "") => {
    if (!date) {
      return;
    }
    date = new Date(date);
    const locale = lngDirection === "rtl" ? "he-IL" : "en-US";
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };

    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  // Get all Surveys and fill table
  const getAllSurveys = async () => {
    setIsLoading(true);
    const searchParams = reportsStore.getSearchParamsSurveys();

    const benefitParams = {
      is_draft: false,
      has_surveys: true,
      limit: 200,
    }
    await flowResult(actionStore.getPathItems('benefit', { ...benefitParams, ...searchParams })).then(
      (response: any) => {
        if (!response?.success) {
          setIsLoading(false);
          handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
          return;
        }
        const data = response.benefits;
        setSurveysList(data);
        setIsLoading(false);
      }
    )
  };

  useEffect(() => {
    reportsStore.triggerModalChange();
    setCategories(benefitStore.getStoredBenefits());
    getAllSurveys();
    return () => {
      reportsStore.triggerModalChange();
      reportsStore.setSurveyInformation({});
    }
  }, []);

  // Error messages
  const handleNotificationOpen = (message = "") => {
    setButtonText(t("common.buttons.close"));
    setNotificationMessage(message);
    setButtonAction(() => handleNotificationClose);
    setOpenNotificationModal(true);
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
    // Reversing the order of Table Grids for Hebrew
    if (dir === "rtl") {
      setTimeout(() => {
        document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
          const width = el.scrollWidth;
          el.scrollTo(width, 0)
        })
      })
    }
  }, [languageStore.switchLng, isLoading === false])

  useEffect(() => {
    if (reportsStore.triggerUpdateList) {
      getAllSurveys();
      reportsStore.triggerUpdateList = false;
    }
  }, [reportsStore.triggerUpdateList]);

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      sortable: false,
      width: 90,
      renderHeader: () => {
        return (
          <div style={{ pointerEvents: 'none' }} />
        )
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row?.image ?? ImagePlaceholder;
        return (
          <img className="table-row-image" src={row} alt={row || "Article image"} />
        );
      },
    },
    {
      field: 'id',
      headerName: t('common.fieldHeaders.id'),
      sortable: false,
      width: 50,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const id = params.api?.getRowIndex(params?.id) + 1;
        return (
          <span className="table-row-title">
            {id}
          </span>
        );
      },
    },
    {
      field: 'communityName',
      headerName: t('tabNavigation.community.mainTab'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{params.row?.appears?.[0]?.community?.communityName ?? t('users.create.owner')}</span>
        );
      },
    },
    {
      field: 'type',
      headerName: t('common.fieldHeaders.type'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>
            {params.colDef.headerName}
          </span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const type = params.row.benefitType;
        return (<span>{stringToCapital(t(`common.selectFilters.${type.toLowerCase()}`))}</span>)
      },
    },
    {
      field: 'categoryId',
      headerName: t('common.fieldHeaders.category'),
      sortable: false,
      width: 100,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title">{getElementFromArrayObject('id', 'categoryName', params.row.categoryId, categories)}</span>
        );
      },
    },
    {
      field: 'header',
      headerName: t('common.fieldHeaders.header'),
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <span className="table-row-title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.header}
          </span>
        );
      },
    },
    {
      field: 'publishDate',
      headerName: t('common.fieldHeaders.live'),
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        const row = params.row.publishDate;
        return (
          <div>
            <div className="table-row-date">{convertToDate(row)}</div>
          </div>
        )
      },
    },
    {
      field: 'archiveDate',
      headerName: t('common.fieldHeaders.archived'),
      sortable: true,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <span className={`table-column`}>{params.colDef.headerName}</span>
        )
      },
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            <div className="table-row-date">{convertToDate(params.row.archiveDate)}</div>
          </div>
        )
      },
    },
  ].map((column) => (
    {
      ...column,
      disableClickEventBubbling: true,
    })
  );

  const handleRowClick = (row) => {
    reportsStore.setSurveyInformation(row)
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper surveys-page">
      <div className="page-heading-wrap">
        <h2 className="page-heading">{t("tabNavigation.reports.mainTab")}</h2>
      </div>
      <div className="sales-page content-wrap">
        {surveysList?.length ? (
          <>
            <div className="table-grid">
              <DataGrid
                rows={surveysList}
                autoHeight={true}
                columns={lngDirection === "ltr" ? columns : columns.reverse()}
                rowHeight={90}
                headerHeight={56}
                disableColumnMenu={true}
                hideFooter={true}
                onCellClick={(row: any) => {
                  handleRowClick(row)
                }}
              />
            </div>
          </>
        ) : (
          <div className="no-content">
            <img src={noContent.users} alt="No messages" />
            <p>{t("reports.surveys.noContent.message1")}</p>
            <span className="note">
              {t("reports.purchases.noContent.message2")}
            </span>
          </div>
        )}
      </div>
      <NotificationModal
        openModal={openNotificationModal}
        handleClose={handleNotificationClose}
        handleButtonClick={buttonAction}
        buttonText={buttonText}
        message={notificationMessage}
      />
    </div>
  );
};

export default observer(Surveys);
