import React, { useEffect, useState } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import List from './List';
import CreateCommunity from './Create/CreateCommunity';
import { useStore } from '../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import UserTypeEnum from '../../models/UserType';

const Community = () => {
    const { userStore } = useStore();
    const { path } = useRouteMatch();
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setRoleType(userStore.getRoleType());
    }, []);

    return (
        <div className="main-content">
            <div className="main-content-tabs">
                {
                    roleType === UserTypeEnum.owner && (
                        <>
                            <Route path={`${path}/list`} exact component={List}/>
                            <Route path={`${path}/create`} exact component={CreateCommunity}/>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default observer(Community)
