import AllUsers from "./AllUsers/AllUsers";
import ImportFromFile from "./ImportFromFile/ImportFromFile";
import InviteToApp from "./InviteToApp/InviteToApp";
import WaitingForApproval from "./WaitingForApproval/WaitingForApproval";
import CreateUser from "./Create/Create";

export const routes = [
    {
        path: '/list',
        component: AllUsers
    },
    {
        path: '/import',
        component: ImportFromFile
    },
    {
        path: '/invite',
        component: InviteToApp
    },
    {
        path: '/approval',
        component: WaitingForApproval
    },
    {
        path: '/create',
        component: CreateUser
    }
]
