import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  HEADER: "header",
  SUB_HEADER: "sub_header",
  IMAGE: "image",
  CTA_TEXT: "cta_text",
  APPEARS: "appears",
  PUBLISH_DATE: "publish_date",
  ARCHIVE_DATE: "archive_date",
  ARCHIVE_hour: "archive_hour",
  LINK: "link",
  IS_DRAFT: "is_draft",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.HEADER]: Yup.string()
    .min(2, () => i18n.t("validationMessages.articles.adFields.minHeader"))
    .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
    .required(() => i18n.t("validationMessages.articles.adFields.required")),
  [FIELDS.SUB_HEADER]: Yup.string().when("is_draft", {
    is: true,
    then: (text) => text.notRequired(),
    otherwise: (text) =>
      text
        .required(() => i18n.t("validationMessages.articles.adFields.required"))
        .min(2, () => i18n.t("validationMessages.articles.adFields.minHeader"))
        .max(100, () => i18n.t("validationMessages.articles.addArticle.maxHeader", { max: 100}))
  }),
  [FIELDS.IMAGE]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() => i18n.t("validationMessages.articles.adFields.required"))
        .min(1, () => i18n.t("validationMessages.articles.adFields.image")),
  }),
  [FIELDS.APPEARS]: Yup.array().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .required(() => i18n.t("validationMessages.articles.adFields.required"))
        .min(1, () =>
          i18n.t("validationMessages.articles.adFields.required")
        ),
  }),
  [FIELDS.CTA_TEXT]: Yup.string().when("is_draft", {
    is: true,
    then: (text) => text.notRequired(),
    otherwise: (text) =>
      text
        .required(() => i18n.t("validationMessages.articles.adFields.required"))
        .min(1, () => i18n.t("validationMessages.articles.adFields.oneLetter"))
        .max(50, () =>
          i18n.t("validationMessages.articles.adFields.maxHeader50")
        ),
  }),
  [FIELDS.PUBLISH_DATE]: Yup.string(),
  [FIELDS.ARCHIVE_DATE]: Yup.string(),
  [FIELDS.ARCHIVE_hour]: Yup.string(),
  [FIELDS.LINK]: Yup.string().when("is_draft", {
    is: true,
    then: (element) => element.notRequired(),
    otherwise: (element) =>
      element
        .url(i18n.t("validationMessages.articles.adFields.url"))
        .required(() =>
          i18n.t("validationMessages.articles.adFields.required")
        ),
  }),
});
