import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Account from "../pages/Account/Account";
import DashBoard from "../pages/DashBoard/DashBoard";
import SkyLight from "react-skylight";
import TopNavBar from "../components/TopNavBar/TopNavBar";
import LeftSideBar from "../components/LeftSideBar/LeftSideBar";
import Articles from "../pages/Articles/Articles";
import Benefits from "../pages/Benefits/Benefits";
import Users from "../pages/Users/Users";
import Community from "../pages/Community/Community";
import Reports from "../pages/Reports/Reports";
import Push from "../pages/Push/Push";
import Settings from "../pages/Settings/Settings";
import Messages from "../pages/Messages/Messages";
import { flowResult } from 'mobx';
import { useStore } from '../store/StoreContext';
import Spinner from '../components/Spinner/Spinner';
import UserTypeEnum from '../models/UserType';
import { observer } from 'mobx-react-lite';
import SearchList from '../pages/DashBoard/SearchList/SearchList';
import NotificationModal from '../components/NotificationModal/NotificationModal';
import { useTranslation } from 'react-i18next';
import ViewArticle from '../pages/Articles/View/Article/ViewArticle';
import ViewBanner from '../pages/Articles/View/Banner/ViewBanner';
import ViewAd from "../pages/Articles/View/Ad/ViewAd";
import ViewSale from '../pages/Benefits/View/ViewSale';
import ViewNotification from '../pages/Push/View/ViewNotification';
import ViewCommunity from '../pages/Community/View/ViewCommunity';
import EditCommunity from '../pages/Community/Edit/EditCommunity';
import TabsComponent from '../shared/components/Tabs/TabsComponent';
import AllArticles from "../pages/Articles/AllArticles/AllArticles";
import RotatingBanner from "../pages/Articles/RotatingBanner/RotatingBanner";
import Ads from "../pages/Articles/Ads/Ads";
import HomePage from '../pages/Mobile/HomePage/HomePage';
import ExportModals from '../components/ExportModals/ExportModals';
import CreateUser from "../pages/Users/Create/Create";

const overlayStyles = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
};

let modalRef: any;
function openModalHandler() {
    modalRef.show();
}

const contentStyles = {
    backgroundColor: '#fff',
    color: '#577BF9',
    width: '400px',
    height: '-10px',
    minHeight: '150px',
    marginTop: '-200px',
    marginLeft: '-15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    borderRadius: '15px'
};

const HomeNavigator = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [renderView, setRenderView] = useState(true);
    const {
        userStore,
        contentStore,
        benefitStore,
        groupStore,
        departmentStore,
        archiveStore,
        settingsStore
    } = useStore();
    const [isLoading, setIsLoading] = useState(true);
    const [isLogout, setIsLogout] = useState(false);
    const [isMobile, setIsMobile] = useState(false);; // window.innerWidth < 768
    
    const dynamicPaths = [
        {
            component: ViewArticle,
            url: `/articles/:type/article/:id`,
            hasTabs: true,
        },
        {
            component: ViewBanner,
            url: `/articles/:type/banner/:id`,
            hasTabs: true,
        },
        {
            component: ViewAd,
            url: `/articles/:type/ad/:id`,
            hasTabs: true,
        },
        {
            component: ViewSale,
            url: `/benefits/:type/sale/:id`,
        },
        {
            component: ViewNotification,
            url: `/push/:type/:id`,
        },
        {
            component: CreateUser,
            url: `/register/:community_id?/:community_name?`,
            hasTabs: false,
        },
        {
            component: ViewCommunity,
            url: `/community/view/:id?`,
            noWrap: true,
        },
        {
            component: EditCommunity,
            url: `/community/edit/:id`,
            noWrap: true,
        }
    ]

    const tabs = [
        {
            title: t('tabNavigation.articles.allArticles'),
            component: AllArticles,
            url: `/articles/list`,
            type: 'article',
            hideComponent: true,
        },
        {
            title: t('tabNavigation.articles.rotatingBanner'),
            component: RotatingBanner,
            url: `/articles/rotating-banner`,
            type: 'banner',
            hideComponent: true,
        },
        {
            title: t('tabNavigation.articles.ads'),
            component: Ads,
            url: `/articles/ads`,
            type: 'ad',
            hideComponent: true,
        },
    ]

    const getContents = async () => {
        await flowResult(contentStore.getContents()).then(
            (response: any) => {
                if (!response?.success) {
                    console.error(`Error while getting Content Categories information `);
                    return;
                }
            }
        )
    }

    const getBenefits = async () => {
        await flowResult(benefitStore.getBenefits()).then(
            (response: any) => {
                if (!response?.success) {
                    console.error(`Error while getting Benefit Categories information `);
                    return;
                }
            }
        )
    }

    const getGroups = async (roleType) => {
        if (roleType === UserTypeEnum.associatedOwner || roleType === UserTypeEnum.owner || roleType === UserTypeEnum.admin) {
            await flowResult(groupStore.getGroups()).then(
                (response: any) => {
                    if (!response?.success) {
                        console.error(`Error while getting Groups information `);
                        return;
                    }
                }
            )
        } else {
            groupStore.clearStore();
        }
    }

    const getDepartments = async (roleType) => {
        if (roleType === UserTypeEnum.associatedOwner || roleType === UserTypeEnum.chiefEditor || roleType === UserTypeEnum.editor || roleType === UserTypeEnum.admin) {
            await flowResult(departmentStore.getDepartments()).then(
                (response: any) => {
                    if (!response?.success) {
                        console.error(`Error while getting Departments information `);
                        return;
                    }
                }
            )
        } else {
            departmentStore.clearStore();
        }
    }

    const getArchiveSettings = async (roleType) => {
        if (roleType !== UserTypeEnum.owner) {
            await flowResult(archiveStore.getArchive()).then(
                (response: any) => {
                    if (!response?.success) {
                        console.error(`Error while getting Archive information `);
                        return;
                    }
                }
            )
        }
    }

    const updateStoredAccountInfo = async () => {
        await flowResult(userStore.getMyAccountInfo()).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLogout(true)
                    handleNotificationOpen(t("errorMessages.settings.noAccess"));
                    return;
                }
                userStore.updateStoredAccountInfo(response);
            }
        )
    }

    const getUserPermissions = async (roleType) => {
        if (roleType === UserTypeEnum.chiefEditor || roleType === UserTypeEnum.editor) {
            await flowResult(settingsStore.getPathItems('permissions')).then(
                (response: any) => {
                    if (!response?.success) {
                        console.error(`Error while getting Permissions`);
                        return;
                    }
                    const permissions = response?.permissions?.[0];
                    if (permissions) {
                        settingsStore.setUserPermissions(permissions);
                    }
                }
            )
        } else {
            settingsStore.setUserPermissions({
                ads: true,
                banners: true,
                benefits: true,
                articles: true,
                users: true,
                push: true,
                reports: true
            });
        }
    }

    const getUserInfo = async () => {
        userStore.setRoleType();
        const roleType = userStore.getRoleType();
        await updateStoredAccountInfo();
        await Promise.all([
            getContents(),
            getBenefits(),
            getGroups(roleType),
            getDepartments(roleType),
            getArchiveSettings(roleType),
            getUserPermissions(roleType)
        ])
        const communities = userStore.getCommunities();
        const groups = groupStore.getStoredGroups();
        if (roleType === UserTypeEnum.owner) {
            if (!groups.length && !communities.length) {
                handleNotificationOpen(t('errorMessages.login.noGroupAndCommunity'))
            } else if (!communities.length) {
                handleNotificationOpen(t('errorMessages.login.noCommunity'))
            }
        }
        setTimeout(() => {
            setIsLoading(false);
        });
    }

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const handleNotificationOpen = (message = '') => {
        setNotificationMessage(message);
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = () => {
        setOpenNotificationModal(false);
        const roleType = userStore.getRoleType();
        if (isLogout) {
            userStore.logout();
            history.push("/sign-in");
            return;
        }
        if (roleType === UserTypeEnum.owner) {
            const communities = userStore.getCommunities();
            const groups = groupStore.getStoredGroups();
            if (!groups.length && !communities.length) {
                history.push("/settings/manageGroups")
            } else if (!communities.length) {
                history.push("/community/create")
            }
        }
    }

    useEffect(() => {
        history.listen((location) => {
            const element = document.querySelector('.main-content-container');
            if (element) {
                element.scrollTo(0, 0);
            }
            setIsMobile(history.location.pathname.indexOf('/mobile') > -1)
        })
    }, [history])

    useEffect(() => {
        getUserInfo();
    }, [userStore.isAuthenticated, userStore.triggerHomeNavigatorUpdate]);

    useEffect(() => {
        if (!userStore.isAuthenticated) {
            history.push("/sign-in");
        }
    }, [userStore.isAuthenticated]);

    useEffect(() => {
        // Remove scrolling on input fields
        document.addEventListener("wheel", function () {
            //@ts-ignore
            if (document.activeElement.type === "number") {
                //@ts-ignore
                document.activeElement.blur();
            }
        })
        setIsMobile(history.location.pathname.indexOf('/mobile') > -1)
        const hasMobileToken = localStorage.getItem('mobile_token');
        if (hasMobileToken) history.push('/mobile');
    }, [])

    return isLoading ? (<Spinner />) : (
        <>
            {
                !isMobile ? (
                    <>
                        <SkyLight dialogStyles={contentStyles} overlayStyles={overlayStyles} hideOnOverlayClicked ref={(ref: any) => modalRef = ref} >
                            <div className="modal-wrapper">
                                <div className="modal-title">{t('common.comingSoon')}</div>
                                <div className="modal-content">{t('common.underConstruction')}</div>
                            </div>
                        </SkyLight>
                        <TopNavBar openModal={openModalHandler} />
                        <LeftSideBar openModal={openModalHandler} />
                    </>
                ) : null
            }
            <div className="main-content-container" style={{ height: window.innerHeight }}>
                {renderView &&
                    <Switch>
                        <Route path={'/'} exact component={DashBoard} />
                        <Route path={'/dashboard/searchList'} exact component={SearchList} />
                        <Route path={'/account'} component={Account} />
                        {
                            dynamicPaths.map((route, index) => {
                                return (
                                    <Route key={index} path={`${route.url}`}>
                                        {
                                            !route?.noWrap ? (
                                                <div className="main-content main-content-tabs my-messages-page">
                                                    {
                                                        route?.hasTabs && (<TabsComponent data={tabs} />)
                                                    }
                                                    {
                                                        React.createElement(route.component)
                                                    }
                                                </div>
                                            ) : (React.createElement(route.component))
                                        }
                                    </Route>
                                )
                            })
                        }
                        <Route path={'/articles'} component={Articles} />
                        <Route path={'/benefits'} component={Benefits} />
                        <Route path={'/users'} component={Users} />
                        <Route path={'/community'} component={Community} />
                        <Route path={'/reports'} component={Reports} />
                        <Route path={'/push'} component={Push} />
                        <Route path={'/settings'} component={Settings} />
                        <Route path={'/messages'} component={Messages} />
                        <Route path={'/mobile'} component={HomePage} />
                        <Route path={'*'} component={() => {
                            return userStore.isAuthenticated ? <Redirect to={'/'} /> : <Redirect to={'/sign-in'} />
                        }} />
                    </Switch>
                }
            </div>
            <ExportModals />
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose}
                handleButtonClick={handleNotificationClose}
                buttonText={t('common.buttons.confirm')}
                message={notificationMessage}
                hideCloseIcon
            />

        </>
    )
};

export default observer(HomeNavigator)
