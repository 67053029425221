import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class ContentStore {
    root: RootStore;

    path = "/admin/settings/categories/content";
    contents: any = [];
    platformContents: any = [];
    communityContents: any = [];

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    getStoredContents() {
        return this.contents;
    }

    getStoredPlatformContents() {
        return this.platformContents;
    }

    getStoredCommunityContents() {
        return this.communityContents;
    }

    updateContents() {
        this.contents = this.platformContents.concat(this.communityContents);
    }

    updatePlatformContents(newContents) {
        this.platformContents = newContents;
        this.updateContents();
    }

    updateCommunityContents(newContents) {
        this.communityContents = newContents;
        this.updateContents();
    }

    * getContents() {
        const response = yield Api.get(this.path);
        if (response.ok && response.data?.success && (response.data?.contentCategories?.platform?.length || response.data?.contentCategories?.community?.length)) {
            this.platformContents = response.data.contentCategories.platform;
            this.communityContents = response.data.contentCategories.community;
            this.updateContents();
        } else {
            this.contents = [];
            this.platformContents = [];
            this.communityContents = [];
        }
        return response?.data
    }

    * create(payload: any, role) {
        const response = yield Api.post(this.path, payload);
        if (response.ok && response.data?.success && response.data?.contentCategory) {
            if (role === 'owner') {
                this.platformContents.push(response.data.contentCategory)
            } else {
                this.communityContents.push(response.data.contentCategory)
            }
            this.updateContents();
        }
        return response?.data
    }

    * update(payload: any, id, role) {
        const response = yield Api.put(`${this.path}/${id}`, payload);
        if (response.ok && response.data?.success) {
            if (role === 'owner') {
                this.platformContents = this.platformContents.map((item) => {
                    if (id === item?.id) {
                        return {...item, ...payload }
                    }
                    return item
                })
            } else {
                this.communityContents = this.communityContents.map((item) => {
                    if (id === item?.id) {
                        return {...item, ...payload }
                    }
                    return item
                })
            }
            this.updateContents();
        }
        return response?.data
    }

    * delete(id, role) {
        const response = yield Api.delete(`${this.path}/${id}`);
        if (response.ok && response.data?.success) {
            if (role === 'owner') {
                this.platformContents = this.platformContents.filter((item) => {
                    return id !== item?.id
                })
            } else {
                this.communityContents = this.communityContents.filter((item) => {
                    return id !== item?.id
                })
            }
            this.updateContents();
        }
        return response?.data
    }
}
