import React, { useEffect } from 'react'
import { AppBar, Tooltip } from "@material-ui/core";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab';
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from '../../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
export interface TabsProps {
    data: any;
    toggleSearch?: any;
    className?: string;
}

const TabsComponent = (props: TabsProps) => {
    const { t } = useTranslation();
    const { actionStore } = useStore();
    const params: any = useParams();
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();

    useEffect(() => {
        let activeTabIndex = 0;
        if (params?.id) {
            activeTabIndex = props.data.findIndex((tab) => history.location.pathname.indexOf(`/${tab.type}/`) !== -1);
        } else {
            activeTabIndex = props.data.findIndex((tab) => tab.url === history.location.pathname);
        }
        const value = activeTabIndex !== -1 ? activeTabIndex : 0;
        actionStore.setTabIndex(value);
        setValue(value);
        setTimeout(() => {
            setIsLoading(false)
        });
    }, [props.data])
    const handleChange = (tab, index) => {
        if (tab?.disabled) return;
        actionStore.toggleSearch(false);
        history.push(tab.url);
        setValue(index);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                className="tab-panel"
                {...other}
            >
                {value === index && (
                    <div className="tab">{children}</div>
                )}
            </div>
        );
    }

    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return !isLoading && (
        <div className={`${props?.className ?? ''}`}>
            <AppBar position="relative" color="default" className="app-bar">
                <Tabs
                    className="tabs-wrapper"
                    value={value}
                    indicatorColor="primary"
                    textColor="secondary"
                >
                    {
                        props.data?.map((tab, index) => {
                            return tab?.disabled ? (
                                <Tooltip key={index} title={t('tabNavigation.temporaryDisabled')} placement="bottom" arrow>
                                    <Tab
                                        style={tab.hide ? { display: "none" } : {}}
                                        key={index}
                                        label={tab.title}
                                        {...a11yProps(index)}
                                        onClick={() => handleChange(tab, index)} />
                                </Tooltip>
                            ) : (
                                <Tab
                                    style={tab.hide ? { display: "none" } : {}}
                                    key={index}
                                    label={tab.title}
                                    {...a11yProps(index)}
                                    onClick={() => handleChange(tab, index)} />
                            )
                        })
                    }
                </Tabs>
            </AppBar>
            {
                props.data?.map(({ component: TabComponent, hideComponent }, index) => {
                    if (hideComponent || actionStore.getTabIndex() !== value) return null
                    return (
                        <TabPanel key={index} value={value} index={index}>
                            <TabComponent />
                        </TabPanel>
                    )
                })
            }
        </div>
    );

}


export default observer(TabsComponent)
