import { Button, Modal } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { useTranslation } from 'react-i18next';

const TermsAndPrivacyPolicy = ({ openModal, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal
            open={openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modal-terms"
        >
            <div className="terms-container">
                <div className="terms-close terms-padding">
                    <CloseIcon className="icon-close" onClick={onClose} />
                </div>
                <h2>{t('termsAndPrivacy.title')}</h2>
                <div className="text-container">
                    <p>יישומון קהילות אשר פותח על ידי חברת L.S. Legendary Solutions Ltd. (להלן: "קהילות" ו-"החברה", בהתאמה) הינה פלטפורמת מובייל אפליקטיבית המאפשרת להקים ישומון לניהול קהילת משתמשים בקלות וביעילות. הפלטפורמה מאפשרת יצירה וניהול של קהילה מוגדרת, יצירת והנגשה של תוכן יעודי פרטני עבור כל משתמש וניהול תהליכים בחיי הקהילה באמצעות הנגשת מודולים מתוחכמים כולל ממשק הודעות יעודי. המטרה של יישומון קהילות הינה לספק למשתמש חוויית תוכן ייחודית ומותאמת לצרכיו בהתאם לקהילה אליה הוא משתייך.</p>
                    <p>מדיניות הפרטיות שלהלן, סוקרת את האופן בו "קהילות" אוספת ומשתמשת במידע הנוגע למשתמשים במסגרת הפעילות.</p>
                    <p>מדיניות פרטיות זו מהווה תנאי מחייב לשימוש ביישומון, והשימוש בהם מעיד על הסכמתך מרצונך החופשי לתנאיה, ויש לקרוא אותה בקפידה.</p>
                    <p><u>השימוש ביישומון מהווה הסכמה למדיניות הפרטיות, ולאיסוף המידע והשימוש בו, בהתאם למפורט להלן במדיניות פרטיות זו.</u></p>
                    <p><u>החברה שומרת על זכותה לשנות מעת לעת את מדיניות הפרטיות, על פי שיקול דעתה הבלעדי, וכל שינוי יחייב את המשתמשים ממועד פרסומו ביישומון, או קבלת הודעה על כך בדרך אחרת כלשהי, לפי המוקדם מביניהם. כל שימוש ביישומון לאחר מתן הודעה כאמור ייחשב לאישור והסכמה לשינוי מדיניות הפרטיות.</u></p>
                    <p><u>כל האמור במדיניות פרטיות זו הינו בכפוף לחוק הגנת הפרטיות, התשמ"א-1981 (להלן - "חוק הגנת הפרטיות") ולהוראות כל דין שלא ניתן להתנות עליהן.</u></p>
                    <p><u>מדיניות הפרטיות מהווה הודעה לפי סעיף 11 לחוק הגנת הפרטיות. מובהר כי מסירת המידע על ידי המשתמשים תלויה ברצונם ובהסכמתם של המשתמשים וכי לא קיימת חובה חוקית למסור את המידע.</u></p>
                    <p><u>מדיניות הפרטיות מנוסחת בלשון זכר מטעמי נוחות בלבד ומיועדת לשני המינים גם יחד.</u></p>
                    <p><u>השימוש ביישומון הינו על אחריותו המוחלטת והבלעדית של בית העסק המשתמש בו.</u></p>
                    <p><u>המידע הנאסף</u></p>
                    <p>השימוש ביישומון טעון הרשמה. במסגרת ההרשמה נדרשת מסירת מידע אישי, כדוגמת שם, כתובת, דרכי ההתקשרות, או כתובת דואר אלקטרוני. השדות שחובה למלא, יסומנו במפורש. בלא למסור את הנתונים המתבקשים בשדות החובה לא ניתן להירשם לשירותים הטעונים רישום. הואיל ו"קהילות" תבקש רק את המידע הנחוץ לשירות שאליו נרשמת, באחדים מהשירותים תתבקש מסירת נתונים נוספים, המתחייבים מאופי השירות, כגון אמצעי תשלום ופרטי משלוח, בין היתר.</p>
                    <p>כמו כן, בעת השימוש באתר וביישומון יכול שיצטבר מידע על התנהלותך, מוצרים, הטבות ושירותים שרכשת או ביקשת לרכוש, מידע או פרסומות שקראת, העמודים שבהם צפית, ההצעות והשירותים שעניינו אותך, אמצעי התשלום ששימשו אותך, מיקום המכשיר שבאמצעותו השתמשת בשירות ועוד. עוד במסגרת השימוש באתר וביישומון יכול להיאסף מידע של קבצי cookies, כפי שיפורט בהמשך, וכן מידע פומבי מצדדים שלישיים ככל שהדבר נדרש לצורך מתן השירותים ולפי הכללים של מסירת מידע על ידי אותם הצדדים.</p>
                    <p>חלק מן המידע האמור נאסף באופן סטטיסטי באופיו, אשר אינו מזהה משתמשים פרטניים או נשמר יחד עם פרטיהם. ויובהר, כי כל מידע אשר מקושר עם פרטים אשר יש בהם כדי לזהות משתמשים פרטניים, יוחזק ויישמר כמידע אישי לכל עניין.</p>
                    <p><u>מאגר המידע</u></p>
                    <p>הנתונים הנמסרים בעת ההרשמה לאתר, ליישומון או לשירותים השונים, וכל מידע אישי אחר הנשמר בעניין המשתמשים, יישמרו במאגר המידע של "קהילות" מובהר כי אין חובה על-פי חוק למסור את המידע, אולם, בלא מסירתו, לא ניתן להשתמש בשירותים של האתר והיישומון.</p>
                    <p><u>השימוש במידע</u></p>
                    <p>"קהילות" משתמשת במידע המתקבל והנאסף במסגרת פעילות היישומון רק על פי דין ובהתאם למדיניות הפרטיות שלעיל ולהלן, והכל למטרות המפורטות להלן:</p>
                    <p>- כדי לטייב את השירותים והתכנים המוצעים באתר וביישומון, ולשם התאמתם להעדפות המשתמשים וצרכיהם. המידע שישמש לצורך כך הינו סטטיסטי בעיקרו ואינו מזהה את המשתמשים אישית.</p>
                    <p>- כדי להנגיש את השירותים השונים באתר וביישומון, ובכלל זה ליצירת אזורים אישיים למשתמשים ומשלוח הודעות אישיות המותאמות להעדפותיהם.</p>
                    <p>- לצורך מענה לפניות המשתמשים ושירות לקוחות.</p>
                    <p>- לצורך רכישת מוצרים, שירותים והטבות המוצעות למשתמשים ביישומון.</p>
                    <p>- ליצירת קשר עמך במידת הצורך.</p>
                    <p>- לצורך איסוף וניתוח של מידע סטטיסטי שאינו מזהה אישית, או מקושר למידע מזהה אישית, של המשתמשים באתר וביישומון. מידע סטטיסטי זה עשוי להימסר לצדדים שלישיים, לרבות מפרסמים.</p>
                    <p>- לצורך התפעול התקין של היישומון. המידע המשמש לצורך זה לא יימסר לצדדים שלישיים, אלא במידה שהדבר הותר במסגרת מדיניות פרטיות זו כפי שתעודכן מעת לעת.</p>
                    <p>- לכל מטרה אחרת, המפורטת במדיניות פרטיות זו או בתנאי השימוש של איזה מן השירותים הניתנים באתר וביישומון.</p>
                    <p>החברה אוספת במהלך השימוש גם מידע לגבי מיקומם הגיאוגרפי של המשתמשים באמצעות מכשירם הסלולרי, הכולל איסוף, ניטור ואחסון של קואורדינטות GPS של מיקומם או מידע דומה בנוגע למיקום המכשיר הסלולרי.</p>
                    <p>ייתכן גם כי החברה תאסוף מידע על סוג המכשיר בו נעשה שימוש, שמו ואופן השימוש בו, הדפדפן (למשל FireFox, Google Chrome, Internet Explorer וכיוצ"ב), הגדרות הדפדפן וכן אפליקציות ו-ווידג'טים שהותקנו על המכשיר, לרבות שמן, מועד התקנתן, מועד עדכון וגרסה. כמו כן, ייתכן כי ייאסף מידע על פעולות באפליקציות האמורות, כגון פתיחה, סגירה או הסרה ומשך השימוש. יובהר כי אין איסוף של המידע והנתונים מתוך האפליקציות עצמן, למעט נתוני שימוש באפליקציות עצמן.</p>
                    <p>החברה תשתמש במידע האישי ובמידע הסטטיסטי שייאסף על ידה אך ורק לצורך תפעול האתר והיישומון, בכפוף למדיניות פרטיות זו והוראות כל דין שלא ניתן להתנות עליהן. לעניין זה, "פעילות האתר והיישומון" - לרבות האפשרות לגלוש באתר וביישומון; שיפור השירות באתר וביישומון; שינוי ו/או ביטול פונקציות ותכונות קיימים באתר וביישומון; התאמת מודעות ו/או הצעות שיוצגו למשתמשים בעת הביקור באתר או ביישומון בהתאם לתחומי התעניינות של המשתמשים, וכיוצ"ב.</p>
                    <p>החברה עשויה להשתמש במידע האישי לצורך שמירה ואחסון עבור פניות עתידיות.</p>
                    <p>מעת לעת החברה עשויה להשתמש במידע כדי ליצור קשר עם המשתמש כדי להציע לו שירותים, למטרות שירות לקוחות או מחקרי שוק. החברה עשויה לפנות אל המשתמש באמצעות פקס, דואר ישראל, טלפון, הודעת SMS, דואר אלקטרוני ועוד. </p>
                    <p>החברה עשויה להשתמש במידע שאספה כדי להציע למשתמש פרסום ממוקד, מוצרים ושירותים מותאמים-אישית, ואולי גם להתאים אישית את האתר או היישומון ואת מודעות הפרסום באתר או באפליקציה על פי הרגלי הביקור של המשתמש באתר, הצרכים והרצונות שלו. </p>
                    <p>השימוש במידע סטטיסטי נועד אך ורק לצרכי מחקר וניתוח, וכן שיפור חווית המשתמש במהלך הגלישה באתר או באפליקציה ושימוש בשירותים המוצעים באתר או באפליקציה. </p>
                    <p>בעת השימוש בשירותים מסוימים המוצעים באתר או ביישומון, ייתכן שיתבקש המשתמש לתת את הסכמתו לקבלת דואר אלקטרוני, לרבות "דבר פרסומת", כהגדרתו בחוק התקשורת (בזק ושידורים), התשמ"ב-1982. המשתמש רשאי, בכל עת, להודיע על סירובו לקבל דבר פרסומת מהאתר או מהאפליקציה, דרך כלל או מסוג מסוים, ולחזור בו מהסכמתו, וזאת על-ידי לחיצה על קישור "הסר" בגוף ההודעה שקיבלת, או על ידי שליחת הודעת הסרה לכתובת <a href="mailto: info@communities-app.com">info@communities-app.com</a> לאחר קבלת הבקשה, המידע האישי הדרוש לצורך פעילות האתר והיישומון יוסיף להישמר במאגר המידע של החברה, אך לא ישמש עוד לצורך פניות אל המשתמש.</p>
                    <p>המשתמש רשאי לדרוש כי המידע האישי אודותיו יימחק ממאגרי היישומון, זאת באמצעות פנייה בדואר אלקטרוני לכתובת. <a href="mailto: info@communities-app.com">info@communities-app.com</a> .  למען הסר ספק, אין באמור לעיל בכדי למנוע מהחברה לשמור את המידע האישי שביקש למחוק מקום בו החברה נדרשת לעשות כן על-¬פי כל דין.</p>
                    <p><u>מסירת מידע לצדדים שלישיים</u></p>
                    <p>"קהילות" לא תעביר לצדדיים שלישיים את פרטיך האישיים והמידע שנאסף על פעילותך באתר וביישומון, ככל שיש בפרטים ומידע כאמור לזהות אותך אישית, אלא במקרים שיפורטו להלן:</p>
                    <p>- בעת רכישה באתר או ביישומון מוצרים ושירותים מאת צדדים שלישיים, או מימוש הטבות מטעם צדדים שלישיים כאמור. במקרים אלה יועבר לצדדים האמורים המידע הדרוש להם לשם אספקת השירותים/המוצרים ושמירת הקשר איתך בלבד. כמו כן, בבחירה להשתתף בפעילות אחרת המשותפת ל-"קהילות" ולצד שלישי, אז ניתן שהצד השלישי ייחשף למידע הנוגע לגלישתך והשתתפותך בפעילות המשותפת כאמור.</p>
                    <p>- לפי אישורך הפרטני בעת שימושך באפשרויות "שיתוף" מהיישומון לרשתות חברתיות כגון פייסבוק או טוויטר.</p>
                    <p>- אם "קהילות" תהא מחויבת על-פי דין למסור את פרטיך או המידע אודותיך לצד שלישי, או אם יתקבל אצלה צו שיפוטי המורה לה למסור את פרטיך או המידע אודותיך כאמור.</p>
                    <p>- במקרה שתפר את תנאי השימוש באתר או ביישומון, או את תנאי השימוש באיזה מהשירותים המוצעים בהם, או אם תבצע באמצעות האתר או היישומון, או בקשר איתם, פעולות הנחזות כמנוגדות לדין, או ניסיון לבצע פעולות כאלה במקרים אלה.</p>
                    <p>- בכל מחלוקת, תביעה או הליך משפטי, ככל שיהיו, בינך לבין "קהילות".</p>
                    <p>- בכל מקרה ש-"קהילות" תסבור, כי מסירת המידע נחוצה כדי למנוע נזק חמור לגופך או לרכושך או לגופו או לרכושו של צד שלישי.</p>
                    <p>- במקרה שבו החברה תעביר בכל צורה שהיא את פעילות האתר ו/או היישומון לתאגיד אחר, תתמזג עם תאגיד אחר או תמזג את פעילות האתר והיישומון עם פעילותו של צד שלישי, ובלבד שאותו תאגיד או צד שלישי יקבל על עצמו את הוראות מדיניות פרטיות זו.</p>
                    <p><u>Cookies ומערכות ניטור ובקרה</u></p>
                    <p>האתר והשירותים השונים עושים שימוש ב"עוגיות" ("Cookies"), "משואות רשת" ("Web Beacons"), "תגיות פיקסלים", קבצי יומן רישום, (להלן יחדיו: Cookies) ומערכות נוספות המוכרות בתעשייה לאיסוף מידע אוטומטי בשימוש באתר ובשירותים, להתאמת השימוש ביישומון (ובכלל זה תכנים ופרסומות) להעדפותיך האישיות ולצרכי אבטחת מידע.</p>
                    <p>Cookies הן קבצי טקסט קטנים המכילים מחרוזות של מידע, הנשמרות באופן מקומי בזיכרון המחשב (או המכשיר), והמשמשות למטרות שונות כגון: איסוף מידע סטטיסטי, אימות פרטים, שמירת העדפות אישיות ועוד. חלק מה-Cookies יפוגו כשתסיים לגלוש ותסגור את תוכנת הדפדפן שלך, ואחרות ישמרו בזיכרון המכשיר שלך.</p>
                    <p>חלק מן ה-Cookies נדרשות לצורך תפעולם של האתר והשירותים, ואילו יתרתן משמשות לצורכי בעקרה והערכה, או לצורך התאמת התכנים השיווקיים והפרסומיים שיוצגו לך.</p>
                    <p>מחיקת Cookies והפסקת השימוש בהן:</p>
                    <p>ניתן למחוק ו/או להפסיק את השימוש ב-Cookies באמצעות שינוי ההגדרות בדפדפן האינטרנט או מערכת ההפעלה. יש לבדוק בתפריט "עזרה" של הדפדפן או מערכת ההפעלה על מנת לדעת כיצד לשנות את הגדרות ה-Cookies. יתכן שהפסקת או צמצום השימוש ב-Cookies תיפגע באפשרותך להשתמש בחלק מהשירותים באתר וביישומון, וכן באיכות הגלישה ובפעולות נוספות בשימוש בהם. בחלק מהמקרים, מחיקת ה-Cookies תוביל לדרישה להזנה מחדש של פרטים כגון שם המשתמש והסיסמה, בטרם כניסה לשירותים השונים</p>
                    <p><u>שירותים של צדדים שלישיים</u></p>
                    <p>שירותים מסוימים באתר וביישומון, כגון תפעול אתר ההטבות ומערך שירות הלקוחות  ושירותים הקשורים ל"פייסבוק", מנוהלים בפועל על ידי גופים אחרים שאינם בשליטת "קהילות". שירותים אלו מופעלים גם באמצעות הגופים האחרים כאמור, ובאמצעות מחשביהם ושרתיהם, ובהתאם לכללים ולמדיניות של כל גוף כאמור.</p>
                    <p><u>פרסומות של צדדים שלישיים</u></p>
                    <p>פרסומות מסוימות המוצגות ביישומון מופעלות בפועל ממחשבי החברות המפרסמות ו/או על ידי מי מטעמן. נבקש ליידע כי לצורך הפעלת הפרסומות, החברות האמורות עושות שימוש ב-Cookies הנשמרות במכשירך ומשבצות "משואות רשת" ("web beacons") במודעות הפרסומת, בדפי האתר או ביישומון. מידע זה אינו מידע אישי המאפשר זיהוי שלך, אלא נועד לשם התאמה אישית של הפרסומות המוצגות עבורך לנושאים שיעניינו אותך. שימוש חברות אלו בCookies- ובמשואות רשת כפוף למדיניות הפרטיות שלהן ולא למדיניות זו של "קהילות".</p>
                    <p>ר' לעיל האמור לעיל בנוגע למחיקת Cookies, והפסקת או צמצום השימוש בהן, בפרק העוסק ב-Cookies המשומשות על ידי שירותי "קהילות".</p>
                    <p><u>אבטחת מידע</u></p>
                    <p>"קהילות" מיישמת באתר וביישומון מערכות ונהלים לאבטחת מידע. בעת שמערכות ונהלים אלו מצמצמים את הסיכונים לחדירה בלתי-מורשית למחשבי ולשרתי "קהילות", אין בהם בטחון מוחלט. "קהילות" אינה יכולה להתחייב שהשירותים באתר וביישומון יהיו חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם.</p>
                    <p><u>זכות לעיין במידע</u></p>
                    <p>על פי חוק הגנת הפרטיות, כל אדם זכאי לעיין במידע אודותיו המוחזק במאגר מידע. אדם שעיין במידע אודותיו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו.</p>
                    <p>פנייה כזאת יש להפנות ל – info@communities-app.com</p>
                    <p>בנוסף, אם המידע שבמאגרי המידע משמש לצורך פנייה אישית אליך, אתה זכאי על פי חוק הגנת הפרטיות לדרוש במכתב שהמידע המתייחס אליך יימחק ממאגר המידע.</p>
                    <p><u>שינויים במדיניות הפרטיות</u></p>
                    <p>מדיניות הפרטיות זו יכולה להשתנות מעת לעת, כאשר השינויים יפורסמו באתר. כל שימוש באתר וביישומון מעיד על הסכמה למדיניות כפי שהיא מעודכנת באותו מועד, ולכן מומלץ לשוב ולקרוא מדי פעם את המדיניות לבחינת השינויים. בכל מקרה בו יבוצעו במדיניות זו שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסרת, תפורסם על-כך הודעה בעמוד הבית של האתר.</p>
                </div>
                <div className="button-container">
                    <Button variant="contained" color="primary" onClick={onClose}>{t('common.buttons.close')}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default TermsAndPrivacyPolicy
