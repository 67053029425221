import { Button, IconButton, Menu, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import { useStore } from '../../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { formatToPhoneNumber } from '../../../shared/utils/sharedFunctions';
import CloseIcon from "@material-ui/icons/Close";

const EditUser = ({ handleSaveEdit }) => {
    const { i18n, t } = useTranslation();
    const { communityStore, languageStore } = useStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editRowWidth, setEditRowWidth] = useState(0);

    const [idEdited, setIdEdited] = useState(0);
    const [firstNameEdited, setFirstNameEdited] = useState('');
    const [lastNameEdited, setLastNameEdited] = useState('');
    const [emailEdited, setEmailEdited] = useState('');
    const [phoneEdited, setPhoneEdited] = useState('');
    const [isPermitted, setIsPermitted] = useState(false);

    useEffect(() => {
        setEditRowWidth(communityStore.editRowWidth);
        setAnchorEl(communityStore.editRowAnchor);
    }, [communityStore.editRowWidth, communityStore.editRowAnchor])

    const handleSave = () => {
        let data = {
            "first_name": firstNameEdited,
            "last_name": lastNameEdited,
            "phone": phoneEdited.replace(' ', ''),
            "email": emailEdited,
            "is_permitted": isPermitted,
        }
        handleSaveEdit(data, idEdited);
        communityStore.editRowAnchor = null;
    }


    useEffect(() => {
        const editedRow = communityStore.userEdit;
        setIdEdited(editedRow.id);
        setFirstNameEdited(editedRow.firstName);
        setLastNameEdited(editedRow.lastName);
        setEmailEdited(editedRow.email);
        setPhoneEdited(editedRow.phone);
        setIsPermitted(editedRow.isPermitted);

    }, [communityStore.userEdit])

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng])

    const handleClose = () => {
        setAnchorEl(null);
        communityStore.editRowAnchor = null;
    }

    return (
        <Menu
            id="edit-row-container"
            className="community-user-edit"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
        >
            <div className="edit-row-container" style={{ width: editRowWidth }}>
                <div style={{ flex: 1, padding: lngDirection !== 'rtl' ? '0 0 0 16px' : '0 16px 0 0' }}>
                    <TextField value={firstNameEdited} onChange={(e) => setFirstNameEdited(e.target.value)} />
                </div>
                <div style={{ flex: 1, padding: lngDirection !== 'rtl' ? '0 0 0 4px' : '0 4px 0 0' }}>
                    <TextField value={lastNameEdited} onChange={(e) => setLastNameEdited(e.target.value)} />
                </div>
                <div style={{ flex: 1, padding: lngDirection !== 'rtl' ? '0 0 0 4px' : '0 4px 0 0' }}>
                    <TextField value={phoneEdited} onChange={(e) => setPhoneEdited(formatToPhoneNumber(e.target.value, phoneEdited))} />
                </div>
                <div style={{ flex: 1, }}>
                    <TextField value={emailEdited} onChange={(e) => setEmailEdited(e.target.value)} />
                </div>
                <div style={{ width: 240 }} className="table-row-checkIcon-container" />
                <div style={{ width: 240 }} className="table-row-checkIcon-container" onClick={() => setIsPermitted(!isPermitted)}>
                    <IconButton aria-label="Edit" className="icon-container">
                        {isPermitted ? (
                            <CheckIcon color="secondary" />
                        ) : (
                            <CheckIcon color="disabled" />
                        )}
                    </IconButton>
                </div>
                <div style={{ width: 100 }} className="flex-center">
                    <Button size="small" variant="contained" color="primary" onClick={handleSave}>{t('common.buttons.save')}</Button>
                </div>
                <div style={{ width: 100 }} className="flex-center">
                    <CloseIcon className="icon-close" onClick={() => handleClose()} />
                </div>
            </div>
        </Menu>
    )
}

export default observer(EditUser)
