import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchAllArticles from './Articles/SearchAllArticles';
import SearchRotatingBanner from './Articles/SearchRotatingBanner';
import SearchAds from './Articles/SearchAds';
import SearchCommunity from './Community/SearchCommunity';
import SearchReportsSurveys from './Reports/Surveys/SearchReportsSurveys';
import SearchReportsPurchases from './Reports/Purchases/SearchReportsPurchases';
import SearchReportsClicks from './Reports/Clicks/SearchReportsClicks';
import SearchBenefits from './Benefits/SearchBenefits';
import SearchPromo from './Push/SearchPromo';
import SearchDashboard from './Dashboard/SearchDashboard';
import "./style.scss";
import CloseIcon from '@material-ui/icons/Close';
import SearchUsers from './Users/SearchUsers';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/StoreContext';
import { observer } from 'mobx-react-lite';

const SearchBarInfo = ({ onClose }) => {
    const { t } = useTranslation();
    const { languageStore } = useStore();
    const history = useHistory();
    const location = history.location.pathname;
    const [title, setTitle] = useState('');
    const [component, setComponent] = useState(<></>);

    const getTitle = () => {
        switch (location) {
            case '/':
                setTitle(t('common.fieldHeaders.search'));
                break;
            case '/dashboard/searchList':
                setTitle(t('common.fieldHeaders.search'));
                break;
            case '/articles/list':
                setTitle(t('articles.allArticles.search.title'));
                break;
            case '/articles/rotating-banner':
                setTitle(t('articles.rotatingBanner.search.title'));
                break;
            case '/articles/ads':
                setTitle(t('articles.ads.search.title'));
                break;
            case '/benefits/list':
                setTitle(t('benefits.search.title'));
                break;
            case '/users/list':
                setTitle(t('users.search.title'));
                break;
            case '/community/list':
                setTitle(t('community.search.title'));
                break;
            case '/reports/surveys':
                setTitle(t('benefits.search.title'));
                break;
            case '/reports/purchases':
                setTitle(t('benefits.search.title'));
                break;
            case '/reports/clicks':
                setTitle(t('benefits.search.title'));
                break;
            case '/push/promotional':
                setTitle(t('push.search.title'));
                break;
            default:
                setTitle(location);
                break;
        }
    }

    useEffect(() => {
        getTitle();
    }, [languageStore.switchLng])

    useEffect(() => {
        switch (location) {
            case '/':
                setTitle(t('common.fieldHeaders.search'));
                setComponent(<SearchDashboard />);
                break;
            case '/dashboard/searchList':
                setTitle(t('common.fieldHeaders.search'));
                setComponent(<SearchDashboard />);
                break;
            case '/articles/list':
                setTitle(t('articles.allArticles.search.title'));
                setComponent(<SearchAllArticles />);
                break;
            case '/articles/rotating-banner':
                setTitle(t('articles.rotatingBanner.search.title'));
                setComponent(<SearchRotatingBanner />);
                break;
            case '/articles/ads':
                setTitle(t('articles.ads.search.title'));
                setComponent(<SearchAds />);
                break;
            case '/benefits/list':
                setTitle(t('benefits.search.title'));
                setComponent(<SearchBenefits />);
                break;
            case '/users/list':
                setTitle(t('users.search.title'));
                setComponent(<SearchUsers />);
                break;
            case '/community/list':
                setTitle(t('community.search.title'));
                setComponent(<SearchCommunity />);
                break;
            case '/reports/surveys':
                setTitle(t('reports.searchTitle'));
                setComponent(<SearchReportsSurveys />);
                break;
            case '/reports/purchases':
                setTitle(t('reports.searchTitle'));
                setComponent(<SearchReportsPurchases />);
                break;
            case '/reports/clicks':
                setTitle(t('reports.searchTitle'));
                setComponent(<SearchReportsClicks />);
                break;
            case '/push/promotional':
                setTitle(t('push.search.title'));
                setComponent(<SearchPromo />);
                break;
            default:
                setTitle(location);
                break;
        }
    }, [location])

    return (
        <div className="search-panel-container">
            <Grid container justifyContent="space-between" className="search-panel-header">
                <span className="search-panel-title">{title}</span>
                <span className="search-panel-close" onClick={onClose}><CloseIcon /></span>
            </Grid>
            {
                (component)
            }
        </div>
    )
}

export default observer(SearchBarInfo)
