import React, { useEffect, useState } from 'react';
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import { DataGrid, GridColDef, GridCellParams, GridColumnHeaderParams } from '@material-ui/data-grid';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import "./style.scss";
import { useStore } from '../../../store/StoreContext';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import EditIcon from "../../../assets/icons/edit.svg";
import ViewIcon from "../../../assets/icons/view.svg";
import ArchiveIcon from "../../../assets/icons/archive.svg";
import { Link } from 'react-router-dom';
import { flowResult } from 'mobx';

const ArticleList = ({rows, triggerIsLoading }) => {
    const { i18n, t } = useTranslation();
    const { actionStore, languageStore, dashboardStore } = useStore();

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const toggleArchive = async (id) => {
        const path = 'article/archive';
        triggerIsLoading();
        await flowResult(actionStore.update({}, path, id)).then(
            (response: any) => {
                if (!response?.success) {
                    return;
                }
            }
        );
        dashboardStore.triggerUpdateList = true;
    }


    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng])

    const columns: GridColDef[] = [
        {
            field: 'image',
            headerName: '',
            sortable: false,
            renderHeader: () => {
                return (
                    <div />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.image;
                const isFaded = params.row.isArchived;
                return (
                    <img className={`table-row-image ${isFaded && 'element-faded'}`} src={row || ImagePlaceholder} alt={row || "Article image"} />
                )
            },
        },
        {
            field: 'category',
            sortable: false,
            headerName: t('common.fieldHeaders.category'),
            width: 120,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row.category.contentName;
                return (<span className="table-row-title">{name}</span>)
            },
        },
        {
            field: 'header',
            headerName: t('common.fieldHeaders.header'),
            minWidth: 150,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isFaded = params.row.isArchived;
                return (
                    <span className={`table-row-title ${isFaded && 'element-faded'}`}>{params.row.header}</span>
                );
            },
        },
        {
            field: 'publishDate',
            headerName: t('common.fieldHeaders.publishedOn'),
            width: 255,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.publishDate;
                const todayDate = new Date();
                const liveDate = new Date(row);
                const isLiveDateFuture = Date.parse(todayDate.toString()) < Date.parse(liveDate.toString());
                const isFaded = params.row.isArchived;
                return (
                    <div className={`${isFaded && 'element-faded'} ${isLiveDateFuture ? "table-row-double" : ''}`}>
                        <div className="table-row-date">{convertToDate(row)}</div>
                        <div className="table-row-scheduled">{isLiveDateFuture ? t('common.fieldHeaders.scheduled') : ''}</div>
                    </div>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            sortable: false,
            width: 250,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column actions-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                const id = data.id;
                return (
                    <div id={`grid-row-${id}`} className={`table-row-actions cell-actions ${data.isArchived && 'element-faded'}`}>
                        <Link className="view-link flex-center" to={`/articles/edit/article/${id}`}>
                            <IconComponent icon={EditIcon} />
                        </Link>
                        <Link className="view-link flex-center" to={`/articles/view/article/${id}`}>
                            <IconComponent icon={ViewIcon} />
                        </Link>
                        <div onClick={() => toggleArchive(id)}>
                            <IconComponent icon={ArchiveIcon} />
                        </div>
                    </div>
                );
            },
        },
    ].map((column) => (
        {
        ...column,
        disableClickEventBubbling: true,
    }));

    return (
        <div className={`table-grid dashboard-articles-search`}>
            <DataGrid
                autoHeight={true}
                rows={rows}
                columns={lngDirection === "ltr" ? columns : columns.reverse()}
                rowHeight={90}
                headerHeight={56}
                disableColumnMenu={true}
                hideFooter={true}
            />
        </div>
    )
}

export default observer(ArticleList)
