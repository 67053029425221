import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Button, Input, InputAdornment, Select, MenuItem, FormControlLabel, Checkbox, Slider } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '../../LeftSideBar/Icons/SearchIcon/SearchIcon';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIELDS, VALIDATIONS } from "./fields";
import { useStore } from '../../../store/StoreContext';
import Spinner from '../../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { observer } from 'mobx-react-lite';

const SearchSales = () => {
    const { i18n, t } = useTranslation();
    const { benefitStore, actionStore, languageStore } = useStore();

    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([{
        id: '',
        categoryName: '',
        categoryType: '',
    }]);

    const [maxPrice, setMaxPrice] = useState(100);

    const convertToDate = (date) => {
        if (!date) {
            return;
        }
        const locale = 'en-US'
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const {
        register,
        handleSubmit,
        control,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        defaultValues: {
            'search': '',
            'category': 'all',
            'type': 'all',
            'from_date': convertToDate(new Date()),
            'to_date': convertToDate(new Date(new Date().setDate(new Date().getDate() + 1))),
            'price': 0,
            'is_archive': false,
            'low_inventory': false,
        }
    });

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    useEffect(() => {
        setCategories(benefitStore.getStoredBenefits());
        setIsLoading(false)
    }, [])

    useEffect(() => {
        setIsLoading(true);
        let maxPrice = benefitStore.getMaxPrice();
        if (maxPrice > 100) {
            setMaxPrice(maxPrice);
            setMarks([
                {
                    value: 0,
                    label: t("common.currency") + '0',
                },
                {
                    value: maxPrice,
                    label: t("common.currency") + maxPrice,
                },
            ]);
        }
        setTimeout(() => {
            setIsLoading(false);
        })
    }, [benefitStore.maxPrice]);

    const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

    const IOSSlider = withStyles({
        root: {
            color: '#3880ff',
            height: 2,
            padding: '15px 0',
        },
        thumb: {
            '&:focus, &:hover, &$active': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    boxShadow: iOSBoxShadow,
                },
            },
        },
        active: {},
        valueLabel: {
            left: -11,
            top: -22,
            fontWeight: 'bold',
            '& *': {
                background: 'transparent',
                color: '#000',
            },
        },
        track: {
            height: 2,
        },
        markActive: {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    })(Slider);

    const [marks, setMarks] = useState([
        {
            value: 0,
            label: t("common.currency") + '0',
        },
        {
            value: 100,
            label: t("common.currency") + '100',
        },
    ]);

    function valuetext(value) {
        return t("common.currency") + `${value}`;
    }

    function valueLabelFormat(value) {
        return t("common.currency") + `${value}`;
    }

    const handleSubmitCheck = (objectData) => {
        const data = {
            ...objectData,
            "category": objectData["category"] !== 'all' ? objectData["category"] : '',
            "type": objectData["type"] !== 'all' ? objectData["type"] : '',
        }
        actionStore.setSearchParams(data);
    }

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng])

    return isLoading ? (<Spinner className="search-spinner" />) : (
        <div className="search-panel">
            <form className="" autoComplete="off" onSubmit={handleSubmit((d) => handleSubmitCheck(d))}>
                <div className="main-wrapper">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lngDirection === 'rtl' ? heLocale : enLocale}>
                        <Grid container className="view-grid" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item className="grid-row">
                                    <FormControl className="search-field-container">
                                        <InputLabel htmlFor="search-field">{t('common.fieldHeaders.search')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name="search"
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    id="search-field"
                                                    value={value}
                                                    onChange={onChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id="category-label">{t('common.fieldHeaders.categories')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name="category"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    labelId="category-label"
                                                    id="category"
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value='all'>{t('common.selectFilters.allCategories')}</MenuItem>
                                                    {
                                                        categories.map((cat, index) => (
                                                            <MenuItem key={index} value={cat.id}>{cat.categoryName}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id="category-label">{t('common.fieldHeaders.type')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name="type"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    labelId="type-label"
                                                    id="type"
                                                    displayEmpty
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value='all'>{t('common.selectFilters.allTypes')}</MenuItem>
                                                    <MenuItem value='LEADS'>{t('common.selectFilters.leads')}</MenuItem>
                                                    <MenuItem value='LINK'>{t('common.selectFilters.link')}</MenuItem>
                                                    <MenuItem value='STORE'>{t('common.selectFilters.store')}</MenuItem>
                                                    <MenuItem value='SURVEY'>{t('common.selectFilters.survey')}</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <p className="search-panel-subTitle">{t('common.fieldHeaders.date')}</p>
                                <Grid item className="grid-row search-date-first">
                                    <Controller
                                        control={control}
                                        name="from_date"
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="publishDate-picker-inline"
                                                className="date-picker-inline"
                                                label={t('common.fieldHeaders.fromDate')}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onChange={(value) => {
                                                    props.field.onChange(convertToDate(value));
                                                }}
                                                value={props.field.value as ParsableDate}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item className="grid-row">
                                    <Controller
                                        control={control}
                                        name="to_date"
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                // minDate={getValues('from_date') as ParsableDate}
                                                minDateMessage={t('errorMessages.minimumDate')}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="publishDate-picker-inline"
                                                className="date-picker-inline"
                                                label={t('common.fieldHeaders.toDate')}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onChange={(value) => {
                                                    props.field.onChange(convertToDate(value));
                                                }}
                                                value={props.field.value as ParsableDate}
                                            />
                                        )}
                                    />
                                </Grid>
                                <p className="search-panel-subTitle">{t('common.fieldHeaders.price')}</p>
                                <Controller
                                    control={control}
                                    name="price"
                                    render={(props: any) => (
                                        <IOSSlider
                                            aria-label="Custom marks"
                                            getAriaValueText={valuetext}
                                            valueLabelFormat={valueLabelFormat}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            marks={marks}
                                            max={maxPrice}
                                            id="price"
                                            onChange={(evt, value) => {
                                                props.field.onChange(value);
                                            }}
                                            value={props.field.value}
                                        />
                                    )}
                                />
                                <Grid item className="grid-row">
                                    <FormControlLabel
                                        control={

                                            <Controller
                                                control={control}
                                                name="low_inventory"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value || false}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('common.fieldHeaders.lowInventory')}
                                    />
                                </Grid>
                                <Grid item className="grid-row">
                                    <FormControlLabel
                                        control={

                                            <Controller
                                                control={control}
                                                name="is_archive"
                                                render={({ field: { onChange, value } }: any) => (
                                                    <Checkbox
                                                        color="primary"
                                                        checked={value || false}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        }
                                        label={t('common.fieldHeaders.searchArchive')}
                                    />
                                </Grid>
                                <Grid item className="grid-row search-button-container">
                                    <Button variant="contained" color="primary" type="submit">{t('common.fieldHeaders.search')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
            </form>
        </div>
    )
}

export default observer(SearchSales)
