import RootStore from "./RootStore";
import {makeAutoObservable} from "mobx";
import {Api} from "../services/Api";

export default class ArchiveStore {
    root: RootStore;

    path = "/admin/settings/archive";
    articleArchiveDays = 7;
    benefitArchiveDays = 7;

    constructor(root) {
        this.root = root;
        makeAutoObservable(this, {
            root: false,
        })
    }

    getArticleArchiveDays() {
        return this.articleArchiveDays;
    }

    getBenefitArchiveDays() {
        return this.benefitArchiveDays;
    }

    * getArchive() {
        const response = yield Api.get(this.path);
        if (response.ok && response.data?.success) {
            this.articleArchiveDays = response.data.article_days || 7;
            this.benefitArchiveDays = response.data.benefit_days || 7;
        } else {
            this.articleArchiveDays = 7;
            this.benefitArchiveDays = 7;
        }
        return response?.data
    }

    * update(payload: any) {
        const response = yield Api.put(`${this.path}`, payload);
        this.articleArchiveDays = payload.article_days;
        this.benefitArchiveDays = payload.benefit_days;
        return response?.data
    }
}
