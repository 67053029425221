import React from 'react'
import "./PrimaryBox.scss";

export interface PrimaryBoxComponentProps {
    children: any;
}
const PrimaryBoxComponent = (props: PrimaryBoxComponentProps) => {
    return (
        <div className="primary-box-container">
            {props.children}
        </div>
    )

}


export default PrimaryBoxComponent