import * as Yup from 'yup';

export const FIELDS = {
    SEARCH: "search",
    CATEGORY_ID: "category_id",
    WRITER: "writer",
    FROM_DATE: "from_date",
    TO_DATE: "to_date",
    MANAGED_BY: "managed_by",
    IS_ARCHIVE: "is_archived",
}

export const VALIDATIONS = Yup.object().shape({
    [FIELDS.SEARCH]: Yup.string(),
    [FIELDS.CATEGORY_ID]: Yup.string(),
    [FIELDS.WRITER]: Yup.string(),
    [FIELDS.FROM_DATE]: Yup.string(),
    [FIELDS.TO_DATE]: Yup.string(),
    [FIELDS.MANAGED_BY]: Yup.string(),
    [FIELDS.IS_ARCHIVE]: Yup.boolean(),
});
