import React from 'react'
import PrimaryBoxComponent from '../../../shared/components/PrimaryBox/PrimaryBox';
import ActivityItemComponent, { ActivityItem } from './ActivityItemComponent/ActivityItemComponent';
import "./LatestActivitiesComponent.scss";
import { useTranslation } from 'react-i18next';

export interface LatestActivityProps {
    latestActivity: ActivityItem[];
    isLoading?: boolean;
    openModal?: any;
}



const LatestActivityComponent = (props: LatestActivityProps) => {
    const { t } = useTranslation();
    return (
        <PrimaryBoxComponent>
            <div className="latest-activity-list-container">
                <div className="latest-activity-title">{t('dashboard.latestActivitiesComponent')}</div>
                <div className="activity-list-wrapper">
                    {
                        props.latestActivity.map((activity, idx) => {
                            return (
                                <ActivityItemComponent isLoading={props.isLoading} key={idx} activityInfo={activity} openModal={props.openModal} />
                            )
                        })
                    }
                    {
                    !props.latestActivity?.length ? (<p>{t('dashboard.noActivity')}</p>) : (<></>)
                }
                </div>

            </div>
        </PrimaryBoxComponent>
    )

}


export default LatestActivityComponent