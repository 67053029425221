import DateFnsUtils from "@date-io/date-fns";
import {
  Grid,
  FormControl,
  InputLabel,
  Button,
  Input,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import SearchIcon from "../../LeftSideBar/Icons/SearchIcon/SearchIcon";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/StoreContext";
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

const SearchDashboard = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { dashboardStore, languageStore } =
    useStore();
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(true);

  const categoryOptions = [
    {
      value: "articles",
      label: t("tabNavigation.articles.mainTab"),
    },
    {
      value: "benefits",
      label: t("tabNavigation.benefits.mainTab"),
    },
  ];

  const convertToDate = (date) => {
    if (!date) {
      return date;
    }
    const locale = "en-US";
    const option1 = { day: "numeric" };
    const option2 = { month: "long" };
    const option3 = { year: "numeric" };
    return `${date.toLocaleDateString(
      locale,
      option1
    )} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(
      locale,
      option3
    )}`;
  };

  const { register, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      search: "",
      category: ['articles', 'benefits'],
      from_date: convertToDate(new Date()),
      to_date: convertToDate(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ),
      is_archived: false,
    },
  });

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const handleSubmitCheck = (objectData) => {
    const data = {
      ...objectData,
      category: objectData.category.filter((el) => el !== 'select-all')
    }
    dashboardStore.setSearchParams(data);
    history.push("/dashboard/searchList");
  };

  // Detecting Language Switch
  const [lngDirection, setLngDirection] = useState("");

  useEffect(() => {
    const dir = i18n.dir();
    setLngDirection(dir);
  }, [languageStore.switchLng]);

  const onMultiSelectChange = (data, isSelectAll = false) => {
    setValue('category', data)
    setIsSelectAllSelected(isSelectAll)
  }

  return (
    <div className="search-panel dashboard-search">
      <form
        className=""
        autoComplete="off"
        onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
      >
        <div className="main-wrapper">
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={lngDirection === "rtl" ? heLocale : enLocale}
          >
            <Grid container className="view-grid" justifyContent="center">
              <Grid item xs={12}>
                <Grid item className="grid-row">
                  <FormControl className="search-field-container">
                    <InputLabel htmlFor="search-field">
                      {t("common.fieldHeaders.search")}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="search"
                      render={({ field: { onChange, value } }) => (
                        <Input
                          id="search-field"
                          value={value}
                          onChange={onChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item className="grid-row" xs={12}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange, value } }: any) => (
                        <>
                          <label className="field-label">
                            {t("common.fieldHeaders.categories")}
                          </label>
                          <MultiSelect
                            showSelectAll
                            value={value}
                            isSelectAllSelected={isSelectAllSelected}
                            mapBy="value"
                            renderedName="label"
                            onChange={onMultiSelectChange}
                            selectOptions={categoryOptions}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item className="grid-row search-date-first">
                  <p className="search-panel-subTitle">
                    {t("common.fieldHeaders.publishedOn")}
                  </p>
                  <Controller
                    control={control}
                    name="from_date"
                    render={(props) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="publishDate-picker-inline"
                        className="date-picker-inline"
                        label={t("common.fieldHeaders.fromDate")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onChange={(value) => {
                          props.field.onChange(convertToDate(value));
                        }}
                        value={props.field.value as ParsableDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item className="grid-row">
                  <Controller
                    control={control}
                    name="to_date"
                    render={(props) => (
                      <KeyboardDatePicker
                        // minDate={getValues("from_date") as ParsableDate}
                        minDateMessage={t('errorMessages.minimumDate')}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="publishDate-picker-inline"
                        className="date-picker-inline"
                        label={t("common.fieldHeaders.toDate")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onChange={(value) => {
                          props.field.onChange(convertToDate(value));
                        }}
                        value={props.field.value as ParsableDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item className="grid-row">
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="is_archived"
                        render={({ field: { onChange, value } }: any) => (
                          <Checkbox
                            color="primary"
                            checked={value || false}
                            onChange={onChange}
                          />
                        )}
                      />
                    }
                    label={t("common.fieldHeaders.searchArchive")}
                  />
                </Grid>
                <Grid item className="grid-row search-button-container">
                  <Button variant="contained" color="primary" type="submit">
                    {t("common.fieldHeaders.search")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </form>
    </div>
  );
};

export default SearchDashboard;
