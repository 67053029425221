import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PHONE: "phone",
  EMAIL: "email",
  IMAGE: "image",
  COMMUNITY_ID: "community_id",
  ADMIN_COMMUNITIES: "admin_communities",
  DEPARTMENT_ID: "department_id",
  SECONDARY_DEPARTMENT_ID: "secondary_department_id",
  ROLE: "role",
  PASSWORD: "password",
};

export const VALIDATIONS = Yup.object().shape(
  {
    [FIELDS.IMAGE]: Yup.string(),
    [FIELDS.FIRST_NAME]: Yup.string()
      .min(2, () => i18n.t("validationMessages.users.allUsers.minHeader"))
      .max(15, () => i18n.t("validationMessages.users.allUsers.maxHeader"))
      .required(() => i18n.t("validationMessages.users.allUsers.required")),
    [FIELDS.LAST_NAME]: Yup.string()
      .min(2, () => i18n.t("validationMessages.users.allUsers.minHeader"))
      .max(15, () => i18n.t("validationMessages.users.allUsers.maxHeader"))
      .required(() => i18n.t("validationMessages.users.allUsers.required")),
    [FIELDS.PHONE]: Yup.string()
      .min(11, () => i18n.t("validationMessages.users.allUsers.phone"))
      .max(11, () => i18n.t("validationMessages.users.allUsers.phone"))
      .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () =>
        i18n.t("validationMessages.auth.signUp.phonePattern")
      )
      .required(() => i18n.t("validationMessages.users.allUsers.required")),
    [FIELDS.EMAIL]: Yup.string()
      .email(() => i18n.t("validationMessages.users.allUsers.email"))
      .required(() => i18n.t("validationMessages.users.allUsers.required")),
    [FIELDS.COMMUNITY_ID]: Yup.string().when("role", {
      is: (value) => value === "ADMIN",
      then: (element) => element.notRequired(),
      otherwise: (element) =>
        element.required(() =>
          i18n.t("validationMessages.users.allUsers.required")
        ),
    }),
    [FIELDS.ADMIN_COMMUNITIES]: Yup.array().when("role", {
      is: (value) => value === "ADMIN",
      then: (element) =>
        element
          .required(() => i18n.t("validationMessages.users.allUsers.required"))
          .min(1, () => i18n.t("validationMessages.users.allUsers.required")),
      otherwise: (element) => element.notRequired(),
    }),
    [FIELDS.DEPARTMENT_ID]: Yup.string().required(() =>
      i18n.t("validationMessages.users.allUsers.required")
    ),
    [FIELDS.SECONDARY_DEPARTMENT_ID]: Yup.string().notRequired().nullable(true),
    [FIELDS.ROLE]: Yup.string().required(() =>
      i18n.t("validationMessages.users.allUsers.required")
    ),
    [FIELDS.PASSWORD]: Yup.string()
      .nullable()
      .notRequired()
      .when("password", {
        is: (value) => value?.length,
        then: (rule) =>
          rule.min(6, () => i18n.t("validationMessages.auth.signUp.minPass")),
      }),
  },
  [["password", "password"]]
);
