import React, { useEffect, useState } from 'react';
import Upload from '../../assets/images/upload.png';
import "./style.scss";
import ImageIcon from '@material-ui/icons/Image';
import NotificationModal from '../NotificationModal/NotificationModal';
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';

function UploadFile(
    {
        handleFileUpload,
        text,
        id = '',
        showImage = false,
        image = '',
        height = 139,
        width = 100,
        demoIcon = Upload,
        hoverOn = true,
        format = ["image/jpg", "image/jpeg", "image/png"], enableVideo = false,
        isMobile = false,
    }
) {
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const { getRootProps, acceptedFiles } = useDropzone()
    const { t } = useTranslation();
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            handleFile(acceptedFiles[0])
        }
    }, [acceptedFiles])

    const handleFile = (file) => {
        const formats = format.join('/').replace(/image\//g, '').toUpperCase();
        if (file?.type) {
            if (!enableVideo) {
                if (file.size > 26214400) {
                    setNotificationMessage(t('errorMessages.uploadFile.maxSizeImage'))
                    setOpenNotificationModal(true);
                    return;
                }
                if (format.indexOf(file.type) === -1) {
                    setNotificationMessage(t('errorMessages.uploadFile.formatsImage', { formats: formats }))
                    setOpenNotificationModal(true);
                    return;
                }
            } else {
                if (format.indexOf(file.type) !== -1 && file.size > 26214400) {
                    setNotificationMessage(t('errorMessages.uploadFile.maxSizeImage'))
                    setOpenNotificationModal(true);
                    return;
                }
                if (file.type.indexOf(`video/mp4`) !== -1 && file.size > 52428800) {
                    setNotificationMessage(t('errorMessages.uploadFile.maxSizeVideo'))
                    setOpenNotificationModal(true);
                    return;
                }
                if (format.indexOf(file.type) === -1 && file.type.indexOf(`video/mp4`) === -1) {
                    setNotificationMessage(t('errorMessages.uploadFile.formatsAll', { formats: formats }))
                    setOpenNotificationModal(true);
                    return;
                }
            }
        }

        handleFileUpload(file);
    };

    const handleUpload = (event) => {
        handleFile(event.target.files[0]);
    };

    const handleNotificationClose = () => {
        setOpenNotificationModal(false);
    }

    return (
        <>
            <div className={`upload-container ${isMobile ? 'mobile' : 'flex-center'} ${hoverOn ? 'hover' : ''} `} {...getRootProps()}>
                <label htmlFor={`fileUpload ${id}`} className={`d-flex ${isMobile ? 'flex-row align-center' : 'flex-column flex-center'}`}>
                    {
                        showImage && (
                            <div style={{ height: height, width: width }}>
                                <img src={image} className="upload-image" alt="Upload" />
                                <div className="upload-icon-container flex-center">
                                    <ImageIcon className="upload-icon" />
                                </div>
                            </div>
                        )
                    }
                    {
                        !showImage && (
                            <>
                                <img src={demoIcon} className="upload-image-demo" alt="Upload" />
                                {
                                    text ? (
                                        <div className="upload-text">
                                            {text}
                                        </div>
                                    ) : <></>
                                }

                            </>
                        )
                    }
                </label>
                <input id={`fileUpload ${id}`} type="file" name="file" hidden={true} onChange={handleUpload} />
            </div>
            <NotificationModal openModal={openNotificationModal} buttonText={t('common.buttons.close')}
                handleClose={handleNotificationClose} handleButtonClick={handleNotificationClose}
                message={notificationMessage} />
        </>
    );
}
export default UploadFile
