import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  HEADER: "header",
  BACKGROUND_COLOR: "background_color",
  PRIMARY_COLOR: "primary_color",
  NAVBAR_LOGO: "navbar_logo",
  TEXT_COLOR: "text_color",
  SENDER_NAME: "sender_name",
  SENDER_EMAIL: "sender_email",
  SENDER_PHONE: "sender_phone",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.HEADER]: Yup.string()
    .min(2, () => i18n.t("validationMessages.settings.general.minHeader"))
    .max(20, () => i18n.t("validationMessages.settings.general.maxHeader", { max: 20}))
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.BACKGROUND_COLOR]: Yup.string()
    .min(7, () => i18n.t("validationMessages.settings.general.bgColor"))
    .max(7, () => i18n.t("validationMessages.settings.general.bgColor"))
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.PRIMARY_COLOR]: Yup.string()
    .min(7, () => i18n.t("validationMessages.settings.general.prmColor"))
    .max(7, () => i18n.t("validationMessages.settings.general.prmColor"))
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.NAVBAR_LOGO]: Yup.string().required(() =>
    i18n.t("validationMessages.settings.general.required")
  ),
  [FIELDS.TEXT_COLOR]: Yup.string()
  .nullable()
  .notRequired()
  .when('text_color', {
      is: (value) => value?.length,
      then: (rule) => rule.min(7, () => i18n.t("validationMessages.settings.general.prmColor"))
                          .max(7, () => i18n.t("validationMessages.settings.general.prmColor")),
  }),
  [FIELDS.SENDER_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.settings.general.minHeader"))
    .max(15, () => i18n.t("validationMessages.settings.general.maxHeader", { max: 15}))
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.SENDER_EMAIL]: Yup.string()
    .email(() => i18n.t("validationMessages.settings.general.email"))
    .required(() => i18n.t("validationMessages.settings.general.required")),
  [FIELDS.SENDER_PHONE]: Yup.string()
    .required(() => i18n.t("validationMessages.settings.general.required"))
    .max(15, () => i18n.t("validationMessages.settings.general.maxHeader", { max: 15})),
}, [
  ['text_color', 'text_color']
]);
