import React, { useEffect, useState } from "react";
import { users } from "../../../db/db";
import { Button, Checkbox, FormControl, FormControlLabel, MenuItem, Modal, Select } from "@material-ui/core";
import "./style.scss";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationModal from "../../../components/NotificationModal/NotificationModal";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from "../../../components/Spinner/Spinner";
import { useStore } from "../../../store/StoreContext";
import { flowResult } from "mobx";
import Person_1 from "../../../assets/images/usersPage/person_1.png";
import Person_2 from "../../../assets/images/usersPage/person_2.png";
import Person_3 from "../../../assets/images/usersPage/person_3.png";
import Person_4 from "../../../assets/images/usersPage/person_4.png";
import Person_5 from "../../../assets/images/usersPage/person_5.png";
import Person_6 from "../../../assets/images/usersPage/person_6.png";
import UserTypeEnum from "../../../models/UserType";

const ImportFromFile = () => {
  const { i18n, t } = useTranslation();
  const { usersPageStore, departmentStore, userStore } = useStore();
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importMode, setImportMode] = useState(["create"]);
  const [deactivateUsers, setDeactivateUsers] = useState(false);
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);

  const isDisabled = userStore.getRoleType() === UserTypeEnum.owner;
  const { getRootProps, acceptedFiles } = useDropzone({ disabled: isDisabled });
  const [uploadedResults, setUploadedResults] = useState<any>({});
  const [uploadErrors, setUploadErrors] = useState<any>([]);
  const language = i18n.language;

  const uploadUsersURI = `${window.location.origin}/files/users_template_new${language === 'en' ? '_en' : ''}.xlsx`;

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const handleFile = async (file) => {
    if (importMode.length === 0) {
      return;
    }
    setIsLoading(true);
    
    const searchParams = {
      create: importMode.indexOf('create') > -1,
      update: importMode.indexOf('update') > -1,
      deactivate: deactivateUsers,
    }

    if (file.size > 26214400) {
      setNotificationMessage(t("errorMessages.fileSize"));
      setOpenNotificationModal(true);
      return;
    }
    if (
      file.type.indexOf(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) === -1
    ) {
      setNotificationMessage(t("errorMessages.fileXls"));
      setOpenNotificationModal(true);
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    await flowResult(usersPageStore.importUsers(formData, searchParams)).then(
      async (response: any) => {
        if (!response?.success) {
          const users = response?.users?.length ? response.users.map((user) => `${user.firstName} ${user.lastName}`).join('. ') : "";
          handleNotificationOpen(
            response.code ? `${t(`apiMessages.${response.code}`)}${users.length ? `. ${users}` : ""}` : t("apiMessages.0")
          );
          return;
        }
        setUploadedResults(response.results);
        setUploadErrors(response.errors);
        if (userStore.getCurrentCommunity()?.id) {
          await flowResult(departmentStore.getDepartments()).then(
            (response: any) => {
              if (!response?.success) {
                const users = response?.users?.length ? response.users.map((user) => `${user.firstName} ${user.lastName}`).join('. ') : "";
                handleNotificationOpen(
                  response.code ? `${t(`apiMessages.${response.code}`)}${users.length ? `. ${users}` : ""}` : t("apiMessages.0")
                  );
                  return;
                }
              })
        }
        setOpenUploadFileModal(true);
      }
    );
    setIsLoading(false);
  };

  const handleUpload = (event) => {
    handleFile(event.target.files[0]);
  };

  const handleNotificationOpen = (message = "") => {
    setNotificationMessage(message);
    setOpenNotificationModal(true);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-wrapper import-from-file-page">
      <p className="import-message">{t('users.importFromFile.importOptionMessage')}</p>
      <div>
        <FormControl className="select-container">
          <Select
            labelId="select-filter"
            id="select-filter"
            className="select-preview-filter"
            value={[].concat(importMode)}
            onChange={(e: any) => {
              const eValue = e.target.value;
              if (eValue.indexOf('update') === -1) {
                setDeactivateUsers(false);
              }
              setImportMode(eValue);
            }}
            renderValue={(value: any) => {
              return value.map((el, index) => {
                const name = t(`users.importFromFile.${el}`);
                return name ? (name + (index !== value.length - 1 ? ', ' : '')) : null
              })
            }}
            MenuProps={{

              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            multiple
          >
            <MenuItem value='create'>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    style={{ pointerEvents: "auto" }}
                    color="primary"
                    checked={importMode.indexOf('create') > -1}
                  />
                }
                label={t('users.importFromFile.create')}
              />
            </MenuItem>
            <MenuItem value='update'>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                control={
                  <Checkbox
                    style={{ pointerEvents: "auto" }}
                    color="primary"
                    checked={importMode.indexOf('update') > -1}
                  />
                }
                label={t('users.importFromFile.update')}
              />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deactivateUsers}
              onChange={() => setDeactivateUsers(!deactivateUsers)}
            />
          }
          disabled={importMode.indexOf('update') === -1}
          label={t('users.importFromFile.deactivateUsers')}
        />
        <p className="field-error">{importMode.length === 0 ? t('validationMessages.account.required') : ''}&nbsp;</p>
      </div>
      <div className={`import-from-file content-wrap`} {...getRootProps()}>
        <label htmlFor={`fileUpload`} className={isDisabled ? "cursor-disabled" : "cursor-upload"}>
          <div>
            <img src={users.importFromFile.image} alt="Import from file" />
            <p className="link">{t('users.importFromFile.clickHere')}</p>
            <h3 className="page-heading">
              {t("users.importFromFile.importUserList")}
            </h3>
          </div>
        </label>
        <input
          id={`fileUpload`}
          type="file"
          name="file"
          disabled={isDisabled}
          hidden={true}
          onChange={handleUpload}
        />
        <span className="note">
          {t("users.importFromFile.uploadExcel")}{" "}
          <a href={`${uploadUsersURI}`} download target="_blank" rel="noreferrer">{t("users.importFromFile.here")}</a>
          . <br />
          {t("users.importFromFile.pleaseNote")}
        </span>
        <div className="action-buttons">
          <Link className="view-link flex-center" to={"/users/create"}>
            <Button variant="contained" color="primary" className="button">
              {t("users.create.title")}
            </Button>
          </Link>
          <Link className="view-link flex-center" to={"/users/list"}>
            <Button variant="contained" color="primary" className="button">
              {t("users.importFromFile.allUsersList")}
            </Button>
          </Link>
        </div>
      </div>
      <Modal
        open={openUploadFileModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={`modal-preview import-from-file`}
      >
        <div className="uploaded-information-container">
          <div className="uploaded-information">
            <div className="flex-center flex-column">
              <h1>{uploadedResults.new_users}</h1>
              <p className="text-center">
                {t("users.importFromFile.newUsers")}
              </p>
            </div>
            <div className="flex-center flex-column">
              <h1>{uploadedResults.users_updated}</h1>
              <p className="text-center">
                {t("users.importFromFile.updatedUsers")}
              </p>
            </div>
            <div className="flex-center flex-column">
              <h1>{uploadedResults.users_non_active}</h1>
              <p className="text-center">
                {t("users.importFromFile.turnNonActive")}
              </p>
            </div>
          </div>
          <div className="person-images">
            <div>
              <img src={Person_1} alt="Person_1" />
            </div>
            <div>
              <img src={Person_2} alt="Person_2" />
            </div>
            <div>
              <img src={Person_3} alt="Person_3" />
            </div>
            <div>
              <img src={Person_4} alt="Person_4" />
            </div>
            <div>
              <img src={Person_5} alt="Person_5" />
            </div>
            <div>
              <img src={Person_6} alt="Person_6" />
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenUploadFileModal(false)}
            >
              {t("common.buttons.confirm")}
            </Button>
          </div>
          <div className={`error-container-header text-center ${uploadErrors.length === 0 ? 'hide-element' : ''}`}>{t(`common.errors`)}</div>
          <div className={`error-container ${uploadErrors.length === 0 ? 'hide-element' : ''}`}>
            {
              uploadErrors.map((error, index) => {
                return (
                  <div key={index} className="field-error text-right">{error.value} - {t(`apiMessages.${error.code}`)}</div>
                )
              })
            }
          </div>
          <div className="close-container">
            <CloseIcon
              className="icon-close"
              onClick={() => setOpenUploadFileModal(false)}
            />
          </div>
        </div>
      </Modal>
      <NotificationModal
        className="import-users"
        openModal={openNotificationModal}
        buttonText={t("common.buttons.close")}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        message={notificationMessage}
      />
    </div>
  );
};

export default ImportFromFile;
