import React, { useEffect, useState } from "react";
import { Props } from "./props";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import "./style.scss";
import { loggedUser } from "../../db/db";
import { useTranslation } from "react-i18next";
import UserTypeEnum from "../../models/UserType";
import { useStore } from "../../store/StoreContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIELDS, VALIDATIONS } from "./fields";
import { flowResult } from "mobx";
import Spinner from "../../components/Spinner/Spinner";
import ProceedImage from "../../shared/utils/proceedImage";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import { observer } from "mobx-react-lite";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../shared/utils/cropImage";
import { formatToPhoneNumber } from '../../shared/utils/sharedFunctions';
import SortedAutocomplete from "../../components/SortedAutocomplete/SortedAutocomplete";

const Account = (props: Props) => {
  const { t } = useTranslation();
  const { userStore, languageStore } = useStore();
  const [roleType, setRoleType] = useState(null);
  const [community, setCommunity] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [isNewPictureUploaded, setIsNewPictureUploaded] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const getAccountInfo = async () => {
    await flowResult(userStore.getMyAccountInfo()).then((response: any) => {
      if (!response?.success) {
        setNotificationMessage(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        setOpenNotificationModal(true);
        setIsLoading(false);
        return;
      }
      const user = response.user;
      setValue("first_name", user.firstName);
      setValue("last_name", user.lastName);
      setValue("phone", `${user.phone.slice(0, 3)} ${user.phone.slice(3, 10)}`);
      setValue("email", user.email);
      setValue("image", user.image || "");
      setProfilePicture(user.image);
      setValue("community_id", response?.community?.id ?? "all");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setCommunity(userStore.getCommunities());
    getAccountInfo();
  }, [languageStore.switchLng]);

  useEffect(() => {
    const role = userStore.getRoleType();
    setRoleType(role);
    setValue("role", role);
  }, [userStore.roleType]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(VALIDATIONS),
    mode: "onChange",
    defaultValues: {
      role: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      image: "",
      community_id: "all",
    },
  });

  useEffect(() => {
    Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
  }, [register]);

  const handleSubmitCheck = async (objectData) => {
    setIsLoading(true);
    cropImage(croppedAreaPixels);
    let data: any = {
      first_name: objectData.first_name,
      last_name: objectData.last_name,
      phone: objectData.phone.replace(" ", ""),
      email: objectData.email,
      community_id:
        objectData.community_id !== "all" ? objectData.community_id : "",
    };
    if (objectData?.image) {
      data["image"] = croppedImage || objectData.image;
    }
    setProfilePicture(data.image);
    await flowResult(userStore.setMyAccountInfo(data)).then(
      async (response: any) => {
        if (!response?.success) {
          setNotificationMessage(
            response?.code
              ? t(`apiMessages.${response.code}`)
              : t("apiMessages.0")
          );
          setOpenNotificationModal(true);
          setIsLoading(false);
          return;
        }
        await flowResult(userStore.getMyAccountInfo()).then(
          async (response: any) => {
            if (!response?.success) {
              setNotificationMessage(
                response?.code
                  ? t(`apiMessages.${response.code}`)
                  : t("apiMessages.0")
              );
              setOpenNotificationModal(true);
              setIsLoading(false);
              return;
            }
            userStore.updateStoredAccountInfo(response);
            userStore.triggerHomeNavigatorUpdate =
              !userStore.triggerHomeNavigatorUpdate;
          }
        );
        setNotificationMessage(
          response?.code
            ? t(`apiMessages.${response.code}`)
            : t("apiMessages.0")
        );
        setOpenNotificationModal(true);
        setIsLoading(false);
        setIsNewPictureUploaded(false);
      }
    );
  };

  const handleUpload = async (event) => {
    const data = event.target.files[0];
    if (data) {
      if (data.size > 26214400) {
        setNotificationMessage(t("errorMessages.fileSize"));
        setOpenNotificationModal(true);
        return;
      }
      if (data.type.indexOf(`image/jpeg`) === -1) {
        setNotificationMessage(t("errorMessages.fileJpg"));
        setOpenNotificationModal(true);
        return;
      }
      setInnerLoading(true);
      setIsNewPictureUploaded(true);
      const image: any = await ProceedImage.toBase64(data);
      setProfilePicture(image);
      setValue("image", image);
      setInnerLoading(false);
    }
  };

  const onAutocompleteChange = (value: any) => {
    setValue("community_id", value)
  }

  const getRole = () => {
    if (roleType === UserTypeEnum.owner) {
      return t("users.create.owner");
    } else if (roleType === UserTypeEnum.associatedOwner) {
      return t("users.create.associatedOwner");
    } else if (roleType === UserTypeEnum.chiefEditor) {
      return t("users.create.chiefEditor");
    } else if (roleType === UserTypeEnum.admin) {
      return t("users.create.admin");
    } else if (roleType === UserTypeEnum.editor) {
      return t("users.create.editor");
    } else {
      return t("users.create.user");
    }
  };

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<any>("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    cropImage(croppedAreaPixels);
  };

  const cropImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(profilePicture, croppedAreaPixels);
    setCroppedImage(croppedImage);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="main-content account-page">
      <h2 className="page-heading">{t("myAccount.title")}</h2>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((d) => handleSubmitCheck(d))}
      >
        <Card className="card-wrapper">
          <CardContent>
            <Typography variant="h6" className="card-heading">
              {t("myAccount.details")}
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={5} md={4}>
                <div className="form-field">
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          id="firstName"
                          label={t("signUp.fields.firstName.label")}
                          value={value}
                          onChange={onChange}
                        />
                        <p className="field-error">
                          {errors?.first_name?.message ?? ""}&nbsp;
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="form-field">
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          id="lastName"
                          label={t("signUp.fields.lastName.label")}
                          value={value}
                          onChange={onChange}
                        />
                        <p className="field-error">
                          {errors?.last_name?.message ?? ""}&nbsp;
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="form-field">
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          id="phone"
                          label={t("signUp.fields.phone.label")}
                          value={value}
                          onChange={(e) =>
                            onChange(formatToPhoneNumber(e.target.value, value))
                          }
                        />
                        <p className="field-error">
                          {errors?.phone?.message ?? ""}&nbsp;
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="form-field">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          id="email"
                          label={t("signUp.fields.email.label")}
                          value={value}
                          onChange={onChange}
                        />
                        <p className="field-error">
                          {errors?.email?.message ?? ""}&nbsp;
                        </p>
                      </>
                    )}
                  />
                </div>
                {(roleType === UserTypeEnum.owner ||
                  roleType === UserTypeEnum.associatedOwner ||
                  roleType === UserTypeEnum.admin) && (
                    <div className="form-field">
                      <Controller
                        control={control}
                        name="community_id"
                        render={({ field: { value } }) => (
                          <>
                              <SortedAutocomplete 
                                options={community}
                                value={value}
                                label={t("tabNavigation.community.mainTab")}
                                labelName={'communityName'}
                                valueName={'id'}
                                onChange={onAutocompleteChange}
                              />
                            <p className="field-error">
                              {errors?.community_id?.message ?? ""}&nbsp;
                            </p>
                          </>
                        )}
                      />
                    </div>
                  )}
                <div className="info-section">
                  <Typography variant="h5" className="info-section-title">
                    {t("common.fieldHeaders.type")}
                  </Typography>
                  <Typography variant="h4">{getRole()}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} xl={3}>
                <div className="image-upload">
                  <div className={"image-wrap"}>
                    {!isNewPictureUploaded ? (
                      <Avatar
                        alt="Avatar"
                        src={profilePicture || loggedUser.avatar}
                        className="avatar"
                      />

                    ) : (
                      <div className="avatar">
                        <Cropper
                          image={profilePicture || loggedUser.avatar}
                          crop={crop}
                          zoom={zoom}
                          zoomSpeed={0.1}
                          cropShape="round"
                          minZoom={0.05}
                          maxZoom={4}
                          restrictPosition={false}
                          objectFit="vertical-cover"
                          cropSize={{
                            width: 250,
                            height: 250,
                          }}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                      </div>
                    )}
                  </div>
                  <div className="proportion-container">
                    <span className="text">{t('common.proportions', { x: 1, y: 1 })}</span>
                  </div>
                  <div className="upload-wrapper">
                    <label
                      htmlFor={`fileUpload`}
                      className="flex-center flex-column"
                    >
                      <p className="hover-item">
                        {t("myAccount.uploadPicture")}
                      </p>
                    </label>
                    <input
                      id={`fileUpload`}
                      type="file"
                      name="file"
                      hidden={true}
                      onChange={handleUpload}
                    />
                    <p className="field-error">
                      {errors?.image?.message ?? ""}&nbsp;
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Button
          variant="contained"
          color="primary"
          className="uppercase"
          type="submit"
        >
          {t("common.buttons.save")}
        </Button>
      </form>
      <NotificationModal
        openModal={openNotificationModal}
        buttonText={t("common.buttons.close")}
        handleClose={() => setOpenNotificationModal(false)}
        handleButtonClick={() => setOpenNotificationModal(false)}
        message={notificationMessage}
      />
      {innerLoading ? <Spinner text={t("common.uploading")} /> : null}
    </div>
  );
};

export default observer(Account);
