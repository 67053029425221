import React from "react"
import ContentLoader from "react-content-loader"

const LatestActivityLoader = (props: any) => (
  <div className={`latest-activity-loader-wrapper fade-in ${!props.isLoading && 'visible'}`}>


  <ContentLoader 
    speed={2}
    width={500}
    height={50}
    viewBox="0 0 500 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    title={''}
  >
    <rect x="0" y="0" rx="100" ry="100" width="50" height="50" /> 
    <rect x="307" y="54" rx="3" ry="3" width="146" height="6" /> 
    <rect x="79" y="11" rx="3" ry="3" width="379" height="8" /> 
    <rect x="79" y="31" rx="3" ry="3" width="379" height="8" />
  </ContentLoader>





  </div>
)

export default LatestActivityLoader

