import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/StoreContext';
import './style.scss';
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import ArticleBox from '../../../components/Mobile/ArticleBox/ArticleBox';
import { flowResult } from 'mobx';
import Spinner from '../../../components/Spinner/Spinner';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getPopUpActionOptions } from '../../../shared/utils/sharedOptions';

const MyArticles = () => {
    const { i18n, t } = useTranslation();
    const {
        actionStore,
        languageStore,
        mobileStore,
        userStore,
        settingsStore
    } = useStore();
    const [lngDirection, setLngDirection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [actionType, setActionType] = useState('');
    const [potentialEditRowID, setPotentialEditRowID] = useState('');
    const [filter, setFilter] = useState('all');

    const [lockedList, setLockedList] = useState<any>([]);
    const [articleList, setArticleList] = useState<any>([]);
    const [approvalList, setApprovalList] = useState<any>([]);
    const [draftList, setDraftList] = useState<any>([]);
    const [archiveList, setArchiveList] = useState<any>([]);
    const [roleType, setRoleType] = useState(null);

    useEffect(() => {
        setRoleType(userStore.getRoleType());
        updateLists();
    }, [])

    // Detecting Language Switch
    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const updateArticles = async (params = {}) => {
        const articleParams = {
            is_archived: false,
            is_locked: false,
            is_draft: false,
            is_approved: true,
        }
        await flowResult(actionStore.getPathItems('articles', { ...params, ...articleParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArticleList(data)
            }
        )
    }

    const updateApprovalArticles = async (params = {}) => {
        const approvalParams = {
            is_approved: false,
            is_archived: false,
        }
        await flowResult(actionStore.getPathItems('articles', { ...params, ...approvalParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setApprovalList(data)
            }
        )
    }

    const updateLockedArticles = async (params = {}) => {
        const lockedParams = {
            is_archived: false,
            is_locked: true,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('articles', { ...params, ...lockedParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setLockedList(data)
            }
        )
    }

    const updateDraftArticles = async (params = {}) => {
        const draftParams = {
            is_draft: true,
        }
        await flowResult(actionStore.getPathItems('articles', { ...params, ...draftParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setDraftList(data)
            }
        )
    }

    const updateArchiveArticles = async (params = {}) => {
        const archiveParams = {
            is_archived: true,
        }
        await flowResult(actionStore.getPathItems('articles', { ...params, ...archiveParams })).then(
            (response: any) => {
                if (!response?.success) {

                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArchiveList(data)
            }
        )
    }

    const updateLists = async () => {
        setIsLoading(true);
        setArticleList([]);
        setApprovalList([]);
        setLockedList([]);
        setDraftList([]);
        setArchiveList([]);

        let params = {
            limit: 10
        };
        await Promise.all(
            [
                updateArticles(params),
                updateApprovalArticles(params),
                updateLockedArticles(params),
                updateDraftArticles(params),
                updateArchiveArticles(params),
            ]
        );
        setIsLoading(false)
    }

    const handleFlow = (flow) => {
        setIsLoading(true);
        flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : (response?.message ?? t("apiMessages.0")));
                    setIsLoading(false);
                    return;
                }
                updateLists();
            }
        );
    }

    const toggleLock = (id, toLock) => {
        const path = 'article/lock';
        handleFlow(actionStore.update({}, path, id));
    }

    const openActionMenu = (data) => (event) => {
        const id = data.id;
        setPotentialEditRowID(id);
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        const options = settingsStore.getUserPermissions().articles ? getPopUpActionOptions(data, roleType, userStore.getIsPermitted()) : ['view'];
        actionStore.handleOpenPopActionMenu(currentTarget.firstChild, '', data, options);
    };

    const toggleArchive = () => {
        const path = 'article/archive';
        handleFlow(actionStore.update({}, path, potentialEditRowID));
    }

    const handleDuplicate = () => {
        const path = 'article/duplicate';
        handleFlow(actionStore.update({}, path, potentialEditRowID));
    }

    const handleDelete = () => {
        const path = 'article';
        handleFlow(actionStore.delete(path, potentialEditRowID));
    }

    const handleNotificationOpen = (message = '', actionType = "") => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (actionType) {
            setButtonText(t('common.buttons.confirm'));
            setActionType(actionType);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (actionType = "") => () => {
        if (actionType === "isDelete") {
            handleDelete();
            setActionType("");
        }
        if (actionType === "isArchive") {
            toggleArchive();
            setActionType("");
        }
        setOpenNotificationModal(false);
    }

    const handleApprove = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.approveArticle(id)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        updateLists();
    }

    const handleReject = async (id) => {
        setIsLoading(true);
        await flowResult(mobileStore.rejectArticle(id)).then(
            (response: any) => {
                setIsLoading(false);
                handleNotificationOpen(
                    response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0')
                );
            }
        );
        updateLists();
    }

    return isLoading ? <Spinner /> : (
        <div className={`article-list ${!(lockedList.length || articleList.length || approvalList.length || draftList.length || archiveList.length) ? 'full-height' : ''}`}>
            {
                (lockedList.length || articleList.length || approvalList.length || draftList.length || archiveList.length) ? (
                    <>
                        <div className="select-filter-container">
                            <FormControl>
                                <InputLabel id="select-filter">{t('common.fieldHeaders.status')}</InputLabel>
                                <Select
                                    labelId="select-filter"
                                    id="select-filter"
                                    className="select-preview-filter"
                                    value={filter}
                                    onChange={(e: any) => setFilter(e.target.value)}
                                >
                                    <MenuItem value='all'>{t('common.selectFilters.allArticles')}</MenuItem>
                                    <MenuItem value='waitingApproval'>{t('common.fieldHeaders.waitingApproval')}</MenuItem>
                                    <MenuItem value='drafts'>{t('common.drafts')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {
                            (lockedList.length && filter === 'all') ? (
                                <div className="list-container">
                                    {
                                        lockedList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article?.thumbnail ?? article.image,
                                                            category: article.category.contentName,
                                                            header: article.header,
                                                            managedBy: article.managedBy,
                                                            writer: article.details.writer,
                                                            status: t('mobile.statuses.published'),
                                                            isLocked: article.isLocked,
                                                            isApproved: article.isApproved,
                                                            template: article.template,
                                                        }
                                                    }
                                                    toggleLock={toggleLock}
                                                    openActionMenu={openActionMenu(article)}
                                                    handleApprove={handleApprove}
                                                    handleReject={handleReject}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (articleList.length && filter === 'all') ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{convertToDate(articleList[0].publishDate, 'header')} <span>({articleList.length})</span></span>
                                    </div>
                                    {
                                        articleList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article?.thumbnail ?? article.image,
                                                            category: article.category.contentName,
                                                            header: article.header,
                                                            managedBy: article.managedBy,
                                                            writer: article.details.writer,
                                                            status: t('mobile.statuses.published'),
                                                            isLocked: article.isLocked,
                                                            isApproved: article.isApproved,
                                                            template: article.template,
                                                        }
                                                    }
                                                    toggleLock={toggleLock}
                                                    openActionMenu={openActionMenu(article)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (approvalList.length && (filter === 'all' || filter === 'waitingApproval')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('common.fieldHeaders.waitingApproval')} <span>({approvalList.length})</span></span>
                                    </div>
                                    {
                                        approvalList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            category: article.category.contentName,
                                                            header: article.header,
                                                            managedBy: article.managedBy,
                                                            writer: article.details.writer,
                                                            status: t('mobile.statuses.pending'),
                                                            isLocked: article.isLocked,
                                                            isApproved: article.isApproved,
                                                            template: article.template,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                    handleApprove={handleApprove}
                                                    handleReject={handleReject}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (draftList.length && (filter === 'all' || filter === 'drafts')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('common.fieldHeaders.draft')} <span>({draftList.length})</span></span>
                                    </div>
                                    {
                                        draftList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            category: article.category.contentName,
                                                            header: article.header,
                                                            managedBy: article.managedBy,
                                                            writer: article.details.writer,
                                                            status: t('users.create.draftStatus'),
                                                            isLocked: article.isLocked,
                                                            isApproved: article.isApproved,
                                                            template: article.template,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            (archiveList.length && (filter === 'all' || filter === 'drafts')) ? (
                                <div className="list-container">
                                    <div className="list-title">
                                        <span>{t('common.fieldHeaders.archived')} <span>({archiveList.length})</span></span>
                                    </div>
                                    {
                                        archiveList.map((article, index) => {
                                            return (
                                                <ArticleBox key={index}
                                                    className="element-faded"
                                                    article={
                                                        {
                                                            id: article.id,
                                                            image: article.image,
                                                            category: article.category.contentName,
                                                            header: article.header,
                                                            managedBy: article.managedBy,
                                                            writer: article.details.writer,
                                                            status: t('users.create.archivedStatus'),
                                                            isLocked: article.isLocked,
                                                            isApproved: article.isApproved,
                                                            isArchived: article.isArchived,
                                                            template: article.template,
                                                        }
                                                    }
                                                    openActionMenu={openActionMenu(article)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {
                            ((filter === 'waitingApproval' && !approvalList.length)
                                || (filter === 'drafts' && !draftList.length)) ? (
                                <div className="no-items-container">
                                    <p className="no-items">{t('mobile.articles.noContent')}</p>
                                </div>
                            ) : null
                        }
                    </>
                ) : (
                    <div className="no-items-container">
                        <p className="no-items">{t('mobile.articles.noContent')}</p>
                    </div>
                )
            }
            <PopUpActionMenu
                toggleArchive={toggleArchive}
                handleDuplicate={handleDuplicate}
                handleAction={handleNotificationOpen}
                currentPage="mobile/articles"
            />
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose(actionType)}
                buttonText={buttonText}
                message={notificationMessage}
            />
        </div>
    )
}

export default MyArticles
