import * as Yup from "yup";
import i18n from "i18next";

export const FIELDS = {
  ROLE: "role",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PHONE: "phone",
  EMAIL: "email",
  IMAGE: "image",
  COMMUNITY_ID: "community_id",
  // PASSWORD: "password",
  // REPEAT_PASSWORD: "repeat_password",
};

export const VALIDATIONS = Yup.object().shape({
  [FIELDS.FIRST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.account.minName"))
    .max(15, () => i18n.t("validationMessages.account.maxName"))
    .required(() => i18n.t("validationMessages.account.required")),
  [FIELDS.LAST_NAME]: Yup.string()
    .min(2, () => i18n.t("validationMessages.account.minName"))
    .max(15, () => i18n.t("validationMessages.account.maxName"))
    .required(() => i18n.t("validationMessages.account.required")),
  [FIELDS.PHONE]: Yup.string()
    .min(11, () => i18n.t("validationMessages.account.phone"))
    .max(11, () => i18n.t("validationMessages.account.phone"))
    .matches(RegExp(/^05[01234589]{1}\s\d{7}$/), () => i18n.t("validationMessages.auth.signUp.phonePattern"))
    .required(() => i18n.t("validationMessages.account.required")),
  [FIELDS.EMAIL]: Yup.string()
    .email(() => i18n.t("validationMessages.account.email"))
    .required(() => i18n.t("validationMessages.account.required")),
  [FIELDS.IMAGE]: Yup.string(),
  [FIELDS.COMMUNITY_ID]: Yup.string().required(),
  // [FIELDS.PASSWORD]: Yup.string().min(6).required(),
  // [FIELDS.REPEAT_PASSWORD]: Yup.string().min(6).required(),
});
