import {
    Button,
    FormControl,
    Grid,
    TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import UploadFile from '../../../components/UploadFile/UploadFile';
import "./style.scss";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIELDS, VALIDATIONS } from "./notificationFields";
import { flowResult } from "mobx";
import { useStore } from '../../../store/StoreContext';
import { useHistory } from 'react-router';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import UserTypeEnum from '../../../models/UserType';
import { useTranslation } from 'react-i18next';
import heLocale from "date-fns/locale/he";
import enLocale from "date-fns/locale/en-US";
import Spinner from '../../../components/Spinner/Spinner';
import ProceedImage from '../../../shared/utils/proceedImage';
import { useParams } from 'react-router-dom';
import { convertLocalToZuluTime, convertZuluToLocalTime, formatAppearsIds } from "../../../shared/utils/sharedFunctions";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

const ViewNotification = () => {
    const [isLoading, setIsLoading] = useState(true);
    const params: any = useParams();
    const id = params.id;
    const areFieldsDisabled = params.type === "view";
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const { groupStore, departmentStore, userStore, languageStore, pushStore } = useStore();
    const [image, setImage] = useState("");
    const [pushName, setPushName] = useState("");
    const [roleType, setRoleType] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [isSelectAllSelectedSecondary, setIsSelectAllSelectedSecondary] = useState(false);

    // GET DATA
    const [groups, setGroups] = useState<any>([]);
    const [departments, setDepartments] = useState<any>([]);
    const [secondaryDepartments, setSecondaryDepartments] = useState<any>([]);
    const [myCommunity, setMyCommunity] = useState<any>({});


    useEffect(() => {
        const storedGroups = groupStore.getStoredGroups();
        const storedDepartments = departmentStore.getStoredDepartments();
        const storedSecondaryDepartments = departmentStore.getStoredSecondaryDepartments();
        const roleType = userStore.getRoleType();
        setGroups(storedGroups);
        setDepartments(storedSecondaryDepartments);
        setSecondaryDepartments(secondaryDepartments)
        setRoleType(roleType);
        setMyCommunity(userStore.getCurrentCommunity());
        flowResult(pushStore.getNotificationByID(id)).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), true);
                    setIsLoading(false);
                    return;
                }
                const push = response.push;
                let appears = formatAppearsIds(push?.appears, roleType);
                appears = appears.filter((el, index) => appears.indexOf(el) === index);
                const groupOrDepartment = roleType === UserTypeEnum.owner ? storedGroups : [...storedDepartments, ...storedSecondaryDepartments];
                if(roleType !== UserTypeEnum.owner) {
                    setIsSelectAllSelected(appears.filter(depID => departments.some(dep => dep.id === depID)).length === departments.length);
                    setIsSelectAllSelectedSecondary(appears.filter(depID => secondaryDepartments.some(dep => dep.id === depID)).length === secondaryDepartments.length);
                } else {
                    setIsSelectAllSelected(appears.length === groupOrDepartment.length);
                }
                setValue("appears", appears.length === groupOrDepartment.length ? [...appears, 'select-all'] : appears);

                setValue('push_name', push?.pushName ?? '');
                setPushName(push?.pushName ?? '');
                setValue('image', push?.image ?? '');
                setImage(push?.image ?? '');
                setValue('link', push?.link ?? '');
                const publishDate = convertZuluToLocalTime(push.publishDate);
                setValue('publish_date', publishDate);
                setDateAndTime(new Date(publishDate));
                setHourAndMinutes({ hour: new Date(publishDate).getHours(), minutes: new Date(publishDate).getMinutes() });
                setIsLoading(false);
            }
        )
    }, [])

    // Notification Modal
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const handleFileUpload = async (data) => {
        if (data) {
            const image: any = await ProceedImage.toBase64(data);
            setImage(image);
            setValue('image', image)
            setShowImage(true);
        }
    }

    const convertToDate = (date) => {
        if (!date) {
            return;
        }
        const locale = 'en-US'
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
    } = useForm({
        resolver: yupResolver(VALIDATIONS),
        mode: 'onChange',
        defaultValues: {
            "push_name": "",
            "link": "",
            "image": "",
            "appears": [],
            'publish_date': convertToDate(new Date()),
            'publish_hour': convertToDate(new Date()),
        }
    } as any);

    useEffect(() => {
        Object.keys(FIELDS).map((field: string) => register(FIELDS[field]));
    }, [register]);

    const createNotification = async (data) => {
        setIsLoading(true);
        await flowResult(pushStore.update(data, id)).then(
            (response: any) => {
                if (!response?.success) {
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), false);
                    return;
                }
                handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t('apiMessages.0'), true)
            }
        )
        setIsLoading(false);
    }

    const transformToData = (objectData) => {
        let data = {
            "push_name": objectData.push_name,
            "image": objectData.image,
            "link": objectData.link,
            "appears": objectData.appears.filter((el) => el !== 'select-all'),
            "publish_date": convertLocalToZuluTime(dateAndTime),
        }
        if (!data?.link) {
            delete data["link"];
        }
        return data;
    }

    const handleSubmitCheck = (objectData) => {
        createNotification(transformToData(objectData));
    }

    const handleNotificationOpen = (message = '', isRedirect) => {
        setNotificationMessage(message);
        setIsRedirect(isRedirect);
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = () => {
        setOpenNotificationModal(false);
        if (isRedirect) {
            history.push('/push/promotional')
        }
    }

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
    }, [languageStore.switchLng])

    const [dateAndTime, setDateAndTime] = useState(new Date());
    const [hourAndMinutes, setHourAndMinutes] = useState({
        hour: 0,
        minutes: 0,
    });

    const changeDateOnly = (time) => {
        if (time instanceof Date) {
            const hours = hourAndMinutes.hour;
            const minutes = hourAndMinutes.minutes;
            if (!isNaN(hours) && !isNaN(minutes))
                setDateAndTime((date: any) => {
                    if (date) {
                        date = new Date(time);
                        date.setHours(hours, minutes);
                        const newDate = new Date(date)
                        return newDate;
                    }
                    return new Date();
                });
        }
    }


    const changeTimeOnly = (time) => {
        if (time instanceof Date) {
            if (!isNaN(time.getHours()) && !isNaN(time.getMinutes()))
                setDateAndTime((date: any) => {
                    if (date) {
                        date.setHours(time.getHours(), time.getMinutes());
                        const newDate = new Date(date)
                        setHourAndMinutes({ hour: time.getHours(), minutes: time.getMinutes() })
                        return newDate;
                    }
                    return new Date();
                });
        }
    }

    const onDepartmentSelectChange = (data, isSelectAll = false) => {
        const secondaries = getValues('appears').filter(depID => secondaryDepartments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...secondaries]);
        setIsSelectAllSelected(isSelectAll)
      }
      const onSecondaryDepartmentSelectChange = (data, isSelectAll = false) => {
        const deps = getValues('appears').filter(depID => departments.some(dep => dep.id === depID))
        setValue('appears', [...data, ...deps]);
        setIsSelectAllSelectedSecondary(isSelectAll);
      }

    return isLoading ? (<Spinner />) : (
        <div className="create-article notification-page">
            <div className="main-wrapper">
                <h2 className="page-header bolder-font">
                    {
                        areFieldsDisabled ? t('push.view') : t('push.edit')
                    }
                </h2>
                <div className="create-preview">
                    <form className="" autoComplete="off" onSubmit={handleSubmit((d) => handleSubmitCheck(d))}>
                        <div className="main-wrapper">
                            <h3>
                                {t('benefits.create.form.details')}
                            </h3>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lngDirection === 'rtl' ? heLocale : enLocale}>
                                <Grid container className="create-grid" justifyContent="space-between">
                                    <Grid item xs={5} lg={4}>
                                        <Grid container direction="column">
                                            <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                <Controller
                                                    control={control}
                                                    name="push_name"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField id="push-text"
                                                                label={t('common.fieldHeaders.pushNotificationText')}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e)
                                                                    setPushName(e.target.value)
                                                                }}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {
                                                                !areFieldsDisabled && (<p className="field-error">{errors?.push_name?.message ?? ''}&nbsp;</p>)
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                <Controller
                                                    control={control}
                                                    name="link"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <TextField id="link"
                                                                label={t('benefits.create.form.link')}
                                                                value={value}
                                                                onChange={onChange}
                                                                disabled={areFieldsDisabled}
                                                            />
                                                            {
                                                                !areFieldsDisabled && (<p className="field-error">{errors?.link?.message ?? ''}&nbsp;</p>)
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                {
                                                    !areFieldsDisabled ? (
                                                        <>
                                                            <UploadFile
                                                                text={t('common.fieldHeaders.uploadImage')}
                                                                handleFileUpload={handleFileUpload}
                                                                showImage={!!getValues('image')}
                                                                image={image}
                                                                width={100}
                                                                height={100}
                                                            />
                                                            <span className="information-message">{t("informationMessages.imageOnly")}</span>
                                                            <p className="field-error">{!showImage ? (errors?.image?.message ?? '') : ''}&nbsp;</p>
                                                        </>
                                                    ) : null
                                                }
                                            </Grid>
                                            <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                                <label className="field-label">{t('common.fieldHeaders.whoWillSeeAd')}</label>
                                                                {roleType !== UserTypeEnum.owner && <><br />
                                                                    <label className="field-label">{t('common.fieldHeaders.department')}</label></>}
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={roleType === UserTypeEnum.owner ? value : value.filter(depID => departments.some(dep => dep.id === depID))}
                                                                    hasError={!!errors?.appears?.message}
                                                                    isSelectAllSelected={isSelectAllSelected}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onDepartmentSelectChange}
                                                                    selectOptions={roleType === UserTypeEnum.owner ? groups : departments}
                                                                    isDisabled={
                                                                        areFieldsDisabled ||
                                                                        (roleType === UserTypeEnum.owner &&
                                                                            !groups.length) ||
                                                                        (roleType !== UserTypeEnum.owner &&
                                                                            !departments.length)
                                                                    }
                                                                />
                                                                {
                                                                    !areFieldsDisabled && ((
                                                                        (roleType === UserTypeEnum.owner && groups.length)
                                                                        ||
                                                                        (roleType !== UserTypeEnum.owner && departments.length)
                                                                    ) ? (
                                                                        <>
                                                                            {/* @ts-ignore */}
                                                                            <p className="field-error">{errors?.appears?.message ?? ''}&nbsp;</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className="field-error">{roleType === UserTypeEnum.owner ? t('errorMessages.articles.create.noGroups') : t('errorMessages.articles.create.noDepartments')}</p>
                                                                    ))
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {roleType !== UserTypeEnum.owner && secondaryDepartments.length > 0 && <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="appears"
                                                        render={({ field: { onChange, value } }) => (
                                                            <>
                                                            <label className="field-label">{t('common.fieldHeaders.secondaryDepartment')}</label>
                                                                <MultiSelect
                                                                    showSelectAll
                                                                    value={value.filter(depID => secondaryDepartments.some(dep => dep.id === depID))}
                                                                    isSelectAllSelected={isSelectAllSelectedSecondary}
                                                                    mapBy="id"
                                                                    renderedName="name"
                                                                    onChange={onSecondaryDepartmentSelectChange}
                                                                    selectOptions={secondaryDepartments}
                                                                    isDisabled={
                                                                        areFieldsDisabled ||
                                                                        (roleType !== UserTypeEnum.owner &&
                                                                            !secondaryDepartments.length)
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>}
                                            <Grid item xs={12} className={`${areFieldsDisabled ? 'grid-row' : ''}`}>
                                                <p>{t('push.create.whenToSendNotification')}</p>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={7}>

                                                        <KeyboardDatePicker
                                                            // {... !areFieldsDisabled ? { minDate: today } : {}}
                                                            minDateMessage={t('errorMessages.minimumDate')}
                                                            value={convertToDate(dateAndTime)}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            id="publishDate-picker-inline"
                                                            className="date-picker-inline"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            autoOk={true}
                                                            onChange={(value: any) => {
                                                                changeDateOnly(value)
                                                            }}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} lg={4}>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            okLabel={t("timePicker.confirm")}
                                                            cancelLabel={t("timePicker.cancel")}
                                                            value={dateAndTime}
                                                            onChange={(value: any) => {
                                                                changeTimeOnly(value)
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'Hour',
                                                            }}
                                                            disabled={areFieldsDisabled}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5} lg={4}>
                                        <div className="preview-notification-container">
                                            <Grid container justifyContent="space-between" className="preview-header">
                                                <Grid item className="preview-headline" lg={5} xl={4}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3} lg={2}>
                                                            <div className={`preview-image`}>
                                                                <img
                                                                    src={image || ImagePlaceholder}
                                                                    alt={image || ImagePlaceholder}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <span className="preview-message-text">{t('push.create.messages')}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className="preview-when flex-center" xs={1}>
                                                    <span>{t('common.fieldHeaders.now')}</span>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="preview-footer">
                                                <Grid item xs={12}>
                                                    <div><strong>{myCommunity?.communityName ?? t('push.myCommunity')}</strong></div>
                                                    <div><span>{pushName || t('push.create.yourMessage')}</span></div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                        {
                            !areFieldsDisabled ? (
                                <div className="submit-buttons">
                                    <Button variant="contained" color="primary" type="submit">{t('common.buttons.save')}</Button>
                                </div>
                            ) : null
                        }

                    </form>
                </div>
            </div>
            <NotificationModal openModal={openNotificationModal} buttonText={t('common.buttons.close')} handleClose={handleNotificationClose} handleButtonClick={handleNotificationClose} message={notificationMessage} />
        </div>
    )
}

export default ViewNotification
