import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import LockIcon from "../../../assets/icons/lock.svg";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from "@material-ui/data-grid";
import "./style.scss";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NoAds from "../../../assets/images/articles/noAds.svg";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import { useStore } from '../../../store/StoreContext';
import { Button, Checkbox, IconButton } from '@material-ui/core';
import { flowResult } from 'mobx';
import NotificationModal from '../../../components/NotificationModal/NotificationModal';
import Spinner from '../../../components/Spinner/Spinner';
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import { observer } from 'mobx-react-lite';
import UserTypeEnum from '../../../models/UserType';
import { useTranslation } from 'react-i18next';
import PreviewModal from '../../../components/PreviewModal/PreviewModal';
import { Link } from 'react-router-dom';
import DragAndDropTable from '../../../components/DragAndDropTable/DragAndDropTable';
import { getPopUpActionOptions } from '../../../shared/utils/sharedOptions';
import { formatAppearsIds } from '../../../shared/utils/sharedFunctions';
import { useNotificationModal } from '../../../components/NotificationModal/useNotificationModal';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 'fit-content',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useColumsState = () => {

}

const selectColumn: (list: any, setList: any, activity: boolean, setActivity: any) => GridColDef = (list, setList, activity, setActivity) => ({
	field: "select",
	headerName: "",
	sortable: false,
	width: 90,
	headerClassName: "d-flex justify-center align-center",
	renderHeader: () => {
			return (
					<IconButton
							aria-label="select all items"
							onClick={() => {
								const newList = list.map((el) => { return { ...el, isSelected: !activity } })
								setList(newList);
								setActivity(!activity);
						}}
					>
							<Checkbox
									checked={activity}
									color="primary"
									className="select-all-checkbox"
							/>
					</IconButton>
			);
	},
	renderCell: (params: GridCellParams) => {
			let row = params.row;
			return (
					<IconButton
							aria-label="select item"
							onClick={() =>  {
								const newList = list.map((el) => (row.id === el.id) ? { ...el, isSelected: !el.isSelected } : el)
								setList(newList) //reset the list of article to show the selected
								if (activity) setActivity(false) //disable the select all checkbox
							}}
					>
							<Checkbox
									checked={row?.isSelected ?? false}
									color="primary"
									className="select-all-checkbox"
							/>
					</IconButton>
			);
	},
});

const Ads = () => {
    const { i18n, t } = useTranslation();
    const {
        adStore,
        userStore,
        actionStore,
        languageStore,
        settingsStore
    } = useStore();
    const classes = useStyles();
    const [comFilter, setComFilter] = useState('all');

    const [communities, setCommunities] = useState<any>([]);

    const [lockedList, setLockedList] = useState<Array<Object>>([]);
    const [articleList, setArticleList] = useState<Array<Object>>([]);
    const [draftList, setDraftList] = useState<Array<Object>>([]);
    const [archiveList, setArchiveList] = useState<Array<Object>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [actionType, setActionType] = useState('');
    const [roleType, setRoleType] = useState(null);

		const {element : ModalPopup, open: openModalPopup} = useNotificationModal();

		const [lockedCheckboxIsSelected, setLockedCheckboxIsSelected] = useState(false);
		const [listCheckboxIsSelected, setListCheckboxIsSelected] = useState(false);
		const [archivedListCheckboxIsSelected, setArchivedListCheckboxIsSelected] = useState(false);

    // Pagination D&D Table
    const [totalActiveArticles, setTotalActiveArticles] = useState(0);
    const rowsPerPage = 50;

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const updateArticles = async (params = {}) => {
        const articleParams = {
            is_archive: false,
            is_locked: false,
            is_draft: false,
            limit: rowsPerPage * 2,
        }
        await flowResult(actionStore.getPathItems('ads', { ...params, ...articleParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.ads;
                setArticleList(data);
                setTotalActiveArticles(response.pagination.total);
            }
        )
    }

    const updateArchivedArticles = async (params = {}) => {
        const archivedParams = {
            is_archive: true,
            is_locked: false,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('ads', { ...params, ...archivedParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.ads;
                setArchiveList(data)
            }
        )
    }

    const updateLockedArticles = async (params = {}) => {
        const lockedParams = {
            is_archive: false,
            is_locked: true,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('ads', { ...params, ...lockedParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.ads;
                setLockedList(data)
            }
        )
    }

    const updateDraftArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_archive']
        delete incomingParams['is_locked']
        const draftParams = {
            is_draft: true,
        }
        await flowResult(actionStore.getPathItems('ads', { ...incomingParams, ...draftParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.ads;
                setDraftList(data)
            }
        )
    }

    const updateLists = async (params: any = {}) => {
        setIsLoading(true);
        setArticleList([]);
        setArchiveList([]);
        setLockedList([]);
        setDraftList([]);

        const searchParams = actionStore.getSearchParams();
        params = {
            ...params,
            is_archive: true,
            is_locked: true,
            is_draft: true,
            ...searchParams,
            limit: 10
        };
        let promises: any = [];
        if (!params.is_archived || (params.is_archived && params.is_locked && params.is_draft)) {
            promises.push(updateArticles(params));
        }
        if (params.is_archive) {
            promises.push(updateArchivedArticles(params));
        }
        if (params.is_locked) {
            promises.push(updateLockedArticles(params));
        }
        if (params.is_draft) {
            promises.push(updateDraftArticles(params));
        }
        await Promise.all(promises);
        setIsLoading(false)
    }

    const handleFlow = async (flow) => {
        setIsLoading(true);

        const response : any = await flowResult(flow);

        if (!response?.success) {
            handleNotificationOpen(response?.code ? t(`apiMessages.${response.code}`) : (response?.message ?? t("apiMessages.0")));
            setIsLoading(false);
            return;
        }
				
        updateLists();
    }

    useEffect(() => {
        setCommunities(userStore.getCommunities());
        setRoleType(userStore.getRoleType());
        updateLists();
    }, []);

    useEffect(() => {
        if (actionStore.triggerUpdateList) {
            updateLists();
            actionStore.triggerUpdateList = false;
        }
    }, [actionStore.triggerUpdateList])

    const handleNotificationOpen = (message = '', actionType = "") => {
        setButtonText(t('common.buttons.close'));
        setNotificationMessage(message);
        if (actionType) {
            setButtonText(t('common.buttons.confirm'));
            setActionType(actionType);
        }
        setOpenNotificationModal(true);
    }

    const handleNotificationClose = (actionType = "") => () => {
        if (actionType === "isDelete") {
            handleDelete();
            setActionType("");
        }
        if (actionType === "isArchive") {
            toggleArchive();
            setActionType("");
        }
        setOpenNotificationModal(false);
    }

    const handleFilterCommunity = (event) => {
        const value = event.target.value;
        setComFilter(value);
        let filter = {};
        if (value !== 'all') {
            filter['community'] = value
        }
        updateLists(filter)
    }

    const openActionMenu = (data) => (event) => {
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        const options = settingsStore.getUserPermissions().ads ? getPopUpActionOptions(data, roleType, userStore.getIsPermitted()) : ['view'];
        actionStore.handleOpenPopActionMenu(currentTarget.firstChild, `ad`, data, options);
    };

    const toggleArchive = async (id? : any) => {
        const path = 'ads/archive';
        return handleFlow(actionStore.update({}, path, id ?? actionStore.editRowData.id));
    }

    const handleDuplicate = async (id? : any) => {
        const path = 'ads/duplicate';
        return handleFlow(actionStore.update({}, path, id ?? actionStore.editRowData.id));
    }

    const handleDelete = async (id? : any) => {
        const path = 'ads';
        return handleFlow(actionStore.delete(path, id ?? actionStore.editRowData.id ));
    }

    const toggleLock = async (id, toLock) => {
        const path = 'ads/lock';
        return handleFlow(actionStore.update({}, path, id));
    }

    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    // Preview Modal
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => () => {
        setOpenModal(false);
    }

    const reverseDirection = (columns) => {
        const array = [];
        columns.forEach(el => {
            array.unshift(el)
        });
        return array
    }

    const handleReorder = async (objectData) => {
        const data = {
            template: 0,
            header: objectData.header,
            sub_header: objectData.sub_header,
            image: objectData.image,
            cta_text: objectData.cta_text,
            appears: formatAppearsIds(objectData.appears, roleType),
            publish_date: objectData.publishDate,
            archive_date: objectData.archiveDate,
            details: {
                link: objectData.details.link,
            },
            is_draft: false,
        };
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el] && el !== 'is_draft') delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        let error = false;
        await flowResult(adStore.update(data, objectData.id)).then((response: any) => {
            if (!response?.success) {
                handleNotificationOpen(
                    response?.code
                        ? t(`apiMessages.${response.code}`)
                        : t("apiMessages.0")
                );
                error = true;
                return;
            }
        });
        return error;
    }

    const columns: GridColDef[] = [
        {
            field: 'bannerAction',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div style={{ pointerEvents: 'none' }} />
                )
            },
            renderCell: (params: GridCellParams) => {
                const id = params.row.id;
                const isLocked = params.row.isLocked;
                const showLock = !(params.row.isArchived || params.row.isDraft) && (roleType === UserTypeEnum.owner || roleType === UserTypeEnum.associatedOwner);
                return showLock ? (
                    <div className={`banner-action ${!isLocked ? 'margin' : ''} text-center`}
                        onClick={() => toggleLock(id, !isLocked)}>
                        {
                            !isLocked && (
                                <LockOpenIcon />
                            )
                        }
                        {
                            isLocked && (
                                <IconComponent icon={LockIcon} />
                            )
                        }
                    </div>
                ) : <></>
            },
        },
        {
            field: 'image',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div style={{ pointerEvents: 'none' }} />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.image;
                return (
                    <img className={`table-row-image`} src={row ?? ImagePlaceholder} alt={row} />
                );
            },
        },
        {
            field: 'header',
            headerName: t('common.fieldHeaders.header'),
            sortable: true,
            flex: 1,
            minWidth: 150,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className={`table-row-title`}>{params.row.header}</span>
                );
            },
        },
        {
            field: 'managedBy ',
            headerName: t('common.fieldHeaders.managedBy'),
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row?.managedBy ?? '';
                return (<span>{row}</span>)
            },
            width: 200
        },
        {
            field: 'publishDate',
            headerName: t('common.fieldHeaders.from'),
            sortable: true,
            width: 155,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className={`table-row-date`}>{convertToDate(params.row.publishDate)}</span>
                );
            },
        },
        {
            field: 'archiveDate',
            headerName: t('common.fieldHeaders.to'),
            sortable: true,
            width: 155,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className={`table-row-date`}>{convertToDate(params.row.archiveDate)}</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            width: 100,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column text-center`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                return (
                    <div id={`grid-row-${data.id}`} className={`table-row-actions cell-actions`}
                        onClick={openActionMenu(data)}>
                        <MoreHorizIcon
                            aria-controls={`actions-menu`}
                            aria-haspopup="true"
                            className="table-row-actionIcon"
                        />
                    </div>
                );
            },
        },
    ].map((column) => (
        {
            ...column,
            disableClickEventBubbling: true,
        }));

		const reversedColumns =	lngDirection === "ltr" ? columns : reverseDirection(columns)

		const lockedColumns = [...reversedColumns, selectColumn(lockedList, setLockedList, lockedCheckboxIsSelected, setLockedCheckboxIsSelected)];
		const articleColumns = [ selectColumn(articleList, setArticleList, listCheckboxIsSelected, setListCheckboxIsSelected), ...reverseDirection(reversedColumns)];
		const archivedColumns = [...reversedColumns, selectColumn(archiveList, setArchiveList, archivedListCheckboxIsSelected, setArchivedListCheckboxIsSelected)];

		const applyOnSelected = (action : (id : any) => Promise<void>, list : Array<Object>) => {
				const promises = list.filter((el : any) => el?.isSelected).map((el: any) => action(el.id))
        Promise.all(promises).then(updateLists);
		}

    return isLoading ? (<Spinner />) : (
        <div className="main-wrapper">
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('articles.ads.title')}
                </h2>
                <div className="flex-center">
                    {
                        (roleType === UserTypeEnum.owner) && (
                            <>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        labelId="select-filter"
                                        id="select-filter"
                                        className="select-preview-filter"
                                        value={comFilter}
                                        onChange={handleFilterCommunity}
                                    >
                                        <MenuItem value='all'>{t('common.selectFilters.allCommunities')}</MenuItem>
                                        {
                                            communities.map((el, index) => {
                                                return (
                                                    <MenuItem key={index} value={el.id}>{el.communityName}</MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </>
                        )
                    }
                    {
                        lockedList.concat(articleList).length ? (
                            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                                {t('common.buttons.preview')}
                            </Button>
                        ) : null
                    }
                </div>
            </div>
            <div className="rotatingBanner-page">
                {
                    (articleList.length || archiveList.length || lockedList.length || draftList.length) ? (
                        <>
                            {
                                lockedList.length > 0 && (
                                    <div >
																				<div style={{display: "flex", justifyContent : "flex-end", marginBottom: "15px"}}>
                                          <Button
                                              className="link-button-container"
																							onClick={() => openModalPopup({
																								message: t("common.archiveItems"),
																								buttonText: t("common.buttons.confirm"),
																								onOk: () => { applyOnSelected(toggleArchive, lockedList); setLockedCheckboxIsSelected(false); }
																							})}
                                              size="large"
                                              variant="contained"
                                              color="primary"
                                          >
                                              {t("common.fieldHeaders.moveToArchive")}
                                          </Button>
                                        </div>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                pagination
                                                autoHeight={true}
                                                rows={lockedList}
                                                columns={lockedColumns}
                                                rowHeight={90}
                                                headerHeight={56}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                articleList.length > 0 && (
                                    <div>
																				<div style={{display: "flex", justifyContent : "space-between"}}>
                                        		<h3>{t('common.active')} ({totalActiveArticles})</h3>
                                        		<Button
                                              className="link-button-container"
                                              onClick={() => openModalPopup({
																								message: t("common.archiveItems"),
																								buttonText: t("common.buttons.confirm"),
																								onOk: () => {applyOnSelected(toggleArchive, articleList); setArchivedListCheckboxIsSelected(false); }
																							})}
                                              size="large"
                                              variant="contained"
                                              color="primary"
                                          	>
                                            	{t("common.fieldHeaders.moveToArchive")}
                                          	</Button>
                                        </div>
                                        <div className={`table-grid drag-and-drop-table`}>
                                        	<DragAndDropTable
                                                rows={articleList}
                                                columns={ articleColumns }
                                                onReorder={handleReorder}
                                                pagination
                                                rowsPerPage={rowsPerPage}
                                                totalRows={totalActiveArticles}
                                                pageType='ads'
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                draftList.length > 0 && (
                                    <div>
                                        <h3>{t('common.drafts')} ({draftList.length})</h3>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={draftList}
                                                columns={columns}
                                                rowHeight={90}
                                                headerHeight={0}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                archiveList.length > 0 && (
                                    <div>
																				<div style={{display: "flex", justifyContent : "space-between"}}>
																						<h3>{t('common.archive')}</h3>
                                        		<Button
                                              className="link-button-container"
																							onClick={() => openModalPopup({
																								message: t("common.deleteItems"),
																								buttonText: t("common.buttons.confirm"),
																								onOk: () => {applyOnSelected(handleDelete, archiveList); setArchivedListCheckboxIsSelected(false);}
																							})}
                                              size="large"
                                              variant="contained"
                                              color="primary"
                                          	>
                                            	{t("common.buttons.delete")}
                                          	</Button>
                                        </div>
                                        <div className={`table-grid element-faded`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={archiveList}
                                                columns={archivedColumns}
                                                rowHeight={90}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                <>
                                    <PopUpActionMenu toggleArchive={toggleArchive}
                                        handleDuplicate={handleDuplicate}
                                        handleAction={handleNotificationOpen}
                                        currentPage="articles" />
                                    {
                                        lockedList.concat(articleList).length ? (
                                            <PreviewModal className="create-article ad list-preview"
                                                openModal={openModal} handleClose={handleCloseModal}
                                                hideNotYet content={
                                                    <div
                                                        className="scroll-container">{lockedList.concat(articleList).map((article: any, index) => {
                                                            const image = article?.thumbnail ?? article?.image ?? '';
                                                            return (
                                                                <div key={index} className="preview-image-container">
                                                                    <div className={`preview-image ads`}>
                                                                        <img
                                                                            src={image}
                                                                            alt={article?.header ?? ''}
                                                                        />
                                                                    </div>
                                                                    <div className="preview-text ads">
                                                                        <p className="preview-header">{article?.header || t('articles.allArticles.create.form.preview.headerGoes')}</p>
                                                                        <p className="preview-header sub-header">{article?.subHeader || t('articles.ads.create.form.preview.subHeaderGoes')}</p>
                                                                        <div className="preview-category">
                                                                            <span>{article?.ctaText || t('common.fieldHeaders.ctaText')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}</div>
                                                } />
                                        ) : null
                                    }
                                </>
                            }
                        </>
                    ) : <></>
                }

                {
                    !(articleList.length || archiveList.length || lockedList.length || draftList.length) ? (
                        <div className="no-ads">
                            <img src={NoAds} alt="" />
                            <div>
                                <h3>{t('articles.ads.noArticles.message1')}</h3>
                                <Link to="/articles/create/ad"
                                    onClick={() => actionStore.toggleSearch(false)}>{t('articles.ads.noArticles.message2')}</Link>
                            </div>
                        </div>
                    ) : <></>
                }
            </div>
            <NotificationModal
                openModal={openNotificationModal}
                handleClose={handleNotificationClose()}
                handleButtonClick={handleNotificationClose(actionType)}
                buttonText={buttonText}
                message={notificationMessage}
            />
						<ModalPopup/>
        </div>
    )
}

export default observer(Ads)
