import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import IconComponent from "../../../shared/components/IconComponent/IconComponent";
import LockIcon from "../../../assets/icons/lock.svg";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from "@material-ui/data-grid";
import "./style.scss";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NoBanners from "../../../assets/images/articles/noBanners.svg";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PopUpActionMenu from '../../../components/PopUpActionMenu/PopUpActionMenu';
import { useStore } from '../../../store/StoreContext';
import { Button, Checkbox, IconButton } from '@material-ui/core';
import { flowResult } from 'mobx';
import Spinner from '../../../components/Spinner/Spinner';
import ImagePlaceholder from "../../../assets/images/image-placeholder.png";
import { observer } from 'mobx-react-lite';
import UserTypeEnum from '../../../models/UserType';
import { useTranslation } from 'react-i18next';
import PreviewModal from '../../../components/PreviewModal/PreviewModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import SwiperCore, {
    Pagination, Navigation
} from 'swiper';
import DragAndDropTable from '../../../components/DragAndDropTable/DragAndDropTable';
import { getPopUpActionOptions } from '../../../shared/utils/sharedOptions';
import { MANAGEMENT } from '../../../shared/constants';
import { formatAppearsIds } from '../../../shared/utils/sharedFunctions';
import { useNotificationModal } from '../../../components/NotificationModal/useNotificationModal';


SwiperCore.use([Pagination, Navigation]);


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 'fit-content',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const selectColumn : (allSelected: boolean, handleSelect : (id: any) => void, handleSelectAll : () => void) => GridColDef = (allSelected, handleSelect, handleSelectAll) => ({
	field: "select",
	headerName: "",
	sortable: false,
	width: 90,
	headerClassName: "d-flex justify-center align-center",
	renderHeader: () => {
			return (
					<IconButton
							aria-label="select all items"
							onClick={handleSelectAll}
					>
							<Checkbox
									checked={allSelected}
									color="primary"
									className="select-all-checkbox"
							/>
					</IconButton>
			);
	},
	renderCell: (params: GridCellParams) => {
			let row = params.row;
			return (
					<IconButton
							aria-label="select item"
							onClick={() => handleSelect(row.id)}
					>
							<Checkbox
									checked={row?.isSelect ?? false}
									color="primary"
									className="select-all-checkbox"
							/>
					</IconButton>
			);
	},
});

const RotatingBanner = () => {
    const { i18n, t } = useTranslation();
    const {
        contentStore,
        userStore,
        actionStore,
        languageStore,
        bannerStore,
        settingsStore,
    } = useStore();
    const classes = useStyles();
    const [comFilter, setComFilter] = useState('all');
    const [catsFilter, setCatsFilter] = useState('all');
    const [roleType, setRoleType] = useState(null);
    const carouselSlides = [1, 2, 3, 4, 5, 6];

    const [categories, setCategories] = useState([{
        id: '',
        contentName: '',
    }]);

    const [communities, setCommunities] = useState<any>([]);

    const [lockedList, setLockedList] = useState<Array<Object>>([]);
    const [articleList, setArticleList] = useState<Array<Object>>([]);
    const [draftList, setDraftList] = useState<Array<Object>>([]);
    const [archiveList, setArchiveList] = useState<Array<Object>>([]);
    const [isLoading, setIsLoading] = useState(true);
		const [isSelectAllArticles, setIsSelectAllArticles] = useState(false);
		const [isSelectAllArchive, setIsSelectAllArchive] = useState(false);

		const {element : Modal, open: openModalNotify} = useNotificationModal();

    // Pagination D&D Table
    const [totalActiveArticles, setTotalActiveArticles] = useState(0);
    const rowsPerPage = 50;

    const convertToDate = (date, type = '') => {
        if (!date) {
            return;
        }
        date = new Date(date);
        const locale = lngDirection === 'rtl' ? 'he-IL' : 'en-US';
        if (type === 'header') {
            const option1 = { month: 'long' };
            const option2 = { year: 'numeric' };
            return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)}`
        }
        const option1 = { day: 'numeric' };
        const option2 = { month: 'long' };
        const option3 = { year: 'numeric' };
        return `${date.toLocaleDateString(locale, option1)} ${date.toLocaleDateString(locale, option2)} ${date.toLocaleDateString(locale, option3)}`
    }

    const updateArticles = async (params = {}) => {
        const articleParams = {
            is_archived: false,
            is_locked: false,
            is_draft: false,
            limit: rowsPerPage * 2,
        }
        await flowResult(actionStore.getPathItems('rotating-banners', { ...params, ...articleParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    openModalForAction(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArticleList(data);
                setTotalActiveArticles(response.pagination.total);
            }
        )
    }

    const updateArchivedArticles = async (params = {}) => {
        const archivedParams = {
            is_archived: true,
            is_locked: false,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('rotating-banners', { ...params, ...archivedParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    openModalForAction(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setArchiveList(data)
            }
        )
    }

    const updateLockedArticles = async (params = {}) => {
        const lockedParams = {
            is_archived: false,
            is_locked: true,
            is_draft: false,
        }
        await flowResult(actionStore.getPathItems('rotating-banners', { ...params, ...lockedParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    openModalForAction(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setLockedList(data)
            }
        )
    }

    const updateDraftArticles = async (params = {}) => {
        const incomingParams = { ...params };
        delete incomingParams['is_archived']
        delete incomingParams['is_locked']
        const draftParams = {
            is_draft: true,
        }
        await flowResult(actionStore.getPathItems('rotating-banners', { ...incomingParams, ...draftParams })).then(
            (response: any) => {
                if (!response?.success) {
                    setIsLoading(false);
                    openModalForAction(response?.code ? t(`apiMessages.${response.code}`) : t("apiMessages.0"));
                    return;
                }
                const data = response.articles;
                setDraftList(data)
            }
        )
    }

    const updateLists = async (params: any = {}) => {
        setIsLoading(true);
        setArticleList([]);
        setArchiveList([]);
        setLockedList([]);
        setDraftList([]);

        const searchParams = actionStore.getSearchParams();
        params = {
            ...params,
            is_archived: true,
            is_locked: true,
            is_draft: true,
            ...searchParams,
            limit: 10
        };
        let promises: any = [];
        if (!params.is_archived || (params.is_archived && params.is_locked && params.is_draft)) {
            promises.push(updateArticles(params));
        }
        if (params.is_archived) {
            promises.push(updateArchivedArticles(params));
        }
        if (params.is_locked) {
            promises.push(updateLockedArticles(params));
        }
        if (params.is_draft) {
            promises.push(updateDraftArticles(params));
        }
        await Promise.all(promises);
        setIsLoading(false)
    }

    const handleFlow = (flow) => {
        setIsLoading(true);
        flowResult(flow).then(
            (response: any) => {
                if (!response?.success) {
									openModalForAction(response?.code ? t(`apiMessages.${response.code}`) : (response?.message ?? t("apiMessages.0")));
                    setIsLoading(false);
                    return;
                }
                updateLists();
            }
        );
    }

    useEffect(() => {
        setCommunities(userStore.getCommunities());
        setCategories(contentStore.getStoredContents());
        setRoleType(userStore.getRoleType());
        updateLists();
    }, []);

    useEffect(() => {
        if (actionStore.triggerUpdateList) {
            updateLists();
            actionStore.triggerUpdateList = false;
        }
    }, [actionStore.triggerUpdateList])

		const handleAction = (action, listToFilter) => {
			const list = listToFilter.filter((el: any) => el.isSelect);
			const promises = [list.map((el: any) => action(el.id))]
			Promise.all(promises).then(() => setTimeout(() => updateLists(), 2000))
		};

		const openModalForAction = (message : string = "", actionType : string = "") => {

			const onOkModal = () => {
				
				if (actionType === "isArchive") {
					toggleArchive();
				}

				if (actionType === "archiveSelected") {
					handleAction(toggleArchive, articleList);
					setIsSelectAllArticles(false);
				}

				if (actionType === "isDelete") {
					handleDelete();
				}

				if (actionType === "deleteSelected") {
					handleAction(handleDelete, archiveList);
					setIsSelectAllArchive(false);
				}
			}

			openModalNotify({
					message,
					buttonText: actionType ? t('common.buttons.confirm') : t('common.buttons.close'),
					onOk: onOkModal,
				})
		}

		const handleArchiveSelected = () => {
			const selected = articleList.filter((el: any) => el?.isSelect);

			if (selected.length) {
				openModalForAction(t("errorMessages.archiveItems"), 'archiveSelected');
			} else {
				openModalForAction(t("errorMessages.needToSelect"));
			}
		}

		const handleDeleteSelected = () => {
			
			const selected = archiveList.filter((el: any) => el?.isSelect);

      if (selected.length) {
        openModalForAction(t("errorMessages.deleteItems"), 'deleteSelected');
      } else {
        openModalForAction(t("errorMessages.needToSelect"));
      }
		}

    const openActionMenu = (data) => (event) => {
        const currentTarget = event.currentTarget;
        const rowElement = currentTarget.parentElement.parentElement;
        actionStore.editRowWidth = rowElement.clientWidth;
        const options = settingsStore.getUserPermissions().banners ? getPopUpActionOptions(data, roleType, userStore.getIsPermitted()) : ['view'];
        actionStore.handleOpenPopActionMenu(currentTarget.firstChild, `banner`, data, options);
    };

    const handleDuplicate = () => {
        const path = 'rotating-banners/duplicate';
        handleFlow(actionStore.update({}, path, actionStore.editRowData.id));
    }

    const toggleArchive = (id = '') => {
			const path = 'rotating-banners/archive';
			const idToUpdate = id ? id : actionStore.editRowData.id
			handleFlow(actionStore.update({}, path, idToUpdate));
	}

    const handleDelete = (id = '') => {
        const path = 'rotating-banners';
				const idToUpdate = id ? id : actionStore.editRowData.id
        handleFlow(actionStore.delete(path, idToUpdate));
    }

    const toggleLock = (id, toLock) => {
        const path = 'rotating-banners/lock';
        handleFlow(actionStore.update({}, path, id));
    }

    const handleFilterCommunity = (event) => {
        const value = event.target.value;
        setComFilter(value);
        let filter = {};
        if (value !== 'all') {
            filter['community'] = value
        }
        if (catsFilter !== 'all') {
            filter['category'] = catsFilter
        }
        updateLists(filter)
    }

    const handleFilterCategory = (event) => {
        const value = event.target.value;
        setCatsFilter(value);
        let filter = {};
        if (comFilter !== 'all') {
            filter['community'] = comFilter
        }
        if (value !== 'all') {
            filter['category'] = value
        }
        updateLists(filter)
    }


    // Detecting Language Switch
    const [lngDirection, setLngDirection] = useState('');

    useEffect(() => {
        const dir = i18n.dir();
        setLngDirection(dir);
        // Reversing the order of Table Grids for Hebrew
        if (dir === "rtl") {
            setTimeout(() => {
                document.querySelectorAll('.MuiDataGrid-window').forEach(el => {
                    const width = el.scrollWidth;
                    el.scrollTo(width, 0)
                })
            })
        }
    }, [languageStore.switchLng, isLoading === false])

    // Preview Modal
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => () => {
        setOpenModal(false);
    }

    const reverseDirection = (columns) => {
        const array = [];
        columns.forEach(el => {
            array.unshift(el)
        });
        return array
    }

    const handleReorder = async (objectData) => {
        const data = {
            template: objectData.template,
            header: objectData.header,
            category_id: objectData.category_id,
            image:
                objectData.image,
            appears: formatAppearsIds(objectData.appears, roleType),
            publish_date: objectData.publishDate,
            archive_date: objectData.archiveDate,
            details: {
                external_link: objectData.details.external_link,
                header: objectData.details.header,
                writer: objectData.details.writer,
                text: objectData.details.text,
            },
            is_draft: false,
        };
        const dataKeys = Object.keys(data);
        dataKeys.forEach((el) => {
            if (!data[el] && el !== 'is_draft') delete data[el];
            if (el === "details") {
                const detailsKeys = Object.keys(data[el]);
                detailsKeys.forEach((det) => {
                    // @ts-ignore
                    if (!data.details[det]) delete data.details[det];
                });
            }
        });
        let error = false;
        await flowResult(bannerStore.update(data, objectData.id)).then(
            (response: any) => {
                if (!response?.success) {
									openModalForAction(
                        response?.code
                            ? t(`apiMessages.${response.code}`)
                            : t("apiMessages.0")
                    );
                    error = true;
                    return;
                }
            });
        return error;
    }

    const handleSelect = (id, list, setList, activity, setActivity) => {
      const newList = list.map((el) =>
        id === el.id ? { ...el, isSelect: !el.isSelect } : el
      );
      setList(newList); //reset the list of article to show the selected
      if (activity) setActivity(false); //disable the select all checkbox
    };

		const handleSelectAll = (list, setList, activity, setActivity) => {
      const newList = list.map((el) => {
        return { ...el, isSelect: !activity };
      });

      setList(newList);
      setActivity(!activity);
    };

    const columns: GridColDef[] = [
        {
            field: 'bannerAction',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div style={{ pointerEvents: 'none' }} />
                )
            },
            renderCell: (params: GridCellParams) => {
                const id = params.row.id;
                const isLocked = params.row.isLocked;
                const showLock = !(params.row.isArchived || params.row.isDraft) && (roleType === UserTypeEnum.owner || roleType === UserTypeEnum.associatedOwner);
                return showLock ? (
                    <div className={`banner-action ${!isLocked ? 'margin' : ''} text-center`}
                        onClick={() => toggleLock(id, !isLocked)}>
                        {
                            !isLocked && (
                                <LockOpenIcon />
                            )
                        }
                        {
                            isLocked && (
                                <IconComponent icon={LockIcon} />
                            )
                        }
                    </div>
                ) : <></>
            },
        },
        {
            field: 'image',
            headerName: '',
            sortable: false,
            width: 90,
            renderHeader: () => {
                return (
                    <div style={{ pointerEvents: 'none' }} />
                )
            },
            renderCell: (params: GridCellParams) => {
                const row = params.row.image;
                return (
                    <img className="table-row-image" src={row || ImagePlaceholder} alt={"Rotating banner"} />
                );
            },
        },
        {
            field: 'category',
            headerName: t('common.fieldHeaders.category'),
            sortable: true,
            width: 230,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column`}>
                        {params.colDef.headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const name = params.row.category.contentName;
                return (<span>{name}</span>)
            },
        },
        {
            field: 'header',
            headerName: t('common.fieldHeaders.header'),
            sortable: true,
            minWidth: 150,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isSaved = params.row.managementSave && !params.row.isDraft;
                return (
                    <span className={`table-row-title ${isSaved ? 'element-faded' : ''}`}>{params.row.header}</span>
                );
            },
        },
        {
            field: 'managedBy',
            headerName: t('common.fieldHeaders.managedBy'),
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                const headerName = params.colDef.headerName;
                return (
                    <span className={`table-column`}>
                        {headerName}
                    </span>
                )
            },
            renderCell: (params: GridCellParams) => {
                return (
                    <span className="table-row-title">{params.row.managedBy === MANAGEMENT ? t(`common.management`) : params.row.managedBy}</span>
                );
            },
            width: 200
        },
        {
            field: 'publishDate',
            headerName: t('common.fieldHeaders.date'),
            sortable: true,
            width: 170,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isSaved = params.row.managementSave && !params.row.isDraft;
                return (
                    <span
                        className={`table-row-date ${isSaved ? 'element-faded' : ''}`}>{convertToDate(params.row.publishDate)}</span>
                );
            },
        },
        {
            field: 'archiveDate',
            headerName: t('common.fieldHeaders.archived'),
            sortable: true,
            width: 170,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span
                        className={`table-column`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const isSaved = params.row.managementSave && !params.row.isDraft;
                return (
                    <span
                        className={`table-row-date ${isSaved ? 'element-faded' : ''}`}>{convertToDate(params.row.archiveDate)}</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: t('common.fieldHeaders.actions'),
            width: 100,
            sortable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <span className={`table-column text-center`}>{params.colDef.headerName}</span>
                )
            },
            renderCell: (params: GridCellParams) => {
                const data = params.row;
                return (
                    <div id={`grid-row-${data.id}`} className={`table-row-actions cell-actions`}
                        onClick={openActionMenu(data)}>
                        <MoreHorizIcon
                            aria-controls={`actions-menu`}
                            aria-haspopup="true"
                            className="table-row-actionIcon"
                        />
                    </div>
                );
            },
        },
    ].map((column) => (
        {
            ...column,
            disableClickEventBubbling: true,
        }));

		const articleCheckHeader = selectColumn(isSelectAllArticles, (id) => handleSelect(id, articleList, setArticleList, isSelectAllArticles, setIsSelectAllArticles),
																						() => handleSelectAll(articleList, setArticleList, isSelectAllArticles, setIsSelectAllArticles));
		const articleColumns = [articleCheckHeader, ...columns];

		const archiveCheckHeader = selectColumn(isSelectAllArchive, (id) => handleSelect(id, archiveList, setArchiveList, isSelectAllArchive, setIsSelectAllArchive),
		() => handleSelectAll(archiveList, setArchiveList, isSelectAllArchive, setIsSelectAllArchive));
		const archiveColumns = [archiveCheckHeader, ...columns];	

    return isLoading ? (<Spinner />) : (
        <div className="main-wrapper">
            <div className="page-heading-wrap">
                <h2 className="page-heading">
                    {t('articles.rotatingBanner.title')}
                </h2>
                <div className="flex-center">
                    {
                        (roleType === UserTypeEnum.owner) && (
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="select-filter"
                                    id="select-filter"
                                    className="select-preview-filter"
                                    value={comFilter}
                                    onChange={handleFilterCommunity}
                                >
                                    <MenuItem value='all'>{t('common.selectFilters.allCommunities')}</MenuItem>
                                    {
                                        communities.map((el, index) => {
                                            return (<MenuItem key={`menuItem-${index}`} value={el.id}>{el.communityName}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="select-filter"
                            id="select-filter"
                            className="select-preview-filter"
                            value={catsFilter}
                            onChange={handleFilterCategory}
                        >
                            <MenuItem value='all'>{t('common.selectFilters.allCategories')}</MenuItem>
                            {
                                categories.map((el, index) => {
                                    return (<MenuItem key={`filterKey-${index}`} value={el.id}>{el.contentName}</MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        lockedList.concat(articleList).length ? (
                            <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
                                {t('common.buttons.preview')}
                            </Button>
                        ) : null
                    }
                </div>
            </div>
            <div className="rotatingBanner-page">
                {
                    (articleList.length || archiveList.length || lockedList.length || draftList.length) ? (
                        <>
                            {
                                lockedList.length > 0 && (
                                    <div>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                pagination
                                                autoHeight={true}
                                                rows={lockedList}
                                                columns={lngDirection === "ltr" ? columns : reverseDirection(columns)}
                                                rowHeight={90}
                                                headerHeight={56}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                articleList.length > 0 && (
                                    <div>
                                        <div className="d-flex justify-between">
																					<h3>
																							{
																									// @ts-ignore
																									convertToDate(articleList[0].publishDate, 'header')
																							} ({totalActiveArticles})
																					</h3>
																					<Button
																								className="link-button-container"
																								onClick={handleArchiveSelected}
																								size="large"
																								variant="contained"
																								color="primary"
																						>
																								{t("common.fieldHeaders.moveToArchive")}
																						</Button>
																				</div>
                                        <div className={`table-grid drag-and-drop-table`}>
                                            <DragAndDropTable
                                                columns={articleColumns}
                                                rows={articleList}
                                                onReorder={handleReorder}
                                                pagination
                                                rowsPerPage={rowsPerPage}
                                                totalRows={totalActiveArticles}
                                                pageType='rotating-banners'
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                draftList.length > 0 && (
                                    <div>
                                        <h3>{t('common.drafts')} ({draftList.length})</h3>
                                        <div className={`table-grid`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={draftList}
                                                columns={lngDirection === "ltr" ? columns : reverseDirection(columns)}
                                                rowHeight={90}
                                                headerHeight={0}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                archiveList.length > 0 && (
                                    <div>
                                        <div className="d-flex justify-between">
																					<h3>{t('common.archive')}</h3>
																					<Button
																						className="link-button-container"
																						onClick={handleDeleteSelected}
																						size="large"
																						variant="contained"
																						color="primary"
																						>
																						{t("users.allUsers.delete")}
																					</Button>
																				</div>
                                        <div className={`table-grid element-faded`}>
                                            <DataGrid
                                                autoHeight={true}
                                                rows={archiveList}
                                                columns={lngDirection === "ltr" ? archiveColumns : reverseDirection(archiveColumns)}
                                                rowHeight={90}
                                                disableColumnMenu={true}
                                                hideFooter={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                <>
                                    <PopUpActionMenu toggleArchive={toggleArchive}
                                        handleDuplicate={handleDuplicate}
                                        handleAction={openModalForAction}
                                        currentPage="articles" />
                                    {
                                        lockedList.concat(articleList).length ? (
                                            <PreviewModal className="create-article banner list-preview"
                                                openModal={openModal} handleClose={handleCloseModal}
                                                hideNotYet content={
                                                    <div
                                                        className="scroll-container">{lockedList.concat(articleList).map((article: any, index) => {
                                                            const category = categories.filter((cat: any) => cat.id === article.categoryId)?.[0]?.contentName ?? '';
                                                            const image = article?.image ?? '';
                                                            return (
                                                                <div key={`locked-${index}`} className="preview-image-container banner">
                                                                    <div className={`preview-image banner`}>
                                                                        <img
                                                                            src={image}
                                                                            alt={image}
                                                                        />
                                                                    </div>
                                                                    <Swiper
                                                                        slidesPerView={1}
                                                                        slidesPerGroup={1}
                                                                        loop={true}
                                                                        navigation={false}
                                                                        className="rotating-banner"
                                                                    >
                                                                        {
                                                                            carouselSlides.map((index) => (
                                                                                <SwiperSlide key={`locked-slide-${index}`}>
                                                                                    <div className="trans-border">
                                                                                        <div className="banner">
                                                                                            <div className="preview-text">
                                                                                                <div
                                                                                                    className="preview-category">
                                                                                                    <span>{category || 'Category'}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="preview-header-date">
                                                                                                    <p className="preview-header">{(article.header ? (article.header.length > 40 ? article.header.substring(0, 40) + '...' : article.header) : '') || t('articles.allArticles.create.form.preview.headerGoes')}</p>
                                                                                                    <p className="preview-date">{convertToDate(article.publishDate)}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </SwiperSlide>
                                                                            ))
                                                                        }
                                                                    </Swiper>
                                                                </div>
                                                            )
                                                        })}</div>
                                                } />
                                        ) : null
                                    }
                                </>
                            }
                        </>
                    ) : <></>
                }
                {
                    !(articleList.length || archiveList.length || lockedList.length || draftList.length) ? (
                        <div className="no-banners">
                            <img src={NoBanners} alt="" />
                            <div>
                                <h3>{t('articles.rotatingBanner.noArticles.message1')}</h3>
                                <p>{t('articles.rotatingBanner.noArticles.message2')}</p>
                            </div>
                        </div>
                    ) : <></>
                }
            </div>
						<Modal></Modal>
        </div>
    )
}

export default observer(RotatingBanner)
